import { DefaultTheme } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"

const legendSize = 24

export const useStyles = makeStyles<DefaultTheme, { symbolColor: string; symbolIconColor: string }>(() => ({
    legend: {
        height: legendSize,
        position: "relative",
        width: legendSize,
    },
    symbol: props => ({
        "& svg": {
            fill: props.symbolIconColor,
            height: legendSize,
            width: legendSize,
        },
        backgroundColor: "transparent",
        color: props.symbolColor,
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: "24px",
        marginRight: "12px",
        minWidth: "10px",
        width: "10px",
    }),
}))
