import * as React from "react";
const SvgIogp1040 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "226.02, 165.16, 264.91998, 264.93", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("path", { d: "M293.7 362.41 L423.26 362.41 L293.7 232.84 L293.7 362.41 z", fillRule: "evenodd", style: {
  fill: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M423.26 362.41 L423.26 232.84 L293.7 232.84 L423.26 362.41 z", fillRule: "evenodd", style: {
  fill: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "293.7,232.84 423.26,232.84 423.26,362.41 293.7,362.41 293.7,232.84", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "358.48,297.63 358.48,297.63", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "293.7,276.03 226.27,276.03 226.27,319.22 293.7,319.22", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "336.88,232.84 336.88,165.41 380.07,165.41 380.07,232.84", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "423.26,276.03 490.69,276.03 490.69,319.22 423.26,319.22", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "380.07,362.41 380.07,429.84 336.88,429.84 336.88,362.41", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#00C5FF"
} }));
export default SvgIogp1040;
