import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(theme => ({
    appCard: {
        boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.15)",
        display: "flex",
        width: "100%",
    },

    appName: {
        color: "black",
        fontSize: 18,
        fontWeight: "bold",
        letterSpacing: 0.19,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    blueChip: {
        alignSelf: "center",
        backgroundColor: "#D3E2EA",
        color: theme.customColors.primary3,
        fill: theme.customColors.primary3,
    },
    cardContent: {
        display: "flex",
        justifyContent: "space-between",
    },

    image: {
        aspectRatio: "16 / 9",
        backgroundColor: theme.customColors.appBgColor,
        width: "100%",
    },
    modifiedUtcText: {
        color: theme.customColors.darkGray,
        fontSize: 14,
        paddingBottom: 8,
    },
}))
