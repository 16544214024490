import { FC } from "react"
import { Divider, Stack } from "@mui/material"
import { Dayjs } from "dayjs"
import CustomDateTimePicker from "./CustomDateTimePicker"
import QuickRangePicker from "./QuickRangePicker"
import { DatePickerView } from "./types/DatePickerView"

type Props = {
    maxDate: Dayjs
    minDate: Dayjs
    values: [Dayjs, Dayjs]
    views?: DatePickerView[]
    onChange: (values: [Dayjs, Dayjs]) => void
    onClear: () => void
}

const CustomDateRangePicker: FC<Props> = ({
    maxDate,
    minDate,
    values,
    views = ["year", "month", "day"],
    onChange,
    onClear,
}) => {
    const onStartDateChange = (startDate: Dayjs) => {
        // if the start time is set after the end time, update the end time as well
        if (startDate.isAfter(values[1])) {
            onChange([startDate, startDate])
        } else {
            onChange([startDate, values[1]])
        }
    }

    // if the end time is set before the start time, update the start time as well
    const onEndDateChange = (endDate: Dayjs) => {
        if (endDate.isBefore(values[0])) {
            onChange([endDate, endDate])
        } else {
            onChange([values[0], endDate])
        }
    }

    return (
        <Stack
            color={theme => theme.customColors.appTextColor}
            direction="row"
            divider={<Divider flexItem orientation="vertical" />}
            maxHeight={370}
            minHeight={340}
        >
            <CustomDateTimePicker
                maxDate={values[1]}
                minDate={minDate}
                name="start"
                value={values[0]}
                views={views}
                onChange={onStartDateChange}
            />
            <CustomDateTimePicker
                maxDate={maxDate}
                minDate={values[0]}
                name="end"
                value={values[1]}
                views={views}
                onChange={onEndDateChange}
            />
            <Stack paddingTop={1.5}>
                <QuickRangePicker
                    interval={values}
                    maxDate={maxDate}
                    minDate={minDate}
                    onClear={onClear}
                    onSetInterval={onChange}
                />
            </Stack>
        </Stack>
    )
}

export default CustomDateRangePicker
