import makeStyles from "@mui/styles/makeStyles"

export const useMapToolsStyles = makeStyles(theme => ({
    root: {
        bottom: 25,
        position: "absolute",
        right: 112,
        width: "auto",
        zIndex: 5,
    },
}))
