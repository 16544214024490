import { FC } from "react"
import { useMainDraw } from "../customHooks/map/useMainDraw"

export const withMainDraw =
    <P extends object>(WrappedComponent: FC<P>) =>
    (props: P) => {
        const draw = useMainDraw()

        return <WrappedComponent draw={draw} {...props} />
    }
