import * as React from "react";
const SvgAirfield11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "airfield-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, fillOpacity: 0, style: {
  fill: "transparent"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0.5 L3.5 0.5 C3 0.5 3 0 3.5 0 L7.5 0 C8 0 8 0.5 7.5 0.5 L6 0.5 C6 0.5 6.5 1 6.5 2 L6.5 3 L11 3 L11 4.5 L6.5 6.5 L6 10 L7.5 10.5 L7.5 11 L3.5 11 L3.5 10.5 L5 10 L4.5 6.5 L0 4.5 L0 3 L4.5 3 L4.5 2 C4.5 1 5 0.5 5 0.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0.5 L3.5 0.5 C3 0.5 3 0 3.5 0 L7.5 0 C8 0 8 0.5 7.5 0.5 L6 0.5 C6 0.5 6.5 1 6.5 2 L6.5 3 L11 3 L11 4.5 L6.5 6.5 L6 10 L7.5 10.5 L7.5 11 L3.5 11 L3.5 10.5 L5 10 L4.5 6.5 L0 4.5 L0 3 L4.5 3 L4.5 2 C4.5 1 5 0.5 5 0.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAirfield11;
