const PRIMARY_BG = "#024F79"
const SECONDARY_BG = "#0393e2"

const appStyles = {
    color: {
        ACTION_BACKGROUND: PRIMARY_BG,
        CHECKED_SWITCH: SECONDARY_BG,
        CLOSE_BACKGROUND: "#013c5c",
        CLOSE_COLOR: "#2a385d",
        HEADER_BACKGROUND: PRIMARY_BG,
        HEADER_BORDER: "#BDBDBD",
        LAYER_BACKGROUND: "#F1F3F3",
        TOOL_HOVER: "#0C567E",
    },
    fontSize: {
        LIST_TEXT: 12,
    },
}
export default appStyles
