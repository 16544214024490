import makeStyles from "@mui/styles/makeStyles"

export const useExaggerationSliderStyles = makeStyles(theme => ({
    exaggerationContainer: {
        alignItems: "center",
        display: "flex",
        marginTop: 16,
        padding: "0px 16px",
    },
    flexGrow: {
        flexGrow: 1,
    },
    headerContainer: {
        alignItems: "center",
        display: "flex",
        padding: "8px 8px",
        paddingTop: 4,
    },
    headerIcon: {
        color: "lightgray",
        marginRight: 8,
        transition: "0.2s all",
    },
    headerTypography: {
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
    },
    icon: {
        color: "#484848",
        transition: "0.2s all",
    },
    iconContainer: {
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4,
        },
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 29,
        justifyContent: "center",
        width: 29,
    },
    iconRoot: {
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
        position: "absolute",
        right: 10,
        top: 267,
        zIndex: 2,
    },
    popoverRoot: {
        padding: 8,
        width: 250,
    },
    slider: {
        padding: 20,
    },
    sliderContainer: {
        display: "flex",
        padding: "0px 16px",
    },
    iconEnabled: {
        color: theme.palette.primary.main,
    },
    IconEnabled: {
        color: theme.palette.primary.main,
    },
}))
