import * as React from "react";
const SvgBicycleShare15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bicycle-share-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 1 C9.4477 1 9 1.4477 9 2 C9 2.5523 9.4477 3 10 3 C10.5523 3 11 2.5523 11 2 C11 1.4477 10.5523 1 10 1 z M8.1445 2.9941 C8.0145 2.9946 7.8897996 3.0458002 7.7967997 3.1367002 L5.1561995 5.6367 C4.930599 5.8495 4.9510994 6.2142 5.1991997 6.4004 L7 7.75 L7 10.5 C6.99 11.1762 8.0096 11.1762 8 10.5 L8 7.5 C8.0003 7.3426 7.9265 7.1943 7.8008 7.0996 L7.0332 6.5234 L8.8512 4.8022 L9.5996 5.8006997 C9.6943 5.9265 9.8426 6.0003 10 6 L11.5 6 C12.1761 6.01 12.1761 4.9904 11.5 5 L10.25 5 L9.5 4 L8.9004 3.1992 C8.8103 3.0756 8.6685 3 8.5156 2.9941 L8.1445 2.9941 z M3 7 C1.3431 7 0 8.3432 0 10 C0 11.6568 1.3431 13 3 13 C4.6569 13 6 11.6568 6 10 C6 8.3432 4.6569 7 3 7 z M12 7 C10.3431 7 9 8.3432 9 10 C9 11.6568 10.3431 13 12 13 C13.6569 13 15 11.6568 15 10 C15 8.3432 13.6569 7 12 7 z M3 8 C4.1046 8 5 8.8954 5 10 C5 11.1046 4.1046 12 3 12 C1.8954 12 1 11.1046 1 10 C1 8.8954 1.8954 8 3 8 z M12 8 C13.1046 8 14 8.8954 14 10 C14 11.1046 13.1046 12 12 12 C10.8954 12 10 11.1046 10 10 C10 8.8954 10.8954 8 12 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 1 C9.4477 1 9 1.4477 9 2 C9 2.5523 9.4477 3 10 3 C10.5523 3 11 2.5523 11 2 C11 1.4477 10.5523 1 10 1 z M8.1445 2.9941 C8.0145 2.9946 7.8897996 3.0458002 7.7967997 3.1367002 L5.1561995 5.6367 C4.930599 5.8495 4.9510994 6.2142 5.1991997 6.4004 L7 7.75 L7 10.5 C6.99 11.1762 8.0096 11.1762 8 10.5 L8 7.5 C8.0003 7.3426 7.9265 7.1943 7.8008 7.0996 L7.0332 6.5234 L8.8512 4.8022 L9.5996 5.8006997 C9.6943 5.9265 9.8426 6.0003 10 6 L11.5 6 C12.1761 6.01 12.1761 4.9904 11.5 5 L10.25 5 L9.5 4 L8.9004 3.1992 C8.8103 3.0756 8.6685 3 8.5156 2.9941 L8.1445 2.9941 z M3 7 C1.3431 7 0 8.3432 0 10 C0 11.6568 1.3431 13 3 13 C4.6569 13 6 11.6568 6 10 C6 8.3432 4.6569 7 3 7 z M12 7 C10.3431 7 9 8.3432 9 10 C9 11.6568 10.3431 13 12 13 C13.6569 13 15 11.6568 15 10 C15 8.3432 13.6569 7 12 7 z M3 8 C4.1046 8 5 8.8954 5 10 C5 11.1046 4.1046 12 3 12 C1.8954 12 1 11.1046 1 10 C1 8.8954 1.8954 8 3 8 z M12 8 C13.1046 8 14 8.8954 14 10 C14 11.1046 13.1046 12 12 12 C10.8954 12 10 11.1046 10 10 C10 8.8954 10.8954 8 12 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBicycleShare15;
