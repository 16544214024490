import * as React from "react";
const SvgGarden11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "garden-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 6 C10.0043 8.2091 8.217 10.0035 6.0078 10.0078 C5.9218 10.008 5.8358 10.0054 5.75 10 C3.5452 10.1381 1.6458998 8.4626 1.5078001 6.2578 C1.5024 6.172 1.4998 6.086 1.5 6 C3.1411 5.8794 4.6886 6.7762 5.4 8.26 L5.4 5 L3.4 5 C2.9085 5.0055 2.5056 4.6116 2.5001 4.1201 C2.5 4.1167 2.5 4.1134 2.5 4.11 L2.5 1.84 C2.492 1.6469 2.6421 1.4838 2.8352 1.4758 C2.9567 1.4708 3.0721 1.5292 3.14 1.63 L4.28 3 L5.4500003 0.6700001 C5.5496 0.50430006 5.7646003 0.45070007 5.9303 0.55030006 C5.9794 0.5798 6.0205 0.6209 6.05 0.67 L7.22 3 L8.349999 1.62 C8.448199 1.4535 8.6628 1.3981 8.829299 1.4963 C8.946 1.5651 9.0125 1.6951 9 1.83 L9 4.11 C9 4.6015 8.6016 5 8.1101 5.0001 C8.1067 5.0001 8.1034 5 8.1 5 L6.1000004 5 L6.1000004 8.26 C6.8114 6.7762 8.3589 5.8794 10 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 6 C10.0043 8.2091 8.217 10.0035 6.0078 10.0078 C5.9218 10.008 5.8358 10.0054 5.75 10 C3.5452 10.1381 1.6458998 8.4626 1.5078001 6.2578 C1.5024 6.172 1.4998 6.086 1.5 6 C3.1411 5.8794 4.6886 6.7762 5.4 8.26 L5.4 5 L3.4 5 C2.9085 5.0055 2.5056 4.6116 2.5001 4.1201 C2.5 4.1167 2.5 4.1134 2.5 4.11 L2.5 1.84 C2.492 1.6469 2.6421 1.4838 2.8352 1.4758 C2.9567 1.4708 3.0721 1.5292 3.14 1.63 L4.28 3 L5.4500003 0.6700001 C5.5496 0.50430006 5.7646003 0.45070007 5.9303 0.55030006 C5.9794 0.5798 6.0205 0.6209 6.05 0.67 L7.22 3 L8.349999 1.62 C8.448199 1.4535 8.6628 1.3981 8.829299 1.4963 C8.946 1.5651 9.0125 1.6951 9 1.83 L9 4.11 C9 4.6015 8.6016 5 8.1101 5.0001 C8.1067 5.0001 8.1034 5 8.1 5 L6.1000004 5 L6.1000004 8.26 C6.8114 6.7762 8.3589 5.8794 10 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGarden11;
