import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function filterRecurseWithReturn<T = object>(
    layers: GenericLayer<T>[],
    func: Function,
    selector: (layer: GenericLayer<T>) => boolean,
) {
    const filteredLayers: GenericLayer<T>[] = []

    for (const layer of layers) {
        if (selector(layer)) {
            if (func(layer)) {
                filteredLayers.push({
                    ...layer,
                    layers: isGroup(layer) ? filterRecurseWithReturn(layer.layers!, func, selector) : undefined,
                })
            }
        } else {
            filteredLayers.push({
                ...layer,
                layers: isGroup(layer) ? filterRecurseWithReturn(layer.layers!, func, selector) : undefined,
            })
        }
    }
    return filteredLayers
}
