import React, { useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import { IconButton, Paper, Tooltip, Typography } from "@mui/material"
import { FilterType, useFilterContext } from "../../../../contexts/FilterContext"
import { useFilterIconStyles } from "./styles"

type FilterIconProps = {
    filter: FilterType
    index: number
}

const FilterIcon = ({ filter, index }: FilterIconProps) => {
    const context = useFilterContext()
    const classes = useFilterIconStyles(context.isFilterSelected(filter))
    const [isHovered, setIsHovered] = useState(false)

    const handlePress = () => {
        context.updateFilterSelection(index)
    }

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        context.removeFilter(index)
    }

    return (
        <Paper
            className={classes.filterPaper}
            elevation={context.isFilterSelected(filter) ? 6 : 2}
            onClick={handlePress}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Tooltip title={filter.fieldName}>
                <Typography noWrap style={{ fontSize: "12px" }}>
                    {filter.fieldName}
                </Typography>
            </Tooltip>
            <div className={classes.removeButton}>
                {isHovered && (
                    <IconButton size="small" style={{ height: "24px", padding: "4px" }} onClick={handleDelete}>
                        <RemoveIcon fontSize="small" />
                    </IconButton>
                )}
            </div>
        </Paper>
    )
}

export default FilterIcon
