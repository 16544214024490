import * as React from "react";
const SvgFerry11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "ferry-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 9.45 L11 10.219999 C10.7833 10.228899 10.5787 10.322199 10.43 10.48 C10.203401 10.699599 9.924601 10.8579 9.62 10.94 C9.1051 11.0765 8.5561 10.9696 8.13 10.65 L7.8 10.38 C7.5331 10.1487 7.1369 10.1487 6.8700004 10.38 C6.7500005 10.47 6.6400003 10.58 6.5200005 10.66 C5.8867006 11.1119995 5.0311003 11.091599 4.4200006 10.61 L4.0900006 10.339999 C3.8443007 10.154999 3.5057006 10.154999 3.2600007 10.339999 C3.1000006 10.449999 2.9600008 10.589999 2.8000007 10.699999 C2.1990006 11.118499 1.4010006 11.118499 0.80000067 10.699999 L0.5400007 10.489999 C0.4009 10.3346 0.2078 10.238 0 10.22 L0 9.45 C0.2162 9.4269 0.4345 9.465 0.63 9.559999 C0.8642 9.6894 1.0882 9.8365 1.3 10 C1.4841 10.1814 1.7478 10.2567 2 10.2 C2.093 10.1756 2.181 10.1349 2.26 10.08 C2.3899999 9.99 2.51 9.88 2.6399999 9.78 C3.2559998 9.328199 4.094 9.328199 4.71 9.78 L5.11 10.09 C5.3516 10.265201 5.6784 10.265201 5.92 10.09 L6.32 9.78 C6.9381003 9.323299 7.7819004 9.323299 8.4 9.78 L8.78 10.08 C9.0237 10.26 9.356299 10.26 9.599999 10.08 L10 9.77 C10.2882 9.5546 10.6403 9.4419 11 9.45 z M2.61 7.61 L1 5 L2 4.09 L2 1.15 C2.0163 0.5061 2.546 -0.0057 3.19 0 L7.81 0 C8.454 -0.0057 8.9837 0.5061 9 1.15 L9 4.09 L10 5 L8.39 7.62 C7.5729 7.0755 6.4952 7.1324 5.74 7.76 L5.5 8 L5.5 8 L5.3 7.82 C4.5536 7.1506 3.4512 7.0646 2.61 7.61 z M2.81 3.61 L5.5 2 L8.190001 3.63 L8.190001 1.15 C8.201401 0.95149994 8.049701 0.78139997 7.8512006 0.77 C7.8375006 0.76919997 7.8237004 0.76919997 7.8100004 0.77 L3.19 0.77 C2.9915 0.7586 2.8214 0.9103 2.81 1.1088 C2.8092 1.1225001 2.8092 1.1363001 2.81 1.1500001 L2.81 3.63 L2.81 3.61 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 9.45 L11 10.219999 C10.7833 10.228899 10.5787 10.322199 10.43 10.48 C10.203401 10.699599 9.924601 10.8579 9.62 10.94 C9.1051 11.0765 8.5561 10.9696 8.13 10.65 L7.8 10.38 C7.5331 10.1487 7.1369 10.1487 6.8700004 10.38 C6.7500005 10.47 6.6400003 10.58 6.5200005 10.66 C5.8867006 11.1119995 5.0311003 11.091599 4.4200006 10.61 L4.0900006 10.339999 C3.8443007 10.154999 3.5057006 10.154999 3.2600007 10.339999 C3.1000006 10.449999 2.9600008 10.589999 2.8000007 10.699999 C2.1990006 11.118499 1.4010006 11.118499 0.80000067 10.699999 L0.5400007 10.489999 C0.4009 10.3346 0.2078 10.238 0 10.22 L0 9.45 C0.2162 9.4269 0.4345 9.465 0.63 9.559999 C0.8642 9.6894 1.0882 9.8365 1.3 10 C1.4841 10.1814 1.7478 10.2567 2 10.2 C2.093 10.1756 2.181 10.1349 2.26 10.08 C2.3899999 9.99 2.51 9.88 2.6399999 9.78 C3.2559998 9.328199 4.094 9.328199 4.71 9.78 L5.11 10.09 C5.3516 10.265201 5.6784 10.265201 5.92 10.09 L6.32 9.78 C6.9381003 9.323299 7.7819004 9.323299 8.4 9.78 L8.78 10.08 C9.0237 10.26 9.356299 10.26 9.599999 10.08 L10 9.77 C10.2882 9.5546 10.6403 9.4419 11 9.45 z M2.61 7.61 L1 5 L2 4.09 L2 1.15 C2.0163 0.5061 2.546 -0.0057 3.19 0 L7.81 0 C8.454 -0.0057 8.9837 0.5061 9 1.15 L9 4.09 L10 5 L8.39 7.62 C7.5729 7.0755 6.4952 7.1324 5.74 7.76 L5.5 8 L5.5 8 L5.3 7.82 C4.5536 7.1506 3.4512 7.0646 2.61 7.61 z M2.81 3.61 L5.5 2 L8.190001 3.63 L8.190001 1.15 C8.201401 0.95149994 8.049701 0.78139997 7.8512006 0.77 C7.8375006 0.76919997 7.8237004 0.76919997 7.8100004 0.77 L3.19 0.77 C2.9915 0.7586 2.8214 0.9103 2.81 1.1088 C2.8092 1.1225001 2.8092 1.1363001 2.81 1.1500001 L2.81 3.63 L2.81 3.61 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFerry11;
