import React, { FC } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import { Autocomplete, TextField } from "@mui/material"
// @ts-ignore
import TitleTextField from "../common/TitleTextfield/TitleTextField"

type AutocompletePropertyProps = {
    columns: []
    editable: boolean
    title: string
    value: string
    isDefaultValue: boolean
    isLastRemainingCase: boolean
    isZoomDependent: boolean
    onPropertyChanged: (value: string) => void
    onRemove: () => void
    onTitleChanged: (newTitle: string) => void
}

const AutocompleteProperty: FC<AutocompletePropertyProps> = ({
    columns,
    editable,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) => {
    const removable = !!onRemove && !isDefaultValue && !isLastRemainingCase

    function generateSuggestions(input: string, options: string[]): string[] {
        const separator = "{"
        const lastIndex = input.lastIndexOf(separator)

        // Split into first part (before last separator) and last part (after last separator)
        const firstPart = input.slice(0, lastIndex)
        const lastPart = input.slice(lastIndex + 1)

        // Filter options that match the last part after the separator
        return options
            .filter(option => option.startsWith(`${separator}${lastPart}`))
            .map(option => `${firstPart}${option}`)
    }

    const columnsNames: string[] = columns.map((item: { prettyName: string }) => item.prettyName)

    const formattedSuggestions = columnsNames.map(columnName => `{${columnName}}`)

    const suggestions = generateSuggestions(value, formattedSuggestions)

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}

                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}

                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}

                <div className="textfield-container">
                    <Autocomplete
                        id="autocomplete-text"
                        options={suggestions}
                        value={value}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={title}
                                placeholder="Select or type"
                                value={value}
                                variant="standard"
                                onChange={e => onPropertyChanged(e.target.value)}
                            />
                        )}
                        onChange={(_, newValue) => onPropertyChanged(newValue as string)}
                    />
                </div>
            </div>
        </div>
    )
}
export default AutocompleteProperty
