import clsx from "clsx"
import { useStyles } from "./style"

type Props = {
    circleColor?: string
    fillColor?: string
    lineColor?: string
}

const PolygonIcon = ({ circleColor, fillColor, lineColor }: Props) => {
    const { circle, fill, lb, legend, line, lt, polygonBorder, rb, rt } = useStyles({
        circleColor,
        fillColor,
        lineColor,
    })

    return (
        <div className={clsx(legend, fillColor && fill, lineColor && line, !lineColor && fillColor && polygonBorder)}>
            {circleColor && (
                <>
                    <div className={clsx(circle, rt)} />
                    <div className={clsx(circle, rb)} />
                    <div className={clsx(circle, lb)} />
                    <div className={clsx(circle, lt)} />
                </>
            )}
        </div>
    )
}

export default PolygonIcon
