export const boatTypes: Record<string, string> = {
    Cargo: "boat-15-green",
    Fishing: "boat-15-orange",
    "High-Speed Craft": "boat-15-yellow",
    "Navigation Aid": "navigational-aid",
    Passanger: "boat-15-blue",
    "Pleasure Craft": "boat-15-purple",
    Tanker: "boat-15-red",
    "Tug & Special Craft": "boat-15-teal",
    Unspecified: "boat-15",
}
