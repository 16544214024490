import * as React from "react";
const SvgStar11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "star-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.4 0 L4 3.5 L0 3.5 L3 6.5 L1.5 11 L5.4 8.4 L9.5 11 L8 6.5 L11 3.5 L7 3.5 L5.4 0 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.4 0 L4 3.5 L0 3.5 L3 6.5 L1.5 11 L5.4 8.4 L9.5 11 L8 6.5 L11 3.5 L7 3.5 L5.4 0 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgStar11;
