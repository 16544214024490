import makeStyles from "@mui/styles/makeStyles"

export const useBufferStyles = makeStyles(theme => ({
    header: {
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        display: "flex",
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
    },
    headerText: {
        flexGrow: 1,
    },
    root: {
        backgroundColor: "#fff",
        borderRadius: 4,
        left: 16,
        minWidth: 300,
        position: "absolute",
        top: 16,
        zIndex: 2,
    },
    searchContainer: {
        padding: theme.spacing(1, 2),
    },
}))
