import { FC } from "react"
import { ExpandLess } from "@mui/icons-material"
import ColorLensIcon from "@mui/icons-material/ColorLens"
import { Collapse, IconButton, Tooltip } from "@mui/material"
import { setSidebarOpen } from "actions/sidebarAndDrawer"
import OverflowTip from "components/common/OverflowTip/OverflowTip"
import { useFetchDprDatasetColumnsQuery } from "features/dprIntegration/api"
import useDefaultInfoboxStyles from "features/infobox/components/DefaultInfobox/styles"
import { setStylerLayerId } from "reducers/layerSelector"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import copyButton from "utils/icons/copy-icon.svg"
import { BuildProperties } from "utils/infoBoxUtils"
import DprProperties from "../DprProperties/DprProperties"
import useInfoboxToUse from "../DprProperties/useInfoboxToUse"

type Props = {
    collapsed: boolean
    feature: mapboxgl.MapboxGeoJSONFeature
    featureIndex: number
    sourceToNameMapping: Record<string, string>
    hasMultipleFeatures: boolean
    toggleFeature: (index: number) => void
    onCopyClick: (index: number) => void
}

const VectorFeature: FC<Props> = ({
    collapsed,
    feature,
    featureIndex,
    sourceToNameMapping,
    hasMultipleFeatures,
    toggleFeature,
    onCopyClick,
}) => {
    const classes = useDefaultInfoboxStyles()

    const dispatch = useAppDispatch()

    const infoboxToUse = useInfoboxToUse(feature.sourceLayer)

    const { data: dprDatasetColumns = null } = useFetchDprDatasetColumnsQuery(feature.sourceLayer ?? "", {
        skip: infoboxToUse !== "dpr" || !feature.sourceLayer,
    })

    let { featurePropertiesElements, hasPropertiesToDisplay } = BuildProperties(
        feature,
        classes,
        dprDatasetColumns?.progressColumn.prettyName,
    )

    const isBoat = () => {
        return !!feature.properties?.MMSI
    }

    const onEditLayerStyleClick = () => {
        dispatch(setSidebarOpen(true))
        // @ts-ignore TODO: fix this
        dispatch(setStylerLayerId(feature?.layer?.metadata?.resourceId ?? ""))
    }

    const table = (
        <table className={classes.table}>
            <tbody>
                {featurePropertiesElements}
                <DprProperties feature={feature} />
            </tbody>
        </table>
    )

    return (
        <div key={featureIndex}>
            <div className={classes.featureHeaderContainer}>
                <div className={classes.featureHeader}>
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <IconButton
                                className={classes.innerCollapseBtn}
                                size="small"
                                onClick={_ => toggleFeature(featureIndex)}
                            >
                                <ExpandLess className={collapsed ? classes.expandedLeft : classes.collapsed} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <OverflowTip textWeight="bold" variant="subtitle1">
                        {sourceToNameMapping[feature.sourceLayer || feature.source]}
                    </OverflowTip>
                </div>
                <div
                    className={
                        hasMultipleFeatures
                            ? classes.featureButtonsMultipleFeatures
                            : classes.featureButtonsSingleFeature
                    }
                >
                    {hasPropertiesToDisplay && (
                        <Tooltip arrow placement="bottom" title="Copy the layer's name and properties">
                            <IconButton
                                className={classes.squareBtn}
                                size="small"
                                onClick={() => onCopyClick(featureIndex)}
                            >
                                <img alt="" src={copyButton} />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!isBoat() && (
                        <Tooltip placement="bottom" title="Edit layer style">
                            <IconButton
                                className={classes.squareBtn}
                                size="small"
                                onClick={() => onEditLayerStyleClick()}
                            >
                                <ColorLensIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <div className={classes.borderBox}>{table}</div>
                </Collapse>
            ) : (
                table
            )}
        </div>
    )
}

export default VectorFeature
