import React from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import EditIcon from "@mui/icons-material/Edit"
import DownloadIcon from "@mui/icons-material/GetApp"
import PublishIcon from "@mui/icons-material/Publish"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogProps,
    Stack,
    IconButton,
    IconButtonProps,
} from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import CustomTypography from "../CustomTypography/CustomTypography"

type QADataTestProps = {
    "data-testid"?: string
}

type CustomModalProps = {
    closeIconButtonProps?: IconButtonProps & QADataTestProps
    dialogTitle: React.ReactNode
    dialogType?: string
    hideActionContainer?: boolean
    disabled?: boolean
    isOpen: boolean
    handleCancel?: () => void
    handleClose: () => void
    onConfirm?: (event: React.MouseEvent) => void
} & Omit<DialogProps, "open">

const CustomDialog = ({
    children,
    closeIconButtonProps,
    dialogTitle,
    dialogType = "",
    hideActionContainer = false,
    disabled,
    isOpen,
    handleCancel,
    handleClose,
    onConfirm,
}: CustomModalProps) => {
    const typesWithoutCancelButton: Record<string, boolean> = {
        info: true,
    }

    const displayConfirmationButton = () => {
        const buttonText: Record<string, string> = {
            add: "Add",
            apply: "Apply",
            create: "Create",
            info: "Understood",
            upload: "Upload",
            start: "Start",
            update: "Update",
        }

        const buttonIcon: Record<string, JSX.Element> = {
            add: <AddIcon fontSize="small" />,
            apply: <CheckIcon fontSize="small" />,
            upload: <PublishIcon fontSize="small" />,
            start: <DownloadIcon fontSize="small" />,
            update: <EditIcon fontSize="small" />,
        }

        return (
            <Button
                color="primary"
                data-testid={confirmationButtonTestId}
                variant="contained"
                disabled={disabled}
                startIcon={buttonIcon[dialogType]}
                onClick={onConfirm}
            >
                {buttonText[dialogType]}
            </Button>
        )
    }

    const cancelButton = () => {
        return (
            typesWithoutCancelButton[dialogType] || (
                <Button
                    color="primary"
                    data-testid={cancelButtonTestId}
                    variant="text"
                    onClick={() => {
                        handleCancel?.()
                        handleClose()
                    }}
                >
                    <CustomTypography fontWeight="bold">Cancel</CustomTypography>
                </Button>
            )
        )
    }

    return (
        <Dialog
            aria-labelledby="atlas-dialog create-group-modal"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            closeAfterTransition
            open={isOpen}
        >
            <DialogTitle>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <CustomTypography textWeight="bold" variant="h3">
                        {dialogTitle}
                    </CustomTypography>
                    <IconButton data-testid={closeButtonTestId} onClick={handleClose} {...closeIconButtonProps}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            {!hideActionContainer && (
                <DialogActions>
                    {displayConfirmationButton()}
                    {cancelButton()}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default CustomDialog

const confirmationButtonTestId = "qa-custom-modal-confirmation-button"
const cancelButtonTestId = "qa-custom-modal-cancel-button"
const closeButtonTestId = "qa-custom-modal-close-button"
