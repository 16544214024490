import { useControl } from "@emblautec/react-map-gl"
import PitchToggle from "../../utils/pitchToggle"

const PitchToggleControl = ({ position, setTerrainToggle }) => {
    useControl(() => new PitchToggle({ minpitchzoom: 11 }, val => setTerrainToggle(val)), { position })

    return null
}

export default PitchToggleControl
