import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { resetProjectData } from "actions/globalActions"
import { fetchHealth } from "./actions"
import { HealthEnum } from "./model/HealthEnum"

type HealthState = {
    fetching: boolean
    health: HealthEnum
}

const initialState: HealthState = {
    fetching: false,
    health: HealthEnum.Unknown,
}

const healthSlice = createSlice({
    extraReducers: builder => {
        builder
            .addCase(fetchHealth.fulfilled, (state, { payload }) => {
                state.health = payload
            })
            .addCase(fetchHealth.rejected, (state, { payload }) => {
                state.health = payload ?? HealthEnum.Unhealthy
            })
            .addCase(fetchHealth.pending, state => {
                state.fetching = true
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isAnyOf(fetchHealth.fulfilled, fetchHealth.rejected), state => {
                state.fetching = false
            })
    },
    initialState,
    name: "health",
    reducers: {
        setHealth: (state, { payload: health }: PayloadAction<HealthEnum>) => {
            state.health = health
        },
    },
})

export const { setHealth } = healthSlice.actions

export default healthSlice.reducer
