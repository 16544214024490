import { Component } from "react"
import proj4 from "proj4"
import { connect } from "react-redux"
import { isGroup } from "@emblautec/recursive-array-extensions"
import { getApp } from "actions/apps"
import { getToggledWidgetsSet, getWidgets } from "features/mapTools/selectors"
import { setConfig } from "features/mapTools/slice"
import { getSelectedApp } from "selectors/appsSelectors"
import { clearMap } from "../actions/globalActions"
import * as printActions from "../actions/print"
import * as sidebarActions from "../actions/sidebarAndDrawer"
import HeaderButtons from "../app/components/HeaderButtons/HeaderButtons"
import { mapSubToGeoJsonSource } from "../components/sidebar/ais/utils"
import Print from "../components/sidebar/print/print"
import SidebarRoot from "../components/sidebar/SidebarRoot/SidebarRoot"
import ToolsMenu from "../components/sidebar/toolsMenu/toolsMenu"
import {
    basemapQueryParamName,
    bearingQueryParamName,
    boundsQueryParamName,
    centerQueryParamName,
    languageQueryParamName,
    latQueryParamName,
    lngQueryParamName,
    pitchQueryParamName,
    sidebarStatusQueryParamName,
    visibleLayersQueryParamName,
    zoomQueryParamName,
} from "../constants/map/queryParams"
import MainMap from "../features/map/components/MainMap/MainMap"
import * as layerSelectorActions from "../reducers/layerSelector"
import * as mapActions from "../reducers/map"
import { getAISSubscriptionData, getAISSubscriptionInfo } from "../selectors/aisSelectors"
import { getStyleConfig } from "../selectors/stylesSelectors"
import { makeVisibilityProperty } from "../utils/creators/styleProperties/visibilityPropertyCreator"
import { withMap } from "../utils/HOCs/withMap"
import { PortalDiv } from "../utils/portal"

class MapView extends Component {
    constructor(props) {
        super(props)
        this.queryParams = new URLSearchParams(document.location.search)
    }

    componentDidMount() {
        this.props.clear()
        this.loadApp()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appId !== this.props.appId) {
            this.props.clear()
            this.loadApp()
            this.props.resetMapFeatures()
        }
    }

    isUpdated(resourceId, modifiedDate) {
        const savedDate = new Date(localStorage.getItem(resourceId))
        return modifiedDate > savedDate
    }

    checkCache(app) {
        const maps = app.maps
        const modifiedDate = new Date(app.modifiedUtc)

        for (let i = 0; i < maps.length; i++) {
            let map = maps[i]

            if (this.isUpdated(map.id, modifiedDate)) {
                caches.delete(map.id)
                localStorage.setItem(map.id, app.modifiedUtc)
            }
        }
    }

    initMapBounds = appMapBounds => {
        const location = this.getMapLocationFromQueryParam()
        if (!!location) {
            this.props.mainMap?.flyTo({
                animate: false,
                center: [location.lon, location.lat],
                zoom: 7,
            })
            return
        }

        const mapBounds = this.getMapBoundsFromQueryParam() || appMapBounds
        this.props.mainMap?.fitBounds(mapBounds, {
            animate: false,
            padding: { bottom: 45, left: 45, right: 45, top: 45 },
            ...this.get3DInfoFromQueryParam(),
        })
    }

    initMapLayout = () => {
        const { bearing, pitch } = this.get3DInfoFromQueryParam()
        this.props.mainMap?.setPitch(pitch ?? this.props.mainMap?.getPitch())
        this.props.mainMap?.setBearing(bearing ?? this.props.mainMap?.getBearing())

        const zoom = this.queryParams.get(zoomQueryParamName)
        this.props.mainMap?.setZoom(zoom ?? this.props.mainMap.getZoom())

        const center = this.queryParams.get(centerQueryParamName)
        this.props.mainMap?.setCenter((center && JSON.parse(center)) ?? this.props.mainMap.getCenter())
    }

    getInitialBasemap = basemaps => {
        const basemapQueryParam = this.queryParams.get(basemapQueryParamName)

        if (basemapQueryParam === "None") {
            return { type: "none" }
        }

        if (basemapQueryParam) {
            return basemaps.find(basemap => basemap.title === basemapQueryParam)
        }
        return basemaps[0]
    }

    getInitialLanguage = languages => {
        const languageQueryParam = this.queryParams.get(languageQueryParamName)

        const paramLanguage = languages?.find(x => x.code === languageQueryParam)
        if (paramLanguage) return paramLanguage.code

        const appDefaultLanguage = languages?.find(x => x.default)
        if (appDefaultLanguage) return appDefaultLanguage.code

        return "en"
    }

    loadApp() {
        const isPublic = this.props.path.includes("public")
        return this.props.getApp({ appId: this.props.appId, isPublic }).then(({ payload: appResponse }) => {
            if (!appResponse) {
                this.props.history.replace("/")
                return
            }

            const app = appResponse

            this.checkCache(app)

            this.addProjections(app.configJson.projections)

            this.initMapBounds(app.mapBounds)

            this.initMapLayout()

            this.addSources(app.maps, app.rasters)

            this.loadStyles(app)

            this.props.setRestrictedView(app.restrictedView)

            this.props.setSidebarOpen(this.getSidebarStatusFromQueryParam(app))

            this.props.setConfig({
                basemaps: app.basemaps || [],
                languages: app.languages,
                projections: app.configJson.projections,
            })

            const basemap = this.getInitialBasemap(app.basemaps)
            const language = this.getInitialLanguage(app.languages)

            this.props.initMapSettings({
                basemap,
                language,
            })
        })
    }

    get3DInfoFromQueryParam = () => {
        const pitch = parseInt(this.queryParams.get(pitchQueryParamName))
        const bearing = parseInt(this.queryParams.get(bearingQueryParamName))

        return {
            ...(!!pitch && { pitch }),
            ...(!!bearing && { bearing }),
        }
    }

    getMapBoundsFromQueryParam = () => {
        const bounds = this.queryParams.get(boundsQueryParamName)
        return bounds && JSON.parse(bounds)
    }

    getMapLocationFromQueryParam = () => {
        const queryParams = new URLSearchParams(document.location.search)

        const lat = parseFloat(queryParams.get(latQueryParamName) || "")
        const lng = parseFloat(queryParams.get(lngQueryParamName) || "")

        if (!!lat && !!lng) {
            return {
                lat: lat,
                lon: lng,
            }
        }
        return null
    }

    getVisibleLayersFromQueryParam = () => {
        const visibleLayersCsv = this.queryParams.get(visibleLayersQueryParamName)
        if (!visibleLayersCsv) return {}

        return visibleLayersCsv.split(",").reduce((map, obj) => {
            map[obj] = true
            return map
        }, {})
    }

    getSidebarStatusFromQueryParam = app => {
        const sidebarStatus = this.queryParams.get(sidebarStatusQueryParamName)
        return JSON.parse(sidebarStatus) ?? !app.sidebarCollapsed
    }

    loadStyles(app) {
        let layers = []
        let paintsMap = {}
        let layoutsMap = {}
        const zoomRangesMap = {}
        let visibleLayersIdsMap = this.getVisibleLayersFromQueryParam()

        const datasetsMap = app.maps.reduce((acc, map) => {
            map.datasets.forEach(dataset => {
                acc[dataset.id] = dataset
            })
            return acc
        }, {})

        let layerGroups = app.configJson.layerGroups

        if (this.queryParams.has(visibleLayersQueryParamName)) {
            layerGroups.forGroupsRecursive(group => {
                const hasVisibleLayer = group.layers.some(
                    layer => visibleLayersIdsMap[layer.resourceId] && !isGroup(layer),
                )

                group.options.collapsed = !hasVisibleLayer
            })
        }

        const layerVisibilityMap = {}
        const layerStylesMap = {}

        const currentZoomLevel = this.props.mainMap?.getZoom() || 1

        layerGroups.forLayersRecursive(layer => {
            layerVisibilityMap[layer.resourceId] = this.queryParams.has(visibleLayersQueryParamName)
                ? !!visibleLayersIdsMap[layer.resourceId]
                : layer.options.enabled

            layer.rowCount = datasetsMap[layer.resourceId]?.rowCount || 0

            let styleMinZoom = 24,
                styleMaxZoom = 0

            layer.styles.forEach(style => {
                const layerId = style.styleId
                if (style.minZoom < styleMinZoom) styleMinZoom = style.minZoom
                if (style.maxZoom > styleMaxZoom) styleMaxZoom = style.maxZoom
                layers.push({
                    layerId,
                    resourceId: layer.resourceId,
                    sourceId: layer.sourceId,
                    sourceName: layer.sourceName,
                    type: style.type,
                })
                const zoomRange = { layerId, maxZoom: style.maxZoom, minZoom: style.minZoom }

                zoomRangesMap[layerId] = zoomRange

                //Add any new paint/layout properties to style
                const properties = JSON.parse(JSON.stringify(this.props.styleConfig[style.type]))
                properties.forEach(property => {
                    let styleProperty = style.properties.find(x => x.name === property.name)
                    if (styleProperty) {
                        property.value = styleProperty.value
                        property.expressionType = styleProperty.expressionType || "none"
                    }
                })

                paintsMap[layerId] = {
                    layerId,
                    properties: properties.filter(x => x.type === "paint"),
                }

                layoutsMap[layerId] = {
                    datasetId: layer.resourceId,
                    layerId,
                    properties: [
                        ...properties.filter(x => x.type === "layout"),
                        makeVisibilityProperty(layerVisibilityMap[layer.resourceId]),
                    ],
                }
            })
            layerStylesMap[layer.resourceId] = layer.styles
            delete layer.styles
            layer.minZoom = styleMinZoom
            layer.maxZoom = styleMaxZoom

            layer.isShown =
                Math.floor(styleMinZoom) <= Math.floor(currentZoomLevel) &&
                Math.floor(currentZoomLevel) <= Math.floor(styleMaxZoom)
        })

        this.props.setLayerData({ layerGroups, layerStylesMap, layerVisibilityMap })
        this.props.initMapResources({ layers, layoutsMap, paintsMap, zoomRangesMap })
    }

    addProjections(projections) {
        proj4.defs(projections.map(projection => [projection.crs, projection.value]))
    }

    addSources(maps, rasters) {
        const sources = []

        maps.forEach(map => {
            let shouldReset = localStorage.getItem("cache_reset" + map.id) === null
            if (shouldReset) {
                caches.delete(map.id)
                localStorage.setItem("cache_reset" + map.id, "reset")
            }
            sources.push({
                bounds: this.polygonToBounds(map.bounds),
                id: map.id,
                maxZoom: map.maxZoom,
                minZoom: map.minZoom,
                type: "vector",
            })

            // TODO remove the datasets from sources once the sourceId issue in layers is fixed
            map.datasets.forEach(dataset => {
                if (!sources.some(s => s.id === dataset.id)) {
                    sources.push({
                        bounds: this.polygonToBounds(dataset.bounds),
                        id: dataset.id,
                        maxZoom: dataset.maxZoom,
                        minZoom: dataset.minZoom,
                        type: "vector",
                    })
                }
            })
        })

        rasters.forEach(raster => {
            let shouldReset = localStorage.getItem("cache_reset" + raster.id) === null
            if (shouldReset) {
                caches.delete(raster.id)
                localStorage.setItem("cache_reset" + raster.id, "reset")
            }
            sources.push({
                bounds: this.polygonToBounds(raster.bounds),
                id: raster.id,
                maxZoom: raster.maxZoom,
                minZoom: raster.minZoom,
                type: "raster",
            })
        })

        this.props.AISSubscriptionsInfo?.forEach(sub => {
            const source = mapSubToGeoJsonSource(sub.id, this.props.AISSubscriptionsData[sub.id]?.boats || [])
            sources.push(source)
        })
        this.props.addMapSources(sources)
    }

    polygonToBounds = poly => {
        const minLng = poly.coordinates[0][1][0]
        const minLat = poly.coordinates[0][1][1]

        const maxLng = poly.coordinates[0][3][0]
        const maxLat = poly.coordinates[0][3][1]

        return [minLng, maxLat, maxLng, minLat]
    }

    render() {
        const printEnabled = this.props.toggledWidgetsSet.has("print")
        return (
            <div className="main-grid main-view" id="grid">
                <div className="flex-container">
                    {!this.props.app?.public && <HeaderButtons className="map-header-buttons" userMenuIsCollapsed />}

                    <MainMap location={this.getMapLocationFromQueryParam()} />
                    {printEnabled && <Print />}
                    <PortalDiv />
                </div>

                <ToolsMenu />
                <SidebarRoot path={this.props.path} />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    AISSubscriptionsData: getAISSubscriptionData(state),
    AISSubscriptionsInfo: getAISSubscriptionInfo(state),
    app: getSelectedApp(state),
    styleConfig: getStyleConfig(state),
    widgets: getWidgets(state),
    toggledWidgetsSet: getToggledWidgetsSet(state),
})

const mapDispatchToProps = dispatch => {
    return {
        addMapSources: source => dispatch(mapActions.addSources(source)),
        clear: () => dispatch(clearMap()),
        getApp: (appid, isPublic) => dispatch(getApp(appid, isPublic)),
        initMapResources: mapResources => dispatch(mapActions.initMapResources(mapResources)),
        initMapSettings: mapSettings => dispatch(mapActions.initMapSettings(mapSettings)),
        resetMapFeatures: () => dispatch(printActions.resetMapFeatures()),
        setConfig: config => dispatch(setConfig(config)),
        setLayerData: ({ layerGroups, layerStylesMap, layerVisibilityMap }) =>
            dispatch(layerSelectorActions.setLayerData({ layerGroups, layerStylesMap, layerVisibilityMap })),
        setMapZoom: mapZoom => dispatch(layerSelectorActions.setMapZoom(mapZoom)),
        setRestrictedView: restrictedView => dispatch(mapActions.setRestrictedView(restrictedView)),
        setSidebarOpen: isSidebarOpen => dispatch(sidebarActions.setSidebarOpen(isSidebarOpen)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withMap(MapView))
