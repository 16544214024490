import * as types from "../actions/actionTypes"
import { clearMap, resetProjectData } from "../actions/globalActions"

const digitizeInit = {
    editing: false,
    features: [],
    infoHelp: true,
    layers: [],
    selectedFeature: null,
}

export function digitizeReducer(state = digitizeInit, action) {
    switch (action.type) {
        case clearMap.type:
            return {
                editing: false,
                features: [],
                infoHelp: true,
                layers: [],
                selectedFeature: null,
            }
        case types.DRAW_FEATURES_CREATED:
            return {
                ...state,
                features: [...state.features, ...action.result],
            }
        case types.DRAW_FEATURE_DELETED:
            return {
                ...state,
                features: removeFromArray(state.features, action.result),
            }
        case types.DRAW_FEATURE_EDITED:
            return {
                ...state,
                features: updateFeatureInArray(state.features, action.result),
            }
        case types.DRAW_FEATURES_RENAMED:
            return {
                ...state,
                features: updateInArray(state.features, action.result),
            }
        case types.SELECT_DIGITIZE_FEATURE:
            return {
                ...state,
                selectedFeature: action.result,
            }
        case types.ADD_DIGITIZE_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.result],
            }
        case types.DELETE_DIGITIZE_LAYER:
            return {
                ...state,
                layers: removeFromArray(state.layers, action.result),
            }
        case types.EDIT_DIGITIZE_LAYER:
            return {
                ...state,
                features: updateLayerNameInFeatures(state.features, action.result.features, action.result.name),
                layers: updateInArray(state.layers, action.result),
            }
        case types.DIGITIZE_EDITING:
            return {
                ...state,
                editing: action.result,
                infoHelp: action.result ? state.infoHelp : false,
            }
        case types.DIGITIZE_HELP:
            return {
                ...state,
                infoHelp: action.result,
            }
        case types.TOGGLE_DIGITIZE_LAYER:
            return {
                ...state,
                layers: state.layers.map(layer =>
                    layer.id === action.result.digitizeLayerId ? { ...layer, selected: action.result.selected } : layer,
                ),
            }
        case types.TOGGLE_ALL_DIGITIZE_LAYERS:
            return {
                ...state,
                layers: state.layers.map(layer => ({ ...layer, selected: action.result.activationState })),
            }
        case resetProjectData.type:
            return digitizeInit
        default:
            return state
    }
}

function removeFromArray(features, item) {
    let result = features.filter(feature => feature.id !== item.id)
    return result
}

function updateInArray(features, item) {
    let result = features.map(feature => (feature.id === item.id ? item : feature))
    return result
}

function updateFeatureInArray(features, item) {
    //We need to keep the old properties because some feature options come from the mapbox draw which doesn't contain all the necessary data.
    let result = features.map(feature =>
        feature.id === item.id ? { ...item, properties: { ...feature.properties, ...item.properties } } : feature,
    )
    return result
}

const updateLayerNameInFeatures = (features, featureIdsThatNeedUpdate, newName) => {
    return features.map(feature => {
        return featureIdsThatNeedUpdate.includes(feature.id)
            ? { ...feature, properties: { ...feature.properties, layerName: newName } }
            : feature
    })
}
