import * as React from "react";
const SvgAquarium11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "aquarium-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 1 C7.1243 1 6.1491 1.092 4.9961 1.5273 C3.8431 1.9622 2.8479 2.6569 2 3.5 C1.1477 4.3474 0 6 0 6.5 C0 7 1.1354 8.4426 2.6777 9.121099 C4.2201 9.7994995 5.0685997 9.919399 5.9609003 10.015599 C6.7577004 10.1016 7.9033003 9.988599 8.8457 9.783199 C9.5925 9.6205 10.9937 9.3099 11 9 C11 9 8.2439 8.937 8 8.5 C7.7514 8.0552 7.7506 6.9142 8 6.5 C8.258 6.0717 10.5 7.5 10.5 7.5 C11.1439 7.7576 11.1439 3.2424002 10.5 3.5 C10.5 3.5 8.2232 4.9474 8 4.5 C7.7506 4 7.7506 3 8 2.5 C8.2232 2.0526 11 2 11 2 C11 1.5 8.8757 1 8 1 z M3.5137 4.502 C4.0735 4.5021 4.5273 4.9558997 4.5274 5.5157 C4.5272 6.0754 4.0735 6.5292 3.5137 6.5293 C2.9539 6.5292 2.5001 6.0754 2.5 5.5156 C2.5001 4.9558 2.9539 4.5021 3.5137 4.502 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 1 C7.1243 1 6.1491 1.092 4.9961 1.5273 C3.8431 1.9622 2.8479 2.6569 2 3.5 C1.1477 4.3474 0 6 0 6.5 C0 7 1.1354 8.4426 2.6777 9.121099 C4.2201 9.7994995 5.0685997 9.919399 5.9609003 10.015599 C6.7577004 10.1016 7.9033003 9.988599 8.8457 9.783199 C9.5925 9.6205 10.9937 9.3099 11 9 C11 9 8.2439 8.937 8 8.5 C7.7514 8.0552 7.7506 6.9142 8 6.5 C8.258 6.0717 10.5 7.5 10.5 7.5 C11.1439 7.7576 11.1439 3.2424002 10.5 3.5 C10.5 3.5 8.2232 4.9474 8 4.5 C7.7506 4 7.7506 3 8 2.5 C8.2232 2.0526 11 2 11 2 C11 1.5 8.8757 1 8 1 z M3.5137 4.502 C4.0735 4.5021 4.5273 4.9558997 4.5274 5.5157 C4.5272 6.0754 4.0735 6.5292 3.5137 6.5293 C2.9539 6.5292 2.5001 6.0754 2.5 5.5156 C2.5001 4.9558 2.9539 4.5021 3.5137 4.502 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAquarium11;
