import React from "react"
import { Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
    header: {
        borderBottom: "2px solid #f5f7f8",
        padding: 16,
    },
    outputType: {
        "&:hover": {
            backgroundColor: "#dcdcdc",
        },
        backgroundColor: "#ececec",
        cursor: "pointer",
        padding: 8,
        textAlign: "center",
        width: "50%",
    },
    outputTypeSelected: {
        // border: '2px solid #0C567E',
        backgroundColor: "#9dbbcb",
        color: "#fff",
        padding: 8,
        textAlign: "center",
        width: "50%",
    },
    root: {
        background: "#fff",
    },
    section: {
        backgroundColor: "#fff",
        borderBottom: "2px solid #ececec",
        padding: 16,
    },
}))

const PrintOutput = ({ outputType, setOutputType }) => {
    const classes = useStyles()

    return (
        <Grid className={classes.root} container>
            <Grid item xs={12}>
                <Grid className={classes.section} container>
                    <Grid
                        className={outputType === "image" ? classes.outputTypeSelected : classes.outputType}
                        item
                        xs={6}
                        onClick={() => setOutputType("image")}
                    >
                        <Typography variant="subtitle1">Image</Typography>
                    </Grid>
                    <Grid
                        className={outputType === "pdf" ? classes.outputTypeSelected : classes.outputType}
                        item
                        xs={6}
                        onClick={() => setOutputType("pdf")}
                    >
                        <Typography variant="subtitle1">PDF</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PrintOutput
