import { useEffect, useRef } from "react"
import { useMap } from "@emblautec/react-map-gl"

type Props = {
    mapId: string
}

const MapResizeObserver = ({ mapId }: Props) => {
    const { current } = useMap()
    const isMounted = useRef(true)

    useEffect(() => {
        const ro = new ResizeObserver(resize)
        const mapElement = document.getElementById(mapId)
        ro.observe(mapElement!)

        return () => {
            ro.disconnect()
            isMounted.current = false
        }
    }, [])

    const resize = () => {
        if (!isMounted.current) return

        requestAnimationFrame(resize)
        current?.resize()
    }

    return null
}
export default MapResizeObserver
