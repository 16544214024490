import React, { useEffect, useState } from "react"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import { Select, IconButton, Tooltip, Menu, FormControl, InputLabel, Dialog, FormHelperText, Box } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import gradients from "../constants/gradients"
import { PropertyStyleTypes } from "../constants/propertyStyleTypes"
import { useStylerContext } from "../hooks/useStylerContext"
import * as ColorUtils from "../utils/colorUtils"
import { isExpressionProperty } from "../utils/helpers/isExpressionProperty"

const defaultColorRamp = ["#d7191c", "#fdae61", "#ffffbf", "#abdda4", "#2b83ba"]

const GraduateModal = ({ open, property, onClose, onFinish }) => {
    const [minValue, setMinValue] = useState(0.0)
    const [maxValue, setMaxValue] = useState(0.0)
    const [stepsHasError, setStepsHasError] = useState(false)
    const [stepValues, setStepValues] = useState([])
    const [colorRamp, setColorRamp] = useState([...defaultColorRamp])
    const [colorRampMenuAnchor, setColorRampMenuAnchor] = useState(null)

    const {
        columns,
        initSharedExpression,
        keysArray,
        minMaxColumnValues,
        selectedDatasetColumn,
        sharedExpressionType,
        setSelectedDatasetColumn,
    } = useStylerContext()

    const [stepsNumber, setStepsNumber] = useState(5)

    useEffect(() => {
        const minValue = minMaxColumnValues[0]
        const maxValue = minMaxColumnValues[1]
        if (minValue !== undefined && maxValue !== undefined) {
            const values = calculateSteps(stepsNumber, minValue, maxValue)
            setStepValues(values)
            setMinValue(minValue)
            setMaxValue(maxValue)
        } else {
            setStepValues([])
            setMinValue(0.0)
            setMaxValue(0.0)
        }
    }, [minMaxColumnValues[0], minMaxColumnValues[1], stepsNumber])

    useEffect(() => {
        const sharedExpressionInitted = sharedExpressionType === PropertyStyleTypes.Graduate
        const followsSharedKeys = sharedExpressionInitted && !isExpressionProperty(property)

        const values = followsSharedKeys
            ? generateValuesBasedOnSteps(keysArray)
            : calculateSteps(stepsNumber, minValue, maxValue)

        setStepValues(values)
    }, [colorRamp])

    useEffect(() => {
        if (keysArray !== null) {
            const values = generateValuesBasedOnSteps(keysArray)
            setStepValues(values)
        } else {
            // Reset to default
            const values = calculateSteps(stepsNumber, minValue, maxValue)
            setStepValues(values)
        }
    }, [keysArray])

    const onFinished = () => {
        if (!selectedDatasetColumn) return

        const sharedExpressionInitted = sharedExpressionType === PropertyStyleTypes.Graduate

        const followsSharedKeys = sharedExpressionInitted && !isExpressionProperty(property)

        const interpolateArray = ["interpolate", ["linear"], ["get", selectedDatasetColumn.name]]

        stepValues.forEach(step => {
            interpolateArray.push(parseFloat(step.value), step.color)
        })

        // If the shared expression wasn't initialized we initialize it.
        if (!followsSharedKeys) {
            initSharedExpression(
                stepValues.map(sv => sv.value),
                PropertyStyleTypes.Graduate,
                selectedDatasetColumn.name,
            )
        }
        onClose()
        onFinish(interpolateArray)
    }

    const onColumnChange = column => {
        setSelectedDatasetColumn(column)
    }

    const generateValuesBasedOnSteps = stepsArray => {
        // generate original amount of steps because the expression could have steps that aren't
        // evenly spaced out
        const colors = ColorUtils.interpolateColorArray(colorRamp, stepsNumber)
        const singleStepValue = Math.abs(minValue - maxValue) / (stepsNumber - 1)

        return stepsArray.map(stepAsString => {
            const step = parseFloat(stepAsString)

            let colorIndex = Math.abs(parseInt(step / singleStepValue))
            colorIndex = colorIndex > colors.length - 1 ? colors.length - 1 : colorIndex
            return {
                color: colors[colorIndex],
                value: step,
            }
        })
    }

    const calculateSteps = (steps, minValue, maxValue) => {
        const singleStepValue = Math.abs(minValue - maxValue) / (steps - 1)

        const colors = ColorUtils.interpolateColorArray(colorRamp, steps)

        const stepValues = []

        //Add min value
        stepValues.push({
            color: colors[0],
            value: minValue.toFixed(2),
        })

        for (let i = 1; i < steps - 1; i++) {
            stepValues.push({
                color: colors[i],
                value: (singleStepValue * i + minValue).toFixed(2),
            })
        }

        //Add max value
        stepValues.push({
            color: colors[steps - 1],
            value: maxValue.toFixed(2),
        })

        return stepValues
    }

    const onStepsChange = e => {
        const newSteps = parseInt(e.target.value)

        if (newSteps < 2 || newSteps > 100) {
            setStepsHasError(true)
            setStepsNumber(newSteps)
            return
        }

        const values = calculateSteps(newSteps, minValue, maxValue)
        setStepsHasError(false)
        setStepsNumber(newSteps)
        setStepValues(values)

        return false
    }

    const validColumnType = columnType => {
        switch (columnType.toLowerCase()) {
            case "smallint":
            case "integer":
            case "bigint":
            case "decimal":
            case "numeric":
            case "real":
            case "double precision":
            case "serial":
            case "bigserial":
                return true
            default:
                return false
        }
    }

    const onOpenColorRampMenu = e => {
        setColorRampMenuAnchor(e.target)
    }

    const onCloseColorRampMenu = () => {
        setColorRampMenuAnchor(null)
    }

    const onInvert = () => {
        setColorRamp([...colorRamp.reverse()])
    }

    const onColorRampSelected = colorRamp => {
        setColorRamp(colorRamp.values)
        onCloseColorRampMenu()
    }

    const renderColumns = () =>
        columns
            .filter(x => validColumnType(x.type))
            .map((item, index) => {
                return (
                    <MenuItem
                        key={index}
                        className="menu-item-flex"
                        value={item.prettyName}
                        onClick={() => onColumnChange(item)}
                    >
                        <div className="name">{item.prettyName}</div>
                        <span className="type">{item.type}</span>
                    </MenuItem>
                )
            })

    const values = stepValues.map((step, index) => {
        return (
            <div key={index} className="step-container">
                <div className="step-value">{step.value}</div>
                <div className="step-color" style={{ backgroundColor: step.color }}></div>
            </div>
        )
    })

    const colorRamps = gradients.map((gradient, index) => {
        const gradientColors = gradient.values.join(",")

        return (
            <MenuItem key={index} onClick={() => onColorRampSelected(gradient)}>
                <Typography className="color-ramp-name" variant="body2">
                    {gradient.name}
                </Typography>
                <div
                    className="color-ramp-gradient"
                    style={{ background: `linear-gradient(to right,${gradientColors})` }}
                ></div>
            </MenuItem>
        )
    })

    const customizationRestricted = sharedExpressionType !== PropertyStyleTypes.None && !isExpressionProperty(property)

    return (
        <Dialog
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="atlas-dialog graduate-style-modal"
            closeAfterTransition
            maxWidth={"xs"}
            open={open}
            onClose={onClose}
        >
            <div className="dialog-header">
                <Typography className="dialog-title" variant="h6">
                    Graduate
                </Typography>

                <Button color="primary" variant="contained" disabled={minValue === maxValue} onClick={onFinished}>
                    <SaveIcon style={{ marginLeft: -8, marginRight: 8 }} />
                    Update
                </Button>
            </div>
            <div className="container">
                <div className="page">
                    {!customizationRestricted && (
                        <>
                            <div className="section">
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="schema-label" disabled={customizationRestricted}>
                                        Column
                                    </InputLabel>
                                    <Select
                                        className="column-select"
                                        fullWidth
                                        label="Column"
                                        labelId="schema-label"
                                        value={selectedDatasetColumn ? selectedDatasetColumn.prettyName : ""}
                                        disabled={customizationRestricted}
                                    >
                                        {renderColumns()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="steps-container">
                                <TextField
                                    fullWidth
                                    id="full-width"
                                    inputProps={{ autoComplete: "no" }}
                                    label="Steps"
                                    margin="none"
                                    placeholder="Number of steps in graduation"
                                    type="number"
                                    value={stepsNumber}
                                    variant="outlined"
                                    disabled={customizationRestricted}
                                    onChange={onStepsChange}
                                />
                                {stepsHasError && (
                                    <FormHelperText error>
                                        The number of steps needs to be in the range 2-100
                                    </FormHelperText>
                                )}
                            </div>
                        </>
                    )}

                    <div className="actions">
                        <Tooltip title="invert">
                            <IconButton size="large" onClick={onInvert}>
                                <CachedOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Color Ramps">
                            <IconButton size="large" onClick={onOpenColorRampMenu}>
                                <PaletteOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={colorRampMenuAnchor}
                            id="simple-menu"
                            keepMounted
                            open={Boolean(colorRampMenuAnchor)}
                            onClose={onCloseColorRampMenu}
                        >
                            {colorRamps}
                        </Menu>
                    </div>
                    {minValue !== maxValue ? (
                        <div className="scroll-container">{values}</div>
                    ) : (
                        <Box paddingY={6}>
                            <Typography align="center" variant="h6">
                                Can't graduate on columns that have a constant value
                            </Typography>
                        </Box>
                    )}
                </div>
            </div>
        </Dialog>
    )
}

export default GraduateModal
