export function registerTileCache() {
    if (!("serviceWorker" in navigator)) return

    window.addEventListener("load", function () {
        navigator.serviceWorker
            .register("/tile-cache-sw.js", { scope: "/" })
            .then(registration => {
                if (!import.meta.env.PROD) {
                    console.log("Tile cache ServiceWorker registration successful with scope: ", registration.scope)
                }
            })
            .catch(err => {
                if (!import.meta.env.PROD) {
                    console.log("Tile cache ServiceWorker registration failed: ", err)
                }
            })
    })
}
