import { createApi } from "@reduxjs/toolkit/query/react"
import config from "../config"
import axiosBaseQuery from "./customBaseQuery"

const baseAtlasApi = createApi({
    baseQuery: axiosBaseQuery(config.apiUrl),
    endpoints: () => ({}),
})

export default baseAtlasApi

export const resetApiState = baseAtlasApi.util?.resetApiState
