import * as React from "react";
const SvgShop11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "shop-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.6 4 L8.49 4 L8.2 2.27 C8.0832 1.7303 7.6738 1.3015 7.14 1.16 C6.8794 1.0669 6.6064 1.013 6.33 1 L4.67 1 C4.3979 1.0075 4.1284 1.0547 3.8700001 1.14 C3.3269 1.2815 2.9117 1.7199 2.8 2.27 L2.51 4 L1.34 4 C1.1357 4.0055 0.9746 4.1755 0.9801 4.3798 C0.9811 4.4173 0.9878 4.4545 1 4.49 L2.21 9.19 L2.21 9.19 C2.3454 9.6605 2.7706 9.9885 3.26 10 L7.74 10 C8.2331 9.9928 8.6635 9.6639 8.8 9.19 L8.8 9.19 L10 4.49 C10.0682 4.2974 9.9674 4.0859 9.7748 4.0176997 C9.7188 3.9978 9.6588 3.9918 9.6 4 z M3.27 4 L3.54 2.3899999 C3.601 2.1211998 3.8181999 1.9158999 4.09 1.8699999 C4.2746 1.8108999 4.4663 1.7771999 4.6600003 1.7699999 L6.3300004 1.7699999 C6.5237 1.7771999 6.7154 1.8108999 6.9000006 1.8699999 C7.1718006 1.9158999 7.3890004 2.1211998 7.450001 2.3899999 L7.73 4 L3.27 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.6 4 L8.49 4 L8.2 2.27 C8.0832 1.7303 7.6738 1.3015 7.14 1.16 C6.8794 1.0669 6.6064 1.013 6.33 1 L4.67 1 C4.3979 1.0075 4.1284 1.0547 3.8700001 1.14 C3.3269 1.2815 2.9117 1.7199 2.8 2.27 L2.51 4 L1.34 4 C1.1357 4.0055 0.9746 4.1755 0.9801 4.3798 C0.9811 4.4173 0.9878 4.4545 1 4.49 L2.21 9.19 L2.21 9.19 C2.3454 9.6605 2.7706 9.9885 3.26 10 L7.74 10 C8.2331 9.9928 8.6635 9.6639 8.8 9.19 L8.8 9.19 L10 4.49 C10.0682 4.2974 9.9674 4.0859 9.7748 4.0176997 C9.7188 3.9978 9.6588 3.9918 9.6 4 z M3.27 4 L3.54 2.3899999 C3.601 2.1211998 3.8181999 1.9158999 4.09 1.8699999 C4.2746 1.8108999 4.4663 1.7771999 4.6600003 1.7699999 L6.3300004 1.7699999 C6.5237 1.7771999 6.7154 1.8108999 6.9000006 1.8699999 C7.1718006 1.9158999 7.3890004 2.1211998 7.450001 2.3899999 L7.73 4 L3.27 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgShop11;
