import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    headerButtons: {
        alignItems: "center",
        display: "flex",
        gap: 12,
        height: 48,
        position: "absolute",
        right: 0,
        zIndex: 1,
    },
}))
