import * as React from "react";
const SvgEmbassy15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "embassy-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.65 2 C5.43 2 4.48 3.38 4.11 3.82 C4.0365 3.9102 3.9975 4.0237 4 4.14 L4 8.54 C3.9884 8.7827 4.1758 8.9889 4.4185 9.0005 C4.528 9.0057 4.6355 8.9699 4.72 8.9 C5.1864996 8.2736 5.8788996 7.8538995 6.6499996 7.7299995 C8.06 7.73 8.6 9 10.07 9 C11.064799 8.9024 12.011499 8.5244 12.799999 7.91 C12.927199 7.8166 13.001599 7.6678 12.999999 7.5099998 L12.999999 2.45 C13.027499 2.2086 12.854099 1.9905 12.612599 1.963 C12.5332 1.954 12.4527 1.9668 12.38 2 C11.6987 2.5212 10.9094 2.8834 10.07 3.06 C8.6 3.08 8.12 2 6.65 2 z M2.5 3 C1.9477 3 1.5 2.5523 1.5 2 C1.5 1.4477 1.9477 1 2.5 1 C3.0523 1 3.5 1.4477 3.5 2 C3.5 2.5523 3.0523 3 2.5 3 z M3 4 L3 13.48 C3 13.7561 2.7761 13.98 2.5 13.98 C2.2239 13.98 2 13.7561 2 13.48 L2 4 C2 3.7239 2.2239 3.5 2.5 3.5 C2.7761 3.5 3 3.7239 3 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.65 2 C5.43 2 4.48 3.38 4.11 3.82 C4.0365 3.9102 3.9975 4.0237 4 4.14 L4 8.54 C3.9884 8.7827 4.1758 8.9889 4.4185 9.0005 C4.528 9.0057 4.6355 8.9699 4.72 8.9 C5.1864996 8.2736 5.8788996 7.8538995 6.6499996 7.7299995 C8.06 7.73 8.6 9 10.07 9 C11.064799 8.9024 12.011499 8.5244 12.799999 7.91 C12.927199 7.8166 13.001599 7.6678 12.999999 7.5099998 L12.999999 2.45 C13.027499 2.2086 12.854099 1.9905 12.612599 1.963 C12.5332 1.954 12.4527 1.9668 12.38 2 C11.6987 2.5212 10.9094 2.8834 10.07 3.06 C8.6 3.08 8.12 2 6.65 2 z M2.5 3 C1.9477 3 1.5 2.5523 1.5 2 C1.5 1.4477 1.9477 1 2.5 1 C3.0523 1 3.5 1.4477 3.5 2 C3.5 2.5523 3.0523 3 2.5 3 z M3 4 L3 13.48 C3 13.7561 2.7761 13.98 2.5 13.98 C2.2239 13.98 2 13.7561 2 13.48 L2 4 C2 3.7239 2.2239 3.5 2.5 3.5 C2.7761 3.5 3 3.7239 3 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgEmbassy15;
