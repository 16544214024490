import * as React from "react";
const SvgLodging15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "lodging-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0.5 2.5 C0.2 2.5 0 2.7 0 3 L0 10.5 L0 12.5 C0 12.8 0.2 13 0.5 13 C0.8 13 1 12.8 1 12.5 L1 11 L14 11 L14 12.5 C14 12.8 14.2 13 14.5 13 C14.8 13 15 12.8 15 12.5 L15 10.5 C15 10.2 14.8 10 14.5 10 L1 10 L1 3 C1 2.7 0.8 2.5 0.5 2.5 z M3.5 3 C2.7 3 2 3.7 2 4.5 L2 4.5 C2 5.3 2.7 6 3.5 6 L3.5 6 C4.3 6 5 5.3 5 4.5 L5 4.5 C5 3.7 4.3 3 3.5 3 L3.5 3 z M7 4 C5.5 4 5.5 5.5 5.5 5.5 L5.5 7 L2.5 7 C2.2 7 2 7.2 2 7.5 L2 8.5 C2 8.8 2.2 9 2.5 9 L6 9 L15 9 L15 6.5 C15 4 12.5 4 12.5 4 L7 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0.5 2.5 C0.2 2.5 0 2.7 0 3 L0 10.5 L0 12.5 C0 12.8 0.2 13 0.5 13 C0.8 13 1 12.8 1 12.5 L1 11 L14 11 L14 12.5 C14 12.8 14.2 13 14.5 13 C14.8 13 15 12.8 15 12.5 L15 10.5 C15 10.2 14.8 10 14.5 10 L1 10 L1 3 C1 2.7 0.8 2.5 0.5 2.5 z M3.5 3 C2.7 3 2 3.7 2 4.5 L2 4.5 C2 5.3 2.7 6 3.5 6 L3.5 6 C4.3 6 5 5.3 5 4.5 L5 4.5 C5 3.7 4.3 3 3.5 3 L3.5 3 z M7 4 C5.5 4 5.5 5.5 5.5 5.5 L5.5 7 L2.5 7 C2.2 7 2 7.2 2 7.5 L2 8.5 C2 8.8 2.2 9 2.5 9 L6 9 L15 9 L15 6.5 C15 4 12.5 4 12.5 4 L7 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLodging15;
