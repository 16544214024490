import makeStyles from "@mui/styles/makeStyles"
import appStyles from "utils/styles"

export const useNavStyles = makeStyles(() => ({
    actionButton: {
        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0,
        },
        alignItems: "center",
        borderRadius: 0,
        color: "white",
        display: "flex",
        fontSize: 14,
        height: 56,
        justifyContent: "center",
        padding: 0,
        textTransform: "none",
        width: "100%",
    },
    actionButtonActive: {
        backgroundColor: "hsla(0,0%,100%,.04)",
    },
    actionButtonInactive: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        opacity: 0.7,
    },
    navOpen: {
        justifyContent: "flex-start",
    },
    toolIcon: {
        alignItems: "center",
        color: "white",
        display: "flex",
        height: 30,
        justifyContent: "center",
        width: 60,
    },
    toolIconClosed: {
        color: "white",
        display: "flex",
    },
    toolLabel: {
        alignItems: "center",
        color: "white",
        display: "flex",
        fontSize: 14,
    },
}))
