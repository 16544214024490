import * as React from "react";
const SvgGarden15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "garden-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13 8 C13 11.309999 10.809999 14 7.5 14 C4.1900005 14 2 11.31 2 8 C4.2643003 8.0191 6.2694 9.4667 7 11.61 L7 7 L4.5 7 C3.6716 7 3 6.3284 3 5.5 L3 2.5 C3 2.2239 3.2239 2 3.5 2 C3.6574 2 3.8056 2.0741 3.9 2.2 L5.4300003 4.2 L7.0800004 1.1999998 C7.2298 0.9679998 7.5393004 0.9014998 7.7713003 1.0512998 C7.8308 1.0898 7.8815 1.1404 7.92 1.2 L9.57 4.2 L11.099999 2.1999998 C11.265699 1.9790998 11.5791 1.9342998 11.799999 2.1 C11.9259 2.1944 12 2.3426 12 2.5 L12 5.5 C12 6.3284 11.3284 7 10.5 7 L8 7 L8 11.610001 C8.7306 9.4667 10.7357 8.0191 13 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13 8 C13 11.309999 10.809999 14 7.5 14 C4.1900005 14 2 11.31 2 8 C4.2643003 8.0191 6.2694 9.4667 7 11.61 L7 7 L4.5 7 C3.6716 7 3 6.3284 3 5.5 L3 2.5 C3 2.2239 3.2239 2 3.5 2 C3.6574 2 3.8056 2.0741 3.9 2.2 L5.4300003 4.2 L7.0800004 1.1999998 C7.2298 0.9679998 7.5393004 0.9014998 7.7713003 1.0512998 C7.8308 1.0898 7.8815 1.1404 7.92 1.2 L9.57 4.2 L11.099999 2.1999998 C11.265699 1.9790998 11.5791 1.9342998 11.799999 2.1 C11.9259 2.1944 12 2.3426 12 2.5 L12 5.5 C12 6.3284 11.3284 7 10.5 7 L8 7 L8 11.610001 C8.7306 9.4667 10.7357 8.0191 13 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGarden15;
