import * as React from "react";
const SvgAmusementPark15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "amusement-park-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C3.919 0 1 2.919 1 6.5 C1 8.8161 2.2251 10.8484 4.0566 12 L4 12 L3 14 L12 14 L11 12 L10.9434 12 C12.7749 10.8484 14 8.8161 14 6.5 C14 2.919 11.081 0 7.5 0 z M7.375 1.5059 L7.375 5.0059 C7.0642 5.0319 6.7693 5.1541 6.5312 5.3554997 L4.0566 2.8809 C4.9243 2.0555 6.0851 1.5376 7.375 1.5059 z M7.625 1.5059 C8.9149 1.5376 10.0757 2.0555 10.943399 2.8809 L8.4688 5.3555 C8.4681 5.3548 8.4675 5.3542004 8.4668 5.3535004 C8.229 5.1532 7.9348 5.0317 7.625 5.0059 L7.625 1.5059 z M3.8809 3.0566 L6.3555 5.5312004 C6.3548 5.5319004 6.3542004 5.5325003 6.3535004 5.5332003 C6.1532 5.771 6.0317 6.0652 6.0059 6.375 L2.5059 6.375 C2.5376 5.0851 3.0555 3.9243 3.8809 3.0566 z M11.1191 3.0566 C11.9445 3.9242 12.4623995 5.0851 12.4941 6.375 L8.9941 6.375 C8.9681 6.0642 8.8459 5.7693 8.6445 5.5312 L11.1191 3.0566 z M2.5059 6.625 L6.0059 6.625 C6.0319 6.9358 6.1541 7.2307 6.3554997 7.4688 L3.8809 9.9434 C3.0555 9.0757 2.5376 7.9149 2.5059 6.625 z M8.9941 6.625 L12.4941 6.625 C12.4623995 7.9149 11.9445 9.0757 11.1191 9.943399 L8.6445 7.4688 C8.6452 7.4681 8.6458 7.4675 8.6465 7.4668 C8.8468 7.229 8.9683 6.9348 8.9941 6.625 z M6.5312 7.6445 C6.5319 7.6452 6.5325 7.6457996 6.5332 7.6464996 C6.6716 7.7624 6.8297 7.8524 7 7.9121 L7 11.4746 C5.8597 11.3622 4.8394003 10.8638 4.0566 10.1191 L6.5312 7.6445 z M8.4688 7.6445 L10.943399 10.1191 C10.1606 10.8638 9.1404 11.3621 7.9999995 11.4746 L7.9999995 7.9121 C8.1711 7.852 8.33 7.7613 8.4688 7.6445 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C3.919 0 1 2.919 1 6.5 C1 8.8161 2.2251 10.8484 4.0566 12 L4 12 L3 14 L12 14 L11 12 L10.9434 12 C12.7749 10.8484 14 8.8161 14 6.5 C14 2.919 11.081 0 7.5 0 z M7.375 1.5059 L7.375 5.0059 C7.0642 5.0319 6.7693 5.1541 6.5312 5.3554997 L4.0566 2.8809 C4.9243 2.0555 6.0851 1.5376 7.375 1.5059 z M7.625 1.5059 C8.9149 1.5376 10.0757 2.0555 10.943399 2.8809 L8.4688 5.3555 C8.4681 5.3548 8.4675 5.3542004 8.4668 5.3535004 C8.229 5.1532 7.9348 5.0317 7.625 5.0059 L7.625 1.5059 z M3.8809 3.0566 L6.3555 5.5312004 C6.3548 5.5319004 6.3542004 5.5325003 6.3535004 5.5332003 C6.1532 5.771 6.0317 6.0652 6.0059 6.375 L2.5059 6.375 C2.5376 5.0851 3.0555 3.9243 3.8809 3.0566 z M11.1191 3.0566 C11.9445 3.9242 12.4623995 5.0851 12.4941 6.375 L8.9941 6.375 C8.9681 6.0642 8.8459 5.7693 8.6445 5.5312 L11.1191 3.0566 z M2.5059 6.625 L6.0059 6.625 C6.0319 6.9358 6.1541 7.2307 6.3554997 7.4688 L3.8809 9.9434 C3.0555 9.0757 2.5376 7.9149 2.5059 6.625 z M8.9941 6.625 L12.4941 6.625 C12.4623995 7.9149 11.9445 9.0757 11.1191 9.943399 L8.6445 7.4688 C8.6452 7.4681 8.6458 7.4675 8.6465 7.4668 C8.8468 7.229 8.9683 6.9348 8.9941 6.625 z M6.5312 7.6445 C6.5319 7.6452 6.5325 7.6457996 6.5332 7.6464996 C6.6716 7.7624 6.8297 7.8524 7 7.9121 L7 11.4746 C5.8597 11.3622 4.8394003 10.8638 4.0566 10.1191 L6.5312 7.6445 z M8.4688 7.6445 L10.943399 10.1191 C10.1606 10.8638 9.1404 11.3621 7.9999995 11.4746 L7.9999995 7.9121 C8.1711 7.852 8.33 7.7613 8.4688 7.6445 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAmusementPark15;
