import * as React from "react";
const SvgToilet11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "toilet-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.33 2.19 C2.7224998 2.19 2.23 1.6975 2.23 1.09 C2.23 0.48250008 2.7225 -0.00999999 3.33 -0.00999999 C3.9374998 -0.00999999 4.43 0.48250002 4.43 1.09 L4.43 1.09 C4.43 1.6975 3.9375 2.19 3.33 2.19 z M10.27 1.09 C10.27 0.48250002 9.7775 -0.00999999 9.17 -0.00999999 C8.5625 -0.00999999 8.07 0.48250002 8.07 1.09 C8.07 1.6975 8.5625 2.19 9.17 2.19 C9.7736 2.19 10.2645 1.7036 10.27 1.1 L10.27 1.09 z M6.51 4.93 L6.51 4.93 L4.7 3.12 L4.7 3.12 C4.6306 3.0442 4.5328 3.0008 4.43 3 L2.22 3 C2.1271 3.0008 2.0378 3.0365 1.97 3.1 L2 3.1 L0.14 4.93 C-0.030900002 5.0518 -0.070700005 5.2890997 0.0511 5.46 C0.1729 5.6309004 0.4102 5.6707 0.5811 5.5489 C0.6155 5.5244 0.6455 5.4944 0.67 5.46 L2.25 3.88 L0.77 8 L2.23 8 L2.23 10.51 C2.2245 10.5465 2.2245 10.5835 2.23 10.62 C2.2639 10.8271 2.4593 10.9675 2.6664 10.933599 C2.8274 10.9072 2.9536 10.781 2.98 10.62 L3 10.62 L3 8 L3.69 8 L3.69 10.63 L3.69 10.63 C3.7239 10.8371 3.9193 10.9775 4.1264 10.9436 C4.2874 10.9172 4.4136 10.791 4.44 10.63 C4.4455 10.5935 4.4455 10.5565 4.44 10.52 L4.44 8 L5.88 8 L4.41 3.88 L6 5.46 L6 5.46 C6.0718 5.5383 6.1738 5.5821 6.28 5.58 C6.4899 5.58 6.6600003 5.4098997 6.6600003 5.2 L6.6600003 5.2 C6.6518 5.0924 6.597 4.9938 6.51 4.93 z M8.62 7 L8.62 10.63 C8.5865 10.8316 8.7228 11.0221 8.9244 11.0556 C9.126 11.0891 9.316501 10.9528 9.35 10.7512 C9.3567 10.7111 9.3567 10.670099 9.35 10.63 L9.35 7 L11 7 L11 3.37 C11 3.1657 10.8343 3 10.63 3 L7.71 3 C7.5057 3 7.34 3.1657 7.34 3.37 L7.34 7 L8.62 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.33 2.19 C2.7224998 2.19 2.23 1.6975 2.23 1.09 C2.23 0.48250008 2.7225 -0.00999999 3.33 -0.00999999 C3.9374998 -0.00999999 4.43 0.48250002 4.43 1.09 L4.43 1.09 C4.43 1.6975 3.9375 2.19 3.33 2.19 z M10.27 1.09 C10.27 0.48250002 9.7775 -0.00999999 9.17 -0.00999999 C8.5625 -0.00999999 8.07 0.48250002 8.07 1.09 C8.07 1.6975 8.5625 2.19 9.17 2.19 C9.7736 2.19 10.2645 1.7036 10.27 1.1 L10.27 1.09 z M6.51 4.93 L6.51 4.93 L4.7 3.12 L4.7 3.12 C4.6306 3.0442 4.5328 3.0008 4.43 3 L2.22 3 C2.1271 3.0008 2.0378 3.0365 1.97 3.1 L2 3.1 L0.14 4.93 C-0.030900002 5.0518 -0.070700005 5.2890997 0.0511 5.46 C0.1729 5.6309004 0.4102 5.6707 0.5811 5.5489 C0.6155 5.5244 0.6455 5.4944 0.67 5.46 L2.25 3.88 L0.77 8 L2.23 8 L2.23 10.51 C2.2245 10.5465 2.2245 10.5835 2.23 10.62 C2.2639 10.8271 2.4593 10.9675 2.6664 10.933599 C2.8274 10.9072 2.9536 10.781 2.98 10.62 L3 10.62 L3 8 L3.69 8 L3.69 10.63 L3.69 10.63 C3.7239 10.8371 3.9193 10.9775 4.1264 10.9436 C4.2874 10.9172 4.4136 10.791 4.44 10.63 C4.4455 10.5935 4.4455 10.5565 4.44 10.52 L4.44 8 L5.88 8 L4.41 3.88 L6 5.46 L6 5.46 C6.0718 5.5383 6.1738 5.5821 6.28 5.58 C6.4899 5.58 6.6600003 5.4098997 6.6600003 5.2 L6.6600003 5.2 C6.6518 5.0924 6.597 4.9938 6.51 4.93 z M8.62 7 L8.62 10.63 C8.5865 10.8316 8.7228 11.0221 8.9244 11.0556 C9.126 11.0891 9.316501 10.9528 9.35 10.7512 C9.3567 10.7111 9.3567 10.670099 9.35 10.63 L9.35 7 L11 7 L11 3.37 C11 3.1657 10.8343 3 10.63 3 L7.71 3 C7.5057 3 7.34 3.1657 7.34 3.37 L7.34 7 L8.62 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgToilet11;
