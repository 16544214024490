import * as React from "react";
const SvgReligiousChristian15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "religious-christian-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 0.9552 L6 4 L3 4 L3 7 L6 7 L6 15 L9 15 L9 7 L12 7 L12 4 L9 4 L9 1 C9 0 8.0224 0 8.0224 0 L6.9887 0 C6.9887 0 6 0 6 0.9552 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 0.9552 L6 4 L3 4 L3 7 L6 7 L6 15 L9 15 L9 7 L12 7 L12 4 L9 4 L9 1 C9 0 8.0224 0 8.0224 0 L6.9887 0 C6.9887 0 6 0 6 0.9552 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgReligiousChristian15;
