import toastr from "./customToastr"

export function handleError(err) {
    switch (err.response.status) {
        case 403:
            toastr.error("You do not have the required permission")
            break
        case 400:
            const { error, errors } = err.response.data
            if (error) {
                toastr.error(error)
            }
            if (errors) {
                //errors looks like this - errors: {ProperyNameWithErrors: ["Error1","Error2"]}
                const firstErrorMessage = errors[Object.keys(errors)[0]][0]
                toastr.error(firstErrorMessage)
            }
            break
        case 405:
            toastr.error(err.response.data)
            break
        default:
            toastr.error(err.message)
            break
    }
}
