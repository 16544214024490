// example "attachment; filename=cable_data2123.geojson; filename*=UTF-8''cable_data2123.geojson"
export const getFilenameFromContentDisposition = (contentDisposition: string) => {
    return (
        contentDisposition
            .split(";")
            .find(x => x.includes("filename="))
            ?.trim()
            .split("filename=")?.[1] ?? "data"
    )
}
