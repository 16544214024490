import React, { createContext, useEffect, useState } from "react"
import { PropertyStyleTypes } from "../constants/propertyStyleTypes"
import { isColorProperty } from "../utils/helpers/isColorProperty"
import { isZoomDependent } from "../utils/helpers/isZoomDependent"

export const StylerContext = createContext({ styleConfig: {} })

export const StylerContextProvider = ({ children, stylerProps }) => {
    const { layer, styleConfig, onPropertiesChanged } = stylerProps

    const [sharedColumnName, setSharedColumnName] = useState(null)
    const [keysArray, setKeysArray] = useState(null)
    const [sharedExpressionType, setSharedExpressionType] = useState(PropertyStyleTypes.None)

    useEffect(() => {
        checkForSharedExpression()
    }, [])

    const extractKeysArray = (valuesArray, offset) => {
        const keysArray = []
        // We substract 1 in order not to include the default value for match.
        // This shouldn't matter for the interpolate since we only exclude a value,
        // not a key
        for (let i = offset; i < valuesArray.length - 1; i += 2) {
            keysArray.push(valuesArray[i])
        }
        return keysArray
    }

    const checkForSharedExpression = () => {
        for (let si = 0; si < layer.styles.length; si++) {
            const style = layer.styles[si]

            for (let pi = 0; pi < style.properties.length; pi++) {
                const p = style.properties[pi]
                if (p.expressionType !== "none" && isColorProperty(p)) {
                    let offset,
                        keysArray = [],
                        expressionType
                    switch (p.expressionType) {
                        case "match":
                            offset = 2
                            expressionType = PropertyStyleTypes.Categorize
                            break
                        case "interpolate":
                            offset = 3
                            expressionType = isZoomDependent(p.value)
                                ? PropertyStyleTypes.ZoomDependent
                                : PropertyStyleTypes.Graduate
                            break
                        default:
                            continue
                    }

                    const columnName = p.value[offset - 1]?.[1] || null
                    setSharedColumnName(columnName)
                    keysArray = extractKeysArray(p.value, offset)
                    setKeysArray(keysArray)
                    setSharedExpressionType(expressionType)
                    return // We init with the first style assuming that the others will be synced
                }
            }
        }
    }

    const resetSharedExpression = () => {
        layer.styles.forEach(s => {
            let propertiesChanged = false
            const newProperties = s.properties.map(p => {
                // This means it was synced to the shared expression
                if (p.expressionType !== "none" && isColorProperty(p)) {
                    propertiesChanged = true

                    const newPropertyValue = styleConfig[s.type].find(x => x.name === p.name).value
                    return { ...p, expressionType: "none", value: newPropertyValue }
                }
                return p
            })
            if (propertiesChanged) onPropertiesChanged(s, newProperties)
        })
        setKeysArray(null)
        setSharedColumnName(null)
        setSharedExpressionType(PropertyStyleTypes.None)
    }

    const initSharedExpression = (keysArray, expressionType, columnName) => {
        setKeysArray(keysArray)
        setSharedExpressionType(expressionType)
        setSharedColumnName(columnName)
    }

    const mapEachExpressionPropertyRecursive = newValue => {
        layer.styles.forEach(s => {
            let propertiesChanged = false
            const newProperties = s.properties.map(p => {
                if (!isColorProperty(p) || p.expressionType === "none") return p
                // This means we need to sync it to the shared expression
                propertiesChanged = true
                switch (p.expressionType) {
                    case "match":
                        var arrayOffset = 2
                        var newPropertyValue = newValue([...p.value], arrayOffset)
                        break
                    case "interpolate":
                        var arrayOffset = 3
                        var newPropertyValue = newValue([...p.value], arrayOffset)
                        break
                    default:
                        return p
                }
                return { ...p, value: newPropertyValue }
            })
            if (propertiesChanged) onPropertiesChanged(s, newProperties)
        })
    }

    const addExpressionKey = newKey => {
        const addKey = value => {
            const matchArray = [...value]
            const defaultValue = matchArray[matchArray.length - 1] // The default value is on the last position
            const firstMatchValueIndex = 2
            matchArray.splice(firstMatchValueIndex, 0, newKey, defaultValue)
            return matchArray
        }

        mapEachExpressionPropertyRecursive(addKey)

        setKeysArray([newKey, ...keysArray])
    }

    const updateExpressionKey = (keyIndex, newValue) => {
        const updateKey = (value, offset) => {
            const expressionIndex = keyIndex * 2 + offset
            value[expressionIndex] = newValue
            return value
        }

        mapEachExpressionPropertyRecursive(updateKey)

        setKeysArray(keysArray.map((k, i) => (i === keyIndex ? newValue : k)))
    }

    const removeExpressionKey = keyIndex => {
        const removeValue = (value, offset) => {
            const expressionIndex = keyIndex * 2 + offset
            const matchArray = [...value]
            matchArray.splice(expressionIndex, 2)
            return matchArray
        }

        mapEachExpressionPropertyRecursive(removeValue)

        setKeysArray(keysArray.filter((_, i) => i !== keyIndex))
    }

    const sharedExpressionData = {
        addExpressionKey,
        initSharedExpression,
        keysArray,
        removeExpressionKey,
        sharedColumnName,
        sharedExpressionType,
        resetSharedExpression,
        updateExpressionKey,
        setSharedExpressionType,
    }

    return (
        <StylerContext.Provider value={{ ...sharedExpressionData, ...stylerProps }}>{children}</StylerContext.Provider>
    )
}
