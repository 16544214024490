import * as React from "react";
const SvgZoo15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "zoo-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.3879 3.8928 C8.3879 3.8928 7.6818 5 7 5 L4.5 5 C3.8182 5 3.2016 5.4086 2.7273 5.8636 L0.5 8 C0.231 8.258 0.0072 8.7681 0 9 C-0.0156 9.5 0 10 0 10 C0 10 1 10 1 9 L1 8.5 L2 7.5 L2.5 7.5 L2.658 7.7892 C2.658 7.7892 1.7045 9.6136 1.7045 11.3181 C1.7046 12 2.3864 12 2.3864 12 L3.0682 12 C3.0682 12 3.4091 12 3.0682 11.6591 L2.7273002 11.318199 C2.7273 10.6364 3.5002 9.6667 4 9 C4 9 4.0168 10.1579 4 11 C3.992 11.4096 4.2721 12 4.6818 12 L5.3636 12 C5.3636 12 5.7044997 12 5.3636 11.6591 L5.0227 11.318199 C4.7105 10.7495 5.5 8.5 5.5 8.5 C6.7716 8.5 7 9 8.5 9 L8.8636 11.3182 C8.975 12.0282 9.5 12 9.5 12 L11 12 C11.3409 12 11.9611 11.6885 11.3409 11.2264 L11 11 L11 9 C12.2142 8.8278 13 8 13 7 L14 7 C14.3214 7 15 7 15 6.5 L15 5.5 L13.2955 3.8182 C12.5444 3.0772 12 2.5 11 2.5 C9.5469 2.5 8.8636 3.0688 8.3879 3.8928 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.3879 3.8928 C8.3879 3.8928 7.6818 5 7 5 L4.5 5 C3.8182 5 3.2016 5.4086 2.7273 5.8636 L0.5 8 C0.231 8.258 0.0072 8.7681 0 9 C-0.0156 9.5 0 10 0 10 C0 10 1 10 1 9 L1 8.5 L2 7.5 L2.5 7.5 L2.658 7.7892 C2.658 7.7892 1.7045 9.6136 1.7045 11.3181 C1.7046 12 2.3864 12 2.3864 12 L3.0682 12 C3.0682 12 3.4091 12 3.0682 11.6591 L2.7273002 11.318199 C2.7273 10.6364 3.5002 9.6667 4 9 C4 9 4.0168 10.1579 4 11 C3.992 11.4096 4.2721 12 4.6818 12 L5.3636 12 C5.3636 12 5.7044997 12 5.3636 11.6591 L5.0227 11.318199 C4.7105 10.7495 5.5 8.5 5.5 8.5 C6.7716 8.5 7 9 8.5 9 L8.8636 11.3182 C8.975 12.0282 9.5 12 9.5 12 L11 12 C11.3409 12 11.9611 11.6885 11.3409 11.2264 L11 11 L11 9 C12.2142 8.8278 13 8 13 7 L14 7 C14.3214 7 15 7 15 6.5 L15 5.5 L13.2955 3.8182 C12.5444 3.0772 12 2.5 11 2.5 C9.5469 2.5 8.8636 3.0688 8.3879 3.8928 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgZoo15;
