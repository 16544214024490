import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

type StylesParams = { printFeatures: { showMapNotes: boolean; showMapTitle: boolean } }

export const useStyles = makeStyles<Theme, StylesParams>(theme => ({
    innerRoot: {
        border: "1px solid grey",
        maxHeight: "87vh",
        overflow: "hidden",
    },
    labelsContainer: {
        overflow: "hidden",
    },
    legendTitle: {
        fontSize: 15,
        textAlign: "center",
        width: "100%",
    },
    root: {
        backgroundColor: "#fff",
        height: "100%",
        padding: 10,
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20,
        maxHeight: "12vh",
        minHeight: 22,
        padding: "0 18px",
    },
    titlecontainer: {
        borderBottom: "1px solid " + theme.customColors.borderColor,
    },
}))
