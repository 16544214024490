import * as React from "react";
const SvgHeliport15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "heliport-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 2 C3 2 3 3 4 3 L8 3 L8 4 C7.723 4 7.5 4.223 7.5 4.5 L7.5 5 L5 5 L3.9707 5 L3.9316 5 C3.7041 4.1201 2.9122 3.5011 2 3.5 C0.89540005 3.5 0 4.3954 0 5.5 C0 6.6046 0.8954 7.5 2 7.5 C2.3722 7.499 2.7368 7.3942 3.0527 7.1973 L5.5 10.5 C6.5074 11.9505 8.3182 12 9 12 L14 12 C14 12 15 12 15 11 L15 10.0059 C15 9.2734 14.874 8.874 14.5 8.5 L11.5 5.5 C11.5 5.5 10.9084 5 10.2266 5 L9.5 5 L9.5 4.5 C9.5 4.223 9.277 4 9 4 L9 3 L13 3 C14 3 14 2 13 2 C13 2 4 2 4 2 z M2 4.5 C2.5523 4.5 3 4.9477 3 5.5 C3 6.0523 2.5523 6.5 2 6.5 C1.4477 6.5 1 6.0523 1 5.5 C1 4.9477 1.4477 4.5 2 4.5 z M10 6 C10.5 6 10.7896 6.3231 11 6.5 L13.5 9 L10 9 C10 9 9 9 9 8 L9 7 C9 7 9 6 10 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 2 C3 2 3 3 4 3 L8 3 L8 4 C7.723 4 7.5 4.223 7.5 4.5 L7.5 5 L5 5 L3.9707 5 L3.9316 5 C3.7041 4.1201 2.9122 3.5011 2 3.5 C0.89540005 3.5 0 4.3954 0 5.5 C0 6.6046 0.8954 7.5 2 7.5 C2.3722 7.499 2.7368 7.3942 3.0527 7.1973 L5.5 10.5 C6.5074 11.9505 8.3182 12 9 12 L14 12 C14 12 15 12 15 11 L15 10.0059 C15 9.2734 14.874 8.874 14.5 8.5 L11.5 5.5 C11.5 5.5 10.9084 5 10.2266 5 L9.5 5 L9.5 4.5 C9.5 4.223 9.277 4 9 4 L9 3 L13 3 C14 3 14 2 13 2 C13 2 4 2 4 2 z M2 4.5 C2.5523 4.5 3 4.9477 3 5.5 C3 6.0523 2.5523 6.5 2 6.5 C1.4477 6.5 1 6.0523 1 5.5 C1 4.9477 1.4477 4.5 2 4.5 z M10 6 C10.5 6 10.7896 6.3231 11 6.5 L13.5 9 L10 9 C10 9 9 9 9 8 L9 7 C9 7 9 6 10 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHeliport15;
