import { Fragment } from "react"
import { Divider, Grid } from "@mui/material"
import { BoxChartWithLabelsCanvas, PieChartWithLabelsCanvas } from "@emblautec/layer-styler-component"
import CustomTypography from "../../components/common/CustomTypography/CustomTypography"
import LegendEntry from "../../components/legend/LegendEntry/LegendEntry"
import LineIcon from "../../components/legend/LineIcon/LineIcon"
import PointIcon from "../../components/legend/PointIcon/PointIcon"
import PolygonIcon from "../../components/legend/PolygonIcon/PolygonIcon"
import RasterIcon from "../../components/legend/RasterIcon/RasterIcon"
import SymbolIcon from "../../components/legend/Symbolcon/SymbolIcon"

const defaultSpacing = {
    multiLabelEntryBreakpointSpacing: 12,
    multiLabelItemBreakpointSpacing: 6,
    multiLabelItemVerticalBreakpointSpacing: 12,
    singleEntryBreakpointSpacing: 12,
}

export const renderLegendEntry = (layer, styles, styleClasses, spacingOptions = defaultSpacing) => {
    const colorProperties = styles.map(s => ({
        ...s.properties.find(p => p.propertyType.includes("color")),
        styleType: s.type,
    }))

    const layerGeometry = layer.geometryType

    if (!layerGeometry) return

    const styling = buildStyling(styles)

    const expressionProperty = colorProperties.find(prop => prop.expressionType !== "none")
    const hasExpression = !!expressionProperty

    if (hasExpression) {
        return (
            <Fragment key={layer.resourceId}>
                <Grid
                    className={styleClasses.multiLabelHeaderTextGridContainer}
                    item
                    xs={spacingOptions.multiLabelEntryBreakpointSpacing}
                >
                    <CustomTypography
                        className={styleClasses.multiLabelHeaderText}
                        textWeight="semibold"
                        variant="subtitle1"
                    >
                        {layer.name}
                    </CustomTypography>
                </Grid>
                {renderExpressionValues(
                    expressionProperty,
                    styling,
                    styleClasses,
                    spacingOptions,
                    layerGeometry === "raster",
                    layerGeometry === "raster",
                )}
                <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                    <Divider className={styleClasses.dividerSpacing} />
                </Grid>
            </Fragment>
        )
    }

    if (styling.type === "pie-chart" || styling.type === "box-chart") {
        return (
            <Fragment key={layer.resourceId}>
                <Grid
                    className={styleClasses.multiLabelHeaderTextGridContainer}
                    item
                    xs={spacingOptions.multiLabelEntryBreakpointSpacing}
                >
                    <CustomTypography
                        className={styleClasses.multiLabelHeaderText}
                        textWeight="semibold"
                        variant="subtitle1"
                    >
                        {layer.name}
                    </CustomTypography>
                </Grid>
                {renderIcon(styling)}
                <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                    <Divider className={styleClasses.dividerSpacing} />
                </Grid>
            </Fragment>
        )
    }
    return (
        <LegendEntry
            key={layer.resourceId}
            classes={{
                dividerClass: styleClasses.dividerSpacing,
                entryContainerClass: styleClasses.singleLabelEntryContainer,
                iconContainerClass: styleClasses.iconContainerClass,
                typographyClass: styleClasses.labelText,
            }}
            divider
            icon={renderIcon(styling)}
            title={layer.name}
            xsSpacing={spacingOptions.singleEntryBreakpointSpacing}
        />
    )
}

const renderExpressionValues = (
    expressionProperty,
    styling,
    styleClasses,
    spacingOptions,
    verticalDirection = false,
    rightAlign = false,
) => {
    const renderedEntries = []
    let expressionPropertyCopy = { ...expressionProperty, value: [...expressionProperty.value] }

    const valuesOffset = expressionPropertyCopy.expressionType === "match" ? 2 : 3

    if (rightAlign) {
        let longestNumberOfDecimals = 0

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionStringSplitted = expressionPropertyCopy.value[i].toString().split(".")

            if (!expressionStringSplitted[1] || expressionStringSplitted[1]?.length <= longestNumberOfDecimals) continue

            longestNumberOfDecimals = expressionStringSplitted[1]?.length
        }
        var longestValueLength = 0

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionVal = Number(expressionPropertyCopy.value[i])
            expressionPropertyCopy.value[i] = !!expressionVal
                ? expressionVal.toFixed(longestNumberOfDecimals)
                : expressionPropertyCopy.value[i]
            if (expressionPropertyCopy.value[i].length <= longestValueLength) continue

            longestValueLength = expressionPropertyCopy.value[i].toString().length
        }
    }

    for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
        const props = {
            icon: null,
            title: "",
            typographyProps: null,
        }

        // This means we are on the default case of a match expression
        if (expressionPropertyCopy.value[i + 1] === undefined) {
            //We don't want to render the default value of rasters
            if (styling.type === "raster") {
                continue
            }
            const renderIconProps = {
                ...styling,
                [styling.type + "Color"]: styling[styling.type + "Color"][i],
            }

            if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                if (typeof styling.lineColor === "string") {
                    renderIconProps.lineColor = styling.lineColor
                } else {
                    renderIconProps.lineColor = styling.lineColor[i]
                }
            }

            props.icon = renderIcon(renderIconProps)
            props.title = "Others"
        } else {
            const index = styling.type === "raster" ? i - 1 + valuesOffset : i + 1

            const renderIconProps = {
                ...styling,
                [styling.type + "Color"]: styling[styling.type + "Color"][index],
            }

            if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                if (typeof styling.lineColor === "string") {
                    renderIconProps.lineColor = styling.lineColor
                } else {
                    renderIconProps.lineColor = styling.lineColor[index]
                }
            }

            if (styling.type === "fill" && Object.hasOwn(styling, "circleColor")) {
                if (typeof styling.circleColor === "string") {
                    renderIconProps.circleColor = styling.circleColor
                } else {
                    renderIconProps.circleColor = styling.circleColor[index]
                }
            }
            props.icon = renderIcon(renderIconProps)
            props.title = expressionPropertyCopy.value[i]
        }

        if (rightAlign) {
            props.typographyProps = {
                maxWidth: `${longestValueLength}ch`,
                textAlign: "right",
            }
        }

        renderedEntries.push(
            <LegendEntry
                key={expressionPropertyCopy.value[i]}
                classes={{
                    dividerClass: styleClasses.dividerSpacing,
                    entryContainerClass: styleClasses.multiLabelEntryContainer,
                    iconContainerClass: styleClasses.iconContainerClass,
                    typographyClass: styleClasses.multiLabelText,
                }}
                xsSpacing={
                    verticalDirection
                        ? spacingOptions.multiLabelItemVerticalBreakpointSpacing
                        : spacingOptions.multiLabelItemBreakpointSpacing
                }
                {...props}
            />,
        )
    }
    return renderedEntries
}

const renderIcon = styling => {
    switch (styling.type) {
        case "symbol":
            return <SymbolIcon {...styling} />
        case "circle":
            return <PointIcon {...styling} />
        case "line":
            return <LineIcon {...styling} />

        case "fill":
            return <PolygonIcon {...styling} />
        case "pie-chart":
            return (
                <PieChartWithLabelsCanvas
                    centerFillPercentage={styling["pie-chartCenterSize"] / styling["pie-chartSize"]}
                    colors={styling["pie-chartColors"]}
                    height={240}
                    labels={styling["pie-chartLabels"]}
                    style={{
                        fontSize: 12,
                        strokeColor: styling["pie-chartStrokeColor"],
                        strokeWidth: styling["pie-chartStrokeWidth"],
                    }}
                    width={370}
                />
            )
        case "box-chart":
            return (
                <BoxChartWithLabelsCanvas
                    colors={styling["box-chartColors"]}
                    columns={styling["box-chartColumns"]}
                    height={50 * styling["box-chartRows"]}
                    labels={styling["box-chartLabels"]}
                    rows={styling["box-chartRows"]}
                    style={{
                        strokeColor: styling["box-chartStrokeColor"],
                        strokeWidth: styling["box-chartStrokeWidth"],
                    }}
                    width={370}
                />
            )

        case "raster":
            return <RasterIcon {...styling} />

        default:
            console.error(`Styling type not supported: ${styling.type}`)
            return null
    }
}

const styleOrder = {
    "box-chart": 5,
    circle: 1,
    fill: 3,
    line: 2,
    "pie-chart": 6,
    raster: 4,
    symbol: 0,
}

const propertiesMapping = {
    "box-chart": {
        "box-chart-colors": "Colors",
        "box-chart-columns": "Columns",
        "box-chart-labels": "Labels",
        "box-chart-rows": "Rows",
        "box-chart-stroke-color": "StrokeColor",
        "box-chart-stroke-width": "StrokeWidth",
    },
    circle: {
        "circle-color": "Color",
        "circle-stroke-color": "StrokeColor",
        "circle-stroke-width": "StrokeWidth",
    },
    fill: {
        "fill-color": "Color",
    },
    line: {
        "line-color": "Color",
        "line-dasharray": "DashArray",
    },
    "pie-chart": {
        "pie-chart-center-size": "CenterSize",
        "pie-chart-colors": "Colors",
        "pie-chart-labels": "Labels",
        "pie-chart-size": "Size",
        "pie-chart-stroke-color": "StrokeColor",
        "pie-chart-stroke-width": "StrokeWidth",
    },
    raster: {
        "fill-color": "Color",
    },
    symbol: {
        "icon-color": "IconColor",
        "icon-image": "Image",
        "text-color": "Color",
    },
}

const buildStyling = styles => {
    let styling = { type: "symbol" }

    for (let i = 0; i < styles.length; i++) {
        let style = styles[i]
        let propertyMapping = propertiesMapping[style.type]

        if (!propertyMapping) continue
        //Set the style type to the highest style type among the styles.
        //As an example: for a layer with a circle and fill style, select fill
        if (styleOrder[style.type] > styleOrder[styling.type]) {
            styling.type = style.type
        }

        for (let j = 0; j < style.properties.length; j++) {
            let property = style.properties[j]
            let styleName = style.type + propertyMapping[property.name]

            if (propertyMapping[property.name] && !styling.hasOwnProperty(styleName)) {
                styling[styleName] = property.value
            }
        }
    }

    return styling
}
