import { memo } from "react"
import { Source as SourceType } from "model/map/Source"
import { Source } from "@emblautec/react-map-gl"
import config from "config"
import { SOURCE_TYPES } from "constants/sources/sourceTypes"

type Props = {
    source: SourceType
    isPublic: boolean
}

const MapSource = ({ source, isPublic }: Props) => {
    const endpointName = source.type === SOURCE_TYPES.raster ? "tile/raster" : "tile/vector"
    const publicRoute = isPublic ? "/public" : ""

    const needsTiles =
        source.type === SOURCE_TYPES.raster ||
        source.type === SOURCE_TYPES.rasterDem ||
        source.type === SOURCE_TYPES.vector

    return (
        <Source
            id={source.id}
            maxzoom={source.maxZoom}
            type={source.type}
            // This looks confusing, but giving undefined to the value of a non-existent prop throws some
            // console logs. Doing it like this avoids giving the props. Using an predefined object is a bit hard
            // because of typescript
            {...(needsTiles && {
                minzoom: source.minZoom,
                tiles: [config.apiUrl + `${endpointName}${publicRoute}/${source.id}/{z}/{x}/{y}`],
            })}
            {...(source.type === "geojson" && { data: source.data })}
            {...(source.type !== "geojson" && { bounds: source.bounds })}
        />
    )
}

export default memo(MapSource)
