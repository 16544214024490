import { FeatureCollection } from "@turf/helpers"
import { AppLayer } from "model/app/AppLayer"
import { SourceType } from "model/enums/SourceType"

const minZoom = 0
const maxZoom = 24

export const getBufferSource = (sourceId: string, data: FeatureCollection) => {
    return {
        data,
        id: sourceId,
        maxZoom,
        minZoom,
        type: "geojson",
    }
}

export const getBufferLayer = (sourceId: string, layerId: string, resourceId: string) => {
    return {
        layerId: layerId,
        resourceId: resourceId,
        sourceId: sourceId,
        type: "fill",
    }
}

export const getBufferPaintProperties = () => {
    return bufferPaint.map(x => ({
        name: x.name,
        value: x.value,
    }))
}

export const getBufferLayoutProperties = () => {
    return [
        {
            name: "visibility",
            value: "visible",
        },
    ]
}

export const mapMapboxFeaturesToTurfFeatures = (features: mapboxgl.MapboxGeoJSONFeature[]): FeatureCollection => {
    return {
        features: features.map(feature => ({
            geometry: {
                coordinates: feature.geometry.type !== "GeometryCollection" ? feature.geometry.coordinates : [],
                type: feature.geometry.type,
                ...(feature.geometry.type === "GeometryCollection" && { geometries: feature.geometry.geometries }),
            },
            id: feature.id,
            properties: feature.properties,
            type: feature.type,
        })),
        type: "FeatureCollection",
    }
}

export const getInitialBufferAppLayer = (
    layerResourceId: string,
    layerName: string,
    originalAppLayer: AppLayer,
): AppLayer => {
    return {
        bounds: {
            coordinates: [],
            type: "Polygon",
        },
        geometryType: "",
        maxZoom,
        minZoom,
        name: layerName,
        options: {
            loading: true,
            enabled: true,
            downloadable: true,
        },
        resourceId: layerResourceId,
        rowCount: originalAppLayer.rowCount,
        sourceId: "",
        sourceName: "",
        type: SourceType.Vector,
        isBuffer: true,
        isShown: true,
    }
}

export const getAppLayerStyle = (styleId: string) => {
    return { maxZoom, minZoom, properties: bufferPaint, styleId, type: "fill" }
}

export const defaultBufferOpacity = 0.3

const bufferPaint = [
    {
        expressionType: "none",
        name: "fill-color",
        propertyType: "color",
        title: "fill",
        type: "paint",
        value: "darkcyan",
    },
    {
        expressionType: "none",
        name: "fill-outline-color",
        propertyType: "color",
        title: "outline",
        type: "paint",
        value: "black",
    },
    {
        expressionType: "none",
        name: "fill-opacity",
        propertyType: "float",
        title: "opacity",
        type: "paint",
        value: defaultBufferOpacity,
    },
    {
        expressionType: "none",
        name: "fill-antialias",
        propertyType: "boolean",
        title: "antialias",
        type: "paint",
        value: true,
    },
]

export const allowedBufferUnits = {
    kilometers: { key: "km", toMeters: (x: number) => x * 1000 },
    meters: { key: "m", toMeters: (x: number) => x },
    nauticalmiles: { key: "nm", toMeters: (x: number) => x * 1852 },
}
