import * as React from "react";
const SvgReligiousJewish15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "religious-jewish-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15 12 L9.78 12 L7.5 15 L5.24 12 L0 12 L2.7 8 L0 4 L5.3 4 L7.5 0 L9.84 4 L15 4 L12.440001 8 L15 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15 12 L9.78 12 L7.5 15 L5.24 12 L0 12 L2.7 8 L0 4 L5.3 4 L7.5 0 L9.84 4 L15 4 L12.440001 8 L15 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgReligiousJewish15;
