import { makeStyles } from "@mui/styles"

export const useEraInfoboxStyles = makeStyles(theme => ({
    content: {
        overflow: "auto",
        paddingBottom: theme.spacing(2),
        paddingInline: theme.spacing(2),
    },
    copyBtn: {
        backgroundColor: "white",
    },
    featureHeader: {
        flexGrow: 1,
        fontSize: 14,
        fontWeight: "bold",
        padding: 8,
        paddingLeft: 16,
    },
    featureHeaderContainer: {
        backgroundColor: "white",
        borderBottom: "2px solid ",
        borderTop: "8px white solid",
        display: "flex",
        marginTop: "-8",
        position: "sticky",
        top: 0,
    },
    features: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        marginBottom: 8,
        overflowY: "scroll",
        padding: "0px 8px 8px 8px",
    },
    header: {
        borderBottom: "1px solid " + theme.customColors.borderColor,
        paddingBlock: theme.spacing(1),
        paddingInline: theme.spacing(2),
    },
    item: {
        "&:not(:last-child)": {
            borderBottom: "1px solid " + theme.customColors.borderColor,
        },
        alignItems: "center",
        display: "flex",
        height: 44,

        justifyContent: "space-between",
    },
    propertyValue: {
        borderBottom: "whitesmoke 1px solid",
        fontFamily: "Roboto Helvetica Neue sans-serif",
        fontSize: 14,
        fontWeight: "normal",
        overflow: "hidden",
        padding: 8,
    },
    root: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        [theme.breakpoints.down("sm")]: {
            bottom: 8,
            left: 8,
            right: 8,
        },

        width: 500,
    },
    squareBtn: {
        borderRadius: 3,
        height: 32,
        width: 32,
    },
    table: {
        backgroundColor: "white",
        borderCollapse: "collapse",
        marginBottom: theme.spacing(0.5),
        tableLayout: "fixed",
        width: "100%",
    },
}))
