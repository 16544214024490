import { FCWC } from "react"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { StyledEngineProvider, ThemeProvider } from "@mui/material"
import theme from "theme/muiTheme"

const muiCache = createCache({
    key: "mui",
    prepend: true,
})

const StylingProviders: FCWC = ({ children }) => {
    return (
        <CacheProvider value={muiCache}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    )
}

export default StylingProviders
