import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { AuthenticationProvider } from "app/providers/AuthenticationProvider/AuthenticationProvider"
import { store } from "store/store"
import "utils/dayjs/customLocale"
import App from "./App"
import MapProviders from "./app/providers/MapProviders/MapProviders"
import StylingProviders from "./app/providers/StylingProviders/StylingProviders"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const root = document.getElementById("root") as HTMLElement

ReactDOM.createRoot(root).render(
    <Provider store={store}>
        <Router>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StylingProviders>
                    <MapProviders>
                        <AuthenticationProvider>
                            <App />
                        </AuthenticationProvider>
                    </MapProviders>
                </StylingProviders>
            </LocalizationProvider>
        </Router>
    </Provider>,
)

serviceWorkerRegistration.registerTileCache()
