import * as React from "react";
const SvgIceCream11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "ice-cream-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 6 C4.541 6.0007 5.0676003 5.8252 5.5 5.5 C5.9324 5.8252 6.459 6.0007 7 6 L6 10.690001 C5.8918 10.9441 5.5981 11.062301 5.344 10.954 C5.2251997 10.9034 5.1306 10.808801 5.08 10.690001 L4 6 z M7 2 L6.91 2 C7.1926 1.2213 6.7904997 0.36090004 6.0118 0.07819998 C5.2331 -0.20450008 4.3726 0.1978 4.09 0.9765 C3.97 1.3071 3.97 1.6694 4.09 2 L4 2 C3.1716 2 2.5 2.6716 2.5 3.5 C2.5 4.3283997 3.1716 5 4 5 C4.8283997 5 5.5 4.3284 5.5 3.5 L5.5 3.5 L5.5 3.5 L5.5 3.5 C5.5 4.3284 6.1716 5 7 5 C7.8284 5 8.5 4.3284 8.5 3.5 C8.5 2.6715999 7.8284 2 7 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 6 C4.541 6.0007 5.0676003 5.8252 5.5 5.5 C5.9324 5.8252 6.459 6.0007 7 6 L6 10.690001 C5.8918 10.9441 5.5981 11.062301 5.344 10.954 C5.2251997 10.9034 5.1306 10.808801 5.08 10.690001 L4 6 z M7 2 L6.91 2 C7.1926 1.2213 6.7904997 0.36090004 6.0118 0.07819998 C5.2331 -0.20450008 4.3726 0.1978 4.09 0.9765 C3.97 1.3071 3.97 1.6694 4.09 2 L4 2 C3.1716 2 2.5 2.6716 2.5 3.5 C2.5 4.3283997 3.1716 5 4 5 C4.8283997 5 5.5 4.3284 5.5 3.5 L5.5 3.5 L5.5 3.5 L5.5 3.5 C5.5 4.3284 6.1716 5 7 5 C7.8284 5 8.5 4.3284 8.5 3.5 C8.5 2.6715999 7.8284 2 7 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgIceCream11;
