import { useMemo, ReactNode } from "react"
import { Grid } from "@mui/material"
import clsx from "clsx"
import { getDigitizeLayers, getDigitizeLayersVisibilityMapSelector } from "selectors/digitizeSelectors"
import { getLayerGroups, getLayerStylesMap, getLayerVisibilityMap } from "selectors/layerSelector"
import { useAppSelector } from "store/hooks/useAppSelector"
import { digitizeLayerToPartialAppLayer, digitizeLayerToStyle } from "utils/digitizeUtils"
import { renderLegendEntry } from "utils/legend/legendUtils"

export type LegendSpacingOptions = {
    multiLabelEntryBreakpointSpacing: number
    multiLabelItemBreakpointSpacing: number
    multiLabelItemVerticalBreakpointSpacing: number
    singleEntryBreakpointSpacing: number
}

export type LegendClasses = {
    dividerSpacing: any
    labelsContainer: any
    labelText: any
    multiLabelEntryContainer: any
    multiLabelHeaderText: any
    multiLabelText: any
    singleLabelEntryContainer: any
}

type Props = {
    classes: LegendClasses
    spacingOptions: LegendSpacingOptions
}

const LegendEntries = ({ classes, spacingOptions }: Props) => {
    const digitizeLayers = useAppSelector(getDigitizeLayers)
    const layerGroups = useAppSelector(getLayerGroups)
    const stylesMap = useAppSelector(getLayerStylesMap)
    const layerVisibilityMap = useAppSelector(getLayerVisibilityMap)
    const digitizeLayersVisibilityMap = useAppSelector(getDigitizeLayersVisibilityMapSelector)

    const normalLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = []
        layerGroups.forLayersRecursive(lay => {
            if (layerVisibilityMap[lay.resourceId] && lay.isShown) {
                renderedLegendEntries.push(renderLegendEntry(lay, stylesMap[lay.resourceId], classes, spacingOptions))
            }
        })

        return renderedLegendEntries
    }, [layerGroups, stylesMap, layerVisibilityMap, classes, spacingOptions])

    const digitizeLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = []
        digitizeLayers.forEach((lay: any) => {
            if (digitizeLayersVisibilityMap[lay.id]) {
                renderedLegendEntries.push(
                    renderLegendEntry(
                        digitizeLayerToPartialAppLayer(lay),
                        [digitizeLayerToStyle(lay, lay.styleCount)],
                        classes,
                        spacingOptions,
                    ),
                )
            }
        })
        return renderedLegendEntries
    }, [digitizeLayersVisibilityMap, digitizeLayers])

    return (
        <Grid className={clsx(classes.labelsContainer)} container>
            {[...normalLegendEntries, ...digitizeLegendEntries]}
        </Grid>
    )
}

export default LegendEntries
