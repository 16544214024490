import * as React from "react";
const SvgLibrary11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "library-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0 1 L0 8 C3.26 8 5.4902 10 5.4902 10 C5.4902 10 7.76 8 11 8 L11 1 C7 1 5.4902 2.7246 5.4902 2.7246 C5.4902 2.7246 4 1 0 1 z M1 2 C2.1953 1.9687 4.053 2.4015 5 3.3477 L5.5 4 L6 3.3477 C6.9615 2.4183 8.8009 1.9878 10 2 L10 7 C8 7 6.6456 7.8564 5.4902 8.7812 C4.3506 7.8533 3 7 1 7 L1 2 z M2 3.3027 L2 3.4843001 C2.8234 3.6531 4.0997 4.1711 5 4.6601 L5 4.4316 C4.0828 3.9535 2.8241 3.46 2 3.3027 z M9 3.3027 C8.1759 3.46 6.9172 3.9535 6 4.4316 L6 4.6601 C6.9003 4.1711 8.1765995 3.6531 9 3.4843001 L9 3.3027 z M2 4.2227 L2 4.4043 C2.8217 4.5582004 4.0985003 5.0627003 5 5.5371003 L5 5.3418 C4.0827 4.8663 2.8238 4.3752 2 4.2227 z M9 4.2227 C8.1762 4.3752 6.9173 4.8663 6 5.3418 L6 5.5371003 C6.9014997 5.0627003 8.1783 4.5582004 9 4.4043 L9 4.2227 z M2 5.1172 L2 5.2988 C2.8216 5.4535 4.0984 5.9578 5 6.4316 L5 6.2363 C4.0825 5.7614 2.8236 5.2707 2 5.1172 z M9 5.1172 C8.1764 5.2707 6.9175 5.7614 6 6.2363 L6 6.4316 C6.9016 5.9578 8.1784 5.4535003 9 5.2988 L9 5.1172 z M2 6 L2 6.1816 C2.8201 6.322 4.097 6.811 5 7.2695 L5 7.1191 C4.0825 6.6445 2.8236 6.1538 2 6 z M9 6 C8.1764 6.1538 6.9175 6.6445 6 7.1191 L6 7.2695003 C6.903 6.811 8.1799 6.322 9 6.1816 L9 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0 1 L0 8 C3.26 8 5.4902 10 5.4902 10 C5.4902 10 7.76 8 11 8 L11 1 C7 1 5.4902 2.7246 5.4902 2.7246 C5.4902 2.7246 4 1 0 1 z M1 2 C2.1953 1.9687 4.053 2.4015 5 3.3477 L5.5 4 L6 3.3477 C6.9615 2.4183 8.8009 1.9878 10 2 L10 7 C8 7 6.6456 7.8564 5.4902 8.7812 C4.3506 7.8533 3 7 1 7 L1 2 z M2 3.3027 L2 3.4843001 C2.8234 3.6531 4.0997 4.1711 5 4.6601 L5 4.4316 C4.0828 3.9535 2.8241 3.46 2 3.3027 z M9 3.3027 C8.1759 3.46 6.9172 3.9535 6 4.4316 L6 4.6601 C6.9003 4.1711 8.1765995 3.6531 9 3.4843001 L9 3.3027 z M2 4.2227 L2 4.4043 C2.8217 4.5582004 4.0985003 5.0627003 5 5.5371003 L5 5.3418 C4.0827 4.8663 2.8238 4.3752 2 4.2227 z M9 4.2227 C8.1762 4.3752 6.9173 4.8663 6 5.3418 L6 5.5371003 C6.9014997 5.0627003 8.1783 4.5582004 9 4.4043 L9 4.2227 z M2 5.1172 L2 5.2988 C2.8216 5.4535 4.0984 5.9578 5 6.4316 L5 6.2363 C4.0825 5.7614 2.8236 5.2707 2 5.1172 z M9 5.1172 C8.1764 5.2707 6.9175 5.7614 6 6.2363 L6 6.4316 C6.9016 5.9578 8.1784 5.4535003 9 5.2988 L9 5.1172 z M2 6 L2 6.1816 C2.8201 6.322 4.097 6.811 5 7.2695 L5 7.1191 C4.0825 6.6445 2.8236 6.1538 2 6 z M9 6 C8.1764 6.1538 6.9175 6.6445 6 7.1191 L6 7.2695003 C6.903 6.811 8.1799 6.322 9 6.1816 L9 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLibrary11;
