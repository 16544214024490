import * as React from "react";
const SvgStadium15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "stadium-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1 L7 3 L7 4.5 L7 5.0098 C4.1695 5.1037 2.0021 5.9665 2 7 L2 11.5 C2 12.6046 4.4624 13.5 7.5 13.5 C10.5376 13.5 13 12.6046 13 11.5 L13 7 C12.9979 5.9665003 10.8305 5.1037 8 5.0098 L8 4.0625 L11 2.75 L7 1 z M3 8.1465 C3.5148 8.4136 4.2014 8.630799 5 8.7793 L5 11.746099 C3.7948 11.477 3 11.0199 3 10.5 L3 8.1465 z M12 8.1484 L12 10.5 C12 11.0199 11.2052 11.477 10 11.746099 L10 8.7812 C10.7986 8.6328 11.4852 8.4155 12 8.1484 z M6 8.9219 C6.4877 8.973 6.9925 8.9992 7.5 9 C8.0073 8.9999 8.5121 8.9743 9 8.9238 L9 11.9082 C8.5287 11.964 8.0288 12 7.5 12 C6.9712 12 6.4713 11.964 6 11.9082 L6 8.9219 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1 L7 3 L7 4.5 L7 5.0098 C4.1695 5.1037 2.0021 5.9665 2 7 L2 11.5 C2 12.6046 4.4624 13.5 7.5 13.5 C10.5376 13.5 13 12.6046 13 11.5 L13 7 C12.9979 5.9665003 10.8305 5.1037 8 5.0098 L8 4.0625 L11 2.75 L7 1 z M3 8.1465 C3.5148 8.4136 4.2014 8.630799 5 8.7793 L5 11.746099 C3.7948 11.477 3 11.0199 3 10.5 L3 8.1465 z M12 8.1484 L12 10.5 C12 11.0199 11.2052 11.477 10 11.746099 L10 8.7812 C10.7986 8.6328 11.4852 8.4155 12 8.1484 z M6 8.9219 C6.4877 8.973 6.9925 8.9992 7.5 9 C8.0073 8.9999 8.5121 8.9743 9 8.9238 L9 11.9082 C8.5287 11.964 8.0288 12 7.5 12 C6.9712 12 6.4713 11.964 6 11.9082 L6 8.9219 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgStadium15;
