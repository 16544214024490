import { memo, useEffect, useRef, useState } from "react"
import { Layer, Source, useMap } from "@emblautec/react-map-gl"
import { STYLE_TYPES } from "constants/layers/styleTypes"
import { SOURCE_TYPES } from "constants/sources/sourceTypes"
import PolygonLabelGenerator from "../../utils/polygonLabelGenerator"

const PolygonLabelLayer = props => {
    const { layerId, maxZoom, minZoom, polygonLayerId } = props

    const { current } = useMap()
    const polygonGenerator = useRef(new PolygonLabelGenerator(current))
    const [features, setFeatures] = useState([])

    const sourceId = layerId + "_centroid"

    const isZoomValid = () => {
        const currentZoom = current.getZoom()
        return minZoom <= currentZoom && currentZoom <= maxZoom
    }

    const generateLabels = () => {
        if (!isZoomValid) return //We don't generate features if the layer isn't visible
        const generatedFeatures = polygonGenerator.current.generateLabels(polygonLayerId)
        setFeatures(generatedFeatures)
    }

    const onMapLoad = () => {
        generateLabels()
        current.on("moveend", generateLabels)
    }

    useEffect(() => {
        current.once("load", onMapLoad)
        return () => {
            current.off("moveend", generateLabels)
        }
    }, [])

    return <MemoGeoJsonSymbolLayer {...props} features={features} sourceId={sourceId} />
}

const GeoJsonSymbolLayer = ({ beforeId, features, layerId, layout, maxZoom, minZoom, sourceId }) => {
    return (
        <Source
            data={{
                features,
                type: "FeatureCollection",
            }}
            id={sourceId}
            type={SOURCE_TYPES.geojson}
        >
            <Layer
                beforeId={beforeId}
                id={layerId}
                layout={{ ...layout, "text-font": ["Open Sans Regular"] }}
                maxZoom={maxZoom}
                minZoom={minZoom}
                type={STYLE_TYPES.symbol}
            />
        </Source>
    )
}

const MemoGeoJsonSymbolLayer = memo(GeoJsonSymbolLayer)

export default PolygonLabelLayer
