import dayjs, { Dayjs } from "dayjs"
import { Operators, Types } from "model/map/LayerFilterType"
import { MapFilter } from "model/map/MapFilterType"

const convertToType = (valueType: Types, value: string | number | Dayjs): string | number | Dayjs => {
    switch (valueType) {
        case Types.String:
        case Types.CharacterVarying:
            return value.toString()
        case Types.Integer:
        case Types.DoublePrecision:
            return Number(value)
        case Types.Date:
            return dayjs(Date.parse(value.toString())).format("YYYY-MM-DD")
        default:
            return value
    }
}

export const toMapboxFilters = (filters: MapFilter[] | undefined): Array<any> => {
    return filters && filters.length > 0
        ? [
              "all",
              ...filters
                  .map((filter: MapFilter) => {
                      switch (filter.operatorName) {
                          case Operators.Contains:
                              return [["in", convertToType(filter.type, filter.value), ["get", filter.fieldName]]]
                          case Operators.Between:
                              return [
                                  [
                                      Operators.GreaterThanOrEqualTo,
                                      ["get", filter.fieldName],
                                      convertToType(filter.type, filter.value),
                                  ],
                                  [
                                      Operators.LessThanOrEqualTo,
                                      ["get", filter.fieldName],
                                      convertToType(filter.type, filter.secondValue ?? 0),
                                  ],
                              ]
                          default:
                              return [
                                  [
                                      filter.operatorName,
                                      ["get", filter.fieldName],
                                      convertToType(filter.type, filter.value),
                                  ],
                              ]
                      }
                  })
                  .flat(),
          ]
        : ["all"]
}
