import React, { useEffect, useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import * as Yup from "yup"
import TitleTextField from "../../../common/TitleTextfield/TitleTextField"

const dashAndGapsValidator = Yup.number()
    .typeError("Value must be a number")
    .required("Required")
    .min(0, "Value must be greater or equal to 0.")

function LineDasharrayProperty({
    editable,
    title,
    value: propertyValue,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) {
    const isLiteral = propertyValue[0] === "literal"

    const value = isLiteral ? propertyValue[1] : propertyValue

    const [dashes, setDashes] = useState(value[0])
    const [gaps, setGaps] = useState(value[1])
    const [dashesError, setDashesError] = useState(undefined)
    const [gapsError, setGapsError] = useState(undefined)

    useEffect(() => {
        processDashesChange(value[0], true)
        processGapsChange(value[1], true)
    }, [])

    const processDashesChange = (dashesNumber, initing = false) => {
        //For empty string we send undefined to trigger required
        const valueToProcess = dashesNumber === "" ? undefined : dashesNumber

        setDashes(dashesNumber)
        dashAndGapsValidator
            .validate(valueToProcess)
            .then(newDashes => {
                setDashesError()
                if (!gapsError && !initing) {
                    const gapsNumber = Number(gaps)
                    const newValue = isLiteral ? ["literal", [newDashes, gapsNumber]] : [newDashes, gapsNumber]
                    onPropertyChanged(newValue)
                }
            })
            .catch(err => {
                setDashesError(err.errors[0])
            })
    }

    const processGapsChange = (gapsNumber, initing = false) => {
        //For empty string we send undefined to trigger required
        const valueToProcess = gapsNumber === "" ? undefined : gapsNumber

        setGaps(gapsNumber)
        dashAndGapsValidator
            .validate(valueToProcess)
            .then(newGaps => {
                setGapsError()
                if (!dashesError && !initing) {
                    const dashesNumber = Number(dashes)
                    const newValue = isLiteral ? ["literal", [dashesNumber, newGaps]] : [dashesNumber, newGaps]
                    onPropertyChanged(newValue)
                }
            })
            .catch(err => {
                setGapsError(err.errors[0])
            })
    }

    const onGapsChange = e => {
        processGapsChange(e.target.value)
    }

    const onDashesChange = e => {
        processDashesChange(e.target.value)
    }

    const removable = onRemove && !isDefaultValue && !isLastRemainingCase

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}

                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <div className="textfield-container column">
                    <TextField
                        error={!!dashesError}
                        fullWidth
                        helperText={dashesError}
                        label="Dashes"
                        value={dashes}
                        variant="standard"
                        onChange={onDashesChange}
                    />
                    <Box height={10} />
                    <TextField
                        error={!!gapsError}
                        fullWidth
                        helperText={gapsError}
                        label="Gaps"
                        value={gaps}
                        variant="standard"
                        onChange={onGapsChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default LineDasharrayProperty
