export const measureJss = theme => ({
    arrowIcon: {
        marginLeft: 8,
    },
    content: {
        padding: 16,
    },
    copyBtn: {
        minWidth: 0,
        padding: 5,
    },
    header: {
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        display: "flex",
        padding: 8,
        paddingLeft: 16,
    },
    headerText: {
        flexGrow: 1,
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
    },
    infoPanel: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1),
    },
    infoRow: {
        display: "flex",
    },
    infoRowSpan: {
        flexGrow: 1,
    },
    measurementUnit: {
        justifyContent: "left",
        paddingLeft: 10,
        paddingRight: 0,
    },
    measurementUnitText: {
        textTransform: "lowercase",
    },
    measurementValue: {
        alignItems: "center",
        color: theme.palette.primary.main,
        display: "inline-flex",
        fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1.75,
        paddingRight: 8,
        textTransform: "uppercase",
    },
    root: {
        backgroundColor: "#fff",
        borderRadius: 4,
        left: 16,
        minWidth: 300,
        position: "absolute",
        top: 16,
        zIndex: 2,
    },
})
