import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    version: {
        alignItems: "center",
        color: theme.customColors.gray,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
}))
