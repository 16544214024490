import React, { Component, useState } from "react"
import { List } from "@mui/icons-material"
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat"
import CloseNav from "@mui/icons-material/DoubleArrow"
import LayerIcon from "@mui/icons-material/Layers"
import OpenNav from "@mui/icons-material/Menu"
import PrintIcon from "@mui/icons-material/Print"
import SearchIcon from "@mui/icons-material/Search"
import SettingsIcon from "@mui/icons-material/Settings"
import DigitizeIcon from "@mui/icons-material/Timeline"
import Button from "@mui/material/Button"
import SvgIcon from "@mui/material/SvgIcon"
import withStyles from "@mui/styles/withStyles"
import clsx from "clsx"
import { connect, useDispatch, useSelector } from "react-redux"
import { useLocation, withRouter } from "react-router-dom"
import * as sidebarAndDrawerActions from "actions/sidebarAndDrawer"
import acceptedTools from "constants/acceptedTools"
import { AIS_PATH_SUFFIX, LAYER_SELECTOR_PATH_SUFFIX, SETTINGS_PATH_SUFFIX } from "constants/routes"
import toolsDict from "constants/tools"
import { useFetchIsAISPublicQuery } from "features/ais/api"
import { closeWidgets, toggleWidget } from "features/mapTools/slice"
import * as layerSelectorActions from "reducers/layerSelector"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { getIsDrawerOpen, getIsSidebarOpen } from "selectors/sidebarAndDrawer"
import { measureIcon } from "utils/mapIcons"
import { AIS_TITLE } from "../ais/utils"
import NavButton from "./components/NavButton/NavButton"
import NavLink from "./components/NavLink/NavLink"
import { useToolsMenuStyles } from "./jss/toolsMenuJss"

const ToolsMenu = () => {
    const classes = useToolsMenuStyles()
    const location = useLocation()
    const dispatch = useDispatch()

    const sidebarState = useSelector(getIsSidebarOpen)
    const isDrawerOpen = useSelector(getIsDrawerOpen)
    const tools = useSelector(state => state.mapTools.tools)
    const widgets = useSelector(state => state.mapTools.widgets)
    const toggledWidgets = useSelector(state => state.mapTools.toggledWidgets)
    const featureFlags = useSelector(getFeatureFlags)

    const icons = {
        digitize: <DigitizeIcon fontSize="medium" />,
        legend: <List fontSize="medium" />,
        measure: (
            <SvgIcon className={classes.measureIcon} fontSize="medium">
                {measureIcon}
            </SvgIcon>
        ),
        print: <PrintIcon fontSize="medium" />,
        search: <SearchIcon fontSize="medium" />,
    }

    const { data: { isPublic: isAisPublic } = { isPublic: false } } = useFetchIsAISPublicQuery()

    const onClickNavLink = () => {
        dispatch(layerSelectorActions.setStylerLayerId(null))
        if (!sidebarState) dispatch(sidebarAndDrawerActions.toggleSidebarOpen())
    }

    const toggle = widget => {
        if (!toggledWidgets.hasOwnProperty(widget.name)) {
            dispatch(closeWidgets())
        }
        dispatch(toggleWidget(widget.name))
    }

    const locationSplit = location.pathname.split("/")
    const currentLocation = locationSplit[locationSplit.length - 1]

    const toolsSidebar = tools.map(tool => {
        if (!acceptedTools[tool.name]) return null

        const toolIcon = icons[tool.name]
        const toolRoute = { pathname: toolsDict[tool.name].routeSuffix, search: location.search }

        return (
            <NavLink
                key={tool.namePretty}
                active={currentLocation === tool.name}
                icon={toolIcon}
                name={tool.namePretty}
                to={toolRoute}
                onClick={onClickNavLink}
            />
        )
    })

    const widgetsSidebar = widgets.map(widget => {
        const widgetIcon = icons[widget.name]
        return (
            <NavButton
                key={widget.namePretty}
                active={widget.toggled}
                icon={widgetIcon}
                name={widget.namePretty}
                onClick={() => toggle(widget)}
            />
        )
    })

    const aisTool = tools.find(x => x.name === "ais")

    return (
        <div className={`toolbar ${isDrawerOpen && `open`}`}>
            <div className={"endAlign " + classes.padding12}>
                <Button
                    aria-label="Close"
                    className={clsx(
                        isDrawerOpen && classes.navOpen,
                        classes.closeButton,
                        sidebarState && classes.closedSidebar,
                    )}
                    onClick={() => dispatch(sidebarAndDrawerActions.toggleDrawerOpen())}
                >
                    {!isDrawerOpen ? (
                        <OpenNav />
                    ) : (
                        <div className={classes.headerItem}>
                            {/* We apply the tool class on a wrapper since rotate also inverts the left and right padding*/}
                            <div className={classes.toolIcon}>
                                <CloseNav style={{ transform: "rotate(180deg)" }} />
                            </div>
                            <span className={classes.headerLabel}>Navigation</span>
                        </div>
                    )}
                </Button>
            </div>
            <div className={classes.root}>
                <div className={classes.content}>
                    <NavLink
                        active={currentLocation === LAYER_SELECTOR_PATH_SUFFIX}
                        icon={<LayerIcon fontSize="medium" />}
                        name="Layer selector"
                        to={{ pathname: LAYER_SELECTOR_PATH_SUFFIX, search: location.search }}
                        onClick={onClickNavLink}
                    />
                    {toolsSidebar}
                    {(featureFlags.AIS || isAisPublic) && !!aisTool && (
                        <NavLink
                            active={currentLocation === AIS_PATH_SUFFIX}
                            icon={<DirectionsBoatIcon fontSize="medium" />}
                            name={aisTool.namePretty}
                            to={{ pathname: AIS_PATH_SUFFIX, search: location.search }}
                            onClick={onClickNavLink}
                        />
                    )}
                    {widgetsSidebar}

                    {/* TODO: REMOVE THE CONDITIONAL RENDER WHEN OTHER SETTINGS BESIDE NOTIFICATIONS ARE ADDED */}
                    {featureFlags.NOTIFICATIONS && (
                        <NavLink
                            active={currentLocation === SETTINGS_PATH_SUFFIX}
                            icon={<SettingsIcon fontSize="medium" />}
                            name="Settings"
                            to={{ pathname: SETTINGS_PATH_SUFFIX, search: location.search }}
                            onClick={onClickNavLink}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ToolsMenu
