import * as React from "react";
const SvgDentist15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "dentist-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.36 14 C3.3600001 14 3.8000002 11.33 3.5 9 C3.4 8.24 2.5 7.51 2.38 6.94 C2 5 1.39 1.44 3.66 1 C5.9300003 0.55999994 6 3 7.54 3 C9.08 3 9.11 0.6400001 11.389999 1 C13.669999 1.3599999 12.98 4.9 12.679999 6.9 C12.579999 7.35 11.579999 8.38 11.499999 8.96 C11.169999 11.360001 11.819999 13.96 10.699999 13.96 C9.769999 13.96 9.379999 11.24 8.699999 9.46 C8.43 8.63 8.06 8 7.54 8 C6 8 5.75 14 4.36 14 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.36 14 C3.3600001 14 3.8000002 11.33 3.5 9 C3.4 8.24 2.5 7.51 2.38 6.94 C2 5 1.39 1.44 3.66 1 C5.9300003 0.55999994 6 3 7.54 3 C9.08 3 9.11 0.6400001 11.389999 1 C13.669999 1.3599999 12.98 4.9 12.679999 6.9 C12.579999 7.35 11.579999 8.38 11.499999 8.96 C11.169999 11.360001 11.819999 13.96 10.699999 13.96 C9.769999 13.96 9.379999 11.24 8.699999 9.46 C8.43 8.63 8.06 8 7.54 8 C6 8 5.75 14 4.36 14 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDentist15;
