import dayjs, { Dayjs } from "dayjs"
import { utils } from "features/ais/components/AisArchivePlayer"
import { BoatPositionWithStartTime } from "features/ais/models/BoatPositionWithStartTime"

// to be moved to subscription config on the backend
const THRESHOLD = 32

const FULL_OPACITY = 255
const HALF_OPACITY = Math.round(255 / 2)

export const shouldDisplayBoat = (
    boatPosition: BoatPositionWithStartTime,
    previousBucketDictionary: Record<string, BoatPositionWithStartTime>,
    currentTime: Dayjs,
) => {
    if (previousBucketDictionary[utils.getBoatIdentifier(boatPosition)]) return true
    const diff = dayjs(currentTime).diff(boatPosition.startTime, "minutes")
    return diff <= 60 && diff >= 0
}

export const getBoatOpacity = (boatPosition: BoatPositionWithStartTime, currentTime: Dayjs, maxBoatAge: number) => {
    const diff = dayjs(currentTime).diff(boatPosition.startTime, "minutes")

    if (diff < 0) return 0
    if (diff < THRESHOLD) return FULL_OPACITY
    if (diff < maxBoatAge) return HALF_OPACITY

    return 0
}
