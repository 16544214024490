import { FC } from "react"
import config from "config"
import { useStyles } from "./styles"

type Props = {
    isPublic?: boolean
}

const Logo: FC<Props> = ({ isPublic = false }) => {
    const classes = useStyles()
    return isPublic ? (
        <img alt="LAUTEC WindGIS" className={classes.logo} src="/media/Lautec_WindGIS-ProductLogo_Black.svg"></img>
    ) : (
        <a className={classes.link} href={config.landingUrl}>
            <img alt="LAUTEC WindGIS" className={classes.logo} src="/media/Lautec_WindGIS-ProductLogo_Black.svg"></img>
        </a>
    )
}

export default Logo
