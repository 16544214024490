import React from "react"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { getPrintFeatures } from "selectors/printSelectors"

const useStyles = makeStyles(() => ({
    bottom20: {
        bottom: 20,
    },
    bottom50: {
        bottom: 50,
    },
    root: {
        backgroundColor: "rgba(0,0,0,0)",
        padding: "2px 0 6px 0",
        position: "absolute",
        right: 10,
        zIndex: 1,
    },
    rootLegend: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: 2,
        position: "relative",
        textAlign: "center",
    },
    text: {
        backgroundColor: "rgba(255,255,255,0.90)",
        borderColor: "grey",
        borderStyle: "solid",
        // borderRadius: '0 0 0 5px',
        borderWidth: "1px 1px 1px 1px",
        bottom: 0,
        fontSize: "0.5rem",
        left: 0,
        margin: "auto",
        maxWidth: "13vw",
        padding: 6,
        right: 0,
    },
    textLegend: {
        fontSize: "0.5rem",
    },
}))

const MapDate = ({ mapDateFormat, modal = false, showMapLegend }) => {
    const printFeatures = useSelector(getPrintFeatures)
    const classes = useStyles()
    let dateObj = new Date()
    return (
        <div
            className={`${
                modal
                    ? classes.rootLegend
                    : `${classes.root} ${printFeatures.showMapScalebar ? classes.bottom50 : classes.bottom20}`
            } `}
            id="map-date"
        >
            <Typography className={showMapLegend ? classes.textLegend : classes.text} variant="caption">
                {mapDateFormat === "local"
                    ? dayjs(dateObj).format("LLL")
                    : `${dayjs.utc(dateObj).format("YYYY-MM-DD HH:mm")} UTC`}
            </Typography>
        </div>
    )
}

export default MapDate
