import * as React from "react";
const SvgMarker11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "marker-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 -0.0176 C3.7134 -0.0176 1.6289 1.0741999 1.6289 3.8535 C1.6289 5.7393 4.6067 9.9082 5.5 11 C6.2941 9.9082 9.371 5.8386 9.371 3.8534002 C9.371 1.0742 7.2866 -0.0176 5.5 -0.0176 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 -0.0176 C3.7134 -0.0176 1.6289 1.0741999 1.6289 3.8535 C1.6289 5.7393 4.6067 9.9082 5.5 11 C6.2941 9.9082 9.371 5.8386 9.371 3.8534002 C9.371 1.0742 7.2866 -0.0176 5.5 -0.0176 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMarker11;
