import { useControl } from "@emblautec/react-map-gl"
import { createDraw } from "components/map/utils/createDraw"

// This will create a draw object scoped to the current map context.
// It will automatically be added and removed from the map.
export const useScopedDraw = () => {
    // TODO: fix type
    // @ts-ignore
    return useControl(() => createDraw())
}
