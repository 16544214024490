import React from "react"
import SyncLockIcon from "@mui/icons-material/SyncLock"
import { Tooltip, Typography } from "@mui/material"
import Icons from "../../icons"
import { isColorProperty } from "../../utils/helpers/isColorProperty"
import { isExpressionProperty } from "../../utils/helpers/isExpressionProperty"
import BoxChartCanvas from "../BoxChartCanvas/BoxChartCanvas"
import { useStyles } from "./styles"

const StylePropertyButton = ({ property, selected, value, onClick }) => {
    const classes = useStyles({ selected })
    const renderExpression = () => {
        switch (property.expressionType) {
            case "none":
                return renderValue()
            case "match":
                return <div>Categorised</div>
            case "interpolate":
                return <div>Graduated</div>
            default:
                return renderValue()
        }
    }

    const renderValue = () => {
        switch (property.propertyType) {
            case "color":
                return <div className="color-value" style={{ backgroundColor: value }}></div>
            case "numberArray":
                return <div>[{value}]</div>
            case "column":
                return <div>{property.value[1]}</div>
            case "color-array":
                return (
                    <div>
                        <BoxChartCanvas
                            colors={property.value}
                            columns={1}
                            height={22}
                            rows={property.value.length}
                            width={22}
                        />
                    </div>
                )
            case "number":
            case "float":
            case "text":
            case "select":
            case "boolean":
                return <div>{value}</div>
            case "icon":
                return renderIcon()
            default:
                return <div>error</div>
        }
    }

    function renderIcon() {
        if (value) {
            const icon = Icons[value]
            return <div className="icon-value">{icon}</div>
        } else {
            return <div>None</div>
        }
    }

    const renderGlobalExpressionLock = () => {
        return (
            isColorProperty(property) &&
            isExpressionProperty(property) && (
                <Tooltip title="The keys of this property are synced with the other color properties">
                    <SyncLockIcon className={classes.syncIcon} />
                </Tooltip>
            )
        )
    }

    return (
        <div className={selected ? "property-button selected" : "property-button"} onClick={onClick}>
            <div className={classes.titleContainer}>
                <div className={classes.title}>{property.title}</div>
                {renderGlobalExpressionLock()}
            </div>
            <div className="property-value">{renderExpression()}</div>
        </div>
    )
}
export default StylePropertyButton
