import * as React from "react";
const SvgVeterinary15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "veterinary-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 6 C5 6 4.5 8.28 4.5 9.47 L4.5 9.47 C3.8903 9.6759 3.3165998 9.9762 2.8 10.360001 C1.9289999 11.0214 1.7507999 12.259801 2.3999999 13.14 C3.0798998 13.994201 4.3080997 14.169701 5.2 13.54 C5.8778996 13.0799 6.6808 12.839 7.5 12.85 C8.3192 12.839001 9.1221 13.079901 9.8 13.54 C10.6575 14.2254 11.9072 14.0915 12.6 13.24 C13.2888 12.4265995 13.1878 11.2087 12.3744 10.519899 C12.3501005 10.499299 12.3253 10.4793 12.3 10.459999 L12.2 10.359999 C11.666699 10.003599 11.0968 9.705198 10.5 9.469998 L10.5 9.469998 C10.5 8.29 10 6 7.5 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.08 4.3 C1.3452 4.6676 1.0148 5.5371003 1.3199999 6.3 C1.384 7.1282 2.1009 7.7517004 2.9299998 7.7000003 C3.6647997 7.3324003 3.9951997 6.4629 3.6899998 5.7000003 C3.626 4.8718 2.9091 4.2483 2.08 4.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.93 4.3 C13.664801 4.6676 13.9953 5.5371003 13.690001 6.3 C13.626 7.1282 12.909101 7.7517004 12.080001 7.7000003 C11.345201 7.3324003 11.014701 6.4629 11.320001 5.7000003 C11.384 4.8718 12.1009 4.2483 12.93 4.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.08 1.3 C4.4 1.39 4.08 2.24 4.3199997 3.17 C4.4301 3.9951 5.1003 4.6321 5.93 4.7 C6.6099997 4.6099997 6.93 3.7599998 6.6899996 2.83 C6.5799 2.0049 5.9097 1.3679 5.08 1.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.93 1.3 C10.610001 1.39 10.93 2.24 10.690001 3.17 C10.5791 3.9986 9.9036 4.6365 9.07 4.7 C8.389999 4.62 8.07 3.7599998 8.309999 2.83 C8.4209 2.0014 9.0964 1.3634 9.93 1.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 6 C5 6 4.5 8.28 4.5 9.47 L4.5 9.47 C3.8903 9.6759 3.3165998 9.9762 2.8 10.360001 C1.9289999 11.0214 1.7507999 12.259801 2.3999999 13.14 C3.0798998 13.994201 4.3080997 14.169701 5.2 13.54 C5.8778996 13.0799 6.6808 12.839 7.5 12.85 C8.3192 12.839001 9.1221 13.079901 9.8 13.54 C10.6575 14.2254 11.9072 14.0915 12.6 13.24 C13.2888 12.4265995 13.1878 11.2087 12.3744 10.519899 C12.3501005 10.499299 12.3253 10.4793 12.3 10.459999 L12.2 10.359999 C11.666699 10.003599 11.0968 9.705198 10.5 9.469998 L10.5 9.469998 C10.5 8.29 10 6 7.5 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.08 4.3 C1.3452 4.6676 1.0148 5.5371003 1.3199999 6.3 C1.384 7.1282 2.1009 7.7517004 2.9299998 7.7000003 C3.6647997 7.3324003 3.9951997 6.4629 3.6899998 5.7000003 C3.626 4.8718 2.9091 4.2483 2.08 4.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.93 4.3 C13.664801 4.6676 13.9953 5.5371003 13.690001 6.3 C13.626 7.1282 12.909101 7.7517004 12.080001 7.7000003 C11.345201 7.3324003 11.014701 6.4629 11.320001 5.7000003 C11.384 4.8718 12.1009 4.2483 12.93 4.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.08 1.3 C4.4 1.39 4.08 2.24 4.3199997 3.17 C4.4301 3.9951 5.1003 4.6321 5.93 4.7 C6.6099997 4.6099997 6.93 3.7599998 6.6899996 2.83 C6.5799 2.0049 5.9097 1.3679 5.08 1.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.93 1.3 C10.610001 1.39 10.93 2.24 10.690001 3.17 C10.5791 3.9986 9.9036 4.6365 9.07 4.7 C8.389999 4.62 8.07 3.7599998 8.309999 2.83 C8.4209 2.0014 9.0964 1.3634 9.93 1.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgVeterinary15;
