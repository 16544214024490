import { FC, useEffect } from "react"
import { useHistory } from "react-router-dom"
import config from "config"
import { CORE_CLIENT_KEY, CORE_PROJECT_KEY } from "utils/coreInfo"

const LandingView: FC = () => {
    const history = useHistory()

    useEffect(() => {
        const clientId = localStorage.getItem(CORE_CLIENT_KEY)
        const projectId = localStorage.getItem(CORE_PROJECT_KEY)

        if (clientId && projectId) {
            history.push(`/${clientId}/${projectId}`)
            return
        }
        if (!!config.landingUrl) {
            window.location.href = config.landingUrl
            return
        }
    }, [])

    return null
}

export default LandingView
