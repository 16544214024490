import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import config from "config"
import { APPS_VIEW_PATH, ROOT_MAP_VIEW_PATH } from "constants/routes"
import AISDataLoader from "features/ais/components/AISDataLoader/AISDataLoader"
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import AppsView from "views/AppsView"
import MapView from "views/mapView"

const MainView = () => {
    const featureFlags = useAppSelector(getFeatureFlags)

    const history = useHistory()

    return (
        <>
            {featureFlags.AIS && <AISDataLoader />}
            <Switch>
                <Route component={AppsView} exact path={APPS_VIEW_PATH} />
                <Route
                    path={ROOT_MAP_VIEW_PATH}
                    render={matchProps => (
                        <BufferWWContextProvider
                            appId={matchProps.match.params.appId}
                            maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}
                        >
                            <MapView
                                {...{ appId: matchProps.match.params.appId, history, path: matchProps.match.path }}
                            />
                        </BufferWWContextProvider>
                    )}
                ></Route>
                <Redirect to={APPS_VIEW_PATH} />
            </Switch>
        </>
    )
}

export default MainView
