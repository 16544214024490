import React from "react"
import RefreshIcon from "@mui/icons-material/Refresh"
import { Button, Typography } from "@mui/material"
import { useErrorPlaceholderStyles } from "./styles"

const ErrorPlaceholder = ({ children, error, message, textVariant, onTryAgain }) => {
    const { classes } = useErrorPlaceholderStyles()
    return error ? (
        <div className={classes.container}>
            <Typography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </Typography>
            {onTryAgain && (
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={onTryAgain}
                >
                    Try Again
                </Button>
            )}
        </div>
    ) : (
        children
    )
}

ErrorPlaceholder.defaultProps = {
    message: "Encountered an error",
    textVariant: "body1",
}

export default ErrorPlaceholder
