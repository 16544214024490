import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    innerRoot: {
        border: "1px solid grey",
    },
    labelsContainer: {
        maxHeight: 240,
        overflow: "hidden",
    },
    legendTitle: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
        width: "15%",
    },
    notesSectionStyle: {
        backgroundColor: "#fff",
        borderTop: "1px solid lightgrey",
        color: "#000",
        fontSize: 20,
    },
    rightSidePanel: {
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 0",
    },
    root: {
        backgroundColor: "#fff",
        padding: 10,
    },
    sectionStyle: {
        backgroundColor: "#024F79",
        color: "#fff",
        fontSize: 20,
    },
    titlecontainer: {
        borderBottom: "1px solid " + theme.customColors.borderColor,
        marginBottom: 8,
    },
}))
