import * as React from "react";
const SvgIogp1103 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "312.16, 185.7, 192.13, 192.12001", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "408.23,281.76 408.23,281.76", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.46 285.1 L376.46 278.42 L376.29 281.76 L376.46 285.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.46 285.1 L376.99 288.4 L376.46 278.42 L376.46 285.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.99 288.4 L376.99 275.12 L376.46 278.42 L376.99 288.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.99 288.4 L377.85 291.63 L376.99 275.12 L376.99 288.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.85 291.63 L377.85 271.89 L376.99 275.12 L377.85 291.63 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.85 291.63 L379.05 294.75 L377.85 271.89 L377.85 291.63 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.05 294.75 L379.05 268.77 L377.85 271.89 L379.05 294.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.05 294.75 L380.57 297.73 L379.05 268.77 L379.05 294.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.57 297.73 L380.57 265.79 L379.05 268.77 L380.57 297.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.57 297.73 L382.39 300.53 L380.57 265.79 L380.57 297.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M382.39 300.53 L382.39 262.99 L380.57 265.79 L382.39 300.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M382.39 300.53 L384.49 303.13 L382.39 262.99 L382.39 300.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.49 303.13 L384.49 260.39 L382.39 262.99 L384.49 303.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.49 303.13 L386.86 305.5 L384.49 260.39 L384.49 303.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M386.86 305.5 L386.86 258.03 L384.49 260.39 L386.86 305.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M386.86 305.5 L389.45 307.6 L386.86 258.03 L386.86 305.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.45 307.6 L389.45 255.92 L386.86 258.03 L389.45 307.6 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.45 307.6 L392.26 309.42 L389.45 255.92 L389.45 307.6 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M392.26 309.42 L392.26 254.1 L389.45 255.92 L392.26 309.42 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M392.26 309.42 L395.24 310.94 L392.26 254.1 L392.26 309.42 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.24 310.94 L395.24 252.59 L392.26 254.1 L395.24 310.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.24 310.94 L398.36 312.14 L395.24 252.59 L395.24 310.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.36 312.14 L398.36 251.39 L395.24 252.59 L398.36 312.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.36 312.14 L401.59 313 L398.36 251.39 L398.36 312.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.59 313 L401.59 250.52 L398.36 251.39 L401.59 313 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.59 313 L404.89 313.52 L401.59 250.52 L401.59 313 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.89 313.52 L404.89 250 L401.59 250.52 L404.89 313.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.89 313.52 L408.23 313.7 L404.89 250 L404.89 313.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.23 313.7 L408.23 249.82 L404.89 250 L408.23 313.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "376.29,281.76 376.48,278.25 377.06,274.78 378.02,271.39 379.34,268.13 381.02,265.04 383.02,262.15 385.33,259.49 387.92,257.11 390.76,255.03 393.81,253.27 397.03,251.85 400.39,250.8 403.84,250.13 407.35,249.84 410.86,249.93 414.35,250.42 417.76,251.28 421.06,252.51 424.2,254.1 427.14,256.03 429.86,258.27 432.31,260.79 434.47,263.56 436.31,266.56 437.82,269.74 438.96,273.07 439.73,276.51 440.12,280 440.12,283.52 439.73,287.02 438.96,290.45 437.82,293.78 436.31,296.96 434.47,299.96 432.31,302.74 429.86,305.26 427.14,307.5 424.2,309.42 421.06,311.01 417.76,312.24 414.35,313.11 410.86,313.59 407.35,313.69 403.84,313.4 400.39,312.72 397.03,311.67 393.81,310.26 390.76,308.5 387.92,306.42 385.33,304.03 383.02,301.38 381.02,298.49 379.34,295.39 378.02,292.13 377.06,288.75 376.48,285.28 376.29,281.76", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "408.23,377.57 408.23,313.7", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "408.23,249.82 408.23,185.95", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "312.41,281.76 376.29,281.76", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "440.16,281.76 504.04,281.76", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "408.23,249.82 408.23,313.7", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1103;
