import * as React from "react";
const SvgSidescanSonarContact = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "205.94, 144.35, 388.12, 311.3", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("path", { d: "M246.35 402.43 L246.35 376.82 L207.94 376.82 L246.35 402.43 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.35 402.43 L323.18 453.65 L246.35 376.82 L246.35 402.43 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.18 453.65 L323.18 376.82 L246.35 376.82 L323.18 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.18 357.62 L323.18 325.61 L246.35 261.59 L323.18 357.62 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.18 453.65 L338.54 453.65 L323.18 376.82 L323.18 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.54 453.65 L338.54 376.82 L323.18 376.82 L338.54 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.18 357.62 L338.54 376.82 L323.18 325.61 L323.18 357.62 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.54 376.82 L338.54 338.41 L323.18 325.61 L338.54 376.82 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.54 453.65 L368.37 453.65 L338.54 338.41 L338.54 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.37 453.65 L368.37 363.27 L338.54 338.41 L368.37 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.37 453.65 L434.4 453.65 L368.37 363.27 L368.37 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.4 453.65 L434.4 231.19 L368.37 363.27 L434.4 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.4 453.65 L476.82 453.65 L434.4 376.82 L434.4 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M476.82 453.65 L476.82 353.81 L434.4 376.82 L476.82 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.4 376.82 L476.82 146.35 L434.4 231.19 L434.4 376.82 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M476.82 453.65 L488.01 446.19 L476.82 353.81 L476.82 453.65 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.01 446.19 L488.01 347.74 L476.82 353.81 L488.01 446.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.01 446.19 L576.03 387.51 L488.01 376.82 L488.01 446.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M576.03 387.51 L576.03 376.82 L488.01 376.82 L576.03 387.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.01 376.82 L576.03 300 L488.01 347.74 L488.01 376.82 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M576.03 387.51 L592.06 376.82 L576.03 376.82 L576.03 387.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "323.18,453.65 476.82,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "476.82,453.65 592.06,376.82", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "592.06,376.82 488.01,376.82", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "488.01,376.82 576.03,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "576.03,300 434.4,376.82", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "434.4,376.82 476.82,146.35", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "476.82,146.35 368.37,363.27", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "368.37,363.27 246.35,261.59", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "246.35,261.59 338.54,376.82", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "338.54,376.82 207.94,376.82", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "207.94,376.82 323.18,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,300 400,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }));
export default SvgSidescanSonarContact;
