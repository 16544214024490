import * as React from "react";
const SvgCircleStroked11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "circle-stroked-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C8.5376 0 11 2.4624 11 5.5 C11 8.5376 8.5376 11 5.5 11 C2.4624004 11 0 8.5376 0 5.5 C0 2.4624004 2.4624 0 5.5 0 z M5.5 1.2222 C3.1374 1.2222 1.2221999 3.1374002 1.2221999 5.5 C1.2221999 7.8626 3.1374 9.7778 5.5 9.7778 C7.8626003 9.7778 9.7778 7.8626 9.7778 5.5 C9.7778 3.1374002 7.8626 1.2222 5.5 1.2222 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C8.5376 0 11 2.4624 11 5.5 C11 8.5376 8.5376 11 5.5 11 C2.4624004 11 0 8.5376 0 5.5 C0 2.4624004 2.4624 0 5.5 0 z M5.5 1.2222 C3.1374 1.2222 1.2221999 3.1374002 1.2221999 5.5 C1.2221999 7.8626 3.1374 9.7778 5.5 9.7778 C7.8626003 9.7778 9.7778 7.8626 9.7778 5.5 C9.7778 3.1374002 7.8626 1.2222 5.5 1.2222 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCircleStroked11;
