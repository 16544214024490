import { Menu } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

const StyledMenu = withStyles({
    paper: {
        border: "1px solid #d3d4d5",
    },
})(props => (
    <Menu
        anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
        }}
        elevation={0}
        transformOrigin={{
            horizontal: "center",
            vertical: "top",
        }}
        {...props}
    />
))

export default StyledMenu
