import * as React from "react";
const SvgBar15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bar-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C5.5 1 0.5 1.25 1 1.75 L7 8 L7 12 C7 13 4 12.5 4 14 L11 14 C11 12.5 8 13 8 12 L8 8 L14 1.75 C14.5 1.25 9.5 1 7.5 1 z M7.5 2 C10 2 12.25 2.25 12.25 2.25 L11.5 3 L3.5 3 L2.75 2.25 C2.75 2.25 5 2 7.5 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C5.5 1 0.5 1.25 1 1.75 L7 8 L7 12 C7 13 4 12.5 4 14 L11 14 C11 12.5 8 13 8 12 L8 8 L14 1.75 C14.5 1.25 9.5 1 7.5 1 z M7.5 2 C10 2 12.25 2.25 12.25 2.25 L11.5 3 L3.5 3 L2.75 2.25 C2.75 2.25 5 2 7.5 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBar15;
