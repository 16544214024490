import makeStyles from "@mui/styles/makeStyles"
import isMobile from "utils/isMobile"

export const usePrintPreviewStyles = makeStyles(theme => ({
    closeButton: {
        color: theme.palette.primary.main,
        position: "absolute",
        right: 6,
        top: 4,
        zIndex: 1500,
    },
    closeButtonLandscape: {
        color: theme.palette.common.white,
        fontSize: "0.54rem",
        position: "absolute",
        right: 6,
        top: 4,
        zIndex: 3000,
    },
    header: {
        backgroundColor: "#f5f7f8",
        boxShadow: theme.shadows[1],
        position: "sticky",
        top: 0,
        zIndex: 5,
    },
    mapLegendLandscape: {
        gridArea: "mapLegendLandscape",
    },
    mapLegendPortrait: {
        gridArea: "mapLegendPortrait",
    },
    mapPreviewLandscapeContainer: {
        height: "100%",
        width: "calc(90vh * 1.4142)",
    },
    mapPreviewPortraitContainer: {
        height: "100%",
        width: "calc(90vh / 1.4142)",
    },
    printPreviewContainer: {
        display: "grid",
        gridArea: "printPreview",
        gridTemplateAreas: '"mapPreview mapLegendLandscape" "mapLegendPortrait mapLegendPortrait"',
        gridTemplateColumns: "minmax(0%, 100%) min-content",
        gridTemplateRows: "minmax(75%, 100%) min-content",
        height: "90vh",
        minWidth: "calc(90vh / 1.4142)",
        overflow: "auto",
    },
    root: {
        backgroundColor: "#f5f7f8",
        borderRadius: 8,
        boxShadow: theme.shadows[5],
        display: "grid",
        gridTemplateAreas: '"settings printPreview"',
        gridTemplateColumns: "min-content min-content",
        gridTemplateRows: "100%",
        overflowY: "auto",
        width: isMobile.phone ? "81vw" : "auto",
    },
    section: {
        padding: 16,
    },
    settingsContainer: {
        gridArea: "settings",
        height: "100%",
        maxHeight: "90vh",
        overflowY: "overlay",
        width: 350,
    },
}))
