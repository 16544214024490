import * as React from "react";
const SvgAlcoholShop15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "alcohol-shop-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 4 L10 4 L10 7.44 L10 7.44 C10 7.44 10 7.44 10 7.5 C10.003 8.4096 10.6193 9.2026005 11.5 9.43 L11.5 13 L11 13 C10.7239 13 10.5 13.2239 10.5 13.5 C10.5 13.7761 10.7239 14 11 14 L13 14 C13.2761 14 13.5 13.7761 13.5 13.5 C13.5 13.2239 13.2761 13 13 13 L12.5 13 L12.5 9.43 C13.3807 9.2026005 13.997 8.4096 14 7.5000005 C14 7.5000005 14 7.5000005 14 7.4400005 L14 7.4400005 L14 4 z M13 7.5 C13 8.0522995 12.5523 8.5 12 8.5 C11.4477 8.5 11 8.0523 11 7.5 L11 5 L13 5 L13 7.5 z M5.5 2.5 L5.5 2 C5.7761 2 6 1.7761 6 1.5 C6 1.2239 5.7761 1 5.5 1 L5.5 0.5 C5.5 0.2239 5.2761 0 5 0 L4 0 C3.7239 0 3.5 0.2239 3.5 0.5 L3.5 1 C3.2239 1 3 1.2239 3 1.5 C3 1.7761 3.2239 2 3.5 2 L3.5 2.5 C3.5 3.93 1 5.57 1 7 L1 13 C1 13.5522995 1.4477 14 2 14 L7 14 C7.5318 13.9535 7.9535 13.5318 8 13 L8 7 C8 5.65 5.5 3.85 5.5 2.5 z M4.5 12 C3.1193 12 2 10.8807 2 9.5 C2 8.1193 3.1193 7 4.5 7 C5.8807 7 7 8.1193 7 9.5 C7 10.8807 5.8807 12 4.5 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 4 L10 4 L10 7.44 L10 7.44 C10 7.44 10 7.44 10 7.5 C10.003 8.4096 10.6193 9.2026005 11.5 9.43 L11.5 13 L11 13 C10.7239 13 10.5 13.2239 10.5 13.5 C10.5 13.7761 10.7239 14 11 14 L13 14 C13.2761 14 13.5 13.7761 13.5 13.5 C13.5 13.2239 13.2761 13 13 13 L12.5 13 L12.5 9.43 C13.3807 9.2026005 13.997 8.4096 14 7.5000005 C14 7.5000005 14 7.5000005 14 7.4400005 L14 7.4400005 L14 4 z M13 7.5 C13 8.0522995 12.5523 8.5 12 8.5 C11.4477 8.5 11 8.0523 11 7.5 L11 5 L13 5 L13 7.5 z M5.5 2.5 L5.5 2 C5.7761 2 6 1.7761 6 1.5 C6 1.2239 5.7761 1 5.5 1 L5.5 0.5 C5.5 0.2239 5.2761 0 5 0 L4 0 C3.7239 0 3.5 0.2239 3.5 0.5 L3.5 1 C3.2239 1 3 1.2239 3 1.5 C3 1.7761 3.2239 2 3.5 2 L3.5 2.5 C3.5 3.93 1 5.57 1 7 L1 13 C1 13.5522995 1.4477 14 2 14 L7 14 C7.5318 13.9535 7.9535 13.5318 8 13 L8 7 C8 5.65 5.5 3.85 5.5 2.5 z M4.5 12 C3.1193 12 2 10.8807 2 9.5 C2 8.1193 3.1193 7 4.5 7 C5.8807 7 7 8.1193 7 9.5 C7 10.8807 5.8807 12 4.5 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAlcoholShop15;
