import * as React from "react";
const SvgBank11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bank-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 2 C0 2 0 3 0 3 L0 8 C0 9 1 9 1 9 L10 9 C11 9 11 8 11 8 L11 3 C11 3 11 2 10 2 L1 2 z M1 3 L2.5 3 C2.7761 3 3 3.2239 3 3.5 C3 3.7761 2.7761 4 2.5 4 C2.2239 4 2 3.7761 2 3.5 L1.5 4 C1.7761 4 2 4.2239 2 4.5 C2 4.7761 1.7761 5 1.5 5 C1.2239 5 1 4.7761 1 4.5 L1 3 z M5.5 3 C6.6046 3 7.5 4.1193 7.5 5.5 C7.5 6.8807 6.6046 8 5.5 8 C4.3954 8 3.5 6.8807 3.5 5.5 C3.5 4.1193 4.3954 3 5.5 3 z M8.5 3 L10 3 L10 4.5 C10 4.7761 9.7761 5 9.5 5 C9.2239 5 9 4.7761 9 4.5 C9 4.2239 9.2239 4 9.5 4 L9 3.5 C9 3.7761 8.7761 4 8.5 4 C8.2239 4 8 3.7761 8 3.5 C8 3.2239 8.2239 3 8.5 3 z M1.5 6 C1.7761 6 2 6.2239 2 6.5 C2 6.7761 1.7761 7 1.5 7 L2 7.5 C2 7.2239 2.2239 7 2.5 7 C2.7761 7 3 7.2239 3 7.5 C3 7.7761 2.7761 8 2.5 8 L1 8 L1 6.5 C1 6.2239 1.2239 6 1.5 6 z M9.5 6 C9.7761 6 10 6.2239 10 6.5 L10 8 L8.5 8 C8.2239 8 8 7.7761 8 7.5 C8 7.2239 8.2239 7 8.5 7 C8.7761 7 9 7.2239 9 7.5 L9.5 7 C9.2239 7 9 6.7761 9 6.5 C9 6.2239 9.2239 6 9.5 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.9023 4.25 C4.8261 4.321 4.7584 4.4051 4.7012 4.5 L6.2989 4.5 C6.2417 4.4051 6.1739 4.321 6.0977 4.25 L4.9023 4.25 z M4.5859 4.75 C4.5575 4.8303 4.5359 4.9141 4.5215 5 L6.4805 5 C6.4661 4.9141 6.4445 4.8303 6.416 4.75 L4.5859 4.75 z M4.5 5.25 C4.4998 5.3339 4.5063 5.4177 4.5195 5.5 L6.4785 5.5 C6.4924 5.4178 6.4996 5.334 6.5 5.25 L4.5 5.25 z M4.5859 5.75 C4.6171 5.8387 4.6564 5.9226 4.7031 6 L6.2988005 6 C6.3448005 5.9225 6.3835006 5.8386 6.4140005 5.75 L4.5859 5.75 z M4.9023 6.25 C4.9132 6.2607 4.9242997 6.2711 4.9354997 6.2812 L4.5 6.5 L6.5 6.5 L6.0645 6.2812 C6.0757 6.2711 6.0868 6.2607 6.0977 6.25 L4.9023 6.25 z M4.582 6.75 C4.611 6.8289 4.638 6.9091 4.6914 7 L6.3184 7 C6.3708 6.909 6.3961997 6.8289 6.4239 6.75 L4.582 6.75 z M4.8926 7.25 C5.0395 7.3915 5.2332 7.5 5.5 7.5 C5.7739 7.5 5.9701 7.3915 6.1172 7.25 L4.8926 7.25 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 2 C0 2 0 3 0 3 L0 8 C0 9 1 9 1 9 L10 9 C11 9 11 8 11 8 L11 3 C11 3 11 2 10 2 L1 2 z M1 3 L2.5 3 C2.7761 3 3 3.2239 3 3.5 C3 3.7761 2.7761 4 2.5 4 C2.2239 4 2 3.7761 2 3.5 L1.5 4 C1.7761 4 2 4.2239 2 4.5 C2 4.7761 1.7761 5 1.5 5 C1.2239 5 1 4.7761 1 4.5 L1 3 z M5.5 3 C6.6046 3 7.5 4.1193 7.5 5.5 C7.5 6.8807 6.6046 8 5.5 8 C4.3954 8 3.5 6.8807 3.5 5.5 C3.5 4.1193 4.3954 3 5.5 3 z M8.5 3 L10 3 L10 4.5 C10 4.7761 9.7761 5 9.5 5 C9.2239 5 9 4.7761 9 4.5 C9 4.2239 9.2239 4 9.5 4 L9 3.5 C9 3.7761 8.7761 4 8.5 4 C8.2239 4 8 3.7761 8 3.5 C8 3.2239 8.2239 3 8.5 3 z M1.5 6 C1.7761 6 2 6.2239 2 6.5 C2 6.7761 1.7761 7 1.5 7 L2 7.5 C2 7.2239 2.2239 7 2.5 7 C2.7761 7 3 7.2239 3 7.5 C3 7.7761 2.7761 8 2.5 8 L1 8 L1 6.5 C1 6.2239 1.2239 6 1.5 6 z M9.5 6 C9.7761 6 10 6.2239 10 6.5 L10 8 L8.5 8 C8.2239 8 8 7.7761 8 7.5 C8 7.2239 8.2239 7 8.5 7 C8.7761 7 9 7.2239 9 7.5 L9.5 7 C9.2239 7 9 6.7761 9 6.5 C9 6.2239 9.2239 6 9.5 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.9023 4.25 C4.8261 4.321 4.7584 4.4051 4.7012 4.5 L6.2989 4.5 C6.2417 4.4051 6.1739 4.321 6.0977 4.25 L4.9023 4.25 z M4.5859 4.75 C4.5575 4.8303 4.5359 4.9141 4.5215 5 L6.4805 5 C6.4661 4.9141 6.4445 4.8303 6.416 4.75 L4.5859 4.75 z M4.5 5.25 C4.4998 5.3339 4.5063 5.4177 4.5195 5.5 L6.4785 5.5 C6.4924 5.4178 6.4996 5.334 6.5 5.25 L4.5 5.25 z M4.5859 5.75 C4.6171 5.8387 4.6564 5.9226 4.7031 6 L6.2988005 6 C6.3448005 5.9225 6.3835006 5.8386 6.4140005 5.75 L4.5859 5.75 z M4.9023 6.25 C4.9132 6.2607 4.9242997 6.2711 4.9354997 6.2812 L4.5 6.5 L6.5 6.5 L6.0645 6.2812 C6.0757 6.2711 6.0868 6.2607 6.0977 6.25 L4.9023 6.25 z M4.582 6.75 C4.611 6.8289 4.638 6.9091 4.6914 7 L6.3184 7 C6.3708 6.909 6.3961997 6.8289 6.4239 6.75 L4.582 6.75 z M4.8926 7.25 C5.0395 7.3915 5.2332 7.5 5.5 7.5 C5.7739 7.5 5.9701 7.3915 6.1172 7.25 L4.8926 7.25 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBank11;
