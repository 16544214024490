import * as React from "react";
const SvgIogp1012 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "1.31, 176.71, 797.36, 246.59999", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400.01,300.01 400.01,300.01", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "1.56,300.01 1.76,293.05 2.35,286.11 3.33,279.21 4.7,272.38 6.46,265.64 8.59,259.01 11.1,252.51 13.97,246.17 17.19,239.99 20.76,234.01 24.66,228.24 28.88,222.7 33.41,217.4 38.23,212.38 43.32,207.63 48.68,203.18 54.28,199.03 60.1,195.22 66.14,191.73 72.36,188.6 78.74,185.82 85.28,183.41 91.94,181.37 98.7,179.71 105.55,178.43 112.46,177.55 119.41,177.05 126.37,176.96 133.33,177.25 140.26,177.94 147.14,179.02 153.95,180.49 160.66,182.34 167.26,184.57 173.73,187.16 180.03,190.12 186.16,193.43 192.09,197.08 197.81,201.06 203.29,205.36 208.52,209.97 213.48,214.86 218.15,220.02 222.53,225.44 226.59,231.1 230.32,236.97 233.72,243.06 236.77,249.32 239.46,255.74 241.78,262.31 243.72,269 245.29,275.79 246.46,282.65 247.25,289.57 247.64,296.53 247.64,303.49 247.25,310.45 246.46,317.37 245.29,324.23 243.72,331.02 241.78,337.71 239.46,344.27 236.77,350.7 233.72,356.96 230.32,363.04 226.59,368.92 222.53,374.58 218.15,380 213.48,385.16 208.52,390.05 203.29,394.65 197.81,398.95 192.09,402.93 186.16,406.59 180.03,409.9 173.73,412.86 167.26,415.45 160.66,417.68 153.95,419.53 147.14,421 140.26,422.08 133.33,422.77 126.37,423.06 119.41,422.96 112.46,422.47 105.55,421.59 98.7,420.31 91.94,418.65 85.28,416.61 78.74,414.2 72.36,411.42 66.14,408.29 60.1,404.8 54.28,400.98 48.68,396.84 43.32,392.39 38.23,387.64 33.41,382.61 28.88,377.32 24.66,371.78 20.76,366.01 17.19,360.03 13.97,353.85 11.1,347.51 8.59,341.01 6.46,334.38 4.7,327.64 3.33,320.81 2.35,313.91 1.76,306.97 1.56,300.01", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "247.69,300.01 552.34,300.01", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "552.34,300.01 552.53,293.05 553.12,286.11 554.11,279.21 555.48,272.38 557.23,265.64 559.37,259.01 561.87,252.51 564.74,246.17 567.96,239.99 571.53,234.01 575.43,228.24 579.65,222.7 584.18,217.4 589,212.38 594.1,207.63 599.45,203.18 605.05,199.03 610.88,195.22 616.91,191.73 623.13,188.6 629.52,185.82 636.05,183.41 642.71,181.37 649.47,179.71 656.32,178.43 663.23,177.55 670.18,177.05 677.14,176.96 684.1,177.25 691.03,177.94 697.91,179.02 704.72,180.49 711.44,182.34 718.04,184.57 724.5,187.16 730.81,190.12 736.93,193.43 742.87,197.08 748.58,201.06 754.06,205.36 759.29,209.97 764.25,214.86 768.92,220.02 773.3,225.44 777.36,231.1 781.1,236.97 784.49,243.06 787.54,249.32 790.23,255.74 792.55,262.31 794.5,269 796.06,275.79 797.24,282.65 798.02,289.57 798.42,296.53 798.42,303.49 798.02,310.45 797.24,317.37 796.06,324.23 794.5,331.02 792.55,337.71 790.23,344.27 787.54,350.7 784.49,356.96 781.1,363.04 777.36,368.92 773.3,374.58 768.92,380 764.25,385.16 759.29,390.05 754.06,394.65 748.58,398.95 742.87,402.93 736.93,406.59 730.81,409.9 724.5,412.86 718.04,415.45 711.44,417.68 704.72,419.53 697.91,421 691.03,422.08 684.1,422.77 677.14,423.06 670.18,422.96 663.23,422.47 656.32,421.59 649.47,420.31 642.71,418.65 636.05,416.61 629.52,414.2 623.13,411.42 616.91,408.29 610.88,404.8 605.05,400.98 599.45,396.84 594.1,392.39 589,387.64 584.18,382.61 579.65,377.32 575.43,371.78 571.53,366.01 567.96,360.03 564.74,353.85 561.87,347.51 559.37,341.01 557.23,334.38 555.48,327.64 554.11,320.81 553.12,313.91 552.53,306.97 552.34,300.01", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1012;
