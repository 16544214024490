import { FC } from "react"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import config from "config"
import { useFetchIsAISPublicQuery } from "features/ais/api"
import AISPublicDataLoader from "features/ais/components/AISDataLoader/AISPublicDataLoader"
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider"
import MapView from "views/mapView"

const MainPublicView: FC = () => {
    const { data: { isPublic: isAisPublic } = { isPublic: false } } = useFetchIsAISPublicQuery()

    const { appId } = useParams<{ appId: string }>()
    const { path } = useRouteMatch()
    const history = useHistory()
    const mapViewProps = { appId, history, path }

    return (
        <>
            {isAisPublic && <AISPublicDataLoader />}
            <BufferWWContextProvider appId={appId} maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}>
                <MapView {...mapViewProps} />
            </BufferWWContextProvider>
        </>
    )
}

export default MainPublicView
