import { configureStore } from "@reduxjs/toolkit"
import baseAtlasApi from "./baseAtlasApi"
import { rootReducer } from "./rootReducer"

type WindowWithReduxDev = { __REDUX_DEVTOOLS_EXTENSION__: boolean } & typeof window

export const store = configureStore({
    devTools: !import.meta.env.PROD && (window as WindowWithReduxDev).__REDUX_DEVTOOLS_EXTENSION__,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
            thunk: true,
        }).concat(baseAtlasApi.middleware),
    reducer: rootReducer,
})
