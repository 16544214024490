import * as React from "react";
const SvgDogPark11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "dog-park-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C7.5 1 7.1543 1.0371 7 1.5 L6.5 3.5 L8.5 5 L10 5 C11 5 11 4 11 4 L9.5 2.5 C9 2 8.5 2 8 2 L8 1.5 C8 1.5 8 1 7.5 1 z M2.5 2 C2.5 2 2.1466 1.9931 1.7773 2.1777 C1.408 2.3623 1 2.8333 1 3.5 L1 4.4648 C0.9996 4.4766 0.9996 4.4883 1 4.5 L1 5 L1 5.5 C1 6.5 1 7 0.5 7 C0.5 7 0 7 0 7.5 L0 9.5 C0 9.5 0 10 0.5 10 C1 10 1 9.5 1 9.5 L1 8 C1.3537 8 1.6905999 7.8629 2 7.7012 L2 9.5 C2 9.5 2 10 2.5 10 C3 10 3 9.5 3 9.5 L3 7 L6 7 L6.6641 8.9922 C7 9.9999 7.5 10 7.5 10 L8 10 C8 10 8.5 10 8.5 9.5 C8.5 9 8 9 8 9 L8 6.5 C8 5.6094 7.3678 5.2449 7 5 L5.498 4 L2 4 L2 3.5 C2 3.1667 2.0919 3.1376 2.2227 3.0723 C2.3534 3.0069 2.5 3 2.5 3 C3.1762 3.0096 3.1762 1.9904 2.5 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C7.5 1 7.1543 1.0371 7 1.5 L6.5 3.5 L8.5 5 L10 5 C11 5 11 4 11 4 L9.5 2.5 C9 2 8.5 2 8 2 L8 1.5 C8 1.5 8 1 7.5 1 z M2.5 2 C2.5 2 2.1466 1.9931 1.7773 2.1777 C1.408 2.3623 1 2.8333 1 3.5 L1 4.4648 C0.9996 4.4766 0.9996 4.4883 1 4.5 L1 5 L1 5.5 C1 6.5 1 7 0.5 7 C0.5 7 0 7 0 7.5 L0 9.5 C0 9.5 0 10 0.5 10 C1 10 1 9.5 1 9.5 L1 8 C1.3537 8 1.6905999 7.8629 2 7.7012 L2 9.5 C2 9.5 2 10 2.5 10 C3 10 3 9.5 3 9.5 L3 7 L6 7 L6.6641 8.9922 C7 9.9999 7.5 10 7.5 10 L8 10 C8 10 8.5 10 8.5 9.5 C8.5 9 8 9 8 9 L8 6.5 C8 5.6094 7.3678 5.2449 7 5 L5.498 4 L2 4 L2 3.5 C2 3.1667 2.0919 3.1376 2.2227 3.0723 C2.3534 3.0069 2.5 3 2.5 3 C3.1762 3.0096 3.1762 1.9904 2.5 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDogPark11;
