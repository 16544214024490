import makeStyles from "@mui/styles/makeStyles"

export const useCollapsibleCardStyles = makeStyles(theme => ({
    border: {
        position: "sticky",
        top: 0,
        zIndex: 1,
    },
    bottomContainer: {
        backgroundColor: theme.customColors.secondaryBackgroundColor,
        borderTop: "1px solid #e3e4e5",
        bottom: 0,
        padding: 16,
        position: "sticky",
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    collapsibleCard: {
        "& ::-webkit-scrollbar": {
            height: 33,
            width: 20,
        },
        "& ::-webkit-scrollbar-track:vertical": {
            marginBottom: 64,
        },
        "& .MuiCollapse-entered": {
            overflow: "auto",
        },
        borderRadius: 3,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
    },
    collapsibleCardHeader: {
        alignItems: "center",
        boxSizing: "border-box",
        cursor: "pointer",
        display: "flex",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "space-between",
        minHeight: 54,
        padding: 16,
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
}))

export default useCollapsibleCardStyles
