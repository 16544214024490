export function toPoint(lngLat, properties, id) {
    return {
        geometry: {
            coordinates: [lngLat.lng, lngLat.lat],
            type: "Point",
        },
        id: id,
        properties: properties,
        type: "Feature",
    }
}

export function toLine(coordinates, properties) {
    return {
        geometry: {
            coordinates: [...coordinates],
            type: "LineString",
        },
        properties: properties,
        type: "Feature",
    }
}

export function lineToPolygon(coordinates, properties) {
    coordinates = [...coordinates, coordinates[0].slice()]

    return {
        geometry: {
            coordinates: [coordinates],
            type: "Polygon",
        },
        properties: properties,
        type: "Feature",
    }
}
