import * as React from "react";
const SvgPharmacy15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "pharmacy-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 4 L10.57 2.46 C10.6299 2.4646 10.6901 2.4646 10.75 2.46 C11.4404 2.4596 11.9997 1.8997 11.9994 1.2094 C11.999 0.519 11.4391 -0.0404 10.7487 -0.04 C10.0584 -0.0396 9.499 0.5203 9.4994 1.2106 C9.4994 1.2237 9.4996 1.2368001 9.5 1.25 C9.5 1.25 9.5 1.32 9.5 1.35 L7 4 L9.5 4 z M12 6 L12 5 L3 5 L3 6 L4.5 9.5 L3 13 L3 14 L12 14 L12 13 L11 9.5 L12 6 z M10 10 L8 10 L8 12 L7 12 L7 10 L5 10 L5 9 L7 9 L7 7 L8 7 L8 9 L10 9 L10 10 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 4 L10.57 2.46 C10.6299 2.4646 10.6901 2.4646 10.75 2.46 C11.4404 2.4596 11.9997 1.8997 11.9994 1.2094 C11.999 0.519 11.4391 -0.0404 10.7487 -0.04 C10.0584 -0.0396 9.499 0.5203 9.4994 1.2106 C9.4994 1.2237 9.4996 1.2368001 9.5 1.25 C9.5 1.25 9.5 1.32 9.5 1.35 L7 4 L9.5 4 z M12 6 L12 5 L3 5 L3 6 L4.5 9.5 L3 13 L3 14 L12 14 L12 13 L11 9.5 L12 6 z M10 10 L8 10 L8 12 L7 12 L7 10 L5 10 L5 9 L7 9 L7 7 L8 7 L8 9 L10 9 L10 10 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPharmacy15;
