import * as React from "react";
const SvgHarbor15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "harbor-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C5.5 0 4 1.567 4 3.5 C4.0024 5.0629 5.0397 6.402 6.5 6.8379 L6.5 12.877001 C5.5695 12.712301 4.6245003 12.327401 3.8516 11.607501 C2.7992 10.6273 2.002 9.0676 2.002 6.498 C2.0097 5.9334 1.5489001 5.4744 0.98440003 5.4843 C0.4329 5.493 -0.0076 5.9465 0 6.498 C0 9.5009 1.0119 11.6935005 2.4902 13.0703 C3.9685 14.4471 5.8379 15 7.5 15 C9.1656 15 11.035 14.4404 12.5117 13.0605 C13.9883995 11.6806 14.998 9.4868 14.998 6.498 C15.0628 5.1027 12.9352 5.1027 13 6.498 C13 9.051001 12.2003 10.6129 11.1465 11.5976 C10.3731 12.3203 9.4288 12.7084 8.5 12.875 L8.5 6.8418 C9.9607 6.4058 10.9986 5.0642 11 3.5 C11 1.567 9.5 0 7.5 0 z M7.5 2 C8.3284 2 9 2.6716 9 3.5 C9 4.3283997 8.3284 5 7.5 5 C6.6716003 5 6 4.3284 6 3.5 C6 2.6715999 6.6716 2 7.5 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C5.5 0 4 1.567 4 3.5 C4.0024 5.0629 5.0397 6.402 6.5 6.8379 L6.5 12.877001 C5.5695 12.712301 4.6245003 12.327401 3.8516 11.607501 C2.7992 10.6273 2.002 9.0676 2.002 6.498 C2.0097 5.9334 1.5489001 5.4744 0.98440003 5.4843 C0.4329 5.493 -0.0076 5.9465 0 6.498 C0 9.5009 1.0119 11.6935005 2.4902 13.0703 C3.9685 14.4471 5.8379 15 7.5 15 C9.1656 15 11.035 14.4404 12.5117 13.0605 C13.9883995 11.6806 14.998 9.4868 14.998 6.498 C15.0628 5.1027 12.9352 5.1027 13 6.498 C13 9.051001 12.2003 10.6129 11.1465 11.5976 C10.3731 12.3203 9.4288 12.7084 8.5 12.875 L8.5 6.8418 C9.9607 6.4058 10.9986 5.0642 11 3.5 C11 1.567 9.5 0 7.5 0 z M7.5 2 C8.3284 2 9 2.6716 9 3.5 C9 4.3283997 8.3284 5 7.5 5 C6.6716003 5 6 4.3284 6 3.5 C6 2.6715999 6.6716 2 7.5 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHarbor15;
