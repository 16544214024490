import * as types from "../actions/actionTypes"

const loginInit = {
    username: "",
}

export function loginReducer(state = loginInit, action) {
    switch (action.type) {
        case types.AUTHENTICATED:
            return {
                ...state,
                username: action.result.username || "",
            }
        default:
            return state
    }
}
