import * as React from "react";
const SvgIogp1007 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "233.44, 146.1, 333.12, 307.8", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400,300 400,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,300 400,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "233.69,300 566.31,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "336.36,146.35 463.64,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "463.64,146.35 336.36,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1007;
