import * as React from "react";
const SvgPolice11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "police-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 0.5 L5 1.5 L8 1.5 L8.5 0.5 L4.5 0.5 z M5 2 L5 3 C5 3.8284001 5.6716 4.5 6.5 4.5 C7.3284 4.5 8 3.8284 8 3 L8 2 L5 2 z M1.75 3 C1 3 1 3.75 1 3.75 L1 6.75 C1.0006 7.256 1.4918001 7.6163 1.9746001 7.4648 L4 6.8262 L4 10 L8 5 L5 5 C5 5 4.8055 5 4.5762 5.0723 L2.5 5.7266 L2.5 3.75 C2.5 3.75 2.5 3 1.75 3 z M8.7715 5.3887 L5 10 L9 10 L9 6 C9 5.7648 8.9064 5.5591 8.7715 5.3887 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 0.5 L5 1.5 L8 1.5 L8.5 0.5 L4.5 0.5 z M5 2 L5 3 C5 3.8284001 5.6716 4.5 6.5 4.5 C7.3284 4.5 8 3.8284 8 3 L8 2 L5 2 z M1.75 3 C1 3 1 3.75 1 3.75 L1 6.75 C1.0006 7.256 1.4918001 7.6163 1.9746001 7.4648 L4 6.8262 L4 10 L8 5 L5 5 C5 5 4.8055 5 4.5762 5.0723 L2.5 5.7266 L2.5 3.75 C2.5 3.75 2.5 3 1.75 3 z M8.7715 5.3887 L5 10 L9 10 L9 6 C9 5.7648 8.9064 5.5591 8.7715 5.3887 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPolice11;
