import { Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import LegendEntries from "components/legend/LegendEntries/LegendEntries"
import MapCopyright from "components/map/printMapExtraInfo/mapCopyright"
import MapDate from "components/map/printMapExtraInfo/mapDate"
import MapNotes from "components/map/printMapExtraInfo/mapNotes"
import MapTitle from "components/map/printMapExtraInfo/mapTitle"
import { getToggledWidgetsSet } from "features/mapTools/selectors"
import { getPrintFeatures } from "selectors/printSelectors"
import widgetTypes from "utils/tools/widgets"
import { useLegendEntryStyles } from "./legendEntryStyles"
import { useStyles } from "./styles"

const MapLegendLandscape = () => {
    const printFeatures = useSelector(getPrintFeatures)
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT)
    const classes = useStyles({ printFeatures })
    const legendEntryClasses = useLegendEntryStyles()

    const spacingOptions = {
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 6,
        multiLabelItemVerticalBreakpointSpacing: 12,
        singleEntryBreakpointSpacing: 12,
    }

    return (
        <Grid className={classes.root} container id="modal-info-landscape">
            <Grid className={classes.innerRoot} item xs={12}>
                <Grid container>
                    <Grid className={classes.sectionStyle} item xs={12}>
                        {printFeatures.showMapTitle && (
                            <MapTitle mapTitle={printFeatures.mapTitle} modal={modalOpen} orientation="landscape" />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container justifyContent="center">
                            {/* TEMPORARILY DISABLED */}
                            {/* <Grid item xs={4}>
                                {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                            </Grid> */}
                            <Grid item xs={12}>
                                <Grid alignItems="center" container justifyContent="space-around">
                                    {printFeatures.showMapCopyright && (
                                        <MapCopyright
                                            mapCopyright={printFeatures.mapCopyright}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                    {printFeatures.showMapDate && (
                                        <MapDate
                                            mapDateFormat={printFeatures.mapDateFormat}
                                            modal={modalOpen}
                                            showMapLegend={printFeatures.showMapLegend}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.titlecontainer} item xs={12}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid alignContent="flex-start" className={classes.labelsContainer} item xs={12}>
                        <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
                    </Grid>
                    <Grid item xs={12}>
                        {printFeatures.showMapNotes && (
                            <MapNotes mapNotes={printFeatures.mapNotes} modal={modalOpen} orientation="landscape" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MapLegendLandscape
