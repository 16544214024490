import * as React from "react";
const SvgRailLight15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-light-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C5 0 5 0.5 5 0.5 L5 1.5 C5 1.777 5.223 2 5.5 2 C5.777 2 6 1.777 6 1.5 L6 1 L7 1 L7 3 L6 3 C6 3 4 3 4 5 L4 8 C4 11 7 11 7 11 L8 11 C8 11 11 11 11 8 L11 5 C11 3 9 3 9 3 L8 3 L8 1 L9 1 L9 1.5 C9 1.777 9.223 2 9.5 2 C9.777 2 10 1.777 10 1.5 L10 0.5 C10 0 9.5 0 9.5 0 L5.5 0 z M7.5 4 L9.5449 4.7734 L10 6.5 C10.1316 7 9.5 7 9.5 7 L5.5 7 C5.5 7 4.8684 7 5 6.5 L5.4551 4.7734 L7.5 4 z M7.5 8 C7.7761 8 8 8.2239 8 8.5 C8 8.7761 7.7761 9 7.5 9 C7.2239 9 7 8.7761 7 8.5 C7 8.2239 7.2239 8 7.5 8 z M4.125 12 L3 15 L4.5 15 L4.875 14 L10.125 14 L10.5 15 L12 15 L10.875 12 L9.375 12 L9.75 13 L5.25 13 L5.625 12 L4.125 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C5 0 5 0.5 5 0.5 L5 1.5 C5 1.777 5.223 2 5.5 2 C5.777 2 6 1.777 6 1.5 L6 1 L7 1 L7 3 L6 3 C6 3 4 3 4 5 L4 8 C4 11 7 11 7 11 L8 11 C8 11 11 11 11 8 L11 5 C11 3 9 3 9 3 L8 3 L8 1 L9 1 L9 1.5 C9 1.777 9.223 2 9.5 2 C9.777 2 10 1.777 10 1.5 L10 0.5 C10 0 9.5 0 9.5 0 L5.5 0 z M7.5 4 L9.5449 4.7734 L10 6.5 C10.1316 7 9.5 7 9.5 7 L5.5 7 C5.5 7 4.8684 7 5 6.5 L5.4551 4.7734 L7.5 4 z M7.5 8 C7.7761 8 8 8.2239 8 8.5 C8 8.7761 7.7761 9 7.5 9 C7.2239 9 7 8.7761 7 8.5 C7 8.2239 7.2239 8 7.5 8 z M4.125 12 L3 15 L4.5 15 L4.875 14 L10.125 14 L10.5 15 L12 15 L10.875 12 L9.375 12 L9.75 13 L5.25 13 L5.625 12 L4.125 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRailLight15;
