import makeStyles from "@mui/styles/makeStyles"

type StyleProps = {
    isSelected: boolean
}

export const useFilterIconStyles = makeStyles<StyleProps>((props: StyleProps) => ({
    filterPaper: {
        "&:hover": {
            "& $removeButton": {
                opacity: 1,
            },
            backgroundColor: "#ececec",
        },
        backgroundColor: `${props.isSelected} ? #ffffff : #d3d3d3`,
        cursor: "pointer",
        display: "flex",
        height: "18px",
        justifyContent: "center",
        margin: "8px",
        padding: "8px",
        position: "relative",
        transition: "background-color 0.3s, box-shadow 0.3s",
    },
    removeButton: {
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "flex-end",
        opacity: 0,
        position: "absolute",
        right: "0",
        top: "0",
        transition: "opacity 0.2s",
    },
}))
