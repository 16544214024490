import CryptoJS from "crypto-js"
import Config from "../config"

export const decryptId = (encryptedId: string) => {
    return decryptByDES(
        encryptedId.replaceAll("_", "/").replaceAll("-", "=").replaceAll(".", "+"),
        Config.cryptoUtilsPass,
    )
}

function decryptByDES(ciphertext: string, key: string) {
    const keyHex = CryptoJS.enc.Latin1.parse(key)
    const params = CryptoJS.lib.CipherParams.create({ ciphertext: CryptoJS.enc.Base64.parse(ciphertext) })

    // direct decrypt ciphertext
    const decrypted = CryptoJS.DES.decrypt(params, keyHex, {
        iv: keyHex,
        mode: CryptoJS.mode.CBC,
    })

    const result = decrypted.toString(CryptoJS.enc.Utf8)

    return parseInt(result)
}
