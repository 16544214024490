import React from "react"
import ACTIVITY from "./activity.svg?react"
import ADDHEXAGON from "./add-hexagon.svg?react"
import ADDKEYFRAMEALT from "./add-keyframe-alt.svg?react"
import ADDPINALT from "./add-pin-alt.svg?react"
import AFRICANTREE from "./african-tree.svg?react"
import AIRFILL0WGHT300GRAD200OPSZ40 from "./air_FILL0_wght300_GRAD200_opsz40.svg?react"
import AIRFIELD11 from "./airfield-11.svg?react"
import AIRFIELD15 from "./airfield-15.svg?react"
import AIRPLANEHELIX45DEG from "./airplane-helix-45deg.svg?react"
import AIRPLANEHELIX from "./airplane-helix.svg?react"
import AIRPLANEOFF from "./airplane-off.svg?react"
import AIRPLANEROTATION from "./airplane-rotation.svg?react"
import AIRPLANE from "./airplane.svg?react"
import AIRPORT11 from "./airport-11.svg?react"
import AIRPORT15 from "./airport-15.svg?react"
import ALCOHOLSHOP11 from "./alcohol-shop-11.svg?react"
import ALCOHOLSHOP15 from "./alcohol-shop-15.svg?react"
import AMENITYAIRPORT from "./amenity=airport.svg?react"
import AMENITYFERRYTERMINAL from "./amenity=ferry_terminal.svg?react"
import AMENITYPARKING from "./amenity=parking.svg?react"
import AMENITYTAXI from "./amenity=taxi.svg?react"
import AMUSEMENTPARK11 from "./amusement-park-11.svg?react"
import AMUSEMENTPARK15 from "./amusement-park-15.svg?react"
import ANCHOR from "./anchor.svg?react"
import ANGLETOOL from "./angle-tool.svg?react"
import ANTENNAOFF from "./antenna-off.svg?react"
import ANTENNA from "./antenna.svg?react"
import AQUARIUM11 from "./aquarium-11.svg?react"
import AQUARIUM15 from "./aquarium-15.svg?react"
import ARROWDOWNCIRCLED from "./arrow-down-circled.svg?react"
import ARROWDOWN from "./arrow-down.svg?react"
import ARROWLEFTCIRCLED from "./arrow-left-circled.svg?react"
import ARROWLEFT from "./arrow-left.svg?react"
import ARROWRIGHTCIRCLED from "./arrow-right-circled.svg?react"
import ARROWRIGHT from "./arrow-right.svg?react"
import ARROWUPCIRCLED from "./arrow-up-circled.svg?react"
import ARROWUP from "./arrow-up.svg?react"
import ARROW01 from "./Arrow_01.svg?react"
import ARROW02 from "./Arrow_02.svg?react"
import ARROW03 from "./Arrow_03.svg?react"
import ARROW04 from "./Arrow_04.svg?react"
import ARROW05 from "./Arrow_05.svg?react"
import ARROW06 from "./Arrow_06.svg?react"
import ARTGALLERY11 from "./art-gallery-11.svg?react"
import ARTGALLERY15 from "./art-gallery-15.svg?react"
import ATTRACTION11 from "./attraction-11.svg?react"
import ATTRACTION15 from "./attraction-15.svg?react"
import BACKGROUNDCIRCLE from "./background_circle.svg?react"
import BACKGROUNDFORBIDDEN from "./background_forbidden.svg?react"
import BACKGROUNDOCTOGON from "./background_octogon.svg?react"
import BACKGROUNDSAFETY from "./background_safety.svg?react"
import BACKGROUNDSECURITY from "./background_security.svg?react"
import BACKGROUNDSECURITY02 from "./background_security_02.svg?react"
import BACKGROUNDSQUARE from "./background_square.svg?react"
import BACKGROUNDSQUAREROUNDED from "./background_square_rounded.svg?react"
import BACKGROUNDTILTEDSQUARE from "./background_tilted_square.svg?react"
import BACKGROUNDTILTEDSQUAREROUNDED from "./background_tilted_square_rounded.svg?react"
import BACKGROUNDTRIANGLE from "./background_triangle.svg?react"
import BAKERY11 from "./bakery-11.svg?react"
import BAKERY15 from "./bakery-15.svg?react"
import BANK1 from "./bank-1.svg?react"
import BANK11 from "./bank-11.svg?react"
import BANK15 from "./bank-15.svg?react"
import BANK3 from "./bank-3.svg?react"
import BAR11 from "./bar-11.svg?react"
import BAR15 from "./bar-15.svg?react"
import BARRIERBOLLARD from "./barrier_bollard.svg?react"
import BARRIERENTERANCE from "./barrier_enterance.svg?react"
import BARRIERGATE from "./barrier_gate.svg?react"
import BARRIERLIFTGATE from "./barrier_lift_gate.svg?react"
import BARRIERSTILE from "./barrier_stile.svg?react"
import BARRIERTOLLBOOTH from "./barrier_toll_booth.svg?react"
import BATTERYINDICATOR from "./battery-indicator.svg?react"
import BEER11 from "./beer-11.svg?react"
import BEER15 from "./beer-15.svg?react"
import BICYCLE11 from "./bicycle-11.svg?react"
import BICYCLE15 from "./bicycle-15.svg?react"
import BICYCLESHARE11 from "./bicycle-share-11.svg?react"
import BICYCLESHARE15 from "./bicycle-share-15.svg?react"
import BLUEMARKER from "./blue-marker.svg?react"
import BOAT15BLUE from "./boat-15-blue.svg?react"
import BOAT15GRAY from "./boat-15-gray.svg?react"
import BOAT15GREEN from "./boat-15-green.svg?react"
import BOAT15MAGENTA from "./boat-15-magenta.svg?react"
import BOAT15ORANGE from "./boat-15-orange.svg?react"
import BOAT15PURPLE from "./boat-15-purple.svg?react"
import BOAT15RED from "./boat-15-red.svg?react"
import BOAT15TEAL from "./boat-15-teal.svg?react"
import BOAT15YELLOW from "./boat-15-yellow.svg?react"
import BOAT15 from "./boat-15.svg?react"
import BOAT from "./boat.svg?react"
import BOLTFILL0WGHT300GRAD200OPSZ40 from "./bolt_FILL0_wght300_GRAD200_opsz40.svg?react"
import BUILDING1 from "./building-1.svg?react"
import BUOY from "./buoy.svg?react"
import BUS11 from "./bus-11.svg?react"
import BUS15 from "./bus-15.svg?react"
import CABLEROUNDED from "./cable-rounded.svg?react"
import CAFE11 from "./cafe-11.svg?react"
import CAFE15 from "./cafe-15.svg?react"
import CAMERA from "./camera.svg?react"
import CAMPSITE11 from "./campsite-11.svg?react"
import CAMPSITE15 from "./campsite-15.svg?react"
import CAR11 from "./car-11.svg?react"
import CAR15 from "./car-15.svg?react"
import CAR2 from "./car-2.svg?react"
import CAROUTLINE from "./car-outline.svg?react"
import CASTLE11 from "./castle-11.svg?react"
import CASTLE15 from "./castle-15.svg?react"
import CEMETERY11 from "./cemetery-11.svg?react"
import CEMETERY15 from "./cemetery-15.svg?react"
import CHARGERFILL0WGHT300GRAD200OPSZ40 from "./charger_FILL0_wght300_GRAD200_opsz40.svg?react"
import CHECK from "./check.svg?react"
import CINEMA11 from "./cinema-11.svg?react"
import CINEMA15 from "./cinema-15.svg?react"
import CIRCLE1 from "./circle-1.svg?react"
import CIRCLE11 from "./circle-11.svg?react"
import CIRCLE15 from "./circle-15.svg?react"
import CIRCLESTROKED11 from "./circle-stroked-11.svg?react"
import CIRCLESTROKED15 from "./circle-stroked-15.svg?react"
import CITY1 from "./city-1.svg?react"
import CITYBUILDING from "./city_building.svg?react"
import CITYLARGE from "./city_large.svg?react"
import CITYMEDIUM from "./city_medium.svg?react"
import CITYSMALL from "./city_small.svg?react"
import CLEANWATER from "./clean-water.svg?react"
import CLOTHINGSTORE11 from "./clothing-store-11.svg?react"
import CLOTHINGSTORE15 from "./clothing-store-15.svg?react"
import COLLEGE11 from "./college-11.svg?react"
import COLLEGE15 from "./college-15.svg?react"
import COMPASS from "./compass.svg?react"
import CONSTRUCTIONFILL0WGHT300GRAD200OPSZ40 from "./construction_FILL0_wght300_GRAD200_opsz40.svg?react"
import CONVENEINCE from "./conveneince.svg?react"
import CORALPINNACLE from "./coral-pinnacle.svg?react"
import COUPLE from "./couple.svg?react"
import CROSS1 from "./Cross1.svg?react"
import CROSS2 from "./Cross2.svg?react"
import CROSS4 from "./Cross4.svg?react"
import CROSS5 from "./Cross5.svg?react"
import CROSS6 from "./Cross6.svg?react"
import CROSS8 from "./cross8.svg?react"
import CYCLING from "./cycling.svg?react"
import DEER from "./deer.svg?react"
import DELIVERYTRUCK from "./delivery-truck.svg?react"
import DENTIST11 from "./dentist-11.svg?react"
import DENTIST15 from "./dentist-15.svg?react"
import DISABILITYACCESSIBILITY from "./disability_accessibility.svg?react"
import DISABILITYACCESSIBILITY2 from "./disability_accessibility2.svg?react"
import DISABILITYLOWVISION from "./disability_lowvision.svg?react"
import DOCTOR11 from "./doctor-11.svg?react"
import DOCTOR15 from "./doctor-15.svg?react"
import DOGPARK11 from "./dog-park-11.svg?react"
import DOGPARK15 from "./dog-park-15.svg?react"
import DOLLAR from "./dollar.svg?react"
import DRINKINGWATER11 from "./drinking-water-11.svg?react"
import DRINKINGWATER15 from "./drinking-water-15.svg?react"
import DRONE from "./drone.svg?react"
import EDUCATIONNURSERY from "./education_nursery.svg?react"
import EDUCATIONSCHOOL from "./education_school.svg?react"
import EDUCATIONUNIVERSITY from "./education_university.svg?react"
import EMBASSY11 from "./embassy-11.svg?react"
import EMBASSY15 from "./embassy-15.svg?react"
import ENTRANCE11 from "./entrance-11.svg?react"
import ENTRANCE15 from "./entrance-15.svg?react"
import EVCHARGE from "./ev-charge.svg?react"
import EVPLUGCHARGING from "./ev-plug-charging.svg?react"
import EVPLUGERROR from "./ev-plug-error.svg?react"
import EVPLUG from "./ev-plug.svg?react"
import EVSTATION from "./ev-station.svg?react"
import FACTORYFILL0WGHT300GRAD200OPSZ40 from "./factory_FILL0_wght300_GRAD200_opsz40.svg?react"
import FASTFOOD11 from "./fast-food-11.svg?react"
import FASTFOOD15 from "./fast-food-15.svg?react"
import FERRY11 from "./ferry-11.svg?react"
import FERRY15 from "./ferry-15.svg?react"
import FIRESTATION11 from "./fire-station-11.svg?react"
import FIRESTATION15 from "./fire-station-15.svg?react"
import FISH from "./fish.svg?react"
import FISHING from "./Fishing.svg?react"
import FLAG from "./flag.svg?react"
import FLARE from "./flare.svg?react"
import FLASHOFF from "./flash-off.svg?react"
import FLASH from "./flash.svg?react"
import FLOWER from "./flower.svg?react"
import FOOD from "./food.svg?react"
import FOUNTAIN from "./fountain.svg?react"
import FUEL11 from "./fuel-11.svg?react"
import FUEL15 from "./fuel-15.svg?react"
import GARDEN11 from "./garden-11.svg?react"
import GARDEN15 from "./garden-15.svg?react"
import GASTANKDROP from "./gas-tank-drop.svg?react"
import GASTANK from "./gas-tank.svg?react"
import GAS from "./gas.svg?react"
import GOLF1 from "./golf-1.svg?react"
import GOLF11 from "./golf-11.svg?react"
import GOLF15 from "./golf-15.svg?react"
import GOLF3 from "./golf-3.svg?react"
import GPS from "./gps.svg?react"
import GROCERY11 from "./grocery-11.svg?react"
import GROCERY15 from "./grocery-15.svg?react"
import H from "./h.svg?react"
import HAMMER from "./hammer.svg?react"
import HARBOR11 from "./harbor-11.svg?react"
import HARBOR15 from "./harbor-15.svg?react"
import HELIPORT11 from "./heliport-11.svg?react"
import HELIPORT15 from "./heliport-15.svg?react"
import HEPTAGON from "./heptagon.svg?react"
import HEXAGONALT from "./hexagon-alt.svg?react"
import HEXAGON from "./hexagon.svg?react"
import HIGHWAYBUSSTOP from "./highway=bus_stop.svg?react"
import HOSPITAL11 from "./hospital-11.svg?react"
import HOSPITAL15 from "./hospital-15.svg?react"
import HOSPITALSIGN from "./hospital-sign.svg?react"
import HOUSE from "./house.svg?react"
import ICECREAM11 from "./ice-cream-11.svg?react"
import ICECREAM15 from "./ice-cream-15.svg?react"
import INDUSTRY1 from "./industry-1.svg?react"
import INFORMATION11 from "./information-11.svg?react"
import INFORMATION15 from "./information-15.svg?react"
import IOGP1006 from "./IOGP1006.svg?react"
import IOGP1007 from "./IOGP1007.svg?react"
import IOGP1012 from "./IOGP1012.svg?react"
import IOGP1014 from "./IOGP1014.svg?react"
import IOGP1021 from "./IOGP1021.svg?react"
import IOGP1022 from "./IOGP1022.svg?react"
import IOGP1026 from "./IOGP1026.svg?react"
import IOGP1027 from "./IOGP1027.svg?react"
import IOGP1028 from "./IOGP1028.svg?react"
import IOGP1030 from "./IOGP1030.svg?react"
import IOGP1034 from "./IOGP1034.svg?react"
import IOGP1040 from "./IOGP1040.svg?react"
import IOGP1041 from "./IOGP1041.svg?react"
import IOGP1045 from "./IOGP1045.svg?react"
import IOGP1047 from "./IOGP1047.svg?react"
import IOGP1101 from "./IOGP1101.svg?react"
import IOGP1103 from "./IOGP1103.svg?react"
import IOGP1104 from "./IOGP1104.svg?react"
import IOGP1105 from "./IOGP1105.svg?react"
import IOGP1107 from "./IOGP1107.svg?react"
import IOGP1108 from "./IOGP1108.svg?react"
import IOGP1109 from "./IOGP1109.svg?react"
import IOGP1110 from "./IOGP1110.svg?react"
import IOGP1111 from "./IOGP1111.svg?react"
import IOGP1115 from "./IOGP1115.svg?react"
import IOGP1116 from "./IOGP1116.svg?react"
import IOGP1117 from "./IOGP1117.svg?react"
import ISOLATEDDEPRESSION from "./isolated-depression.svg?react"
import KEYFRAME from "./keyframe.svg?react"
import KEYFRAMESCOUPLE from "./keyframes-couple.svg?react"
import LANDUSECONIFEROUS from "./landuse_coniferous.svg?react"
import LANDUSECONIFEROUSANDDECIDUOUS from "./landuse_coniferous_and_deciduous.svg?react"
import LANDUSEDECIDUOUS from "./landuse_deciduous.svg?react"
import LANDUSEGRASS from "./landuse_grass.svg?react"
import LANDUSEHILLS from "./landuse_hills.svg?react"
import LANDUSEQUARY from "./landuse_quary.svg?react"
import LANDUSESCRUB from "./landuse_scrub.svg?react"
import LANDUSESWAMP from "./landuse_swamp.svg?react"
import LANGUAGE from "./language.svg?react"
import LAUNDRY11 from "./laundry-11.svg?react"
import LAUNDRY15 from "./laundry-15.svg?react"
import LEAF from "./leaf.svg?react"
import LIBRARY11 from "./library-11.svg?react"
import LIBRARY15 from "./library-15.svg?react"
import LIFEBELT from "./lifebelt.svg?react"
import LOCATIONSEARCHINGFILL0WGHT400GRAD0OPSZ48 from "./location_searching_FILL0_wght400_GRAD0_opsz48.svg?react"
import LODGING11 from "./lodging-11.svg?react"
import LODGING15 from "./lodging-15.svg?react"
import MAPISSUE from "./map-issue.svg?react"
import MAP from "./map.svg?react"
import MAPSARROWDIAGONAL from "./maps-arrow-diagonal.svg?react"
import MAPSARROWISSUE from "./maps-arrow-issue.svg?react"
import MAPSARROW from "./maps-arrow.svg?react"
import MAPSGOSTRAIGHT from "./maps-go-straight.svg?react"
import MAPSTURNBACK from "./maps-turn-back.svg?react"
import MAPSTURNLEFT from "./maps-turn-left.svg?react"
import MAPSTURNRIGHT from "./maps-turn-right.svg?react"
import MARKER11 from "./marker-11.svg?react"
import MARKER15 from "./marker-15.svg?react"
import MINUSHEXAGON from "./minus-hexagon.svg?react"
import MINUSPINALT from "./minus-pin-alt.svg?react"
import MONUMENT11 from "./monument-11.svg?react"
import MONUMENT15 from "./monument-15.svg?react"
import MOUNTAIN11 from "./mountain-11.svg?react"
import MOUNTAIN15 from "./mountain-15.svg?react"
import MUSEUM11 from "./museum-11.svg?react"
import MUSEUM15 from "./museum-15.svg?react"
import MUSIC11 from "./music-11.svg?react"
import MUSIC15 from "./music-15.svg?react"
import MYLOCATIONFILL0WGHT400GRAD0OPSZ48 from "./my_location_FILL0_wght400_GRAD0_opsz48.svg?react"
import NAVIGATIONALAID from "./navigational-aid.svg?react"
import NAVIGATORALT from "./navigator-alt.svg?react"
import NAVIGATOR from "./navigator.svg?react"
import NORTHARROW01 from "./NorthArrow_01.svg?react"
import NORTHARROW02 from "./NorthArrow_02.svg?react"
import NORTHARROW03 from "./NorthArrow_03.svg?react"
import NORTHARROW04 from "./NorthArrow_04.svg?react"
import NORTHARROW05 from "./NorthArrow_05.svg?react"
import NORTHARROW06 from "./NorthArrow_06.svg?react"
import NORTHARROW07 from "./NorthArrow_07.svg?react"
import NORTHARROW08 from "./NorthArrow_08.svg?react"
import NORTHARROW09 from "./NorthArrow_09.svg?react"
import NORTHARROW10 from "./NorthArrow_10.svg?react"
import NORTHARROW11 from "./NorthArrow_11.svg?react"
import OCTAGON from "./octagon.svg?react"
import OILINDUSTRY from "./oil-industry.svg?react"
import OILBARRELFILL0WGHT300GRAD200OPSZ40 from "./oil_barrel_FILL0_wght300_GRAD200_opsz40.svg?react"
import PANORAMA from "./panorama.svg?react"
import PARACHUTE from "./parachute.svg?react"
import PARK11 from "./park-11.svg?react"
import PARK15 from "./park-15.svg?react"
import PARKING2 from "./parking-2.svg?react"
import PENTAGON from "./pentagon.svg?react"
import PHARMACY11 from "./pharmacy-11.svg?react"
import PHARMACY15 from "./pharmacy-15.svg?react"
import PHONE from "./phone.svg?react"
import PICNICSITE11 from "./picnic-site-11.svg?react"
import PICNICSITE15 from "./picnic-site-15.svg?react"
import PINALT from "./pin-alt.svg?react"
import PIN from "./pin.svg?react"
import PINETREE from "./pine-tree.svg?react"
import PLACEOFWORSHIP11 from "./place-of-worship-11.svg?react"
import PLACEOFWORSHIP15 from "./place-of-worship-15.svg?react"
import PLANE from "./plane.svg?react"
import PLANEORANGE from "./plane_orange.svg?react"
import PLAYGROUND11 from "./playground-11.svg?react"
import PLAYGROUND15 from "./playground-15.svg?react"
import PLUS from "./plus.svg?react"
import POIBOUNDARYADMINISTRATIVE from "./poi_boundary_administrative.svg?react"
import POICAVE from "./poi_cave.svg?react"
import POIEMBASSY from "./poi_embassy.svg?react"
import POIEMBASSY2 from "./poi_embassy2.svg?react"
import POIMILITARYBUNKER from "./poi_military_bunker.svg?react"
import POIMINE from "./poi_mine.svg?react"
import POIMINEABANDONED from "./poi_mine_abandoned.svg?react"
import POIPEAK from "./poi_peak.svg?react"
import POIPLACECITY from "./poi_place_city.svg?react"
import POIPLACETOWN from "./poi_place_town.svg?react"
import POIPLACEVILLAGE from "./poi_place_village.svg?react"
import POIPOINTOFINTEREST from "./poi_point_of_interest.svg?react"
import POITOWERCOMMUNICATIONS from "./poi_tower_communications.svg?react"
import POITOWERPOWER from "./poi_tower_power.svg?react"
import POITOWERWATER from "./poi_tower_water.svg?react"
import POINT from "./point.svg?react"
import POLICE11 from "./police-11.svg?react"
import POLICE15 from "./police-15.svg?react"
import POSITION from "./position.svg?react"
import POST11 from "./post-11.svg?react"
import POST15 from "./post-15.svg?react"
import POWERINPUTFILL0WGHT300GRAD200OPSZ40 from "./power_input_FILL0_wght300_GRAD200_opsz40.svg?react"
import PRISON11 from "./prison-11.svg?react"
import PRISON15 from "./prison-15.svg?react"
import QUESTIONMARK from "./question-mark.svg?react"
import QUESTION from "./question.svg?react"
import RADIOBUTTONCHECKEDFILL0WGHT400GRAD0OPSZ48 from "./radio_button_checked_FILL0_wght400_GRAD0_opsz48.svg?react"
import RADIOBUTTONUNCHECKEDFILL0WGHT400GRAD0OPSZ48 from "./radio_button_unchecked_FILL0_wght400_GRAD0_opsz48.svg?react"
import RAIL11 from "./rail-11.svg?react"
import RAIL15 from "./rail-15.svg?react"
import RAILLIGHT11 from "./rail-light-11.svg?react"
import RAILLIGHT15 from "./rail-light-15.svg?react"
import RAILMETRO11 from "./rail-metro-11.svg?react"
import RAILMETRO15 from "./rail-metro-15.svg?react"
import RAILWAYSTATION from "./railway=station.svg?react"
import RECTANGLEFILL0WGHT400GRAD0OPSZ48 from "./rectangle_FILL0_wght400_GRAD0_opsz48.svg?react"
import REDMARKER from "./red-marker.svg?react"
import RELIGIOUSCHRISTIAN11 from "./religious-christian-11.svg?react"
import RELIGIOUSCHRISTIAN15 from "./religious-christian-15.svg?react"
import RELIGIOUSJEWISH11 from "./religious-jewish-11.svg?react"
import RELIGIOUSJEWISH15 from "./religious-jewish-15.svg?react"
import RELIGIOUSMUSLIM11 from "./religious-muslim-11.svg?react"
import RELIGIOUSMUSLIM15 from "./religious-muslim-15.svg?react"
import REMOVEEMPTY from "./remove-empty.svg?react"
import REMOVEKEYFRAMEALT from "./remove-keyframe-alt.svg?react"
import REMOVEPINALT from "./remove-pin-alt.svg?react"
import REMOVESQUARE from "./remove-square.svg?react"
import RESTAURANT11 from "./restaurant-11.svg?react"
import RESTAURANT15 from "./restaurant-15.svg?react"
import RHOMBUS from "./rhombus.svg?react"
import ROCKET11 from "./rocket-11.svg?react"
import ROCKET15 from "./rocket-15.svg?react"
import RUNNING from "./running.svg?react"
import SCHOOL11 from "./school-11.svg?react"
import SCHOOL15 from "./school-15.svg?react"
import SHIPWRECK from "./shipwreck.svg?react"
import SHOP1 from "./shop-1.svg?react"
import SHOP11 from "./shop-11.svg?react"
import SHOP15 from "./shop-15.svg?react"
import SHOPALT from "./shop-alt.svg?react"
import SHOPPINGALCOHOL from "./shopping_alcohol.svg?react"
import SHOPPINGBAKERY from "./shopping_bakery.svg?react"
import SHOPPINGBICYCLE from "./shopping_bicycle.svg?react"
import SHOPPINGBOOK from "./shopping_book.svg?react"
import SHOPPINGBUTCHER from "./shopping_butcher.svg?react"
import SHOPPINGCAR from "./shopping_car.svg?react"
import SHOPPINGCARREPAIR from "./shopping_car_repair.svg?react"
import SHOPPINGCLOTHES from "./shopping_clothes.svg?react"
import SHOPPINGCONFECTIONERY from "./shopping_confectionery.svg?react"
import SHOPPINGCONVENIENCE from "./shopping_convenience.svg?react"
import SHOPPINGDIY from "./shopping_diy.svg?react"
import SHOPPINGESTATEAGENT from "./shopping_estateagent.svg?react"
import SHOPPINGESTATEAGENT2 from "./shopping_estateagent2.svg?react"
import SHOPPINGFISH from "./shopping_fish.svg?react"
import SHOPPINGGARDENCENTRE from "./shopping_garden_centre.svg?react"
import SHOPPINGGIFT from "./shopping_gift.svg?react"
import SHOPPINGGREENGROCER from "./shopping_greengrocer.svg?react"
import SHOPPINGHAIRDRESSER from "./shopping_hairdresser.svg?react"
import SHOPPINGHIFI from "./shopping_hifi.svg?react"
import SHOPPINGJEWELRY from "./shopping_jewelry.svg?react"
import SHOPPINGLAUNDRETTE from "./shopping_laundrette.svg?react"
import SHOPPINGMOBILEPHONE from "./shopping_mobile_phone.svg?react"
import SHOPPINGMOTORCYCLE from "./shopping_motorcycle.svg?react"
import SHOPPINGMUSIC from "./shopping_music.svg?react"
import SHOPPINGPET from "./shopping_pet.svg?react"
import SHOPPINGPET2 from "./shopping_pet2.svg?react"
import SHOPPINGPHOTO from "./shopping_photo.svg?react"
import SHOPPINGSUPERMARKET from "./shopping_supermarket.svg?react"
import SHOPPINGTACKLE from "./shopping_tackle.svg?react"
import SHOPPINGVIDEORENTAL from "./shopping_video_rental.svg?react"
import SIDESCANSONARCONTACT from "./sidescan-sonar-contact.svg?react"
import SKIER from "./skier.svg?react"
import SKULL from "./skull.svg?react"
import SMALLSHOPALT from "./small-shop-alt.svg?react"
import SMALLSHOP from "./small-shop.svg?react"
import SOILALT from "./soil-alt.svg?react"
import SOIL from "./soil.svg?react"
import SOLARPOWERFILL0WGHT300GRAD200OPSZ40 from "./solar_power_FILL0_wght300_GRAD200_opsz40.svg?react"
import SONAR from "./sonar.svg?react"
import SPORTARCHERY from "./sport_archery.svg?react"
import SPORTBASEBALL from "./sport_baseball.svg?react"
import SPORTCRICKET from "./sport_cricket.svg?react"
import SPORTDIVING from "./sport_diving.svg?react"
import SPORTGOLF from "./sport_golf.svg?react"
import SPORTGYM from "./sport_gym.svg?react"
import SPORTGYMNASIUM from "./sport_gymnasium.svg?react"
import SPORTGYMNASIUM2 from "./sport_gymnasium2.svg?react"
import SPORTHILLCLIMBING from "./sport_hillclimbing.svg?react"
import SPORTHORSERACING from "./sport_horse_racing.svg?react"
import SPORTICESKATING from "./sport_iceskating.svg?react"
import SPORTJETSKI from "./sport_jetski.svg?react"
import SPORTLEISURECENTRE from "./sport_leisure_centre.svg?react"
import SPORTMOTORRACING from "./sport_motorracing.svg?react"
import SPORTPLAYGROUND from "./sport_playground.svg?react"
import SPORTSAILING from "./sport_sailing.svg?react"
import SPORTSKIINGCROSSCOUNTRY from "./sport_skiing_crosscountry.svg?react"
import SPORTSKIINGDOWNHILL from "./sport_skiing_downhill.svg?react"
import SPORTSNOOKER from "./sport_snooker.svg?react"
import SPORTSOCCER from "./sport_soccer.svg?react"
import SPORTSWIMMINGINDOOR from "./sport_swimming_indoor.svg?react"
import SPORTSWIMMINGOUTDOOR from "./sport_swimming_outdoor.svg?react"
import SPORTTENNIS from "./sport_tennis.svg?react"
import SPORTWINDSURFING from "./sport_windsurfing.svg?react"
import SQUARE1 from "./square-1.svg?react"
import STADIUM11 from "./stadium-11.svg?react"
import STADIUM15 from "./stadium-15.svg?react"
import STAR11 from "./star-11.svg?react"
import STAR15 from "./star-15.svg?react"
import STAR1 from "./Star1.svg?react"
import STAR2 from "./Star2.svg?react"
import STAR3 from "./Star3.svg?react"
import STORMFILL0WGHT300GRAD200OPSZ40 from "./storm_FILL0_wght300_GRAD200_opsz40.svg?react"
import SUGGESTION from "./suggestion.svg?react"
import SUITCASE11 from "./suitcase-11.svg?react"
import SUITCASE15 from "./suitcase-15.svg?react"
import SUNLIGHT from "./sun-light.svg?react"
import SWIMMER from "./swimmer.svg?react"
import SWIMMING11 from "./swimming-11.svg?react"
import SWIMMING15 from "./swimming-15.svg?react"
import TABLE from "./table.svg?react"
import TEEPEE from "./teepee.svg?react"
import THEATRE11 from "./theatre-11.svg?react"
import THEATRE15 from "./theatre-15.svg?react"
import TOILET11 from "./toilet-11.svg?react"
import TOILET15 from "./toilet-15.svg?react"
import TOOLS from "./tools.svg?react"
import TORNADOFILL0WGHT300GRAD200OPSZ40 from "./tornado_FILL0_wght300_GRAD200_opsz40.svg?react"
import TOURISTARCHAEOLOGICAL from "./tourist_archaeological.svg?react"
import TOURISTARCHAEOLOGICAL2 from "./tourist_archaeological2.svg?react"
import TOURISTARTGALLERY from "./tourist_art_gallery.svg?react"
import TOURISTARTGALLERY2 from "./tourist_art_gallery2.svg?react"
import TOURISTBATTLEFIELD from "./tourist_battlefield.svg?react"
import TOURISTBEACH from "./tourist_beach.svg?react"
import TOURISTCASINO from "./tourist_casino.svg?react"
import TOURISTCASTLE from "./tourist_castle.svg?react"
import TOURISTCINEMA from "./tourist_cinema.svg?react"
import TOURISTCINEMA2 from "./tourist_cinema2.svg?react"
import TOURISTFOUNTAIN from "./tourist_fountain.svg?react"
import TOURISTMEMORIAL from "./tourist_memorial.svg?react"
import TOURISTMONUMENT from "./tourist_monument.svg?react"
import TOURISTMUSEUM from "./tourist_museum.svg?react"
import TOURISTPICNIC from "./tourist_picnic.svg?react"
import TOURISTRUIN from "./tourist_ruin.svg?react"
import TOURISTSTEAMTRAIN from "./tourist_steam_train.svg?react"
import TOURISTTHEATRE from "./tourist_theatre.svg?react"
import TOURISTVIEWPOINT from "./tourist_view_point.svg?react"
import TOURISTWATERWHEEL from "./tourist_waterwheel.svg?react"
import TOURISTWINDMILL from "./tourist_windmill.svg?react"
import TOURISTWRECK from "./tourist_wreck.svg?react"
import TOURISTZOO from "./tourist_zoo.svg?react"
import TOURISUMFOUNTAIN from "./tourisum_fountain.svg?react"
import TOWNHALL11 from "./town-hall-11.svg?react"
import TOWNHALL15 from "./town-hall-15.svg?react"
import TRAINOUTLINE from "./train-outline.svg?react"
import TRAM from "./tram.svg?react"
import TRANSPORTAERODROME from "./transport_aerodrome.svg?react"
import TRANSPORTAERODROME2 from "./transport_aerodrome2.svg?react"
import TRANSPORTAIRPORT from "./transport_airport.svg?react"
import TRANSPORTAIRPORT2 from "./transport_airport2.svg?react"
import TRANSPORTBUSSTATION from "./transport_bus_station.svg?react"
import TRANSPORTBUSSTOP from "./transport_bus_stop.svg?react"
import TRANSPORTBUSSTOP2 from "./transport_bus_stop2.svg?react"
import TRANSPORTCARSHARE from "./transport_car_share.svg?react"
import TRANSPORTFORD from "./transport_ford.svg?react"
import TRANSPORTFUEL from "./transport_fuel.svg?react"
import TRANSPORTFUELLPG from "./transport_fuel_lpg.svg?react"
import TRANSPORTHELICOPTER from "./transport_helicopter.svg?react"
import TRANSPORTLIGHTHOUSE from "./transport_lighthouse.svg?react"
import TRANSPORTMARINA from "./transport_marina.svg?react"
import TRANSPORTPARKING from "./transport_parking.svg?react"
import TRANSPORTPARKINGBICYCLE from "./transport_parking_bicycle.svg?react"
import TRANSPORTPARKINGCAR from "./transport_parking_car.svg?react"
import TRANSPORTPARKINGCARPAID from "./transport_parking_car_paid.svg?react"
import TRANSPORTPARKINGDISABLED from "./transport_parking_disabled.svg?react"
import TRANSPORTPARKINGPRIVATE from "./transport_parking_private.svg?react"
import TRANSPORTPARKINGPRIVATE2 from "./transport_parking_private2.svg?react"
import TRANSPORTPARKINGPRIVATE3 from "./transport_parking_private3.svg?react"
import TRANSPORTPORT from "./transport_port.svg?react"
import TRANSPORTRENTALBICYCLE from "./transport_rental_bicycle.svg?react"
import TRANSPORTRENTALCAR from "./transport_rental_car.svg?react"
import TRANSPORTROUNDABOUTANTICLOCKWISE from "./transport_roundabout_anticlockwise.svg?react"
import TRANSPORTROUNDABOUTCLOCKWISE from "./transport_roundabout_clockwise.svg?react"
import TRANSPORTTAXIRANK from "./transport_taxi_rank.svg?react"
import TRANSPORTTRAFFICLIGHTS from "./transport_traffic_lights.svg?react"
import TRANSPORTTRAINSTATION from "./transport_train_station.svg?react"
import TRANSPORTTRAINSTATION2 from "./transport_train_station2.svg?react"
import TRANSPORTTRAMSTOP from "./transport_tram_stop.svg?react"
import TREE1 from "./tree-1.svg?react"
import TREE2 from "./tree-2.svg?react"
import TRIANGLE1 from "./triangle-1.svg?react"
import TRIANGLE11 from "./triangle-11.svg?react"
import TRIANGLE15 from "./triangle-15.svg?react"
import TRIANGLEFLAGCIRCLE from "./triangle-flag-circle.svg?react"
import TRIANGLEFLAGFULL from "./triangle-flag-full.svg?react"
import TRIANGLEFLAG from "./triangle-flag.svg?react"
import TRIANGLESTROKED11 from "./triangle-stroked-11.svg?react"
import TRIANGLESTROKED15 from "./triangle-stroked-15.svg?react"
import TRUCK from "./truck.svg?react"
import VETERINARY11 from "./veterinary-11.svg?react"
import VETERINARY15 from "./veterinary-15.svg?react"
import VOLCANO11 from "./volcano-11.svg?react"
import VOLCANO15 from "./volcano-15.svg?react"
import WALKER from "./walker.svg?react"
import WAREHOUSEFILL0WGHT300GRAD200OPSZ40 from "./warehouse_FILL0_wght300_GRAD200_opsz40.svg?react"
import WARNINGCIRCLEDOUTLINE from "./warning-circled-outline.svg?react"
import WARNINGSQUAREOUTLINE from "./warning-square-outline.svg?react"
import WARNINGTRIANGLEOUTLINE from "./warning-triangle-outline.svg?react"
import WATERTOWER from "./water_tower.svg?react"
import WATERINGSOIL from "./watering-soil.svg?react"
import WAYPOINT from "./waypoint.svg?react"
import WHITEFLAG from "./white-flag.svg?react"
import WIFISIGNALNONE from "./wifi-signal-none.svg?react"
import WIFI from "./wifi.svg?react"
import WINDPOWERFILL0WGHT300GRAD200OPSZ40 from "./wind_power_FILL0_wght300_GRAD200_opsz40.svg?react"
import WINDROSE01 from "./WindRose_01.svg?react"
import WINDROSE02 from "./WindRose_02.svg?react"
import WRENCH from "./wrench.svg?react"
import ZOO11 from "./zoo-11.svg?react"
import ZOO15 from "./zoo-15.svg?react"

const icons = {
    activity: <ACTIVITY />,
    "add-hexagon": <ADDHEXAGON />,
    "add-keyframe-alt": <ADDKEYFRAMEALT />,
    "add-pin-alt": <ADDPINALT />,
    "african-tree": <AFRICANTREE />,
    "air-FILL0-wght300-GRAD200-opsz40": <AIRFILL0WGHT300GRAD200OPSZ40 />,
    "airfield-11": <AIRFIELD11 />,
    "airfield-15": <AIRFIELD15 />,
    airplane: <AIRPLANE />,
    "airplane-helix": <AIRPLANEHELIX />,
    "airplane-helix-45deg": <AIRPLANEHELIX45DEG />,
    "airplane-off": <AIRPLANEOFF />,
    "airplane-rotation": <AIRPLANEROTATION />,
    "airport-11": <AIRPORT11 />,
    "airport-15": <AIRPORT15 />,
    "alcohol-shop-11": <ALCOHOLSHOP11 />,
    "alcohol-shop-15": <ALCOHOLSHOP15 />,
    "amenity-airport": <AMENITYAIRPORT />,
    "amenity-ferry-terminal": <AMENITYFERRYTERMINAL />,
    "amenity-parking": <AMENITYPARKING />,
    "amenity-taxi": <AMENITYTAXI />,
    "amusement-park-11": <AMUSEMENTPARK11 />,
    "amusement-park-15": <AMUSEMENTPARK15 />,
    anchor: <ANCHOR />,
    "angle-tool": <ANGLETOOL />,
    antenna: <ANTENNA />,
    "antenna-off": <ANTENNAOFF />,
    "aquarium-11": <AQUARIUM11 />,
    "aquarium-15": <AQUARIUM15 />,
    "Arrow-01": <ARROW01 />,
    "Arrow-02": <ARROW02 />,
    "Arrow-03": <ARROW03 />,
    "Arrow-04": <ARROW04 />,
    "Arrow-05": <ARROW05 />,
    "Arrow-06": <ARROW06 />,
    "arrow-down": <ARROWDOWN />,
    "arrow-down-circled": <ARROWDOWNCIRCLED />,
    "arrow-left": <ARROWLEFT />,
    "arrow-left-circled": <ARROWLEFTCIRCLED />,
    "arrow-right": <ARROWRIGHT />,
    "arrow-right-circled": <ARROWRIGHTCIRCLED />,
    "arrow-up": <ARROWUP />,
    "arrow-up-circled": <ARROWUPCIRCLED />,
    "art-gallery-11": <ARTGALLERY11 />,
    "art-gallery-15": <ARTGALLERY15 />,
    "attraction-11": <ATTRACTION11 />,
    "attraction-15": <ATTRACTION15 />,
    "background-circle": <BACKGROUNDCIRCLE />,
    "background-forbidden": <BACKGROUNDFORBIDDEN />,
    "background-octogon": <BACKGROUNDOCTOGON />,
    "background-safety": <BACKGROUNDSAFETY />,
    "background-security": <BACKGROUNDSECURITY />,
    "background-security-02": <BACKGROUNDSECURITY02 />,
    "background-square": <BACKGROUNDSQUARE />,
    "background-square-rounded": <BACKGROUNDSQUAREROUNDED />,
    "background-tilted-square": <BACKGROUNDTILTEDSQUARE />,
    "background-tilted-square-rounded": <BACKGROUNDTILTEDSQUAREROUNDED />,
    "background-triangle": <BACKGROUNDTRIANGLE />,
    "bakery-11": <BAKERY11 />,
    "bakery-15": <BAKERY15 />,
    "bank-1": <BANK1 />,
    "bank-11": <BANK11 />,
    "bank-15": <BANK15 />,
    "bank-3": <BANK3 />,
    "bar-11": <BAR11 />,
    "bar-15": <BAR15 />,
    "barrier-bollard": <BARRIERBOLLARD />,
    "barrier-enterance": <BARRIERENTERANCE />,
    "barrier-gate": <BARRIERGATE />,
    "barrier-lift-gate": <BARRIERLIFTGATE />,
    "barrier-stile": <BARRIERSTILE />,
    "barrier-toll-booth": <BARRIERTOLLBOOTH />,
    "battery-indicator": <BATTERYINDICATOR />,
    "beer-11": <BEER11 />,
    "beer-15": <BEER15 />,
    "bicycle-11": <BICYCLE11 />,
    "bicycle-15": <BICYCLE15 />,
    "bicycle-share-11": <BICYCLESHARE11 />,
    "bicycle-share-15": <BICYCLESHARE15 />,
    "blue-marker": <BLUEMARKER />,
    boat: <BOAT />,
    "boat-15": <BOAT15 />,
    "boat-15-blue": <BOAT15BLUE />,
    "boat-15-gray": <BOAT15GRAY />,
    "boat-15-green": <BOAT15GREEN />,
    "boat-15-magenta": <BOAT15MAGENTA />,
    "boat-15-orange": <BOAT15ORANGE />,
    "boat-15-purple": <BOAT15PURPLE />,
    "boat-15-red": <BOAT15RED />,
    "boat-15-teal": <BOAT15TEAL />,
    "boat-15-yellow": <BOAT15YELLOW />,
    "bolt-FILL0-wght300-GRAD200-opsz40": <BOLTFILL0WGHT300GRAD200OPSZ40 />,
    "building-1": <BUILDING1 />,
    buoy: <BUOY />,
    "bus-11": <BUS11 />,
    "bus-15": <BUS15 />,
    "cable-rounded": <CABLEROUNDED />,
    "cafe-11": <CAFE11 />,
    "cafe-15": <CAFE15 />,
    camera: <CAMERA />,
    "campsite-11": <CAMPSITE11 />,
    "campsite-15": <CAMPSITE15 />,
    "car-11": <CAR11 />,
    "car-15": <CAR15 />,
    "car-2": <CAR2 />,
    "car-outline": <CAROUTLINE />,
    "castle-11": <CASTLE11 />,
    "castle-15": <CASTLE15 />,
    "cemetery-11": <CEMETERY11 />,
    "cemetery-15": <CEMETERY15 />,
    "charger-FILL0-wght300-GRAD200-opsz40": <CHARGERFILL0WGHT300GRAD200OPSZ40 />,
    check: <CHECK />,
    "cinema-11": <CINEMA11 />,
    "cinema-15": <CINEMA15 />,
    "circle-1": <CIRCLE1 />,
    "circle-11": <CIRCLE11 />,
    "circle-15": <CIRCLE15 />,
    "circle-stroked-11": <CIRCLESTROKED11 />,
    "circle-stroked-15": <CIRCLESTROKED15 />,
    "city-1": <CITY1 />,
    "city-building": <CITYBUILDING />,
    "city-large": <CITYLARGE />,
    "city-medium": <CITYMEDIUM />,
    "city-small": <CITYSMALL />,
    "clean-water": <CLEANWATER />,
    "clothing-store-11": <CLOTHINGSTORE11 />,
    "clothing-store-15": <CLOTHINGSTORE15 />,
    "college-11": <COLLEGE11 />,
    "college-15": <COLLEGE15 />,
    compass: <COMPASS />,
    "construction-FILL0-wght300-GRAD200-opsz40": <CONSTRUCTIONFILL0WGHT300GRAD200OPSZ40 />,
    conveneince: <CONVENEINCE />,
    "coral-pinnacle": <CORALPINNACLE />,
    couple: <COUPLE />,
    Cross1: <CROSS1 />,
    Cross2: <CROSS2 />,
    Cross4: <CROSS4 />,
    Cross5: <CROSS5 />,
    Cross6: <CROSS6 />,
    cross8: <CROSS8 />,
    cycling: <CYCLING />,
    deer: <DEER />,
    "delivery-truck": <DELIVERYTRUCK />,
    "dentist-11": <DENTIST11 />,
    "dentist-15": <DENTIST15 />,
    "disability-accessibility": <DISABILITYACCESSIBILITY />,
    "disability-accessibility2": <DISABILITYACCESSIBILITY2 />,
    "disability-lowvision": <DISABILITYLOWVISION />,
    "doctor-11": <DOCTOR11 />,
    "doctor-15": <DOCTOR15 />,
    "dog-park-11": <DOGPARK11 />,
    "dog-park-15": <DOGPARK15 />,
    dollar: <DOLLAR />,
    "drinking-water-11": <DRINKINGWATER11 />,
    "drinking-water-15": <DRINKINGWATER15 />,
    drone: <DRONE />,
    "education-nursery": <EDUCATIONNURSERY />,
    "education-school": <EDUCATIONSCHOOL />,
    "education-university": <EDUCATIONUNIVERSITY />,
    "embassy-11": <EMBASSY11 />,
    "embassy-15": <EMBASSY15 />,
    "entrance-11": <ENTRANCE11 />,
    "entrance-15": <ENTRANCE15 />,
    "ev-charge": <EVCHARGE />,
    "ev-plug": <EVPLUG />,
    "ev-plug-charging": <EVPLUGCHARGING />,
    "ev-plug-error": <EVPLUGERROR />,
    "ev-station": <EVSTATION />,
    "factory-FILL0-wght300-GRAD200-opsz40": <FACTORYFILL0WGHT300GRAD200OPSZ40 />,
    "fast-food-11": <FASTFOOD11 />,
    "fast-food-15": <FASTFOOD15 />,
    "ferry-11": <FERRY11 />,
    "ferry-15": <FERRY15 />,
    "fire-station-11": <FIRESTATION11 />,
    "fire-station-15": <FIRESTATION15 />,
    fish: <FISH />,
    Fishing: <FISHING />,
    flag: <FLAG />,
    flare: <FLARE />,
    flash: <FLASH />,
    "flash-off": <FLASHOFF />,
    flower: <FLOWER />,
    food: <FOOD />,
    fountain: <FOUNTAIN />,
    "fuel-11": <FUEL11 />,
    "fuel-15": <FUEL15 />,
    "garden-11": <GARDEN11 />,
    "garden-15": <GARDEN15 />,
    gas: <GAS />,
    "gas-tank": <GASTANK />,
    "gas-tank-drop": <GASTANKDROP />,
    "golf-1": <GOLF1 />,
    "golf-11": <GOLF11 />,
    "golf-15": <GOLF15 />,
    "golf-3": <GOLF3 />,
    gps: <GPS />,
    "grocery-11": <GROCERY11 />,
    "grocery-15": <GROCERY15 />,
    h: <H />,
    hammer: <HAMMER />,
    "harbor-11": <HARBOR11 />,
    "harbor-15": <HARBOR15 />,
    "heliport-11": <HELIPORT11 />,
    "heliport-15": <HELIPORT15 />,
    heptagon: <HEPTAGON />,
    hexagon: <HEXAGON />,
    "hexagon-alt": <HEXAGONALT />,
    "highway-bus-stop": <HIGHWAYBUSSTOP />,
    "hospital-11": <HOSPITAL11 />,
    "hospital-15": <HOSPITAL15 />,
    "hospital-sign": <HOSPITALSIGN />,
    house: <HOUSE />,
    "ice-cream-11": <ICECREAM11 />,
    "ice-cream-15": <ICECREAM15 />,
    "industry-1": <INDUSTRY1 />,
    "information-11": <INFORMATION11 />,
    "information-15": <INFORMATION15 />,
    IOGP1006: <IOGP1006 />,
    IOGP1007: <IOGP1007 />,
    IOGP1012: <IOGP1012 />,
    IOGP1014: <IOGP1014 />,
    IOGP1021: <IOGP1021 />,
    IOGP1022: <IOGP1022 />,
    IOGP1026: <IOGP1026 />,
    IOGP1027: <IOGP1027 />,
    IOGP1028: <IOGP1028 />,
    IOGP1030: <IOGP1030 />,
    IOGP1034: <IOGP1034 />,
    IOGP1040: <IOGP1040 />,
    IOGP1041: <IOGP1041 />,
    IOGP1045: <IOGP1045 />,
    IOGP1047: <IOGP1047 />,
    IOGP1101: <IOGP1101 />,
    IOGP1103: <IOGP1103 />,
    IOGP1104: <IOGP1104 />,
    IOGP1105: <IOGP1105 />,
    IOGP1107: <IOGP1107 />,
    IOGP1108: <IOGP1108 />,
    IOGP1109: <IOGP1109 />,
    IOGP1110: <IOGP1110 />,
    IOGP1111: <IOGP1111 />,
    IOGP1115: <IOGP1115 />,
    IOGP1116: <IOGP1116 />,
    IOGP1117: <IOGP1117 />,
    keyframe: <KEYFRAME />,
    "keyframes-couple": <KEYFRAMESCOUPLE />,
    "landuse-coniferous": <LANDUSECONIFEROUS />,
    "landuse-coniferous-and-deciduous": <LANDUSECONIFEROUSANDDECIDUOUS />,
    "landuse-deciduous": <LANDUSEDECIDUOUS />,
    "landuse-grass": <LANDUSEGRASS />,
    "landuse-hills": <LANDUSEHILLS />,
    "landuse-quary": <LANDUSEQUARY />,
    "landuse-scrub": <LANDUSESCRUB />,
    "landuse-swamp": <LANDUSESWAMP />,
    language: <LANGUAGE />,
    "laundry-11": <LAUNDRY11 />,
    "laundry-15": <LAUNDRY15 />,
    leaf: <LEAF />,
    "library-11": <LIBRARY11 />,
    "library-15": <LIBRARY15 />,
    lifebelt: <LIFEBELT />,
    "location-searching-FILL0-wght400-GRAD0-opsz48": <LOCATIONSEARCHINGFILL0WGHT400GRAD0OPSZ48 />,
    "lodging-11": <LODGING11 />,
    "lodging-15": <LODGING15 />,
    map: <MAP />,
    "map-issue": <MAPISSUE />,
    "maps-arrow": <MAPSARROW />,
    "maps-arrow-diagonal": <MAPSARROWDIAGONAL />,
    "maps-arrow-issue": <MAPSARROWISSUE />,
    "maps-go-straight": <MAPSGOSTRAIGHT />,
    "maps-turn-back": <MAPSTURNBACK />,
    "maps-turn-left": <MAPSTURNLEFT />,
    "maps-turn-right": <MAPSTURNRIGHT />,
    "marker-11": <MARKER11 />,
    "marker-15": <MARKER15 />,
    "minus-hexagon": <MINUSHEXAGON />,
    "minus-pin-alt": <MINUSPINALT />,
    "monument-11": <MONUMENT11 />,
    "monument-15": <MONUMENT15 />,
    "mountain-11": <MOUNTAIN11 />,
    "mountain-15": <MOUNTAIN15 />,
    "museum-11": <MUSEUM11 />,
    "museum-15": <MUSEUM15 />,
    "music-11": <MUSIC11 />,
    "music-15": <MUSIC15 />,
    "my-location-FILL0-wght400-GRAD0-opsz48": <MYLOCATIONFILL0WGHT400GRAD0OPSZ48 />,
    "navigational-aid": <NAVIGATIONALAID />,
    navigator: <NAVIGATOR />,
    "navigator-alt": <NAVIGATORALT />,
    "NorthArrow-01": <NORTHARROW01 />,
    "NorthArrow-02": <NORTHARROW02 />,
    "NorthArrow-03": <NORTHARROW03 />,
    "NorthArrow-04": <NORTHARROW04 />,
    "NorthArrow-05": <NORTHARROW05 />,
    "NorthArrow-06": <NORTHARROW06 />,
    "NorthArrow-07": <NORTHARROW07 />,
    "NorthArrow-08": <NORTHARROW08 />,
    "NorthArrow-09": <NORTHARROW09 />,
    "NorthArrow-10": <NORTHARROW10 />,
    "NorthArrow-11": <NORTHARROW11 />,
    octagon: <OCTAGON />,
    "oil-barrel-FILL0-wght300-GRAD200-opsz40": <OILBARRELFILL0WGHT300GRAD200OPSZ40 />,
    "oil-industry": <OILINDUSTRY />,
    panorama: <PANORAMA />,
    parachute: <PARACHUTE />,
    "park-11": <PARK11 />,
    "park-15": <PARK15 />,
    "parking-2": <PARKING2 />,
    pentagon: <PENTAGON />,
    "pharmacy-11": <PHARMACY11 />,
    "pharmacy-15": <PHARMACY15 />,
    phone: <PHONE />,
    "picnic-site-11": <PICNICSITE11 />,
    "picnic-site-15": <PICNICSITE15 />,
    pin: <PIN />,
    "pin-alt": <PINALT />,
    "pine-tree": <PINETREE />,
    "place-of-worship-11": <PLACEOFWORSHIP11 />,
    "place-of-worship-15": <PLACEOFWORSHIP15 />,
    plane: <PLANE />,
    "plane-orange": <PLANEORANGE />,
    "playground-11": <PLAYGROUND11 />,
    "playground-15": <PLAYGROUND15 />,
    plus: <PLUS />,
    "poi-boundary-administrative": <POIBOUNDARYADMINISTRATIVE />,
    "poi-cave": <POICAVE />,
    "poi-embassy": <POIEMBASSY />,
    "poi-embassy2": <POIEMBASSY2 />,
    "poi-military-bunker": <POIMILITARYBUNKER />,
    "poi-mine": <POIMINE />,
    "poi-mine-abandoned": <POIMINEABANDONED />,
    "poi-peak": <POIPEAK />,
    "poi-place-city": <POIPLACECITY />,
    "poi-place-town": <POIPLACETOWN />,
    "poi-place-village": <POIPLACEVILLAGE />,
    "poi-point-of-interest": <POIPOINTOFINTEREST />,
    "poi-tower-communications": <POITOWERCOMMUNICATIONS />,
    "poi-tower-power": <POITOWERPOWER />,
    "poi-tower-water": <POITOWERWATER />,
    point: <POINT />,
    "police-11": <POLICE11 />,
    "police-15": <POLICE15 />,
    position: <POSITION />,
    "post-11": <POST11 />,
    "post-15": <POST15 />,
    "power-input-FILL0-wght300-GRAD200-opsz40": <POWERINPUTFILL0WGHT300GRAD200OPSZ40 />,
    "prison-11": <PRISON11 />,
    "prison-15": <PRISON15 />,
    question: <QUESTION />,
    "question-mark": <QUESTIONMARK />,
    "radio-button-checked-FILL0-wght400-GRAD0-opsz48": <RADIOBUTTONCHECKEDFILL0WGHT400GRAD0OPSZ48 />,
    "radio-button-unchecked-FILL0-wght400-GRAD0-opsz48": <RADIOBUTTONUNCHECKEDFILL0WGHT400GRAD0OPSZ48 />,
    "rail-11": <RAIL11 />,
    "rail-15": <RAIL15 />,
    "rail-light-11": <RAILLIGHT11 />,
    "rail-light-15": <RAILLIGHT15 />,
    "rail-metro-11": <RAILMETRO11 />,
    "rail-metro-15": <RAILMETRO15 />,
    "railway-station": <RAILWAYSTATION />,
    "rectangle-FILL0-wght400-GRAD0-opsz48": <RECTANGLEFILL0WGHT400GRAD0OPSZ48 />,
    "red-marker": <REDMARKER />,
    "religious-christian-11": <RELIGIOUSCHRISTIAN11 />,
    "religious-christian-15": <RELIGIOUSCHRISTIAN15 />,
    "religious-jewish-11": <RELIGIOUSJEWISH11 />,
    "religious-jewish-15": <RELIGIOUSJEWISH15 />,
    "religious-muslim-11": <RELIGIOUSMUSLIM11 />,
    "religious-muslim-15": <RELIGIOUSMUSLIM15 />,
    "remove-empty": <REMOVEEMPTY />,
    "remove-keyframe-alt": <REMOVEKEYFRAMEALT />,
    "remove-pin-alt": <REMOVEPINALT />,
    "remove-square": <REMOVESQUARE />,
    "restaurant-11": <RESTAURANT11 />,
    "restaurant-15": <RESTAURANT15 />,
    rhombus: <RHOMBUS />,
    "rocket-11": <ROCKET11 />,
    "rocket-15": <ROCKET15 />,
    running: <RUNNING />,
    "school-11": <SCHOOL11 />,
    "school-15": <SCHOOL15 />,
    shipwreck: <SHIPWRECK />,
    "shop-1": <SHOP1 />,
    "shop-11": <SHOP11 />,
    "shop-15": <SHOP15 />,
    "shop-alt": <SHOPALT />,
    "shopping-alcohol": <SHOPPINGALCOHOL />,
    "shopping-bakery": <SHOPPINGBAKERY />,
    "shopping-bicycle": <SHOPPINGBICYCLE />,
    "shopping-book": <SHOPPINGBOOK />,
    "shopping-butcher": <SHOPPINGBUTCHER />,
    "shopping-car": <SHOPPINGCAR />,
    "shopping-car-repair": <SHOPPINGCARREPAIR />,
    "shopping-clothes": <SHOPPINGCLOTHES />,
    "shopping-confectionery": <SHOPPINGCONFECTIONERY />,
    "shopping-convenience": <SHOPPINGCONVENIENCE />,
    "shopping-diy": <SHOPPINGDIY />,
    "shopping-estateagent": <SHOPPINGESTATEAGENT />,
    "shopping-estateagent2": <SHOPPINGESTATEAGENT2 />,
    "shopping-fish": <SHOPPINGFISH />,
    "shopping-garden-centre": <SHOPPINGGARDENCENTRE />,
    "shopping-gift": <SHOPPINGGIFT />,
    "shopping-greengrocer": <SHOPPINGGREENGROCER />,
    "shopping-hairdresser": <SHOPPINGHAIRDRESSER />,
    "shopping-hifi": <SHOPPINGHIFI />,
    "shopping-jewelry": <SHOPPINGJEWELRY />,
    "shopping-laundrette": <SHOPPINGLAUNDRETTE />,
    "shopping-mobile-phone": <SHOPPINGMOBILEPHONE />,
    "shopping-motorcycle": <SHOPPINGMOTORCYCLE />,
    "shopping-music": <SHOPPINGMUSIC />,
    "shopping-pet": <SHOPPINGPET />,
    "shopping-pet2": <SHOPPINGPET2 />,
    "shopping-photo": <SHOPPINGPHOTO />,
    "shopping-supermarket": <SHOPPINGSUPERMARKET />,
    "shopping-tackle": <SHOPPINGTACKLE />,
    "shopping-video-rental": <SHOPPINGVIDEORENTAL />,
    "sidescan-sonar-contact": <SIDESCANSONARCONTACT />,
    skier: <SKIER />,
    skull: <SKULL />,
    "small-shop": <SMALLSHOP />,
    "small-shop-alt": <SMALLSHOPALT />,
    soil: <SOIL />,
    "soil-alt": <SOILALT />,
    "solar-power-FILL0-wght300-GRAD200-opsz40": <SOLARPOWERFILL0WGHT300GRAD200OPSZ40 />,
    sonar: <SONAR />,
    "sport-archery": <SPORTARCHERY />,
    "sport-baseball": <SPORTBASEBALL />,
    "sport-cricket": <SPORTCRICKET />,
    "sport-diving": <SPORTDIVING />,
    "sport-golf": <SPORTGOLF />,
    "sport-gym": <SPORTGYM />,
    "sport-gymnasium": <SPORTGYMNASIUM />,
    "sport-gymnasium2": <SPORTGYMNASIUM2 />,
    "sport-hillclimbing": <SPORTHILLCLIMBING />,
    "sport-horse-racing": <SPORTHORSERACING />,
    "sport-iceskating": <SPORTICESKATING />,
    "sport-jetski": <SPORTJETSKI />,
    "sport-leisure-centre": <SPORTLEISURECENTRE />,
    "sport-motorracing": <SPORTMOTORRACING />,
    "sport-playground": <SPORTPLAYGROUND />,
    "sport-sailing": <SPORTSAILING />,
    "sport-skiing-crosscountry": <SPORTSKIINGCROSSCOUNTRY />,
    "sport-skiing-downhill": <SPORTSKIINGDOWNHILL />,
    "sport-snooker": <SPORTSNOOKER />,
    "sport-soccer": <SPORTSOCCER />,
    "sport-swimming-indoor": <SPORTSWIMMINGINDOOR />,
    "sport-swimming-outdoor": <SPORTSWIMMINGOUTDOOR />,
    "sport-tennis": <SPORTTENNIS />,
    "sport-windsurfing": <SPORTWINDSURFING />,
    "square-1": <SQUARE1 />,
    "stadium-11": <STADIUM11 />,
    "stadium-15": <STADIUM15 />,
    "star-11": <STAR11 />,
    "star-15": <STAR15 />,
    Star1: <STAR1 />,
    Star2: <STAR2 />,
    Star3: <STAR3 />,
    "storm-FILL0-wght300-GRAD200-opsz40": <STORMFILL0WGHT300GRAD200OPSZ40 />,
    suggestion: <SUGGESTION />,
    "suitcase-11": <SUITCASE11 />,
    "suitcase-15": <SUITCASE15 />,
    "sun-light": <SUNLIGHT />,
    swimmer: <SWIMMER />,
    "swimming-11": <SWIMMING11 />,
    "swimming-15": <SWIMMING15 />,
    table: <TABLE />,
    teepee: <TEEPEE />,
    "theatre-11": <THEATRE11 />,
    "theatre-15": <THEATRE15 />,
    "toilet-11": <TOILET11 />,
    "toilet-15": <TOILET15 />,
    tools: <TOOLS />,
    "tornado-FILL0-wght300-GRAD200-opsz40": <TORNADOFILL0WGHT300GRAD200OPSZ40 />,
    "tourist-archaeological": <TOURISTARCHAEOLOGICAL />,
    "tourist-archaeological2": <TOURISTARCHAEOLOGICAL2 />,
    "tourist-art-gallery": <TOURISTARTGALLERY />,
    "tourist-art-gallery2": <TOURISTARTGALLERY2 />,
    "tourist-battlefield": <TOURISTBATTLEFIELD />,
    "tourist-beach": <TOURISTBEACH />,
    "tourist-casino": <TOURISTCASINO />,
    "tourist-castle": <TOURISTCASTLE />,
    "tourist-cinema": <TOURISTCINEMA />,
    "tourist-cinema2": <TOURISTCINEMA2 />,
    "tourist-fountain": <TOURISTFOUNTAIN />,
    "tourist-memorial": <TOURISTMEMORIAL />,
    "tourist-monument": <TOURISTMONUMENT />,
    "tourist-museum": <TOURISTMUSEUM />,
    "tourist-picnic": <TOURISTPICNIC />,
    "tourist-ruin": <TOURISTRUIN />,
    "tourist-steam-train": <TOURISTSTEAMTRAIN />,
    "tourist-theatre": <TOURISTTHEATRE />,
    "tourist-view-point": <TOURISTVIEWPOINT />,
    "tourist-waterwheel": <TOURISTWATERWHEEL />,
    "tourist-windmill": <TOURISTWINDMILL />,
    "tourist-wreck": <TOURISTWRECK />,
    "tourist-zoo": <TOURISTZOO />,
    "tourisum-fountain": <TOURISUMFOUNTAIN />,
    "town-hall-11": <TOWNHALL11 />,
    "town-hall-15": <TOWNHALL15 />,
    "train-outline": <TRAINOUTLINE />,
    tram: <TRAM />,
    "transport-aerodrome": <TRANSPORTAERODROME />,
    "transport-aerodrome2": <TRANSPORTAERODROME2 />,
    "transport-airport": <TRANSPORTAIRPORT />,
    "transport-airport2": <TRANSPORTAIRPORT2 />,
    "transport-bus-station": <TRANSPORTBUSSTATION />,
    "transport-bus-stop": <TRANSPORTBUSSTOP />,
    "transport-bus-stop2": <TRANSPORTBUSSTOP2 />,
    "transport-car-share": <TRANSPORTCARSHARE />,
    "transport-ford": <TRANSPORTFORD />,
    "transport-fuel": <TRANSPORTFUEL />,
    "transport-fuel-lpg": <TRANSPORTFUELLPG />,
    "transport-helicopter": <TRANSPORTHELICOPTER />,
    "transport-lighthouse": <TRANSPORTLIGHTHOUSE />,
    "transport-marina": <TRANSPORTMARINA />,
    "transport-parking": <TRANSPORTPARKING />,
    "transport-parking-bicycle": <TRANSPORTPARKINGBICYCLE />,
    "transport-parking-car": <TRANSPORTPARKINGCAR />,
    "transport-parking-car-paid": <TRANSPORTPARKINGCARPAID />,
    "transport-parking-disabled": <TRANSPORTPARKINGDISABLED />,
    "transport-parking-private": <TRANSPORTPARKINGPRIVATE />,
    "transport-parking-private2": <TRANSPORTPARKINGPRIVATE2 />,
    "transport-parking-private3": <TRANSPORTPARKINGPRIVATE3 />,
    "transport-port": <TRANSPORTPORT />,
    "transport-rental-bicycle": <TRANSPORTRENTALBICYCLE />,
    "transport-rental-car": <TRANSPORTRENTALCAR />,
    "transport-roundabout-anticlockwise": <TRANSPORTROUNDABOUTANTICLOCKWISE />,
    "transport-roundabout-clockwise": <TRANSPORTROUNDABOUTCLOCKWISE />,
    "transport-taxi-rank": <TRANSPORTTAXIRANK />,
    "transport-traffic-lights": <TRANSPORTTRAFFICLIGHTS />,
    "transport-train-station": <TRANSPORTTRAINSTATION />,
    "transport-train-station2": <TRANSPORTTRAINSTATION2 />,
    "transport-tram-stop": <TRANSPORTTRAMSTOP />,
    "tree-1": <TREE1 />,
    "tree-2": <TREE2 />,
    "triangle-1": <TRIANGLE1 />,
    "triangle-11": <TRIANGLE11 />,
    "triangle-15": <TRIANGLE15 />,
    "triangle-flag": <TRIANGLEFLAG />,
    "triangle-flag-circle": <TRIANGLEFLAGCIRCLE />,
    "triangle-flag-full": <TRIANGLEFLAGFULL />,
    "triangle-stroked-11": <TRIANGLESTROKED11 />,
    "triangle-stroked-15": <TRIANGLESTROKED15 />,
    truck: <TRUCK />,
    "veterinary-11": <VETERINARY11 />,
    "veterinary-15": <VETERINARY15 />,
    "volcano-11": <VOLCANO11 />,
    "volcano-15": <VOLCANO15 />,
    walker: <WALKER />,
    "warehouse-FILL0-wght300-GRAD200-opsz40": <WAREHOUSEFILL0WGHT300GRAD200OPSZ40 />,
    "warning-circled-outline": <WARNINGCIRCLEDOUTLINE />,
    "warning-square-outline": <WARNINGSQUAREOUTLINE />,
    "warning-triangle-outline": <WARNINGTRIANGLEOUTLINE />,
    "water-tower": <WATERTOWER />,
    "watering-soil": <WATERINGSOIL />,
    waypoint: <WAYPOINT />,
    "white-flag": <WHITEFLAG />,
    wifi: <WIFI />,
    "wifi-signal-none": <WIFISIGNALNONE />,
    "wind-power-FILL0-wght300-GRAD200-opsz40": <WINDPOWERFILL0WGHT300GRAD200OPSZ40 />,
    "WindRose-01": <WINDROSE01 />,
    "WindRose-02": <WINDROSE02 />,
    wrench: <WRENCH />,
    "zoo-11": <ZOO11 />,
    "zoo-15": <ZOO15 />,
    "isolated-depression": <ISOLATEDDEPRESSION />,
}
export default icons
