import * as React from "react";
const SvgMusic11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "music-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 0.5 C9.4238 0.4993 9.3484 0.5164 9.28 0.55 L3.5 2 C3.2239 2 3 2.2239 3 2.5 L3 7.09 C2.219 6.8139 1.3619 7.2232 1.0858 8.0042 C0.80970013 8.7852 1.219 9.6423 2 9.9184 C2.7810001 10.1945 3.6381001 9.7852 3.9141998 9.0042 C3.9715 8.8423 4.0005 8.6717 4 8.5 L4 5.38 L9 4.13 L9 5.59 C8.219 5.3139 7.3619 5.7232 7.0858 6.5042 C6.8097 7.2853 7.219 8.1423 8 8.4184 C8.781 8.6945 9.6381 8.2852 9.9142 7.5042 C9.9715 7.3423 10.0005 7.1717 10 7 L10 1 C10 0.7239 9.7761 0.5 9.5 0.5 z M4 4.38 L4 2.88 L9 1.6300001 L9 3.13 L4 4.38 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 0.5 C9.4238 0.4993 9.3484 0.5164 9.28 0.55 L3.5 2 C3.2239 2 3 2.2239 3 2.5 L3 7.09 C2.219 6.8139 1.3619 7.2232 1.0858 8.0042 C0.80970013 8.7852 1.219 9.6423 2 9.9184 C2.7810001 10.1945 3.6381001 9.7852 3.9141998 9.0042 C3.9715 8.8423 4.0005 8.6717 4 8.5 L4 5.38 L9 4.13 L9 5.59 C8.219 5.3139 7.3619 5.7232 7.0858 6.5042 C6.8097 7.2853 7.219 8.1423 8 8.4184 C8.781 8.6945 9.6381 8.2852 9.9142 7.5042 C9.9715 7.3423 10.0005 7.1717 10 7 L10 1 C10 0.7239 9.7761 0.5 9.5 0.5 z M4 4.38 L4 2.88 L9 1.6300001 L9 3.13 L4 4.38 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMusic11;
