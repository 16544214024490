import * as React from "react";
const SvgPlayground11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "playground-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 0.79 C1.1286 0.2529 1.6682999 -0.0783 2.2054 0.050300002 C2.7425 0.1789 3.0736 0.7186 2.945 1.2557 C2.8183 1.7849 2.2917 2.1156 1.76 2 C1.2203 1.8826 0.878 1.35 0.9954 0.8103 C0.9968 0.8035 0.9984 0.7968 1 0.79 z M10.85 8.16 L10.85 8.16 L8.85 6.16 L8.85 6.16 C8.76 6.0612 8.6336 6.0034 8.5 6 L8.37 6 L8.37 6 L6.5 6.55 L6.5 4 L6.5 4 C6.746 4.0083 6.96 3.8328 7 3.59 C7.0558 3.3225 6.8866 3.0598 6.62 3 L6.5 3 L6.5 0 L6.28 0 L6.28 3 L1.82 3.55 L1.82 3.55 C1.4201 3.6111 1.0965 3.9072 1 4.3 C0.9688 4.4416 0.9688 4.5884 1 4.73 L1 4.73 L1.81 7.86 L1.81 7.86 C1.9111999 8.1954 2.1806 8.4533 2.52 8.54 C2.6713 8.5757 2.8287 8.5757 2.98 8.54 L3 8.54 L6.29 7.65 L6.29 9.27 L3.29 10.01 L3.29 10.01 C3.0139 10.0956 2.8594 10.3889 2.945 10.665 C3.0305998 10.9411 3.3239 11.0956 3.6 11.01 C3.6566 11.0151005 3.7134 11.0151005 3.77 11.01 L7.77 10.01 C8.0461 9.9272 8.2028 9.6361 8.12 9.360001 C8.0372 9.0839 7.7461 8.9272 7.47 9.01 L7.27 9 L6.5 9.22 L6.5 7.58 L8.33 7.0699997 L10.139999 8.849999 L10.139999 8.849999 C10.3158 9.063 10.630899 9.0932 10.8439 8.917399 C11.0569 8.741599 11.0871 8.426499 10.9113 8.213499 C10.8956 8.1945 10.8784 8.1766 10.86 8.16 L10.85 8.16 z M6.28 6.61 L4.21 7.07 L3.5 4.33 L6.28 4 L6.28 6.61 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 0.79 C1.1286 0.2529 1.6682999 -0.0783 2.2054 0.050300002 C2.7425 0.1789 3.0736 0.7186 2.945 1.2557 C2.8183 1.7849 2.2917 2.1156 1.76 2 C1.2203 1.8826 0.878 1.35 0.9954 0.8103 C0.9968 0.8035 0.9984 0.7968 1 0.79 z M10.85 8.16 L10.85 8.16 L8.85 6.16 L8.85 6.16 C8.76 6.0612 8.6336 6.0034 8.5 6 L8.37 6 L8.37 6 L6.5 6.55 L6.5 4 L6.5 4 C6.746 4.0083 6.96 3.8328 7 3.59 C7.0558 3.3225 6.8866 3.0598 6.62 3 L6.5 3 L6.5 0 L6.28 0 L6.28 3 L1.82 3.55 L1.82 3.55 C1.4201 3.6111 1.0965 3.9072 1 4.3 C0.9688 4.4416 0.9688 4.5884 1 4.73 L1 4.73 L1.81 7.86 L1.81 7.86 C1.9111999 8.1954 2.1806 8.4533 2.52 8.54 C2.6713 8.5757 2.8287 8.5757 2.98 8.54 L3 8.54 L6.29 7.65 L6.29 9.27 L3.29 10.01 L3.29 10.01 C3.0139 10.0956 2.8594 10.3889 2.945 10.665 C3.0305998 10.9411 3.3239 11.0956 3.6 11.01 C3.6566 11.0151005 3.7134 11.0151005 3.77 11.01 L7.77 10.01 C8.0461 9.9272 8.2028 9.6361 8.12 9.360001 C8.0372 9.0839 7.7461 8.9272 7.47 9.01 L7.27 9 L6.5 9.22 L6.5 7.58 L8.33 7.0699997 L10.139999 8.849999 L10.139999 8.849999 C10.3158 9.063 10.630899 9.0932 10.8439 8.917399 C11.0569 8.741599 11.0871 8.426499 10.9113 8.213499 C10.8956 8.1945 10.8784 8.1766 10.86 8.16 L10.85 8.16 z M6.28 6.61 L4.21 7.07 L3.5 4.33 L6.28 4 L6.28 6.61 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPlayground11;
