import * as React from "react";
const SvgLibrary15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "library-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.47 4.92 C7.47 4.92 5.7 3 1 3 L1 11 C5.7 11 7.47 13 7.47 13 C7.47 13 9.3 11 14 11 L14 3 C9.3 3 7.47 4.92 7.47 4.92 z M13 10 C11.0386 10.0492 9.1273 10.6299 7.47 11.68 C5.836 10.6273 3.9432 10.0459 2 10 L2 4 C5.4 4.26 6.73 5.6 6.75 5.61 L7.48 6.3500004 L8.2 5.6 C8.2 5.6 9.599999 4.2599998 13 4 L13 10 z M8 10.24 L7.9 10.07 C9.2011 9.4769 10.5827 9.0793 12 8.889999 L12 9.089999 C10.6161 9.285299 9.2684 9.682899 8 10.2699995 L8 10.24 z M8 9.24 L7.9 9.07 C9.2016 8.4802 10.5832 8.086 12 7.9 L12 8.1 C10.6156 8.2988 9.2679 8.6997 8 9.290001 L8 9.24 z M8 8.24 L7.9 8.07 C9.2015 7.48 10.5831 7.0857 12 6.9 L12 7.1 C10.6154995 7.2981 9.2677 7.699 8 8.29 L8 8.24 z M8 7.24 L7.9 7.07 C9.2013 6.4794 10.583 6.0851 12 5.9 L12 6.1 C10.6156 6.2985997 9.2679 6.6995997 8 7.29 L8 7.24 z M6.9 10.24 C5.6639 9.6641 4.3499 9.2733 3 9.08 L3 8.88 C4.3872 9.0828 5.7358 9.494101 7 10.1 L6.9 10.24 z M6.9 9.24 C5.6629 8.671 4.3488 8.2869 3 8.1 L3 7.9 C4.386 8.1027 5.7341003 8.5105 7 9.110001 L6.9 9.24 z M6.9 8.24 C5.6631 7.6705 4.3489 7.2863 3 7.1 L3 6.9 C4.3868 7.099 5.7354 7.507 7 8.110001 L6.9 8.24 z M6.9 7.24 C5.6629 6.671 4.3488 6.2869 3 6.1 L3 5.9 C4.386 6.1024003 5.7342 6.5102 7 7.11 L6.9 7.24 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.47 4.92 C7.47 4.92 5.7 3 1 3 L1 11 C5.7 11 7.47 13 7.47 13 C7.47 13 9.3 11 14 11 L14 3 C9.3 3 7.47 4.92 7.47 4.92 z M13 10 C11.0386 10.0492 9.1273 10.6299 7.47 11.68 C5.836 10.6273 3.9432 10.0459 2 10 L2 4 C5.4 4.26 6.73 5.6 6.75 5.61 L7.48 6.3500004 L8.2 5.6 C8.2 5.6 9.599999 4.2599998 13 4 L13 10 z M8 10.24 L7.9 10.07 C9.2011 9.4769 10.5827 9.0793 12 8.889999 L12 9.089999 C10.6161 9.285299 9.2684 9.682899 8 10.2699995 L8 10.24 z M8 9.24 L7.9 9.07 C9.2016 8.4802 10.5832 8.086 12 7.9 L12 8.1 C10.6156 8.2988 9.2679 8.6997 8 9.290001 L8 9.24 z M8 8.24 L7.9 8.07 C9.2015 7.48 10.5831 7.0857 12 6.9 L12 7.1 C10.6154995 7.2981 9.2677 7.699 8 8.29 L8 8.24 z M8 7.24 L7.9 7.07 C9.2013 6.4794 10.583 6.0851 12 5.9 L12 6.1 C10.6156 6.2985997 9.2679 6.6995997 8 7.29 L8 7.24 z M6.9 10.24 C5.6639 9.6641 4.3499 9.2733 3 9.08 L3 8.88 C4.3872 9.0828 5.7358 9.494101 7 10.1 L6.9 10.24 z M6.9 9.24 C5.6629 8.671 4.3488 8.2869 3 8.1 L3 7.9 C4.386 8.1027 5.7341003 8.5105 7 9.110001 L6.9 9.24 z M6.9 8.24 C5.6631 7.6705 4.3489 7.2863 3 7.1 L3 6.9 C4.3868 7.099 5.7354 7.507 7 8.110001 L6.9 8.24 z M6.9 7.24 C5.6629 6.671 4.3488 6.2869 3 6.1 L3 5.9 C4.386 6.1024003 5.7342 6.5102 7 7.11 L6.9 7.24 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLibrary15;
