import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { resetProjectData } from "actions/globalActions"
import { getMetadataSchema } from "../actions/metadataSchema"
import { MetadataProperty } from "../model/metadata/MetadataProperty"

type SliceState = {
    loading: boolean
    schema: MetadataProperty[]
}

const initialState: SliceState = {
    loading: false,
    schema: [],
}

const metadataSchemaSlice = createSlice({
    extraReducers: builder => {
        builder
            .addCase(getMetadataSchema.pending, state => {
                state.loading = true
            })
            .addCase(getMetadataSchema.fulfilled, (state, { payload }) => {
                state.schema = payload
            })
            .addCase(resetProjectData, () => initialState)
            .addMatcher(isAnyOf(getMetadataSchema.rejected, getMetadataSchema.fulfilled), state => {
                state.loading = false
            })
    },
    initialState,
    name: "metadataSchema",
    reducers: {
        reset: () => initialState,
    },
})

export const { reset } = metadataSchemaSlice.actions
export default metadataSchemaSlice.reducer
