import { LngLatBoundsLike, useMap } from "@emblautec/react-map-gl"

const useOnFlyToFeature = () => {
    const { mainMap } = useMap()

    // example BOX(-10 69.99999999999999,-10 69.99999999999999)
    // removes "BOX(" and the last '('
    // maps to array of floats
    return (boundingBox: string) => {
        const boxCoord = boundingBox
            .slice(4, -1)
            .replaceAll(" ", ",")
            .split(",")
            .filter(x => !!x) // I've added this as a safety net
            .map(x => parseFloat(x))

        const bbox: LngLatBoundsLike = [
            [boxCoord[0], boxCoord[1]],
            [boxCoord[2], boxCoord[3]],
        ]

        mainMap?.fitBounds(bbox, {
            curve: 1.2,
            maxZoom: 18,
            padding: 100,
            speed: 1.25,
        })
    }
}

export default useOnFlyToFeature
