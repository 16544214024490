import * as React from "react";
const SvgBar11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bar-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.4883 1 C4.9759 1 0.5 1 1 1.5 L5 6 L5 8.5 C5 9 2.5 9 2.5 10 L8.5 10 C8.5 9 6 9 6 8.5 L6 6 L10 1.5 C10.5 1 6.0006 1 5.4883 1 z M2.5 2 L8.5 2 L7.5 3 L3.5 3 L2.5 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.4883 1 C4.9759 1 0.5 1 1 1.5 L5 6 L5 8.5 C5 9 2.5 9 2.5 10 L8.5 10 C8.5 9 6 9 6 8.5 L6 6 L10 1.5 C10.5 1 6.0006 1 5.4883 1 z M2.5 2 L8.5 2 L7.5 3 L3.5 3 L2.5 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBar11;
