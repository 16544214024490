import * as React from "react";
const SvgRocket15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rocket-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.5547 1 C10.4106 1 7.5336 2.471 5.6015997 5 L4 5 C2.8427 5 2.1794 5.8638 1.7227 6.7773 L1.1113 8 L2.5547 8 L4 8 L5.5 9.5 L7 11 L7 12.4453 L7 13.8887005 L8.2227 13.277401 C9.1362 12.8206 10 12.1573 10 11 L10 9.3984 C12.529 7.4664 14 4.5894003 14 2.4453 L14 1 L12.5547 1 z M10 4 C10.5522995 4 11 4.4477 11 5 L11 5 C11 5.5523 10.5523 6 10 6 L10 6 C9.4477 6 9 5.5523 9 5 L9 5 C9 4.4477 9.4477 4 10 4 L10 4 z M3.5 10 L3 10.5 C2.2778 11.2222 2 13 2 13 C2 13 3.698 12.802 4.5 12 L5 11.5 L3.5 10 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.5547 1 C10.4106 1 7.5336 2.471 5.6015997 5 L4 5 C2.8427 5 2.1794 5.8638 1.7227 6.7773 L1.1113 8 L2.5547 8 L4 8 L5.5 9.5 L7 11 L7 12.4453 L7 13.8887005 L8.2227 13.277401 C9.1362 12.8206 10 12.1573 10 11 L10 9.3984 C12.529 7.4664 14 4.5894003 14 2.4453 L14 1 L12.5547 1 z M10 4 C10.5522995 4 11 4.4477 11 5 L11 5 C11 5.5523 10.5523 6 10 6 L10 6 C9.4477 6 9 5.5523 9 5 L9 5 C9 4.4477 9.4477 4 10 4 L10 4 z M3.5 10 L3 10.5 C2.2778 11.2222 2 13 2 13 C2 13 3.698 12.802 4.5 12 L5 11.5 L3.5 10 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRocket15;
