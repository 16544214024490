import * as React from "react";
const SvgArtGallery11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "art-gallery-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.21 3 L5.85 0.65 C5.6555 0.4539 5.339 0.4526 5.1429 0.6471 C5.1419 0.6481 5.141 0.649 5.14 0.65 L2.79 3 L1.5 3 C1.2239 3 1 3.2239 1 3.5 L1 9.5 C1 9.7761 1.2239 10 1.5 10 L9.5 10 C9.7761 10 10 9.7761 10 9.5 L10 3.5 C10 3.2239 9.7761 3 9.5 3 L8.21 3 z M5.5 1.71 L6.79 3 L4.21 3 L5.5 1.71 z M9 9 L2 9 L2 4 L9 4 L9 9 z M4.5 5.5 C4.5 5.7761 4.2761 6 4 6 C3.7238998 6 3.5 5.7761 3.5 5.5 C3.5 5.2239 3.7239 5 4 5 C4.2761 5 4.5 5.2239 4.5 5.5 z M8 8 L4 8 L4.75 6.5 L5.25 7.5 L6.5 5 L8 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.21 3 L5.85 0.65 C5.6555 0.4539 5.339 0.4526 5.1429 0.6471 C5.1419 0.6481 5.141 0.649 5.14 0.65 L2.79 3 L1.5 3 C1.2239 3 1 3.2239 1 3.5 L1 9.5 C1 9.7761 1.2239 10 1.5 10 L9.5 10 C9.7761 10 10 9.7761 10 9.5 L10 3.5 C10 3.2239 9.7761 3 9.5 3 L8.21 3 z M5.5 1.71 L6.79 3 L4.21 3 L5.5 1.71 z M9 9 L2 9 L2 4 L9 4 L9 9 z M4.5 5.5 C4.5 5.7761 4.2761 6 4 6 C3.7238998 6 3.5 5.7761 3.5 5.5 C3.5 5.2239 3.7239 5 4 5 C4.2761 5 4.5 5.2239 4.5 5.5 z M8 8 L4 8 L4.75 6.5 L5.25 7.5 L6.5 5 L8 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgArtGallery11;
