import * as React from "react";
const SvgAquarium15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "aquarium-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.9 11.6 C10.599999 11 10.599999 9.3 10.9 8.8 C11.299999 8.2 14.299999 10.2 14.299999 10.2 C15.199999 10.599999 15.199999 4.1 14.299999 4.5 C14.299999 4.5 11.199999 6.6 10.9 5.9 C10.599999 5.2000003 10.599999 3.8000002 10.9 3.1000001 C11.2 2.5 15 2.4 15 2.4 C15 1.7 12.1 1 10.9 1 C9.699999 1 8.4 1.1 6.8 1.8 C5.2 2.4 3.9 3.4 2.7 4.6 C1.5 5.7999997 0 8.2 0 8.9 C0 9.599999 1.5 11.7 3.7 12.599999 C5.9 13.499999 7 13.7 8.2 13.9 C9.3 14 10.799999 13.9 12.1 13.599999 C13.1 13.4 15 12.9 15 12.499999 C15 12.3 11.2 12.2 10.9 11.6 z M4.5 9.3 C3.7 9.3 3 8.6 3 7.8 C3 7 3.7 6.3 4.5 6.3 C5.3 6.3 6 7 6 7.8 C6 8.6 5.3 9.3 4.5 9.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.9 11.6 C10.599999 11 10.599999 9.3 10.9 8.8 C11.299999 8.2 14.299999 10.2 14.299999 10.2 C15.199999 10.599999 15.199999 4.1 14.299999 4.5 C14.299999 4.5 11.199999 6.6 10.9 5.9 C10.599999 5.2000003 10.599999 3.8000002 10.9 3.1000001 C11.2 2.5 15 2.4 15 2.4 C15 1.7 12.1 1 10.9 1 C9.699999 1 8.4 1.1 6.8 1.8 C5.2 2.4 3.9 3.4 2.7 4.6 C1.5 5.7999997 0 8.2 0 8.9 C0 9.599999 1.5 11.7 3.7 12.599999 C5.9 13.499999 7 13.7 8.2 13.9 C9.3 14 10.799999 13.9 12.1 13.599999 C13.1 13.4 15 12.9 15 12.499999 C15 12.3 11.2 12.2 10.9 11.6 z M4.5 9.3 C3.7 9.3 3 8.6 3 7.8 C3 7 3.7 6.3 4.5 6.3 C5.3 6.3 6 7 6 7.8 C6 8.6 5.3 9.3 4.5 9.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAquarium15;
