import { useState } from "react"
import AppsIcon from "@mui/icons-material/Apps"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import { useParams } from "react-router-dom"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import config from "config"
import { getIsCurrentUserClientOrSystemAdmin } from "features/auth/selectors"
import { getClientById, getCoreAccountPermissions, getModules } from "features/core/selectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { ModuleStatus } from "../../models/CoreModule"
import ModuleItem from "../ModuleItem/ModuleItem"
import { useStyles } from "./styles"

type Params = {
    clientId: string
    projectId: string
}

const buildRedirectUrl = (locationUrl: string, clientId: string) => {
    try {
        const url = new URL(locationUrl)
        url.searchParams.append("clientId", clientId)

        return url.href
    } catch (error) {
        return "#"
    }
}

const ModulesPicker = () => {
    const { headerIconButton, icon } = useStyles()

    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>(null)
    const modules = useAppSelector(getModules)
    const { clientId } = useParams<Params>()
    const accountPermissions = useAppSelector(getCoreAccountPermissions)
    const isCurrentUserClientOrSystemAdmin = useAppSelector(getIsCurrentUserClientOrSystemAdmin)
    const coreModule = config.coreModule
    const analyticsModuleId = config.analyticsModuleId
    const currentClient = useAppSelector(getClientById(clientId))

    const isModuleEnabledForClient = (moduleId: string) => currentClient?.modules?.some(m => m === moduleId) ?? false

    const hasModuleAccess = (moduleId: string) =>
        isCurrentUserClientOrSystemAdmin ||
        (moduleId === analyticsModuleId
            ? !!accountPermissions.accountReportPermissions.length
            : accountPermissions.accountProjectPermissions.some(
                  projectPermission =>
                      projectPermission.project.clientId === clientId &&
                      projectPermission.modulePermissions.some(
                          modulePermission => modulePermission.moduleId === moduleId,
                      ),
              ))

    const isModuleAccessible = (moduleId: string) => isModuleEnabledForClient(moduleId) && hasModuleAccess(moduleId)

    const isModuleActive = (status: ModuleStatus) => status === ModuleStatus.Active

    const open = !!anchorEl

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title="Other modules">
                <IconButton className={headerIconButton} onClick={onClick}>
                    <AppsIcon className={icon} />
                </IconButton>
            </Tooltip>

            <Menu anchorEl={anchorEl} id="account-menu" open={open} onClick={onClose} onClose={onClose}>
                {modules.length ? (
                    [
                        ...modules.map((module, index) => {
                            const isAccessible = isModuleAccessible(module.id)

                            const url =
                                isAccessible && isModuleActive(module.status)
                                    ? buildRedirectUrl(module.locationUrl, clientId)
                                    : module.landingPageUrl

                            return (
                                <ModuleItem
                                    key={index}
                                    description={module.description}
                                    favIconUrl={module.favIconUrl}
                                    moduleUrl={url}
                                    name={module.name}
                                    isAccessible={isAccessible}
                                />
                            )
                        }),
                        <ModuleItem
                            key={coreModule.id}
                            description={coreModule.description}
                            favIconUrl={coreModule.favIconUrl}
                            moduleUrl={buildRedirectUrl(coreModule.locationUrl, clientId)}
                            name={coreModule.name}
                            isAccessible
                        />,
                    ]
                ) : (
                    <MenuItem disabled>
                        <CustomTypography variant="h3">No modules available at the moment</CustomTypography>
                    </MenuItem>
                )}
            </Menu>
        </>
    )
}

export default ModulesPicker
