import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(theme => ({
    appAndProject: {
        alignItems: "center",
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: "space-between",
        padding: "0 8px",
    },
    appAndProjectNames: {
        marginTop: 2,
        maxWidth: "85%",
        minWidth: 0,
    },
    appName: {
        fontSize: "0.785rem",
    },
    apps: {
        maxHeight: 400,
        overflow: "auto",
    },
    appsMenu: {
        "& .MuiSelect-icon": { right: theme.spacing(1) },

        "& .MuiSelect-select": {
            "& > div": { height: "100%" },
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },

            height: "100%",

            paddingRight: theme.spacing(4),
        },
        height: "100%",
    },
    blueChip: {
        backgroundColor: "inherit",
        color: theme.customColors.primary3,
        fill: theme.customColors.primary3,
        marginRight: -10,
    },
    formControl: {
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        height: "100%",
        justifyContent: "center",
        marginLeft: theme.spacing(1.5),
    },
    link: {
        display: "flex",
        width: "100%",
    },
    overflowText: {
        display: "flex",
        flexGrow: 1,
        marginRight: 20,
        maxWidth: 300,
    },
    project: {
        display: "flex",
        fontSize: 13,
        gap: 32,
        justifyContent: "space-between",
        maxWidth: 340,
        padding: "12px 8px 12px 16px",
    },
    switchProject: {
        color: theme.customColors.primaryColor,
        display: "flex",
        gap: 4,
    },
}))
