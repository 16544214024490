import * as React from "react";
const SvgNorthArrow05 = (props) => /* @__PURE__ */ React.createElement("svg", { xmlnsDc: "http://purl.org/dc/elements/1.1/", xmlnsCc: "http://creativecommons.org/ns#", xmlnsRdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#", xmlnsSvg: "http://www.w3.org/2000/svg", xmlns: "http://www.w3.org/2000/svg", xmlnsSodipodi: "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlnsInkscape: "http://www.inkscape.org/namespaces/inkscape", viewBox: "0 0 19.46875 19.46875", id: "svg2", inkscapeVersion: "0.48.4 r9939", width: "100%", height: "100%", sodipodiDocname: "NorthArrow_05.svg", ...props }, /* @__PURE__ */ React.createElement("metadata", { id: "metadata90" }), /* @__PURE__ */ React.createElement("defs", { id: "defs88" }), /* @__PURE__ */ React.createElement("g", { id: "layer4", style: {
  display: "inline"
} }, /* @__PURE__ */ React.createElement("path", { fill: "param(fill)", fillOpacity: 0.5, stroke: "none", style: {
  strokeWidth: 0.19956166,
  strokeLinecap: "square",
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  display: "inline"
}, d: "m 3.69375,3.69375 2.28125,4 1.34375,-0.375 0.34375,-1.3125 -3.96875,-2.3125 z", id: "path3303" }), /* @__PURE__ */ React.createElement("path", { fill: "param(fill)", fillOpacity: 0.5, stroke: "none", style: {
  strokeWidth: 0.19956166,
  strokeLinecap: "square",
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  display: "inline"
}, d: "m 15.775,3.69375 -4,2.28125 0.375,1.34375 1.34375,0.375 2.28125,-4 z", id: "path3301" }), /* @__PURE__ */ React.createElement("path", { fill: "param(fill)", fillOpacity: 0.5, stroke: "none", style: {
  strokeWidth: 0.19956166,
  strokeLinecap: "square",
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  display: "inline"
}, d: "M 6.00625,11.80625 3.69375,15.775 7.6625,13.4625 7.31875,12.15 6.00625,11.80625 z", id: "path3299" }), /* @__PURE__ */ React.createElement("path", { fill: "param(fill)", fillOpacity: 0.5, stroke: "none", style: {
  strokeWidth: 0.19956166,
  strokeLinecap: "square",
  strokeLinejoin: "miter",
  strokeMiterlimit: 4,
  strokeOpacity: 1,
  strokeDasharray: "none",
  display: "inline"
}, d: "M 13.4625,11.80625 12.15,12.15 l -0.375,1.34375 4,2.28125 -2.3125,-3.96875 z", id: "path3137-4-9" })), /* @__PURE__ */ React.createElement("g", { id: "layer3", style: {
  display: "inline"
} }, /* @__PURE__ */ React.createElement("path", { fill: "param(fill)", fillOpacity: 255, stroke: "param(outline)", strokeOpacity: 255, style: {
  fontSize: "medium",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontStretch: "normal",
  textIndent: 0,
  textAlign: "start",
  textDecoration: "none",
  lineHeight: "normal",
  letterSpacing: "normal",
  wordSpacing: "normal",
  textTransform: "none",
  direction: "ltr",
  blockProgression: "tb",
  writingMode: "lr-tb",
  textAnchor: "start",
  baselineShift: "baseline",
  strokeWidth: 0.2375,
  marker: "none",
  visibility: "visible",
  display: "inline",
  overflow: "visible",
  enableBackground: "accumulate",
  fontFamily: "Sans",
  InkscapeFontSpecification: "Sans"
}, d: "m 9.7375,0.11875 c -5.3108819,0 -9.61875,4.3078672 -9.61875,9.6187503 0,5.3108817 4.3078681,9.6187497 9.61875,9.6187497 5.310882,0 9.61875,-4.307868 9.61875,-9.6187497 0,-5.3108831 -4.307868,-9.6187503 -9.61875,-9.6187503 z m 0,19 c -5.1825274,0 -9.38125,-4.198723 -9.38125,-9.3812497 0,-5.1825286 4.1987226,-9.3812503 9.38125,-9.3812503 5.182527,0 9.38125,4.1987217 9.38125,9.3812503 0,5.1825267 -4.198723,9.3812497 -9.38125,9.3812497", id: "path3049" }), /* @__PURE__ */ React.createElement("path", { sodipodiType: "star", fill: "param(fill) #000", fillOpacity: 255, stroke: "param(fill) #000", strokeOpacity: 255, id: "path3137", d: "M 40,26.620001 32.12132,28.741321 30,36.620001 27.87868,28.741321 20,26.620001 27.87868,24.498681 30,16.620001 32.12132,24.49868 z", transform: "matrix(0.9076178,0,0,0.90807237,-17.497969,-14.436479)" })));
export default SvgNorthArrow05;
