import { useEffect } from "react"
import { useScopedDraw } from "utils/customHooks/map/useScopedDraw"
import { useDigitizeFeatures } from "../../PrintMap/logic/useDigitizeFeatures"

// TODO: add types for Digitize
const DigitizeLayers = () => {
    const draw = useScopedDraw()
    const digitizeFeatures = useDigitizeFeatures()

    useEffect(() => {
        addFeatures(digitizeFeatures)
    }, [])

    const addFeatures = features => {
        if (features.length > 0) {
            const drawnFeatures = {
                features,
                paint: {
                    "line-color": "red",
                },
                type: "FeatureCollection",
            }
            // Last time when tried, I had problems casting anything to fit this type
            draw.add(drawnFeatures)
        }
    }

    return null
}

export default DigitizeLayers
