import * as React from "react";
const SvgIogp1034 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "282.4, 224.28, 180.55002, 166.28", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "372.67,359.77 372.67,359.77", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "283.09,359.77 462.26,359.77", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "309.96,390.31 435.38,390.31", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "372.67,224.53 372.67,359.77", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "421.31,289.38 410.06,359.71", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "462.7,332.08 447.43,359.77", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "324.03,289.38 335.28,359.71", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "282.65,332.08 297.91,359.77", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CD6666"
} }));
export default SvgIogp1034;
