import * as React from "react";
const SvgClothingStore15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "clothing-store-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 1 L0 4 L0 7 L2.9 7 L3 14 L12 14 L12 7 L15 7 L15 4 L11.5 1 L10 1 L7.5 5 L5 1 L3.5 1 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 1 L0 4 L0 7 L2.9 7 L3 14 L12 14 L12 7 L15 7 L15 4 L11.5 1 L10 1 L7.5 5 L5 1 L3.5 1 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgClothingStore15;
