import * as React from "react";
const SvgFastFood15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fast-food-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 8 C14 8.5522995 13.5523 9 13 9 L2 9 C1.4477 9 1 8.5523 1 8 C1 7.4476995 1.4477 7 2 7 L13 7 C13.5523 7 14 7.4477 14 8 z M3.5 10 L2 10 C2 11.6569 3.3431 13 5 13 L10 13 C11.6569 13 13 11.6569 13 10 L3.5 10 z M3 6 L2 6 L2 4 C2 2.8954 2.8954 2 4 2 L11 2 C12.1046 2 13 2.8954 13 4 L13 6 L3 6 z M11 4.5 C11 4.7761 11.2239 5 11.5 5 C11.7761 5 12 4.7761 12 4.5 C12 4.2239 11.7761 4 11.5 4 C11.2239 4 11 4.2239 11 4.5 z M9 3.5 C9 3.7761 9.2239 4 9.5 4 C9.7761 4 10 3.7761 10 3.5 C10 3.2239 9.7761 3 9.5 3 C9.2239 3 9 3.2239 9 3.5 z M7 4.5 C7 4.7761 7.2239 5 7.5 5 C7.7761 5 8 4.7761 8 4.5 C8 4.2239 7.7761 4 7.5 4 C7.2239 4 7 4.2239 7 4.5 z M5 3.5 C5 3.7761 5.2239 4 5.5 4 C5.7761 4 6 3.7761 6 3.5 C6 3.2239 5.7761 3 5.5 3 C5.2239 3 5 3.2239 5 3.5 z M3 4.5 C3 4.7761 3.2239 5 3.5 5 C3.7761 5 4 4.7761 4 4.5 C4 4.2239 3.7761 4 3.5 4 C3.2239 4 3 4.2239 3 4.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 8 C14 8.5522995 13.5523 9 13 9 L2 9 C1.4477 9 1 8.5523 1 8 C1 7.4476995 1.4477 7 2 7 L13 7 C13.5523 7 14 7.4477 14 8 z M3.5 10 L2 10 C2 11.6569 3.3431 13 5 13 L10 13 C11.6569 13 13 11.6569 13 10 L3.5 10 z M3 6 L2 6 L2 4 C2 2.8954 2.8954 2 4 2 L11 2 C12.1046 2 13 2.8954 13 4 L13 6 L3 6 z M11 4.5 C11 4.7761 11.2239 5 11.5 5 C11.7761 5 12 4.7761 12 4.5 C12 4.2239 11.7761 4 11.5 4 C11.2239 4 11 4.2239 11 4.5 z M9 3.5 C9 3.7761 9.2239 4 9.5 4 C9.7761 4 10 3.7761 10 3.5 C10 3.2239 9.7761 3 9.5 3 C9.2239 3 9 3.2239 9 3.5 z M7 4.5 C7 4.7761 7.2239 5 7.5 5 C7.7761 5 8 4.7761 8 4.5 C8 4.2239 7.7761 4 7.5 4 C7.2239 4 7 4.2239 7 4.5 z M5 3.5 C5 3.7761 5.2239 4 5.5 4 C5.7761 4 6 3.7761 6 3.5 C6 3.2239 5.7761 3 5.5 3 C5.2239 3 5 3.2239 5 3.5 z M3 4.5 C3 4.7761 3.2239 5 3.5 5 C3.7761 5 4 4.7761 4 4.5 C4 4.2239 3.7761 4 3.5 4 C3.2239 4 3 4.2239 3 4.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFastFood15;
