import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDidUpdateEffect } from "@windgis/shared"
import { AppLayer } from "model/app/AppLayer"
import { useDispatch, useSelector } from "react-redux"
import { toggleAppLayer } from "actions/globalActions"
import { hideAllSubs } from "reducers/ais"
import * as layerSelectorActions from "reducers/layerSelector"
import { resetLayerGroupsToInitialState, setLayerIsFiltered } from "reducers/layerSelector"
import * as mapActions from "reducers/map"
import { getSelectedApp } from "selectors/appsSelectors"
import { getFetchingLayers, getLayerGroups } from "selectors/layerSelector"

const useLayerSelectorRoute = () => {
    const [searchValue, setSearchValue] = useState("")
    const [openDownloadModal, setOpenDownloadModal] = useState(false)
    const [openLayerMetadata, setOpenLayerMetadata] = useState(false)
    const [openAttributeTable, setOpenAttributeTable] = useState(false)
    const [layer, setLayer] = useState<AppLayer | null>(null)
    const [featureIds, setFeatureIds] = useState<number[]>([])

    const layerGroups = useSelector(getLayerGroups)
    const isFetching = useSelector(getFetchingLayers)
    const currentApp = useSelector(getSelectedApp)

    const dispatch = useDispatch()

    const handleOpenLayerMetadata = useCallback((layer: AppLayer) => {
        setOpenLayerMetadata(true)
        setOpenAttributeTable(false)
        setLayer(layer)
    }, [])

    const handleCloseLayerMetadata = () => {
        setOpenLayerMetadata(false)
        setLayer(null)
    }

    const handleOpenDownloadModal = useCallback((layer: AppLayer, featureIds: number[]) => {
        setOpenDownloadModal(true)
        setLayer(layer)
        setFeatureIds(featureIds)
    }, [])

    const handleCloseDownloadModal = () => {
        setOpenDownloadModal(false)
    }

    const handleOpenAttributeTable = useCallback((layer: AppLayer) => {
        setOpenAttributeTable(true)
        setOpenLayerMetadata(false)
        setLayer(layer)
    }, [])

    const handleCloseAttributeTable = () => {
        setOpenAttributeTable(false)
        setLayer(null)
    }

    const handleHideAll = () => {
        dispatch(hideAllSubs())
        dispatch(layerSelectorActions.hideAllLayers())
        dispatch(mapActions.hideAllLayers())
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchValue(event.target.value)
    }

    const clearSearch = () => {
        setSearchValue("")
    }

    const onStyleLayer = useCallback((layer: AppLayer) => {
        dispatch(layerSelectorActions.setShouldFilter(false))
        dispatch(layerSelectorActions.setStylerLayerId(layer.resourceId))
        dispatch(toggleAppLayer({ resourceId: layer.resourceId, visible: true }))
        setLayer(layer)
    }, [])

    const onFilterLayer = useCallback((layer: AppLayer) => {
        dispatch(layerSelectorActions.setShouldFilter(true))
        dispatch(layerSelectorActions.setStylerLayerId(layer.resourceId))
        dispatch(toggleAppLayer({ resourceId: layer.resourceId, visible: true }))
        setLayer(layer)
    }, [])

    const onClearFilters = useCallback((layer: AppLayer) => {
        dispatch(mapActions.setFilters({ filters: [], layerId: layer.resourceId }))
        dispatch(setLayerIsFiltered({ layerResourceId: layer.resourceId, value: false }))
    }, [])

    const layerHandlers = useMemo(
        () => ({
            handleOpenAttributeTable,
            handleOpenDownloadModal,
            handleOpenLayerMetadata,
            onClearFilters,
            onFilterLayer,
            onStyleLayer,
        }), // This is done for good practice. These will never get redefined but, we are less bug-prone this way
        [
            handleOpenDownloadModal,
            handleOpenLayerMetadata,
            handleOpenAttributeTable,
            onStyleLayer,
            onFilterLayer,
            onClearFilters,
        ],
    )

    const getSearchedLayers = () => {
        return layerGroups.filterLayersAndGroupsRecursive(layer =>
            layer.name.toLowerCase().includes(searchValue.toLowerCase()),
        )
    }

    const setCollapsedToFalse = () => {
        //Still need to make a shallow copy for the nested groups
        groups.forGroupsRecursive(group => {
            dispatch(layerSelectorActions.toggleGroupCollapse({ groupId: group.resourceId, newCollapseValue: false }))
        })
    }

    const groups = searchValue !== "" ? getSearchedLayers() : layerGroups

    useEffect(() => {
        setOpenAttributeTable(false)
    }, [currentApp])

    useDidUpdateEffect(() => {
        if (!!groups && searchValue !== "") {
            setCollapsedToFalse()
        } else {
            dispatch(resetLayerGroupsToInitialState())
        }
    }, [searchValue])

    return {
        clearSearch,
        featureIds,
        groups,
        layer,
        layerHandlers,
        openAttributeTable,
        openDownloadModal,
        openLayerMetadata,
        searchValue,
        isFetching,
        handleCloseAttributeTable,
        handleCloseDownloadModal,
        handleCloseLayerMetadata,
        handleHideAll,
        handleOpenDownloadModal,
        handleSearch,
    }
}

export default useLayerSelectorRoute
