import * as React from "react";
const SvgRail11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 10.5 C9 10.7761 8.7761 11 8.5 11 C8.3176 10.9971 8.1519 10.893 8.07 10.73 L8.07 10.73 L7.69 10 L3.31 10 L2.94 10.74 L2.94 10.74 C2.8537 10.9023 2.6838 11.0027 2.5 11 C2.2239 11 2 10.7761 2 10.5 C2.0022 10.4153 2.0263 10.3326 2.07 10.26 L2.07 10.26 L3.07 8.26 L3.07 8.26 C3.1546 8.1008 3.3198 8.001 3.5 8 C3.7751 7.9765 4.0172 8.1805 4.0407 8.4556 C4.0479 8.5392 4.0339 8.6233 4 8.7 L3.81 9 L7.19 9 L7 8.7 C6.8991 8.443 7.0256 8.1528 7.2826 8.0518 C7.514 7.9609 7.7771 8.0539 7.9 8.27 L7.9 8.27 L8.9 10.27 L8.9 10.27 C8.9527 10.3367 8.9872 10.416 9 10.5 z M8 0 L3 0 C2.4477 0 2 0.4477 2 1 L2 6 C2 6.5523 2.4477 7 3 7 L8 7 C8.5522995 7 9 6.5523 9 6 L9 1 C9 0.4477 8.5523 0 8 0 z M3.5 6 C3.2239 6 3 5.7761 3 5.5 C3 5.2239 3.2239 5 3.5 5 C3.7761 5 4 5.2239 4 5.5 C4 5.7761 3.7761 6 3.5 6 z M3.5 4 C3.2239 4 3 3.7761 3 3.5 L3 1.5 C3 1.2239 3.2239 1 3.5 1 L5.29 1 L5.29 4 L3.5 4 z M7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 z M8 3.5 C8 3.7761 7.7761 4 7.5 4 L5.69 4 L5.69 1 L7.5 1 C7.7761 1 8 1.2239 8 1.5 L8 3.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 10.5 C9 10.7761 8.7761 11 8.5 11 C8.3176 10.9971 8.1519 10.893 8.07 10.73 L8.07 10.73 L7.69 10 L3.31 10 L2.94 10.74 L2.94 10.74 C2.8537 10.9023 2.6838 11.0027 2.5 11 C2.2239 11 2 10.7761 2 10.5 C2.0022 10.4153 2.0263 10.3326 2.07 10.26 L2.07 10.26 L3.07 8.26 L3.07 8.26 C3.1546 8.1008 3.3198 8.001 3.5 8 C3.7751 7.9765 4.0172 8.1805 4.0407 8.4556 C4.0479 8.5392 4.0339 8.6233 4 8.7 L3.81 9 L7.19 9 L7 8.7 C6.8991 8.443 7.0256 8.1528 7.2826 8.0518 C7.514 7.9609 7.7771 8.0539 7.9 8.27 L7.9 8.27 L8.9 10.27 L8.9 10.27 C8.9527 10.3367 8.9872 10.416 9 10.5 z M8 0 L3 0 C2.4477 0 2 0.4477 2 1 L2 6 C2 6.5523 2.4477 7 3 7 L8 7 C8.5522995 7 9 6.5523 9 6 L9 1 C9 0.4477 8.5523 0 8 0 z M3.5 6 C3.2239 6 3 5.7761 3 5.5 C3 5.2239 3.2239 5 3.5 5 C3.7761 5 4 5.2239 4 5.5 C4 5.7761 3.7761 6 3.5 6 z M3.5 4 C3.2239 4 3 3.7761 3 3.5 L3 1.5 C3 1.2239 3.2239 1 3.5 1 L5.29 1 L5.29 4 L3.5 4 z M7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 z M8 3.5 C8 3.7761 7.7761 4 7.5 4 L5.69 4 L5.69 1 L7.5 1 C7.7761 1 8 1.2239 8 1.5 L8 3.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRail11;
