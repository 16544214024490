import * as React from "react";
const SvgHeliport11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "heliport-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 1 C2.723 1 2.5 1.223 2.5 1.5 C2.5 1.777 2.7236 1.9824 3 2 L6 2 L6 4 L2.9141 4 L2.9120998 4 C2.7004 3.4014 2.1349 3.0009 1.5 3 C0.6716 3 0 3.6716 0 4.5 C0 5.3283997 0.6716 6 1.5 6 C1.8794 5.9993 2.2444 5.8548 2.5215 5.5957 L4.5 8.5 C5.1812 9.5 6 9.5 6.5 9.5 L10.0352 9.5 C10.0352 9.5 11 9.4992 11 8.5 L11 7.5 C11 7 10.5 6.5 10.5 6.5 L8.5 4.5 C8.5 4.5 8 4 7.5 4 L7 4 L7 2 L10 2 C10.277 2 10.5 1.777 10.5 1.5 C10.5 1.223 10.277 1 10 1 L3 1 z M1.5 4 C1.7761 4 2 4.2239 2 4.5 C2 4.7761 1.7761 5 1.5 5 C1.2239 5 1 4.7761 1 4.5 C1 4.2239 1.2239 4 1.5 4 z M7.75 5 C7.75 5 8 5 8.5 5.5 L10 7 L7.5 7 C7.5 7 7 7 7 6.5 L7 5.5 C7 5.5 7 5 7.5 5 L7.75 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 1 C2.723 1 2.5 1.223 2.5 1.5 C2.5 1.777 2.7236 1.9824 3 2 L6 2 L6 4 L2.9141 4 L2.9120998 4 C2.7004 3.4014 2.1349 3.0009 1.5 3 C0.6716 3 0 3.6716 0 4.5 C0 5.3283997 0.6716 6 1.5 6 C1.8794 5.9993 2.2444 5.8548 2.5215 5.5957 L4.5 8.5 C5.1812 9.5 6 9.5 6.5 9.5 L10.0352 9.5 C10.0352 9.5 11 9.4992 11 8.5 L11 7.5 C11 7 10.5 6.5 10.5 6.5 L8.5 4.5 C8.5 4.5 8 4 7.5 4 L7 4 L7 2 L10 2 C10.277 2 10.5 1.777 10.5 1.5 C10.5 1.223 10.277 1 10 1 L3 1 z M1.5 4 C1.7761 4 2 4.2239 2 4.5 C2 4.7761 1.7761 5 1.5 5 C1.2239 5 1 4.7761 1 4.5 C1 4.2239 1.2239 4 1.5 4 z M7.75 5 C7.75 5 8 5 8.5 5.5 L10 7 L7.5 7 C7.5 7 7 7 7 6.5 L7 5.5 C7 5.5 7 5 7.5 5 L7.75 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHeliport11;
