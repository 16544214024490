export enum Types {
    CharacterVarying = "character varying",
    Date = "date",
    DoublePrecision = "double precision",
    Integer = "integer",
    String = "string",
    Undefined = "",
}

export type LiteralType = Types.CharacterVarying | Types.String
export type NumericType = Types.Integer | Types.DoublePrecision
export type DateType = Types.Date
export type UndefinedType = Types.Undefined
