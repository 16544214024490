import * as React from "react";
const SvgCampsite15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "campsite-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1.5 L1.5 10.5 L1 10.5 C0 10.5 0 11.5 0 11.5 L0 12.5 C0 12.5 0 13.5 1 13.5 L14 13.5 C15 13.5 15 12.5 15 12.5 L15 11.5 C15 11.5 15 10.5 14 10.5 L13.5 10.5 L8 1.5 C7.8 1.1 7.2 1.1 7 1.5 z M7.5 5 L10.7 10.5 L4.2 10.5 L7.5 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1.5 L1.5 10.5 L1 10.5 C0 10.5 0 11.5 0 11.5 L0 12.5 C0 12.5 0 13.5 1 13.5 L14 13.5 C15 13.5 15 12.5 15 12.5 L15 11.5 C15 11.5 15 10.5 14 10.5 L13.5 10.5 L8 1.5 C7.8 1.1 7.2 1.1 7 1.5 z M7.5 5 L10.7 10.5 L4.2 10.5 L7.5 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCampsite15;
