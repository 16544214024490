import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios"
import fileDownload from "js-file-download"
import { featureIdsParam, includeMetadataParam } from "constants/map/queryParams"
import { MetadataPropertyValue } from "../model/metadata/MetadataPropertyValue"
import { RejectValue } from "../store/models/RejectValue"
import axiosClient from "./apiClient"

type Config = {
    onDownloadProgress: (progressEvent: { loaded: number; total: number }) => void
}

type DownloadDatasetArg = {
    config: Config
    datasetId: string
    featureIds: number[]
    format: string
    includeMetadata: boolean
}
export const downloadDataset = createAsyncThunk<AxiosResponse<any>, DownloadDatasetArg, RejectValue>(
    "downloadDataset",
    async ({ config, datasetId, featureIds, format, includeMetadata }: DownloadDatasetArg, { rejectWithValue }) => {
        const queryParams = new URLSearchParams()
        queryParams.append(includeMetadataParam, includeMetadata.toString())
        featureIds?.forEach(x => queryParams.append(featureIdsParam, x.toString()))

        return axiosClient
            .get(`dataset/${datasetId}/download/${format}?${queryParams.toString()}`, {
                ...config,
                responseType: "blob",
            })
            .then(res => res)
            .catch(err => {
                return rejectWithValue(err)
            })
    },
)

export const getLayerMetadata = createAsyncThunk<MetadataPropertyValue[], string>(
    "getLayerMetadata",
    (datasetId: string) => {
        return axiosClient.get(`dataset/meta/${datasetId}`).then(res => res.data.metadata)
    },
)

type DownloadGeotiffArg = { config: Config; fileName: string; rasterId: string }
export const downloadGeotiff = createAsyncThunk<void, DownloadGeotiffArg, RejectValue>(
    "downloadGeotiff",
    ({ config, fileName, rasterId }, { rejectWithValue }) => {
        return axiosClient
            .get(`raster/${rasterId}/download/`, {
                ...config,
                responseType: "blob",
            })
            .then(res => {
                fileDownload(res.data, fileName)
            })
            .catch(err => rejectWithValue(err))
    },
)
