import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function recurseOne<T = object>(
    layers: GenericLayer<T>[],
    id: string,
    func: (layer?: GenericLayer<T>, index?: number, parentGroupId?: string) => any,
    parentGroupId?: string,
): any {
    for (let i = 0; i < layers.length; i++) {
        const layer = layers[i]

        if (layer.resourceId === id) {
            return func(layer, i, parentGroupId)
        }

        if (isGroup(layer)) {
            let out: object | undefined = recurseOne(layer.layers!, id, func, layer.resourceId)
            if (out) return out
        }
    }
}
