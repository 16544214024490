import { useMemo, useState } from "react"
import { CancelTokenSource } from "axios"
import { generateCancelToken } from "../../actions/apiClient"

type ReturnValue = [stateVariable: CancelTokenSource, updateFunction: () => void]

const useComponentCancelToken = (): ReturnValue => {
    const [flag, setFlag] = useState(true)

    const regenerateToken = () => setFlag(f => !f)

    const cancelToken = useMemo(() => generateCancelToken(), [flag])

    return [cancelToken, regenerateToken]
}

export default useComponentCancelToken
