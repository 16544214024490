import * as React from "react";
const SvgPicnicSite11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "picnic-site-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.75 2 C2.3345 2 2 2.3345 2 2.75 C2 3.1655 2.3345 3.5 2.75 3.5 L4 3.5 L3.5391 5 L1.75 5 C1.3345 5 1 5.3345 1 5.75 C1 6.1655 1.3345 6.5 1.75 6.5 L3.0762 6.5 L2 10 L3 10 L4.0762 6.5 L6.9239 6.5 L8 10 L9 10 L7.9238 6.5 L9.25 6.5 C9.6655 6.5 10 6.1655 10 5.75 C10 5.3345 9.6655 5 9.25 5 L7.4609 5 L7 3.5 L8.25 3.5 C8.6655 3.5 9 3.1655 9 2.75 C9 2.3345 8.6655 2 8.25 2 L2.75 2 z M5 3.5 L6 3.5 L6.4609 5 L4.5391 5 L5 3.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.75 2 C2.3345 2 2 2.3345 2 2.75 C2 3.1655 2.3345 3.5 2.75 3.5 L4 3.5 L3.5391 5 L1.75 5 C1.3345 5 1 5.3345 1 5.75 C1 6.1655 1.3345 6.5 1.75 6.5 L3.0762 6.5 L2 10 L3 10 L4.0762 6.5 L6.9239 6.5 L8 10 L9 10 L7.9238 6.5 L9.25 6.5 C9.6655 6.5 10 6.1655 10 5.75 C10 5.3345 9.6655 5 9.25 5 L7.4609 5 L7 3.5 L8.25 3.5 C8.6655 3.5 9 3.1655 9 2.75 C9 2.3345 8.6655 2 8.25 2 L2.75 2 z M5 3.5 L6 3.5 L6.4609 5 L4.5391 5 L5 3.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPicnicSite11;
