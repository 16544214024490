import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Basemap } from "model/app/Basemap"
import { Language } from "model/app/Language"
import { Projection } from "model/app/Projection"
import { Widget } from "model/tools/Widget"
import { getApp } from "actions/apps"
import { resetProjectData } from "actions/globalActions"
import { DEFAULT_PROJECTION } from "./constants"
import { Tool } from "./models/Tool"

type MapToolsState = {
    basemaps: Basemap[]
    languages: Language[]
    projections: Projection[]
    selectedProjection: Projection
    tools: Tool[]
    widgets: Widget[]
    toggledWidgets: Record<string, any>
}

const initialState: MapToolsState = {
    basemaps: [],
    languages: [],
    projections: [],
    selectedProjection: DEFAULT_PROJECTION,
    tools: [],
    widgets: [],
    toggledWidgets: {},
}

const mapToolsSlice = createSlice({
    extraReducers: builder => {
        builder
            .addCase(resetProjectData, () => initialState)
            .addCase(getApp.fulfilled, (state, { payload }) => {
                state.tools = [...payload.tools]
                state.widgets = [...payload.widgets]
            })
    },
    initialState: initialState,
    name: "mapTools",
    reducers: {
        closeWidgets: state => {
            state.toggledWidgets = {}
        },
        toggleWidget: (state, { payload: widgetName }: PayloadAction<string>) => {
            if (state.toggledWidgets.hasOwnProperty(widgetName)) {
                delete state.toggledWidgets[widgetName]
            } else {
                state.toggledWidgets[widgetName] = null
            }
        },
        toggleWidgetWithArgs: (
            state,
            { payload: { args, widgetName } }: PayloadAction<{ args: any; widgetName: string }>,
        ) => {
            if (state.toggledWidgets.hasOwnProperty(widgetName)) {
                delete state.toggledWidgets[widgetName]
            } else {
                state.toggledWidgets[widgetName] = args
            }
        },
        setConfig: (state, { payload }: PayloadAction<Partial<MapToolsState>>) => ({ ...state, ...payload }),
        setSelectedProjection: (state, { payload }: PayloadAction<Projection>) => {
            state.selectedProjection = payload
        },
    },
})

export const { closeWidgets, toggleWidget, toggleWidgetWithArgs, setConfig, setSelectedProjection } =
    mapToolsSlice.actions

export default mapToolsSlice.reducer
