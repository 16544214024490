import makeStyles from "@mui/styles/makeStyles"

export const useCoordinatesSearchStyles = makeStyles(theme => ({
    closeBtn: {
        background: "#F3F3F3",
    },
    content: {
        padding: 16,
    },
    header: {
        alignItems: "center",
        backgroundColor: "#f5f7f8",
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        display: "flex",
        padding: 8,
        paddingLeft: 16,
    },
    headerText: {
        flexGrow: 1,
    },
    root: {
        backgroundColor: "#fff",
        borderRadius: 4,
        left: 16,
        minWidth: 300,
        position: "absolute",
        top: 16,
        zIndex: 2,
    },
    searchContainer: {
        padding: "8px 16px",
    },
    searchError: {
        color: "red",
        fontSize: 10,
    },
    searchText: {
        padding: 16,
    },
    squareBtn: {
        borderRadius: 0,
        padding: 0,
    },
    "squareBtn:active:focus": {
        borderRadius: 0,
        padding: 0,
    },
}))
