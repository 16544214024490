import { BaseQueryFn } from "@reduxjs/toolkit/query"
import { AxiosError, AxiosRequestConfig } from "axios"
import apiClient from "../actions/apiClient"

type ErrorType = {
    message: string
    status: number
}

const axiosBaseQuery =
    (
        baseUrl: string,
    ): BaseQueryFn<
        {
            data?: AxiosRequestConfig["data"]
            method: AxiosRequestConfig["method"]
            params?: AxiosRequestConfig["params"]
            url: string
            cancelToken?: AxiosRequestConfig["cancelToken"]
            onUploadProgress?: AxiosRequestConfig["onUploadProgress"]
        },
        unknown,
        ErrorType
    > =>
    ({ data, method, params, url, cancelToken, onUploadProgress }) =>
        new Promise((resolve, reject) => {
            apiClient
                .request({
                    data,
                    method,
                    params,
                    url: baseUrl + url,
                    cancelToken,
                    onUploadProgress,
                })
                .then(result => {
                    resolve({ data: result.data })
                })
                .catch(axiosError => {
                    const err = axiosError

                    reject({
                        message: err.response?.data.error || "An unexpected error occurred",
                        status: err.response?.status,
                    })
                })
        })

export const isApiError = (error: any): error is ErrorType => error !== undefined && "message" in error

export default axiosBaseQuery
