import makeStyles from "@mui/styles/makeStyles"

export const useDigitizeLayerStyles = makeStyles(() => ({
    buttonIcon: {
        color: "rgba(51, 51, 51, 1)",
        display: "flex",
        height: 20,
        paddingRight: 6,
        width: 16,
    },
    digitizeStyleMenuIcon: {
        "& .MuiListItemIcon-root": {
            fontSize: "0.865rem",
        },
        minWidth: 32,
    },
    digitizeStyleMenuText: {
        "& .MuiListItemText-primary": {
            fontSize: "0.865rem",
        },
    },
    editFieldContainer: {
        paddingLeft: 16,
    },
    layerMoreButton: {
        borderRadius: 0,
    },
    layerName: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
    },
    selectedLayer: {
        alignItems: "center",
        backgroundColor: "lightgray",
        borderBottom: "1px solid whitesmoke",
        cursor: "pointer",
        display: "flex",
    },
    textField: {
        width: 180,
    },
    title: {
        padding: "8px 16px",
    },
    unselectedLayer: {
        alignItems: "center",
        backgroundColor: "#fff",
        borderBottom: "1px solid whitesmoke",
        cursor: "pointer",
        display: "flex",
    },
}))
