import { HelperLayers } from "../../model/enums/HelperLayers"
import { Basemap } from "../../model/mapComponent/Basemap"

const blankBasemapStyle: Basemap = {
    glyphs: "mapbox://fonts/lautec/{fontstack}/{range}.pbf",
    layers: [
        { id: "background", layout: {}, paint: { "background-color": "hsl(33, 28%, 93%)" }, type: "background" },
        HelperLayers.GeometricHelperLayer,
        HelperLayers.SymbolHelperLayer,
    ],
    sources: {
        composite: {
            type: "vector",
            url: "mapbox://mapbox.mapbox-streets-v8,mapbox.92olaqdt,mapbox.mapbox-terrain-v2,mapbox.9tm8dx88,mapbox.4suez6c9,mapbox.akwnx50w,mapbox.8x70v9py,mapbox.b1l3wqbs,mapbox.cc9j0p61,mapbox.d4advw8k",
        },
    },
    sprite: "mapbox://sprites/lautec/ckk3texd9324b17qjeyovy1h1/812abu9kwt5kxuokhr7dc9ajq",
    version: 8,
}

export default blankBasemapStyle
