import * as React from "react";
const SvgIogp1006 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "390.56, 273.42, 154.14001, 124.119995", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("path", { d: "M407.57 375.52 L407.57 365.51 L392.56 365.51 L407.57 375.52 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M407.57 375.52 L437.6 395.54 L407.57 365.51 L407.57 375.52 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.6 395.54 L437.6 365.51 L407.57 365.51 L437.6 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.6 358 L437.6 345.49 L407.57 320.46 L437.6 358 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.6 395.54 L443.61 395.54 L437.6 365.51 L437.6 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.61 395.54 L443.61 365.51 L437.6 365.51 L443.61 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.6 358 L443.61 365.51 L437.6 345.49 L437.6 358 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.61 365.51 L443.61 350.49 L437.6 345.49 L443.61 365.51 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.61 395.54 L455.26 395.54 L443.61 350.49 L443.61 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.26 395.54 L455.26 360.21 L443.61 350.49 L455.26 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.26 395.54 L481.08 395.54 L455.26 360.21 L455.26 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.08 395.54 L481.08 308.58 L455.26 360.21 L481.08 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.08 395.54 L497.66 395.54 L481.08 365.51 L481.08 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.66 395.54 L497.66 356.51 L481.08 365.51 L497.66 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.08 365.51 L497.66 275.42 L481.08 308.58 L481.08 365.51 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.66 395.54 L502.03 392.62 L497.66 356.51 L497.66 395.54 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M502.03 392.62 L502.03 354.14 L497.66 356.51 L502.03 392.62 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M502.03 392.62 L536.43 369.69 L502.03 365.51 L502.03 392.62 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M536.43 369.69 L536.43 365.51 L502.03 365.51 L536.43 369.69 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M502.03 365.51 L536.43 335.48 L502.03 354.14 L502.03 365.51 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("path", { d: "M536.43 369.69 L542.7 365.51 L536.43 365.51 L536.43 369.69 z", fillRule: "evenodd", style: {
  fill: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "437.6,395.54 497.66,395.54", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "497.66,395.54 542.7,365.51", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "542.7,365.51 502.03,365.51", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "502.03,365.51 536.43,335.48", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "536.43,335.48 481.08,365.51", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "481.08,365.51 497.66,275.42", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "497.66,275.42 455.26,360.21", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "455.26,360.21 407.57,320.46", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "407.57,320.46 443.61,365.51", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "443.61,365.51 392.56,365.51", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "392.56,365.51 437.6,395.54", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "467.63,335.48 467.63,335.48", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "blue"
} }));
export default SvgIogp1006;
