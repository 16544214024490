import { FC } from "react"
import { Chip, SvgIconTypeMap } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import clsx from "clsx"
import { useStyles } from "./styles"

type Props = {
    className?: string
    expanded: boolean
    Icon: {
        muiName: string
    } & OverridableComponent<SvgIconTypeMap<{}, "svg">>
    label: string
    size?: "small" | "large"
}

const CustomChip: FC<Props> = ({ className, expanded, Icon, label, size = "small" }) => {
    const classes = useStyles()

    return (
        <Chip
            className={clsx(
                classes.chip,
                size === "small" && classes.smallChip,
                expanded ? classes.chipExpanded : classes.chipShrinked,
                className,
            )}
            icon={<Icon className={clsx(classes.chipIcon, size === "small" && classes.smallChipIcon)} />}
            label={label}
        />
    )
}

export default CustomChip
