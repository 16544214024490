import { MenuItem } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

const StyledMenuItem = withStyles(theme => ({
    root: {
        "&:active": {
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
            backgroundColor: theme.palette.primary.main,
        },
        padding: "4px 6px",
    },
}))(MenuItem)

export default StyledMenuItem
