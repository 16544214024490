import * as React from "react";
const SvgFastFood11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fast-food-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 8 L10 8 C10 9.1046 9.1046 10 8 10 L3 10 C1.8954 10 1 9.1046 1 8 L10 8 z M10 5 L1 5 C0.4477 5 0 5.4477 0 6 C0 6.5523 0.4477 7 1 7 L10 7 C10.5522995 7 11 6.5523 11 6 C11 5.4477 10.5523 5 10 5 z M8.55 1 L2.46 1 C1.6537 1 1 1.6536 1 2.46 C1 2.4633 1 2.4667 1 2.47 L1 4 L10 4 L10 2.47 C10.0055 1.6637 9.3564 1.0055 8.55 1 C8.55 1 8.55 1 8.55 1 z M3.55 3 C3.2752 3.0276 3.0301 2.8273 3.0025 2.5525 C2.9749 2.2777 3.1753 2.0326 3.45 2.005 C3.7248 1.9774001 3.9699001 2.1777 3.9975 2.4525 C3.9991 2.4683 3.9999 2.4841 4 2.5 C4 2.7761 3.7761 3 3.5 3 L3.55 3 z M7.55 3 C7.2752 3.0276 7.0301 2.8273 7.0025 2.5525 C6.9749002 2.2777 7.1753 2.0326 7.45 2.005 C7.7247996 1.9774001 7.9698997 2.1777 7.9975 2.4525 C7.9991 2.4683 7.9999 2.4841 8 2.5 C8 2.7761 7.7761 3 7.5 3 L7.55 3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 8 L10 8 C10 9.1046 9.1046 10 8 10 L3 10 C1.8954 10 1 9.1046 1 8 L10 8 z M10 5 L1 5 C0.4477 5 0 5.4477 0 6 C0 6.5523 0.4477 7 1 7 L10 7 C10.5522995 7 11 6.5523 11 6 C11 5.4477 10.5523 5 10 5 z M8.55 1 L2.46 1 C1.6537 1 1 1.6536 1 2.46 C1 2.4633 1 2.4667 1 2.47 L1 4 L10 4 L10 2.47 C10.0055 1.6637 9.3564 1.0055 8.55 1 C8.55 1 8.55 1 8.55 1 z M3.55 3 C3.2752 3.0276 3.0301 2.8273 3.0025 2.5525 C2.9749 2.2777 3.1753 2.0326 3.45 2.005 C3.7248 1.9774001 3.9699001 2.1777 3.9975 2.4525 C3.9991 2.4683 3.9999 2.4841 4 2.5 C4 2.7761 3.7761 3 3.5 3 L3.55 3 z M7.55 3 C7.2752 3.0276 7.0301 2.8273 7.0025 2.5525 C6.9749002 2.2777 7.1753 2.0326 7.45 2.005 C7.7247996 1.9774001 7.9698997 2.1777 7.9975 2.4525 C7.9991 2.4683 7.9999 2.4841 8 2.5 C8 2.7761 7.7761 3 7.5 3 L7.55 3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFastFood11;
