import React, { useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import { Typography } from "@mui/material"
import { FilterType, useFilterContext } from "../../../../contexts/FilterContext"
import { Types } from "../../models/FilterType"
import { Operators } from "../../models/OperatorType"
import FilterIcon from "../FilterIcon/FilterIcon"
import { useFiltersListStyles } from "./style"

export const dummyFilter: FilterType = {
    applied: false,
    fieldName: "",
    operatorName: Operators.Undefined,
    type: Types.Undefined,
    value: null,
}

const FiltersList = () => {
    const context = useFilterContext()

    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    useEffect(() => {
        setIsButtonDisabled(!context.areAllFiltersSet())
    }, [context.filters])

    const handleAddFilter = () => {
        if (!isButtonDisabled) context.addFilter({ ...dummyFilter })
    }

    const classes = useFiltersListStyles({ isButtonDisabled: isButtonDisabled })
    return (
        <div className={classes.filtersListContainer}>
            <Typography variant="caption">
                <div className={classes.filterTitle}>Conditions</div>
            </Typography>

            <div className={classes.addButton} onClick={() => handleAddFilter()}>
                <AddIcon color="primary" data-testid={addFilterButtonTestId} fontStyle="small" />
            </div>

            {context.filters.map((filter, index) => (
                <FilterIcon key={index} filter={filter} index={index} />
            ))}
        </div>
    )
}

export default FiltersList

const addFilterButtonTestId = "qa-filters-list-add-filter-button"
