import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { getInfoboxes } from "features/infobox/selectors"
import { useAppSelector } from "store/hooks/useAppSelector"

const useInfoboxToUse = (datasetId: string | undefined) => {
    const infoboxes = useAppSelector(getInfoboxes)

    const { appId } = useParams<{ appId: string }>()

    const infoboxToUse = useMemo(() => {
        return (
            infoboxes.find(
                infobox =>
                    (!infobox.applicationId || infobox.applicationId === appId) && infobox.datasetId === datasetId,
            )?.name ?? "default"
        )
    }, [datasetId])

    return infoboxToUse
}

export default useInfoboxToUse
