import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function recurseWithReturn<T = object>(
    layers: GenericLayer<T>[],
    func: Function,
    selector: (layer: GenericLayer<T>) => boolean,
    parentGroupId?: string,
) {
    let mappedData = []
    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i]

        if (selector(layer)) mappedData.push(func(layer, i, parentGroupId))
        else mappedData.push({ ...layer })

        if (isGroup(mappedData[i])) {
            mappedData[i].layers = recurseWithReturn(mappedData[i].layers, func, selector, mappedData[i].resourceId)
        }
    }
    return mappedData
}
