import * as React from "react";
const SvgAmusementPark11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "amusement-park-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 C3.0206 1 1 3.0206 1 5.5 C1 7.2919 2.0627 8.8316 3.584 9.5547 L2.5 11 L8.5 11 L7.416 9.5547 C8.9373 8.8316 10 7.2919 10 5.5 C10 3.0206 7.9794 1 5.5 1 z M5.375 2.0117 L5.375 4.0058 C5.0642 4.0318 4.7693 4.154 4.5312 4.3553996 L3.1191 2.9434 C3.7146 2.3888 4.5013 2.0428 5.375 2.0117 z M5.625 2.0117 C6.4987 2.0428 7.2854 2.3888 7.8809 2.9432998 L6.4688 4.3555 C6.4681 4.3548 6.4675 4.3542004 6.4668 4.3535004 C6.229 4.1532 5.9348 4.0317 5.625 4.0059 L5.625 2.0117 z M2.9434 3.1191 L4.3554997 4.5312 C4.3547997 4.5319 4.3542 4.5325 4.3535 4.5332 C4.1532 4.771 4.0317 5.0652 4.0059 5.375 L2.0117 5.375 C2.0428 4.5013 2.3888 3.7146 2.9434 3.1191 z M8.0566 3.1191 C8.6112 3.7146 8.9572 4.5013 8.9883 5.375 L6.9941 5.375 C6.9681 5.0642 6.8459 4.7693 6.6445003 4.5312 L8.0566 3.1191 z M2.0117 5.625 L4.0058 5.625 C4.0318 5.9358 4.154 6.2307 4.3553996 6.4688 L2.9434 7.8809 C2.3888 7.2854 2.0428 6.4987 2.0117 5.625 z M6.9941 5.625 L8.9882 5.625 C8.9572 6.4987 8.6112 7.2854 8.0566 7.8809 L6.6445 6.4688 C6.6452 6.4681 6.6457996 6.4675 6.6464996 6.4668 C6.8468 6.229 6.9683 5.9348 6.9941 5.625 z M4.5312 6.6445 C4.5319 6.6452 4.5325 6.6457996 4.5332 6.6464996 C4.6716 6.7624 4.8297 6.8524 5 6.9121 L5 8.9512 C4.2765 8.8476 3.6278 8.5303 3.1191 8.0566 L4.5312 6.6445 z M6.4688 6.6445 L7.8809 8.0566 C7.3722 8.5303 6.7235 8.8476 6 8.9512 L6 6.9121 C6.1711 6.852 6.33 6.7613 6.4688 6.6445 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 C3.0206 1 1 3.0206 1 5.5 C1 7.2919 2.0627 8.8316 3.584 9.5547 L2.5 11 L8.5 11 L7.416 9.5547 C8.9373 8.8316 10 7.2919 10 5.5 C10 3.0206 7.9794 1 5.5 1 z M5.375 2.0117 L5.375 4.0058 C5.0642 4.0318 4.7693 4.154 4.5312 4.3553996 L3.1191 2.9434 C3.7146 2.3888 4.5013 2.0428 5.375 2.0117 z M5.625 2.0117 C6.4987 2.0428 7.2854 2.3888 7.8809 2.9432998 L6.4688 4.3555 C6.4681 4.3548 6.4675 4.3542004 6.4668 4.3535004 C6.229 4.1532 5.9348 4.0317 5.625 4.0059 L5.625 2.0117 z M2.9434 3.1191 L4.3554997 4.5312 C4.3547997 4.5319 4.3542 4.5325 4.3535 4.5332 C4.1532 4.771 4.0317 5.0652 4.0059 5.375 L2.0117 5.375 C2.0428 4.5013 2.3888 3.7146 2.9434 3.1191 z M8.0566 3.1191 C8.6112 3.7146 8.9572 4.5013 8.9883 5.375 L6.9941 5.375 C6.9681 5.0642 6.8459 4.7693 6.6445003 4.5312 L8.0566 3.1191 z M2.0117 5.625 L4.0058 5.625 C4.0318 5.9358 4.154 6.2307 4.3553996 6.4688 L2.9434 7.8809 C2.3888 7.2854 2.0428 6.4987 2.0117 5.625 z M6.9941 5.625 L8.9882 5.625 C8.9572 6.4987 8.6112 7.2854 8.0566 7.8809 L6.6445 6.4688 C6.6452 6.4681 6.6457996 6.4675 6.6464996 6.4668 C6.8468 6.229 6.9683 5.9348 6.9941 5.625 z M4.5312 6.6445 C4.5319 6.6452 4.5325 6.6457996 4.5332 6.6464996 C4.6716 6.7624 4.8297 6.8524 5 6.9121 L5 8.9512 C4.2765 8.8476 3.6278 8.5303 3.1191 8.0566 L4.5312 6.6445 z M6.4688 6.6445 L7.8809 8.0566 C7.3722 8.5303 6.7235 8.8476 6 8.9512 L6 6.9121 C6.1711 6.852 6.33 6.7613 6.4688 6.6445 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAmusementPark11;
