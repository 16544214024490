import * as React from "react";
const SvgLaundry11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "laundry-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0 L4 2 L2 2 C2 2 1 2 1 3 L1 10 C1 10 1 11 2 11 L9 11 C10 11 10 10 10 10 L10 1 C10 0 9 0 9 0 L5 0 z M6 1 L9 1 L9 2 L6 2 L6 1 z M5.5 4 C6.8807 4 8 5.1193 8 6.5 C8 7.8807 6.8807 9 5.5 9 C4.1193 9 3 7.8807 3 6.5 C3 5.1193 4.1193 4 5.5 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0 L4 2 L2 2 C2 2 1 2 1 3 L1 10 C1 10 1 11 2 11 L9 11 C10 11 10 10 10 10 L10 1 C10 0 9 0 9 0 L5 0 z M6 1 L9 1 L9 2 L6 2 L6 1 z M5.5 4 C6.8807 4 8 5.1193 8 6.5 C8 7.8807 6.8807 9 5.5 9 C4.1193 9 3 7.8807 3 6.5 C3 5.1193 4.1193 4 5.5 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLaundry11;
