import * as yup from "yup"
import { MAX_PERCENTILE, MIN_PERCENTILE } from "../constants"
import {
    duplictePercentileErrorMessage,
    integerTypeErrorMessage,
    maxErrorMessage,
    minErrorMessage,
    numberTypeErrorMessage,
} from "./common"

const percentilesSchema = yup
    .array()
    .of(
        yup
            .number()
            .typeError(numberTypeErrorMessage)
            .min(MIN_PERCENTILE, minErrorMessage(MIN_PERCENTILE))
            .max(MAX_PERCENTILE, maxErrorMessage(MAX_PERCENTILE))
            .integer(integerTypeErrorMessage),
    )
    .unique(duplictePercentileErrorMessage)

export default percentilesSchema
