import React, { useEffect, useRef, useState } from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import { Dialog, FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import withStyles from "@mui/styles/withStyles"
import csv2Geojson from "csv2geojson"
import { v4 as generateUuid } from "uuid"

const styles = () => ({})

const CsvDialog = ({ csvFile, loadGeoJson, handleClose, onFinished }) => {
    const [xField, setXField] = useState("")
    const [yField, setYField] = useState("")
    const [columns, setColumns] = useState([])

    const fileContent = useRef(null)

    useEffect(() => {
        if (csvFile) {
            setXField("")
            setYField("")

            new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = function (theFile) {
                    resolve(reader.result)
                }
                reader.readAsText(csvFile)
            }).then(res => {
                let header = res.split("\n")[0]
                let columnNames = header.split(",").map(col => col.trim())

                fileContent.current = res
                setColumns(columnNames.map(columnName => ({ id: generateUuid(), name: columnName })))
            })
        }
    }, [csvFile])

    const onYChanged = e => {
        setYField(e.target.value)
    }

    const onXChanged = e => {
        setXField(e.target.value)
    }

    const onDone = () => {
        const options = {
            delimite: ",",
            latfield: yField,
            lonfield: xField,
        }

        csv2Geojson.csv2geojson(fileContent.current, options, (err, data) => {
            if (err) {
                alert("Invalid data!")
            }
            let pointData = {
                features: [],
                type: "FeatureCollection",
            }
            data.features.forEach(feature => {
                feature.id = feature.properties.id
                pointData.features.push(feature)
            })
            loadGeoJson(pointData)
            handleClose()
        })
    }

    return (
        <Dialog className="atlas-dialog create-group-modal" open={!!csvFile} onClose={handleClose}>
            <div className="dialog-header">
                <Typography className="dialog-title" variant="h6">
                    Upload {csvFile?.name}
                </Typography>
                <Tooltip
                    title={
                        !yField || !xField ? "Please select the latitude and longitude columns before proceeding" : ""
                    }
                >
                    <div>
                        <Button color="primary" variant="contained" disabled={!yField || !xField} onClick={onDone}>
                            <AddIcon style={{ marginLeft: -8, marginRight: 8 }} />
                            Done
                        </Button>
                    </div>
                </Tooltip>
            </div>
            <div className="container">
                <div className="section">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="schema-label">Y Column</InputLabel>
                        <Select
                            className="column-select"
                            fullWidth
                            label="Y Column"
                            labelId="schema-label"
                            value={yField}
                            onChange={onYChanged}
                        >
                            {columns.map(column => (
                                <MenuItem key={column.id} value={column.name}>
                                    {column.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <br />
                <div className="section">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="schema-label">X Column</InputLabel>
                        <Select
                            className="column-select"
                            fullWidth
                            label="X Column"
                            labelId="schema-label"
                            value={xField}
                            onChange={onXChanged}
                        >
                            {columns.map(column => (
                                <MenuItem key={column.id} value={column.name}>
                                    {column.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Dialog>
    )
}

export default withStyles(styles)(CsvDialog)
