import Digitize from "components/sidebar/digitize/digitize"
import LegendTool from "components/sidebar/legend/Legend"
import LayerSelectorRoute from "features/layerSelector/LayerSelectorRoute"
import { DIGITIZE_PATH_SUFFIX, LAYER_SELECTOR_PATH_SUFFIX, LEGEND_PATH_SUFFIX } from "./routes"

export const digitizeTool = { component: Digitize, routeSuffix: DIGITIZE_PATH_SUFFIX }
export const legendTool = { component: LegendTool, routeSuffix: LEGEND_PATH_SUFFIX }
export const layerSelectorTool = {
    component: LayerSelectorRoute,
    routeSuffix: LAYER_SELECTOR_PATH_SUFFIX,
}

const toolsDict = {
    digitize: digitizeTool,
    "layer-selector": layerSelectorTool,
    legend: legendTool,
}
export default toolsDict
