import * as React from "react";
const SvgIogp1104 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "253.43, 173.08, 164.44, 238.92", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "401.27,252.8 389.92,286.86 367.21,309.57 333.15,320.92 321.8,320.92 287.74,309.57 265.03,286.86 253.68,252.8 253.68,241.45 265.03,207.39 287.74,184.68 321.8,173.33 333.15,173.33 367.21,184.68 389.92,207.39 401.27,252.8 401.27,309.57 389.92,366.34 367.21,400.4 333.15,411.75 310.44,411.75 276.38,400.4 265.03,377.69", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.4 337.15 L349.4 329.94 L349.2 333.55 L349.4 337.15 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.4 337.15 L349.98 340.71 L349.4 329.94 L349.4 337.15 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.98 340.71 L349.98 326.38 L349.4 329.94 L349.98 340.71 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.98 340.71 L350.95 344.19 L349.98 326.38 L349.98 340.71 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.95 344.19 L350.95 322.91 L349.98 326.38 L350.95 344.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.95 344.19 L352.28 347.54 L350.95 322.91 L350.95 344.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.28 347.54 L352.28 319.55 L350.95 322.91 L352.28 347.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.28 347.54 L353.97 350.73 L352.28 319.55 L352.28 347.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M353.97 350.73 L353.97 316.36 L352.28 319.55 L353.97 350.73 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M353.97 350.73 L356 353.72 L353.97 316.36 L353.97 350.73 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M356 353.72 L356 313.38 L353.97 316.36 L356 353.72 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M356 353.72 L358.33 356.47 L356 313.38 L356 353.72 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.33 356.47 L358.33 310.63 L356 313.38 L358.33 356.47 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.33 356.47 L360.95 358.95 L358.33 310.63 L358.33 356.47 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M360.95 358.95 L360.95 308.14 L358.33 310.63 L360.95 358.95 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M360.95 358.95 L363.83 361.14 L360.95 308.14 L360.95 358.95 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M363.83 361.14 L363.83 305.96 L360.95 308.14 L363.83 361.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M363.83 361.14 L366.92 363 L363.83 305.96 L363.83 361.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.92 363 L366.92 304.1 L363.83 305.96 L366.92 363 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.92 363 L370.2 364.51 L366.92 304.1 L366.92 363 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M370.2 364.51 L370.2 302.58 L366.92 304.1 L370.2 364.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M370.2 364.51 L373.62 365.67 L370.2 302.58 L370.2 364.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.62 365.67 L373.62 301.43 L370.2 302.58 L373.62 365.67 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.62 365.67 L377.14 366.44 L373.62 301.43 L373.62 365.67 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.14 366.44 L377.14 300.66 L373.62 301.43 L377.14 366.44 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.14 366.44 L380.73 366.83 L377.14 300.66 L377.14 366.44 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.73 366.83 L380.73 300.26 L377.14 300.66 L380.73 366.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.73 366.83 L384.34 366.83 L380.73 300.26 L380.73 366.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.34 366.83 L384.34 300.26 L380.73 300.26 L384.34 366.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.34 366.83 L387.93 366.44 L384.34 300.26 L384.34 366.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.93 366.44 L387.93 300.66 L384.34 300.26 L387.93 366.44 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.93 366.44 L391.45 365.67 L387.93 300.66 L387.93 366.44 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.45 365.67 L391.45 301.43 L387.93 300.66 L391.45 365.67 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.45 365.67 L394.87 364.51 L391.45 301.43 L391.45 365.67 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.87 364.51 L394.87 302.58 L391.45 301.43 L394.87 364.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.87 364.51 L398.15 363 L394.87 302.58 L394.87 364.51 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.15 363 L398.15 304.1 L394.87 302.58 L398.15 363 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.15 363 L401.24 361.14 L398.15 304.1 L398.15 363 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.24 361.14 L401.24 305.96 L398.15 304.1 L401.24 361.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.24 361.14 L404.11 358.95 L401.24 305.96 L401.24 361.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.11 358.95 L404.11 308.14 L401.24 305.96 L404.11 358.95 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.11 358.95 L406.73 356.47 L404.11 308.14 L404.11 358.95 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M406.73 356.47 L406.73 310.63 L404.11 308.14 L406.73 356.47 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M406.73 356.47 L409.07 353.72 L406.73 310.63 L406.73 356.47 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.07 353.72 L409.07 313.38 L406.73 310.63 L409.07 353.72 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.07 353.72 L411.09 350.73 L409.07 313.38 L409.07 353.72 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.09 350.73 L411.09 316.36 L409.07 313.38 L411.09 350.73 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.09 350.73 L412.78 347.54 L411.09 316.36 L411.09 350.73 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.78 347.54 L412.78 319.55 L411.09 316.36 L412.78 347.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.78 347.54 L414.12 344.19 L412.78 319.55 L412.78 347.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M414.12 344.19 L414.12 322.91 L412.78 319.55 L414.12 344.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M414.12 344.19 L415.09 340.71 L414.12 322.91 L414.12 344.19 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.09 340.71 L415.09 326.38 L414.12 322.91 L415.09 340.71 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.09 340.71 L415.67 337.15 L415.09 326.38 L415.09 340.71 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.67 337.15 L415.67 329.94 L415.09 326.38 L415.67 337.15 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.67 337.15 L415.87 333.55 L415.67 329.94 L415.67 337.15 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }));
export default SvgIogp1104;
