import * as React from "react";
const SvgFuel11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fuel-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 3 L9 3 L9 1.5 L9 1.5 C9 1.2239 8.7761 1 8.5 1 C8.2239 1 8 1.2239 8 1.5 L8 1.5 L8 3 C8 3.5523 8.4477 4 9 4 L9 8.25 C9 8.3881 8.8881 8.5 8.75 8.5 C8.6119 8.5 8.5 8.3881 8.5 8.25 L8.5 6.5 C8.5 5.6716 7.8284 5 7 5 L7 2 C7 1.4477 6.5523 1 6 1 L2 1 C1.4477 1 1 1.4477 1 2 L1 9 C1 9.5522995 1.4477 10 2 10 L6 10 C6.5523 10 7 9.5523 7 9 L7 6 C7.2761 6 7.5 6.2239 7.5 6.5 L7.5 8.25 C7.5 8.9404 8.0596 9.5 8.75 9.5 C9.4404 9.5 10 8.9404 10 8.25 L10 3.5 C10 3.2239 9.7761 3 9.5 3 z M6 4.5 C6.0056 4.7706 5.7908 4.9944 5.5202 5 C5.5201 5 5.5201 5 5.52 5 L2.52 5 C2.2384 5.0056 2.0056 4.7818 2 4.5002 C2 4.5001 2 4.5001 2 4.5 L2 4.5 L2 3 C2 2.7239 2.2239 2.5 2.5 2.5 L5.5 2.5 C5.7761 2.5 6 2.7239 6 3 L6 4.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 3 L9 3 L9 1.5 L9 1.5 C9 1.2239 8.7761 1 8.5 1 C8.2239 1 8 1.2239 8 1.5 L8 1.5 L8 3 C8 3.5523 8.4477 4 9 4 L9 8.25 C9 8.3881 8.8881 8.5 8.75 8.5 C8.6119 8.5 8.5 8.3881 8.5 8.25 L8.5 6.5 C8.5 5.6716 7.8284 5 7 5 L7 2 C7 1.4477 6.5523 1 6 1 L2 1 C1.4477 1 1 1.4477 1 2 L1 9 C1 9.5522995 1.4477 10 2 10 L6 10 C6.5523 10 7 9.5523 7 9 L7 6 C7.2761 6 7.5 6.2239 7.5 6.5 L7.5 8.25 C7.5 8.9404 8.0596 9.5 8.75 9.5 C9.4404 9.5 10 8.9404 10 8.25 L10 3.5 C10 3.2239 9.7761 3 9.5 3 z M6 4.5 C6.0056 4.7706 5.7908 4.9944 5.5202 5 C5.5201 5 5.5201 5 5.52 5 L2.52 5 C2.2384 5.0056 2.0056 4.7818 2 4.5002 C2 4.5001 2 4.5001 2 4.5 L2 4.5 L2 3 C2 2.7239 2.2239 2.5 2.5 2.5 L5.5 2.5 C5.7761 2.5 6 2.7239 6 3 L6 4.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFuel11;
