import * as React from "react";
const SvgAttraction15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "attraction-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 2 C5.446 2 5.2478 2.5045 5 3 L4.5 4 L2.5 4 C1.669 4 1 4.669 1 5.5 L1 10.5 C1 11.331 1.669 12 2.5 12 L12.5 12 C13.331 12 14 11.331 14 10.5 L14 5.5 C14 4.669 13.331 4 12.5 4 L10.5 4 L10 3 C9.75 2.5 9.554 2 9 2 L6 2 z M2.5 5 C2.7761 5 3 5.2239 3 5.5 C3 5.7761 2.7761 6 2.5 6 C2.2239 6 2 5.7761 2 5.5 C2 5.2239 2.2239 5 2.5 5 z M7.5 5 C9.1569 5 10.5 6.3431 10.5 8 C10.5 9.6569 9.1569 11 7.5 11 C5.8430996 11 4.5 9.6569 4.5 8 C4.5 6.3430996 5.8431 5 7.5 5 z M7.5 6.5 C6.6716 6.5 6 7.1716 6 8 L6 8 C6 8.8284 6.6716 9.5 7.5 9.5 L7.5 9.5 C8.3284 9.5 9 8.8284 9 8 L9 8 C9 7.1716 8.3284 6.5 7.5 6.5 L7.5 6.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 2 C5.446 2 5.2478 2.5045 5 3 L4.5 4 L2.5 4 C1.669 4 1 4.669 1 5.5 L1 10.5 C1 11.331 1.669 12 2.5 12 L12.5 12 C13.331 12 14 11.331 14 10.5 L14 5.5 C14 4.669 13.331 4 12.5 4 L10.5 4 L10 3 C9.75 2.5 9.554 2 9 2 L6 2 z M2.5 5 C2.7761 5 3 5.2239 3 5.5 C3 5.7761 2.7761 6 2.5 6 C2.2239 6 2 5.7761 2 5.5 C2 5.2239 2.2239 5 2.5 5 z M7.5 5 C9.1569 5 10.5 6.3431 10.5 8 C10.5 9.6569 9.1569 11 7.5 11 C5.8430996 11 4.5 9.6569 4.5 8 C4.5 6.3430996 5.8431 5 7.5 5 z M7.5 6.5 C6.6716 6.5 6 7.1716 6 8 L6 8 C6 8.8284 6.6716 9.5 7.5 9.5 L7.5 9.5 C8.3284 9.5 9 8.8284 9 8 L9 8 C9 7.1716 8.3284 6.5 7.5 6.5 L7.5 6.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAttraction15;
