import { makeStyles } from "@mui/styles"

export const useDefaultInfoboxStyles = makeStyles(theme => ({
    borderBox: {
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        marginLeft: theme.spacing(3.5),
        marginTop: theme.spacing(1),
    },
    collapseBtnContainer: {
        color: theme.customColors.primaryColor,
    },
    collapseButton: {
        color: theme.customColors.primaryColor,
        paddingLeft: theme.spacing(2),
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    featureButtonsMultipleFeatures: {
        flex: "0 0 auto",
        paddingRight: theme.spacing(2.5),
    },
    featureButtonsSingleFeature: {
        flex: "0 0 auto",
        paddingRight: theme.spacing(3.8),
    },
    featureHeader: {
        boxSizing: "border-box",
        display: "flex",
        flexBasis: 0,
        flexGrow: 1,
        maxWidth: "100%",
        overflow: "hidden",
        padding: theme.spacing(1, 0, 1, 2),
    },
    featureHeaderContainer: {
        alignItems: "center",
        backgroundColor: "white",
        display: "flex",
        flexWrap: "wrap",
        position: "sticky",
        top: 0,
    },
    features: {
        "&::-webkit-scrollbar-track": {
            borderLeft: "1px solid" + theme.customColors.borderColor + " !important",
            marginTop: 40,
        },
        backgroundColor: "white",
        minHeight: 100,
        minWidth: 300,
        overflowX: "hidden",
        overflowY: "scroll",

        paddingBottom: theme.spacing(1),
    },
    innerCollapseBtn: {
        borderRadius: 0,
        color: theme.customColors.black,
        height: 24,
        marginRight: theme.spacing(1),
        width: 24,
    },
    noData: {
        "&:not(:first-of-type)": {
            display: "none",
        },
        fontSize: 14,
        fontWeight: "bold",
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        textAlign: "left",

        textTransform: "capitalize",
    },
    propertyKey: {
        borderBottom: "1px solid" + theme.customColors.borderColor,
        padding: theme.spacing(1),
        paddingLeft: 0,
        textAlign: "left",
        textTransform: "capitalize",
    },
    propertyValue: {
        borderBottom: "1px solid" + theme.customColors.borderColor,
        padding: theme.spacing(1, 0),
        textAlign: "right",
        wordBreak: "break-word",
    },
    root: {
        backgroundColor: "white",
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        [theme.breakpoints.down("md")]: {
            bottom: 8,
            left: 8,
            right: 8,
        },
        zIndex: 4,
    },
    squareBtn: {
        "&:hover": {
            backgroundColor: "rgba(211, 226, 234, 0.6)",
        },
        backgroundColor: theme.customColors.secondaryColor,
        borderRadius: 3,
        color: "black",
        height: 30,
        marginLeft: theme.spacing(1),
        paddingBottom: 0,
        paddingTop: 0,

        width: 27,
    },
    table: {
        "& tr:last-of-type td": {
            borderBottom: 0,
        },
        backgroundColor: "white",
        borderCollapse: "collapse",
        marginInline: theme.spacing(2),
        tableLayout: "fixed",

        width: `calc(100% - ${theme.spacing(4.5)} )`,
    },
}))

export default useDefaultInfoboxStyles
