import * as React from "react";
const SvgCampsite11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "campsite-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.92 2.19 C5.7745 1.9553 5.4663 1.8829 5.2316 2.0284 C5.166 2.0691 5.1107 2.1244 5.07 2.19 L1.5 8 L0.5 8 C0.2724 7.9555 0.0517 8.104 0.0072 8.3316 C0.0034 8.3509 0.001 8.3704 0 8.39 L0 9.5 C-0.0056 9.7706 0.2092 9.9944 0.4798 10 C0.4865 10.0001 0.4933 10.0001 0.5 10 L10.5 10 C10.7706 10.0056 10.9944 9.7908 11 9.5202 C11.0001 9.5135 11.0001 9.5067 11 9.5 L11 8.39 C10.9877 8.158401 10.79 7.9805 10.5584 7.9928 C10.5388 7.9938 10.5193 7.9962 10.5 8 L9.5 8 L5.92 2.19 z M5.5 3 L8.5 8 L2.5 8 L5.5 3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.92 2.19 C5.7745 1.9553 5.4663 1.8829 5.2316 2.0284 C5.166 2.0691 5.1107 2.1244 5.07 2.19 L1.5 8 L0.5 8 C0.2724 7.9555 0.0517 8.104 0.0072 8.3316 C0.0034 8.3509 0.001 8.3704 0 8.39 L0 9.5 C-0.0056 9.7706 0.2092 9.9944 0.4798 10 C0.4865 10.0001 0.4933 10.0001 0.5 10 L10.5 10 C10.7706 10.0056 10.9944 9.7908 11 9.5202 C11.0001 9.5135 11.0001 9.5067 11 9.5 L11 8.39 C10.9877 8.158401 10.79 7.9805 10.5584 7.9928 C10.5388 7.9938 10.5193 7.9962 10.5 8 L9.5 8 L5.92 2.19 z M5.5 3 L8.5 8 L2.5 8 L5.5 3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCampsite11;
