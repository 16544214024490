import React, { useEffect } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import FilledInput from "@mui/material/FilledInput"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import TitleTextField from "../common/TitleTextfield/TitleTextField"

const SelectProperty = ({
    editable,
    options,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) => {
    useEffect(() => {
        if (!value) {
            onPropertyChanged(options[0])
        }
    }, [])

    const handleChange = e => {
        onPropertyChanged(e.target.value)
    }
    const removable = onRemove && !isDefaultValue && !isLastRemainingCase

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {!editable && (
                    <Typography className="name" variant="body2">
                        {title}
                    </Typography>
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <div className="text-container">
                    <FormControl className="property-select" fullWidth variant="filled">
                        <InputLabel htmlFor="filled-age-simple">{title} type</InputLabel>
                        <Select
                            input={<FilledInput id="filled-age-simple" name="age" />}
                            value={value}
                            onChange={handleChange}
                        >
                            {options.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default SelectProperty
