import { getApp } from "actions/apps"
import { resetProjectData } from "actions/globalActions"
import * as types from "../actions/actionTypes"
import isMobile from "../utils/isMobile"

const sidebarInit = {
    isDrawerOpen: false,
    isSidebarOpen: isMobile.phone ? false : true,
}

export function sidebarAndDrawerReducer(state = sidebarInit, action) {
    switch (action.type) {
        case types.TOGGLE_SIDEBAR_OPEN:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            }
        case types.SET_SIDEBAR_OPEN:
            return {
                ...state,
                isSidebarOpen: action.payload,
            }
        case types.TOGGLE_DRAWER_OPEN:
            return {
                ...state,
                isDrawerOpen: !state.isDrawerOpen,
            }
        case getApp.fulfilled.type:
            return {
                ...state,
                isSidebarOpen: !action.payload.sidebarCollapsed,
            }
        case resetProjectData.type:
            return sidebarInit
        default:
            return state
    }
}
