import Checkbox from "@mui/material/Checkbox"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import useAttributeTableTableHeadStyles from "./styles"

type Props = {
    areAllRowsSelected: boolean
    featureNames: { name: string; isNumeric: boolean }[]
    orderBy: string
    orderDesc: boolean
    isAtLeastOneRowSelected: boolean
    isDownloadable: boolean
    onSortBy: (column: string) => void
    onToggleSelectAll: () => void
}

const AttributeTableTableHead = ({
    areAllRowsSelected,
    featureNames,
    orderBy,
    orderDesc,
    isAtLeastOneRowSelected,
    isDownloadable,
    onSortBy,
    onToggleSelectAll,
}: Props) => {
    const classes = useAttributeTableTableHeadStyles()
    const orderName = orderDesc ? "desc" : "asc"

    const getDataTestId = (column: string) => {
        const columnName = column.toLocaleLowerCase().replaceAll(" ", "-")

        return columnTestId + columnName
    }

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={areAllRowsSelected}
                        indeterminate={isAtLeastOneRowSelected && !areAllRowsSelected}
                        inputProps={
                            { "aria-label": "select all", "data-testid": checkboxAllTestId } as DataTestIdCheckboxProps
                        }
                        disabled={!isDownloadable}
                        onClick={onToggleSelectAll}
                    />
                </TableCell>
                {featureNames.map(feature => (
                    <TableCell
                        key={feature.name}
                        align={"center"}
                        className={classes.tableCell}
                        padding="normal"
                        sortDirection={orderBy === feature.name ? orderName : false}
                    >
                        <TableSortLabel
                            active={orderBy === feature.name}
                            className={classes.tableCellLabel}
                            data-testid={getDataTestId(feature.name)}
                            direction={orderBy === feature.name ? orderName : "asc"}
                            onClick={() => onSortBy(feature.name)}
                        >
                            {feature.name}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default AttributeTableTableHead

const columnTestId = "qa-attribute-table-head-column-"
const checkboxAllTestId = "qa-attribute-table-checkbox-all"

type DataTestIdCheckboxProps = {
    "data-testid"?: string
} & React.InputHTMLAttributes<HTMLInputElement>
