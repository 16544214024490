import * as types from "./actionTypes"
import axiosClient from "./apiClient.ts"

export function getApps() {
    return dispatch => {
        dispatch({ type: types.FETCH_APPS_START })
        return axiosClient
            .get("application/published")
            .then(function (res) {
                return dispatch({
                    result: res.data.map(app => ({
                        id: app.id,
                        modifiedUtc: app.modifiedUtc,
                        name: app.name,
                        public: app.public,
                        isPublished: app.isPublished,
                    })),
                    type: types.FETCH_APPS_COMPLETE,
                })
            })
            .catch(err => {
                dispatch({ result: err.message, type: types.FETCH_APPS_FAILED })
                return null
            })
    }
}

export function getDistinctColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/distinct?column=` + column).then(function (res) {
            return { result: res.data }
        })
    }
}

export function getDatasetColumns(datasetId) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/columns`).then(function (res) {
            return { id: datasetId, result: res.data }
        })
    }
}

export function getMinMaxColumnValues(datasetId, column) {
    return () => {
        return axiosClient.get(`dataset/${datasetId}/minmax?column=` + column).then(function (res) {
            return { result: res.data }
        })
    }
}
