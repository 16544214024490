import * as React from "react";
const SvgMarker15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "marker-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C5.0676 0 2.2297 1.4865 2.2297 5.2703 C2.2297 7.8378 6.2838 13.5135 7.5 15 C8.5811 13.5135 12.7703 7.973 12.7703 5.2703 C12.7703 1.4865 9.9324 0 7.5 0 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C5.0676 0 2.2297 1.4865 2.2297 5.2703 C2.2297 7.8378 6.2838 13.5135 7.5 15 C8.5811 13.5135 12.7703 7.973 12.7703 5.2703 C12.7703 1.4865 9.9324 0 7.5 0 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMarker15;
