import * as React from "react";
const SvgIceCream15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "ice-cream-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.44 8.17 C6.1556 8.1706 6.854 7.9506 7.44 7.54 C7.9037 7.8634 8.4391 8.0693 9 8.14 L9.44 8.14 L8 13.7 C7.8918 13.9541 7.5981 14.0723 7.344 13.964 C7.2252 13.9134 7.1306 13.8188 7.08 13.7 L5.44 8.17 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.44 4.67 C11.44 5.7746 10.5446 6.67 9.44 6.67 C8.3354 6.67 7.4399996 5.7746 7.4399996 4.67 L7.4399996 4.67 L7.4399996 4.67 L7.4399996 4.67 C7.4399996 5.7746 6.5445995 6.67 5.4399996 6.67 C4.3353996 6.67 3.4399996 5.7746 3.4399996 4.67 C3.4399996 3.5654001 4.3353996 2.67 5.4399996 2.67 L5.5599995 2.67 C5.1756 1.6345 5.7035 0.4834 6.739 0.099 C7.7745 -0.28539997 8.9256 0.2425 9.309999 1.278 C9.4767 1.727 9.4767 2.2209 9.309999 2.6699 L9.44 2.6699 C10.5446 2.67 11.44 3.5654 11.44 4.67 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.44 8.17 C6.1556 8.1706 6.854 7.9506 7.44 7.54 C7.9037 7.8634 8.4391 8.0693 9 8.14 L9.44 8.14 L8 13.7 C7.8918 13.9541 7.5981 14.0723 7.344 13.964 C7.2252 13.9134 7.1306 13.8188 7.08 13.7 L5.44 8.17 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.44 4.67 C11.44 5.7746 10.5446 6.67 9.44 6.67 C8.3354 6.67 7.4399996 5.7746 7.4399996 4.67 L7.4399996 4.67 L7.4399996 4.67 L7.4399996 4.67 C7.4399996 5.7746 6.5445995 6.67 5.4399996 6.67 C4.3353996 6.67 3.4399996 5.7746 3.4399996 4.67 C3.4399996 3.5654001 4.3353996 2.67 5.4399996 2.67 L5.5599995 2.67 C5.1756 1.6345 5.7035 0.4834 6.739 0.099 C7.7745 -0.28539997 8.9256 0.2425 9.309999 1.278 C9.4767 1.727 9.4767 2.2209 9.309999 2.6699 L9.44 2.6699 C10.5446 2.67 11.44 3.5654 11.44 4.67 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgIceCream15;
