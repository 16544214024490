import { useEffect, useState, useMemo } from "react"
import { ExpandLess } from "@mui/icons-material"
import { Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getRasterPixelValue, getPublicRasterPixelValue } from "actions/raster"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import RasterFeature from "components/map/infoBoxes/RasterFeature/RasterFeature"
import VectorFeature from "components/map/infoBoxes/VectorFeature/VectorFeature"
import { getRenamedPropertyKey, POSITION_RECEIVED_PROPERTY } from "components/sidebar/ais/utils"
import { getSelectedAppIsPublic } from "selectors/appsSelectors"
import useComponentCancelToken from "utils/customHooks/useComponentCancelToken"
import toastr from "utils/customToastr"
import * as Utils from "utils/infoBoxUtils"
import useDefaultInfoboxStyles from "./styles.ts"

const DefaultInfobox = props => {
    const classes = useDefaultInfoboxStyles()
    const [loading, setLoading] = useState(true)
    const [rasters, setRasters] = useState([])
    const isPublic = useSelector(getSelectedAppIsPublic)
    const dispatch = useDispatch()
    const cancelToken = useComponentCancelToken()
    const [vectorFeatureCollapsedState, setVectorFeatureCollapsedState] = useState(
        props.features.map((_, index) => (index === 0 ? false : true)),
    )
    const [rasterFeatureCollapsedState, setRasterFeatureCollapsedState] = useState([])
    const areAllFeaturesCollapsed = useMemo(
        () =>
            vectorFeatureCollapsedState.every(collapsed => collapsed) &&
            rasterFeatureCollapsedState.every(collapsed => collapsed),
        [vectorFeatureCollapsedState, rasterFeatureCollapsedState],
    )
    const hasMultipleFeatures = useMemo(
        () => props.features.length + rasters.length > 1,
        [props.features.length, rasters.length],
    )

    useEffect(() => {
        const promises = []
        const lng = props.lngLat.lng
        const lat = props.lngLat.lat

        props.rasters.forEach(raster => {
            if (isPublic)
                promises.push(dispatch(getPublicRasterPixelValue(raster.sourceId, lng, lat, cancelToken.token)))
            else promises.push(dispatch(getRasterPixelValue(raster.sourceId, lng, lat, cancelToken.token)))
        })

        Promise.allSettled(promises).then(responses => {
            const newRasters = responses.map((response, index) => {
                return { ...props.rasters[index], lat, lng, value: response.value?.data || null }
            })

            setRasters(newRasters)
            setLoading(false)

            setRasterFeatureCollapsedState(
                vectorFeatureCollapsedState.length === 0
                    ? newRasters.map((_, index) => (index === 0 ? false : true))
                    : new Array(newRasters.length).fill(true),
            )
        })
        return () => {
            cancelToken.cancel("Canceled")
        }
    }, [])

    const onCopyClick = index => {
        let properties = Object.keys(props.features[index].properties).reduce((a, b) => {
            a.push({
                key: b,
                value: props.features[index].properties[b],
            })
            return a
        }, [])
        let theClipboard = navigator.clipboard
        if (theClipboard) {
            let copyText = props.sourceToNameMapping[props.features[index].sourceLayer] + " -"
            properties.map((propKeyValue, propIndex) => {
                let value
                if (
                    Utils.excludedProperties.indexOf(propKeyValue.key) >= 0 ||
                    propKeyValue.key.includes("link") ||
                    propKeyValue.key.includes("foto")
                ) {
                    return false
                }

                // Creating links
                if (propKeyValue.key.includes("dato")) {
                    let date = new Date(propKeyValue.value)
                    let dd = date.getDate()
                    let mm = date.getMonth() + 1 // january is 0
                    let yyyy = date.getFullYear()
                    value = `${dd}/${mm}-${yyyy}`
                } else {
                    value = propKeyValue.value
                }

                if (propKeyValue.key.includes(POSITION_RECEIVED_PROPERTY)) {
                    propKeyValue.key = getRenamedPropertyKey(POSITION_RECEIVED_PROPERTY)
                }

                if (propIndex >= 1) copyText += "\n"
                copyText += propKeyValue.key.charAt(0).toUpperCase() + propKeyValue.key.slice(1) + ": " + value
                return false
            })
            navigator.clipboard.writeText(copyText)
        }
        toastr.success("The text has been copied")
    }

    const toggleAllFeatures = () => {
        setVectorFeatureCollapsedState(vectorFeatureCollapsedState.map(_ => !areAllFeaturesCollapsed))
        setRasterFeatureCollapsedState(rasterFeatureCollapsedState.map(_ => !areAllFeaturesCollapsed))
    }

    const toggleVectorFeature = index => {
        const updatedCollapsed = [...vectorFeatureCollapsedState]
        updatedCollapsed[index] = !vectorFeatureCollapsedState[index]
        setVectorFeatureCollapsedState(updatedCollapsed)
    }

    const toggleRasterFeature = index => {
        const updatedCollapsed = [...rasterFeatureCollapsedState]
        updatedCollapsed[index] = !rasterFeatureCollapsedState[index]
        setRasterFeatureCollapsedState(updatedCollapsed)
    }

    const getIsVectorFeatureCollapsed = index => vectorFeatureCollapsedState[index]

    const getIsRasterFeatureCollapsed = index => rasterFeatureCollapsedState[index]

    return (
        <div className={classes.root}>
            {hasMultipleFeatures && (
                <div className={classes.collapseBtnContainer}>
                    <Button className={classes.collapseButton} size="large" onClick={() => toggleAllFeatures()}>
                        <ExpandLess className={areAllFeaturesCollapsed ? classes.expandedLeft : classes.collapsed} />

                        <span>{areAllFeaturesCollapsed ? "Expand All" : "Collapse All"}</span>
                    </Button>
                </div>
            )}
            <div className={classes.features}>
                <LoadingPlaceholder loading={loading} message="Loading information">
                    {props.features.map((feature, featureIndex) => (
                        <VectorFeature
                            key={featureIndex}
                            collapsed={getIsVectorFeatureCollapsed(featureIndex)}
                            feature={feature}
                            featureIndex={featureIndex}
                            sourceToNameMapping={props.sourceToNameMapping}
                            hasMultipleFeatures={hasMultipleFeatures}
                            toggleFeature={toggleVectorFeature}
                            onCopyClick={onCopyClick}
                        />
                    ))}
                    {rasters.map((raster, index) => (
                        <RasterFeature
                            key={index}
                            collapsed={getIsRasterFeatureCollapsed(index)}
                            featureIndex={index}
                            lat={raster.lat}
                            long={raster.lng}
                            name={raster.name}
                            value={raster.value}
                            hasMultipleFeatures={hasMultipleFeatures}
                            toggleFeature={toggleRasterFeature}
                        />
                    ))}
                </LoadingPlaceholder>
            </div>
        </div>
    )
}

export default DefaultInfobox
