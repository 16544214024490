import { Component, createRef } from "react"
import { Cancel, Close } from "@mui/icons-material"
import FilterNone from "@mui/icons-material/FilterNone"
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Typography, Tooltip, Button, Menu, Divider, IconButton, Popper, Fade, Paper } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import withStyles from "@mui/styles/withStyles"
import turfArea from "@turf/area"
import { lineString } from "@turf/helpers"
import turfLength from "@turf/length"
import turfMidpoint from "@turf/midpoint"
import { MapIds } from "model/enums/MapIds"
import { connect } from "react-redux"
import { toggleWidget } from "features/mapTools/slice"
import toastr from "utils/customToastr"
import * as FeatureFactory from "utils/geojsonFeatureFactory"
import { withMainDraw } from "utils/HOCs/withMainDraw"
import { withMapHandlers } from "utils/HOCs/withMapHandlers"
import { measureJss } from "./measureJss"

// GeoJSON object to hold the distance labels
let distanceLabels = {
    features: [],
    type: "FeatureCollection",
}

// GeoJSON object to hold our measurement features
const pointLayer = {
    features: [],
    type: "FeatureCollection",
}

// Used to draw a line between points
let lineStringfeature = {
    geometry: {
        coordinates: [],
        type: "LineString",
    },
    type: "Feature",
}

let helpLineFeature = {
    geometry: {
        coordinates: [],
        type: "LineString",
    },
    type: "Feature",
}

let lineLayer = {
    features: [lineStringfeature],
    type: "FeatureCollection",
}

let helpLineLayer = {
    features: [helpLineFeature],
    type: "FeatureCollection",
}

let units = [
    { multiply: 1, name: "km", namePretty: "Kilometers", unitName: "kilometers" },
    { multiply: 1000, name: "m", namePretty: "Meters", unitName: "meters" },
    { multiply: 0.621371, name: "mi", namePretty: "Miles", unitName: "miles" },
    { multiply: 0.539957, name: "nmi", namePretty: "Nautical Miles", unitName: "nauticalmiles" },
    { multiply: 3280.8398950131, name: "ft", namePretty: "Feet", unitName: "feet" },
]

let areaUnits = [
    { multiply: 1, name: "m²", namePretty: "Square Meters" },
    { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" },
    { multiply: 3.86102e-7, name: "mi²", namePretty: "Square Miles" },
    { multiply: 2.91545189e-7, name: "nmi²", namePretty: "Square Nautical Miles" },
    { multiply: 10.76391041671, name: "ft²", namePretty: "Square Feet" },
]

class Measure extends Component {
    constructor(props) {
        super(props)
        this.containerRef = createRef()

        this.state = {
            area: 0,
            areaUnitMenuAnchorEl: null,
            areaUnits: areaUnits,
            distance: 0,
            drawing: true,
            measurementComplete: false,
            perimeter: 0,
            selected: null,
            selectedAreaUnit: { multiply: 0.000001, name: "km²", namePretty: "Square Kilometers" },
            selectedUnit: { multiply: 1, name: "km", namePretty: "Kilometers" },
            unitMenuAnchorEl: null,
            units: units,
            showTooltip: false,
        }
    }

    componentDidMount() {
        this.draw = this.props.draw
        this.props.map.hasControl()
        this.init()
    }

    componentDidUpdate(prevProps) {
        this.props.map.doubleClickZoom?.disable()
    }

    componentWillUnmount() {
        this.unmount()
    }

    init() {
        this.map = this.props.map.getMap()
        this.map.doubleClickZoom?.disable()

        document.getElementById(MapIds.MainMap).addEventListener("keydown", this.onPressingEnter)
        document.getElementById(MapIds.MainMap).addEventListener("dblclick", this.onDoubleClick)

        this.removeLayersAndSources()
        this.draw.changeMode("static")

        this.props.pushHandler("onClick", this.onDrawClick)
        this.addLayersAndSources()

        this.map.on("mousedown", "measure-points", this.onMapMouseDown)
        this.map.on("touchstart", "measure-points", this.onMapMouseDown)

        this.map.on("mousemove", this.onMapMouseMove)
        this.map.on("touchmove", this.onMapMouseMove)

        this.map.on("mousemove", "measure-points", this.onMapPointsMouseMove)
        this.map.on("touchmove", "measure-points", this.onMapMouseMove)

        this.map.on("mouseleave", "measure-points", this.onMapPointsMouseLeave)
        this.map.on("touchend", "measure-points", this.onMapMouseUp)
        this.map.on("touchcancel", "measure-points", this.onMapMouseUp)
        this.map.on("mouseup", "measure-points", this.onMapMouseUp)

        this.index = 0
        this.map.getCanvas().style.cursor = "crosshair"
    }

    removeLayersAndSources() {
        if (this.map.getLayer("measure-points")) this.map.removeLayer("measure-points")
        if (this.map.getLayer("measure-lines")) this.map.removeLayer("measure-lines")
        if (this.map.getLayer("measure-drawing-help-line")) this.map.removeLayer("measure-drawing-help-line")
        if (this.map.getLayer("measure-drawn-help-line-measurement"))
            this.map.removeLayer("measure-drawn-help-line-measurement")
        if (this.map.getSource("measure-points")) this.map.removeSource("measure-points")
        if (this.map.getSource("measure-lines")) this.map.removeSource("measure-lines")
        if (this.map.getSource("measure-drawing-help-line")) this.map.removeSource("measure-drawing-help-line")
        if (this.map.getSource("measure-drawn-help-line-measurement"))
            this.map.removeSource("measure-drawn-help-line-measurement")
    }

    addLayersAndSources() {
        this.map.addSource("measure-points", {
            data: pointLayer,
            type: "geojson",
        })

        this.map.addSource("measure-lines", {
            data: lineLayer,
            type: "geojson",
        })

        this.map.addSource("measure-drawing-help-line", {
            data: helpLineLayer,
            type: "geojson",
        })

        this.map.addSource("measure-drawn-help-line-measurement", {
            data: distanceLabels,
            type: "geojson",
        })

        this.map.addLayer({
            id: "measure-drawing-help-line",
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-color": "#024F79",
                "line-dasharray": [0.2, 2],
                "line-width": 2.5,
            },
            source: "measure-drawing-help-line",
            type: "line",
        })

        this.map.addLayer({
            id: "measure-lines",
            layout: {
                "line-cap": "round",
                "line-join": "round",
            },
            paint: {
                "line-color": "#024F79",
                "line-width": 2.5,
            },
            source: "measure-lines",
            type: "line",
        })
        this.map.addLayer({
            id: "measure-points",
            paint: {
                "circle-color": ["case", ["boolean", ["feature-state", "hover"], false], "white", "#024F79"],
                "circle-radius": 6,
                "circle-stroke-color": ["case", ["boolean", ["feature-state", "hover"], false], "#024F79", "white"],
                "circle-stroke-width": 2,
            },
            source: "measure-points",
            type: "circle",
        })
        this.map.addLayer({
            id: "measure-drawn-help-line-measurement",
            layout: {
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "symbol-placement": "point",
                "text-allow-overlap": true,
                "text-anchor": "center",
                "text-field": "{distance}\n{unit}",
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-justify": "center",
                "text-size": 15,
            },
            paint: {
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 2,
            },
            source: "measure-drawn-help-line-measurement",
            type: "symbol",
        })
    }

    unmount() {
        // when clicking the back button, the map
        // gets deleted before this cleanup function is called,
        // therefore it is important to check whether
        // this map was deleted
        if (this.map["_removed"]) return

        this.map.removeLayer("measure-points")
        this.map.removeLayer("measure-lines")
        this.map.removeSource("measure-points")
        this.map.removeSource("measure-lines")
        this.map.removeLayer("measure-drawing-help-line")
        this.map.removeSource("measure-drawing-help-line")
        this.map.removeLayer("measure-drawn-help-line-measurement")
        this.map.removeSource("measure-drawn-help-line-measurement")

        lineStringfeature = {
            geometry: {
                coordinates: [],
                type: "LineString",
            },
            type: "Feature",
        }
        lineLayer.features = [lineStringfeature]
        helpLineFeature = {
            geometry: {
                coordinates: [],
                type: "LineString",
            },
            type: "Feature",
        }
        helpLineLayer.features = [helpLineFeature]
        pointLayer.features = []
        distanceLabels.features = []

        this.map.off("mousedown", "measure-points", this.onMapMouseDown)
        this.map.off("mousemove", this.onMapMouseMove)
        this.map.off("mousemove", "measure-points", this.onMapPointsMouseMove)
        this.map.off("mouseleave", "measure-points", this.onMapPointsMouseLeave)
        this.map.off("mouseup", "measure-points", this.onMapMouseUp)
        this.map.off("touchstart", "measure-points", this.onMapMouseDown)
        this.map.off("touchmove", this.onMapMouseMove)
        this.map.off("touchmove", "measure-points", this.onMapMouseMove)
        this.map.off("touchend", "measure-points", this.onMapMouseUp)
        this.map.off("touchcancel", "measure-points", this.onMapMouseUp)

        this.map.getCanvas().style.cursor = ""

        document.getElementById(MapIds.MainMap).removeEventListener("keydown", this.onPressingEnter)
        document.getElementById(MapIds.MainMap).removeEventListener("dblclick", this.onDoubleClick)

        this.map.doubleClickZoom?.enable()
        this.props.popHandler("onClick")
    }

    onPressingEnter = e => {
        if (e.key === "Enter" && this.state.drawing) {
            this.onFinishMeasureButtonClicked()
        }
    }

    onDoubleClick = e => {
        if (lineLayer.features[0].geometry.coordinates.length && this.state.drawing) {
            this.onFinishMeasureButtonClicked()
        }
    }

    renderDistanceMarkers(lines, helpLine = null, unitNew = null) {
        let drawnLinesFromParameter = [...lines.features[0].geometry.coordinates]

        if (helpLine) {
            drawnLinesFromParameter[drawnLinesFromParameter.length] = helpLine.geometry.coordinates[1]
        }
        // check if the last two elements are the same, pop the last one out
        if (
            drawnLinesFromParameter[drawnLinesFromParameter.length - 2].toString() ===
            drawnLinesFromParameter[drawnLinesFromParameter.length - 1].toString()
        ) {
            drawnLinesFromParameter.pop()
        }
        let polygonLines = []

        polygonLines = drawnLinesFromParameter
        let unit = this.state.selectedUnit.unitName
        let unitShort = this.state.selectedUnit.name
        if (unitNew) {
            unit = unitNew.unitName
            unitShort = unitNew.name
        }

        let points = []
        if (polygonLines.length > 0) {
            for (let i = 0; i <= polygonLines.length - 2; i++) {
                let polygonLine = lineString([polygonLines[i], polygonLines[i + 1]], { name: `segment-${i + 1}` })
                if (polygonLine) {
                    let midPoint = turfMidpoint(polygonLines[i], polygonLines[i + 1])
                    let routeDistance = turfLength(polygonLine, { units: unit })
                    routeDistance = Number.parseFloat(routeDistance).toFixed(2)
                    points.push({
                        geometry: {
                            coordinates: midPoint.geometry.coordinates,
                            type: "Point",
                        },
                        properties: { distance: routeDistance, unit: unitShort },
                        type: "Feature",
                    })
                    if (this.state.drawing && i === polygonLines.length - 2) {
                        let entireLine = lineString(polygonLines, { name: `entire-line-segment` })
                        let routeDistance = turfLength(entireLine, { units: unit })
                        routeDistance = Number.parseFloat(routeDistance).toFixed(2)
                        points.push({
                            geometry: {
                                coordinates:
                                    entireLine.geometry.coordinates[entireLine.geometry.coordinates.length - 1],
                                type: "Point",
                            },
                            properties: { distance: routeDistance, unit: unitShort },
                            type: "Feature",
                        })
                    }
                }
            }
            distanceLabels.features = points
            //render labels
            this.map.getSource("measure-drawn-help-line-measurement").setData(distanceLabels)
        }
    }

    calculatePerimeter(polygon) {
        let distance = 0
        for (let i = 0; i < polygon.geometry.coordinates[0].length - 1; i++) {
            let point = polygon.geometry.coordinates[0][i]
            let point2 = polygon.geometry.coordinates[0][i + 1]

            let line = FeatureFactory.toLine([point, point2], {})

            distance += turfLength(line)
        }
        return distance
    }

    onMapMouseDown = e => {
        var msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"],
        })
        //If no features where hit
        if (msFeatures.length === 0) {
            return
        }

        //Prevent default map mousedown event
        e.preventDefault()

        this.map.setFeatureState({ id: msFeatures[0].id, source: "measure-points" }, { hover: true })

        //Get a referece to the selected feature in pointlayer
        let feature = pointLayer.features.find(x => x.properties.id === msFeatures[0].properties.id)

        this.setState({
            selected: feature,
        })
    }

    onMapMouseUp = e => {
        //Dont do anything if nothing is selected
        if (!this.state.selected) {
            return
        }

        this.map.setFeatureState({ id: this.state.selected.id, source: "measure-points" }, { hover: false })

        if (this.state.isPolygon) {
            this.setState({
                area: turfArea(lineStringfeature),
                perimeter: this.calculatePerimeter(lineStringfeature),
                selected: null,
            })
        } else {
            this.setState({
                distance: turfLength(lineStringfeature),
                selected: null,
            })
        }
    }

    onMapMouseMove = e => {
        if (helpLineFeature.geometry.coordinates.length > 0) {
            helpLineFeature.geometry.coordinates[1] = [e.lngLat.lng, e.lngLat.lat]
            if (helpLineFeature.geometry.coordinates.length === 2) {
                this.renderDistanceMarkers(lineLayer, helpLineFeature)
                this.map.getSource("measure-drawing-help-line").setData(helpLineLayer)
                this.map.setFeatureState({ id: 2, source: "measure-drawing-help-line" }, { hover: false })
            }
        }

        if (!this.state.selected) {
            return
        }

        //Move point
        let selectedNew = this.state.selected
        selectedNew.geometry.coordinates = [e.lngLat.lng, e.lngLat.lat]
        this.setState({ selected: selectedNew })

        //Handle moving linestring
        if (lineStringfeature.geometry.type === "LineString") {
            lineStringfeature.geometry.coordinates[this.state.selected.properties.index] = [e.lngLat.lng, e.lngLat.lat]
        } else {
            //If the first point in the polygon is selected, move both the first and last point
            if (this.state.selected.properties.index === 0) {
                lineStringfeature.geometry.coordinates[0][0] = [e.lngLat.lng, e.lngLat.lat]
                lineStringfeature.geometry.coordinates[0][lineStringfeature.geometry.coordinates[0].length - 1] = [
                    e.lngLat.lng,
                    e.lngLat.lat,
                ]
            } else {
                lineStringfeature.geometry.coordinates[0][this.state.selected.properties.index] = [
                    e.lngLat.lng,
                    e.lngLat.lat,
                ]
            }
        }
        this.map.getSource("measure-points").setData(pointLayer)
        this.map.getSource("measure-lines").setData(lineLayer)
        if (this.state.selected) {
            if (lineStringfeature.geometry.type === "Polygon") {
                let polygonLayer = {
                    features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }],
                    type: "FeatureCollection",
                }
                this.renderDistanceMarkers(polygonLayer)
            } else {
                this.renderDistanceMarkers(lineLayer)
            }
        }
    }

    onMapPointsMouseMove = e => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : "pointer"
    }

    onMapPointsMouseLeave = e => {
        this.map.getCanvas().style.cursor = this.state.drawing ? "crosshair" : ""
    }

    drawingFinished(endPoint) {
        this.handleCloseTooltip()
        helpLineFeature = {
            geometry: {
                coordinates: [],
                type: "LineString",
            },
            type: "Feature",
        }
        helpLineLayer.features = [helpLineFeature]
        this.map.getSource("measure-drawing-help-line").setData(helpLineLayer)

        //If the first point is clicked, convert to a polygon. Else continue as line
        if (endPoint.properties.index === 0) {
            let polygon = FeatureFactory.lineToPolygon(lineStringfeature.geometry.coordinates, {})
            lineStringfeature = polygon
            lineLayer = {
                features: [lineStringfeature],
                type: "FeatureCollection",
            }
            this.map.getSource("measure-lines").setData(lineLayer)

            this.setState({
                area: turfArea(lineStringfeature),
                drawing: false,
                perimeter: this.calculatePerimeter(lineStringfeature),
                isPolygon: true,
            })
            let polygonLayer = {
                features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }],
                type: "FeatureCollection",
            }
            this.renderDistanceMarkers(polygonLayer)
        } else {
            this.setState({
                distance: turfLength(lineStringfeature),
                drawing: false,
            })
            this.renderDistanceMarkers(lineLayer)
        }
        this.setState({ measurementComplete: true })
        this.map.getCanvas().style.cursor = ""
    }

    onDrawClick = e => {
        if (this.state.measurementComplete) return

        helpLineFeature.geometry.coordinates = []
        const msFeatures = this.map.queryRenderedFeatures(e.point, {
            layers: ["measure-points"],
        })
        e.preventDefault()

        //If a previous point is clicked, and more than one point has been drawn
        if (msFeatures.length > 0 && pointLayer.features.length > 2) {
            helpLineFeature = {
                geometry: {
                    coordinates: [],
                    type: "LineString",
                },
                type: "Feature",
            }
            helpLineLayer.features = [helpLineFeature]
            this.map.getSource("measure-drawing-help-line").setData(helpLineLayer)
            this.renderDistanceMarkers(lineLayer)

            this.drawingFinished(msFeatures[0])
            return
        }

        let newId = this.index++

        let newPoint = FeatureFactory.toPoint(e.lngLat, { id: newId, index: newId }, newId)

        pointLayer.features.push(newPoint)
        //add the new point as the new end for the line
        lineStringfeature.geometry.coordinates.push(newPoint.geometry.coordinates)
        helpLineFeature.geometry.coordinates.push(newPoint.geometry.coordinates)
        this.map.getSource("measure-points").setData(pointLayer)
        this.map.getSource("measure-lines").setData(lineLayer)
        this.map.getSource("measure-drawing-help-line").setData(helpLineLayer)

        this.map.setFeatureState({ id: newId, source: "measure-points" }, { hover: false })
        this.map.setFeatureState({ id: newId, source: "measure-drawing-help-line" }, { hover: false })

        this.setState({
            distance: turfLength(lineStringfeature),
        })
        if (this.map.getSource("measure-points")._data.features.length === 3) {
            this.setState({ showTooltip: true })
        } else if (this.map.getSource("measure-points")._data.features.length === 4) {
            this.handleCloseTooltip()
            this.setState({ showTooltip: false })
        }
    }

    onCopyValue = text => {
        navigator.clipboard.writeText(text)
        toastr.success("Distance copied to clipboard.")
    }

    onOpenUnitMenu = e => {
        this.setState({
            unitMenuAnchorEl: e.currentTarget,
        })
    }

    onCloseUnitMenu = e => {
        this.setState({
            unitMenuAnchorEl: null,
        })
    }

    onOpenAreaUnitMenu = e => {
        this.setState({
            areaUnitMenuAnchorEl: e.currentTarget,
        })
    }

    onCloseAreaUnitMenu = e => {
        this.setState({
            areaUnitMenuAnchorEl: null,
        })
    }

    getLengthUnitFormatted(distance) {
        let roundedDistance = (distance * this.state.selectedUnit.multiply).toFixed(2)
        return { unit: this.state.selectedUnit.name, value: roundedDistance }
    }

    getAreaUnitFormatted(area) {
        let roundedArea = (area * this.state.selectedAreaUnit.multiply).toFixed(2)
        return { unit: this.state.selectedAreaUnit.name, value: roundedArea }
    }

    onUnitItemClick = unit => {
        this.setState({
            selectedUnit: unit,
            unitMenuAnchorEl: null,
        })

        if (lineLayer.features[0].geometry.coordinates.length === 0) return // nothing measured on the map

        if (lineLayer.features[0].geometry.type === "LineString") {
            this.renderDistanceMarkers(lineLayer, null, unit)
        } else {
            let polygonLayer = {
                features: [{ geometry: { coordinates: lineStringfeature.geometry.coordinates[0] } }],
                type: "FeatureCollection",
            }
            this.renderDistanceMarkers(polygonLayer, null, unit)
        }
    }

    onAreaUnitItemClick = unit => {
        this.setState({
            areaUnitMenuAnchorEl: null,
            selectedAreaUnit: unit,
        })
    }

    onStartNewMeasure = () => {
        lineStringfeature = {
            geometry: {
                coordinates: [],
                type: "LineString",
            },
            type: "Feature",
        }
        helpLineFeature = {
            geometry: {
                coordinates: [],
                type: "LineString",
            },
            type: "Feature",
        }
        lineLayer.features = [lineStringfeature]
        helpLineLayer.features = [helpLineFeature]
        pointLayer.features = []
        distanceLabels.features = []

        this.setState({
            area: 0,
            distance: 0,
            drawing: true,
            measurementComplete: false,
            perimeter: 0,
            selected: null,
            isPolygon: false,
        })

        this.index = 0
        this.map.getCanvas().style.cursor = "crosshair"

        this.map.getSource("measure-points").setData(pointLayer)
        this.map.getSource("measure-lines").setData(lineLayer)
        this.map.getSource("measure-lines").setData(helpLineLayer)
        this.map.getSource("measure-drawn-help-line-measurement").setData(distanceLabels)
    }

    onFinishMeasureButtonClicked = () => {
        //Finish drawing, and select the last drawn point as the final
        this.drawingFinished(pointLayer.features[pointLayer.features.length - 1])
    }

    handleClose = e => {
        e.stopPropagation()
        this.props.toggleWidget("measure")
    }

    handleCloseTooltip = () => {
        this.setState({ showTooltip: false })
    }

    render() {
        let { classes } = this.props

        let menuItems = this.state.units.map((unit, index) => {
            return (
                <MenuItem
                    key={index}
                    className={classes.squareBtn}
                    value={unit.name}
                    onClick={() => this.onUnitItemClick(unit)}
                >
                    {unit.namePretty}
                </MenuItem>
            )
        })

        let areaMenuItems = this.state.areaUnits.map((unit, index) => {
            return (
                <MenuItem
                    key={index}
                    className={classes.squareBtn}
                    value={unit.name}
                    onClick={() => this.onAreaUnitItemClick(unit)}
                >
                    {unit.namePretty}
                </MenuItem>
            )
        })

        let distanceText = this.getLengthUnitFormatted(this.state.distance)
        let perimeterText = this.getLengthUnitFormatted(this.state.perimeter)
        let areaText = this.getAreaUnitFormatted(this.state.area)

        return (
            <div className={classes.root} id="measure-widget" ref={this.containerRef}>
                <div className={classes.header}>
                    <Typography className={classes.headerText} variant="h6">
                        Measure
                    </Typography>
                    {this.state.drawing ? (
                        <Button
                            size="small"
                            variant="outlined"
                            disabled={pointLayer.features.length < 2}
                            onClick={this.onFinishMeasureButtonClicked}
                        >
                            Finish
                        </Button>
                    ) : (
                        <Button size="small" variant="outlined" onClick={this.onStartNewMeasure}>
                            Start new
                        </Button>
                    )}
                    <IconButton size="large" onClick={this.handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
                <div className={classes.content}>
                    <Typography className={classes.headerText} gutterBottom variant="body1">
                        {this.state.isPolygon ? "Perimeter" : "Total distance"}
                    </Typography>
                    <div>
                        {this.state.isPolygon ? (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{perimeterText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>
                                        {perimeterText.unit}
                                    </Typography>
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" placement="left" title="Copy">
                                    <IconButton
                                        className={classes.copyBtn}
                                        color="primary"
                                        size="large"
                                        onClick={() => this.onCopyValue(`${perimeterText.value} ${perimeterText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{distanceText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>{distanceText.unit}</Typography>
                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" placement="left" title="Copy">
                                    <IconButton
                                        className={classes.copyBtn}
                                        color="primary"
                                        size="large"
                                        onClick={() => this.onCopyValue(`${distanceText.value} ${distanceText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}

                        <Menu
                            anchorEl={this.state.unitMenuAnchorEl}
                            id="simple-menu"
                            keepMounted
                            open={Boolean(this.state.unitMenuAnchorEl)}
                            onClose={this.onCloseUnitMenu}
                        >
                            {menuItems}
                        </Menu>
                    </div>
                    {this.state.isPolygon && (
                        <div>
                            <Typography className={classes.headerText} gutterBottom variant="body1">
                                Area
                            </Typography>
                            <div className={classes.infoRow}>
                                <span className={classes.measurementValue}>{areaText.value}</span>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    className={classes.measurementUnit}
                                    onClick={this.onOpenAreaUnitMenu}
                                >
                                    <Typography className={classes.measurementUnitText}>{areaText.unit}</Typography>

                                    <ArrowDownIcon className={classes.arrowIcon} />
                                </Button>
                                <span className={classes.infoRowSpan}></span>
                                <Tooltip key="copyTT" placement="left" title="Copy">
                                    <Button
                                        className={classes.copyBtn}
                                        color="primary"
                                        onClick={() => this.onCopyValue(`${areaText.value} ${areaText.unit}`)}
                                    >
                                        <FilterNone fontSize="small" />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Menu
                                anchorEl={this.state.areaUnitMenuAnchorEl}
                                id="simple-menu"
                                keepMounted
                                open={Boolean(this.state.areaUnitMenuAnchorEl)}
                                onClose={this.onCloseAreaUnitMenu}
                            >
                                {areaMenuItems}
                            </Menu>
                        </div>
                    )}
                </div>
                {this.state.showTooltip && (
                    <Popper
                        anchorEl={this.containerRef.current}
                        modifiers={[{ name: "offset", options: { offset: [0, 5] } }]}
                        open={this.state.showTooltip}
                        placement="bottom-start"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.infoPanel}>
                                    <Typography>Click on the first point to measure area.</Typography>
                                    <IconButton size="small" onClick={this.handleCloseTooltip}>
                                        <Cancel />
                                    </IconButton>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
        measure: ownProps,
        menu: state.menu,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleWidget: widget => dispatch(toggleWidget(widget)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(measureJss)(withMapHandlers(withMainDraw(Measure), { mapId: MapIds.MainMap })))
