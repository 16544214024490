import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function recurseGroupsOne<T = object>(
    layers: GenericLayer<T>[],
    id: string,
    func: (layers?: GenericLayer<T>[], index?: number, parentGroupId?: string) => any,
    parentGroupId?: string,
): any {
    for (let i = 0; i < layers.length; i++) {
        const layer = layers[i]

        if (layer.resourceId === id) {
            return func(layer.layers, i, parentGroupId)
        }

        if (isGroup(layer)) {
            const out: object | undefined = recurseGroupsOne(layer.layers!, id, func, layer.resourceId)
            if (out) return out
        }
    }
}
