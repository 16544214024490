import { ComponentProps, FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Collapse } from "@mui/material"
import { MapLayerMouseEvent } from "mapbox-gl"
import { MapIds } from "model/enums/MapIds"
import { Widgets } from "model/enums/Widgets"
import {
    AttributionControl,
    FullscreenControl,
    LngLatBoundsLike,
    Marker,
    NavigationControl,
    ScaleControl,
    ViewStateChangeEvent,
} from "@emblautec/react-map-gl"
import { mapZoomEnd } from "actions/globalActions"
import BoxZoom from "components/map/components/BoxZoom/BoxZoom"
import MapInjector from "components/map/components/MapInjector/MapInjector"
import NoCanvasOutline from "components/map/components/NoCanvasOutline/NoCanvasOutline"
import MapLanguageControl from "components/map/CustomControls/MapLanguageControl/MapLanguageControl"
import PitchToggleControl from "components/map/CustomControls/PitchToggleControl/PitchToggleControl"
import CustomMap from "components/map/CustomMap/CustomMap"
import TokenLoader from "components/map/CustomMap/TokenLoader"
import MapEditing from "components/map/infoBoxes/mapEditing"
import BasemapSelector from "components/map/mapTools/basemapSelector"
import ExaggerationSlider from "components/map/mapTools/exaggerationSlider"
import Legend from "components/map/mapTools/Legend/Legend"
import MapCopyState from "components/map/mapTools/mapCopyState"
import MapTools from "components/map/mapTools/MapTools"
import { getMapOptions } from "components/map/utils/getDefaultMapOptions"
import Measure from "components/sidebar/measure/measure"
import { terrainExaggerationParamName } from "constants/map/queryParams"
import Buffer from "features/buffer/components/Buffer/Buffer"
import { getClientId, getProjectId } from "features/core/selectors"
import InfoboxPopup from "features/infobox/components/InfoboxPopup/InfoboxPopup"
import CoordinatesSearch from "features/mapTools/components/CoordinatesSearch/CoordinatesSearch"
import { getBasemaps, getLanguages, getToggledWidgets } from "features/mapTools/selectors"
import { closeWidgets } from "features/mapTools/slice"
import SearchBar from "features/searchBar/components/SearchBar/SearchBar"
import { SearchBar as SearchBarType } from "features/searchBar/models/SearchBar"
import { mapClick, setBasemap, setLanguage } from "reducers/map"
import { getSelectedAppIsPublic, getSelectedAppSearchBar } from "selectors/appsSelectors"
import { getEditing } from "selectors/digitizeSelectors"
import {
    getBasemap,
    getFilters,
    getLayouts,
    getMapLanguage,
    getPaints,
    getRestrictedView,
    getSources,
    getZoomRanges,
} from "selectors/mapSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useAisLayers } from "utils/customHooks/map/useAisLayers"
import { useMapHandlers } from "utils/customHooks/map/useMapHandlers"
import { useMapStyle } from "utils/customHooks/map/useMapStyle"
import { useOrderedMapLayers } from "utils/customHooks/map/useOrderedMapLayers"

type Props = {
    location: { lat: number; lon: number } | null
}

const MainMap: FC<Props> = ({ location }) => {
    const { popHandler, pushHandler, handlers } = useMapHandlers({
        mapId: MapIds.MainMap,
    })

    const getAccessTokenRef = useRef<() => string>(() => "")
    const [fullscreenEl, setFullscreenEl] = useState<Element>(document.body)

    const [terrainEnabled, setTerrainEnabled] = useState(() => {
        const queryParams = new URLSearchParams(document.location.search)
        return !!queryParams.get(terrainExaggerationParamName)
    })

    const [terrainExaggeration, setTerrainExaggeration] = useState(() => {
        const queryParams = new URLSearchParams(document.location.search)
        return parseFloat(queryParams.get(terrainExaggerationParamName) || "1")
    })

    const sources = useAppSelector(getSources)

    const paintsDict = useAppSelector(getPaints)
    const layoutsDict = useAppSelector(getLayouts)
    const zoomRangesDict = useAppSelector(getZoomRanges)

    const basemap = useAppSelector(getBasemap)
    const basemaps = useAppSelector(getBasemaps)
    const mapLanguage = useAppSelector(getMapLanguage)
    const languages = useAppSelector(getLanguages)
    const clientId = useAppSelector(getClientId)
    const projectId = useAppSelector(getProjectId)
    const enabledWidgets = useAppSelector(getToggledWidgets)
    const editingFeature = useAppSelector(getEditing)
    const isPublic: boolean | undefined = useAppSelector(getSelectedAppIsPublic)
    const searchBar: SearchBarType | null = useAppSelector(getSelectedAppSearchBar)
    const filters = useAppSelector(getFilters)
    const restrictedView = useAppSelector(getRestrictedView)

    const aisLayersData = useAisLayers()

    const layers = useOrderedMapLayers()

    const { mapStyle, setMapStyle } = useMapStyle({
        basemap,
        extraData: {
            layers: [...layers, ...aisLayersData.aisLayers],
            layoutsDict: { ...layoutsDict, ...aisLayersData.aisLayoutsDict },
            paintsDict: { ...paintsDict, ...aisLayersData.aisPaintsDict },
            sources,
            zoomRangesDict: { ...zoomRangesDict, ...aisLayersData.aisZoomRangesDict },
            isPublic: isPublic ?? false,
        },
    })

    const glMapOptions = getMapOptions(getAccessTokenRef, clientId, projectId)

    const dispatch = useAppDispatch()

    const onClick = useCallback(
        (e: MapLayerMouseEvent) =>
            dispatch(mapClick({ lat: e.lngLat.lat, lng: e.lngLat.lng, x: e.point.x, y: e.point.y })),
        [],
    )

    useEffect(() => {
        document.onfullscreenchange = () => {
            setFullscreenEl(document.fullscreenElement || document.body)
        }

        return () => {
            document.onfullscreenchange = null
            dispatch(closeWidgets())
        }
    }, [])

    const onZoomEnd = useCallback((e: ViewStateChangeEvent) => {
        const zoom = e.viewState.zoom
        dispatch(mapZoomEnd(zoom))
    }, [])

    // Set the handlers
    useEffect(() => {
        pushHandler("onZoomEnd", onZoomEnd)
        pushHandler("onClick", onClick)

        return () => {
            popHandler("onZoomEnd")
            popHandler("onClick")
        }
    }, [onZoomEnd, onClick])

    const onBasemapChanged = (basemap: string) => {
        dispatch(setBasemap(basemap))
    }

    const onLanguageChanged = (language: string) => {
        dispatch(setLanguage(language))
    }

    const onTerrainToggled = () => {
        const isTerrainEnabled = !terrainEnabled
        setTerrainEnabled(isTerrainEnabled)
    }

    const onTerrainExaggerationChanged = (exaggeration: number) => {
        setTerrainExaggeration(exaggeration)
    }

    const terrainData = useMemo(
        () => ({ exaggeration: terrainExaggeration, source: "mapbox-dem" }),
        [terrainExaggeration],
    )

    const mapProps: ComponentProps<typeof CustomMap> = {
        aisLayersData,
        filters: filters ?? {},
        id: MapIds.MainMap,
        layers,
        layoutsDict,
        mapStyle,
        maxBounds: undefined,
        maxZoom: undefined,
        minZoom: undefined,
        paintsDict,
        sources,
        terrain: terrainEnabled ? terrainData : undefined,
        zoomRangesDict,
        isPublic: isPublic ?? false,
        ...handlers,
        ...glMapOptions,
    }

    if (restrictedView?.enabled) {
        mapProps.maxZoom = restrictedView.maxZoom
        mapProps.minZoom = restrictedView.minZoom
        mapProps.maxBounds = restrictedView.mapBounds as LngLatBoundsLike
    }

    return (
        <>
            {isPublic !== undefined && !isPublic && <TokenLoader getAccessTokenRef={getAccessTokenRef} />}
            <CustomMap {...mapProps}>
                {/* Logic Components / Initting*/}
                <BoxZoom />
                <NoCanvasOutline />
                {/*Extra info/Tools  */}
                <Collapse in={editingFeature} timeout={500}>
                    <MapEditing />
                </Collapse>
                <BasemapSelector
                    basemaps={basemaps}
                    fullScreenEl={fullscreenEl}
                    value={basemap}
                    onChange={onBasemapChanged}
                />
                <ExaggerationSlider
                    exaggerationValue={terrainExaggeration}
                    fullScreenEl={fullscreenEl}
                    terrainEnabled={terrainEnabled}
                    onTerrainExaggerationChanged={onTerrainExaggerationChanged}
                    onTerrainExaggerationCommitted={() => {}} //Will be removed when we convert the component to ts
                    onTerrainToggled={onTerrainToggled}
                />
                <NavigationControl />
                <FullscreenControl />
                <MapCopyState />

                <MapLanguageControl
                    defaultLanguage={mapLanguage}
                    languages={languages}
                    mapLanguage={mapLanguage}
                    position="top-right"
                    setMapStyle={setMapStyle}
                    onLanguageChanged={onLanguageChanged}
                />
                <PitchToggleControl position="top-right" setTerrainToggle={setTerrainEnabled} />
                <Legend />

                {!!searchBar && <SearchBar />}
                {location && <Marker latitude={location.lat} longitude={location.lon} />}

                {/*Widgets*/}

                <MapInjector>
                    {enabledWidgets.hasOwnProperty(Widgets.Measure) && <Measure />}
                    {enabledWidgets.hasOwnProperty(Widgets.Search) && <CoordinatesSearch />}
                    {enabledWidgets.hasOwnProperty(Widgets.Buffer) && <Buffer layer={enabledWidgets[Widgets.Buffer]} />}
                </MapInjector>

                {/*Bottom controls */}
                <MapTools />
                <AttributionControl
                    customAttribution={
                        '<a class="map-attribution" href="https://lautec.com/" target="_blank">© LAUTEC</a>'
                    }
                />
                <ScaleControl position="bottom-right" />
                <InfoboxPopup />
            </CustomMap>
        </>
    )
}

export default MainMap
