import React, { useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import { Tooltip } from "@mui/material"
import BoxChartCanvas from "./BoxChartCanvas/BoxChartCanvas"
import PieChartCanvas from "./PieChartCanvas/PieChartCanvas"

export default function StyleTypeVisualizer(props) {
    function styleToProperties(style, fillProp, fillOutlineProp) {
        let fillColor = style.find(paintType => paintType.name === fillProp)
        let outlineColor = style.find(paintType => paintType.name === fillOutlineProp)

        return {
            backgroundColor: fillColor ? fillColor.value : "transparent",
            expressionType: fillColor?.expressionType,
            outlineColor: outlineColor ? outlineColor.value : "transparent",
            type: style.type,
        }
    }

    function pieStyleToProperties(style) {
        let colors = style.find(paintType => paintType.name === "pie-chart-colors")

        return {
            colors: colors.value,
            expressionType: "none",
            type: style.type,
        }
    }

    function boxStyleToProperties(style) {
        let colors = style.find(paintType => paintType.name === "box-chart-colors")
        let rows = style.find(paintType => paintType.name === "box-chart-rows")
        let columns = style.find(paintType => paintType.name === "box-chart-columns")
        let strokeWidth = style.find(paintType => paintType.name == "box-chart-stroke-width")
        let strokeColor = style.find(paintType => paintType.name == "box-chart-stroke-color")

        return {
            colors: colors.value,
            columns: columns.value,
            expressionType: "none",
            rows: rows.value,
            strokeColor: strokeColor.value,
            strokeWidth: strokeWidth.value,
            type: style.type,
        }
    }

    function renderLayerType(type) {
        let properties = getColorProperties(type, props.layerProperties)

        let style = renderType(type, properties)

        if (properties.expressionType === "match") {
            style = addGradient(style)
        } else if (properties.expressionType === "interpolate") {
            style = addGradientInterpolate(style)
        }

        if (type === "pie-chart") {
            return <PieChartCanvas colors={properties.colors} height={18} width={18} />
        }

        if (type === "box-chart") {
            return (
                <BoxChartCanvas
                    colors={properties.colors}
                    columns={properties.columns}
                    height={18}
                    rows={properties.rows}
                    strokeColor={properties.strokeColor}
                    width={18}
                    hasStroke={properties.strokeWidth > 0}
                />
            )
        }

        return (
            <div className="layer-type" style={style}>
                {type === "symbol" ? "A" : ""}
            </div>
        )
    }

    function renderType(type, properties) {
        switch (type) {
            case "fill":
                return styleFillLabel(properties)
            case "fill-extrusion":
                return styleFillExtrusionLabel(properties)
            case "circle":
                return styleCircleLabel(properties)
            case "pie-chart":
                return {}
            case "box-chart":
                return {}
            case "line":
                return styleLineLabel(properties)
            case "symbol":
                return styleSymbolLabel(properties)
            case "raster":
                return styleFillLabel(properties)
            default:
                return null
        }
    }

    function getColorProperties(type, styleProperties) {
        switch (type) {
            case "fill":
                return styleToProperties(styleProperties, "fill-color", "fill-outline-color")
            case "fill-extrusion":
                return styleToProperties(styleProperties, "fill-extrusion-color", "fill-extrusion-color")
            case "circle":
                return styleToProperties(styleProperties, "circle-color", "circle-stroke-color")
            case "line":
                return styleToProperties(styleProperties, "line-color", "line-outline-color")
            case "symbol":
                return styleToProperties(styleProperties, "text-color", "icon-halo-color")
            case "raster":
                return styleToProperties(styleProperties, "fill-color", "fill-outline-color")
            case "pie-chart":
                return pieStyleToProperties(styleProperties)
            case "box-chart":
                return boxStyleToProperties(styleProperties)
            default:
                return null
        }
    }

    function styleFillLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
        }
    }

    function styleFillExtrusionLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
        }
    }

    function styleCircleLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
            borderRadius: 16,
        }
    }

    function styleLineLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
            height: 4,
        }
    }

    function styleSymbolLabel(properties) {
        return {
            backgroundColor: "transparent",
            color: properties.backgroundColor,
            fontSize: "22px",
            fontWeight: "bold",
            lineHeight: "16px",
            textShadow:
                "rgb(255, 255, 255) 1px 0px 0px, rgb(255, 255, 255) 0.540302px 0.841471px 0px, rgb(255, 255, 255) -0.416147px 0.909297px 0px, rgb(255, 255, 255) -0.989992px 0.14112px 0px, rgb(255, 255, 255) -0.653644px -0.756802px 0px, rgb(255, 255, 255) 0.283662px -0.958924px 0px, rgb(255, 255, 255) 0.96017px -0.279415px 0px",
        }
    }

    function addGradient(style) {
        let colors = []

        for (let i = 3; i < style.backgroundColor.length; i += 2) {
            colors.push(style.backgroundColor[i])
        }

        return {
            ...style,
            backgroundImage: `linear-gradient(0.25turn,${colors.join(",")})`,
            borderStyle: "none",
            height: 16,
        }
    }

    function addGradientInterpolate(style) {
        let colors = []

        for (let i = 4; i < style.backgroundColor.length; i += 2) {
            colors.push(style.backgroundColor[i])
        }
        return {
            ...style,
            backgroundImage: `linear-gradient(0.25turn,${colors.join(",")})`,
        }
    }

    const tooltipTitle = props.styleDeletionDisabled ? "The last style can't be deleted" : "Delete"

    return (
        <div className={props.selected ? "type selected" : "type"}>
            <Tooltip title={tooltipTitle}>
                <div
                    {...(!props.styleDeletionDisabled && { onClick: props.onRemove })}
                    className={"remove-button " + (props.styleDeletionDisabled ? "disabled-color" : "")}
                >
                    <RemoveIcon />
                </div>
            </Tooltip>

            {renderLayerType(props.type)}
        </div>
    )
}
