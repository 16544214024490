import React from "react"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    root: {
        bottom: 0,
        position: "absolute",
        right: 0,
        zIndex: 1,
    },
    rootLegend: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: 2,
        position: "relative",
        textAlign: "center",
    },
    text: {
        backgroundColor: "rgba(255,255,255,0.60)",
        bottom: 0,
        fontSize: "12px",
        left: 0,
        maxWidth: "13vw",
        padding: "0 5px",
        right: 0,
    },
    textLegend: {
        fontSize: "0.5rem",
    },
}))

const MapCopyright = ({ mapCopyright, modal = false, showMapLegend }) => {
    const classes = useStyles({ showMapLegend })
    return (
        <div className={`${modal ? classes.rootLegend : classes.root}`} id="map-copyright">
            <Typography className={showMapLegend ? classes.textLegend : classes.text} variant="caption">
                {mapCopyright}
            </Typography>
        </div>
    )
}

export default MapCopyright
