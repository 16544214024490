import * as React from "react";
const SvgPlaceOfWorship11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "place-of-worship-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.52 0 L4 2 L4 3 L7 3 L7 2 L5.52 0 z M4 4 L2 5 L2 10 L9 10 L9 5 L7 4 L4 4 z M11 5.5 L11 10 L10 10 L10 5.5 C10 5.2239 10.2239 5 10.5 5 C10.7761 5 11 5.2239 11 5.5 z M1 5.5 L1 10 L0 10 L0 5.5 C0 5.2239 0.2239 5 0.5 5 C0.7761 5 1 5.2239 1 5.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.52 0 L4 2 L4 3 L7 3 L7 2 L5.52 0 z M4 4 L2 5 L2 10 L9 10 L9 5 L7 4 L4 4 z M11 5.5 L11 10 L10 10 L10 5.5 C10 5.2239 10.2239 5 10.5 5 C10.7761 5 11 5.2239 11 5.5 z M1 5.5 L1 10 L0 10 L0 5.5 C0 5.2239 0.2239 5 0.5 5 C0.7761 5 1 5.2239 1 5.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPlaceOfWorship11;
