import React from "react"
import RemoveIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import TitleTextField from "../../common/TitleTextfield/TitleTextField"
import ColorPopover from "../colorPopover"

function ColorProperty({
    editable,
    title,
    value,
    isDefaultValue,
    isZoomDependent,
    onClick,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) {
    const removable = onRemove && !isDefaultValue
    return (
        <div className="property" onClick={onClick}>
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                {!editable && (
                    <Typography className="name" variant="body2">
                        {title}
                    </Typography>
                )}
                <ColorPopover
                    color={value}
                    onColorChanged={color =>
                        onPropertyChanged(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                    }
                />
            </div>
        </div>
    )
}

export default ColorProperty
