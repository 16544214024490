import * as React from "react";
const SvgSwimming11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "swimming-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.0039 0.4941 L7.3046994 0.99609995 L4.6035 2.5977 C4.3036 2.6967 4.2042 3.1962 4.4023 3.4961 L5.0018997 4.4981 L2.5018997 6.4981 L3.5018997 7.5000997 L5.5018997 6.4981 L7.5039 7.5 L8.503901 6.498 L5.5019007 2.996 L8.503901 1.496 L8.503901 0.49400008 C8.5039 0.4941 8.0039 0.4941 8.0039 0.4941 z M9.0059 2.9961 C8.4529 2.9961 8.003901 3.445 8.003901 3.9980998 C8.003901 4.5512 8.452801 4.9981 9.0059 4.9981 C9.558901 4.9981 10.0059 4.5511 10.0059 3.9980998 C10.0059 3.4450998 9.5589 2.9961 9.0059 2.9961 z M2 7.998 L0 9 L0 10 L2 9 L3.5 10 L5.5 9 L7.5 10 L9 9 L11 10 L11 9 L9 7.998 L7.5 9 L5.5 7.998 L3.5 9 L2 7.998 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.0039 0.4941 L7.3046994 0.99609995 L4.6035 2.5977 C4.3036 2.6967 4.2042 3.1962 4.4023 3.4961 L5.0018997 4.4981 L2.5018997 6.4981 L3.5018997 7.5000997 L5.5018997 6.4981 L7.5039 7.5 L8.503901 6.498 L5.5019007 2.996 L8.503901 1.496 L8.503901 0.49400008 C8.5039 0.4941 8.0039 0.4941 8.0039 0.4941 z M9.0059 2.9961 C8.4529 2.9961 8.003901 3.445 8.003901 3.9980998 C8.003901 4.5512 8.452801 4.9981 9.0059 4.9981 C9.558901 4.9981 10.0059 4.5511 10.0059 3.9980998 C10.0059 3.4450998 9.5589 2.9961 9.0059 2.9961 z M2 7.998 L0 9 L0 10 L2 9 L3.5 10 L5.5 9 L7.5 10 L9 9 L11 10 L11 9 L9 7.998 L7.5 9 L5.5 7.998 L3.5 9 L2 7.998 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSwimming11;
