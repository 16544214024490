import * as React from "react";
const SvgSchool15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "school-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 13 L11 12 L13 12 L13 11 L9.5 11 L9.5 10 L13 10 L13 9 L11 9 L11 8 L13 8 L13 7 L11 7 L11 6 L13 6 L13 5 L9.5 5 L9.5 4 L13 4 L13 3 L11 3 L11 2 L13 2 L13 1 L8 1 L8 14 L13 14 L13 13 L11 13 z M6 11 L2 11 L2 1 L6 1 L6 11 z M6 12 L4 14 L2 12 L6 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 13 L11 12 L13 12 L13 11 L9.5 11 L9.5 10 L13 10 L13 9 L11 9 L11 8 L13 8 L13 7 L11 7 L11 6 L13 6 L13 5 L9.5 5 L9.5 4 L13 4 L13 3 L11 3 L11 2 L13 2 L13 1 L8 1 L8 14 L13 14 L13 13 L11 13 z M6 11 L2 11 L2 1 L6 1 L6 11 z M6 12 L4 14 L2 12 L6 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSchool15;
