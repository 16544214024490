import { ComponentProps, MutableRefObject } from "react"
import Map from "@emblautec/react-map-gl"
import config from "config"

const transformRequestResourceTypes = ["Tile", "SpriteJSON", "SpriteImage"]

export const getMapOptions = (
    getAuthToken: MutableRefObject<() => string>,
    clientId: number | null,
    projectId: number | null,
): Partial<ComponentProps<typeof Map>> => ({
    antialias: true,
    attributionControl: false,
    boxZoom: true,
    dragPan: true,
    mapboxAccessToken: config.mapboxApiKey,
    maxZoom: 24,
    optimizeForTerrain: false,
    styleDiffing: false,
    //@ts-ignore TODO: fix types
    transformRequest: (url: string, resourceType: string) => {
        if (transformRequestResourceTypes.includes(resourceType) && url.startsWith(config.apiUrl)) {
            return {
                url: `${url}?key=${getAuthToken.current()}&ClientId=${clientId}&ProjectId=${projectId}`,
            }
        }
        return { url }
    },
    //@ts-ignore
    useWebGL2: true,
    hash: false,
})
