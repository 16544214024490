import React, { useEffect, useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import * as Yup from "yup"
import TitleTextField from "../../../common/TitleTextfield/TitleTextField"

const offsetValidator = Yup.number().typeError("Value must be a number").required("Required")

function TextOffsetProperty({
    editable,
    title,
    value: propertyValue,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) {
    const isLiteral = propertyValue[0] === "literal"

    const value = isLiteral ? propertyValue[1] : propertyValue

    const [offsetX, setOffsetX] = useState(value[0])
    const [offsetY, setOffsetY] = useState(value[1])
    const [offsetXError, setOffsetXError] = useState(undefined)
    const [offsetYError, setOffsetYError] = useState(undefined)

    useEffect(() => {
        processOffsetXChange(value[0], true)
        processOffsetYChange(value[1], true)
    }, [])

    const processOffsetXChange = (offsetX, initing = false) => {
        //For empty string we send undefined to trigger required
        const valueToProcess = offsetX === "" ? undefined : offsetX

        setOffsetX(offsetX)
        offsetValidator
            .validate(valueToProcess)
            .then(newOffsetX => {
                setOffsetXError()
                if (!offsetYError && !initing) {
                    const newValue = isLiteral ? ["literal", [newOffsetX, offsetY]] : [newOffsetX, offsetY]
                    onPropertyChanged(newValue)
                }
            })
            .catch(err => {
                setOffsetXError(err.errors[0])
            })
    }

    const processOffsetYChange = (offsetY, initing = false) => {
        //For empty string we send undefined to trigger required
        const valueToProcess = offsetY === "" ? undefined : offsetY

        setOffsetY(offsetY)
        offsetValidator
            .validate(valueToProcess)
            .then(newOffsetY => {
                setOffsetYError()
                if (!offsetXError && !initing) {
                    const newValue = isLiteral ? ["literal", [offsetX, newOffsetY]] : [offsetX, newOffsetY]
                    onPropertyChanged(newValue)
                }
            })
            .catch(err => {
                setOffsetYError(err.errors[0])
            })
    }

    const onOffsetXChange = e => {
        processOffsetXChange(e.target.value)
    }

    const onOffsetYChange = e => {
        processOffsetYChange(e.target.value)
    }
    const removable = onRemove && !isLastRemainingCase
    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}

                {removable &&
                    !isDefaultValue(
                        <div className="remove-button" onClick={onRemove}>
                            <RemoveIcon />
                        </div>,
                    )}
                <div className="textfield-container column">
                    <TextField
                        error={!!offsetXError}
                        fullWidth
                        helperText={offsetXError}
                        label="Offset X"
                        value={offsetX}
                        variant="standard"
                        onChange={onOffsetXChange}
                    />
                    <Box height={10} />
                    <TextField
                        error={!!offsetYError}
                        fullWidth
                        helperText={offsetYError}
                        label="Offset Y"
                        value={offsetY}
                        variant="standard"
                        onChange={onOffsetYChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default TextOffsetProperty
