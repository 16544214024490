import * as React from "react";
const SvgStar15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "star-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L5.5 5 L0.5 5 L4.5 8.5 L2.5 14.5 L7.5 11 L12.5 14.5 L10.5 8.5 L14.5 5 L9.5 5 L7.5 0 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L5.5 5 L0.5 5 L4.5 8.5 L2.5 14.5 L7.5 11 L12.5 14.5 L10.5 8.5 L14.5 5 L9.5 5 L7.5 0 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgStar15;
