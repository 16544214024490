import React from "react"
import { TextField } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"
import { FilterType } from "../../../../contexts/FilterContext"
import { Types } from "../../models/FilterType"

type FilterValueEditorProps = {
    filter: FilterType
    label: string
    value: string | number | Dayjs | null | undefined
    handleValueChange: (value: string | number | Dayjs) => void
}

const FilterValueEditor = ({ filter, label, value = null, handleValueChange }: FilterValueEditorProps) => {
    const renderNumeric = () => (
        <TextField
            fullWidth
            inputProps={{ "data-testid": numericValueTestId }}
            label={label}
            type="number"
            value={value ?? ""}
            variant="filled"
            onChange={e => handleValueChange(e.target.value)}
        />
    )

    const renderDate = () => (
        <DatePicker
            format="YYYY-MM-DD"
            label={label}
            value={value}
            disabled={!filter.fieldName}
            onChange={date => handleValueChange(dayjs(date))}
        />
    )

    const renderString = () => (
        <TextField
            fullWidth
            inputProps={{ "data-testid": literalValueTestId }}
            label={label}
            value={value ?? ""}
            variant="filled"
            disabled={!filter.fieldName}
            onChange={e => handleValueChange(e.target.value)}
        />
    )

    const renderUndefined = () => (
        <TextField
            fullWidth
            inputProps={{ "data-testid": literalValueTestId }}
            label={label}
            value=""
            variant="filled"
            disabled={!filter.fieldName}
        />
    )

    const typeMap: Record<Types, () => JSX.Element> = {
        [Types.CharacterVarying]: renderString,
        [Types.Date]: renderDate,
        [Types.DoublePrecision]: renderNumeric,
        [Types.Integer]: renderNumeric,
        [Types.String]: renderString,
        [Types.Undefined]: renderUndefined,
    }

    return typeMap[filter.type]()
}

export default FilterValueEditor

const numericValueTestId = "qa-filter-value-editor-numeric-value-field"
const literalValueTestId = "qa-filter-value-editor-literal-value-field"
