import { useState, useEffect } from "react"
import { useMap } from "@emblautec/react-map-gl"
import { getFilters } from "selectors/mapSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"

// Needed because otherwise the filters are not applied on the layers from the printMap
const usePrintMapFilters = () => {
    const { printMap } = useMap()
    const reduxFilters = useAppSelector(getFilters)
    const [filters, setFilters] = useState({})

    useEffect(() => {
        if (printMap) {
            printMap.on("load", () => {
                setFilters(reduxFilters)
            })
        }
    }, [printMap, reduxFilters])

    return filters
}

export default usePrintMapFilters
