import * as React from "react";
const SvgAirport11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "airport-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.5 6.4 L6.5 6 L11 6.5 L11 5 L6.5 3.2 L6.5 1.5 C6.5 0.5 6 0 5.5 0 C5 0 4.5 0.5 4.5 1.5 L4.5 3.2 L0 5 L0 6.4 L4.5 6 L4.5 6.4 L4.5 7.5 L4.5 9.3 L3 10 L3 11 L5.5 10.5 L8 11 L8 10 L6.5 9.2 L6.5 7.5 L6.5 6.4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.5 6.4 L6.5 6 L11 6.5 L11 5 L6.5 3.2 L6.5 1.5 C6.5 0.5 6 0 5.5 0 C5 0 4.5 0.5 4.5 1.5 L4.5 3.2 L0 5 L0 6.4 L4.5 6 L4.5 6.4 L4.5 7.5 L4.5 9.3 L3 10 L3 11 L5.5 10.5 L8 11 L8 10 L6.5 9.2 L6.5 7.5 L6.5 6.4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAirport11;
