import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(() => ({
    appBar: {
        boxShadow: "none",
    },
    content: {
        fontSize: 20,
    },
    logo: {
        height: 23,
    },
    root: {
        background: "white",
        height: "calc(100% - 50px)",
        position: "relative",
    },
    toolbar: {
        alignItems: "center",
        backgroundColor: "white",
        display: "flex",
        height: 50,
        minHeight: 50,
        paddingLeft: 16,
        paddingRight: 0,
    },
}))
