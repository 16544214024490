import * as React from "react";
const SvgAttraction11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "attraction-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 1.5 C4.5 1.5 4 1.5 3.8 2 L3.5 2.5 L1 2.5 C0.39999998 2.5 0 2.9 0 3.5 L0 8.5 C0 9.1 0.4 9.5 1 9.5 L10 9.5 C10.6 9.5 11 9.1 11 8.5 L11 3.5 C11 2.9 10.6 2.5 10 2.5 L7.5 2.5 L7.2 2 C7 1.5 6.5 1.5 6.5 1.5 L4.5 1.5 z M5.5 3.5 C6.9 3.5 8 4.6 8 6 C8 7.4 6.9 8.5 5.5 8.5 C4.1 8.5 3 7.4 3 6 C3 4.6 4.1 3.5 5.5 3.5 z M5.5 5 C4.9 5 4.5 5.4 4.5 6 C4.5 6.6 4.9 7 5.5 7 C6.1 7 6.5 6.6 6.5 6 C6.5 5.4 6.1 5 5.5 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 1.5 C4.5 1.5 4 1.5 3.8 2 L3.5 2.5 L1 2.5 C0.39999998 2.5 0 2.9 0 3.5 L0 8.5 C0 9.1 0.4 9.5 1 9.5 L10 9.5 C10.6 9.5 11 9.1 11 8.5 L11 3.5 C11 2.9 10.6 2.5 10 2.5 L7.5 2.5 L7.2 2 C7 1.5 6.5 1.5 6.5 1.5 L4.5 1.5 z M5.5 3.5 C6.9 3.5 8 4.6 8 6 C8 7.4 6.9 8.5 5.5 8.5 C4.1 8.5 3 7.4 3 6 C3 4.6 4.1 3.5 5.5 3.5 z M5.5 5 C4.9 5 4.5 5.4 4.5 6 C4.5 6.6 4.9 7 5.5 7 C6.1 7 6.5 6.6 6.5 6 C6.5 5.4 6.1 5 5.5 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAttraction11;
