import * as types from "./actionTypes"

export const addDrawnFeatures = features => ({ result: features, type: types.DRAW_FEATURES_CREATED })

export const editFeature = feature => ({ result: feature, type: types.DRAW_FEATURE_EDITED })

export const removeFeature = feature => ({ result: feature, type: types.DRAW_FEATURE_DELETED })

export const renameFeature = (feature, newName) => {
    let newFeat = { ...feature }
    newFeat.properties.name = newName
    return { result: newFeat, type: types.DRAW_FEATURES_RENAMED }
}

export const selectFeature = feature => ({ result: feature, type: types.SELECT_DIGITIZE_FEATURE })

export const addLayer = layer => ({ result: layer, type: types.ADD_DIGITIZE_LAYER })

export const removeLayer = layer => ({ result: layer, type: types.DELETE_DIGITIZE_LAYER })

export const editLayer = newLayer => ({ result: newLayer, type: types.EDIT_DIGITIZE_LAYER })

export const editingFeatures = editing => ({ result: editing, type: types.DIGITIZE_EDITING })

export const displayInfoHelp = info => ({ result: info, type: types.DIGITIZE_HELP })

export const addDigitizeStyle = style => ({ result: style, type: types.ADD_DIGITIZE_STYLE })

export const updateDigitizeStyle = style => ({ result: style, type: types.UPDATE_DIGITIZE_STYLE })

export const removeDigitizeStyle = style => ({ result: style, type: types.REMOVE_DIGITIZE_STYLE })

export const removeAllDigitizeStylesForLayer = datasetId => ({
    result: datasetId,
    type: types.REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER,
})

export const renameDigitizeStylesNameForLayer = updatedLayer => ({
    result: updatedLayer,
    type: types.RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER,
})

export const reinitializeDigitizeStylesLayerId = layer => ({
    result: layer,
    type: types.REINITIALIZE_DIGITIZE_STYLES_LAYER_ID,
})

export const toggleDigitizeLayer = (digitizeLayerId, selected) => ({
    result: { digitizeLayerId, selected },
    type: types.TOGGLE_DIGITIZE_LAYER,
})

export const toggleAllDigitizeLayers = activationState => ({
    result: { activationState },
    type: types.TOGGLE_ALL_DIGITIZE_LAYERS,
})
