import * as React from "react";
const SvgCinema11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cinema-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 L10 7.5 C10 7.7761 9.7761 8 9.5 8 C9.2239 8 9 7.7761 9 7.5 L9 7.5 C8.9427 7.2478 8.7433 7.0523 8.49 7 L8 7 L8 8.63 C8 8.8343 7.8343 9 7.63 9 L1.37 9 C1.1657 9 1 8.8343 1 8.63 L1 5.37 C1 5.1657 1.1657 5 1.37 5 L7.63 5 C7.8343 5 8 5.1657 8 5.37 L8 6 L8.49 6 C8.7433 5.9477 8.9427 5.7522 9 5.5 C9 5.2239 9.2239 5 9.5 5 C9.7761 5 10 5.2239 10 5.5 z M2.5 2 C1.6716 2 1 2.6716 1 3.5 C1 4.3283997 1.6716 5 2.5 5 C3.3284001 5 4 4.3284 4 3.5 C4 2.6715999 3.3284 2 2.5 2 z M2.5 4 C2.2239 4 2 3.7761 2 3.5 C2 3.2239 2.2239 3 2.5 3 C2.7761 3 3 3.2239 3 3.5 C3 3.7761 2.7761 4 2.5 4 z M6 1 C4.8954 1 4 1.8954 4 3 C4 4.1046 4.8954 5 6 5 C7.1046 5 8 4.1046 8 3 C8 1.8954 7.1046 1 6 1 z M6 4 C5.4477 4 5 3.5523 5 3 C5 2.4477 5.4477 2 6 2 C6.5523 2 7 2.4477 7 3 C7 3.5523 6.5523 4 6 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 L10 7.5 C10 7.7761 9.7761 8 9.5 8 C9.2239 8 9 7.7761 9 7.5 L9 7.5 C8.9427 7.2478 8.7433 7.0523 8.49 7 L8 7 L8 8.63 C8 8.8343 7.8343 9 7.63 9 L1.37 9 C1.1657 9 1 8.8343 1 8.63 L1 5.37 C1 5.1657 1.1657 5 1.37 5 L7.63 5 C7.8343 5 8 5.1657 8 5.37 L8 6 L8.49 6 C8.7433 5.9477 8.9427 5.7522 9 5.5 C9 5.2239 9.2239 5 9.5 5 C9.7761 5 10 5.2239 10 5.5 z M2.5 2 C1.6716 2 1 2.6716 1 3.5 C1 4.3283997 1.6716 5 2.5 5 C3.3284001 5 4 4.3284 4 3.5 C4 2.6715999 3.3284 2 2.5 2 z M2.5 4 C2.2239 4 2 3.7761 2 3.5 C2 3.2239 2.2239 3 2.5 3 C2.7761 3 3 3.2239 3 3.5 C3 3.7761 2.7761 4 2.5 4 z M6 1 C4.8954 1 4 1.8954 4 3 C4 4.1046 4.8954 5 6 5 C7.1046 5 8 4.1046 8 3 C8 1.8954 7.1046 1 6 1 z M6 4 C5.4477 4 5 3.5523 5 3 C5 2.4477 5.4477 2 6 2 C6.5523 2 7 2.4477 7 3 C7 3.5523 6.5523 4 6 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCinema11;
