import { useCallback, useEffect, useRef } from "react"
import { Dayjs } from "dayjs"
import { Subscription } from "model/ais/Subscription"
import { ArchiveBucket } from "features/ais//models/ArchiveBucket"
import { utils } from "features/ais/components/AisArchivePlayer"
import { BoatPositionWithStartTime } from "features/ais/models/BoatPositionWithStartTime"
import { SubscriptionArchiveData } from "features/ais/models/SubscriptionArchiveData"
import { setArchiveData } from "reducers/ais"
import { useAppDispatch } from "store/hooks/useAppDispatch"

const updateBoatsDictionary = (
    currentBucket: ArchiveBucket,
    boatsDictionary: Record<string, BoatPositionWithStartTime>,
) => {
    for (const boatPosition of currentBucket.boatPositions) {
        boatsDictionary[utils.getBoatIdentifier(boatPosition)] = {
            ...boatPosition,
            startTime: currentBucket.startDate,
        }
    }
}

const updateArchiveData = (archiveData: SubscriptionArchiveData, data: ArchiveBucket[], currentBucketIndex: number) => {
    const previousBucket = data[currentBucketIndex - 1]
    const currentBucket = data[currentBucketIndex]
    const boatsDictionary = { ...archiveData.boatsDictionary }
    updateBoatsDictionary(archiveData.currentBucket, boatsDictionary)

    return { ...archiveData, boatsDictionary, currentBucket, previousBucket }
}

const useArchiveAnimations = (
    subscription: Subscription,
    data: ArchiveBucket[],
    intervalBetweenLayersInMs: number,
    filteringInterval: [Dayjs, Dayjs],
) => {
    const dispatch = useAppDispatch()
    const archiveDataRef = useRef<SubscriptionArchiveData | null>(null)
    const currentBucketIndexRef = useRef(0)

    // this function will advance time by exactly one bucket
    // when it reaches the end of the current chunk, it will reset the bucket index
    // and call the callback function, while also saving the last bucket of the current chunk
    // as the previous bucket for the first bucket of the next chunk, which allows for
    // animations between the last bucket of the current chunk and the first bucket
    // of the next chunk
    const animate = useCallback(
        (overrideSkipTransition: boolean = false) => {
            if (!archiveDataRef.current || currentBucketIndexRef.current === 0) {
                // we are either building the dictionary for the first time, in the first chunk,
                // or we are appending to the existing dictionary the boats from the
                // first bucket of the next chunk
                const currentBucket = data[currentBucketIndexRef.current++]

                const boatsDictionary = archiveDataRef.current
                    ? { ...archiveDataRef.current.boatsDictionary }
                    : utils.buildBoatsDictionary(data)

                if (archiveDataRef.current) updateBoatsDictionary(archiveDataRef.current.currentBucket, boatsDictionary)

                archiveDataRef.current = {
                    ...archiveDataRef.current,
                    boatsDictionary,
                    currentBucket,
                    intervalBetweenLayersInMs,
                    maxBoatAge: subscription.maxBoatAge,
                    skipTransition: overrideSkipTransition || true,
                    subscriptionId: subscription.id,
                }

                dispatch(setArchiveData(archiveDataRef.current))

                return
            }
            if (currentBucketIndexRef.current < data.length - 1) {
                archiveDataRef.current = updateArchiveData(archiveDataRef.current, data, currentBucketIndexRef.current)

                dispatch(
                    setArchiveData({
                        ...archiveDataRef.current,
                        intervalBetweenLayersInMs,
                        skipTransition: overrideSkipTransition || false,
                    }),
                )

                currentBucketIndexRef.current++
            } else if (currentBucketIndexRef.current === data.length - 1) {
                archiveDataRef.current = updateArchiveData(archiveDataRef.current, data, currentBucketIndexRef.current)

                dispatch(
                    setArchiveData({
                        ...archiveDataRef.current,
                        intervalBetweenLayersInMs,
                        skipTransition: overrideSkipTransition || false,
                    }),
                )

                archiveDataRef.current = {
                    ...archiveDataRef.current,
                    intervalBetweenLayersInMs,
                    previousBucket: archiveDataRef.current.currentBucket,
                    skipTransition: false,
                }

                currentBucketIndexRef.current = 0
            }
        },
        [data, dispatch, intervalBetweenLayersInMs, subscription.id, subscription.maxBoatAge],
    )

    const jumpToDate = useCallback(
        (date: Dayjs) => {
            const boatsDictionary = archiveDataRef.current
                ? { ...archiveDataRef.current.boatsDictionary }
                : utils.buildBoatsDictionary(data)

            for (let i = 0; i < data.length; i++) {
                if (data[i].startDate.isSame(date) || data[i].startDate.isAfter(date)) {
                    currentBucketIndexRef.current = i
                    const currentBucket = data[currentBucketIndexRef.current++]

                    if (archiveDataRef.current) updateBoatsDictionary(currentBucket, boatsDictionary)

                    archiveDataRef.current = {
                        ...archiveDataRef.current,
                        boatsDictionary,
                        currentBucket,
                        intervalBetweenLayersInMs,
                        maxBoatAge: subscription.maxBoatAge,
                        previousBucket: i !== 0 ? data[i - 1] : undefined,
                        skipTransition: true,
                        subscriptionId: subscription.id,
                    }

                    dispatch(setArchiveData(archiveDataRef.current))

                    return
                } else {
                    const currentBucket = data[i]
                    updateBoatsDictionary(currentBucket, boatsDictionary)
                }
            }
        },
        [data, dispatch, intervalBetweenLayersInMs, subscription.id, subscription.maxBoatAge],
    )

    const stopAnimation = useCallback(() => {
        dispatch(setArchiveData(null))
        currentBucketIndexRef.current = 0
        archiveDataRef.current = null
    }, [dispatch])

    useEffect(() => {
        currentBucketIndexRef.current = 0
    }, [filteringInterval])

    return { animate, currentBucketIndexRef, jumpToDate, stopAnimation }
}

export default useArchiveAnimations
