import React from "react"
import { useCanvas } from "../../hooks/useCanvas"

type PieChartCanvasProps = {
    colors: Array<string>
    height: number
    width: number
}

const PieChartCanvas = ({ colors = [], height = 200, width = 300 }: PieChartCanvasProps) => {
    const angleSegement = (2.0 * Math.PI) / colors.length

    const radius = Math.min(width / 2, height / 2)

    const draw = (context: CanvasRenderingContext2D) => {
        if (!canvasRef?.current) {
            return
        }

        context.clearRect(0, 0, width, height)

        let startAngle = 0

        for (let i = 0; i < colors.length; i++) {
            let color = colors[i]
            let endAngle = startAngle + angleSegement

            renderPieSlice(context, startAngle, endAngle, color)

            startAngle += angleSegement
        }
    }

    const renderPieSlice = (context: CanvasRenderingContext2D, startAngle: number, endAngle: number, color: string) => {
        context.beginPath()
        //Move to the center of the canvas
        context.moveTo(width / 2, height / 2)
        //Arc from startAngle to endAngle with radius
        context.arc(width / 2, height / 2, radius, startAngle, endAngle)

        context.fillStyle = color
        context.fill()
    }

    const onClick = () => {}

    const canvasRef = useCanvas(draw)

    return <canvas height={height} ref={canvasRef} width={width} onClick={onClick} />
}

export default PieChartCanvas
