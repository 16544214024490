import { DefaultTheme } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"

const desiredLegendSize = 24
const lineSize = 3

// Since the border width also contributes to the actual width we need to substract it
const polygonSize = desiredLegendSize - lineSize * 2

const pointSize = 6.5

const pointCornerOffset = -lineSize - 2.5 // In order to avoid weird overlaps

export const useStyles = makeStyles<DefaultTheme, { circleColor?: string; fillColor?: string; lineColor?: string }>(
    () => ({
        circle: props => ({
            backgroundColor: props.circleColor,
            borderRadius: 100,
            height: pointSize,
            position: "absolute",
            width: pointSize,
        }),
        fill: props => ({
            backgroundColor: props.fillColor,
            borderColor: props.fillColor,
            borderStyle: "solid",
            borderWidth: lineSize,
        }),
        fillBorder: props => ({
            borderColor: props.fillColor,
            borderStyle: "solid",
            borderWidth: lineSize,
        }),
        lb: {
            bottom: pointCornerOffset,
            left: pointCornerOffset,
        },
        legend: {
            height: polygonSize,
            position: "relative",
            width: polygonSize,
        },
        line: props => ({
            borderColor: props.lineColor,
            borderStyle: "solid",
            borderWidth: lineSize,
        }),
        lt: {
            left: pointCornerOffset,
            top: pointCornerOffset,
        },
        rb: {
            bottom: pointCornerOffset,
            right: pointCornerOffset,
        },
        rt: {
            right: pointCornerOffset,
            top: pointCornerOffset,
        },
    }),
)
