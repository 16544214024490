import { Box, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Stack } from "@mui/material"
import { Subscription } from "model/ais/Subscription"
import CollapsibleCard from "components/common/CollapsibleCard/CollapsibleCard"
import { FromNow } from "components/common/FromNow/FromNow"
import ReadOnlyField from "components/common/readOnlyField/readOnlyField"
import CardTitle from "components/sidebar/ais/CardTitle"
import { getTypeDescription } from "components/sidebar/ais/utils"

type Props = {
    subscription: Subscription
    isArchiveFeatureEnabled: boolean
    isArchiveMetadataLoading: boolean
    isArchivePlayerActive: boolean
    isToggled: boolean
    onArchivePlayerCheck: (isChecked: boolean) => void
    onToggleClick: () => void
}

const TOOLTIP_INTERVAL = 1000

const AisSubscriptionCard = ({
    subscription,
    isArchiveFeatureEnabled,
    isArchiveMetadataLoading,
    isArchivePlayerActive,
    isToggled,
    onArchivePlayerCheck,
    onToggleClick,
}: Props) => (
    <Box boxShadow="0 2px 12px 1px rgba(60, 62, 66, 0.1)" m="8px 20px">
        <CollapsibleCard
            Component={<CardTitle sub={subscription} toggleCard={onToggleClick} />}
            id={subscription.id}
            unmountOnExit
            isToggled={isToggled}
            onToggleClick={onToggleClick}
        >
            <Stack direction={"column"} gap={1}>
                <Stack direction="column" gap={2}>
                    <ReadOnlyField label="Description" text={subscription.description || "-"} />
                    <ReadOnlyField label="Type" text={getTypeDescription(subscription.type)} />
                    <ReadOnlyField label="Update Frequency In Minutes" text={subscription.updateFrequency} />
                    <FromNow date={subscription.createdUtc} interval={TOOLTIP_INTERVAL} label="Created" tooltip />
                    <FromNow
                        date={subscription.latestUpdateUtc}
                        interval={TOOLTIP_INTERVAL}
                        label="Last Update"
                        tooltip
                    />
                </Stack>
                {isArchiveFeatureEnabled && (
                    <>
                        <Divider />
                        <Stack direction="row" justifyItems="center">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isArchivePlayerActive}
                                            onChange={e => onArchivePlayerCheck(e.target.checked)}
                                        />
                                    }
                                    label="Enable history view"
                                />
                            </FormGroup>
                            {isArchiveMetadataLoading && <CircularProgress size={20} />}
                        </Stack>
                    </>
                )}
            </Stack>
        </CollapsibleCard>
    </Box>
)

export default AisSubscriptionCard
