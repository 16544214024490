import React from "react"
import RemoveIcon from "@mui/icons-material/Close"

export default function LayerType(props) {
    function styleToProperties(style, fillProp, fillOutlineProp) {
        let fillColor = style.find(paintType => paintType.name === fillProp)
        let outlineColor = style.find(paintType => paintType.name === fillOutlineProp)

        return {
            backgroundColor: fillColor ? fillColor.value : "transparent",
            expressionType: fillColor.expressionType,
            outlineColor: outlineColor ? outlineColor.value : "transparent",
            type: style.type,
        }
    }

    function renderLayerType(type) {
        let properties = getColorProperties(type, props.layerProperties)

        let style = renderType(type, properties)
        if (properties.expressionType === "match") {
            style = addGradient(style)
        } else if (properties.expressionType === "interpolate") {
            style = addGradientInterpolate(style)
        }

        return (
            <div className="layer-type" style={style}>
                {type === "symbol" ? "A" : ""}
            </div>
        )
    }

    function renderType(type, properties) {
        switch (type) {
            case "fill":
                return styleFillLabel(properties)

            case "fill-extrusion":
                return styleFillExtrusionLabel(properties)

            case "circle":
                return styleCircleLabel(properties)

            case "line":
                return styleLineLabel(properties)

            case "symbol":
                return styleSymbolLabel(properties)
            case "raster":
                return styleFillLabel(properties)
            default:
                return null
        }
    }

    function getColorProperties(type, styleProperties) {
        switch (type) {
            case "fill":
                return styleToProperties(styleProperties, "fill-color", "fill-outline-color")

            case "fill-extrusion":
                return styleToProperties(styleProperties, "fill-extrusion-color", "fill-extrusion-color")

            case "circle":
                return styleToProperties(styleProperties, "circle-color", "circle-stroke-color")

            case "line":
                return styleToProperties(styleProperties, "line-color", "line-outline-color")

            case "symbol":
                return styleToProperties(styleProperties, "text-color", "icon-halo-color")
            case "raster":
                return styleToProperties(styleProperties, "fill-color", "fill-outline-color")
            default:
                return null
        }
    }

    function styleFillLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
        }
    }

    function styleFillExtrusionLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
        }
    }

    function styleCircleLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
            borderRadius: 16,
        }
    }

    function styleLineLabel(properties) {
        return {
            backgroundColor: properties.backgroundColor,
            border: `${properties.outlineColor} 1px solid`,
            height: 4,
        }
    }

    function styleSymbolLabel(properties) {
        return {
            backgroundColor: "transparent",
            color: properties.backgroundColor,
            fontSize: "22px",
            fontWeight: "bold",
            lineHeight: "16px",
        }
    }

    function addGradient(style) {
        let colors = []

        for (let i = 3; i < style.backgroundColor.length; i += 2) {
            colors.push(style.backgroundColor[i])
        }

        return {
            ...style,
            background: `linear-gradient(0.25turn,${colors.join(",")})`,
            border: "none",
            height: 16,
        }
    }

    function addGradientInterpolate(style) {
        let colors = []

        for (let i = 4; i < style.backgroundColor.length; i += 2) {
            colors.push(style.backgroundColor[i])
        }

        return {
            ...style,
            background: `linear-gradient(0.25turn,${colors.join(",")})`,
        }
    }

    return (
        <div className={props.selected ? "type selected" : "type"}>
            <div className="remove-button" onClick={props.onRemove}>
                <RemoveIcon />
            </div>
            {renderLayerType(props.type)}
        </div>
    )
}
