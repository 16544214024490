import makeStyles from "@mui/styles/makeStyles"

const PAGINATION_HEIGHT = "170px"

const useAttributeTableContainerStyles = makeStyles(theme => ({
    container: {
        height: "100%",
    },
    noData: {
        paddingLeft: "50%",
    },
    paperContainer: {
        borderRadius: 0,
        height: 500,
        outline: "none",
        position: "relative",
        width: "100%",
    },
    root: {
        height: "100%",
        width: "100%",
    },
    tableContainer: {
        height: `calc(100% - ${PAGINATION_HEIGHT})`,
        overflowY: "auto",
    },
}))

export default useAttributeTableContainerStyles
