import { reducer as toastrReducer } from "react-redux-toastr"
import { combineReducers } from "redux"
import coreReducer from "features/core/slice"
import infoboxReducer from "features/infobox/slice"
import mapToolsReducer from "features/mapTools/slice"
import authReducer from "../features/auth/slice"
import healthReducer from "../features/health/slice"
import aisReducer from "../reducers/ais"
import { appsReducer } from "../reducers/apps"
import { digitizeReducer } from "../reducers/digitize"
import featureFlagsReducer from "../reducers/featureFlags"
import layerSelectorReducer from "../reducers/layerSelector"
import { loginReducer } from "../reducers/login"
import mapReducer from "../reducers/map"
import metadataSchemaReducer from "../reducers/metadataSchema"
import { printReducer } from "../reducers/print"
import { sidebarAndDrawerReducer } from "../reducers/sidebarAndDrawer"
import { styleReducer } from "../reducers/style"
import baseAtlasApi from "./baseAtlasApi"

export const rootReducer = combineReducers({
    AIS: aisReducer,
    apps: appsReducer,
    auth: authReducer,
    [baseAtlasApi.reducerPath]: baseAtlasApi.reducer,
    core: coreReducer,
    digitize: digitizeReducer,
    featureFlags: featureFlagsReducer,
    health: healthReducer,
    infobox: infoboxReducer,
    layerSelector: layerSelectorReducer,
    login: loginReducer,
    map: mapReducer,
    mapTools: mapToolsReducer,
    metadataSchema: metadataSchemaReducer,
    print: printReducer,
    sidebarAndDrawer: sidebarAndDrawerReducer,
    style: styleReducer,
    toastr: toastrReducer, // <- Mounted at toastr.
})
