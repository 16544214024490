import * as React from "react";
const SvgEntrance11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "entrance-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 2.25 C4 1.8358 4.3358 1.5 4.75 1.5 C5.1642 1.5 5.5 1.8358 5.5 2.25 C5.5 2.6641998 5.1642 3 4.75 3 C4.3358 3 4 2.6642 4 2.25 z M9.27 4 L7.88 4 C7.6856 3.998 7.4985 4.0736 7.36 4.21 L3.3600001 8.21 C3.224 8.344 3.0409 8.4194 2.85 8.42 L1.73 8.42 C1.3268 8.42 1 8.7468 1 9.15 L1 9.15 C1 9.5532 1.3268 9.879999 1.73 9.879999 L3.62 9.879999 C3.8109 9.879399 3.994 9.803999 4.13 9.669999 L8.13 5.669999 C8.2644 5.5576 8.4348 5.4972 8.61 5.5 L9.2699995 5.5 C9.6732 5.5 10 5.1732 10 4.77 L10 4.77 C10.0221 4.3674 9.7137 4.0232 9.3111 4.0011 C9.2974 4.0003 9.2837 4 9.27 4 z M4.75 3.5 C4.3358 3.5 4 3.8358 4 4.25 L4 6 L5.5 4.5 L5.5 4.25 C5.5 3.8358 5.1642 3.5 4.75 3.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 2.25 C4 1.8358 4.3358 1.5 4.75 1.5 C5.1642 1.5 5.5 1.8358 5.5 2.25 C5.5 2.6641998 5.1642 3 4.75 3 C4.3358 3 4 2.6642 4 2.25 z M9.27 4 L7.88 4 C7.6856 3.998 7.4985 4.0736 7.36 4.21 L3.3600001 8.21 C3.224 8.344 3.0409 8.4194 2.85 8.42 L1.73 8.42 C1.3268 8.42 1 8.7468 1 9.15 L1 9.15 C1 9.5532 1.3268 9.879999 1.73 9.879999 L3.62 9.879999 C3.8109 9.879399 3.994 9.803999 4.13 9.669999 L8.13 5.669999 C8.2644 5.5576 8.4348 5.4972 8.61 5.5 L9.2699995 5.5 C9.6732 5.5 10 5.1732 10 4.77 L10 4.77 C10.0221 4.3674 9.7137 4.0232 9.3111 4.0011 C9.2974 4.0003 9.2837 4 9.27 4 z M4.75 3.5 C4.3358 3.5 4 3.8358 4 4.25 L4 6 L5.5 4.5 L5.5 4.25 C5.5 3.8358 5.1642 3.5 4.75 3.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgEntrance11;
