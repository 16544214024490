import makeStyles from "@mui/styles/makeStyles"

const useAttributeRowStyles = makeStyles(theme => ({
    cell: {
        borderRight: "1px solid lightgrey",
        cursor: "pointer",
        maxWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
}))

export default useAttributeRowStyles
