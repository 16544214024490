import { useRef } from "react"
import {
    AccountInfo,
    AuthenticationResult,
    BrowserAuthError,
    ClientAuthError,
    ClientAuthErrorMessage,
    InteractionRequiredAuthError,
} from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import dayjs from "dayjs"
import jwt_decode from "jwt-decode"
import config from "config"

const isExpired = (date: Date | null) => {
    if (!date) return true

    const currentTime = new Date()
    const msDifference = 30000

    return currentTime.getTime() > date.getTime() - msDifference
}

const getExpiresOnDate = (token: string) => {
    const jwtProperties: any = jwt_decode(token)

    return dayjs(jwtProperties.exp * 1000).toDate()
}

const useAuthentication = () => {
    const { instance } = useMsal()
    const activeAccount = instance.getActiveAccount()
    const tokenRef = useRef<string | null>(null)
    const tokenExpiresOnRef = useRef<Date | null>(null)

    async function acquireToken() {
        let response = null
        try {
            response = await instance.acquireTokenSilent({
                account: activeAccount as AccountInfo,
                scopes: config.loginRequest.scopes,
            })
        } catch (error) {
            console.error("Authentication error: ", error)
            if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
                await instance.acquireTokenRedirect({
                    account: activeAccount as AccountInfo,
                    scopes: config.loginRequest.scopes,
                })
            }
            if (
                error instanceof ClientAuthError &&
                error.errorCode === ClientAuthErrorMessage.multipleMatchingTokens.code
            ) {
                localStorage.clear()
                await instance.acquireTokenRedirect({
                    account: activeAccount as AccountInfo,
                    scopes: config.loginRequest.scopes,
                })
            }
            throw error
        }
        return response
    }

    const getCurrentOrRefreshToken = async () => {
        if (tokenRef.current !== null && !isExpired(tokenExpiresOnRef.current)) {
            return tokenRef.current
        }

        const response = await acquireToken()
        if (response !== null) {
            tokenRef.current = response.accessToken
            tokenExpiresOnRef.current = getExpiresOnDate(response.accessToken)
            // map component still uses token from redux
            //dispatch(setToken(response.accessToken));

            return response.accessToken
        }
        return ""
    }

    const getUserEmail = () => (activeAccount?.idTokenClaims?.["email"] as string) ?? ""

    return {
        getAccessToken: getCurrentOrRefreshToken,
        getUserEmail,
        logoutRedirect: (): void => {
            instance.logoutRedirect()
        },
    }
}

export default useAuthentication
