import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getDigitizeLayers, getLayerFeaturesDictSelector } from "selectors/digitizeSelectors"

// TODO: define types for the digitize feature
export const useDigitizeFeatures = () => {
    const layers = useSelector(getDigitizeLayers)

    const layerFeaturesDict = useSelector(getLayerFeaturesDictSelector)

    const digitizeFeatures = useMemo(() => {
        const features: any[] = []
        layers.forEach((layer: any) => {
            const layerFeatures = layerFeaturesDict[layer.id]
            layerFeatures.forEach((feature: any) => {
                const newFeature = { ...feature, properties: { ...feature.properties, hidden: !layer.selected } }
                layer.properties.forEach((property: any) => {
                    newFeature.properties[property.name] = property.value
                })
                newFeature.properties.customStyle = true
                features.push(newFeature)
            })
        })
        return features
    }, [layers, layerFeaturesDict])

    return digitizeFeatures
}
