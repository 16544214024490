import { useCallback, useEffect, useState } from "react"
import { AppLayer } from "model/app/AppLayer"
import { useParams } from "react-router-dom"
import { Popup } from "@emblautec/react-map-gl"
import { getMapClickPos } from "selectors/mapSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import useOnMapClick from "../../hooks/useOnMapClick"
import { getInfoboxes } from "../../selectors"
import DefaultInfobox from "../DefaultInfobox/DefaultInfobox"
import EsoxInfobox from "../EsoxInfobox/EsoxInfobox"

const InfoboxPopup = () => {
    const [selectedFeatures, setSelectedFeatures] = useState<mapboxgl.MapboxGeoJSONFeature[]>([])
    const [selectedRasters, setSelectedRasters] = useState<AppLayer[]>([])
    const [sourceToNameMapping, setSourceToNameMapping] = useState<Record<string, string>>({})

    const [infoboxToUse, setInfoboxToUse] = useState("default")
    const [isInfoboxOpen, setIsInfoboxOpen] = useState(false)
    const infoboxes = useAppSelector(getInfoboxes)

    const mapClickPos = useAppSelector(getMapClickPos)
    const { appId } = useParams<{ appId: string }>()

    useEffect(() => {
        setIsInfoboxOpen(false)
    }, [appId])

    const onMapClick = useCallback(
        (
            features: mapboxgl.MapboxGeoJSONFeature[],
            rasters: AppLayer[],
            sourceToNameMapping: Record<string, string>,
        ) => {
            const getInfoboxToUse = () => {
                if (rasters.length > 0) return "default"

                const firstFeature = features[0]
                const datasetId = firstFeature.sourceLayer

                return (
                    infoboxes.find(
                        infobox =>
                            (!infobox.applicationId || infobox.applicationId === appId) &&
                            infobox.datasetId === datasetId,
                    )?.name ?? "default"
                )
            }

            setSelectedFeatures(features)
            setSelectedRasters(rasters)
            setSourceToNameMapping(sourceToNameMapping)

            setInfoboxToUse(getInfoboxToUse())
            setIsInfoboxOpen(true)
        },
        [appId, infoboxes],
    )

    const onNoData = useCallback(() => setIsInfoboxOpen(false), [])

    useOnMapClick(onMapClick, onNoData)

    const renderInfobox = () => {
        switch (infoboxToUse) {
            case "esox":
                const firstFeature = selectedFeatures[0]
                return (
                    <EsoxInfobox
                        landSeaMask={parseFloat(firstFeature.properties?.lsm)}
                        lat={parseFloat(firstFeature.properties?.latitude)}
                        lng={parseFloat(firstFeature.properties?.longitude)}
                    />
                )
            default:
                return (
                    <DefaultInfobox
                        features={selectedFeatures}
                        lngLat={mapClickPos}
                        rasters={selectedRasters}
                        sourceToNameMapping={sourceToNameMapping}
                    />
                )
        }
    }

    if (!isInfoboxOpen || !mapClickPos) return null

    return (
        <Popup
            latitude={mapClickPos.lat}
            longitude={mapClickPos.lng}
            maxWidth={"400px"}
            onClose={() => setIsInfoboxOpen(false)}
        >
            {renderInfobox()}
        </Popup>
    )
}

export default InfoboxPopup
