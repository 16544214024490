import React from "react"
import { useCanvas } from "../../hooks/useCanvas"

type PieChartCanvasProps = {
    centerFillPercentage: number
    colors: Array<string>
    height: number
    labels: Array<string>
    style: Partial<PieChartCanvasStyleProps>
    width: number
}

type PieChartCanvasStyleProps = {
    fontSize: number
    lineEndSegmentWidth: number
    strokeColor: string
    strokeWidth: number
    textMargin: number
}

const defaultStyleProps: PieChartCanvasStyleProps = {
    fontSize: 16,
    lineEndSegmentWidth: 4,
    strokeColor: "black",
    strokeWidth: 0,
    textMargin: 4,
}

const PieChartWithLabelsCanvas = ({
    centerFillPercentage = 0.0,
    colors = [],
    height = 200,
    labels = [],
    style,
    width = 300,
}: PieChartCanvasProps) => {
    const angleSegement = (2.0 * Math.PI) / colors.length
    let pieWidth = width / 6
    let pieHeight = height / 6

    let styleMerged = { ...defaultStyleProps, ...style }

    let radius = Math.max(width / 2, height / 2)

    const minTextRadiusScale = 0.8
    const maxTextRadiusScale = 1.8
    const minNumberOfSlices = 1
    const maxNumberOfSlices = 24

    const textRadiusScale =
        minTextRadiusScale +
        ((maxTextRadiusScale - minTextRadiusScale) / (maxNumberOfSlices - minNumberOfSlices)) *
            (colors.length - minNumberOfSlices)

    let pieRadius = Math.min(pieWidth / 1.6, pieHeight / 1.6)
    let textRadius = Math.min(pieWidth * textRadiusScale, pieHeight * textRadiusScale)

    const draw = (context: CanvasRenderingContext2D) => {
        if (!canvasRef?.current) {
            return
        }

        context.clearRect(0, 0, width, height)

        let startAngle = 0

        for (let i = 0; i < colors.length; i++) {
            let color = colors[i]
            let endAngle = startAngle + angleSegement

            renderPieSlice(context, startAngle, endAngle, color)
            let label = labels[labels.length - i - 1]
            let textAngle = startAngle + angleSegement / 2 + 0.5 * Math.PI

            renderPieSliceLabel(context, textAngle, label)
            startAngle += angleSegement
        }

        renderPieCenter(context, centerFillPercentage)
    }

    const renderPieSlice = (context: CanvasRenderingContext2D, startAngle: number, endAngle: number, color: string) => {
        context.beginPath()
        //Move to the center of the canvas
        context.moveTo(width / 2, height / 2)
        //Arc from startAngle to endAngle with pie radius
        context.arc(width / 2, height / 2, pieRadius, startAngle, endAngle)

        context.closePath()

        context.fillStyle = color
        context.fill()

        context.strokeStyle = styleMerged.strokeColor
        context.lineWidth = styleMerged.strokeWidth

        context.stroke()
    }

    const renderPieSliceLabel = (context: CanvasRenderingContext2D, angle: number, text: string) => {
        let radiusWidth = width / 2
        let radiusHeight = height / 2

        //Calculate x,y on the pie circle
        let circleBorderX = pieRadius * parseFloat(Math.sin(angle).toFixed(3)) + radiusWidth
        let circleBorderY = pieRadius * parseFloat(Math.cos(angle).toFixed(3)) + radiusHeight

        //Calculate x,y on the text circle
        let circleOuterBorderX = textRadius * parseFloat(Math.sin(angle).toFixed(3)) + radiusWidth
        let circleOuterBorderY = textRadius * parseFloat(Math.cos(angle).toFixed(3)) + radiusHeight

        context.strokeStyle = "#d1d1d1"
        context.lineWidth = 2

        context.beginPath()
        context.moveTo(circleBorderX, circleBorderY)

        context.lineTo(circleOuterBorderX, circleOuterBorderY)
        context.stroke()

        context.beginPath()
        context.moveTo(circleOuterBorderX, circleOuterBorderY)

        let lineEndSegment = circleBorderX

        if (circleOuterBorderX < radius) {
            lineEndSegment = circleOuterBorderX - styleMerged.lineEndSegmentWidth
        }
        if (circleOuterBorderX > radius) {
            lineEndSegment = circleOuterBorderX + styleMerged.lineEndSegmentWidth
        }

        context.lineTo(lineEndSegment, circleOuterBorderY)
        context.stroke()

        context.moveTo(lineEndSegment, circleOuterBorderY)

        context.font = styleMerged.fontSize + "px Arial"

        let textMeasure = context.measureText(text)

        let textX = lineEndSegment - textMeasure.width / 2

        if (lineEndSegment < radius) {
            textX = lineEndSegment - (textMeasure.width + styleMerged.textMargin)
        }
        if (lineEndSegment > radius) {
            textX = lineEndSegment + styleMerged.textMargin
        }

        let textYmargin = lineEndSegment != radius ? 0 : textMeasure.actualBoundingBoxAscent

        let textYmarginSign = Math.sign(Math.cos(angle))

        let textY = circleOuterBorderY + textMeasure.actualBoundingBoxAscent / 2 + textYmarginSign * textYmargin

        context.fillStyle = "#545f69"
        context.fillText(text, textX, textY)
    }

    const renderPieCenter = (context: CanvasRenderingContext2D, centerRatio: number) => {
        //Clamp center ratio, to deal with values > 0.9 and values < 0
        centerRatio = clamp(centerRatio, 0, 0.9)

        context.beginPath()

        //Arc 2 * PI from the pie center with center size radius to form a complete circle
        context.arc(width / 2, height / 2, pieRadius * centerRatio, 0, 2 * Math.PI)

        context.closePath()

        context.fillStyle = "white"
        context.fill()

        context.strokeStyle = styleMerged.strokeColor
        context.lineWidth = styleMerged.strokeWidth

        context.stroke()
    }

    const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max)

    const onClick = () => {}

    const canvasRef = useCanvas(draw)

    return <canvas height={height} ref={canvasRef} width={width} onClick={onClick} />
}

export default PieChartWithLabelsCanvas
