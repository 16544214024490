import * as React from "react";
const SvgDoctor11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "doctor-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 5.87 C9.5017 5.0526 8.8404 4.3887 8.023 4.387 C7.2056 4.3853 6.5417 5.0466 6.54 5.864 C6.5386 6.54 6.9955 7.131 7.65 7.3 L7.65 7.7200003 C7.65 8.7445 6.8195 9.575001 5.795 9.575001 C4.7705 9.575001 3.94 8.7445 3.94 7.72 L3.94 7.72 C3.9776 6.8275 4.3787 5.9893 5.05 5.4 L5 5.4 C5.718 4.9316 6.1563997 4.1372004 6.17 3.2800002 L6.17 1.79 C6.17 1.177 5.6731 0.67999995 5.06 0.67999995 C5.0566998 0.67999995 5.0533 0.67999995 5.0499997 0.67999995 L4.5 0.67999995 C4.2957 0.67999995 4.13 0.84569997 4.13 1.05 C4.13 1.2542999 4.2957 1.42 4.5 1.42 L5.05 1.42 C5.2543 1.42 5.42 1.5856999 5.42 1.79 L5.42 3.28 L5.42 3.28 C5.42 4.2978 4.5978003 5.1245 3.58 5.13 L3.58 5.4 L3.58 5.4 L3.58 5.13 C2.5583 5.1355 1.7255 4.3117 1.72 3.29 C1.72 3.2867 1.72 3.2833 1.72 3.28 L1.72 3.28 L1.72 1.79 C1.72 1.5856999 1.8857 1.42 2.0900002 1.42 L2.0900002 1.42 L2.6100001 1.42 C2.8143 1.42 2.98 1.2543 2.9799001 1.0498999 C2.9799 0.8456 2.8143 0.68 2.61 0.68 L2.09 0.68 C1.4848 0.6909 0.9999 1.1847 1 1.79 L1 3.28 C0.9978 4.1241 1.4086 4.9158 2.1 5.4 L2.1 5.4 C2.7675998 5.991 3.165 6.829 3.1999998 7.7200003 C3.1999998 9.1532 4.3617997 10.315001 5.795 10.315001 C7.2282004 10.315001 8.39 9.1532 8.39 7.72 L8.39 7.3 C9.0424 7.1316 9.4986 6.5438 9.5 5.87 z M8 6.61 C7.5913 6.61 7.26 6.2787004 7.26 5.87 C7.26 5.4612994 7.5913 5.13 8 5.13 C8.4087 5.13 8.74 5.4613 8.74 5.87 L8.74 5.87 C8.74 6.2787 8.4087 6.61 8 6.61 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.5 5.87 C9.5017 5.0526 8.8404 4.3887 8.023 4.387 C7.2056 4.3853 6.5417 5.0466 6.54 5.864 C6.5386 6.54 6.9955 7.131 7.65 7.3 L7.65 7.7200003 C7.65 8.7445 6.8195 9.575001 5.795 9.575001 C4.7705 9.575001 3.94 8.7445 3.94 7.72 L3.94 7.72 C3.9776 6.8275 4.3787 5.9893 5.05 5.4 L5 5.4 C5.718 4.9316 6.1563997 4.1372004 6.17 3.2800002 L6.17 1.79 C6.17 1.177 5.6731 0.67999995 5.06 0.67999995 C5.0566998 0.67999995 5.0533 0.67999995 5.0499997 0.67999995 L4.5 0.67999995 C4.2957 0.67999995 4.13 0.84569997 4.13 1.05 C4.13 1.2542999 4.2957 1.42 4.5 1.42 L5.05 1.42 C5.2543 1.42 5.42 1.5856999 5.42 1.79 L5.42 3.28 L5.42 3.28 C5.42 4.2978 4.5978003 5.1245 3.58 5.13 L3.58 5.4 L3.58 5.4 L3.58 5.13 C2.5583 5.1355 1.7255 4.3117 1.72 3.29 C1.72 3.2867 1.72 3.2833 1.72 3.28 L1.72 3.28 L1.72 1.79 C1.72 1.5856999 1.8857 1.42 2.0900002 1.42 L2.0900002 1.42 L2.6100001 1.42 C2.8143 1.42 2.98 1.2543 2.9799001 1.0498999 C2.9799 0.8456 2.8143 0.68 2.61 0.68 L2.09 0.68 C1.4848 0.6909 0.9999 1.1847 1 1.79 L1 3.28 C0.9978 4.1241 1.4086 4.9158 2.1 5.4 L2.1 5.4 C2.7675998 5.991 3.165 6.829 3.1999998 7.7200003 C3.1999998 9.1532 4.3617997 10.315001 5.795 10.315001 C7.2282004 10.315001 8.39 9.1532 8.39 7.72 L8.39 7.3 C9.0424 7.1316 9.4986 6.5438 9.5 5.87 z M8 6.61 C7.5913 6.61 7.26 6.2787004 7.26 5.87 C7.26 5.4612994 7.5913 5.13 8 5.13 C8.4087 5.13 8.74 5.4613 8.74 5.87 L8.74 5.87 C8.74 6.2787 8.4087 6.61 8 6.61 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDoctor11;
