import axios from "axios"
import config from "../config"

export const coreApiClient = axios.create({
    baseURL: config.coreApiUrl,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 300000,
    withCredentials: false,
})
