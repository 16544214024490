import { createSelector } from "reselect"
import { createParameterizedSelector, forwardArg } from "./common"

export const getDigitizeEditingStatus = state => state.digitize.editing
export const getDigitizeStateInfoHelp = state => state.digitize.infoHelp
export const getDigitizeLayers = state => state.digitize.layers
export const getSelectedFeature = state => state.digitize.getSelectedFeature
export const getDigitizeFeatures = state => state.digitize.features
export const getEditing = state => state.digitize.editing

export const getDigitizeLayersForDownloadSelector = createParameterizedSelector(
    [getDigitizeLayers, forwardArg],
    (layers, downloadAll) => (downloadAll ? layers : layers.filter(layer => layer.selected)),
)

export const getNumberOfSelectedDigitizeLayersSelector = createSelector(
    getDigitizeLayers,
    layers => layers.filter(layer => layer.selected).length,
)

export const getDigitizeFeaturesForLayerSelector = createParameterizedSelector(
    [getDigitizeFeatures, forwardArg],
    (digitizeFeatures, layerId) =>
        digitizeFeatures.filter(digitizeFeature => digitizeFeature.properties.layerId === layerId),
)

export const getLayerFeaturesDictSelector = createSelector(getDigitizeFeatures, features =>
    features.reduce((acc, feat) => {
        const layerId = feat.properties.layerId
        if (!acc[layerId]) acc[layerId] = [feat]
        else acc[layerId].push(feat)

        return acc
    }, {}),
)

export const getDigitizeLayersVisibilityMapSelector = createSelector(getDigitizeLayers, digitizeLayers => {
    return digitizeLayers.reduce((acc, layer) => {
        acc[layer.id] = layer.selected
        return acc
    }, {})
})
