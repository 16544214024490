import { makeStyles } from "@mui/styles"

export const useLegendEntryStyles = makeStyles(() => ({
    dividerSpacing: {
        marginBottom: 8,
        marginTop: 8,
    },
    labelsContainer: {
        marginTop: 4,
        maxHeight: "45vh",
        overflow: "auto",
    },
    labelText: {
        flexGrow: 1,
        fontSize: 14,
        marginLeft: 8,
        textTransform: "capitalize",
    },
    multiLabelEntryContainer: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        paddingLeft: 8,
    },
    multiLabelHeaderText: {
        fontSize: 14,
        marginLeft: 16,
        paddingBottom: 4,
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 14,
        lineHeight: "14px",
        textTransform: "capitalize",
    },
    singleLabelEntryContainer: {
        alignItems: "center",
        display: "flex",
        padding: 16,
        paddingBottom: 8,
        paddingTop: 12,
    },
}))
