import { makeStyles } from "@mui/styles"

const useZanderSheetStyles = makeStyles(theme => ({
    percentiles: {
        justifyContent: "center",
    },
    sectionCard: {
        borderRadius: 8,
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        marginBottom: 16,
        padding: 20,
    },
    sheet: {
        padding: 20,
    },
    slider: {
        paddingBottom: 20,
        width: "95%",
    },
    sliderContainer: {
        display: "flex",
        justifyContent: "center",
    },
    spacingBottom: {
        paddingBottom: 16,
    },
    spacingTop: {
        paddingTop: 16,
    },
}))

export default useZanderSheetStyles
