import React, { useState } from "react"
import { Close } from "@mui/icons-material"
import { Grid, IconButton, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
    closeButton: {
        color: "#fff",
        position: "absolute",
        right: 5,
        top: 5,
    },
    content: {
        padding: theme.spacing(1),
    },
    headerText: {
        alignItems: "center",
        color: "#fff",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: 0,
        padding: 0,
    },
    modalHeader: {
        backgroundColor: "#024F79",
        borderRadius: "5px 5px 0 0",
        color: "#fff",
        padding: theme.spacing(1),
        position: "relative",
        textAlign: "center",
    },
    root: {
        backgroundColor: "#fff",
        borderRadius: "5px 5px 0 0",
        left: "50%",
        maxWidth: 200,
        position: "absolute",
        top: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 1,
    },
    text: {
        fontSize: "0.85rem",
    },
}))

const MapHelper = ({ modal = false }) => {
    const classes = useStyles()
    const [close, setClose] = useState(false)

    const handleClose = () => {
        setClose(true)
    }

    if (close) return null

    return (
        <Grid className={classes.root} container id="map-helper">
            <Grid className={classes.modalHeader} item xs={12}>
                <Typography className={classes.headerText}>Info</Typography>
                <IconButton className={classes.closeButton} size="small" onClick={handleClose}>
                    <Close />
                </IconButton>
            </Grid>
            <Grid className={classes.content} item xs={12}>
                <Typography className={classes.text}>Zoom in/out and pan around to adjust the map view.</Typography>
            </Grid>
        </Grid>
    )
}

export default MapHelper
