import * as React from "react";
const SvgPost11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "post-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 L10 9 C10 9.5522995 9.5523 10 9 10 L2 10 C1.4477 10 1 9.5523 1 9 L1 5.5 C1 5.2239 1.2239 5 1.5 5 C1.5692 4.9848 1.6408 4.9848 1.71 5 L1.71 5 L5.5 7 L9.3 5 L9.3 5 C9.366 4.9862 9.434 4.9862 9.5 5 C9.7761 5 10 5.2239 10 5.5 z M1.25 2.92 L1.25 2.92 L1.33 3 L5.5 5 L9.690001 3 L9.690001 3 L9.750001 3 L9.750001 3 C9.9296 2.9019 10.0292 2.7025 10 2.5 C10 2.2239 9.7761 2 9.5 2 L1.5 2 C1.2239 2 1 2.2239 1 2.5 C1.0026 2.6745 1.0978 2.8345 1.25 2.92 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 L10 9 C10 9.5522995 9.5523 10 9 10 L2 10 C1.4477 10 1 9.5523 1 9 L1 5.5 C1 5.2239 1.2239 5 1.5 5 C1.5692 4.9848 1.6408 4.9848 1.71 5 L1.71 5 L5.5 7 L9.3 5 L9.3 5 C9.366 4.9862 9.434 4.9862 9.5 5 C9.7761 5 10 5.2239 10 5.5 z M1.25 2.92 L1.25 2.92 L1.33 3 L5.5 5 L9.690001 3 L9.690001 3 L9.750001 3 L9.750001 3 C9.9296 2.9019 10.0292 2.7025 10 2.5 C10 2.2239 9.7761 2 9.5 2 L1.5 2 C1.2239 2 1 2.2239 1 2.5 C1.0026 2.6745 1.0978 2.8345 1.25 2.92 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPost11;
