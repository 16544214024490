import { toastr, actions as toastrActions } from "react-redux-toastr"
import { bindActionCreators } from "redux"

const defaultOptions = { position: "bottom-right" }

const customToastr = {
    confirm: (text, opt) => toastr.confirm(text, opt),
    error: (text, opt = defaultOptions) => toastr.error("", text, opt),
    info: (text, opt = defaultOptions) => toastr.info("", text, opt),
    success: (text, opt = defaultOptions) => toastr.success("", text, opt),
    warning: (text, opt = defaultOptions) => toastr.warning("", text, opt),
}

export const createManagedToastr = dispatch => {
    const managedToastr = bindActionCreators(toastrActions, dispatch)

    return {
        info: (id, text, options = defaultOptions) =>
            managedToastr.add({
                id,
                message: text,
                options: { timeOut: 0 },
                position: options.position,
                title: "",
                type: "info",
            }),
        remove: id => managedToastr.remove(id),
    }
}

export default customToastr
