import React from "react"
import RemoveIcon from "@mui/icons-material/Close"
import { Box, Checkbox } from "@mui/material"
import Typography from "@mui/material/Typography"
import TitleTextField from "../common/TitleTextfield/TitleTextField"

function BooleanProperty({
    editable,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) {
    function handleChange(e) {
        onPropertyChanged(e.target.checked)
    }
    const removable = onRemove && !isDefaultValue && !isLastRemainingCase

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {!editable && (
                    <Typography className="name" variant="body2">
                        {title}
                    </Typography>
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <div className="checkbox-container">
                    <Box display={"flex"} justifyContent="center" width="100%">
                        <Checkbox checked={value} value={value} variant="filled" onChange={handleChange} />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default BooleanProperty
