import { useState } from "react"
import { Cancel, Delete, Edit, Save } from "@mui/icons-material"
import { Grid, IconButton, TextField, Typography } from "@mui/material"

const EditFeature = ({
    classes,
    deleteLayerFeature,
    deselectAllFeaturesAndLabels,
    editLayerFeature,
    feature,
    setSelectedFeatures,
    onFeatureClick,
}) => {
    const [editMode, setEditMode] = useState(false)
    const [editedText, setEditedText] = useState("")

    const handleEdit = e => {
        e.stopPropagation()
        setEditMode(true)
        setEditedText(feature.properties.name)

        deselectAllFeaturesAndLabels()
    }

    const handleSave = newName => {
        editLayerFeature(feature, newName)
        setEditMode(false)
        setEditedText("")

        deselectAllFeaturesAndLabels()
    }

    const handleEditMode = e => {
        if (e.keyCode === 13) {
            handleSave(editedText)
        } else if (e.keyCode === 27) {
            setEditMode(false)
            setEditedText("")
        }
    }

    const handleCancelEditMode = () => {
        setEditMode(false)
        setEditedText("")
        setSelectedFeatures([])
        deselectAllFeaturesAndLabels()
    }

    return (
        <Grid alignItems="center" className={classes.featureContainer} container paddingX={2}>
            <Grid
                className={classes.editFieldContainer}
                item
                xs={9}
                onClick={e => !editMode && onFeatureClick(e, feature)}
            >
                {!editMode ? (
                    <Typography className={classes.featureName} variant="body2">
                        {feature.properties.name ? feature.properties.name : feature.geometry.type}
                    </Typography>
                ) : (
                    <TextField
                        className={classes.textField}
                        id="digitize-edit-feature-name"
                        placeholder="Feature name"
                        size="small"
                        value={editedText}
                        onChange={e => setEditedText(e.target.value)}
                        onKeyDown={e => handleEditMode(e, feature)}
                    />
                )}
            </Grid>
            <Grid className={classes.editFieldButtons} item xs={3}>
                {editMode ? (
                    <IconButton className={classes.iconButton} size="small" onClick={() => handleSave(editedText)}>
                        <Save />
                    </IconButton>
                ) : (
                    <IconButton className={classes.iconButton} size="small" onClick={e => handleEdit(e)}>
                        <Edit />
                    </IconButton>
                )}
                {editMode ? (
                    <IconButton className={classes.iconButton} size="small" onClick={handleCancelEditMode}>
                        <Cancel />
                    </IconButton>
                ) : (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={e => deleteLayerFeature(e, feature)}
                    >
                        <Delete />
                    </IconButton>
                )}
            </Grid>
        </Grid>
    )
}

export default EditFeature
