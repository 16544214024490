import React from "react"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    root: {
        position: "absolute",
        textAlign: "center",
        top: 10,
        width: "100%",
        zIndex: 1,
    },
    rootLegend: {
        position: "relative",
    },
    title: {
        backgroundColor: "rgba(255,255,255,0.75)",
        borderRadius: 5,
        left: 0,
        margin: "auto",
        maxWidth: "15%",
        padding: 10,
        right: 0,
    },
    titleLegend: {
        color: "#fff",
        fontSize: ({ orientation }) => (orientation === "portrait" ? 11 : 18),
        fontWeight: "bold",
        margin: "auto",
        maxWidth: "100%",
        padding: 4,
        textAlign: "center",
    },
}))

const MapTitle = ({ mapTitle, modal = false, orientation = "portrait" }) => {
    const classes = useStyles({ orientation })
    return (
        <div className={`${modal ? classes.rootLegend : classes.root}`} id="map-title">
            <Typography className={`${modal ? classes.titleLegend : classes.title}`}>{mapTitle}</Typography>
        </div>
    )
}

export default MapTitle
