import * as React from "react";
const SvgSuitcase15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "suitcase-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 4 L11 2 C11 1 10 1 10 1 L5.0497 1 C5.0497 1 3.9457998 1.0015 3.9999998 2 L3.9999998 4 L2 4 C2 4 1 4 1 5 L1 12 C1 13 2 13 2 13 L13 13 C13 13 14 13 14 12 L14 5 C14 4 13 4 13 4 L11 4 z M5.5 2.5 L9.5 2.5 L9.5 4 L5.5 4 L5.5 2.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 4 L11 2 C11 1 10 1 10 1 L5.0497 1 C5.0497 1 3.9457998 1.0015 3.9999998 2 L3.9999998 4 L2 4 C2 4 1 4 1 5 L1 12 C1 13 2 13 2 13 L13 13 C13 13 14 13 14 12 L14 5 C14 4 13 4 13 4 L11 4 z M5.5 2.5 L9.5 2.5 L9.5 4 L5.5 4 L5.5 2.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSuitcase15;
