import makeStyles from "@mui/styles/makeStyles"

export const useDigitizeStyles = makeStyles(() => ({
    content: {
        maxHeight: "100%",
    },
    digitizeToolsIcon: {
        "& .MuiListItemIcon-root": {
            fontSize: "0.865rem",
        },
        minWidth: 32,
    },
    digitizeToolsText: {
        "& .MuiListItemText-primary": {
            fontSize: "0.865rem",
        },
    },
    input: {
        display: "none",
    },
    layersContainer: {
        flex: 1,
        height: "calc(100% - 99px)",
        overflowY: "overlay",
        width: "100%",
    },
    marginRight: {
        marginRight: 8,
    },
    root: {
        backgroundColor: "#fff",
        height: "100%",
        overflow: "hidden",
        position: "relative",
        width: "100%",
    },
    stylerDivWrapper: { display: "flex", flexGrow: 1, overflow: "auto" },
    title: {
        backgroundColor: "#f5f5f5",
        padding: "8px 16px",
    },
}))
