import * as React from "react";
const SvgRailMetro11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-metro-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 0 C3 0 3 0.5 3 0.5 L2 5 L2 6 C2 7.0243998 3 7 3 7 L8 7 C8 7 9 7 9 6 L9 5 L8 0.5 C8 0.5 8 0 7.5 0 L3.5 0 z M4 1 L7 1 L7.5 4 L3.5 4 L4 1 z M3.5 5 C3.7761 5 4 5.2239 4 5.5 C4 5.7761 3.7761 6 3.5 6 C3.2239 6 3 5.7761 3 5.5 C3 5.2239 3.2239 5 3.5 5 z M5.25 5 L5.75 5 C5.8885 5 6 5.1115 6 5.25 C6 5.3885 5.8885 5.5 5.75 5.5 L5.25 5.5 C5.1115 5.5 5 5.3885 5 5.25 C5 5.1115 5.1115 5 5.25 5 z M7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 z M3 8 L2 11 L3.5 11 L3.834 10 L7.1660004 10 L7.5 11 L9 11 L8 8 L6.5 8 L6.834 9 L4.166 9 L4.5 8 L3 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 0 C3 0 3 0.5 3 0.5 L2 5 L2 6 C2 7.0243998 3 7 3 7 L8 7 C8 7 9 7 9 6 L9 5 L8 0.5 C8 0.5 8 0 7.5 0 L3.5 0 z M4 1 L7 1 L7.5 4 L3.5 4 L4 1 z M3.5 5 C3.7761 5 4 5.2239 4 5.5 C4 5.7761 3.7761 6 3.5 6 C3.2239 6 3 5.7761 3 5.5 C3 5.2239 3.2239 5 3.5 5 z M5.25 5 L5.75 5 C5.8885 5 6 5.1115 6 5.25 C6 5.3885 5.8885 5.5 5.75 5.5 L5.25 5.5 C5.1115 5.5 5 5.3885 5 5.25 C5 5.1115 5.1115 5 5.25 5 z M7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 z M3 8 L2 11 L3.5 11 L3.834 10 L7.1660004 10 L7.5 11 L9 11 L8 8 L6.5 8 L6.834 9 L4.166 9 L4.5 8 L3 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRailMetro11;
