import * as React from "react";
const SvgToilet15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "toilet-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 3 C3.6716 3 3 2.3284 3 1.5 C3 0.6716001 3.6716 0 4.5 0 C5.3283997 0 6 0.6716 6 1.5 C6 2.3284001 5.3284 3 4.5 3 z M14 1.5 C14 0.6716 13.3284 0 12.5 0 C11.6716 0 11 0.6716 11 1.5 C11 2.3284001 11.6716 3 12.5 3 C13.3284 3 14 2.3284 14 1.5 z M8.86 6.64 L8.86 6.64 L6.38 4.15 L6.38 4.15 C6.2798 4.0492 6.142 3.9949 6 4 L3 4 C2.8697 4.0003 2.7445 4.0503 2.65 4.14 L2.65 4.14 L0.14 6.63 C-0.0861 6.807 -0.12589999 7.1339 0.0511 7.36 C0.22809999 7.5861 0.555 7.6259003 0.78110003 7.4489 C0.8142 7.423 0.8441 7.3931 0.87 7.36 L3 5.2 L1 11 L3 11 L3 14.33 C2.9925 14.3797 2.9925 14.4303 3 14.48 C3.0555 14.7561 3.3244 14.934999 3.6005 14.879499 C3.802 14.839 3.9595 14.6815 4 14.48 L4 14.48 L4 11 L5 11 L5 14.5 L5 14.5 C5.0555 14.7761 5.3244 14.955 5.6005 14.8995 C5.802 14.859 5.9595 14.7015 6 14.5 C6.0075 14.4503 6.0075 14.3997 6 14.35 L6 11 L8 11 L6 5.2 L8.14 7.3599997 L8.14 7.3599997 C8.2367 7.4680996 8.374901 7.5299997 8.52 7.5299997 C8.7954 7.5088 9.0061 7.2761 9 7 C9.0023 6.8663 8.9521 6.737 8.86 6.64 z M14.5 4 L10.5 4 C10.2239 4 10 4.2239 10 4.5 L10 9.5 C10 9.7761 10.2239 10 10.5 10 C10.7761 10 11 9.7761 11 9.5 L11 14.5 C11 14.7761 11.2239 15 11.5 15 C11.7761 15 12 14.7761 12 14.5 L12 9.5 L13 9.5 L13 14.5 C13 14.7761 13.2239 15 13.5 15 C13.7761 15 14 14.7761 14 14.5 L14 9.5 C14 9.7761 14.2239 10 14.5 10 C14.7761 10 15 9.7761 15 9.5 L15 4.5 C15 4.2239 14.7761 4 14.5 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 3 C3.6716 3 3 2.3284 3 1.5 C3 0.6716001 3.6716 0 4.5 0 C5.3283997 0 6 0.6716 6 1.5 C6 2.3284001 5.3284 3 4.5 3 z M14 1.5 C14 0.6716 13.3284 0 12.5 0 C11.6716 0 11 0.6716 11 1.5 C11 2.3284001 11.6716 3 12.5 3 C13.3284 3 14 2.3284 14 1.5 z M8.86 6.64 L8.86 6.64 L6.38 4.15 L6.38 4.15 C6.2798 4.0492 6.142 3.9949 6 4 L3 4 C2.8697 4.0003 2.7445 4.0503 2.65 4.14 L2.65 4.14 L0.14 6.63 C-0.0861 6.807 -0.12589999 7.1339 0.0511 7.36 C0.22809999 7.5861 0.555 7.6259003 0.78110003 7.4489 C0.8142 7.423 0.8441 7.3931 0.87 7.36 L3 5.2 L1 11 L3 11 L3 14.33 C2.9925 14.3797 2.9925 14.4303 3 14.48 C3.0555 14.7561 3.3244 14.934999 3.6005 14.879499 C3.802 14.839 3.9595 14.6815 4 14.48 L4 14.48 L4 11 L5 11 L5 14.5 L5 14.5 C5.0555 14.7761 5.3244 14.955 5.6005 14.8995 C5.802 14.859 5.9595 14.7015 6 14.5 C6.0075 14.4503 6.0075 14.3997 6 14.35 L6 11 L8 11 L6 5.2 L8.14 7.3599997 L8.14 7.3599997 C8.2367 7.4680996 8.374901 7.5299997 8.52 7.5299997 C8.7954 7.5088 9.0061 7.2761 9 7 C9.0023 6.8663 8.9521 6.737 8.86 6.64 z M14.5 4 L10.5 4 C10.2239 4 10 4.2239 10 4.5 L10 9.5 C10 9.7761 10.2239 10 10.5 10 C10.7761 10 11 9.7761 11 9.5 L11 14.5 C11 14.7761 11.2239 15 11.5 15 C11.7761 15 12 14.7761 12 14.5 L12 9.5 L13 9.5 L13 14.5 C13 14.7761 13.2239 15 13.5 15 C13.7761 15 14 14.7761 14 14.5 L14 9.5 C14 9.7761 14.2239 10 14.5 10 C14.7761 10 15 9.7761 15 9.5 L15 4.5 C15 4.2239 14.7761 4 14.5 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgToilet15;
