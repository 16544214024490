import clsx from "clsx"
import { useStyles } from "./styles"

type Props = {
    circleColor?: string
    circleStrokeColor?: string
    circleStrokeWidth?: string
}

const PointIcon = ({ circleColor, circleStrokeColor, circleStrokeWidth }: Props) => {
    const { circle, legend, stroke } = useStyles({ circleColor, circleStrokeColor, circleStrokeWidth })
    return <div className={legend}>{circleColor && <div className={clsx(circle, circleStrokeColor && stroke)} />}</div>
}

export default PointIcon
