import { createSlice } from "@reduxjs/toolkit"
import { resetProjectData } from "actions/globalActions"
import { getFeatureFlags } from "../actions/featureFlags"

type SliceState = {
    AIS: boolean
    AIS_ARCHIVE: boolean
    DPR: boolean
    LAYER_FILTERS: boolean
    NOTIFICATIONS: boolean
}

const initialState: SliceState = {
    AIS: false,
    AIS_ARCHIVE: false,
    DPR: false,
    LAYER_FILTERS: false,
    NOTIFICATIONS: false,
}

export const featureFlagsSlice = createSlice({
    extraReducers: builder =>
        builder
            .addCase(getFeatureFlags.fulfilled, (state, { payload: featureFlags }) => {
                Object.keys(state).forEach(feature => {
                    // did this to support features that are only defined on the frontend
                    const featureNotDefinedOnBackend = featureFlags[feature] === undefined
                    if (featureNotDefinedOnBackend) return

                    // @ts-ignore
                    state[feature] = featureFlags[feature]
                })
            })
            .addCase(resetProjectData, () => initialState),
    initialState,
    name: "featureFlags",
    reducers: {},
})

export const {} = featureFlagsSlice.actions

export default featureFlagsSlice.reducer
