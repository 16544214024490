import * as React from "react";
const SvgReligiousJewish11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "religious-jewish-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 8 L7.1 8 L5.5 11 L3.9 8 L0 8 L1.95 5.5 L0 3 L3.9 3 L5.5 0 L7.1 3 L11 3 L9.05 5.5 L11 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 8 L7.1 8 L5.5 11 L3.9 8 L0 8 L1.95 5.5 L0 3 L3.9 3 L5.5 0 L7.1 3 L11 3 L9.05 5.5 L11 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgReligiousJewish11;
