import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    project: {
        color: "black",
        display: "flex",
        justifyContent: "space-between",
    },
    projectLogo: {
        height: 25,
        marginLeft: 35,
    },
    projectName: {
        alignItems: "center",
        display: "flex",
    },
    projectsPicker: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
        },
        alignItems: "center",
        display: "flex",
        height: "100% !important",
        justifyContent: "space-between",

        padding: theme.spacing(0, 4, 0, 2) + " !important",
    },
    projectsPickerWrapper: {
        "& .MuiInput-root": {
            height: "100%",
        },
        "& .MuiSelect-icon": {
            right: theme.spacing(1),
        },
        alignItems: "center",
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        borderRight: `1px solid ${theme.customColors.borderColor}`,
        display: "flex",

        height: "100%",

        marginLeft: theme.spacing(2),
    },
    selectProjectMenuItem: {
        display: "none",
    },
    selectProjectText: { color: theme.customColors.mutedColor },
}))
