import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#fff",
        overflow: "auto",
    },
    section: {
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        margin: "8px 20px",
    },
    title: {
        backgroundColor: theme.customColors.appBgColor,
        padding: "8px 16px",
    },
}))

export default useStyles
