import * as React from "react";
const SvgIogp1041 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "312.73, 217.97, 196.12997, 196.03", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("path", { d: "M314.93 322.14 L314.93 309.83 L314.73 315.98 L314.93 322.14 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.93 322.14 L315.52 328.27 L314.93 309.83 L314.93 322.14 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M315.52 328.27 L315.52 303.7 L314.93 309.83 L315.52 328.27 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M315.52 328.27 L316.51 334.35 L315.52 303.7 L315.52 328.27 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.51 334.35 L316.51 297.62 L315.52 303.7 L316.51 334.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.51 334.35 L317.88 340.35 L316.51 297.62 L316.51 334.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M317.88 340.35 L317.88 291.62 L316.51 297.62 L317.88 340.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M317.88 340.35 L319.63 346.25 L317.88 291.62 L317.88 340.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M319.63 346.25 L319.63 285.71 L317.88 291.62 L319.63 346.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M319.63 346.25 L321.76 352.03 L319.63 285.71 L319.63 346.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.76 352.03 L321.76 279.94 L319.63 285.71 L321.76 352.03 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.76 352.03 L324.25 357.66 L321.76 279.94 L321.76 352.03 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M324.25 357.66 L324.25 274.3 L321.76 279.94 L324.25 357.66 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M324.25 357.66 L327.1 363.12 L324.25 274.3 L324.25 357.66 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M327.1 363.12 L327.1 268.85 L324.25 274.3 L327.1 363.12 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M327.1 363.12 L330.29 368.39 L327.1 268.85 L327.1 363.12 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.29 368.39 L330.29 263.58 L327.1 268.85 L330.29 368.39 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.29 368.39 L333.81 373.44 L330.29 263.58 L330.29 368.39 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M333.81 373.44 L333.81 258.53 L330.29 263.58 L333.81 373.44 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M333.81 373.44 L337.65 378.25 L333.81 258.53 L333.81 373.44 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M337.65 378.25 L337.65 253.71 L333.81 258.53 L337.65 378.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M337.65 378.25 L341.79 382.81 L337.65 253.71 L337.65 378.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M341.79 382.81 L341.79 249.16 L337.65 253.71 L341.79 382.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M341.79 382.81 L346.21 387.1 L341.79 249.16 L341.79 382.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.21 387.1 L346.21 244.87 L341.79 249.16 L346.21 387.1 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.21 387.1 L350.9 391.09 L346.21 244.87 L346.21 387.1 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.9 391.09 L350.9 240.88 L346.21 244.87 L350.9 391.09 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.9 391.09 L355.84 394.77 L350.9 240.88 L350.9 391.09 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.84 394.77 L355.84 237.2 L350.9 240.88 L355.84 394.77 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.84 394.77 L361 398.13 L355.84 237.2 L355.84 394.77 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M361 398.13 L361 233.84 L355.84 237.2 L361 398.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M361 398.13 L366.36 401.15 L361 233.84 L361 398.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.36 401.15 L366.36 230.82 L361 233.84 L366.36 401.15 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.36 401.15 L371.91 403.82 L366.36 230.82 L366.36 401.15 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M371.91 403.82 L371.91 228.14 L366.36 230.82 L371.91 403.82 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M371.91 403.82 L377.62 406.13 L371.91 228.14 L371.91 403.82 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.62 406.13 L377.62 225.83 L371.91 228.14 L377.62 406.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M377.62 406.13 L383.46 408.07 L377.62 225.83 L377.62 406.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M383.46 408.07 L383.46 223.89 L377.62 225.83 L383.46 408.07 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M383.46 408.07 L389.42 409.64 L383.46 223.89 L383.46 408.07 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.42 409.64 L389.42 222.33 L383.46 223.89 L389.42 409.64 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.42 409.64 L395.46 410.81 L389.42 222.33 L389.42 409.64 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.46 410.81 L395.46 221.15 L389.42 222.33 L395.46 410.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.46 410.81 L401.57 411.6 L395.46 221.15 L395.46 410.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.57 411.6 L401.57 220.37 L395.46 221.15 L401.57 411.6 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.57 411.6 L407.72 412 L401.57 220.37 L401.57 411.6 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M407.72 412 L407.72 219.97 L401.57 220.37 L407.72 412 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M407.72 412 L413.87 412 L407.72 219.97 L407.72 412 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.87 412 L413.87 219.97 L407.72 219.97 L413.87 412 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.87 412 L420.02 411.6 L413.87 219.97 L413.87 412 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.02 411.6 L420.02 220.37 L413.87 219.97 L420.02 411.6 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.02 411.6 L426.13 410.81 L420.02 220.37 L420.02 411.6 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.13 410.81 L426.13 221.15 L420.02 220.37 L426.13 410.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.13 410.81 L432.17 409.64 L426.13 221.15 L426.13 410.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M432.17 409.64 L432.17 222.33 L426.13 221.15 L432.17 409.64 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M432.17 409.64 L438.13 408.07 L432.17 222.33 L432.17 409.64 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M438.13 408.07 L438.13 223.89 L432.17 222.33 L438.13 408.07 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M438.13 408.07 L443.97 406.13 L438.13 223.89 L438.13 408.07 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.97 406.13 L443.97 225.83 L438.13 223.89 L443.97 406.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.97 406.13 L449.68 403.82 L443.97 225.83 L443.97 406.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M449.68 403.82 L449.68 228.14 L443.97 225.83 L449.68 403.82 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M449.68 403.82 L455.23 401.15 L449.68 228.14 L449.68 403.82 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.23 401.15 L455.23 230.82 L449.68 228.14 L455.23 401.15 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.23 401.15 L460.59 398.13 L455.23 230.82 L455.23 401.15 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M460.59 398.13 L460.59 233.84 L455.23 230.82 L460.59 398.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M460.59 398.13 L465.75 394.77 L460.59 233.84 L460.59 398.13 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.75 394.77 L465.75 237.2 L460.59 233.84 L465.75 394.77 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.75 394.77 L470.69 391.09 L465.75 237.2 L465.75 394.77 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M470.69 391.09 L470.69 240.88 L465.75 237.2 L470.69 391.09 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M470.69 391.09 L475.38 387.1 L470.69 240.88 L470.69 391.09 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.38 387.1 L475.38 244.87 L470.69 240.88 L475.38 387.1 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.38 387.1 L479.8 382.81 L475.38 244.87 L475.38 387.1 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M479.8 382.81 L479.8 249.16 L475.38 244.87 L479.8 382.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M479.8 382.81 L483.94 378.25 L479.8 249.16 L479.8 382.81 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.94 378.25 L483.94 253.71 L479.8 249.16 L483.94 378.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.94 378.25 L487.78 373.44 L483.94 253.71 L483.94 378.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.78 373.44 L487.78 258.53 L483.94 253.71 L487.78 373.44 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.78 373.44 L491.3 368.39 L487.78 258.53 L487.78 373.44 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M491.3 368.39 L491.3 263.58 L487.78 258.53 L491.3 368.39 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M491.3 368.39 L494.5 363.12 L491.3 263.58 L491.3 368.39 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M494.5 363.12 L494.5 268.85 L491.3 263.58 L494.5 363.12 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M494.5 363.12 L497.34 357.66 L494.5 268.85 L494.5 363.12 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.34 357.66 L497.34 274.3 L494.5 268.85 L497.34 357.66 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.34 357.66 L499.84 352.03 L497.34 274.3 L497.34 357.66 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M499.84 352.03 L499.84 279.94 L497.34 274.3 L499.84 352.03 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M499.84 352.03 L501.96 346.25 L499.84 279.94 L499.84 352.03 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.96 346.25 L501.96 285.71 L499.84 279.94 L501.96 346.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.96 346.25 L503.72 340.35 L501.96 285.71 L501.96 346.25 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M503.72 340.35 L503.72 291.62 L501.96 285.71 L503.72 340.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M503.72 340.35 L505.09 334.35 L503.72 291.62 L503.72 340.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M505.09 334.35 L505.09 297.62 L503.72 291.62 L505.09 334.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M505.09 334.35 L506.07 328.27 L505.09 297.62 L505.09 334.35 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M506.07 328.27 L506.07 303.7 L505.09 297.62 L506.07 328.27 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M506.07 328.27 L506.66 322.14 L506.07 303.7 L506.07 328.27 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M506.66 322.14 L506.66 309.83 L506.07 303.7 L506.66 322.14 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("path", { d: "M506.66 322.14 L506.86 315.98 L506.66 309.83 L506.66 322.14 z", fillRule: "evenodd", style: {
  fill: "#A80084"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "314.73,315.98 314.93,309.83 315.52,303.7 316.51,297.62 317.88,291.62 319.63,285.71 321.76,279.94 324.25,274.3 327.1,268.85 330.29,263.58 333.81,258.53 337.65,253.71 341.79,249.16 346.21,244.87 350.9,240.88 355.84,237.2 361,233.84 366.36,230.82 371.91,228.14 377.62,225.83 383.46,223.89 389.42,222.33 395.46,221.15 401.57,220.37 407.72,219.97 413.87,219.97 420.02,220.37 426.13,221.15 432.17,222.33 438.13,223.89 443.97,225.83 449.68,228.14 455.23,230.82 460.59,233.84 465.75,237.2 470.69,240.88 475.38,244.87 479.8,249.16 483.94,253.71 487.78,258.53 491.3,263.58 494.5,268.85 497.34,274.3 499.84,279.94 501.96,285.71 503.72,291.62 505.09,297.62 506.07,303.7 506.66,309.83 506.86,315.98 506.66,322.14 506.07,328.27 505.09,334.35 503.72,340.35 501.96,346.25 499.84,352.03 497.34,357.66 494.5,363.12 491.3,368.39 487.78,373.44 483.94,378.25 479.8,382.81 475.38,387.1 470.69,391.09 465.75,394.77 460.59,398.13 455.23,401.15 449.68,403.82 443.97,406.13 438.13,408.07 432.17,409.64 426.13,410.81 420.02,411.6 413.87,412 407.72,412 401.57,411.6 395.46,410.81 389.42,409.64 383.46,408.07 377.62,406.13 371.91,403.82 366.36,401.15 361,398.13 355.84,394.77 350.9,391.09 346.21,387.1 341.79,382.81 337.65,378.25 333.81,373.44 330.29,368.39 327.1,363.12 324.25,357.66 321.76,352.03 319.63,346.25 317.88,340.35 316.51,334.35 315.52,328.27 314.93,322.14 314.73,315.98", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#A80084"
} }));
export default SvgIogp1041;
