export const digitizeLayerToStyle = (layer, layerNumber) => ({
    datasetId: layer.datasetId,
    layerId: layer.datasetId + ":" + layerNumber,
    layout: layer.layout,
    name: layer.name,
    paint: layer.paint,
    properties: layer.properties,
    sourceId: layer.datasetId,
    sourceName: layer.name,
    type: layer.styleType,
})

export const digitizeLayerTypeToGeometry = layerType => {
    const typeToGeometry = {
        circle: "POLYGON",
        line: "LINESTRING",
        point: "POINT",
        polygon: "POLYGON",
    }
    return typeToGeometry[layerType]
}

export const digitizeLayerToPartialAppLayer = digitizeLayer => {
    return {
        geometryType: digitizeLayerTypeToGeometry(digitizeLayer.type),
        name: digitizeLayer.name,
        resourceId: digitizeLayer.id,
        type: "vector",
    }
}
