import React from "react"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    notes: {
        backgroundColor: "rgba(255,255,255,0.90)",
        borderRadius: "5px 0 5px 0",
        bottom: 0,
        fontSize: "0.75rem",
        left: 0,
        margin: "auto",
        maxWidth: "13vw",
        padding: 6,
        right: 0,
    },
    notesLegend: {
        borderTop: ({ orientation }) => (orientation === "portrait" ? "0" : "1px solid grey"),
        fontSize: ({ orientation }) => (orientation === "portrait" ? 10 : 16),
        margin: "auto",
        maxWidth: "100%",
        padding: 4,
        textAlign: "center",
    },
    root: {
        bottom: 10,
        position: "absolute",
        textAlign: "center",
        width: "100%",
        zIndex: 1,
    },
    rootLegend: {
        position: "relative",
    },
}))

const MapNotes = ({ mapNotes, modal = false, orientation = "portrait" }) => {
    const classes = useStyles({ orientation })
    return (
        <div className={`${modal ? classes.rootLegend : classes.root}`} id="map-notes">
            <Typography className={`${modal ? classes.notesLegend : classes.notes}`}>{mapNotes}</Typography>
        </div>
    )
}

export default MapNotes
