import { AxiosRequestConfig } from "axios"
import dayjs, { Dayjs } from "dayjs"
import baseAtlasApi from "../../store/baseAtlasApi"
import { interpolateBuckets } from "./components/AisArchivePlayer/utils"
import { ArchiveData } from "./models/ArchiveData"
import { ArchiveMetadata } from "./models/ArchiveMetadata"

type FetchArchiveArgs = {
    bucketSizeInMinutes: number
    endDate: Dayjs
    interpolationFactor: number
    lookAheadInMinutes: number
    lookBehindInMinutes: number
    subscriptionId: string
    cancelToken?: AxiosRequestConfig["cancelToken"]
    startDate: Dayjs
}

const aisApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        fetchArchive: build.query<ArchiveData, FetchArchiveArgs>({
            query: ({
                bucketSizeInMinutes,
                endDate,
                lookAheadInMinutes,
                lookBehindInMinutes,
                subscriptionId,
                cancelToken,
                startDate,
            }) => ({
                method: "GET",
                params: {
                    bucketSizeInMinutes,
                    endDate: endDate.toISOString(),
                    lookAheadInMinutes,
                    lookBehindInMinutes,
                    startDate: startDate.toISOString(),
                },
                url: `ais/Archive/${subscriptionId} `,
                cancelToken,
            }),
            serializeQueryArgs: ({ queryArgs }) => {
                const { cancelToken, ...rest } = queryArgs

                return rest
            },
            transformResponse: (response: ArchiveData, _, { interpolationFactor }) => {
                if (interpolationFactor < 1)
                    return {
                        ...response,
                        buckets: response.buckets.map(ab => ({ ...ab, startDate: dayjs(ab.startDate) })),
                    }

                return interpolateBuckets(response, interpolationFactor)
            },
        }),
        fetchArchiveMetadata: build.query<ArchiveMetadata, string>({
            query: subscriptionId => ({
                method: "GET",
                url: `ais/Archive/${subscriptionId}/metadata`,
            }),
        }),
        fetchIsAISPublic: build.query<{ isPublic: boolean }, void>({
            query: () => ({
                method: "GET",
                url: `ais/config/public`,
            }),
        }),
    }),
})

export const { useFetchArchiveMetadataQuery, useFetchArchiveQuery, useFetchIsAISPublicQuery } = aisApi
