import * as React from "react";
const SvgTriangle15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "triangle-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5385 2 C7.2437 2 7.0502 2.1772 6.9231 2.3846 L1.0769 11.9230995 C1 12 1 12.1538 1 12.3077 C1 12.8462 1.3846 13 1.6923 13 L13.3077 13 C13.6923 13 14 12.8462 14 12.3077 C14 12.1539 14 12.0769005 13.9231 11.9231 L8.1538 2.3846 C8.028 2.1765 7.7882 2 7.5385 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5385 2 C7.2437 2 7.0502 2.1772 6.9231 2.3846 L1.0769 11.9230995 C1 12 1 12.1538 1 12.3077 C1 12.8462 1.3846 13 1.6923 13 L13.3077 13 C13.6923 13 14 12.8462 14 12.3077 C14 12.1539 14 12.0769005 13.9231 11.9231 L8.1538 2.3846 C8.028 2.1765 7.7882 2 7.5385 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTriangle15;
