import { getIsDrawerOpen, getIsSidebarOpen } from "selectors/sidebarAndDrawer"
import { useAppSelector } from "store/hooks/useAppSelector"

const HALF_DRAWER_WIDTH_OPEN = 90
const HALF_DRAWER_WIDTH_CLOSED = 30
const HALF_SIDEBAR_WIDTH_OPEN = 250

const useLeftDistance = (containerWidth: number) => {
    const isDrawerOpen = useAppSelector(getIsDrawerOpen)
    const isSideBarOpen = useAppSelector(getIsSidebarOpen)

    return (
        (isDrawerOpen ? HALF_DRAWER_WIDTH_OPEN : HALF_DRAWER_WIDTH_CLOSED) +
        (isSideBarOpen ? HALF_SIDEBAR_WIDTH_OPEN : 0) -
        containerWidth / 2
    )
}

export default useLeftDistance
