import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(theme => ({
    chip: {
        fontWeight: "bold",
        padding: "0px 3px",
    },
    chipExpanded: {
        "& .MuiChip-label": {
            paddingLeft: 0,
            paddingRight: 8,
            visibility: "visible",
            width: "100%",
        },
    },
    chipIcon: {
        fill: "inherit",
        height: 18,
        margin: "0px 5px !important",
        width: 18,
    },
    chipShrinked: {
        "& .MuiChip-label": {
            padding: 0,
            visibility: "hidden",
            width: 0,
        },
    },
    smallChip: {
        fontSize: 11,
        fontWeight: 600,
        height: "fit-content",
        lineHeight: 1,
        padding: "4px 3px",
    },
    smallChipIcon: {
        height: 15,
        width: 15,
    },
}))
