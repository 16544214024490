import * as React from "react";
const SvgIogp1014 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "188.21, 88.22, 423.56, 423.56", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "399.99,300 399.99,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "188.46,161.45 261.44,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "188.46,311.03 411.01,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "188.46,460.6 560.59,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "287.1,511.53 611.52,187.11", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "436.68,511.53 611.52,336.69", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "586.25,511.53 611.52,486.26", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "339.7,511.53 188.46,360.29", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "489.27,511.53 188.46,210.72", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "611.52,484.2 215.79,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "611.52,334.63 365.36,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "611.52,185.05 514.94,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "188.46,511.53 188.46,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "188.46,88.47 611.52,88.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "611.52,88.47 611.52,511.53", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "611.52,511.53 188.46,511.53", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M213.74 281.39 L213.74 280.42 L212.72 281.21 L213.74 281.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.57 312.88 L215.57 311.04 L213.74 311.47 L215.57 312.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M213.74 281.39 L215.57 281.72 L213.74 280.42 L213.74 281.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.57 281.72 L215.57 279 L213.74 280.42 L215.57 281.72 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.57 312.88 L216.62 313.68 L215.57 311.04 L215.57 312.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M216.62 313.68 L216.62 310.79 L215.57 311.04 L216.62 313.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M215.57 281.72 L216.62 281.9 L215.57 279 L215.57 281.72 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M216.62 281.9 L216.62 278.22 L215.57 279 L216.62 281.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M216.62 313.68 L218.46 315.05 L216.62 310.79 L216.62 313.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.46 315.05 L218.46 310.36 L216.62 310.79 L218.46 315.05 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M216.62 281.9 L218.46 282.23 L216.62 278.22 L216.62 281.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.46 282.23 L218.46 276.84 L216.62 278.22 L218.46 282.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.46 315.05 L219.54 315.85 L218.46 310.36 L218.46 315.05 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.54 315.85 L219.54 310.11 L218.46 310.36 L219.54 315.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M218.46 282.23 L219.54 282.43 L218.46 276.84 L218.46 282.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.54 282.43 L219.54 276.05 L218.46 276.84 L219.54 282.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.54 315.85 L221.38 317.19 L219.54 310.11 L219.54 315.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M221.38 317.19 L221.38 309.68 L219.54 310.11 L221.38 317.19 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M219.54 282.43 L221.38 282.75 L219.54 276.05 L219.54 282.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M221.38 282.75 L221.38 274.71 L219.54 276.05 L221.38 282.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M221.38 317.19 L222.48 317.99 L221.38 309.68 L221.38 317.19 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M222.48 317.99 L222.48 309.43 L221.38 309.68 L222.48 317.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M221.38 282.75 L222.48 282.95 L221.38 274.71 L221.38 282.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M222.48 282.95 L222.48 273.93 L221.38 274.71 L222.48 282.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M222.48 317.99 L224.33 319.29 L222.48 309.43 L222.48 317.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.33 319.29 L224.33 309 L222.48 309.43 L224.33 319.29 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M222.48 282.95 L224.33 283.28 L222.48 273.93 L222.48 282.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.33 283.28 L224.33 272.62 L222.48 273.93 L224.33 283.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.33 319.29 L225.46 320.08 L224.33 309 L224.33 319.29 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.46 320.08 L225.46 308.73 L224.33 309 L225.46 320.08 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M224.33 283.28 L225.46 283.48 L224.33 272.62 L224.33 283.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.46 283.48 L225.46 271.85 L224.33 272.62 L225.46 283.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.46 320.08 L227.3 321.34 L225.46 308.73 L225.46 320.08 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.3 321.34 L227.3 308.3 L225.46 308.73 L227.3 321.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M225.46 283.48 L227.3 283.81 L225.46 271.85 L225.46 283.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.3 283.81 L227.3 270.58 L225.46 271.85 L227.3 283.81 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.3 321.34 L228.46 322.13 L227.3 308.3 L227.3 321.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.46 322.13 L228.46 308.03 L227.3 308.3 L228.46 322.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M227.3 283.81 L228.46 284.02 L227.3 270.58 L227.3 283.81 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.46 284.02 L228.46 269.8 L227.3 270.58 L228.46 284.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.46 322.13 L230.3 323.36 L228.46 308.03 L228.46 322.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.3 323.36 L230.3 307.6 L228.46 308.03 L230.3 323.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M228.46 284.02 L230.3 284.35 L228.46 269.8 L228.46 284.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.3 284.35 L230.3 268.57 L228.46 269.8 L230.3 284.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.3 323.36 L231.49 324.14 L230.3 307.6 L230.3 323.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.49 324.14 L231.49 307.33 L230.3 307.6 L231.49 324.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M230.3 284.35 L231.49 284.56 L230.3 268.57 L230.3 284.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.49 284.56 L231.49 267.8 L230.3 268.57 L231.49 284.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.49 324.14 L233.33 325.33 L231.49 307.33 L231.49 324.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M233.33 325.33 L233.33 306.9 L231.49 307.33 L233.33 325.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M231.49 284.56 L233.33 284.89 L231.49 267.8 L231.49 284.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M233.33 284.89 L233.33 266.61 L231.49 267.8 L233.33 284.89 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M233.33 325.33 L234.55 326.11 L233.33 306.9 L233.33 325.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.55 326.11 L234.55 306.61 L233.33 306.9 L234.55 326.11 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M233.33 284.89 L234.55 285.1 L233.33 266.61 L233.33 284.89 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.55 285.1 L234.55 265.84 L233.33 266.61 L234.55 285.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.55 326.11 L236.39 327.26 L234.55 306.61 L234.55 326.11 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M236.39 327.26 L236.39 306.18 L234.55 306.61 L236.39 327.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M234.55 285.1 L236.39 285.43 L234.55 265.84 L234.55 285.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M236.39 285.43 L236.39 264.68 L234.55 265.84 L236.39 285.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M236.39 327.26 L237.64 328.04 L236.39 306.18 L236.39 327.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M237.64 328.04 L237.64 305.89 L236.39 306.18 L237.64 328.04 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M236.39 285.43 L237.64 285.66 L236.39 264.68 L236.39 285.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M237.64 285.66 L237.64 263.92 L236.39 264.68 L237.64 285.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M237.64 328.04 L239.47 329.15 L237.64 305.89 L237.64 328.04 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M239.47 329.15 L239.47 305.47 L237.64 305.89 L239.47 329.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M237.64 285.66 L239.47 285.98 L237.64 263.92 L237.64 285.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M239.47 285.98 L239.47 262.8 L237.64 263.92 L239.47 285.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M239.47 329.15 L240.75 329.92 L239.47 305.47 L239.47 329.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M240.75 329.92 L240.75 305.17 L239.47 305.47 L240.75 329.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M239.47 285.98 L240.75 286.21 L239.47 262.8 L239.47 285.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M240.75 286.21 L240.75 262.05 L239.47 262.8 L240.75 286.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M240.75 329.92 L242.58 331 L240.75 305.17 L240.75 329.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M242.58 331 L242.58 304.74 L240.75 305.17 L242.58 331 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M240.75 286.21 L242.58 286.54 L240.75 262.05 L240.75 286.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M242.58 286.54 L242.58 260.96 L240.75 262.05 L242.58 286.54 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M242.58 331 L243.88 331.77 L242.58 304.74 L242.58 331 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M243.88 331.77 L243.88 304.44 L242.58 304.74 L243.88 331.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M242.58 286.54 L243.88 286.77 L242.58 260.96 L242.58 286.54 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M243.88 286.77 L243.88 260.21 L242.58 260.96 L243.88 286.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M243.88 331.77 L245.71 332.8 L243.88 304.44 L243.88 331.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M245.71 332.8 L245.71 304.01 L243.88 304.44 L245.71 332.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M243.88 286.77 L245.71 287.1 L243.88 260.21 L243.88 286.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M245.71 287.1 L245.71 259.16 L243.88 260.21 L245.71 287.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M245.71 332.8 L246.96 333.51 L245.71 304.01 L245.71 332.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.96 333.51 L246.96 303.72 L245.71 304.01 L246.96 333.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M245.71 287.1 L246.96 287.32 L245.71 259.16 L245.71 287.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.96 287.32 L246.96 258.47 L245.71 259.16 L246.96 287.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.96 333.51 L247.05 333.56 L246.96 303.72 L246.96 333.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.05 333.56 L247.05 303.7 L246.96 303.72 L247.05 333.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M246.96 287.32 L247.05 287.38 L246.96 258.47 L246.96 287.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.05 287.38 L247.05 258.42 L246.96 258.47 L247.05 287.38 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.05 333.56 L247.53 333.83 L247.05 303.7 L247.05 333.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.53 333.83 L247.53 303.58 L247.05 303.7 L247.53 333.83 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.05 287.38 L247.53 287.71 L247.05 258.42 L247.05 287.38 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.53 287.71 L247.53 258.15 L247.05 258.42 L247.53 287.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.53 333.83 L248.86 334.56 L247.53 303.58 L247.53 333.83 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M248.86 334.56 L248.86 302.48 L247.53 303.58 L248.86 334.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M247.53 287.71 L248.86 288.61 L247.53 258.15 L247.53 287.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M248.86 288.61 L248.86 257.41 L247.53 258.15 L248.86 288.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M248.86 334.56 L250.23 335.32 L248.86 302.48 L248.86 334.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M250.23 335.32 L250.23 301.35 L248.86 302.48 L250.23 335.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M248.86 288.61 L250.23 289.53 L248.86 257.41 L248.86 288.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M250.23 289.53 L250.23 256.67 L248.86 257.41 L250.23 289.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M250.23 335.32 L252.04 336.28 L250.23 301.35 L250.23 335.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.04 336.28 L252.04 299.85 L250.23 301.35 L252.04 336.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M250.23 289.53 L252.04 290.75 L250.23 256.67 L250.23 289.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.04 290.75 L252.04 255.7 L250.23 256.67 L252.04 290.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.04 336.28 L253.44 337.03 L252.04 299.85 L252.04 336.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M253.44 337.03 L253.44 298.69 L252.04 299.85 L253.44 337.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M252.04 290.75 L253.44 291.7 L252.04 255.7 L252.04 290.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M253.44 291.7 L253.44 254.97 L252.04 255.7 L253.44 291.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M253.44 337.03 L255.24 337.96 L253.44 298.69 L253.44 337.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.24 337.96 L255.24 297.2 L253.44 298.69 L255.24 337.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M253.44 291.7 L255.24 292.91 L253.44 254.97 L253.44 291.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.24 292.91 L255.24 254.03 L253.44 254.97 L255.24 292.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.24 337.96 L256.67 338.7 L255.24 297.2 L255.24 337.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M256.67 338.7 L256.67 296.01 L255.24 297.2 L256.67 338.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M255.24 292.91 L256.67 293.88 L255.24 254.03 L255.24 292.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M256.67 293.88 L256.67 253.31 L255.24 254.03 L256.67 293.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M256.67 338.7 L258.09 339.4 L256.67 296.01 L256.67 338.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.09 339.4 L258.09 294.84 L256.67 296.01 L258.09 339.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M256.67 293.88 L258.09 294.84 L256.67 253.31 L256.67 293.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.09 294.84 L258.09 252.59 L256.67 253.31 L258.09 294.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.09 339.4 L258.47 339.59 L258.09 252.59 L258.09 339.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.47 339.59 L258.47 252.4 L258.09 252.59 L258.47 339.59 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M258.47 339.59 L259.93 340.32 L258.47 252.4 L258.47 339.59 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M259.93 340.32 L259.93 251.69 L258.47 252.4 L259.93 340.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M259.93 340.32 L261.71 341.18 L259.93 251.69 L259.93 340.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M261.71 341.18 L261.71 250.82 L259.93 251.69 L261.71 341.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M261.71 341.18 L263.21 341.9 L261.71 250.82 L261.71 341.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M263.21 341.9 L263.21 250.12 L261.71 250.82 L263.21 341.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M263.21 341.9 L264.98 342.72 L263.21 250.12 L263.21 341.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M264.98 342.72 L264.98 249.29 L263.21 250.12 L264.98 342.72 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M264.98 342.72 L266.51 343.43 L264.98 249.29 L264.98 342.72 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M266.51 343.43 L266.51 248.59 L264.98 249.29 L266.51 343.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M266.51 343.43 L268.27 344.22 L266.51 248.59 L266.51 343.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M268.27 344.22 L268.27 247.79 L266.51 248.59 L268.27 344.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M268.27 344.22 L269.82 344.92 L268.27 247.79 L268.27 344.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M269.82 344.92 L269.82 247.11 L268.27 247.79 L269.82 344.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M269.82 344.92 L271.57 345.67 L269.82 247.11 L269.82 344.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M271.57 345.67 L271.57 246.34 L269.82 247.11 L271.57 345.67 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M271.57 345.67 L273.16 346.36 L271.57 246.34 L271.57 345.67 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M273.16 346.36 L273.16 245.67 L271.57 246.34 L273.16 346.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M273.16 346.36 L274.9 347.08 L273.16 245.67 L273.16 346.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M274.9 347.08 L274.9 244.94 L273.16 245.67 L274.9 347.08 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M274.9 347.08 L276.52 347.75 L274.9 244.94 L274.9 347.08 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M276.52 347.75 L276.52 244.28 L274.9 244.94 L276.52 347.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M276.52 347.75 L278.25 348.44 L276.52 244.28 L276.52 347.75 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M278.25 348.44 L278.25 243.58 L276.52 244.28 L278.25 348.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M278.25 348.44 L279.9 349.1 L278.25 243.58 L278.25 348.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M279.9 349.1 L279.9 242.94 L278.25 243.58 L279.9 349.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M279.9 349.1 L281.61 349.76 L279.9 242.94 L279.9 349.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M281.61 349.76 L281.61 242.27 L279.9 242.94 L281.61 349.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M281.61 349.76 L283.3 350.4 L281.61 242.27 L281.61 349.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M283.3 350.4 L283.3 241.64 L281.61 242.27 L283.3 350.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M283.3 350.4 L284.99 351.03 L283.3 241.64 L283.3 350.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M284.99 351.03 L284.99 241.01 L283.3 241.64 L284.99 351.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M284.99 351.03 L286.71 351.66 L284.99 241.01 L284.99 351.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M286.71 351.66 L286.71 240.39 L284.99 241.01 L286.71 351.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M286.71 351.66 L288.39 352.25 L286.71 240.39 L286.71 351.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M288.39 352.25 L288.39 239.79 L286.71 240.39 L288.39 352.25 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M288.39 352.25 L290.14 352.87 L288.39 239.79 L288.39 352.25 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M290.14 352.87 L290.14 239.18 L288.39 239.79 L290.14 352.87 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M290.14 352.87 L291.8 353.43 L290.14 239.18 L290.14 352.87 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M291.8 353.43 L291.8 238.61 L290.14 239.18 L291.8 353.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M291.8 353.43 L293.59 354.03 L291.8 238.61 L291.8 353.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M293.59 354.03 L293.59 238.02 L291.8 238.61 L293.59 354.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M293.59 354.03 L295.23 354.56 L293.59 238.02 L293.59 354.03 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M295.23 354.56 L295.23 237.48 L293.59 238.02 L295.23 354.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M295.23 354.56 L297.05 355.15 L295.23 237.48 L295.23 354.56 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M297.05 355.15 L297.05 236.91 L295.23 237.48 L297.05 355.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M297.05 355.15 L298.68 355.65 L297.05 236.91 L297.05 355.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M298.68 355.65 L298.68 236.4 L297.05 236.91 L298.68 355.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M298.68 355.65 L300.53 356.21 L298.68 236.4 L298.68 355.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M300.53 356.21 L300.53 235.85 L298.68 236.4 L300.53 356.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M300.53 356.21 L302.14 356.68 L300.53 235.85 L300.53 356.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M302.14 356.68 L302.14 235.37 L300.53 235.85 L302.14 356.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M302.14 356.68 L304.02 357.23 L302.14 235.37 L302.14 356.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M304.02 357.23 L304.02 234.83 L302.14 235.37 L304.02 357.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M304.02 357.23 L305.61 357.67 L304.02 234.83 L304.02 357.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M305.61 357.67 L305.61 234.38 L304.02 234.83 L305.61 357.67 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M305.61 357.67 L307.52 358.2 L305.61 234.38 L305.61 357.67 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M307.52 358.2 L307.52 233.86 L305.61 234.38 L307.52 358.2 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M307.52 358.2 L309.09 358.62 L307.52 233.86 L307.52 358.2 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M309.09 358.62 L309.09 233.44 L307.52 233.86 L309.09 358.62 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M309.09 358.62 L311.04 359.13 L309.09 233.44 L309.09 358.62 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.04 359.13 L311.04 232.94 L309.09 233.44 L311.04 359.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.04 359.13 L312.59 359.51 L311.04 232.94 L311.04 359.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M312.59 359.51 L312.59 232.55 L311.04 232.94 L312.59 359.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M312.59 359.51 L314.57 360 L312.59 232.55 L312.59 359.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.57 360 L314.57 232.07 L312.59 232.55 L314.57 360 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.57 360 L316.1 360.36 L314.57 232.07 L314.57 360 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.1 360.36 L316.1 231.7 L314.57 232.07 L316.1 360.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.1 360.36 L318.11 360.83 L316.1 231.7 L316.1 360.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M318.11 360.83 L318.11 231.25 L316.1 231.7 L318.11 360.83 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M318.11 360.83 L319.62 361.16 L318.11 231.25 L318.11 360.83 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M319.62 361.16 L319.62 230.91 L318.11 231.25 L319.62 361.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M319.62 361.16 L321.67 361.61 L319.62 230.91 L319.62 361.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.67 361.61 L321.67 230.47 L319.62 230.91 L321.67 361.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.67 361.61 L323.16 361.91 L321.67 230.47 L321.67 361.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.16 361.91 L323.16 230.16 L321.67 230.47 L323.16 361.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M323.16 361.91 L325.23 362.34 L323.16 230.16 L323.16 361.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M325.23 362.34 L325.23 229.75 L323.16 230.16 L325.23 362.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M325.23 362.34 L326.7 362.61 L325.23 229.75 L325.23 362.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M326.7 362.61 L326.7 229.45 L325.23 229.75 L326.7 362.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M326.7 362.61 L328.8 363.02 L326.7 229.45 L326.7 362.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M328.8 363.02 L328.8 229.07 L326.7 229.45 L328.8 363.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M328.8 363.02 L330.25 363.27 L328.8 229.07 L328.8 363.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.25 363.27 L330.25 228.8 L328.8 229.07 L330.25 363.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.25 363.27 L332.39 363.65 L330.25 228.8 L330.25 363.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M332.39 363.65 L332.39 228.44 L330.25 228.8 L332.39 363.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M332.39 363.65 L333.81 363.88 L332.39 228.44 L332.39 363.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M333.81 363.88 L333.81 228.2 L332.39 228.44 L333.81 363.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M333.81 363.88 L335.98 364.23 L333.81 228.2 L333.81 363.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M335.98 364.23 L335.98 227.86 L333.81 228.2 L335.98 364.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M335.98 364.23 L337.37 364.43 L335.98 227.86 L335.98 364.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M337.37 364.43 L337.37 227.64 L335.98 227.86 L337.37 364.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M337.37 364.43 L339.57 364.76 L337.37 227.64 L337.37 364.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M339.57 364.76 L339.57 227.33 L337.37 227.64 L339.57 364.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M339.57 364.76 L340.95 364.94 L339.57 227.33 L339.57 364.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M340.95 364.94 L340.95 227.13 L339.57 227.33 L340.95 364.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M340.95 364.94 L343.18 365.24 L340.95 227.13 L340.95 364.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343.18 365.24 L343.18 226.84 L340.95 227.13 L343.18 365.24 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343.18 365.24 L344.53 365.41 L343.18 226.84 L343.18 365.24 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M344.53 365.41 L344.53 226.67 L343.18 226.84 L344.53 365.41 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M344.53 365.41 L346.79 365.68 L344.53 226.67 L344.53 365.41 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.79 365.68 L346.79 226.41 L344.53 226.67 L346.79 365.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.79 365.68 L348.12 365.82 L346.79 226.41 L346.79 365.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.12 365.82 L348.12 226.26 L346.79 226.41 L348.12 365.82 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.12 365.82 L350.41 366.06 L348.12 226.26 L348.12 365.82 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.41 366.06 L350.41 226.03 L348.12 226.26 L350.41 366.06 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.41 366.06 L351.71 366.18 L350.41 226.03 L350.41 366.06 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M351.71 366.18 L351.71 225.9 L350.41 226.03 L351.71 366.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M351.71 366.18 L354.03 366.39 L351.71 225.9 L351.71 366.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M354.03 366.39 L354.03 225.69 L351.71 225.9 L354.03 366.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M354.03 366.39 L355.31 366.5 L354.03 225.69 L354.03 366.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.31 366.5 L355.31 225.58 L354.03 225.69 L355.31 366.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.31 366.5 L357.65 366.68 L355.31 225.58 L355.31 366.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.65 366.68 L357.65 225.41 L355.31 225.58 L357.65 366.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.65 366.68 L358.91 366.76 L357.65 225.41 L357.65 366.68 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.91 366.76 L358.91 225.32 L357.65 225.41 L358.91 366.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.91 366.76 L361.28 366.91 L358.91 225.32 L358.91 366.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M361.28 366.91 L361.28 225.18 L358.91 225.32 L361.28 366.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M361.28 366.91 L362.51 366.98 L361.28 225.18 L361.28 366.91 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M362.51 366.98 L362.51 225.1 L361.28 225.18 L362.51 366.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M362.51 366.98 L364.92 367.1 L362.51 225.1 L362.51 366.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M364.92 367.1 L364.92 224.99 L362.51 225.1 L364.92 367.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M364.92 367.1 L366.12 367.14 L364.92 224.99 L364.92 367.1 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.12 367.14 L366.12 224.94 L364.92 224.99 L366.12 367.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M366.12 367.14 L368.55 367.23 L366.12 224.94 L366.12 367.14 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.55 367.23 L368.55 224.86 L366.12 224.94 L368.55 367.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.55 367.23 L369.72 367.26 L368.55 224.86 L368.55 367.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M369.72 367.26 L369.72 224.82 L368.55 224.86 L369.72 367.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M369.72 367.26 L372.19 367.32 L369.72 224.82 L369.72 367.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M372.19 367.32 L372.19 224.77 L369.72 224.82 L372.19 367.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M372.19 367.32 L373.33 367.33 L372.19 224.77 L372.19 367.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.33 367.33 L373.33 224.75 L372.19 224.77 L373.33 367.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.33 367.33 L375.82 367.36 L373.33 224.75 L373.33 367.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M375.82 367.36 L375.82 224.73 L373.33 224.75 L375.82 367.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M375.82 367.36 L376.94 367.35 L375.82 224.73 L375.82 367.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.94 367.35 L376.94 224.73 L375.82 224.73 L376.94 367.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.94 367.35 L379.46 367.34 L376.94 224.73 L376.94 367.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.46 367.34 L379.46 224.75 L376.94 224.73 L379.46 367.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.46 367.34 L380.56 367.32 L379.46 224.75 L379.46 367.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.56 367.32 L380.56 224.76 L379.46 224.75 L380.56 367.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.56 367.32 L383.1 367.28 L380.56 224.76 L380.56 367.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M383.1 367.28 L383.1 224.81 L380.56 224.76 L383.1 367.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M383.1 367.28 L384.16 367.24 L383.1 224.81 L383.1 367.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.16 367.24 L384.16 224.83 L383.1 224.81 L384.16 367.24 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.16 367.24 L386.73 367.16 L384.16 224.83 L384.16 367.24 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M386.73 367.16 L386.73 224.92 L384.16 224.83 L386.73 367.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M386.73 367.16 L387.77 367.12 L386.73 224.92 L386.73 367.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.77 367.12 L387.77 224.96 L386.73 224.92 L387.77 367.12 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.77 367.12 L390.37 367 L387.77 224.96 L387.77 367.12 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M390.37 367 L390.37 225.09 L387.77 224.96 L390.37 367 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M390.37 367 L391.38 366.94 L390.37 225.09 L390.37 367 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.38 366.94 L391.38 225.14 L390.37 225.09 L391.38 366.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.38 366.94 L394 366.79 L391.38 225.14 L391.38 366.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394 366.79 L394 225.3 L391.38 225.14 L394 366.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394 366.79 L394.98 366.71 L394 225.3 L394 366.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.98 366.71 L394.98 225.36 L394 225.3 L394.98 366.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.98 366.71 L397.63 366.52 L394.98 225.36 L394.98 366.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.63 366.52 L397.63 225.56 L394.98 225.36 L397.63 366.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.63 366.52 L398.58 366.44 L397.63 225.56 L397.63 366.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.58 366.44 L398.58 225.63 L397.63 225.56 L398.58 366.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.58 366.44 L401.25 366.21 L398.58 225.63 L398.58 366.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.25 366.21 L401.25 225.87 L398.58 225.63 L401.25 366.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.25 366.21 L402.18 366.12 L401.25 225.87 L401.25 366.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.18 366.12 L402.18 225.96 L401.25 225.87 L402.18 366.12 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.18 366.12 L404.87 365.85 L402.18 225.96 L402.18 366.12 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.87 365.85 L404.87 226.23 L402.18 225.96 L404.87 365.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.87 365.85 L405.77 365.74 L404.87 226.23 L404.87 365.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M405.77 365.74 L405.77 226.33 L404.87 226.23 L405.77 365.74 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M405.77 365.74 L408.48 365.43 L405.77 226.33 L405.77 365.74 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.48 365.43 L408.48 226.65 L405.77 226.33 L408.48 365.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.48 365.43 L409.36 365.32 L408.48 226.65 L408.48 365.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.36 365.32 L409.36 226.75 L408.48 226.65 L409.36 365.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.36 365.32 L412.09 364.97 L409.36 226.75 L409.36 365.32 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.09 364.97 L412.09 227.1 L409.36 226.75 L412.09 364.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.09 364.97 L412.94 364.85 L412.09 227.1 L412.09 364.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.94 364.85 L412.94 227.22 L412.09 227.1 L412.94 364.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.94 364.85 L415.69 364.46 L412.94 227.22 L412.94 364.85 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.69 364.46 L415.69 227.61 L412.94 227.22 L415.69 364.46 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M415.69 364.46 L416.51 364.33 L415.69 227.61 L415.69 364.46 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M416.51 364.33 L416.51 227.73 L415.69 227.61 L416.51 364.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M416.51 364.33 L419.28 363.9 L416.51 227.73 L416.51 364.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M419.28 363.9 L419.28 228.17 L416.51 227.73 L419.28 363.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M419.28 363.9 L420.07 363.76 L419.28 228.17 L419.28 363.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.07 363.76 L420.07 228.3 L419.28 228.17 L420.07 363.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.07 363.76 L422.87 363.29 L420.07 228.3 L420.07 363.76 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M422.87 363.29 L422.87 228.78 L420.07 228.3 L422.87 363.29 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M422.87 363.29 L423.63 363.15 L422.87 228.78 L422.87 363.29 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M423.63 363.15 L423.63 228.91 L422.87 228.78 L423.63 363.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M423.63 363.15 L426.45 362.63 L423.63 228.91 L423.63 363.15 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.45 362.63 L426.45 229.44 L423.63 228.91 L426.45 362.63 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.45 362.63 L427.18 362.48 L426.45 229.44 L426.45 362.63 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M427.18 362.48 L427.18 229.57 L426.45 229.44 L427.18 362.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M427.18 362.48 L430.02 361.92 L427.18 229.57 L427.18 362.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M430.02 361.92 L430.02 230.14 L427.18 229.57 L430.02 361.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M430.02 361.92 L430.72 361.77 L430.02 230.14 L430.02 361.92 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M430.72 361.77 L430.72 230.28 L430.02 230.14 L430.72 361.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M430.72 361.77 L433.57 361.16 L430.72 230.28 L430.72 361.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M433.57 361.16 L433.57 230.89 L430.72 230.28 L433.57 361.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M433.57 361.16 L434.25 361.01 L433.57 230.89 L433.57 361.16 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.25 361.01 L434.25 231.04 L433.57 230.89 L434.25 361.01 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.25 361.01 L437.12 360.36 L434.25 231.04 L434.25 361.01 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.12 360.36 L437.12 231.7 L434.25 231.04 L437.12 360.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.12 360.36 L437.77 360.2 L437.12 231.7 L437.12 360.36 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.77 360.2 L437.77 231.85 L437.12 231.7 L437.77 360.2 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.77 360.2 L440.66 359.5 L437.77 231.85 L437.77 360.2 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M440.66 359.5 L440.66 232.55 L437.77 231.85 L440.66 359.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M440.66 359.5 L441.28 359.34 L440.66 232.55 L440.66 359.5 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.28 359.34 L441.28 232.7 L440.66 232.55 L441.28 359.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.28 359.34 L444.18 358.6 L441.28 232.7 L441.28 359.34 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M444.18 358.6 L444.18 233.45 L441.28 232.7 L444.18 358.6 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M444.18 358.6 L444.77 358.44 L444.18 233.45 L444.18 358.6 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M444.77 358.44 L444.77 233.6 L444.18 233.45 L444.77 358.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M444.77 358.44 L447.69 357.65 L444.77 233.6 L444.77 358.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.69 357.65 L447.69 234.39 L444.77 233.6 L447.69 357.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.69 357.65 L448.26 357.49 L447.69 234.39 L447.69 357.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M448.26 357.49 L448.26 234.55 L447.69 234.39 L448.26 357.49 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M448.26 357.49 L451.19 356.65 L448.26 234.55 L448.26 357.49 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M451.19 356.65 L451.19 235.39 L448.26 234.55 L451.19 356.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M451.19 356.65 L451.73 356.49 L451.19 235.39 L451.19 356.65 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M451.73 356.49 L451.73 235.54 L451.19 235.39 L451.73 356.49 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M451.73 356.49 L454.67 355.61 L451.73 235.54 L451.73 356.49 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M454.67 355.61 L454.67 236.43 L451.73 235.54 L454.67 355.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M454.67 355.61 L455.19 355.44 L454.67 236.43 L454.67 355.61 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.19 355.44 L455.19 236.59 L454.67 236.43 L455.19 355.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M455.19 355.44 L458.14 354.51 L455.19 236.59 L455.19 355.44 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.14 354.51 L458.14 237.52 L455.19 236.59 L458.14 354.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.14 354.51 L458.63 354.35 L458.14 237.52 L458.14 354.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.63 354.35 L458.63 237.68 L458.14 237.52 L458.63 354.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.63 354.35 L461.59 353.37 L458.63 237.68 L458.63 354.35 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.59 353.37 L461.59 238.66 L458.63 237.68 L461.59 353.37 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.59 353.37 L462.05 353.21 L461.59 238.66 L461.59 353.37 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M462.05 353.21 L462.05 238.81 L461.59 238.66 L462.05 353.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M462.05 353.21 L465.03 352.18 L462.05 238.81 L462.05 353.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.03 352.18 L465.03 239.84 L462.05 238.81 L465.03 352.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.03 352.18 L465.47 352.02 L465.03 239.84 L465.03 352.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.47 352.02 L465.47 239.99 L465.03 239.84 L465.47 352.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.47 352.02 L468.45 350.94 L465.47 239.99 L465.47 352.02 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.45 350.94 L468.45 241.07 L465.47 239.99 L468.45 350.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.45 350.94 L468.86 350.79 L468.45 241.07 L468.45 350.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.86 350.79 L468.86 241.22 L468.45 241.07 L468.86 350.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.86 350.79 L471.85 349.66 L468.86 241.22 L468.86 350.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M471.85 349.66 L471.85 242.35 L468.86 241.22 L471.85 349.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M471.85 349.66 L472.24 349.51 L471.85 242.35 L471.85 349.66 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M472.24 349.51 L472.24 242.5 L471.85 242.35 L472.24 349.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M472.24 349.51 L475.24 348.33 L472.24 242.5 L472.24 349.51 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.24 348.33 L475.24 243.67 L472.24 242.5 L475.24 348.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.24 348.33 L475.6 348.18 L475.24 243.67 L475.24 348.33 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.6 348.18 L475.6 243.82 L475.24 243.67 L475.6 348.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.6 348.18 L478.61 346.95 L475.6 243.82 L475.6 348.18 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.61 346.95 L478.61 245.04 L475.6 243.82 L478.61 346.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.61 346.95 L478.94 346.81 L478.61 245.04 L478.61 346.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.94 346.81 L478.94 245.18 L478.61 245.04 L478.94 346.81 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.94 346.81 L481.95 345.53 L478.94 245.18 L478.94 346.81 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.95 345.53 L481.95 246.46 L478.94 245.18 L481.95 345.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.95 345.53 L482.27 345.4 L481.95 246.46 L481.95 345.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M482.27 345.4 L482.27 246.59 L481.95 246.46 L482.27 345.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M482.27 345.4 L485.28 344.07 L482.27 246.59 L482.27 345.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.28 344.07 L485.28 247.92 L482.27 246.59 L485.28 344.07 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.28 344.07 L485.57 343.93 L485.28 247.92 L485.28 344.07 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.57 343.93 L485.57 248.05 L485.28 247.92 L485.57 343.93 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.57 343.93 L488.59 342.55 L485.57 248.05 L485.57 343.93 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.59 342.55 L488.59 249.43 L485.57 248.05 L488.59 342.55 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.59 342.55 L488.85 342.43 L488.59 249.43 L488.59 342.55 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.85 342.43 L488.85 249.55 L488.59 249.43 L488.85 342.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.85 342.43 L491.88 340.99 L488.85 249.55 L488.85 342.43 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M491.88 340.99 L491.88 250.98 L488.85 249.55 L491.88 340.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M491.88 340.99 L492.12 340.88 L491.88 250.98 L491.88 340.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M492.12 340.88 L492.12 251.09 L491.88 250.98 L492.12 340.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M492.12 340.88 L495.14 339.39 L492.12 251.09 L492.12 340.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M495.14 339.39 L495.14 252.58 L492.12 251.09 L495.14 339.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M495.14 339.39 L495.36 339.28 L495.14 252.58 L495.14 339.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M495.36 339.28 L495.36 252.68 L495.14 252.58 L495.36 339.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M495.36 339.28 L498.38 337.74 L495.36 252.68 L495.36 339.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M498.38 337.74 L498.38 254.22 L495.36 252.68 L498.38 337.74 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M498.38 337.74 L498.58 337.64 L498.38 254.22 L498.38 337.74 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M498.58 337.64 L498.58 254.31 L498.38 254.22 L498.58 337.64 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M498.58 337.64 L501.6 336.05 L498.58 254.31 L498.58 337.64 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.6 336.05 L501.6 255.9 L498.58 254.31 L501.6 336.05 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.6 336.05 L501.78 335.96 L501.6 255.9 L501.6 336.05 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.78 335.96 L501.78 255.99 L501.6 255.9 L501.78 335.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M501.78 335.96 L504.8 334.31 L501.78 255.99 L501.78 335.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.8 334.31 L504.8 257.63 L501.78 255.99 L504.8 334.31 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.8 334.31 L504.95 334.23 L504.8 257.63 L504.8 334.31 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.95 334.23 L504.95 257.71 L504.8 257.63 L504.95 334.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.95 334.23 L507.97 332.53 L504.95 257.71 L504.95 334.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M507.97 332.53 L507.97 259.4 L504.95 257.71 L507.97 332.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M507.97 332.53 L508.1 332.46 L507.97 259.4 L507.97 332.53 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M508.1 332.46 L508.1 259.47 L507.97 259.4 L508.1 332.46 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M508.1 332.46 L511.12 330.71 L508.1 259.47 L508.1 332.46 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M511.12 330.71 L511.12 261.21 L508.1 259.47 L511.12 330.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M511.12 330.71 L511.23 330.64 L511.12 261.21 L511.12 330.71 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M511.23 330.64 L511.23 261.28 L511.12 261.21 L511.23 330.64 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M511.23 330.64 L514.24 328.84 L511.23 261.28 L511.23 330.64 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M514.24 328.84 L514.24 263.07 L511.23 261.28 L514.24 328.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M514.24 328.84 L514.33 328.79 L514.24 263.07 L514.24 328.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M514.33 328.79 L514.33 263.13 L514.24 263.07 L514.33 328.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M514.33 328.79 L517.34 326.94 L514.33 263.13 L514.33 328.79 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.34 326.94 L517.34 264.97 L514.33 263.13 L517.34 326.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.34 326.94 L517.41 326.89 L517.34 264.97 L517.34 326.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.41 326.89 L517.41 265.01 L517.34 264.97 L517.41 326.89 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.41 326.89 L520.4 324.98 L517.41 265.01 L517.41 326.89 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M520.4 324.98 L520.4 266.91 L517.41 265.01 L520.4 324.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M520.4 324.98 L520.46 324.95 L520.4 266.91 L520.4 324.98 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M520.46 324.95 L520.46 266.95 L520.4 266.91 L520.46 324.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M520.46 324.95 L523.45 322.99 L520.46 266.95 L520.46 324.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M523.45 322.99 L523.45 268.89 L520.46 266.95 L523.45 322.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M523.45 322.99 L523.48 322.97 L523.45 268.89 L523.45 322.99 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M523.48 322.97 L523.48 268.92 L523.45 268.89 L523.48 322.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M523.48 322.97 L526.46 320.96 L523.48 268.92 L523.48 322.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M526.46 320.96 L526.46 270.92 L523.48 268.92 L526.46 320.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M526.46 320.96 L526.48 320.94 L526.46 270.92 L526.46 320.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M526.48 320.94 L526.48 270.93 L526.46 270.92 L526.48 320.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M526.48 320.94 L529.45 318.88 L526.48 270.93 L526.48 320.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M529.45 318.88 L529.45 272.98 L526.48 270.93 L529.45 318.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M529.45 318.88 L556.76 338.73 L529.45 272.98 L529.45 318.88 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M556.76 338.73 L556.76 253.13 L529.45 272.98 L556.76 338.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M556.76 338.73 L575.13 355.37 L556.76 253.13 L556.76 338.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M575.13 355.37 L575.13 236.49 L556.76 253.13 L575.13 355.37 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M575.13 355.37 L577.85 357.84 L575.13 295.93 L575.13 355.37 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M577.85 357.84 L577.85 299.04 L575.13 295.93 L577.85 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M575.13 295.93 L577.85 292.82 L575.13 236.49 L575.13 295.93 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M577.85 292.82 L577.85 234.03 L575.13 236.49 L577.85 292.82 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M577.85 357.84 L587.7 357.84 L577.85 299.04 L577.85 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M587.7 357.84 L587.7 310.3 L577.85 299.04 L587.7 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M577.85 292.82 L587.7 281.57 L577.85 234.03 L577.85 292.82 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M587.7 281.57 L587.7 234.03 L577.85 234.03 L587.7 281.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M587.7 357.84 L591.61 357.84 L587.7 310.3 L587.7 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M591.61 357.84 L591.61 318.89 L587.7 310.3 L591.61 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M587.7 281.57 L591.61 272.97 L587.7 234.03 L587.7 281.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M591.61 272.97 L591.61 234.03 L587.7 234.03 L591.61 272.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M591.61 357.84 L594.92 341.31 L591.61 318.89 L591.61 357.84 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M594.92 341.31 L594.92 326.17 L591.61 318.89 L594.92 341.31 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M591.61 272.97 L594.92 265.7 L591.61 234.03 L591.61 272.97 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M594.92 265.7 L594.92 250.56 L591.61 234.03 L594.92 265.7 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "575.13,295.93 587.7,310.3", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "587.7,310.3 594.92,326.17", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "594.92,326.17 594.92,341.31", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "594.92,341.31 591.61,357.84", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "591.61,357.84 577.85,357.84", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "577.85,357.84 556.76,338.73", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "556.76,338.73 529.45,318.88", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "213.74,311.47 247.53,303.58", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "247.53,303.58 258.09,294.84", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "258.09,294.84 246.96,287.32", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "246.96,287.32 212.72,281.21", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "529.45,272.98 556.76,253.13", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "556.76,253.13 577.85,234.03", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "577.85,234.03 591.61,234.03", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "591.61,234.03 594.92,250.56", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "594.92,250.56 594.92,265.7", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "594.92,265.7 587.7,281.57", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "587.7,281.57 575.13,295.93", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "529.45,318.88 520.96,324.63 512.26,330.04 503.35,335.11 494.25,339.83 484.98,344.2 475.55,348.21 465.96,351.85 456.25,355.11 446.41,358 436.48,360.51 426.45,362.63 416.35,364.36 406.18,365.7 395.98,366.65 385.74,367.2 375.49,367.35 365.25,367.11 355.02,366.48 344.82,365.45 334.67,364.02 324.58,362.21 314.57,360 304.65,357.41 294.84,354.44 285.16,351.1 275.6,347.38 266.2,343.29 256.97,338.85 247.91,334.05 239.05,328.9 230.39,323.42 221.95,317.6 213.74,311.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "529.45,272.98 520.92,267.24 512.17,261.83 503.22,256.77 494.08,252.05 484.77,247.69 475.29,243.69 465.67,240.07 455.92,236.81 446.04,233.94 436.07,231.45 426,229.35 415.86,227.63 405.66,226.32 395.42,225.39 385.15,224.86 374.87,224.73 364.59,225 354.32,225.66 344.1,226.72 333.92,228.18 323.8,230.03 313.76,232.26 303.82,234.88 293.98,237.89 284.27,241.27 274.7,245.03 265.28,249.15 256.02,253.63 246.95,258.47 238.07,263.65 229.39,269.18 220.94,275.03 212.72,281.21", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "402.26,226.43 402.26,226.43", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1014;
