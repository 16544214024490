import { useEffect } from "react"
import { useState } from "react"
import dayjs from "dayjs"
import { fromNowWithCheck } from "utils/timeUtils"
import ReadOnlyField from "../readOnlyField/readOnlyField"

export const FromNow = ({ date, interval, label, tooltip, tooltipDateFormat = "LLL" }) => {
    const [text, setText] = useState(fromNowWithCheck(dayjs(date)))

    useEffect(() => {
        const intervalId = setInterval(() => {
            setText(fromNowWithCheck(dayjs(date)))
        }, interval)

        return () => clearInterval(intervalId)
    }, [date])

    return (
        <ReadOnlyField
            label={label}
            text={text}
            tooltip={tooltip}
            tooltipTitle={dayjs(date).format(tooltipDateFormat)}
        />
    )
}
