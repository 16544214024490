import * as React from "react";
const SvgMonument11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "monument-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L4 2 L4 6.5 L7 6.5 L7 2 L5.5 0 z M3 7 L2 8 L2 11 L9 11 L9 8 L8 7 L3 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L4 2 L4 6.5 L7 6.5 L7 2 L5.5 0 z M3 7 L2 8 L2 11 L9 11 L9 8 L8 7 L3 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMonument11;
