import { RootState } from "../store/models/RootState"

export const getAISSubscriptionData = (state: RootState) => state.AIS.subscriptionData

export const getAISSubscriptionInfo = (state: RootState) => state.AIS.subscriptionInfo

export const getArchiveData = (state: RootState) => state.AIS.archiveData

export const getBoatDetails = (state: RootState) => state.AIS.boatDetailsDictionary

export const getArchiveDisplayOptions = (state: RootState) => state.AIS.archiveDisplayOptions
