export const STYLE_TYPES = {
    background: "background",
    circle: "circle",
    fill: "fill",
    fillExtrusion: "fill-extrusion",
    heatmap: "heatmap",
    hillShade: "hillshade",
    line: "line",
    raster: "raster",
    sky: "sky",
    symbol: "symbol",
} as const
