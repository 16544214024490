import { css, styled } from "@mui/material"
import { Theme } from "@mui/material"
import { IconButton, Input, LinearProgress } from "components/atoms"

export const StyledBaseContainer = styled("div")`
    transition: all 0.4s;
    height: 100%;
    position: relative;
`

export const StyledFullHeightContainer = styled("div")`
    height: 100%;
`

export const StyledSearchContainer = styled("div")`
    padding: 10px 16px;
    background-color: white;
`

export const StyledLinearProgress = styled(LinearProgress)`
    margin-bottom: -4px;
`

export const StyledInput = styled(Input)`
    font-size: 14px;
`

export const StyledIconButton = styled(IconButton)`
    border-radius: 0;
    padding: 0;
    background: #f3f3f3;
`

export const StyledHideAllIconButton = styled(IconButton)`
    color: #000;
    margin-right: 20px;
    padding: 9px;
    background-color: #fafbff;
`

export const LayersContainer = styled("div")(
    ({ theme }) => css`
        height: calc(100% - 114px); // subtract the headers height to avoid unnecessary scroll

        [${(theme as Theme).breakpoints.down("md")}] {
            max-height: 100%;
        }
    `,
)
