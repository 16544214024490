import * as React from "react";
const SvgTheatre15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "theatre-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1 C2 1 1 1 1 2 L1 7.1582 C1 8.8885 1.354 11 4.5 11 L5 11 L5 8 L2.5 9 C2.5 9 2.5 6.5 5 6.5 L5 5 C5 4.2922 5.0868 3.6791 5.5 3.2245998 C5.8815 2.805 6.5046 1.9674 8.1562 2.7539 L9 3.3027 L9 2 C9 2 9 1 8 1 C7.2922 1 6.0224 2 5 2 C3.9776 2 2.7865 1 2 1 z M3 3 C3.5523 3 4 3.4477 4 4 C4 4.5523 3.5523 5 3 5 C2.4477 5 2 4.5523 2 4 C2 3.4477 2.4477 3 3 3 z M7 4 C7 4 6 4 6 5 L6 10 C6 12 7 14 10 14 C13 14 14 12 14 10 L14 5 C14 4 13 4 13 4 C12.2922 4 11.0224 5 10 5 C8.9776 5 7.7865 4 7 4 z M8 6 C8.5522995 6 9 6.4477 9 7 C9 7.5523 8.5523 8 8 8 C7.4476995 8 7 7.5523 7 7 C7 6.4477 7.4477 6 8 6 z M12 6 C12.5522995 6 13 6.4477 13 7 C13 7.5523 12.5523 8 12 8 C11.4477 8 11 7.5523 11 7 C11 6.4477 11.4477 6 12 6 z M7.5 10 L10 10 L12.5 10 C12.5 10 12.5 12.5 10 12.5 C7.5 12.5 7.5 10 7.5 10 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1 C2 1 1 1 1 2 L1 7.1582 C1 8.8885 1.354 11 4.5 11 L5 11 L5 8 L2.5 9 C2.5 9 2.5 6.5 5 6.5 L5 5 C5 4.2922 5.0868 3.6791 5.5 3.2245998 C5.8815 2.805 6.5046 1.9674 8.1562 2.7539 L9 3.3027 L9 2 C9 2 9 1 8 1 C7.2922 1 6.0224 2 5 2 C3.9776 2 2.7865 1 2 1 z M3 3 C3.5523 3 4 3.4477 4 4 C4 4.5523 3.5523 5 3 5 C2.4477 5 2 4.5523 2 4 C2 3.4477 2.4477 3 3 3 z M7 4 C7 4 6 4 6 5 L6 10 C6 12 7 14 10 14 C13 14 14 12 14 10 L14 5 C14 4 13 4 13 4 C12.2922 4 11.0224 5 10 5 C8.9776 5 7.7865 4 7 4 z M8 6 C8.5522995 6 9 6.4477 9 7 C9 7.5523 8.5523 8 8 8 C7.4476995 8 7 7.5523 7 7 C7 6.4477 7.4477 6 8 6 z M12 6 C12.5522995 6 13 6.4477 13 7 C13 7.5523 12.5523 8 12 8 C11.4477 8 11 7.5523 11 7 C11 6.4477 11.4477 6 12 6 z M7.5 10 L10 10 L12.5 10 C12.5 10 12.5 12.5 10 12.5 C7.5 12.5 7.5 10 7.5 10 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTheatre15;
