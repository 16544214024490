import clsx from "clsx"
import { useStyles } from "./style"

type Props = {
    rasterColor?: string
}

const RasterIcon = ({ rasterColor }: Props) => {
    const { legend, raster } = useStyles({
        rasterColor,
    })

    return <div className={clsx(legend, raster)}></div>
}

export default RasterIcon
