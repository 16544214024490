import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function recurse<T = object>(
    layers: GenericLayer<T>[],
    func: Function,
    selector: (layer: GenericLayer<T>) => boolean,
    parentGroupId?: string,
) {
    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i]

        if (selector(layer)) func(layer, i, parentGroupId)

        if (isGroup(layer)) {
            recurse(layer.layers!, func, selector, layer.resourceId)
        }
    }
}
