import { makeStyles } from "@mui/styles"

export const useZanderModalStyles = makeStyles(theme => ({
    activeTab: {
        cursor: "unset",
    },
    closeIconVisible: {
        display: "initial",
    },
    closeTabIcon: {
        "&:hover": {
            color: theme.customColors.appTextColor,
        },
        color: theme.customColors.mutedColor,
        cursor: "pointer",
        display: "none",
        marginTop: -8,
        position: "absolute",
        right: 8,
    },
    honeypot: {
        left: "-9999px",
        position: "absolute",
    },
    recaptchaDisclaimer: {
        marginLeft: "auto",
        maxWidth: 300,
    },
    spacing: {
        marginRight: 8,
    },
    tabLabel: {
        display: "flex",
        fontSize: 600,
    },
    tabsWrapper: {
        "& .MuiTabs-root": {
            borderBottom: `1px solid ${theme.customColors.borderColor}`,
        },
    },
    zanderDialog: {
        "& .MuiDialog-paper": {
            maxHeight: "90%",
            maxWidth: 660,
            width: "100%",
        },
    },
    zanderDialogActions: {
        alignItems: "left",
        display: "flex",
        padding: 20,
    },
}))
