import * as React from "react";
const SvgBeer15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "beer-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 5 L12 2 C12 2 11 1 7.5 1 C4 1 3 2 3 2 L3 5 C3.0288 6.3915 3.3706 7.7586 4 9 C4.6255 10.4348 4.6255 12.0652 4 13.5 C4 13.5 4 14.5 7.5 14.5 C11 14.5 11 13.5 11 13.5 C10.3745 12.0652 10.3745 10.4348 11 9 C11.6294 7.7586 11.9712 6.3915 12 5 z M7.5 13.5 C6.7034 13.535 5.9063 13.4404 5.1400003 13.22 C5.3430004 12.497601 5.4440002 11.750401 5.4400005 11 L9.56 11 C9.556001 11.7504 9.657001 12.4976 9.860001 13.22 C9.0937 13.4404 8.2966 13.535 7.5 13.5 z M7.5 5 C6.3136 5.0299 5.1306 4.8609 4 4.5 L4 2.5 C5.131 2.1411 6.3137 1.9722 7.5 2 C8.6863 1.9722 9.869 2.1411 11 2.5 L11 4.5 C9.8694 4.8609 8.6864 5.0299 7.5 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 5 L12 2 C12 2 11 1 7.5 1 C4 1 3 2 3 2 L3 5 C3.0288 6.3915 3.3706 7.7586 4 9 C4.6255 10.4348 4.6255 12.0652 4 13.5 C4 13.5 4 14.5 7.5 14.5 C11 14.5 11 13.5 11 13.5 C10.3745 12.0652 10.3745 10.4348 11 9 C11.6294 7.7586 11.9712 6.3915 12 5 z M7.5 13.5 C6.7034 13.535 5.9063 13.4404 5.1400003 13.22 C5.3430004 12.497601 5.4440002 11.750401 5.4400005 11 L9.56 11 C9.556001 11.7504 9.657001 12.4976 9.860001 13.22 C9.0937 13.4404 8.2966 13.535 7.5 13.5 z M7.5 5 C6.3136 5.0299 5.1306 4.8609 4 4.5 L4 2.5 C5.131 2.1411 6.3137 1.9722 7.5 2 C8.6863 1.9722 9.869 2.1411 11 2.5 L11 4.5 C9.8694 4.8609 8.6864 5.0299 7.5 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBeer15;
