export const SOURCE_TYPES = {
    custom: "custom",
    geojson: "geojson",
    image: "image",
    raster: "raster",
    rasterDem: "raster-dem",
    vector: "vector",
    video: "video",
    canvas: "canvas",
} as const

type SourceKeys = keyof typeof SOURCE_TYPES
export type SourceTypes = (typeof SOURCE_TYPES)[SourceKeys]
