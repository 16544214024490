import React from "react"
import { InputBase, Typography, TextField } from "@mui/material"

function NumberProperty(props) {
    function handleChange(e) {
        let number = e.target.value.replace(/-?\d+,\d+/g, "")
        props.onPropertyChanged(number)
    }

    return (
        <div className="property" onClick={props.onClick}>
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" value={props.title} onChange={props.onTitleChanged} />
                )}
                {!props.editable && (
                    <Typography className="name" variant="body1">
                        {props.title}
                    </Typography>
                )}
                <div className="input-container">
                    <InputBase className="number" value={props.value} onChange={handleChange} />
                </div>
            </div>
        </div>
    )
}

export default NumberProperty
