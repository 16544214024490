import * as React from "react";
const SvgGrocery11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "grocery-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.75 1.5 C9.7464 1.4999 9.4324 1.4942 9.1191 1.6875 C8.8041 1.8819 8.5 2.3013 8.5 3 L8.2539 3 L1 3 L1.75 6.5 C1.8571 7 2.5 7 2.5 7 L8.5 7 C8.5 7 8.4973 7.2466 8.3477 7.4961 C8.1981 7.7455997 7.9306 8 7.25 8 L2 8 C1.6619 7.9952 1.6619 8.5048 2 8.5 L3.25 8.5 L7.25 8.5 C8.0694 8.5 8.552 8.1295 8.7773 7.7539 C9.0027 7.3784 9 7 9 7 L9 3 C9 2.433 9.1959 2.2275 9.3809 2.1133 C9.5659 1.9991 9.7461 2 9.7461 2 C9.7474 2 9.7487 2 9.75 2 L10.25 2 C10.5881 2.0048 10.5881 1.4951999 10.25 1.5 L9.7539 1.5 L9.75 1.5 z M7.25 8.5 C6.8358 8.5 6.5 8.8358 6.5 9.25 C6.5 9.6642 6.8358 10 7.25 10 C7.6642 10 8 9.6642 8 9.25 C8 8.8358 7.6642 8.5 7.25 8.5 z M3.25 8.5 C2.8358 8.5 2.5 8.8358 2.5 9.25 C2.5 9.6642 2.8358 10 3.25 10 C3.6642 10 4 9.6642 4 9.25 C4 8.8358 3.6642 8.5 3.25 8.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.75 1.5 C9.7464 1.4999 9.4324 1.4942 9.1191 1.6875 C8.8041 1.8819 8.5 2.3013 8.5 3 L8.2539 3 L1 3 L1.75 6.5 C1.8571 7 2.5 7 2.5 7 L8.5 7 C8.5 7 8.4973 7.2466 8.3477 7.4961 C8.1981 7.7455997 7.9306 8 7.25 8 L2 8 C1.6619 7.9952 1.6619 8.5048 2 8.5 L3.25 8.5 L7.25 8.5 C8.0694 8.5 8.552 8.1295 8.7773 7.7539 C9.0027 7.3784 9 7 9 7 L9 3 C9 2.433 9.1959 2.2275 9.3809 2.1133 C9.5659 1.9991 9.7461 2 9.7461 2 C9.7474 2 9.7487 2 9.75 2 L10.25 2 C10.5881 2.0048 10.5881 1.4951999 10.25 1.5 L9.7539 1.5 L9.75 1.5 z M7.25 8.5 C6.8358 8.5 6.5 8.8358 6.5 9.25 C6.5 9.6642 6.8358 10 7.25 10 C7.6642 10 8 9.6642 8 9.25 C8 8.8358 7.6642 8.5 7.25 8.5 z M3.25 8.5 C2.8358 8.5 2.5 8.8358 2.5 9.25 C2.5 9.6642 2.8358 10 3.25 10 C3.6642 10 4 9.6642 4 9.25 C4 8.8358 3.6642 8.5 3.25 8.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGrocery11;
