import * as React from "react";
const SvgDogPark15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "dog-park-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.1 3.3216 C12.4 2.6216 11.7 2.5 11 2.5 L11 2 C11 2 11.0667 1.2214999 10.3 1.2216 C9.9137 1.2216 9.6 1.2 9.5 2 L9.1 4.5215 L11.5 6.5 L13.5 6.5 C14.9 6.5 15 5.5412 15 5.5412 L13.1 3.3216 z M11.75 4.5 C11.6119 4.5 11.5 4.3881 11.5 4.25 C11.5 4.1119 11.6119 4 11.75 4 C11.8881 4 12 4.1119 12 4.25 C12 4.3881 11.8881 4.5 11.75 4.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 12 L11.1 7.9216 L8 5.5 L3.5 5.5 L3.5 3.5 C3.5 3.1 3.7765 3.0053 4 3 C4.4941 2.9882 4.75 3 4.75 3 C5.1642 3 5.5 2.6642 5.5 2.25 C5.5 1.8357999 5.1642 1.5 4.75 1.5 C4.75 1.5 3.8 1.5215 3.2 1.7216 C2.6 1.9216001 2 2.6216002 2 3.6216002 L2 7.5216002 C2 9.2216 1.3 9.5 1 9.5 C1 9.5 0 9.5215 0 10.5215 L0 12.721499 C0 12.721499 0 13.5215 0.8 13.5215 L1 13.5215 L1.5 13.5215 L2 13.5215 L2 13.0215 L2 12.8215 C2 12.4215 1.8 12.221499 1.5 12.1215 L1.5 11.0215 C2.5 11.0215 2.6 10.8215 3 10.721499 L3.55 12.9176 C3.6499999 13.2176 3.75 13.4176 4.05 13.5176 L5.05 13.5176 L6 13.5 L6 12.7 C6 12.0236 5 12 5 12 L5 9.5 L8.5 9.5 L9.2 12.1216 C9.599999 13.5216 10.5 13.5 10.5 13.5 L11 13.5 L12 13.5 L12 12.7 C12 11.9882 11 12 11 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.1 3.3216 C12.4 2.6216 11.7 2.5 11 2.5 L11 2 C11 2 11.0667 1.2214999 10.3 1.2216 C9.9137 1.2216 9.6 1.2 9.5 2 L9.1 4.5215 L11.5 6.5 L13.5 6.5 C14.9 6.5 15 5.5412 15 5.5412 L13.1 3.3216 z M11.75 4.5 C11.6119 4.5 11.5 4.3881 11.5 4.25 C11.5 4.1119 11.6119 4 11.75 4 C11.8881 4 12 4.1119 12 4.25 C12 4.3881 11.8881 4.5 11.75 4.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 12 L11.1 7.9216 L8 5.5 L3.5 5.5 L3.5 3.5 C3.5 3.1 3.7765 3.0053 4 3 C4.4941 2.9882 4.75 3 4.75 3 C5.1642 3 5.5 2.6642 5.5 2.25 C5.5 1.8357999 5.1642 1.5 4.75 1.5 C4.75 1.5 3.8 1.5215 3.2 1.7216 C2.6 1.9216001 2 2.6216002 2 3.6216002 L2 7.5216002 C2 9.2216 1.3 9.5 1 9.5 C1 9.5 0 9.5215 0 10.5215 L0 12.721499 C0 12.721499 0 13.5215 0.8 13.5215 L1 13.5215 L1.5 13.5215 L2 13.5215 L2 13.0215 L2 12.8215 C2 12.4215 1.8 12.221499 1.5 12.1215 L1.5 11.0215 C2.5 11.0215 2.6 10.8215 3 10.721499 L3.55 12.9176 C3.6499999 13.2176 3.75 13.4176 4.05 13.5176 L5.05 13.5176 L6 13.5 L6 12.7 C6 12.0236 5 12 5 12 L5 9.5 L8.5 9.5 L9.2 12.1216 C9.599999 13.5216 10.5 13.5 10.5 13.5 L11 13.5 L12 13.5 L12 12.7 C12 11.9882 11 12 11 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDogPark15;
