import React, { memo, useContext, useState } from "react"
import { Close } from "@mui/icons-material"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom"
import InfoIcon from "@mui/icons-material/Info"
import MoreHoriz from "@mui/icons-material/MoreHoriz"
import { Icon } from "@mui/material"
import { CircularProgress, Divider, Tooltip } from "@mui/material"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Switch from "@mui/material/Switch"
import { useDispatch, useSelector } from "react-redux"
import { toggleAppLayer } from "actions/globalActions"
import { PROPERTY_TYPES } from "constants/styles/propertyTypes"
import { BufferWWContext } from "features/buffer/components/BufferWWContextProvider"
import * as layerSelectorActions from "reducers/layerSelector"
import * as mapActions from "reducers/map"
import { getLayerStyles, getLayerVisibility } from "selectors/layerSelector"
import OverflowTip from "../../common/OverflowTip/OverflowTip"
import Context from "./contextMenu"
import useLayerStyles from "./styles/layerStyles"

const Layer = ({ layer, layerHandlers }) => {
    const classes = useLayerStyles()
    const [contextOpen, setContextOpen] = useState(false)
    const anchorRef = React.useRef(null)

    const visible = useSelector(getLayerVisibility(layer.resourceId))
    const styles = useSelector(getLayerStyles(layer.resourceId))
    const dispatch = useDispatch()
    const opacity = layer.opacity
    const bufferWWContext = useContext(BufferWWContext)

    const toggleCMenu = e => {
        if (e) e.stopPropagation()
        setContextOpen(contextOpen => !contextOpen)
        return null
    }

    const closeCMenu = () => {
        setContextOpen(false)
    }

    const handleOpacity = (_, newValue) => {
        dispatch(layerSelectorActions.setLayerOpacity({ layerResourceId: layer.resourceId, opacity: newValue }))
    }

    const handleCommitOpacity = () => {
        styles.forEach(style => {
            const newProperties = style.properties.map(paintProperty =>
                paintProperty.name.includes("opacity") ? { ...paintProperty, value: opacity / 100 } : paintProperty,
            )
            dispatch(
                mapActions.updateMapPaint({
                    layerId: style.styleId,
                    properties: newProperties.filter(property => property.type === PROPERTY_TYPES.paint),
                }),
            )
            dispatch(
                layerSelectorActions.changePropertiesOfLayerStyle({
                    layerId: layer.resourceId,
                    properties: newProperties,
                    styleId: style.styleId,
                }),
            )
        })
    }

    const toggleVisibility = visible => {
        const resourceId = layer.resourceId
        dispatch(toggleAppLayer({ resourceId, visible }))
    }

    const onToggle = () => {
        toggleVisibility(!visible)
    }

    // Layer's elements
    const renderVerticalBufferIndicator = () => {
        return (
            <Divider
                orientation="vertical"
                sx={{ borderStyle: "dashed", height: `calc(100% + 16px)`, mr: 2, mt: -2 }}
            />
        )
    }

    const renderSwicthAndName = () => {
        return (
            <>
                <Switch
                    checked={!!visible}
                    className={classes.switch}
                    color={!layer.isShown ? "default" : "primary"}
                    edge="end"
                    inputProps={{ "aria-labelledby": "switch-list-label-wifi" }}
                    onClick={() => onToggle()}
                />
                <div className="layer__text">
                    <OverflowTip color="inherit" title="" variant="body2">
                        {layer.name}
                    </OverflowTip>
                </div>
            </>
        )
    }

    const renderTemporaryLayerIndicator = () => {
        return (
            <>
                <Tooltip title={`Temporary Layer`}>
                    <Chip
                        color="warning"
                        icon={<HourglassBottomIcon />}
                        size="small"
                        sx={{
                            "& .MuiChip-icon": { mr: 0.5 },
                            "& .MuiChip-label": { display: "none" },
                            mr: 1,
                        }}
                    />
                </Tooltip>
                <Divider orientation="vertical" sx={{ mr: 1 }} />
            </>
        )
    }

    const renderFilteredIndicator = () => {
        return (
            <>
                <Tooltip title={`Filter(s) applied`}>
                    <Chip
                        color="warning"
                        icon={<FilterAltIcon />}
                        size="small"
                        sx={{
                            "& .MuiChip-icon": { mr: 0.5 },
                            "& .MuiChip-label": { display: "none" },
                            mr: 1,
                        }}
                    />
                </Tooltip>
                <Divider orientation="vertical" sx={{ mr: 1 }} />
            </>
        )
    }

    const renderVisibilityIndicator = () => {
        return (
            <>
                <Tooltip title={`Layer is only visible between zoom levels ${layer.minZoom} and ${layer.maxZoom}`}>
                    <Icon className={classes.grayIcon}>
                        <InfoIcon />
                    </Icon>
                </Tooltip>
                <Divider orientation="vertical" sx={{ mr: 1 }} />
            </>
        )
    }

    const renderLoadingAndCancelBtn = () => {
        return (
            <>
                <CircularProgress size={"1em"} sx={{ mx: 2 }} />
                <Divider orientation="vertical" sx={{ mr: 1 }} />
                <IconButton
                    className={classes.cancelBtn}
                    size="large"
                    onClick={() => bufferWWContext.cancelGenerate(layer.resourceId)}
                >
                    <Close fontSize="small" />
                </IconButton>
            </>
        )
    }

    const renderActionMenu = () => {
        return (
            <>
                <IconButton
                    aria-controls={contextOpen ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    className={classes.contextMenuBtn}
                    ref={anchorRef}
                    onClick={e => {
                        e.currentTarget.blur()
                        toggleCMenu()
                    }}
                >
                    <MoreHoriz />
                </IconButton>
                <Context
                    anchorEl={anchorRef.current}
                    closeCMenu={closeCMenu}
                    layer={layer}
                    layerHandlers={layerHandlers}
                    opacity={opacity}
                    open={contextOpen}
                    toggleCMenu={toggleCMenu}
                    handleCommitOpacity={handleCommitOpacity}
                    handleOpacity={handleOpacity}
                    onToggle={onToggle}
                />
            </>
        )
    }

    return (
        <div className="layer">
            {layer.isBuffer && renderVerticalBufferIndicator()}

            {renderSwicthAndName()}

            {layer.isBuffer && !layer.options.loading && renderTemporaryLayerIndicator()}

            {layer.isFiltered && renderFilteredIndicator()}

            {!layer.isShown && renderVisibilityIndicator()}

            {layer.options.loading && renderLoadingAndCancelBtn()}

            {!layer.options.loading && renderActionMenu()}
        </div>
    )
}

export default memo(Layer)
