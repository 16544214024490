import * as React from "react";
const SvgHospital15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "hospital-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1 C6.4 1 6 1.4 6 2 L6 6 L2 6 C1.4 6 1 6.4 1 7 L1 8 C1 8.6 1.4 9 2 9 L6 9 L6 13 C6 13.6 6.4 14 7 14 L8 14 C8.6 14 9 13.6 9 13 L9 9 L13 9 C13.6 9 14 8.6 14 8 L14 7 C14 6.4 13.6 6 13 6 L9 6 L9 2 C9 1.4 8.6 1 8 1 L7 1 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 1 C6.4 1 6 1.4 6 2 L6 6 L2 6 C1.4 6 1 6.4 1 7 L1 8 C1 8.6 1.4 9 2 9 L6 9 L6 13 C6 13.6 6.4 14 7 14 L8 14 C8.6 14 9 13.6 9 13 L9 9 L13 9 C13.6 9 14 8.6 14 8 L14 7 C14 6.4 13.6 6 13 6 L9 6 L9 2 C9 1.4 8.6 1 8 1 L7 1 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHospital15;
