import * as React from "react";
const SvgIogp1108 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "329.85, 267, 97.75998, 106.23001", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "378.73,306.95 378.73,306.95", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M378.73 371.23 L378.73 306.95 L331.85 306.95 L378.73 371.23 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("path", { d: "M378.73 371.23 L425.61 306.95 L378.73 306.95 L378.73 371.23 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "378.73,306.95 331.85,306.95 378.73,371.23", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "378.73,306.95 425.61,306.95 378.73,371.23", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "378.73,306.95 378.73,267.25", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1108;
