import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles(theme => ({
    cardBody: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    cardTitleContent: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginRight: theme.spacing(1),
        width: "100%",
    },
    cardTitleText: {
        cursor: "pointer",
        flexGrow: 1,
    },
    legend: {
        padding: "16px 32px",
    },
    legendIcon: {
        height: 24,
    },
    legendItem: {
        alignItems: "center",
        display: "flex",
        marginBottom: 8,
    },
    legendText: {
        flexGrow: 1,
    },
    root: {
        backgroundColor: "#fff",
        overflow: "auto",
    },
    section: {
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        margin: "8px 20px",
    },
    title: {
        backgroundColor: theme.customColors.appBgColor,
        padding: "8px 16px",
    },
}))
