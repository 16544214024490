import * as React from "react";
const SvgIogp1028 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "289.27, 206.32, 191.84, 166.26999", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "289.77,289.45 480.61,289.45", strokeWidth: 1, style: {
  fill: "none",
  stroke: "#E6E600"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "385.19,289.45 385.19,289.45", strokeWidth: 1, style: {
  fill: "none",
  stroke: "#E6E600"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "337.48,372.09 432.9,206.82", strokeWidth: 1, style: {
  fill: "none",
  stroke: "#E6E600"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "337.48,206.82 432.9,372.09", strokeWidth: 1, style: {
  fill: "none",
  stroke: "#E6E600"
} }));
export default SvgIogp1028;
