import { ComponentProps, FCWC } from "react"
import { MapFilterRecord } from "model/map/MapFilterRecordType"
import { MapLayer as MapLayerType } from "model/map/MapLayer"
import { Source as SourceType } from "model/map/Source"
import Map from "@emblautec/react-map-gl"
import { DeckGLOverlay } from "features/map/components/DeckGL"
import { AisLayersData } from "utils/customHooks/map/useAisLayers"
import AisMapLayer from "../components/AisMapLayer/AisMapLayer"
import DefaultPermaHandlers from "../components/DefaultPermaHandlers/DefaultPermaHandlers"
import MapLayer from "../components/MapLayer/MapLayer"
import MapResizeObserver from "../components/MapResizeObserver/MapResizeObserver"
import MapSource from "../components/MapSource/MapSource"
import { useSourceFirstPolygonLayerIdDict } from "./logic/useSourceFirstGeometricLayerIdDict"

type Props = {
    aisLayersData?: AisLayersData
    filters?: MapFilterRecord
    id: string
    layers: MapLayerType[]
    layoutsDict: Record<string, any>
    paintsDict: Record<string, any>
    sources: SourceType[]
    zoomRangesDict: Record<string, any>
    isPublic: boolean
} & ComponentProps<typeof Map>

const CustomMap: FCWC<Props> = ({
    aisLayersData,
    children,
    filters,
    layers,
    layoutsDict,
    paintsDict,
    sources,
    zoomRangesDict,
    isPublic,
    ...mapOptions
}) => {
    const sourceFirstPolygonLayerIdDict: Record<string, any> = useSourceFirstPolygonLayerIdDict({ layers })

    return (
        <Map mapLib={import("@emblautec/mapbox-gl")} {...mapOptions}>
            <MapResizeObserver mapId={mapOptions.id} />
            <DefaultPermaHandlers />
            <DeckGLOverlay />
            {children}
            {sources.map(source => {
                return <MapSource key={source.id} source={source} isPublic={isPublic} />
            })}
            {layers.map(layer => (
                <MapLayer
                    key={layer.layerId}
                    {...layer}
                    filters={filters ? filters[layer.resourceId] : []}
                    layoutProperties={layoutsDict[layer.layerId]?.properties}
                    maxZoom={zoomRangesDict[layer.layerId].maxZoom}
                    minZoom={zoomRangesDict[layer.layerId].minZoom}
                    paintProperties={paintsDict[layer.layerId]?.properties}
                    polygonLayerId={sourceFirstPolygonLayerIdDict[layer.layerId]}
                />
            ))}
            {aisLayersData &&
                aisLayersData.aisLayers.map(aisLayer => {
                    const { aisLayoutsDict, aisPaintsDict, aisZoomRangesDict } = aisLayersData

                    return (
                        <AisMapLayer
                            key={aisLayer.layerId}
                            {...aisLayer}
                            layoutProperties={aisLayoutsDict[aisLayer.layerId]?.properties}
                            maxZoom={aisZoomRangesDict[aisLayer.layerId].maxZoom}
                            minZoom={aisZoomRangesDict[aisLayer.layerId].minZoom}
                            paintProperties={aisPaintsDict[aisLayer.layerId]?.properties}
                        />
                    )
                })}
        </Map>
    )
}

export default CustomMap
