import React, { createContext, useState, useContext, ReactNode, useEffect } from "react"
import { Dayjs } from "dayjs"
import { dummyFilter } from "../components/LayerFilterEditor/components/FiltersList/FiltersList"
import {
    DateType,
    LiteralType,
    NumericType,
    Types,
    UndefinedType,
} from "../components/LayerFilterEditor/models/FilterType"
import {
    DateOperators,
    NumericOperators,
    Operators,
    StringOperators,
    UndefinedOperators,
} from "../components/LayerFilterEditor/models/OperatorType"

export const selectableOperatorsByType: Record<Types, Operators[]> = {
    [Types.CharacterVarying]: [Operators.Equal, Operators.NotEqual, Operators.Contains],
    [Types.Date]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
    ],
    [Types.DoublePrecision]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
    ],
    [Types.Integer]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
    ],
    [Types.String]: [Operators.Equal, Operators.NotEqual, Operators.Contains],
    [Types.Undefined]: [],
}

export type FilterType = {
    applied?: boolean
    fieldName: string
} & (
    | {
          operatorName: StringOperators
          type: LiteralType
          value: string | null
      }
    | {
          operatorName: NumericOperators
          secondValue?: number
          type: NumericType
          value: number | null
      }
    | {
          operatorName: DateOperators
          secondValue?: Dayjs
          type: DateType
          value: Dayjs | null
      }
    | {
          operatorName: UndefinedOperators
          type: UndefinedType
          value: null
      }
)

type FilterContextProps = {
    addFilter: (x: FilterType) => void
    applyFilter: () => void
    areAllFiltersSet: () => boolean
    emptySelectedFilter: () => void
    filters: FilterType[]
    removeFilter: (index: number) => void
    selectedFilter: FilterType | null
    isFilterSelected: (filter: FilterType) => boolean
    updateFilter: (filter: FilterType) => void
    updateFilterSelection: (index: number) => void
}

type FilterContextProviderProps = {
    children: ReactNode
    initialFiltersList: FilterType[]
    onFiltersUpdate: (filters: FilterType[]) => void
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined)

export const FilterContextProvider: React.FC<FilterContextProviderProps> = ({
    children,
    initialFiltersList,
    onFiltersUpdate,
}) => {
    const [filters, setFilters] = useState<FilterType[]>(initialFiltersList ?? [])
    const [filtersUpdated, setFiltersUpdated] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState<FilterType | null>(null)

    const addFilter = (newFilter: FilterType) => {
        if (newFilter.type === Types.String && newFilter.value === null) {
            newFilter.value = ""
        }
        setFilters(prevFilters => [...prevFilters, newFilter])
        setSelectedFilter(newFilter)
    }

    const removeFilter = async (index: number) => {
        if (filters.length == 1) {
            setFilters([])
            setSelectedFilter(null)
        } else {
            setFilters(prevFilters => prevFilters.filter((_, i) => i !== index))
            if (selectedFilter === filters[index]) {
                setSelectedFilter(null)
            }
        }
        setFiltersUpdated(true)
    }

    const updateFilterSelection = (index: number) => {
        setSelectedFilter(filters[index])
    }

    const areAllFiltersSet = () => {
        return filters.every(
            x => x.fieldName !== "" && x.type !== Types.Undefined && x.operatorName !== Operators.Undefined,
        )
    }

    const updateFilter = (updatedFilter: FilterType) => {
        setFilters(prevFilters => {
            const newFilters = [...prevFilters]
            newFilters[getSelectedFilterIndex()] = updatedFilter
            return newFilters
        })
        setSelectedFilter(updatedFilter)
    }

    const getSelectedFilterIndex = () => {
        const selectedIndex = filters.findIndex(filter => filter === selectedFilter)
        return selectedIndex
    }

    const emptySelectedFilter = () => {
        removeFilter(getSelectedFilterIndex())
        addFilter({ ...dummyFilter })
    }

    const applyFilter = () => {
        if (selectedFilter) {
            const index = getSelectedFilterIndex()
            if (index !== -1) {
                const updatedFilter = { ...selectedFilter, applied: true }
                setSelectedFilter(updatedFilter)
                const updatedFilters = [...filters]
                updatedFilters[index] = updatedFilter
                setFilters(updatedFilters)
            }
        }
        setFiltersUpdated(true)
    }

    const isFilterSelected = (filter: FilterType) => {
        return filter === selectedFilter
    }

    useEffect(() => {
        if (filtersUpdated) {
            const appliedFilters = filters.filter(filter => filter.applied).map(({ applied, ...rest }) => rest)
            onFiltersUpdate(appliedFilters)
            setFiltersUpdated(false)
        }
    }, [filtersUpdated])

    const contextValues: FilterContextProps = {
        addFilter,
        applyFilter,
        areAllFiltersSet,
        emptySelectedFilter,
        filters,
        removeFilter,
        selectedFilter,
        isFilterSelected,
        updateFilter,
        updateFilterSelection,
    }

    return <FilterContext.Provider value={contextValues}>{children}</FilterContext.Provider>
}

export const useFilterContext = () => {
    const context = useContext(FilterContext)
    if (!context) {
        throw new Error("useFilterContext must be used within a FilterContextProvider")
    }
    return context
}
