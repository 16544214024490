import React from "react"
import dayjs from "dayjs"
import Linkify from "react-linkify"
import Typography from "../components/common/CustomTypography/CustomTypography"
import { POSITION_RECEIVED_PROPERTY, getRenamedPropertyKey } from "../components/sidebar/ais/utils"

const generatePropertyName = propertyName => {
    const re = /(?:[a-zA-Z0-9])_(?=[a-zA-Z0-9])/g
    const originalPropName = propertyName
    let match
    while ((match = re.exec(originalPropName)) != null) {
        propertyName = propertyName.slice(0, match.index + 1) + " " + propertyName.slice(match.index + 2)
    }
    return propertyName
}

export const excludedProperties = [
    "ogc_fid",
    "gid",
    "geom_type",
    "?column?",
    "icon-rotate",
    "text-field",
    "icon-opacity",
    "icon-image",
]

export function BuildProperties(feature, classes, progressColumn) {
    let properties = Object.keys(feature.properties).reduce((a, b) => {
        a.push({
            key: b,
            value: feature.properties[b],
        })
        return a
    }, [])

    let propertyPresent = false

    const featurePropertiesElements = properties.map((propKeyValue, propIndex) => {
        let value

        if (excludedProperties.indexOf(propKeyValue.key) >= 0 || propKeyValue.key === progressColumn) {
            return null
        }

        propertyPresent = true

        if (propKeyValue.key.includes("dato")) {
            value = dayjs().format("L")
        } else {
            value = propKeyValue.value
        }

        if (propKeyValue.key.includes(POSITION_RECEIVED_PROPERTY)) {
            propKeyValue.key = getRenamedPropertyKey(POSITION_RECEIVED_PROPERTY)
        }

        return (
            <tr key={propIndex}>
                <td className={classes.propertyKey}>
                    <Typography textWeight="bold" variant="body2">
                        {generatePropertyName(propKeyValue.key)}
                    </Typography>
                </td>
                <td className={classes.propertyValue}>
                    <Typography variant="body2">
                        <Linkify
                            componentDecorator={(decoratedHref, _, key) => (
                                <a key={key} href={decoratedHref} rel="noreferrer" target="_blank">
                                    Link
                                </a>
                            )}
                        >
                            {value}
                        </Linkify>
                    </Typography>
                </td>
            </tr>
        )
    })

    if (!propertyPresent) {
        return { featurePropertiesElements: <div className={classes.noData}>No properties to display</div> }
    }
    return { featurePropertiesElements, hasPropertiesToDisplay: true }
}
