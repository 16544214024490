import * as React from "react";
const SvgFireStation15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fire-station-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0.5 L5 4.5 L3.5 2.5 C2.9452 3.4753 0.8036 5.7924 0.8036 8.3036 C0.8036 12.002 3.8017 15 7.5 15 C11.198299 15 14.1964 12.002 14.1964 8.3036 C14.1964 5.7924004 12.0548 3.4753003 11.5 2.5000005 L10 4.5000005 L7.5 0.5 z M7.5 7 C7.5 7 10 9.5618 10 11.5 C10 12.3371 9.1741 13.5 7.5 13.5 C5.8259 13.5 5 12.3371 5 11.5 C5 9.6283 7.5 7 7.5 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0.5 L5 4.5 L3.5 2.5 C2.9452 3.4753 0.8036 5.7924 0.8036 8.3036 C0.8036 12.002 3.8017 15 7.5 15 C11.198299 15 14.1964 12.002 14.1964 8.3036 C14.1964 5.7924004 12.0548 3.4753003 11.5 2.5000005 L10 4.5000005 L7.5 0.5 z M7.5 7 C7.5 7 10 9.5618 10 11.5 C10 12.3371 9.1741 13.5 7.5 13.5 C5.8259 13.5 5 12.3371 5 11.5 C5 9.6283 7.5 7 7.5 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFireStation15;
