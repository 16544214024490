import * as React from "react";
const SvgIogp1115 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "225.5, 185, 246.54001, 246.54999", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "348.77,308.28 348.77,308.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "307.76,308.28 307.96,304.26 308.55,300.28 309.53,296.37 310.88,292.58 312.6,288.95 314.67,285.49 317.07,282.26 319.77,279.28 322.75,276.58 325.99,274.18 329.44,272.11 333.07,270.39 336.86,269.03 340.77,268.06 344.75,267.47 348.77,267.27 352.79,267.47 356.77,268.06 360.67,269.03 364.46,270.39 368.1,272.11 371.55,274.18 374.78,276.58 377.76,279.28 380.47,282.26 382.86,285.49 384.93,288.95 386.65,292.58 388.01,296.37 388.99,300.28 389.58,304.26 389.77,308.28 389.58,312.3 388.99,316.28 388.01,320.18 386.65,323.97 384.93,327.61 382.86,331.06 380.47,334.29 377.76,337.27 374.78,339.98 371.55,342.37 368.1,344.44 364.46,346.16 360.67,347.52 356.77,348.5 352.79,349.09 348.77,349.28 344.75,349.09 340.77,348.5 336.86,347.52 333.07,346.16 329.44,344.44 325.99,342.37 322.75,339.98 319.77,337.27 317.07,334.29 314.67,331.06 312.6,327.61 310.88,323.97 309.53,320.18 308.55,316.28 307.96,312.3 307.76,308.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.71 310.28 L338.71 306.28 L338.52 308.28 L338.71 310.28 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.71 310.28 L339.3 312.2 L338.71 306.28 L338.71 310.28 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M339.3 312.2 L339.3 304.35 L338.71 306.28 L339.3 312.2 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M339.3 312.2 L340.24 313.97 L339.3 304.35 L339.3 312.2 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M340.24 313.97 L340.24 302.58 L339.3 304.35 L340.24 313.97 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M340.24 313.97 L341.52 315.53 L340.24 302.58 L340.24 313.97 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M341.52 315.53 L341.52 301.03 L340.24 302.58 L341.52 315.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M341.52 315.53 L343.07 316.8 L341.52 301.03 L341.52 315.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343.07 316.8 L343.07 299.75 L341.52 301.03 L343.07 316.8 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343.07 316.8 L344.84 317.75 L343.07 299.75 L343.07 316.8 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M344.84 317.75 L344.84 298.81 L343.07 299.75 L344.84 317.75 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M344.84 317.75 L346.77 318.33 L344.84 298.81 L344.84 317.75 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.77 318.33 L346.77 298.22 L344.84 298.81 L346.77 318.33 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M346.77 318.33 L348.77 318.53 L346.77 298.22 L346.77 318.33 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.77 318.53 L348.77 298.02 L346.77 298.22 L348.77 318.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.77 318.53 L350.77 318.33 L348.77 298.02 L348.77 318.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.77 318.33 L350.77 298.22 L348.77 298.02 L350.77 318.33 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M350.77 318.33 L352.69 317.75 L350.77 298.22 L350.77 318.33 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.69 317.75 L352.69 298.81 L350.77 298.22 L352.69 317.75 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.69 317.75 L354.46 316.8 L352.69 298.81 L352.69 317.75 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M354.46 316.8 L354.46 299.75 L352.69 298.81 L354.46 316.8 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M354.46 316.8 L356.02 315.53 L354.46 299.75 L354.46 316.8 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M356.02 315.53 L356.02 301.03 L354.46 299.75 L356.02 315.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M356.02 315.53 L357.29 313.97 L356.02 301.03 L356.02 315.53 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.29 313.97 L357.29 302.58 L356.02 301.03 L357.29 313.97 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.29 313.97 L358.24 312.2 L357.29 302.58 L357.29 313.97 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.24 312.2 L358.24 304.35 L357.29 302.58 L358.24 312.2 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.24 312.2 L358.82 310.28 L358.24 304.35 L358.24 312.2 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.82 310.28 L358.82 306.28 L358.24 304.35 L358.82 310.28 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M358.82 310.28 L359.02 308.28 L358.82 306.28 L358.82 310.28 z", fillRule: "evenodd", style: {
  fill: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "338.52,308.28 338.71,306.28 339.3,304.35 340.24,302.58 341.52,301.03 343.07,299.75 344.84,298.81 346.77,298.22 348.77,298.02 350.77,298.22 352.69,298.81 354.46,299.75 356.02,301.03 357.29,302.58 358.24,304.35 358.82,306.28 359.02,308.28 358.82,310.28 358.24,312.2 357.29,313.97 356.02,315.53 354.46,316.8 352.69,317.75 350.77,318.33 348.77,318.53 346.77,318.33 344.84,317.75 343.07,316.8 341.52,315.53 340.24,313.97 339.3,312.2 338.71,310.28 338.52,308.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "348.77,431.3 348.77,349.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "348.77,267.27 348.77,185.25", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "225.75,308.28 307.76,308.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "389.77,308.28 471.79,308.28", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#0070FF"
} }));
export default SvgIogp1115;
