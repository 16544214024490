import * as React from "react";
const SvgEntrance15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "entrance-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 6.5 L7 5.5 C7 4.9477 6.5523 4.5 6 4.5 C5.4477 4.5 5 4.9477 5 5.5 L5 8.5 L7 6.5 z M4.65 10.56 L5 3 C5 2.4477 5.4477 2 6 2 C6.5523 2 7 2.4477 7 3 C7 3.5523 6.5523 4 6 4 C5.4477 4 5 3.5523 5 3 L4.65 10.56 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 6 L14 6 C14 6.5523 13.5523 7 13 7 L11.42 7 C11.1542 6.9985 10.8987 7.1028 10.71 7.29 L5.29 12.71 C5.1037 12.8947 4.8523 12.9989 4.59 13 L2 13 C1.4477 13 1 12.5523 1 12 L1 12 C1 11.4477005 1.4477 11 2 11 L3.5900002 11 C3.8523002 10.9989 4.1037 10.8947 4.29 10.71 L9.71 5.29 C9.8987 5.1028 10.1542 4.9985 10.42 5 L13 5 C13.5523 5 14 5.4477 14 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 6.5 L7 5.5 C7 4.9477 6.5523 4.5 6 4.5 C5.4477 4.5 5 4.9477 5 5.5 L5 8.5 L7 6.5 z M4.65 10.56 L5 3 C5 2.4477 5.4477 2 6 2 C6.5523 2 7 2.4477 7 3 C7 3.5523 6.5523 4 6 4 C5.4477 4 5 3.5523 5 3 L4.65 10.56 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 6 L14 6 C14 6.5523 13.5523 7 13 7 L11.42 7 C11.1542 6.9985 10.8987 7.1028 10.71 7.29 L5.29 12.71 C5.1037 12.8947 4.8523 12.9989 4.59 13 L2 13 C1.4477 13 1 12.5523 1 12 L1 12 C1 11.4477005 1.4477 11 2 11 L3.5900002 11 C3.8523002 10.9989 4.1037 10.8947 4.29 10.71 L9.71 5.29 C9.8987 5.1028 10.1542 4.9985 10.42 5 L13 5 C13.5523 5 14 5.4477 14 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgEntrance15;
