import { useFetchDprDatasetColumnsQuery, useFetchLocationStatusHistoryQuery } from "features/dprIntegration/api"
import useInfoboxToUse from "./useInfoboxToUse"

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature
}
const useDprLocationHistory = ({ feature }: Props) => {
    const infoboxToUse = useInfoboxToUse(feature.sourceLayer)

    const {
        data: dprDatasetColumns = null,
        isError: columnsError,
        isLoading: columnsLoading,
    } = useFetchDprDatasetColumnsQuery(feature?.sourceLayer ?? "", {
        skip: infoboxToUse !== "dpr" || !feature?.sourceLayer,
    })

    const {
        data: locationHistory = null,
        isError: historyError,
        isLoading: historyLoading,
    } = useFetchLocationStatusHistoryQuery(
        {
            datasetId: feature?.sourceLayer ?? "",
            locationName: feature.properties?.[dprDatasetColumns?.locationColumn.name ?? ""] ?? "",
        },
        {
            skip:
                infoboxToUse !== "dpr" ||
                columnsLoading ||
                columnsError ||
                !dprDatasetColumns?.locationColumn ||
                !feature?.sourceLayer,
        },
    )

    return {
        locationHistory,
        isError: columnsError || historyError,
        isLoading: columnsLoading || historyLoading,
        shouldDisplayInfo: infoboxToUse === "dpr",
    }
}

export default useDprLocationHistory
