import { useState } from "react"
import InfoIcon from "@mui/icons-material/Info"
import { Divider, Grid, Typography } from "@mui/material"
import CardContent from "@mui/material/CardContent"
import { useSelector } from "react-redux"
import { AisSubscriptionCard } from "features/ais/components/AisSubscriptionCard"
import { boatTypes } from "features/ais/utils"
import { getAISSubscriptionInfo } from "selectors/aisSelectors"
import { useStyles } from "./styles"
import { AIS_TITLE } from "./utils"

const AISComponent = () => {
    const classes = useStyles()
    const [selectedArchiveSubscriptionId, setSelectedArchiveSubscriptionId] = useState<string | null>(null)
    const subscriptionInfo = useSelector(getAISSubscriptionInfo)

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <Divider />
            <Grid className={classes.title} container justifyContent="flex-start">
                <Typography variant="h6">{AIS_TITLE}</Typography>
            </Grid>
            <Divider />

            <div className={classes.legend}>
                <Typography gutterBottom variant="h6">
                    Legend
                </Typography>
                <div className="info-section__text">
                    {Object.entries(boatTypes).map(([typeName, icon], index) => {
                        return (
                            <div key={index} className={classes.legendItem}>
                                <div className={classes.legendText}>{typeName}</div>
                                <img alt={typeName} className={classes.legendIcon} src={`/icons/${icon}.svg`} />
                            </div>
                        )
                    })}
                </div>
            </div>
            {subscriptionInfo.map(s => (
                <AisSubscriptionCard
                    key={s.id}
                    subscription={s}
                    isArchivePlayerActive={s.id === selectedArchiveSubscriptionId}
                    onArchivePlayerCheck={isChecked => setSelectedArchiveSubscriptionId(isChecked ? s.id : null)}
                />
            ))}
            {subscriptionInfo.length === 0 && (
                <CardContent>
                    <div className="info-section">
                        <InfoIcon className="info-section__icon" />
                        <div className="info-section__text">
                            There is no active subscription to vessel position data at the moment. Please contact your
                            WindGIS administrator for further information.
                        </div>
                    </div>
                </CardContent>
            )}
        </Grid>
    )
}

export default AISComponent
