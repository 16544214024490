import * as React from "react";
const SvgCoralPinnacle = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "220.58, 144.35, 358.83997, 311.3", "background-size": "20px 30px", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400,351.22 400,351.22", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("path", { d: "M400 453.65 L400 146.35 L222.58 453.65 L400 453.65 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("path", { d: "M400 453.65 L577.42 453.65 L400 146.35 L400 453.65 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "222.58,453.65 400,146.35", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,146.35 577.42,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "577.42,453.65 222.58,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }));
export default SvgCoralPinnacle;
