import * as React from "react";
const SvgPlayground15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "playground-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1.15 C2.2091 0.3484 3.0284 -0.13190007 3.83 0.07709992 C4.6316 0.2860999 5.1119 1.1054999 4.9028997 1.9071 C4.7026 2.6752 3.9386 3.1542 3.16 3 C2.3474 2.839 1.8191 2.0498 1.98 1.2371 C1.9858 1.2079 1.9925 1.1788 2 1.15 z M13 12.32 C13.1032 12.862599 12.7469 13.386 12.2044 13.4892 C12.2029 13.4895 12.2015 13.4897995 12.2 13.49 C11.774799 13.576 11.3426 13.378099 11.13 13 L11.13 13 L9.45 9.63 L8 9.92 L7.78 10 L7.72 10 L7.72 12.15 L8.34 12 L8.4800005 12 C8.7671 12.0054 8.995501 12.2425 8.990101 12.5297 C8.9862 12.7361 8.8606 12.9207 8.67 13 L3.67 14 L3.67 14 C3.6137002 14.0095 3.5563002 14.0095 3.5 14 C3.2128 14 2.98 13.7672 2.98 13.48 C2.98 13.2701 3.1062 13.0807 3.3 13 L3.3 13 L7.45 12.17 L7.45 10 L4.2299995 10.58 L4.2299995 10.58 C4.0659995 10.6223 3.8939996 10.6223 3.7299995 10.58 C3.3905995 10.4932995 3.1211996 10.2354 3.0199995 9.9 L3 9.9 L2 5.83 L2 5.83 C1.9688 5.6884 1.9688 5.5416 2 5.4 C2.0936 5.013 2.4078 4.7185 2.8 4.65 L2.8 4.65 L7.5 4.13 L7.5 0 L7.72 0 L7.72 4.1 L7.7799997 4.1 L8 4.08 L8.4 4 L8.61 4 C8.877299 4.0643 9.0426 4.3323 8.98 4.6 C8.9361 4.835 8.729 5.0041 8.49 5 L8 5.08 L7.78 5.08 L7.72 5.08 L7.72 7.9399996 L7.7799997 7.9399996 L8 7.88 L9.809999 7.52 L9.809999 7.52 L9.809999 7.52 C10.237499 7.4848 10.639899 7.7262 10.809999 8.12 L10.809999 8.12 L12.809999 12.059999 L12.809999 12.059999 C12.887 12.1358 12.9511 12.2236 13 12.32 z M7.5 5.13 L5 5.4 L5.74 8.34 L7.5 8 L7.5 5.13 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1.15 C2.2091 0.3484 3.0284 -0.13190007 3.83 0.07709992 C4.6316 0.2860999 5.1119 1.1054999 4.9028997 1.9071 C4.7026 2.6752 3.9386 3.1542 3.16 3 C2.3474 2.839 1.8191 2.0498 1.98 1.2371 C1.9858 1.2079 1.9925 1.1788 2 1.15 z M13 12.32 C13.1032 12.862599 12.7469 13.386 12.2044 13.4892 C12.2029 13.4895 12.2015 13.4897995 12.2 13.49 C11.774799 13.576 11.3426 13.378099 11.13 13 L11.13 13 L9.45 9.63 L8 9.92 L7.78 10 L7.72 10 L7.72 12.15 L8.34 12 L8.4800005 12 C8.7671 12.0054 8.995501 12.2425 8.990101 12.5297 C8.9862 12.7361 8.8606 12.9207 8.67 13 L3.67 14 L3.67 14 C3.6137002 14.0095 3.5563002 14.0095 3.5 14 C3.2128 14 2.98 13.7672 2.98 13.48 C2.98 13.2701 3.1062 13.0807 3.3 13 L3.3 13 L7.45 12.17 L7.45 10 L4.2299995 10.58 L4.2299995 10.58 C4.0659995 10.6223 3.8939996 10.6223 3.7299995 10.58 C3.3905995 10.4932995 3.1211996 10.2354 3.0199995 9.9 L3 9.9 L2 5.83 L2 5.83 C1.9688 5.6884 1.9688 5.5416 2 5.4 C2.0936 5.013 2.4078 4.7185 2.8 4.65 L2.8 4.65 L7.5 4.13 L7.5 0 L7.72 0 L7.72 4.1 L7.7799997 4.1 L8 4.08 L8.4 4 L8.61 4 C8.877299 4.0643 9.0426 4.3323 8.98 4.6 C8.9361 4.835 8.729 5.0041 8.49 5 L8 5.08 L7.78 5.08 L7.72 5.08 L7.72 7.9399996 L7.7799997 7.9399996 L8 7.88 L9.809999 7.52 L9.809999 7.52 L9.809999 7.52 C10.237499 7.4848 10.639899 7.7262 10.809999 8.12 L10.809999 8.12 L12.809999 12.059999 L12.809999 12.059999 C12.887 12.1358 12.9511 12.2236 13 12.32 z M7.5 5.13 L5 5.4 L5.74 8.34 L7.5 8 L7.5 5.13 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPlayground15;
