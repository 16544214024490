import * as React from "react";
const SvgPost15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "post-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 6.5 L14 12 C14 12.5522995 13.5523 13 13 13 L2 13 C1.4477 13 1 12.5523 1 12 L1 6.5 C1 6.2239 1.2239 6 1.5 6 C1.5692 5.9848 1.6408 5.9848 1.71 6 L1.71 6 L7.5 10 L13.3 6 L13.3 6 C13.366 5.9862 13.434 5.9862 13.5 6 C13.7761 6 14 6.2239 14 6.5 z M1.25 3.92 L1.25 3.92 L1.33 4 L7.5 8 L13.690001 4 L13.690001 4 L13.750001 4 L13.750001 4 C13.929601 3.9019 14.029201 3.7024999 14.000001 3.5 C14 3.2239 13.7761 3 13.5 3 L1.5 3 C1.2239 3 1 3.2239 1 3.5 C1.0026 3.6745 1.0978 3.8345 1.25 3.92 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 6.5 L14 12 C14 12.5522995 13.5523 13 13 13 L2 13 C1.4477 13 1 12.5523 1 12 L1 6.5 C1 6.2239 1.2239 6 1.5 6 C1.5692 5.9848 1.6408 5.9848 1.71 6 L1.71 6 L7.5 10 L13.3 6 L13.3 6 C13.366 5.9862 13.434 5.9862 13.5 6 C13.7761 6 14 6.2239 14 6.5 z M1.25 3.92 L1.25 3.92 L1.33 4 L7.5 8 L13.690001 4 L13.690001 4 L13.750001 4 L13.750001 4 C13.929601 3.9019 14.029201 3.7024999 14.000001 3.5 C14 3.2239 13.7761 3 13.5 3 L1.5 3 C1.2239 3 1 3.2239 1 3.5 C1.0026 3.6745 1.0978 3.8345 1.25 3.92 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPost15;
