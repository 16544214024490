import * as React from "react";
const SvgPharmacy11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "pharmacy-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 4 L8 2 C7.9899 1.9304 7.9899 1.8596 8 1.79 C8 1.3757999 8.3358 1.04 8.75 1.04 C9.1642 1.04 9.5 1.3758 9.5 1.79 C9.5 2.2041998 9.1642 2.54 8.75 2.54 L8.64 2.54 L7.5 4 L6 4 z M9.48 5.83 L8.65 7.5 L9.48 9.17 L9.48 10 L1.4799995 10 L1.4799995 9.17 L2.32 7.5 L1.48 5.83 L1.48 5 L9.48 5 L9.48 5.83 z M7.5 7 L6 7 L6 5.5 L5 5.5 L5 7 L3.5 7 L3.5 8 L5 8 L5 9.5 L6 9.5 L6 8 L7.5 8 L7.5 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6 4 L8 2 C7.9899 1.9304 7.9899 1.8596 8 1.79 C8 1.3757999 8.3358 1.04 8.75 1.04 C9.1642 1.04 9.5 1.3758 9.5 1.79 C9.5 2.2041998 9.1642 2.54 8.75 2.54 L8.64 2.54 L7.5 4 L6 4 z M9.48 5.83 L8.65 7.5 L9.48 9.17 L9.48 10 L1.4799995 10 L1.4799995 9.17 L2.32 7.5 L1.48 5.83 L1.48 5 L9.48 5 L9.48 5.83 z M7.5 7 L6 7 L6 5.5 L5 5.5 L5 7 L3.5 7 L3.5 8 L5 8 L5 9.5 L6 9.5 L6 8 L7.5 8 L7.5 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPharmacy11;
