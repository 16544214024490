import { MapLayer } from "../../model/map/MapLayer"
import { StyleProperty } from "../../model/style/StyleProperty"

type MakeMapLayerParams = {
    layer: MapLayer
    layouts: any
    paints: any
    zoomRange: { maxZoom: number; minZoom: number }
}
// takes in a layer from the map, paints, layouts and zoomRange from the  reducer structure
export const makeMapLayer = ({ layer, layouts, paints, zoomRange }: MakeMapLayerParams) => {
    const mapLayer: Record<string, any> = {
        beforeId: layer.drawUnderId,
        id: layer.layerId,
        layout: propertiesToLayerPropertiesObj(layouts),
        maxzoom: zoomRange.maxZoom,
        metadata: { resourceId: layer.resourceId },
        minzoom: zoomRange.minZoom,
        paint: propertiesToLayerPropertiesObj(paints),
        source: layer.sourceId,
        type: layer.type,
    }

    if (layer.sourceName) mapLayer["source-layer"] = layer.sourceName

    return mapLayer
}

const propertiesToLayerPropertiesObj = (properties: StyleProperty[]) => {
    return properties.reduce((acc: Record<string, string>, prop) => {
        if (prop.title === "Legend") return acc
        acc[prop.name] = prop.value
        return acc
    }, {})
}
