import { DefaultTheme } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"

const desiredLegendSize = 20
const lineSize = 3

// Since the border width also contributes to the actual width we need to substract it
const polygonSize = desiredLegendSize

export const useStyles = makeStyles<DefaultTheme, { rasterColor?: string }>(() => ({
    legend: {
        height: polygonSize,
        position: "relative",
        width: polygonSize,
    },
    polygonBorder: props => ({
        borderColor: props.rasterColor,
        borderStyle: "solid",
        borderWidth: lineSize,
    }),
    raster: props => ({
        backgroundColor: props.rasterColor,
    }),
}))
