export const transformStringToFloatNumber = (value: string): number | string => {
    const floatNumberRegex = /^-?\d+(\.\d+)?$/
    const dotSymbol = "."
    const isNegativeSymbol = value.startsWith("-") && value.length === 1

    // If the value will be a negative number, make sure to return the negative symbol
    if (isNegativeSymbol) {
        return "-"
    }

    // If the value is a float number, parse it to a float number
    if (floatNumberRegex.test(value)) {
        return parseFloat(value)
    }

    // If the value is a float number with a dot symbol, return the value, in order to avoid the user to type more than one dot symbol
    if (value.includes(dotSymbol)) {
        return value
    }

    // If there is an unacceptable character, remove it
    return value.slice(0, -1)
}
