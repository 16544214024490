import { toastr } from "react-redux-toastr"

const options = { position: "bottom-right" }

const customToastr = {
    confirm: (text, opt) => toastr.confirm(text, opt),
    error: (text, opt = options) => toastr.error("", text, opt),
    info: (text, opt = options) => toastr.info("", text, opt),
    success: (text, opt = options) => toastr.success("", text, opt),
    warning: (text, opt = options) => toastr.warning("", text, opt),
}

export default customToastr
