import React from "react"
import { Typography, TextField } from "@mui/material"
import ColorPopover from "../colorPopover"

function ColorProperty(props) {
    return (
        <div className="property" onClick={props.onClick}>
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" value={props.title} onChange={props.onTitleChanged} />
                )}
                {!props.editable && (
                    <Typography className="name" variant="body1">
                        {props.title}
                    </Typography>
                )}
                <ColorPopover
                    color={props.value}
                    onColorChanged={color =>
                        props.onPropertyChanged(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                    }
                />
            </div>
        </div>
    )
}

export default ColorProperty
