import React from "react"
import { Grid, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#024F79",
        boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
        color: "#f5f5f5",
        fontWeight: "bold",
        height: 25,
        position: "absolute",
        zIndex: 5,
    },
    whiteText: {
        color: "#fff",
    },
}))

const MapEditing = () => {
    const classes = useStyles()
    return (
        <Grid className={classes.root} container>
            <Grid item xs={12}>
                <Grid alignItems="center" container justifyContent="center">
                    <Typography className={classes.whiteText} variant="subtitle2">
                        Editing map features
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MapEditing
