import React, { useEffect } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import FilledInput from "@mui/material/FilledInput"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from "@mui/material/Typography"
import TitleTextField from "../common/TitleTextfield/TitleTextField"

const ColumnProperty = ({
    columns,
    editable,
    options,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) => {
    const handleChange = e => {
        onPropertyChanged(["get", e.target.value])
    }
    const removable = onRemove && !isDefaultValue && !isLastRemainingCase
    const selectedValue = value[1]

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {!editable && (
                    <Typography className="name" variant="body2">
                        {title}
                    </Typography>
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <div className="text-container">
                    <FormControl className="property-select" fullWidth variant="filled">
                        <InputLabel htmlFor="filled-age-simple">Dataset Column</InputLabel>
                        <Select
                            input={<FilledInput id="filled-age-simple" name="age" />}
                            value={selectedValue}
                            onChange={handleChange}
                        >
                            {columns
                                .filter(x => x.type === "double precision")
                                .map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>
                                            {item.prettyName}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default ColumnProperty
