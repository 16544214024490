import * as React from "react";
const SvgHarbor11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "harbor-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C4.1193 0 3 1.1193 3 2.5 C3.0018 3.5981 3.7217 4.5466003 4.75 4.8711 L4.75 9.416 C4.091 9.301001 3.4119 9.0407 2.8574 8.5586 C2.0886 7.8901 1.5 6.8297 1.5 5 C1.5055 4.5774 1.1609 4.2336 0.7383 4.2402 C0.3248 4.2467 -0.0054 4.5865 0 5 C0 7.1703 0.7731 8.7348995 1.873 9.691401 C2.9729 10.647902 4.3333 11 5.5 11 C6.6667 11 8.027 10.6479 9.127 9.691401 C10.226999 8.734901 11 7.1703 11 5 C11.0143 3.9858 9.4857 3.9858 9.5 5 C9.5 6.8297 8.9114 7.8901 8.1426 8.558599 C7.5881 9.0407 6.909 9.3011 6.25 9.416 L6.25 4.875 C7.2795 4.55 7.9999 3.5995 8 2.5 C8 1.1193 6.8807 0 5.5 0 z M5.5 1.5 C6.0523 1.5 6.5 1.9477 6.5 2.5 C6.5 3.0523 6.0523 3.5 5.5 3.5 C4.9477 3.5 4.5 3.0523 4.5 2.5 C4.5 1.9477 4.9477 1.5 5.5 1.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C4.1193 0 3 1.1193 3 2.5 C3.0018 3.5981 3.7217 4.5466003 4.75 4.8711 L4.75 9.416 C4.091 9.301001 3.4119 9.0407 2.8574 8.5586 C2.0886 7.8901 1.5 6.8297 1.5 5 C1.5055 4.5774 1.1609 4.2336 0.7383 4.2402 C0.3248 4.2467 -0.0054 4.5865 0 5 C0 7.1703 0.7731 8.7348995 1.873 9.691401 C2.9729 10.647902 4.3333 11 5.5 11 C6.6667 11 8.027 10.6479 9.127 9.691401 C10.226999 8.734901 11 7.1703 11 5 C11.0143 3.9858 9.4857 3.9858 9.5 5 C9.5 6.8297 8.9114 7.8901 8.1426 8.558599 C7.5881 9.0407 6.909 9.3011 6.25 9.416 L6.25 4.875 C7.2795 4.55 7.9999 3.5995 8 2.5 C8 1.1193 6.8807 0 5.5 0 z M5.5 1.5 C6.0523 1.5 6.5 1.9477 6.5 2.5 C6.5 3.0523 6.0523 3.5 5.5 3.5 C4.9477 3.5 4.5 3.0523 4.5 2.5 C4.5 1.9477 4.9477 1.5 5.5 1.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHarbor11;
