import { createSlice } from "@reduxjs/toolkit"
import { AppLayer } from "model/app/AppLayer"
import { Group } from "model/app/Group"
import { LayerDataProperties } from "model/app/LayerDataProperties"
import { MetadataPropertyValue } from "model/metadata/MetadataPropertyValue"
import { Style } from "model/style/Style"
import { getApp } from "actions/apps"
import { mapZoomEnd, resetProjectData, toggleAppLayer, toggleGroupLayers } from "actions/globalActions"
import { downloadDataset, downloadGeotiff, getLayerMetadata } from "actions/layerSelector"
import { GuidMap } from "common/types/GuidMap"
import { defaultBufferOpacity } from "features/buffer/components/utils"

type LayerDataInfo = {
    count: number
    data: LayerDataProperties[]
}

type SliceState = {
    error: string | null
    fetchingAttributes: boolean
    fetchingGeotiff: boolean
    fetchingMetadata: boolean
    initialLayerGroups: Group[]
    layerDataInfo: LayerDataInfo
    layerGroups: Group[]
    layerMetadata: MetadataPropertyValue[]
    layerStylesMap: GuidMap<Style[]> //{layerId: [styleOne, ...]}
    layerVisibilityMap: GuidMap<boolean> //{layerId: false/true}
    loading: boolean
    mapZoom: number
    stylerLayerId: string | null
    shouldFilter: boolean
}

const initialState: SliceState = {
    error: null,
    fetchingAttributes: false,
    fetchingGeotiff: false,
    fetchingMetadata: false,
    initialLayerGroups: [],
    layerDataInfo: {
        count: 0,
        data: [],
    },
    layerGroups: [],
    layerMetadata: [],
    layerStylesMap: {}, //{layerId: [styleOne, ...]}
    layerVisibilityMap: {}, //{layerId: false/true}
    loading: false,
    mapZoom: 0,
    stylerLayerId: null,
    shouldFilter: false,
}

export const layerSelectorSlice = createSlice({
    extraReducers: builder =>
        builder
            .addCase(
                toggleGroupLayers,
                ({ layerGroups, layerVisibilityMap }, { payload: { groupId, newVisibility } }) => {
                    //Left it like this for when we want to implement the
                    //system with totalLayerCounts and visibleLayerCounts on groups
                    const group = layerGroups.getRecursive(groupId)
                    group.layers.forLayersRecursive((layer: AppLayer) => {
                        layerVisibilityMap[layer.resourceId] = newVisibility
                    })
                },
            )
            .addCase(toggleAppLayer, ({ layerVisibilityMap }, { payload: { resourceId, visible } }) => {
                layerVisibilityMap[resourceId] = visible
            })
            .addCase(mapZoomEnd, (state, { payload: newZoom }) => {
                state.mapZoom = newZoom
                state.layerGroups.forLayersRecursive(layer => {
                    layer.isShown =
                        Math.floor(layer.minZoom) <= Math.floor(newZoom) &&
                        Math.floor(newZoom) <= Math.floor(layer.maxZoom)
                })
            })
            .addCase(downloadDataset.pending, state => {
                state.loading = true
                state.error = null
            })
            .addCase(downloadDataset.fulfilled, state => {
                state.loading = false
            })
            .addCase(downloadDataset.rejected, (state, { payload: error }) => {
                state.loading = false
                state.error = error
            })
            .addCase(getApp.pending, state => {
                state.loading = true
            })
            .addCase(getApp.rejected, state => {
                state.loading = false
            })
            .addCase(downloadGeotiff.pending, state => {
                state.fetchingGeotiff = true
                state.error = null
            })
            .addCase(downloadGeotiff.fulfilled, state => {
                state.fetchingGeotiff = false
            })
            .addCase(downloadGeotiff.rejected, (state, { payload: error }) => {
                state.fetchingGeotiff = false
                state.error = error
            })
            .addCase(getLayerMetadata.pending, state => {
                state.fetchingMetadata = true
            })
            .addCase(getLayerMetadata.fulfilled, (state, { payload }) => {
                state.fetchingMetadata = false
                state.layerMetadata = payload
            })
            .addCase(getLayerMetadata.rejected, state => {
                state.fetchingMetadata = false
            })
            .addCase(resetProjectData, () => initialState),
    initialState,
    name: "layerSelector",
    reducers: {
        addBufferLayer: (state, { payload: { layer, targetLayerResourceId } }) => {
            state.layerGroups.addElementRecursive(targetLayerResourceId, layer, false)
            state.initialLayerGroups.addElementRecursive(targetLayerResourceId, layer, false)
            state.layerStylesMap[layer.resourceId] = []
            state.layerVisibilityMap[layer.resourceId] = true
        },
        addStyleToLayer: ({ layerGroups, layerStylesMap, mapZoom }, { payload: { layerId, style } }) => {
            layerStylesMap[layerId].splice(0, 0, style)
            const layer = layerGroups.getRecursive<AppLayer>(layerId)
            const newMinZoom = Math.min(layer.minZoom, style.minZoom)
            const newMaxZoom = Math.max(layer.maxZoom, style.maxZoom)
            layer.minZoom = newMinZoom
            layer.maxZoom = newMaxZoom
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom)
        },
        changePropertiesOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, properties, styleId } }) => {
            layerStylesMap[layerId].forEach(style => {
                if (style.styleId === styleId) {
                    style.properties = properties
                }
            })
        },
        changePropertyOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, property, styleId } }) => {
            layerStylesMap[layerId].forEach(style => {
                if (style.styleId === styleId) {
                    const propIndex = style.properties.findIndex(p => p.name === property.name)
                    if (propIndex !== -1) {
                        style.properties[propIndex] = property
                    } else {
                        console.error("Property index could not be found")
                    }
                }
            })
        },
        changeStyleOrder: ({ layerStylesMap }, { payload: { beforeStyleId, layerId, styleId } }) => {
            const styles = layerStylesMap[layerId]

            const movedStyleIndex = styles.findIndex(s => s.styleId === styleId)
            let destinationIndex = styles.findIndex(s => s.styleId === beforeStyleId)

            const style = styles.splice(movedStyleIndex, 1)[0]

            styles.splice(destinationIndex, 0, style)
        },
        changeTypeOfLayerStyle: ({ layerStylesMap }, { payload: { layerId, properties, styleId, type } }) => {
            layerStylesMap[layerId].forEach(style => {
                if (style.styleId === styleId) {
                    style.properties = properties
                    style.type = type
                }
            })
        },
        hideAllLayers: ({ layerVisibilityMap }) => {
            Object.keys(layerVisibilityMap).forEach(layerId => {
                layerVisibilityMap[layerId] = false
            })
        },
        removeBufferLayer: (state, { payload: layerResourceId }) => {
            state.layerGroups.removeOneRecursive(layerResourceId)
        },
        removeStyleFromLayer: ({ layerGroups, layerStylesMap, mapZoom }, { payload: { layerId, styleId } }) => {
            layerStylesMap[layerId] = layerStylesMap[layerId].filter(s => s.styleId !== styleId)

            let minZoom = 24
            let maxZoom = 0
            layerStylesMap[layerId].forEach(style => {
                if (style.minZoom < minZoom) minZoom = style.minZoom
                if (style.maxZoom > maxZoom) maxZoom = style.maxZoom
            })

            const layer = layerGroups.getRecursive<AppLayer>(layerId)
            layer.minZoom = minZoom
            layer.maxZoom = maxZoom
            layer.isShown = Math.floor(minZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(maxZoom)
        },
        resetLayerData: state => {
            state.layerDataInfo.data = []
            state.layerDataInfo.count = 0
        },
        resetLayerGroupsToInitialState: state => {
            state.layerGroups = state.initialLayerGroups
        },
        toggleGroupCollapse: ({ layerGroups }, { payload: { groupId, newCollapseValue } }) => {
            const group = layerGroups.getRecursive(groupId)
            group.options.collapsed = newCollapseValue
        },
        updateBufferLayer: (state, { payload: { bounds, geometryType, layerResourceId, layerStyle, sourceId } }) => {
            const updateLayerFn = (layer: AppLayer) => {
                if (layer.resourceId === layerResourceId) {
                    layer.bounds = bounds
                    layer.sourceId = sourceId
                    layer.geometryType = geometryType
                    layer.options.loading = false
                    layer.opacity = defaultBufferOpacity * 100
                }
            }

            state.layerGroups.forLayersRecursive(updateLayerFn)
            state.initialLayerGroups.forLayersRecursive(updateLayerFn)

            state.layerStylesMap[layerResourceId] = [layerStyle]
        },
        updateStyleZoomRange: (
            { layerGroups, layerStylesMap, mapZoom },
            { payload: { layerId, maxZoom, minZoom, styleId } },
        ) => {
            layerStylesMap[layerId].forEach(style => {
                if (style.styleId === styleId) {
                    style.minZoom = minZoom
                    style.maxZoom = maxZoom
                }
            })

            let newMinZoom = 24
            let newMaxZoom = 0
            layerStylesMap[layerId].forEach(style => {
                if (style.minZoom < newMinZoom) newMinZoom = style.minZoom
                if (style.maxZoom > newMaxZoom) newMaxZoom = style.maxZoom
            })

            const layer = layerGroups.getRecursive<AppLayer>(layerId)
            layer.minZoom = newMinZoom
            layer.maxZoom = newMaxZoom
            layer.isShown =
                Math.floor(newMinZoom) <= Math.floor(mapZoom) && Math.floor(mapZoom) <= Math.floor(newMaxZoom)
        },
        setLayerData: (state, { payload: { layerGroups, layerStylesMap, layerVisibilityMap } }) => {
            const formattedLayerGroups = layerGroups.mapLayersRecursive((layer: AppLayer) => {
                return { ...layer, opacity: 100 }
            })

            state.layerStylesMap = layerStylesMap
            state.layerVisibilityMap = layerVisibilityMap
            state.layerGroups = formattedLayerGroups
            state.initialLayerGroups = formattedLayerGroups
            state.loading = false
        },
        setLayerIsFiltered: (state, { payload: { layerResourceId, value } }) => {
            const layer = state.layerGroups.getRecursive<AppLayer>(layerResourceId)
            layer.isFiltered = value
        },
        setLayerOpacity: (state, { payload: { layerResourceId, opacity } }) => {
            const layer = state.layerGroups.getRecursive<AppLayer>(layerResourceId)
            const initialLayer = state.initialLayerGroups.getRecursive<AppLayer>(layerResourceId)
            layer.opacity = opacity
            initialLayer.opacity = opacity
        },
        setLoading: (state, { payload: loading }) => {
            state.loading = loading
        },
        setMapZoom: (state, { payload: newMapZoom }) => {
            state.mapZoom = newMapZoom
        },
        setShouldFilter: (state, { payload: shouldFilter }) => {
            state.shouldFilter = shouldFilter
        },
        setStylerLayerId: (state, { payload: newStylerLayerId }) => {
            state.stylerLayerId = newStylerLayerId
        },
    },
})

export const {
    addBufferLayer,
    addStyleToLayer,
    changePropertiesOfLayerStyle,
    changePropertyOfLayerStyle,
    changeStyleOrder,
    changeTypeOfLayerStyle,
    hideAllLayers,
    removeBufferLayer,
    removeStyleFromLayer,
    resetLayerData,
    resetLayerGroupsToInitialState,
    toggleGroupCollapse,
    updateBufferLayer,
    updateStyleZoomRange,
    setLayerData,
    setLayerIsFiltered,
    setLayerOpacity,
    setLoading,
    setMapZoom,
    setShouldFilter,
    setStylerLayerId,
} = layerSelectorSlice.actions

export default layerSelectorSlice.reducer
