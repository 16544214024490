import axiosClient from "actions/apiClient"
import { NotificationOperation } from "./models/NotificationOperation"

export const getApplicationEmailNotifications = (appId: string) => {
    return axiosClient.get(`application/${appId}/emailNotifications`).then(res => res.data)
}

type OptInOutRequest = {
    appId: string
    operation: NotificationOperation
}

export const applicationEmailNotificationOptIn = (request: OptInOutRequest) => {
    return axiosClient.post(`application/${request.appId}/emailNotifications/${request.operation}`)
}

export const applicationEmailNotificationOptOut = (request: OptInOutRequest) => {
    return axiosClient.delete(`application/${request.appId}/emailNotifications/${request.operation}`)
}
