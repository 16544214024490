import React from "react"
import Popover from "@mui/material/Popover"
import { ChromePicker } from "react-color"

function SimplePopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [color, setColor] = React.useState(props.color)

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : null

    return (
        <div>
            <div className="color-container" style={{ backgroundColor: props.color }} onClick={handleClick}></div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "center",
                }}
                id={id}
                open={open}
                PaperProps={{
                    style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" },
                }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "center",
                }}
                onClose={handleClose}
            >
                <ChromePicker
                    color={color}
                    onChange={color => setColor(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)}
                    onChangeComplete={props.onColorChanged}
                />
            </Popover>
        </div>
    )
}

export default SimplePopover
