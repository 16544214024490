import { createSelector } from "reselect"
import { RootState } from "store/models/RootState"

export const getBasemaps = (state: RootState) => state.mapTools.basemaps
export const getLanguages = (state: RootState) => state.mapTools.languages
export const getProjections = (state: RootState) => state.mapTools.projections
export const getSelectedProjection = (state: RootState) => state.mapTools.selectedProjection
export const getTools = (state: RootState) => state.mapTools.tools
export const getWidgets = (state: RootState) => state.mapTools.widgets
export const getToggledWidgets = (state: RootState) => state.mapTools.toggledWidgets

export const getToggledWidgetsSet = createSelector(
    (state: RootState) => state.mapTools.toggledWidgets,
    widgets => new Set(Object.keys(widgets)),
)
