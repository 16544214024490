export const LAYER_TYPES = {
    background: "background",
    boxChart: "box-chart",
    circle: "circle",
    fill: "fill",
    fillExtrusion: "fill-extrusion",
    heatmap: "heatmap",
    hillShade: "hillshade",
    line: "line",
    pieChart: "pie-chart",
    raster: "raster",
    sky: "sky",
    symbol: "symbol",
}
