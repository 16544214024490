import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(() => ({
    syncIcon: {
        color: "inherit",
        fontSize: 15,
    },
    title: {
        color: "inherit",
        fontSize: "12px",
        fontWeight: "bold",
        textTransform: "capitalize",
    },
    titleContainer: {
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
    },
}))
