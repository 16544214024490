import { useRef } from "react"
import { Paper, Typography } from "@mui/material"
import useVerticalResizableMouseEvent from "app/hooks/useVerticalResizableMouseEvent"
import ErrorPlaceholder from "components/common/ErrorPlaceholder/ErrorPlaceholder"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import AttributeTable from "../AttributeTable/AttributeTable"
import AttributeTableHeader from "../AttributeTableHeader/AttributeTableHeader"
import AttributeTablePagination from "../AttributeTablePagination/AttributeTablePagination"
import useAttributeTableData from "../hooks/useAttributeTableData"
import useOnFlyToFeature from "../hooks/useOnFlyToFeature"
import useSelectedFeaturesManager from "../hooks/useSelectedFeaturesManager"
import SearchField from "../SearchField/SearchField"
import useAttributeTableContainerStyles from "./styles"

type Props = {
    datasetId: string
    layerName: string
    isDownloadable: boolean
    onClose: () => void
    onDownloadClick: (ids: number[]) => void
}

const MIN_ATTRIBUTE_TABLE_HEIGHT = 53

const AttributeTableContainer = ({ datasetId, layerName, isDownloadable, onClose, onDownloadClick }: Props) => {
    const classes = useAttributeTableContainerStyles()
    const resizableRef = useRef<HTMLDivElement>(null)
    const onMouseDown = useVerticalResizableMouseEvent({ minHeight: MIN_ATTRIBUTE_TABLE_HEIGHT, ref: resizableRef })
    const onFlyToFeature = useOnFlyToFeature()

    const {
        data,
        error,
        orderBy,
        orderByDesc,
        refetch,
        rowsPerPage,
        searchText,
        tablePage,
        totalRowCount,
        isError,
        isFetching,
        setOrderBy,
        setRowsPerPage,
        setSearchText,
        setTablePage,
    } = useAttributeTableData(datasetId)

    const {
        areAllVisibleFeaturesSelected,
        numberOfSelectedFeatures,
        selectedFeatures,
        isAtLeastOneVisibleFeatureSelected,
        isFeatureSelected,
        toggleAllVisibleFeatures,
        toggleFeature,
    } = useSelectedFeaturesManager(data?.properties)

    return (
        <Paper className={classes.paperContainer} ref={resizableRef}>
            <div id="map-table-dragbar" onMouseDown={onMouseDown} />
            <div className={classes.container}>
                <AttributeTableHeader
                    layerName={layerName}
                    numberOfSelectedFeatures={numberOfSelectedFeatures}
                    isDownloadable={isDownloadable}
                    onClose={onClose}
                    onDownloadClick={() => onDownloadClick(selectedFeatures)}
                />
                <SearchField searchText={searchText} startSearch={setSearchText} />
                <div className={classes.root}>
                    <div className={classes.tableContainer}>
                        <ErrorPlaceholder error={isError} message={error?.message} onTryAgain={() => refetch()}>
                            <LoadingPlaceholder loading={isFetching}>
                                {data?.properties && data.properties.length > 0 ? (
                                    <AttributeTable
                                        areAllFeaturesSelected={areAllVisibleFeaturesSelected}
                                        features={data.properties}
                                        orderBy={orderBy}
                                        orderDesc={orderByDesc}
                                        isAtLeastOneFeatureSelected={isAtLeastOneVisibleFeatureSelected}
                                        isDownloadable={isDownloadable}
                                        isFeatureSelected={isFeatureSelected}
                                        onFlyToFeature={onFlyToFeature}
                                        onSortBy={setOrderBy}
                                        onToggleFeature={toggleFeature}
                                        onToggleSelectAllFeatures={toggleAllVisibleFeatures}
                                    />
                                ) : (
                                    <div className={classes.noData}>
                                        <Typography variant="body2">No data</Typography>
                                    </div>
                                )}
                            </LoadingPlaceholder>
                        </ErrorPlaceholder>
                    </div>
                    <AttributeTablePagination
                        count={totalRowCount}
                        page={tablePage}
                        rowsPerPage={rowsPerPage}
                        onPageChange={setTablePage}
                        onRowsPerPageChange={setRowsPerPage}
                    />
                </div>
            </div>
        </Paper>
    )
}

export default AttributeTableContainer
