import { ReactNode } from "react"
import { Grid, Divider, Box } from "@mui/material"
import CustomTypography from "../../common/CustomTypography/CustomTypography"

type Props = {
    classes: {
        dividerClass: any
        entryContainerClass: any
        iconContainerClass: any
        typographyClass: any
    }
    divider: boolean
    icon: ReactNode
    title: string
    typographyProps: any
    xsSpacing: number
}

const LegendEntry = ({ classes, divider, icon, title, typographyProps, xsSpacing }: Props) => {
    return (
        <Grid item xs={xsSpacing}>
            <Box className={classes.entryContainerClass}>
                <Box alignItems="center" className={classes.iconContainerClass} display="flex" pr={1}>
                    {icon}
                </Box>
                <CustomTypography
                    className={classes.typographyClass}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    textWeight="semibold"
                    variant="subtitle1"
                    {...typographyProps}
                >
                    {title}
                </CustomTypography>
            </Box>

            {divider && <Divider className={classes.dividerClass} />}
        </Grid>
    )
}

export default LegendEntry
