import * as React from "react";
const SvgTriangleStroked11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "triangle-stroked-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5174 1.2315 C5.3163 1.2253 5.1276 1.328 5.024 1.5 L1.0240002 8.1598 C0.8013 8.5293 1.0679 8.9999 1.5 9 L9.5 9 C9.9321 8.9999 10.1987 8.5293 9.976 8.1598 L5.976 1.4999995 C5.8787 1.3386 5.706 1.2375 5.5174 1.2315 z M5.5 2.8627 L8.5191 7.8913 L2.4809 7.8913 L5.5 2.8627 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5174 1.2315 C5.3163 1.2253 5.1276 1.328 5.024 1.5 L1.0240002 8.1598 C0.8013 8.5293 1.0679 8.9999 1.5 9 L9.5 9 C9.9321 8.9999 10.1987 8.5293 9.976 8.1598 L5.976 1.4999995 C5.8787 1.3386 5.706 1.2375 5.5174 1.2315 z M5.5 2.8627 L8.5191 7.8913 L2.4809 7.8913 L5.5 2.8627 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTriangleStroked11;
