import { useState } from "react"
import { Box } from "@mui/material"
import { Subscription } from "model/ais/Subscription"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useFetchArchiveMetadataQuery } from "../../api"
import useLeftDistance from "../../hooks/useLeftDistance"
import { AisArchivePlayer } from "../AisArchivePlayer"
import AisSubscriptionCard from "./AisSubscriptionCard"

type Props = {
    subscription: Subscription
    isArchivePlayerActive: boolean
    onArchivePlayerCheck: (isChecked: boolean) => void
}

const PLAYER_WIDTH = 560

const AisSubscriptionCardContainer = ({ subscription, isArchivePlayerActive, onArchivePlayerCheck }: Props) => {
    const [isToggled, setIsToggled] = useState(true)
    const leftDistance = useLeftDistance(PLAYER_WIDTH)
    const featureFlags = useAppSelector(getFeatureFlags)

    const { data: archiveMetadata, isFetching: isArchiveMetadataFetching } = useFetchArchiveMetadataQuery(
        subscription.id,
        { skip: !isArchivePlayerActive },
    )

    const shouldShowArchivePlayer = !isArchiveMetadataFetching && archiveMetadata && isArchivePlayerActive

    return (
        <>
            <AisSubscriptionCard
                subscription={subscription}
                isArchiveFeatureEnabled={featureFlags.AIS_ARCHIVE}
                isArchiveMetadataLoading={isArchiveMetadataFetching}
                isArchivePlayerActive={isArchivePlayerActive}
                isToggled={isToggled}
                onArchivePlayerCheck={onArchivePlayerCheck}
                onToggleClick={() => setIsToggled(!isToggled)}
            />
            {shouldShowArchivePlayer && (
                <Box bottom={60} left={`calc(50% + ${leftDistance}px)`} position="fixed" zIndex={2}>
                    <AisArchivePlayer
                        archiveMetadata={archiveMetadata}
                        subscription={subscription}
                        onClose={() => onArchivePlayerCheck(false)}
                    />
                </Box>
            )}
        </>
    )
}

export default AisSubscriptionCardContainer
