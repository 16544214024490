import React, { Component } from "react"
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined"
import PaletteOutlinedIcon from "@mui/icons-material/PaletteOutlined"
import { Tabs, Tab, Select, IconButton, Tooltip, Menu } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Button from "@mui/material/Button"
import Fade from "@mui/material/Fade"
import MenuItem from "@mui/material/MenuItem"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import * as atlasActions from "actions/atlasClient"
import * as ColorUtils from "utils/colorUtils"

const StyledTabs = withStyles({
    indicator: {
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
    },
    root: {
        height: 32,
        minHeight: 32,
    },
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
    root: {
        "&:focus": {
            opacity: 1,
        },
        "&:hover": {
            filter: "brightness(75%)",
        },
        height: 32,
        minHeight: 32,

        padding: 0,
        width: "50%",
    },
    selected: {
        "&:hover": {
            backgroundColor: "#f1f0f0",
            filter: "none",
        },
        backgroundColor: theme.palette.background,
    },
}))(props => <Tab {...props} />)

let gradients = [
    {
        end: "#013220",
        name: "Greens",
        start: "#FFFFFF",
    },
    {
        end: "#00008b",
        name: "Blues",
        start: "#FFFFFF",
    },
    {
        end: "#8b0000",
        name: "Reds",
        start: "#FFFFFF",
    },
    {
        end: "#000000",
        name: "Grays",
        start: "#FFFFFF",
    },
    {
        end: "#8b0000",
        name: "Blue Red",
        start: "#00008b",
    },
]

class GraduateModal extends Component {
    state = {
        colorRamp: {
            end: "#013220",
            start: "#FFFFFF",
        },
        maxValue: 0.0,
        minValue: 0.0,
        page: "column",
        selectedColumn: "",
        steps: 5,
        stepValues: [],
    }

    changePage(page) {
        this.setState({
            page: page,
        })
    }

    onFinished = e => {
        let interpolateArray = ["interpolate", ["linear"], ["get", this.state.selectedColumn]]

        for (let i = 0; i < this.state.stepValues.length; i++) {
            let step = this.state.stepValues[i]
            interpolateArray.push(parseFloat(step.value), step.color)
        }

        this.props.onClose()
        this.props.onFinish(interpolateArray)
    }

    onColumnChange = e => {
        this.setState({
            selectedColumn: e.target.value,
        })
    }

    onColumnNextClick = () => {
        this.props.getMinMaxColumnValues(this.props.datasetId, this.state.selectedColumn).then(res => {
            let values = this.calculateSteps(this.state.steps, res.result.minValue, res.result.maxValue)

            this.setState({
                maxValue: res.result.maxValue,
                minValue: res.result.minValue,
                page: "graduate",
                stepValues: values,
            })
            // for (let i = 0; i < res.result.length; i++) {
            //     let uniqueColumnValue = res.result[i];
            //     matchArray.push(uniqueColumnValue, colors[i]);
            // }
            // matchArray.push("#FFFFFF");
            // this.props.onPropertyExpressionTypeChanged("match", matchArray);
            // this.setState({
            //     categoriseDialogOpen: false
            // });
        })
    }

    calculateSteps(steps, minValue, maxValue) {
        let singleStepValue = Math.abs(minValue - maxValue) / (steps - 1)

        let colors = ColorUtils.interpolateColors(this.state.colorRamp.start, this.state.colorRamp.end, steps - 2)

        let stepValues = []

        //Add min value
        stepValues.push({
            color: colors[0],
            index: 0,
            value: minValue.toFixed(2),
        })

        for (let i = 1; i < steps - 1; i++) {
            stepValues.push({
                color: colors[i],
                index: i,
                value: (singleStepValue * i + minValue).toFixed(2),
            })
        }

        //Add max value
        stepValues.push({
            color: colors[steps - 1],
            index: steps,
            value: maxValue.toFixed(2),
        })

        return stepValues
    }

    onStepsChange = e => {
        let newSteps = parseInt(e.target.value)

        if (newSteps < 2) {
            return
        }

        let values = this.calculateSteps(newSteps, this.state.minValue, this.state.maxValue)

        this.setState({
            steps: newSteps,
            stepValues: values,
        })

        return false
    }

    validColumnType = columnType => {
        switch (columnType.toLowerCase()) {
            case "smallint":
            case "integer":
            case "bigint":
            case "decimal":
            case "numeric":
            case "real":
            case "double precision":
            case "serial":
            case "bigserial":
                return true
            default:
                return false
        }
    }

    onOpenColorRampMenu = e => {
        this.setState({
            colorRampMenuAnchor: e.target,
        })
    }

    onCloseColorRampMenu = e => {
        this.setState({
            colorRampMenuAnchor: null,
        })
    }

    onInvert = () => {
        this.setState(
            {
                colorRamp: {
                    end: this.state.colorRamp.start,
                    start: this.state.colorRamp.end,
                },
            },
            () => {
                let values = this.calculateSteps(this.state.steps, this.state.minValue, this.state.maxValue)

                this.setState({
                    stepValues: values,
                })
            },
        )
    }

    onColorRampSelected = colorRamp => {
        this.setState(
            {
                colorRamp: {
                    end: colorRamp.end,
                    start: colorRamp.start,
                },
            },
            () => {
                let values = this.calculateSteps(this.state.steps, this.state.minValue, this.state.maxValue)

                this.setState({
                    stepValues: values,
                })
            },
        )
        this.onCloseColorRampMenu()
    }

    render() {
        let { props } = this

        let columns = props.columns
            .filter(x => this.validColumnType(x.type))
            .map((item, index) => {
                return (
                    <MenuItem key={index} className="menu-item-flex" value={item.name}>
                        <div className="name">{item.name}</div>
                        <span className="type">{item.type}</span>
                    </MenuItem>
                )
            })

        let values = this.state.stepValues.map(step => {
            return (
                <div className="step-container">
                    {/* <div className="step-index">{step.index}</div> */}
                    <div className="step-value">{step.value}</div>
                    <div className="step-color" style={{ backgroundColor: step.color }}></div>
                </div>
            )
        })

        let colorRamps = gradients.map((gradient, index) => {
            return (
                <MenuItem key={index} onClick={() => this.onColorRampSelected(gradient)}>
                    <Typography className="color-ramp-name" variant="body2">
                        {gradient.name}
                    </Typography>
                    <div
                        className="color-ramp-gradient"
                        style={{ background: `linear-gradient(to right,${gradient.start},${gradient.end})` }}
                    ></div>
                </MenuItem>
            )
        })

        return (
            <Modal
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                className="modal graduate-style-modal"
                closeAfterTransition
                open={props.open}
                onClose={props.onClose}
            >
                <Fade in={props.open}>
                    <div className="modal-container">
                        <Typography className="header" id="transition-modal-title" variant="h6">
                            Graduate Test
                        </Typography>
                        {/* {this.state.uploading && <LinearProgress className="no-margin-progress" />} */}
                        <StyledTabs TabIndicatorProps={<div />} value={this.state.page}>
                            <StyledTab label="column" value="column" onClick={() => this.changePage("column")} />
                            <StyledTab label="graduate" value="graduate" onClick={() => this.changePage("graduate")} />
                        </StyledTabs>
                        {this.state.page === "column" && (
                            <div className="page">
                                <Typography variant="body1">Column</Typography>

                                <Select fullWidth value={this.state.selectedColumn} onChange={this.onColumnChange}>
                                    {columns}
                                </Select>
                                <Button
                                    className="next-button"
                                    color="primary"
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => this.onColumnNextClick()}
                                >
                                    Next
                                </Button>
                            </div>
                        )}

                        {this.state.page === "graduate" && (
                            <div className="page">
                                <div className="steps-container">
                                    <Typography variant="body1">Steps</Typography>
                                    <TextField
                                        fullWidth
                                        id="full-width"
                                        inputProps={{ autoComplete: "no" }}
                                        margin="none"
                                        placeholder="Number of steps in graduation"
                                        type="number"
                                        value={this.state.steps}
                                        onChange={this.onStepsChange}
                                    />
                                </div>
                                <div className="actions">
                                    <Tooltip title="invert">
                                        <IconButton size="large" onClick={this.onInvert}>
                                            <CachedOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Color Ramps">
                                        <IconButton size="large" onClick={this.onOpenColorRampMenu}>
                                            <PaletteOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={this.state.colorRampMenuAnchor}
                                        id="simple-menu"
                                        keepMounted
                                        open={Boolean(this.state.colorRampMenuAnchor)}
                                        onClose={this.onCloseColorRampMenu}
                                    >
                                        {colorRamps}
                                    </Menu>
                                </div>
                                <div className="scroll-container">{values}</div>

                                <Button
                                    className="next-button"
                                    color="primary"
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => this.onFinished()}
                                >
                                    Finish
                                </Button>
                            </div>
                        )}
                    </div>
                </Fade>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        mapState: state.map,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMinMaxColumnValues: (datasetId, columnName) =>
            dispatch(atlasActions.getMinMaxColumnValues(datasetId, columnName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GraduateModal)
