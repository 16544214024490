import { AppBar, Toolbar } from "@mui/material"
import ProjectsPicker from "features/core/components/ProjectsPicker/ProjectsPicker"
import Logo from "../Logo/Logo"
import { useStyles } from "./styles"

const Header = () => {
    const classes = useStyles()
    return (
        <AppBar position="static">
            <Toolbar className={classes.toolbar}>
                <Logo />
                <ProjectsPicker />
            </Toolbar>
        </AppBar>
    )
}

export default Header
