import config from "../../config"
import { SOURCE_TYPES } from "../../constants/sources/sourceTypes"

// Takes a source and returns a source that can be added to the map
export const makeMapSource = (source, isPublic) => {
    const mapSource = { maxzoom: source.maxZoom, type: source.type }

    const endpointName = source.type === SOURCE_TYPES.raster ? "tile/raster" : "tile/vector"
    const publicRoute = isPublic ? "/public" : ""

    const needsTiles =
        source.type === SOURCE_TYPES.raster ||
        source.type === SOURCE_TYPES.rasterDem ||
        source.type === SOURCE_TYPES.vector

    if (needsTiles) {
        mapSource.minzoom = source.minZoom
        mapSource.tiles = [config.apiUrl + `${endpointName}${publicRoute}/${source.id}/{z}/{x}/{y}`]
    }

    if (source.type === SOURCE_TYPES.geojson) {
        mapSource.data = source.data
    }

    if (source.type !== SOURCE_TYPES.geojson) {
        mapSource.bounds = source.bounds
    }

    return mapSource
}
