import React from "react"
import RemoveIcon from "@mui/icons-material/Close"
import TitleTextField from "../../common/TitleTextfield/TitleTextField"

const ColorTitle = ({ editable, title, isDefaultValue, isZoomDependent, onRemove, onTitleChanged }) => {
    const removable = !!(onRemove && !isDefaultValue && editable)
    return (
        <div className="property">
            <div className="property-content">

                <TitleTextField
                    value={title}
                    isDefaultValue={isDefaultValue}
                    isZoomDependent={isZoomDependent}
                    onTitleChanged={onTitleChanged}
                    isReadOnly={!editable}
                />

                {removable && (
                    <div className="remove-button color-title" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ColorTitle
