import * as React from "react";
const SvgShop15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "shop-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.33 6 L11.5 6 L11.11 3.67 C10.9499 2.9518 10.408299 2.3795 9.7 2.18 C9.3507 2.0676 8.9869 2.007 8.62 2 L6.38 2 C6.0131 2.007 5.6493 2.0676 5.3 2.18 C4.5917 2.3795 4.0501 2.9518 3.89 3.67 L3.5 6 L1.67 6 C1.3939 5.9983 1.1687 6.2208 1.167 6.497 C1.1667 6.5489 1.1744 6.6005 1.19 6.65 L3.0700002 12.950001 L3.0700002 12.950001 C3.2664 13.5746 3.8453 13.9996 4.5 14 L10.5 14 C11.151 13.9953 11.7247 13.5711 11.92 12.95 L11.92 12.95 L13.8 6.6499996 C13.8829 6.3865995 13.7365 6.1059 13.473101 6.023 C13.4268 6.0084 13.3786 6.0007 13.33 6 z M4.52 6 L4.88 3.83 C4.9607 3.4675 5.2536 3.1904998 5.62 3.1299999 C5.8663 3.0524 6.1219 3.0087 6.38 3 L8.62 3 C8.8814 3.0078 9.1405 3.0515 9.389999 3.13 C9.756399 3.1905 10.049299 3.4675002 10.129999 3.8300002 L10.48 6 L4.4799995 6 L4.52 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.33 6 L11.5 6 L11.11 3.67 C10.9499 2.9518 10.408299 2.3795 9.7 2.18 C9.3507 2.0676 8.9869 2.007 8.62 2 L6.38 2 C6.0131 2.007 5.6493 2.0676 5.3 2.18 C4.5917 2.3795 4.0501 2.9518 3.89 3.67 L3.5 6 L1.67 6 C1.3939 5.9983 1.1687 6.2208 1.167 6.497 C1.1667 6.5489 1.1744 6.6005 1.19 6.65 L3.0700002 12.950001 L3.0700002 12.950001 C3.2664 13.5746 3.8453 13.9996 4.5 14 L10.5 14 C11.151 13.9953 11.7247 13.5711 11.92 12.95 L11.92 12.95 L13.8 6.6499996 C13.8829 6.3865995 13.7365 6.1059 13.473101 6.023 C13.4268 6.0084 13.3786 6.0007 13.33 6 z M4.52 6 L4.88 3.83 C4.9607 3.4675 5.2536 3.1904998 5.62 3.1299999 C5.8663 3.0524 6.1219 3.0087 6.38 3 L8.62 3 C8.8814 3.0078 9.1405 3.0515 9.389999 3.13 C9.756399 3.1905 10.049299 3.4675002 10.129999 3.8300002 L10.48 6 L4.4799995 6 L4.52 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgShop15;
