import { lighten } from "@mui/material/styles"
import { makeStyles } from "@mui/styles"

const generalFontSize = 13

export const useStyles = makeStyles(theme => {
    return {
        activeText: {
            color: theme.customColors.successColor,
            fontSize: generalFontSize,
        },
        checkedIcon: {
            color: theme.customColors.successColor,
            fontSize: 15,
            marginRight: 3,
        },
        clickableContainer: {
            paddingInline: theme.spacing(2),
        },
        moduleDescription: {
            color: theme.customColors.appTextColorLight,
            fontSize: generalFontSize,
            lineHeight: 1.2,
        },
        moduleIcon: {
            marginRight: 10,
            width: 25,
        },
        moduleTitle: {
            fontSize: generalFontSize,
            fontWeight: 600,
        },
        noAccessBtn: {
            backgroundColor: lighten(theme.palette.primary.main, 0.92),
            color: theme.palette.primary.main,
            fontSize: generalFontSize,
            padding: "5px 10px 5px 10px",
        },
        rootItem: {
            "&:not(:first-child)": {
                borderTop: `1px solid ${theme.customColors.borderColor}`,
            },
            minHeight: 48,
            padding: 0,

            width: 400,
        },
    }
})
