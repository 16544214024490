import * as React from "react";
const SvgRestaurant11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "restaurant-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.25 0 L1.75 3.5 C1.6793 3.995 2.9908 4.9001 3 5.5 L3 11 L4 11 L4 5.5 C4 4.9 5.3207 3.995 5.25 3.5 L4.75 0 L4.25 0 L4.5 2.75 L3.75 3.25 L3.75 0 L3.25 0 L3.25 3.25 L2.5 2.75 L2.75 0 L2.25 0 z M9 0 C7.5 0 7.0064 1.7242 7 3 L7 6 L8 6 L8 11 L9 11 L9 0 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.25 0 L1.75 3.5 C1.6793 3.995 2.9908 4.9001 3 5.5 L3 11 L4 11 L4 5.5 C4 4.9 5.3207 3.995 5.25 3.5 L4.75 0 L4.25 0 L4.5 2.75 L3.75 3.25 L3.75 0 L3.25 0 L3.25 3.25 L2.5 2.75 L2.75 0 L2.25 0 z M9 0 C7.5 0 7.0064 1.7242 7 3 L7 6 L8 6 L8 11 L9 11 L9 0 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRestaurant11;
