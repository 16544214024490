import makeStyles from "@mui/styles/makeStyles"

const useGroupStyles = makeStyles(theme => ({
    borderBox: {
        borderLeft: `1px solid ${theme.customColors.borderColor}`,
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(-1),
    },
    collapseBtn: {
        borderRadius: 0,
        color: theme.customColors.black,
        height: 24,
        marginRight: theme.spacing(2),
        width: 24,
    },
    collapsed: {
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandedLeft: {
        transform: "rotate(180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandedRight: {
        transform: "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    groupName: {
        fontWeight: "bold",
        minWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "capitalize",
    },
    grow: {
        flexGrow: 1,
        minWidth: 0,
    },
    innerCollapseBtn: {
        borderRadius: 0,
        color: theme.customColors.black,
        height: 24,
        marginRight: theme.spacing(1),
        width: 24,
    },
    innerLayerContainer: {
        marginLeft: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
    },
    layerContainer: {
        backgroundColor: "white",
        paddingBlock: theme.spacing(1),
        paddingInline: theme.spacing(2),
    },
    layerCount: {
        color: theme.palette.primary.main,
        fontSize: 14,
    },
    layerCountSeperator: {
        backgroundColor: theme.customColors.borderColor,
        height: 24,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        width: 1,
    },
    nrOfLayers: {
        "&:hover": {
            backgroundColor: theme.customColors.appBgColor,
        },
        borderRadius: 4,
        marginLeft: 8,
    },
    root: {
        "&:not(:last-child)": {
            marginBottom: theme.spacing(1),
        },
    },
    rootContainer: {
        margin: theme.spacing(1, 2),
    },
    subGoupName: {
        minWidth: 200,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textTransform: "capitalize",
    },
}))

export default useGroupStyles
