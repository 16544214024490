import * as React from "react";
const SvgClothingStore11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "clothing-store-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.5 1 L0.5 3 L0.5 5 L2.5 5 L2.5 10 L8.5 10 L8.5 5 L10.5 5 L10.5 3 L8.5 1 L7 1 L5.5 4 L4 1 L2.5 1 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2.5 1 L0.5 3 L0.5 5 L2.5 5 L2.5 10 L8.5 10 L8.5 5 L10.5 5 L10.5 3 L8.5 1 L7 1 L5.5 4 L4 1 L2.5 1 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgClothingStore11;
