import React from "react"
import { useCanvas } from "../../hooks/useCanvas"

type PieChartCanvasProps = {
    colors: Array<string>
    columns: number
    height: number
    labels: Array<string>
    rows: number
    style: BoxChartCanvasStyleProps
    width: number
}

type BoxChartCanvasStyleProps = {
    fontSize: number
    strokeColor: string
    strokeWidth: number
}

const defaultStyle: BoxChartCanvasStyleProps = {
    fontSize: 13,
    strokeColor: "black",
    strokeWidth: 0,
}

const BoxChartWithLabelsCanvas = ({
    colors = [],
    columns = 3,
    height = 200,
    labels = [],
    rows = 2,
    style,
    width = 300,
}: PieChartCanvasProps) => {
    let styleMerged = { ...defaultStyle, ...style }

    let borderPadding = styleMerged.strokeWidth * 2

    let rectWidth = Math.floor((width - borderPadding) / columns)
    let rectHeight = Math.floor((height - borderPadding) / rows)

    const draw = (context: CanvasRenderingContext2D) => {
        if (!canvasRef?.current) {
            return
        }

        context.clearRect(0, 0, width, height)

        let currentX = styleMerged.strokeWidth,
            currentY = styleMerged.strokeWidth,
            colorIndex = 0

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < columns; col++) {
                let color = colors[colorIndex] || "rgba(255, 255, 255, 0)"
                let label = labels[colorIndex] || ""

                renderBox(context, currentX, currentY, color)
                renderLabel(context, currentX, currentY, label)

                currentX += rectWidth
                colorIndex++
            }
            currentX = styleMerged.strokeWidth
            currentY += rectHeight
        }
    }

    const renderBox = (context: CanvasRenderingContext2D, currentX: number, currentY: number, color: string) => {
        context.beginPath()
        context.rect(currentX, currentY, rectWidth, rectHeight)

        context.fillStyle = color
        context.fill()

        context.strokeStyle = styleMerged.strokeColor
        context.lineWidth = styleMerged.strokeWidth

        if (styleMerged.strokeWidth > 0) {
            context.stroke()
        }
    }

    const renderLabel = (context: CanvasRenderingContext2D, currentX: number, currentY: number, label: string) => {
        let fontSize = styleMerged.fontSize

        context.font = "13px Arial"
        let textMeasure = context.measureText(label)

        let textWidth = textMeasure.width
        let textHeight = textMeasure.actualBoundingBoxAscent

        let lines = [label]

        // if text is wider than the box, run the following downsizeing algorithm
        while (textWidth > rectWidth || textHeight > rectHeight) {
            //Break the label into lines, if we have space
            if (textHeight < rectHeight) {
                textWidth = 0

                lines = getLines(context, label, rectWidth - styleMerged.strokeWidth * 2)

                //if we're able to break the word
                if (lines.length > 1) {
                    //run though each line
                    for (let k = 0; k < lines.length; k++) {
                        //Measure it
                        const lineTextMeasure = context.measureText(lines[k])

                        //Keep track of the widest line with some padding
                        if (lineTextMeasure.width + 8 > textWidth) {
                            textWidth = lineTextMeasure.width + 8
                        }

                        //Stack the height of the lines, with some padding
                        textHeight += lineTextMeasure.actualBoundingBoxAscent + styleMerged.strokeWidth * 2
                    }
                } else {
                    textHeight = 1000
                }
            }
            //Otherwise reduce the font size and try again
            else {
                fontSize -= 2
                context.font = fontSize + "px Arial"
                let textMeasure = context.measureText(label)
                textHeight = 0
                textWidth = textMeasure.width
            }
        }

        for (let j = 0; j < lines.length; j++) {
            let line = lines[j]
            let lineTextMeasure = context.measureText(line)
            let textX = currentX + rectWidth / 2 - lineTextMeasure.width / 2
            let textY =
                currentY + rectHeight / ((lines.length + 1) / (j + 1)) + lineTextMeasure.actualBoundingBoxAscent / 2

            context.fillStyle = "white"
            context.strokeStyle = "black"
            context.lineWidth = 3
            context.lineJoin = "round"
            context.strokeText(line, textX, textY)
            context.fillText(line, textX, textY)
        }
    }

    const getLines = (ctx: CanvasRenderingContext2D, text: string, maxWidth: number) => {
        const words = text.split(" ")
        const lines = []
        let currentLine = words[0]

        for (var i = 1; i < words.length; i++) {
            const word = words[i]
            const width = ctx.measureText(currentLine + " " + word).width
            if (width < maxWidth) {
                currentLine += " " + word
            } else {
                lines.push(currentLine)
                currentLine = word
            }
        }
        lines.push(currentLine)
        return lines
    }

    const onClick = () => {}

    const canvasRef = useCanvas(draw)

    return <canvas height={height} ref={canvasRef} width={width} onClick={onClick} />
}

export default BoxChartWithLabelsCanvas
