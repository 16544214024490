export const AUTHENTICATING = "AUTHENTICATING"
export const AUTHENTICATED = "AUTHENTICATED"
export const AUTHENTICATING_FAILED = "AUTHENTICATING_FAILED"

export const DRAW_FEATURES_CREATED = "DRAW_FEATURES_CREATED"
export const DRAW_FEATURE_DELETED = "DRAW_FEATURE_DELETED"
export const DRAW_FEATURE_EDITED = "DRAW_FEATURE_EDITED"
export const DRAW_FEATURES_RENAMED = "DRAW_FEATURES_RENAMED"
export const SELECT_DIGITIZE_FEATURE = "SELECT_DIGITIZE_FEATURE"
export const ADD_DIGITIZE_LAYER = "ADD_DIGITIZE_LAYER"
export const EDIT_DIGITIZE_LAYER = "EDIT_DIGITIZE_LAYER"
export const DELETE_DIGITIZE_LAYER = "DELETE_DIGITIZE_LAYER"
export const DIGITIZE_EDITING = "DIGITIZE_EDITING"
export const DIGITIZE_HELP = "DIGITIZE_HELP"
export const TOGGLE_DIGITIZE_LAYER = "TOGGLE_DIGITIZE_LAYER"
export const TOGGLE_ALL_DIGITIZE_LAYERS = "TOGGLE_ALL_DIGITIZE_LAYERS"

export const SET_SEARCH = "SET_SEARCH"
export const FETCH_APPS_START = "FETCH_APPS_START"
export const FETCH_APPS_COMPLETE = "FETCH_APPS_COMPLETE"
export const FETCH_APPS_FAILED = "FETCH_APPS_FAILED"
export const SET_CONFIG = "SET_CONFIG"

export const ADD_DIGITIZE_STYLE = "ADD_DIGITIZE_STYLE"
export const UPDATE_DIGITIZE_STYLE = "UPDATE_DIGITIZE_STYLE"
export const REMOVE_DIGITIZE_STYLE = "REMOVE_DIGITIZE_STYLE"
export const REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER = "REMOVE_ALL_DIGITIZE_STYLES_FOR_LAYER"
export const RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER = "RENAME_DIGITIZE_STYLES_NAME_FOR_LAYER"
export const REINITIALIZE_DIGITIZE_STYLES_LAYER_ID = "REINITIALIZE_DIGITIZE_STYLES_LAYER_ID"
export const TOGGLE_SIDEBAR_OPEN = "TOGGLE_SIDEBAR_OPEN"
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN"
export const TOOLS_TOGGLE_WIDGET = "TOOLS_TOGGLE_WIDGET"
export const PRINT_MAP_LEGEND = "PRINT_MAP_LEGEND"
export const PRINT_MAP_SCALEBAR = "PRINT_MAP_SCALEBAR"
export const PRINT_TOGGLE_MAP_NOTES = "PRINT_TOGGLE_MAP_NOTES"
export const PRINT_MAP_NOTES = "PRINT_MAP_NOTES"
export const PRINT_TOGGLE_MAP_TITLE = "PRINT_TOGGLE_MAP_TITLE"
export const PRINT_MAP_TITLE = "PRINT_MAP_TITLE"
export const PRINT_TOGGLE_MAP_DATE = "PRINT_TOGGLE_MAP_DATE"
export const PRINT_TOGGLE_MAP_DATE_FORMAT = "PRINT_TOGGLE_MAP_DATE_FORMAT"
export const PRINT_TOGGLE_MAP_COPYRIGHT = "PRINT_TOGGLE_MAP_COPYRIGHT"
export const PRINT_TOGGLE_MAP_NORTH_ARROW = "PRINT_TOGGLE_MAP_NORTH_ARROW"
export const PRINT_TOGGLE_MAP_LOGO = "PRINT_TOGGLE_MAP_LOGO"
export const RESET_PRINT_FEATURES = "RESET_PRINT_FEATURES"

export const FETCH_MAPBOX_STYLE = "FETCH_MAPBOX_STYLE"
export const FETCH_MAPBOX_STYLE_COMPLETED = "FETCH_MAPBOX_STYLE_COMPLETED"

export const TOGGLE_NAVIGATION_OPEN = "TOGGLE_NAVIGATION_OPEN"
export const TOGGLE_DRAWER_OPEN = "TOGGLE_DRAWER_OPEN"
