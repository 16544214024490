import { styled, css } from "@mui/material"

export const StyledContainerDiv = styled("div", {
    shouldForwardProp: prop => prop !== "width" && prop !== "height",
})<{ width: number; height: number }>(
    ({ width, height }) => css`
        height: ${height}px;
        width: ${width}px;
        overflow-y: auto;
        contain: strict;
    `,
)

export const StyledInnerContainerDiv = styled("div", {
    shouldForwardProp: prop => prop !== "height",
})<{ height?: number }>(
    ({ height = 0 }) => css`
        height: ${height}px;
        width: 100%;
        position: relative;
    `,
)

export const StyledVirtualItemsContainerDiv = styled("div", {
    shouldForwardProp: prop => prop !== "startYPosition",
})<{ startYPosition?: number }>(
    ({ startYPosition = 0 }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateY(${startYPosition}px);
    `,
)
