import * as React from "react";
const SvgVeterinary11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "veterinary-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 4.32 C4.2852 4.3446 3.3204 5.3493 3.345 6.5641 C3.3464 6.6363 3.3515 6.7083 3.36 6.78 L3.36 6.78 C2.9264 6.9258 2.5181 7.1384 2.15 7.41 C1.5186 7.8752 1.384 8.7642 1.8492 9.3955 C1.8528 9.4004 1.8564 9.4052 1.86 9.41 C2.3495 10.014299 3.2233 10.1367 3.8600001 9.69 C4.3412 9.3542 4.9132 9.1728 5.5 9.17 C6.0836 9.1640005 6.6554003 9.3348 7.14 9.66 C7.7409 10.1553 8.6296 10.0696 9.1248 9.468699 C9.1299 9.4625 9.135 9.4563 9.14 9.45 C9.640301 8.8972 9.5978 8.0435 9.045 7.5431995 C9.0084 7.51 8.97 7.4789 8.93 7.45 L8.85 7.41 C8.4703 7.1575 8.0646 6.9463 7.64 6.78 L7.64 6.78 C7.7837996 5.5735 6.9224 4.4789 5.7159 4.335 C5.6442 4.3265 5.5722 4.3215 5.5 4.32 z M1.63 3.12 C1.0889 3.3737 0.8458 4.0102 1.08 4.56 C1.1227001 5.1531 1.6367 5.6001 2.23 5.56 C2.7711 5.3063 3.0142 4.6698 2.78 4.12 C2.7373 3.5269 2.2233 3.0799 1.63 3.12 z M9.37 3.12 C9.9111 3.3737 10.1542 4.0102 9.92 4.56 C9.8773 5.1531 9.3633 5.6001 8.77 5.56 C8.2289 5.3063 7.9858 4.6698 8.22 4.12 C8.2627 3.5269 8.7767 3.0799 9.37 3.12 z M3.77 1 C3.257 1.2045 3.0069 1.7862 3.2115 2.2992 C3.2142 2.3062 3.2171 2.3131 3.22 2.32 C3.2992 2.9125 3.7837 3.3677998 4.38 3.4099998 C4.893 3.2055 5.1431003 2.6237998 4.9385 2.1107998 C4.9358 2.1038 4.9329 2.0969 4.93 2.09 C4.8508 1.4975 4.3663 1.0422 3.77 1 z M7.23 1 C7.743 1.2045 7.9931 1.7862 7.7885 2.2992 C7.7858 2.3062 7.7829 2.3131 7.78 2.32 C7.7008 2.9125 7.2163 3.3678 6.62 3.41 C6.107 3.2055 5.8569 2.6238 6.0615 2.1108 C6.0642 2.1038 6.0671 2.0969 6.07 2.09 C6.1492 1.4975 6.6337 1.0422 7.23 1 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 4.32 C4.2852 4.3446 3.3204 5.3493 3.345 6.5641 C3.3464 6.6363 3.3515 6.7083 3.36 6.78 L3.36 6.78 C2.9264 6.9258 2.5181 7.1384 2.15 7.41 C1.5186 7.8752 1.384 8.7642 1.8492 9.3955 C1.8528 9.4004 1.8564 9.4052 1.86 9.41 C2.3495 10.014299 3.2233 10.1367 3.8600001 9.69 C4.3412 9.3542 4.9132 9.1728 5.5 9.17 C6.0836 9.1640005 6.6554003 9.3348 7.14 9.66 C7.7409 10.1553 8.6296 10.0696 9.1248 9.468699 C9.1299 9.4625 9.135 9.4563 9.14 9.45 C9.640301 8.8972 9.5978 8.0435 9.045 7.5431995 C9.0084 7.51 8.97 7.4789 8.93 7.45 L8.85 7.41 C8.4703 7.1575 8.0646 6.9463 7.64 6.78 L7.64 6.78 C7.7837996 5.5735 6.9224 4.4789 5.7159 4.335 C5.6442 4.3265 5.5722 4.3215 5.5 4.32 z M1.63 3.12 C1.0889 3.3737 0.8458 4.0102 1.08 4.56 C1.1227001 5.1531 1.6367 5.6001 2.23 5.56 C2.7711 5.3063 3.0142 4.6698 2.78 4.12 C2.7373 3.5269 2.2233 3.0799 1.63 3.12 z M9.37 3.12 C9.9111 3.3737 10.1542 4.0102 9.92 4.56 C9.8773 5.1531 9.3633 5.6001 8.77 5.56 C8.2289 5.3063 7.9858 4.6698 8.22 4.12 C8.2627 3.5269 8.7767 3.0799 9.37 3.12 z M3.77 1 C3.257 1.2045 3.0069 1.7862 3.2115 2.2992 C3.2142 2.3062 3.2171 2.3131 3.22 2.32 C3.2992 2.9125 3.7837 3.3677998 4.38 3.4099998 C4.893 3.2055 5.1431003 2.6237998 4.9385 2.1107998 C4.9358 2.1038 4.9329 2.0969 4.93 2.09 C4.8508 1.4975 4.3663 1.0422 3.77 1 z M7.23 1 C7.743 1.2045 7.9931 1.7862 7.7885 2.2992 C7.7858 2.3062 7.7829 2.3131 7.78 2.32 C7.7008 2.9125 7.2163 3.3678 6.62 3.41 C6.107 3.2055 5.8569 2.6238 6.0615 2.1108 C6.0642 2.1038 6.0671 2.0969 6.07 2.09 C6.1492 1.4975 6.6337 1.0422 7.23 1 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgVeterinary11;
