import { Divider, Grid, Typography } from "@mui/material"
import LegendEntries from "../../legend/LegendEntries/LegendEntries"
import { useLegendEntryStyles } from "./legendEntryStyles"
import { useStyles } from "./styles"

const LegendTool = () => {
    const classes = useStyles()
    const legendEntryClasses = useLegendEntryStyles()

    const spacingOptions = {
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 4,
        multiLabelItemVerticalBreakpointSpacing: 12,
        singleEntryBreakpointSpacing: 12,
    }

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <Divider />
            <Grid className={classes.title} container justifyContent="flex-start">
                <Typography variant="h6">Legend</Typography>
            </Grid>
            <Divider />
            <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
        </Grid>
    )
}

export default LegendTool
