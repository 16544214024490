import { css, styled } from "@mui/material"
import { Button, TextField } from "@mui/material"

type StyledButtonProps = {
    isSelected?: boolean
}

export const StyledTextField = styled(TextField)`
    width: 40px;
    input {
        text-align: center;
        padding: 0 2px;
        font-size: 16px;
    }
`

//TODO: Fix the issue with the Theme
export const StyledButton = styled(Button, { shouldForwardProp: prop => prop !== "isSelected" })<StyledButtonProps>(
    ({ theme, isSelected = false }) => css`
        display: flex;
        justify-content: flex-start;
        color: ${isSelected ? (theme as any).customColors.primaryColor : (theme as any).customColors.appTextColorLight};
        margin-inline: ${(theme as any).spacing(0.5)};
        & svg {
            margin-right: ${(theme as any).spacing(0.5)};
        }
    `,
)
