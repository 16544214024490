import * as React from "react";
const SvgBus11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bus-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 0 C2 0 1 0.5312 1 2 L1 6 L1 9.5 C1 9.5 1 10 1.5 10 L2 10.0156 L2 10.5 C2 10.5 2 11 2.5 11 L3 11 C3 11 3.5 11 3.5 10.5 L3.5 10.0156 L7.5 10 L7.5 10.5 C7.5 10.5 7.5 11 8 11 L8.5 11 C9 11 9 10.5 9 10.5 L9 10.0156 L9.5 10 C9.5 10 10 10 10 9.5 L10 6 L10 2 C10 0.5 9 0 8 0 L3 0 z M3.75 1 L7.25 1 C7.3885 1 7.5 1.1115 7.5 1.25 C7.5 1.3885 7.3885 1.5 7.25 1.5 L3.75 1.5 C3.6115 1.5 3.5 1.3885 3.5 1.25 C3.5 1.1115 3.6115 1 3.75 1 z M3 2 L8 2 C9 2 9 3 9 3 L9 5 C9 5 9 6 8 6 L3 6 C2 6 2 5 2 5 L2 3 C2 3 2 2 3 2 z M2.75 7.5 C3.1642 7.5 3.5 7.8358 3.5 8.25 C3.5 8.6642 3.1642 9 2.75 9 C2.3358 9 2 8.6642 2 8.25 C2 7.8358 2.3358 7.5 2.75 7.5 z M8.25 7.5 C8.6642 7.5 9 7.8358 9 8.25 C9 8.6642 8.6642 9 8.25 9 C7.8358 9 7.5 8.6642 7.5 8.25 C7.5 7.8358 7.8358 7.5 8.25 7.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 0 C2 0 1 0.5312 1 2 L1 6 L1 9.5 C1 9.5 1 10 1.5 10 L2 10.0156 L2 10.5 C2 10.5 2 11 2.5 11 L3 11 C3 11 3.5 11 3.5 10.5 L3.5 10.0156 L7.5 10 L7.5 10.5 C7.5 10.5 7.5 11 8 11 L8.5 11 C9 11 9 10.5 9 10.5 L9 10.0156 L9.5 10 C9.5 10 10 10 10 9.5 L10 6 L10 2 C10 0.5 9 0 8 0 L3 0 z M3.75 1 L7.25 1 C7.3885 1 7.5 1.1115 7.5 1.25 C7.5 1.3885 7.3885 1.5 7.25 1.5 L3.75 1.5 C3.6115 1.5 3.5 1.3885 3.5 1.25 C3.5 1.1115 3.6115 1 3.75 1 z M3 2 L8 2 C9 2 9 3 9 3 L9 5 C9 5 9 6 8 6 L3 6 C2 6 2 5 2 5 L2 3 C2 3 2 2 3 2 z M2.75 7.5 C3.1642 7.5 3.5 7.8358 3.5 8.25 C3.5 8.6642 3.1642 9 2.75 9 C2.3358 9 2 8.6642 2 8.25 C2 7.8358 2.3358 7.5 2.75 7.5 z M8.25 7.5 C8.6642 7.5 9 7.8358 9 8.25 C9 8.6642 8.6642 9 8.25 9 C7.8358 9 7.5 8.6642 7.5 8.25 C7.5 7.8358 7.8358 7.5 8.25 7.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBus11;
