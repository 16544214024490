import { DragIndicator } from "@mui/icons-material"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import { ZanderParameter as ParameterType } from "features/zander/models/ZanderParameter"
import { ZanderParameterTypeEnum } from "features/zander/models/ZanderParameterTypeEnum"
import { parametersDisplayNames } from "features/zander/utils"
import limitsSchema from "features/zander/validators/limitsSchema"
import LimitsCreator from "../LimitsCreator/LimitsCreator"
import { useZanderParameterStyles } from "./styles"

type Props = {
    parameter: ParameterType
    onParameterChanged: (parameter: ParameterType) => void
}

const ZanderParameter = ({ parameter, onParameterChanged }: Props) => {
    const classes = useZanderParameterStyles()

    const onMaxLimitsChanged = (newLimits: number[]) => onParameterChanged({ ...parameter, maxLimits: newLimits })

    const displayName = parametersDisplayNames[parameter.type]

    return (
        <>
            <div className={classes.zanderParameter}>
                <div className={classes.dragIndicatorColumn}>
                    <DragIndicator fontSize="medium" />
                </div>
                <div className={classes.contentColumn}>
                    <div className={classes.zanderParameterHeader}>
                        <CustomTypography fontWeight="bold" variant="body2">
                            {displayName}
                        </CustomTypography>
                    </div>
                    <div className={classes.limitsContainer}>
                        <LimitsCreator
                            displayDecimals={parameter.type === ZanderParameterTypeEnum.WaveHeight}
                            limits={parameter.maxLimits.map(limit => {
                                return {
                                    error: false,
                                    value: limit,
                                }
                            })}
                            text="Save"
                            validationSchema={limitsSchema(parameter.type)}
                            onLimitsChanged={onMaxLimitsChanged}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZanderParameter
