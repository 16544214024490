import * as React from "react";
const SvgHospital11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "hospital-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 4 L7 4 L7 1 C6.9616 0.4644 6.5356 0.0384 6 0 L5 0 C4.4644 0.0384 4.0384 0.4644 4 1 L4 4 L1 4 C0.4644 4.0384 0.0384 4.4644 0 5 L0 6 C0.0384 6.5356 0.4644 6.9616 1 7 L4 7 L4 10 C4.0384 10.5356 4.4644 10.9616 5 11 L6 11 C6.5356 10.9616 6.9616 10.5356 7 10 L7 7 L10 7 C10.5356 6.9616 10.9616 6.5356 11 6 L11 5 C11 4.4477 10.5523 4 10 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 4 L7 4 L7 1 C6.9616 0.4644 6.5356 0.0384 6 0 L5 0 C4.4644 0.0384 4.0384 0.4644 4 1 L4 4 L1 4 C0.4644 4.0384 0.0384 4.4644 0 5 L0 6 C0.0384 6.5356 0.4644 6.9616 1 7 L4 7 L4 10 C4.0384 10.5356 4.4644 10.9616 5 11 L6 11 C6.5356 10.9616 6.9616 10.5356 7 10 L7 7 L10 7 C10.5356 6.9616 10.9616 6.5356 11 6 L11 5 C11 4.4477 10.5523 4 10 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgHospital11;
