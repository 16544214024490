import * as React from "react";
const SvgInformation11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "information-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5989 0.9391 C4.9989 0.9391 4.4989 1.4391 4.4989 2.0391002 C4.4989 2.6391003 4.9989 3.1391 5.5989 3.1391 C6.1988997 3.1391 6.6988997 2.6391 6.6988997 2.0391002 C6.6988997 1.4391003 6.1989 0.9391 5.5989 0.9391 z M3 4 L2.9989 4.7391 C2.9989 4.7391 4.5 4.6353 4.5 6 L4.5 7.5 C4.5 9 2.9989 9.2390995 2.9989 9.2390995 L3 10 L8.2 10 L8.1989 9.2391 C8.1989 9.2391 6.9989004 9.2391 6.9989004 7.7391005 L7 5 C7 5 7 4 6 4 L3 4 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5989 0.9391 C4.9989 0.9391 4.4989 1.4391 4.4989 2.0391002 C4.4989 2.6391003 4.9989 3.1391 5.5989 3.1391 C6.1988997 3.1391 6.6988997 2.6391 6.6988997 2.0391002 C6.6988997 1.4391003 6.1989 0.9391 5.5989 0.9391 z M3 4 L2.9989 4.7391 C2.9989 4.7391 4.5 4.6353 4.5 6 L4.5 7.5 C4.5 9 2.9989 9.2390995 2.9989 9.2390995 L3 10 L8.2 10 L8.1989 9.2391 C8.1989 9.2391 6.9989004 9.2391 6.9989004 7.7391005 L7 5 C7 5 7 4 6 4 L3 4 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgInformation11;
