import * as React from "react";
const SvgIogp1105 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "389.88, 248.93, 144.64001, 144.63", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "462.2,321.24 462.2,321.24", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "438.18,321.24 438.37,318.17 438.96,315.15 439.93,312.23 441.27,309.46 442.95,306.88 444.94,304.53 447.22,302.46 449.75,300.7 452.47,299.28 455.36,298.21 458.36,297.53 461.43,297.23 464.51,297.33 467.54,297.82 470.5,298.7 473.31,299.95 475.94,301.54 478.35,303.46 480.49,305.67 482.33,308.14 483.84,310.82 485,313.68 485.78,316.65 486.17,319.71 486.17,322.78 485.78,325.84 485,328.81 483.84,331.67 482.33,334.35 480.49,336.82 478.35,339.03 475.94,340.95 473.31,342.54 470.5,343.79 467.54,344.67 464.51,345.16 461.43,345.26 458.36,344.96 455.36,344.28 452.47,343.21 449.75,341.79 447.22,340.03 444.94,337.96 442.95,335.61 441.27,333.03 439.93,330.26 438.96,327.34 438.37,324.32 438.18,321.24", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("path", { d: "M456.24 322 L456.61 323.46 L456.24 320.49 L456.24 322 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M456.61 323.46 L456.61 319.03 L456.24 320.49 L456.61 323.46 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M456.61 323.46 L457.34 324.78 L456.61 319.03 L456.61 323.46 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.34 324.78 L457.34 317.71 L456.61 319.03 L457.34 324.78 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.34 324.78 L458.37 325.87 L457.34 317.71 L457.34 324.78 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.37 325.87 L458.37 316.62 L457.34 317.71 L458.37 325.87 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M458.37 325.87 L459.64 326.68 L458.37 316.62 L458.37 325.87 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M459.64 326.68 L459.64 315.81 L458.37 316.62 L459.64 326.68 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M459.64 326.68 L461.07 327.14 L459.64 315.81 L459.64 326.68 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.07 327.14 L461.07 315.35 L459.64 315.81 L461.07 327.14 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.07 327.14 L462.58 327.24 L461.07 315.35 L461.07 327.14 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M462.58 327.24 L462.58 315.25 L461.07 315.35 L462.58 327.24 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M462.58 327.24 L464.05 326.96 L462.58 315.25 L462.58 327.24 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M464.05 326.96 L464.05 315.53 L462.58 315.25 L464.05 326.96 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M464.05 326.96 L465.42 326.32 L464.05 315.53 L464.05 326.96 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.42 326.32 L465.42 316.17 L464.05 315.53 L465.42 326.32 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.42 326.32 L466.58 325.36 L465.42 316.17 L465.42 326.32 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M466.58 325.36 L466.58 317.13 L465.42 316.17 L466.58 325.36 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M466.58 325.36 L467.46 324.14 L466.58 317.13 L466.58 325.36 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M467.46 324.14 L467.46 318.35 L466.58 317.13 L467.46 324.14 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M467.46 324.14 L468.02 322.74 L467.46 318.35 L467.46 324.14 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.02 322.74 L468.02 319.75 L467.46 318.35 L468.02 322.74 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.02 322.74 L468.2 321.24 L468.02 319.75 L468.02 322.74 z", fillRule: "evenodd", style: {
  fill: "#C500FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "456.19,321.24 456.38,319.75 456.94,318.35 457.82,317.13 458.98,316.17 460.34,315.53 461.82,315.25 463.32,315.35 464.76,315.81 466.03,316.62 467.06,317.71 467.78,319.03 468.16,320.49 468.16,322 467.78,323.46 467.06,324.78 466.03,325.87 464.76,326.68 463.32,327.14 461.82,327.24 460.34,326.96 458.98,326.32 457.82,325.36 456.94,324.14 456.38,322.74 456.19,321.24", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#C500FF"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "462.2,393.31 462.2,345.27", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "462.2,297.22 462.2,249.18", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "390.13,321.24 438.18,321.24", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "486.22,321.24 534.27,321.24", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#E600A9"
} }));
export default SvgIogp1105;
