import * as React from "react";
const SvgMusic15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "music-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.5 1 C13.4196 1.0008 13.3406 1.0214 13.27 1.06 L4.5 3.5 C4.2239 3.5 4 3.7239 4 4 L4 10.280001 C3.6971 10.1002 3.3522 10.0037 3 10 C1.8954 10 1 10.8954 1 12 C1 13.1046 1.8954 14 3 14 C4.1046 14 5 13.1046 5 12 L5 7.36 L13 5.1400003 L13 8.780001 C12.6971 8.600201 12.3522 8.5037 12 8.500001 C10.8954 8.500001 10 9.395401 10 10.500001 C10 11.604601 10.8954 12.500001 12 12.500001 C13.1046 12.500001 14 11.604601 14 10.500001 L14 1.500001 C14 1.2239 13.7761 1 13.5 1 z M13 4.14 L5 6.36 L5 4.36 L13 2.14 C13 2.14 13 4.14 13 4.14 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.5 1 C13.4196 1.0008 13.3406 1.0214 13.27 1.06 L4.5 3.5 C4.2239 3.5 4 3.7239 4 4 L4 10.280001 C3.6971 10.1002 3.3522 10.0037 3 10 C1.8954 10 1 10.8954 1 12 C1 13.1046 1.8954 14 3 14 C4.1046 14 5 13.1046 5 12 L5 7.36 L13 5.1400003 L13 8.780001 C12.6971 8.600201 12.3522 8.5037 12 8.500001 C10.8954 8.500001 10 9.395401 10 10.500001 C10 11.604601 10.8954 12.500001 12 12.500001 C13.1046 12.500001 14 11.604601 14 10.500001 L14 1.500001 C14 1.2239 13.7761 1 13.5 1 z M13 4.14 L5 6.36 L5 4.36 L13 2.14 C13 2.14 13 4.14 13 4.14 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMusic15;
