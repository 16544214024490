import * as React from "react";
const SvgCar11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "car-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 4 L8.11 1.34 C8.0418 1.1381 7.8531 1.0016 7.64 1 L3.36 1 C3.1469 1.0016 2.9582 1.1381 2.89 1.34 L2 4 C1.4477 4 1 4.4477 1 5 L1 8 L2 8 L2 9 C2 9.5522995 2.4477 10 3 10 C3.5523 10 4 9.5523 4 9 L4 8 L7 8 L7 9 C7 9.5522995 7.4477 10 8 10 C8.5522995 10 9 9.5523 9 9 L9 8 L10 8 L10 5 C10 4.4477 9.5523 4 9 4 z M3 7 C2.4477 7 2 6.5523 2 6 C2 5.4477 2.4477 5 3 5 C3.5523 5 4 5.4477 4 6 C4 6.5523 3.5523 7 3 7 z M3 4 L3.62 2 L7.38 2 L8 4 L3 4 z M8 7 C7.4477 7 7 6.5523 7 6 C7 5.4477 7.4477 5 8 5 C8.5522995 5 9 5.4477 9 6 C9 6.5523 8.5523 7 8 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 4 L8.11 1.34 C8.0418 1.1381 7.8531 1.0016 7.64 1 L3.36 1 C3.1469 1.0016 2.9582 1.1381 2.89 1.34 L2 4 C1.4477 4 1 4.4477 1 5 L1 8 L2 8 L2 9 C2 9.5522995 2.4477 10 3 10 C3.5523 10 4 9.5523 4 9 L4 8 L7 8 L7 9 C7 9.5522995 7.4477 10 8 10 C8.5522995 10 9 9.5523 9 9 L9 8 L10 8 L10 5 C10 4.4477 9.5523 4 9 4 z M3 7 C2.4477 7 2 6.5523 2 6 C2 5.4477 2.4477 5 3 5 C3.5523 5 4 5.4477 4 6 C4 6.5523 3.5523 7 3 7 z M3 4 L3.62 2 L7.38 2 L8 4 L3 4 z M8 7 C7.4477 7 7 6.5523 7 6 C7 5.4477 7.4477 5 8 5 C8.5522995 5 9 5.4477 9 6 C9 6.5523 8.5523 7 8 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCar11;
