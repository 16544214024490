import { useEffect, useState } from "react"
import { Add, Cancel, Delete, Edit, GetApp, MoreHoriz, Palette, Save } from "@mui/icons-material"
import {
    Checkbox,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SvgIcon,
    TextField,
    Typography,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import * as digitizeActions from "actions/digitize"
import { getDigitizeFeatures } from "selectors/digitizeSelectors"
import { useMainDraw } from "utils/customHooks/map/useMainDraw"
import toastr from "utils/customToastr"
import { digitizeLayerToStyle } from "utils/digitizeUtils"
import { digitizeIcons } from "utils/mapIcons"
import DownloadDialog from "./digitizeDownloadDialog"
import { useDigitizeLayerStyles } from "./styles/digitizeLayerStyles"

const LayerMenu = props => {
    function removeLayer(e, layer) {
        e.stopPropagation()

        const toastrConfirmOptions = {
            onCancel: () => {},
            onOk: () => props.handleRemoveLayer(layer),
        }
        toastr.confirm(`Are you sure you want to delete layer: ${props.layer.name} ?`, toastrConfirmOptions)
    }

    return (
        <Menu
            anchorEl={props.anchorEl}
            id="digitize-layer-menu"
            open={Boolean(props.anchorEl)}
            onClose={() => props.handleClose(props.layer)}
        >
            <MenuItem onClick={() => props.drawingFeature(props.layer)}>
                <ListItemIcon>
                    <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Add feature" />
            </MenuItem>
            <MenuItem
                onClick={() => {
                    props.setSelectedLayer(props.layer)
                    props.setIsStylerOpen(true)
                }}
            >
                <ListItemIcon>
                    <Palette fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Change style" />
            </MenuItem>
            <MenuItem onClick={props.handleClickOpenDialog}>
                <ListItemIcon>
                    <GetApp fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Download" />
            </MenuItem>
            <MenuItem onClick={e => removeLayer(e, props.layer)}>
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Delete layer" />
            </MenuItem>
        </Menu>
    )
}

const DigitizeLayer = ({
    deselectAllFeaturesAndLabels,
    layer,
    selectedLayer,
    resetDrawingState,
    setIsStylerOpen,
    setSelectedLayer,
    onDraw,
}) => {
    const classes = useDigitizeLayerStyles()

    const draw = useMainDraw()

    const [showEditIcon, setShowEditIcon] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editedText, setEditedText] = useState(layer.namePretty)
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)

    const digitizeFeatures = useSelector(getDigitizeFeatures)

    const dispatch = useDispatch()

    useEffect(() => {
        deselectAllFeaturesAndLabels()
    }, [anchorEl, editMode])

    const handleRemoveLayer = layer => {
        layer.features &&
            layer.features.forEach(feature => {
                let featureToBeDeleted = digitizeFeatures.find(element => element.id === feature)
                dispatch(digitizeActions.removeFeature(featureToBeDeleted))
                draw.delete(featureToBeDeleted.id)
            })

        dispatch(digitizeActions.removeLayer(layer))
        dispatch(digitizeActions.removeAllDigitizeStylesForLayer(layer.datasetId))
    }

    const handleSave = layer => {
        let newLayer = { ...layer }
        newLayer.namePretty = editedText
        newLayer.name = editedText.toLowerCase()
        dispatch(digitizeActions.editLayer(newLayer))
        dispatch(digitizeActions.renameDigitizeStylesNameForLayer(digitizeLayerToStyle(newLayer)))
        setEditMode(false)
        setEditedText(newLayer.namePretty)
    }

    const handleEdit = e => {
        e.nativeEvent.stopImmediatePropagation()
        setEditMode(true)
    }

    const handleEditMode = (e, layer) => {
        if (e.keyCode === 13) {
            handleSave(layer)
        } else if (e.keyCode === 27) {
            setEditMode(false)
            setEditedText("")
        }
    }

    const handleClick = (e, layer) => {
        setAnchorEl(e.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSelectLayer = (e, layer) => {
        // e.nativeEvent.stopImmediatePropagation();
        setEditMode(true)
        setSelectedLayer(layer)
    }

    const drawingFeature = layer => {
        handleClose()
        onDraw(layer)
    }

    const handleClickOpenDialog = () => {
        setOpen(true)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const handleChange = (e, layer) => {
        e.stopPropagation()

        deselectAllFeaturesAndLabels()

        const selected = e.target.checked
        dispatch(digitizeActions.toggleDigitizeLayer(layer.id, selected))

        layer.features.forEach(featureId => {
            draw.setFeatureProperty(featureId, "hidden", !selected)
            draw.add(draw.get(featureId))
        })

        resetDrawingState()
    }

    return (
        <Grid
            key={layer.id}
            className={selectedLayer.id === layer.id ? classes.selectedLayer : classes.unselectedLayer}
            item
            paddingX={2}
            xs={12}
        >
            <Grid alignItems="center" container>
                <Grid item xs={1}>
                    <Grid container justifyContent="center">
                        <Checkbox
                            checked={layer.selected}
                            inputProps={{ "aria-label": "show feature in legend checkbox" }}
                            onChange={e => handleChange(e, layer)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    className={classes.editFieldContainer}
                    item
                    xs={10}
                    onMouseEnter={() => setShowEditIcon(true)}
                    onMouseLeave={() => setShowEditIcon(false)}
                >
                    <Grid alignItems="center" container wrap="nowrap">
                        {editMode ? (
                            <TextField
                                className={classes.textField}
                                id="digitize-edit-layer-name"
                                placeholder="Layer name"
                                size="small"
                                value={editedText}
                                onChange={e => setEditedText(e.target.value)}
                                onKeyDown={e => handleEditMode(e, layer)}
                            />
                        ) : (
                            <div className={classes.layerName} onClick={e => handleSelectLayer(e, layer)}>
                                <SvgIcon className={classes.buttonIcon}>{digitizeIcons[layer.icon]}</SvgIcon>
                                <Typography>{layer.namePretty}</Typography>
                            </div>
                        )}
                        {showEditIcon && !editMode && (
                            <IconButton size="small" onClick={e => handleEdit(e)}>
                                <Edit />
                            </IconButton>
                        )}
                        {showEditIcon && editMode && (
                            <IconButton size="small" onClick={() => handleSave(layer)}>
                                <Save />
                            </IconButton>
                        )}
                        {showEditIcon && editMode && (
                            <IconButton size="small" onClick={() => setEditMode(false)}>
                                <Cancel />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <Grid container justifyContent="flex-end">
                        <IconButton
                            className={classes.layerMoreButton}
                            size="medium"
                            onClick={e => handleClick(e, layer)}
                        >
                            <MoreHoriz />
                        </IconButton>
                        <LayerMenu
                            anchorEl={anchorEl}
                            classes={classes}
                            drawingFeature={drawingFeature}
                            layer={layer}
                            setIsStylerOpen={setIsStylerOpen}
                            setSelectedLayer={setSelectedLayer}
                            handleClickOpenDialog={handleClickOpenDialog}
                            handleClose={handleClose}
                            handleRemoveLayer={handleRemoveLayer}
                        />
                        <DownloadDialog layer={layer} open={open} onClose={handleCloseDialog} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default DigitizeLayer
