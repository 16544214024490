import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    extraSmall: {
        fontSize: 16,
    },
    infoContainer: {
        display: "flex",
    },
    infoIcon: {
        color: theme.customColors.appTextColorLight,
        marginRight: 8,
    },
}))
