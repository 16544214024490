import * as React from "react";
const SvgCastle15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "castle-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 4 L4 4 C3.4477 4 3 3.5523 3 3 L3 0.5 C3 0.2239 3.2239 0 3.5 0 C3.7761 0 4 0.2239 4 0.5 L4 2 L5 2 L5 1 C5 0.44770002 5.4477 0 6 0 C6.5523 0 7 0.4477 7 1 L7 2 L8 2 L8 1 C8 0.44770002 8.4477 0 9 0 C9.5523 0 10 0.4477 10 1 L10 2 L11 2 L11 0.5 C11 0.2239 11.2239 0 11.5 0 C11.7761 0 12 0.2239 12 0.5 L12 3 C12 3.5523 11.5523 4 11 4 z M14 14.5 C14 14.7761 13.7761 15 13.5 15 L1.5 15 C1.2239 15 1 14.7761 1 14.5 C1 14.2239 1.2239 14 1.5 14 L2 14 C2.5523 14 3 13.5523 3 13 C3 13 4 7 4 6 C4 5.4477 4.4477 5 5 5 L10 5 C10.5522995 5 11 5.4477 11 6 C11 7 12 13 12 13 C12 13.5522995 12.4477 14 13 14 L13.5 14 C13.7723 13.9999 13.9946 14.2178 14 14.49 L14 14.5 z M9 10.5 C9 9.6716 8.3284 9 7.5 9 C6.6716003 9 6 9.6716 6 10.5 L6 14 L9 14 L9 10.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 4 L4 4 C3.4477 4 3 3.5523 3 3 L3 0.5 C3 0.2239 3.2239 0 3.5 0 C3.7761 0 4 0.2239 4 0.5 L4 2 L5 2 L5 1 C5 0.44770002 5.4477 0 6 0 C6.5523 0 7 0.4477 7 1 L7 2 L8 2 L8 1 C8 0.44770002 8.4477 0 9 0 C9.5523 0 10 0.4477 10 1 L10 2 L11 2 L11 0.5 C11 0.2239 11.2239 0 11.5 0 C11.7761 0 12 0.2239 12 0.5 L12 3 C12 3.5523 11.5523 4 11 4 z M14 14.5 C14 14.7761 13.7761 15 13.5 15 L1.5 15 C1.2239 15 1 14.7761 1 14.5 C1 14.2239 1.2239 14 1.5 14 L2 14 C2.5523 14 3 13.5523 3 13 C3 13 4 7 4 6 C4 5.4477 4.4477 5 5 5 L10 5 C10.5522995 5 11 5.4477 11 6 C11 7 12 13 12 13 C12 13.5522995 12.4477 14 13 14 L13.5 14 C13.7723 13.9999 13.9946 14.2178 14 14.49 L14 14.5 z M9 10.5 C9 9.6716 8.3284 9 7.5 9 C6.6716003 9 6 9.6716 6 10.5 L6 14 L9 14 L9 10.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCastle15;
