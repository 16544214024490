import * as React from "react";
const SvgCollege11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "college-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 7.3 C2.3 7.1000004 2.5 6.8 2.5 6.5 C2.5 6.1 2.3 5.8 1.9 5.6 L1.9 4.4 L5.5 6 L11 3.5 L5.5 1 L0 3.5 L1.2 4 L1.2 5.6 C0.8 5.8 0.6 6.1 0.6 6.5 C0.6 6.8 0.8 7.1 1.1 7.3 L0.6 9 C0.3 10 1.1 10 1.1 10 L2.1 10 C2.1 10 2.8999999 10 2.6 9 L2 7.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 6.2 C3.5 6.2999997 3.5 6.3999996 3.5 6.5 C3.5 6.9 3.3 7.3 3.1 7.6 C3.3 8 3.5 8.4 3.5 9 L3.5 9.6 C4 9.8 4.7 10 5.5 10 C8 10 9 8.5 9 8.5 L9 5.5 L5.5 7.1 L3.5 6.2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 7.3 C2.3 7.1000004 2.5 6.8 2.5 6.5 C2.5 6.1 2.3 5.8 1.9 5.6 L1.9 4.4 L5.5 6 L11 3.5 L5.5 1 L0 3.5 L1.2 4 L1.2 5.6 C0.8 5.8 0.6 6.1 0.6 6.5 C0.6 6.8 0.8 7.1 1.1 7.3 L0.6 9 C0.3 10 1.1 10 1.1 10 L2.1 10 C2.1 10 2.8999999 10 2.6 9 L2 7.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 6.2 C3.5 6.2999997 3.5 6.3999996 3.5 6.5 C3.5 6.9 3.3 7.3 3.1 7.6 C3.3 8 3.5 8.4 3.5 9 L3.5 9.6 C4 9.8 4.7 10 5.5 10 C8 10 9 8.5 9 8.5 L9 5.5 L5.5 7.1 L3.5 6.2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCollege11;
