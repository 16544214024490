import * as React from "react";
const SvgPolice15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "police-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 L6 2 L11 2 L11.5 1 L5.5 1 z M6 2.5 L6 3.75 C6 3.75 6 6.5 8.5 6.5 C11 6.5 11 3.75 11 3.75 L11 2.5 L6 2.5 z M1.9844 3.9863 C1.4329 3.9949 0.9924 4.4485 1 5 L1 9 C0.9999 9.6398 1.5922 10.1152 2.2168 9.9766 L5 9.3574 L5 14 L10.878901 7.0703 C10.7391 7.0294 10.5947 7 10.4414 7 L6.5 7 L3 7.7539 L3 5 C3.0077 4.4362 2.5481 3.9775 1.9844 3.9863 z M11.748 7.7109 L6.4121 14 L12 14 L12 8.5586 C12 8.2451 11.9061 7.9548 11.748 7.7109 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 L6 2 L11 2 L11.5 1 L5.5 1 z M6 2.5 L6 3.75 C6 3.75 6 6.5 8.5 6.5 C11 6.5 11 3.75 11 3.75 L11 2.5 L6 2.5 z M1.9844 3.9863 C1.4329 3.9949 0.9924 4.4485 1 5 L1 9 C0.9999 9.6398 1.5922 10.1152 2.2168 9.9766 L5 9.3574 L5 14 L10.878901 7.0703 C10.7391 7.0294 10.5947 7 10.4414 7 L6.5 7 L3 7.7539 L3 5 C3.0077 4.4362 2.5481 3.9775 1.9844 3.9863 z M11.748 7.7109 L6.4121 14 L12 14 L12 8.5586 C12 8.2451 11.9061 7.9548 11.748 7.7109 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPolice15;
