import * as React from "react";
const SvgPrison11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "prison-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1 L2 10 L9 10 L9 1 L2 1 z M3 2 L4 2 L4 5 L3 5 L3 2 z M5 2 L6 2 L6 5 L5 5 L5 2 z M7 2 L8 2 L8 4 L7 4 L7 2 z M7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 z M3 6 L4 6 L4 9 L3 9 L3 6 z M5 6 L6 6 L6 9 L5 9 L5 6 z M7 7 L8 7 L8 9 L7 9 L7 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M2 1 L2 10 L9 10 L9 1 L2 1 z M3 2 L4 2 L4 5 L3 5 L3 2 z M5 2 L6 2 L6 5 L5 5 L5 2 z M7 2 L8 2 L8 4 L7 4 L7 2 z M7.5 5 C7.7761 5 8 5.2239 8 5.5 C8 5.7761 7.7761 6 7.5 6 C7.2239 6 7 5.7761 7 5.5 C7 5.2239 7.2239 5 7.5 5 z M3 6 L4 6 L4 9 L3 9 L3 6 z M5 6 L6 6 L6 9 L5 9 L5 6 z M7 7 L8 7 L8 9 L7 9 L7 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPrison11;
