import { FC, MutableRefObject, useEffect, useRef } from "react"
import useAuthentication from "app/hooks/useAuthentication"

type Props = {
    getAccessTokenRef: MutableRefObject<() => string>
}

const TokenLoader: FC<Props> = ({ getAccessTokenRef }) => {
    const { getAccessToken } = useAuthentication()
    const accessTokenRef = useRef("")

    getAccessTokenRef.current = () => {
        getAccessToken().then(token => {
            accessTokenRef.current = token
        })

        return accessTokenRef.current
    }

    useEffect(() => {
        getAccessToken().then(token => (accessTokenRef.current = token))
    }, [])

    return null
}

export default TokenLoader
