import React, { useEffect, useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import TextField from "@mui/material/TextField"
import * as Yup from "yup"
import TitleTextField from "../../common/TitleTextfield/TitleTextField"

const numberValidator = Yup.number()
    .typeError("Value must be a number")
    .required("Required")
    .integer("Value must be an integer")

const NumberProperty = ({
    editable,
    max,
    min,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) => {
    const [formValue, setFormValue] = useState(value)
    const [formError, setFormError] = useState()

    let initted = false
    useEffect(() => {
        processValue(value, true)
        initted = true
    }, [title])

    useEffect(() => {
        //this is for when we reset the value
        if (value !== formValue && !initted) processValue(value, true)
    }, [value])

    const validateNumber = number => {
        return numberValidator
            .when("$min", { then: num => num.min(min, `Value must be bigger or equal to ${min}`), is: true })
            .when("$max", { then: num => num.max(max, `Value must be smaller or equal to ${max}`), is: true })
            .validate(number, { context: { max: max !== undefined, min: min !== undefined } })
    }

    const processValue = (value, initing = false) => {
        const valueToProcess = value === "" ? undefined : value

        setFormValue(value)
        validateNumber(valueToProcess)
            .then(() => {
                setFormError()
                if (!initing) onPropertyChanged(value)
            })
            .catch(err => {
                setFormError(err.errors[0])
            })
    }

    const onChange = e => {
        processValue(e.target.value)
    }
    const removable = onRemove && !isDefaultValue && !isLastRemainingCase

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <div className="textfield-container">
                    <TextField
                        error={!!formError}
                        fullWidth
                        helperText={formError}
                        label={editable ? "Number" : title}
                        value={formValue}
                        variant="standard"
                        onChange={onChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default NumberProperty
