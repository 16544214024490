import * as React from "react";
const SvgMountain15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "mountain-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 2 C7.2 2 7.1 2.2 6.9 2.4 L1.0999999 11.9 C1 12 1 12.2 1 12.3 C1 12.8 1.4 13 1.7 13 L13.3 13 C13.7 13 14 12.8 14 12.3 C14 12.1 14 12.1 13.9 11.900001 L8.2 2.4 C8 2.2 7.8 2 7.5 2 z M7.5 3.5 L10.8 9 L10 9 L8.5 7.5 L7.5 9 L6.5 7.5 L5 9 L4.1 9 L7.5 3.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 2 C7.2 2 7.1 2.2 6.9 2.4 L1.0999999 11.9 C1 12 1 12.2 1 12.3 C1 12.8 1.4 13 1.7 13 L13.3 13 C13.7 13 14 12.8 14 12.3 C14 12.1 14 12.1 13.9 11.900001 L8.2 2.4 C8 2.2 7.8 2 7.5 2 z M7.5 3.5 L10.8 9 L10 9 L8.5 7.5 L7.5 9 L6.5 7.5 L5 9 L4.1 9 L7.5 3.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMountain15;
