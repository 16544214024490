import { makeStyles } from "@mui/styles"

export const useZanderParameterStyles = makeStyles(theme => ({
    chip: {
        background: "white",
        fontSize: 11,
        fontWeight: "bold",
        height: 20,
        marginBottom: -18,
        marginRight: 8,
    },
    contentColumn: {
        flexGrow: 1,
    },
    criteriaUnit: {
        color: theme.palette.primary.main,
        marginRight: 8,
    },
    dividerSpacing: {
        marginTop: 10,
    },
    dragIndicatorColumn: {
        alignItems: "center",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "8px 0px 0px 8px",
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        padding: "0 4px",
    },
    limitsContainer: {
        alignItems: "center",
        display: "flex",
        padding: 16,
    },
    typeColumn: {
        alignItems: "center",
        color: theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
        padding: "0px 16px",
    },
    zanderParameter: {
        borderRadius: 8,
        boxShadow: "0 2px 12px 1px rgba(60, 62, 66, 0.1)",
        display: "flex",
        margin: "4px 0px",
    },
    zanderParameterHeader: {
        alignItems: "center",
        color: "white",
        display: "flex",
        padding: 16,
        paddingBottom: 0,
    },
}))
