import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    link: {
        display: "flex",
    },
    logo: {
        height: 23,
    },
}))
