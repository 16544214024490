import { FC } from "react"
import ExitToApp from "@mui/icons-material/ExitToApp"
import { MenuItem } from "@mui/material"
import useAuthentication from "app/hooks/useAuthentication"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import { MENU_ITEM_HEIGHT } from "../UserMenu"
import { useStyles } from "./styles"

type Props = {
    onSignOut: () => void
}

const SignOut: FC<Props> = ({ onSignOut }) => {
    const classes = useStyles()

    const { logoutRedirect } = useAuthentication()

    const signOut = () => {
        logoutRedirect()
        onSignOut()
    }

    return (
        <MenuItem sx={{ height: MENU_ITEM_HEIGHT }} onClick={signOut}>
            <div className={classes.signOut}>
                <ExitToApp />
                <CustomTypography className={classes.signOutText} color="inherit" variant="body2">
                    Sign out
                </CustomTypography>
            </div>
        </MenuItem>
    )
}

export default SignOut
