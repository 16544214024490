import * as React from "react";
const SvgLaundry15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "laundry-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 1 L6 3 L3 3 C3 3 2 3 2 4 L2 13 C2 14 3 14 3 14 L12 14 C12 14 13 14 13 13 L13 2 C13 1 12 1 12 1 C12 1 8 1 8 1 z M8.5 2 L10.5 2 C10.777 2 11 2.223 11 2.5 C11 2.777 10.777 3 10.5 3 L8.5 3 C8.223 3 8 2.777 8 2.5 C8 2.223 8.223 2 8.5 2 z M7.5 6 C9.1569 6 10.5 7.3431 10.5 9 C10.5 10.6569 9.1569 12 7.5 12 C5.8430996 12 4.5 10.6569 4.5 9 C4.5 7.3430996 5.8431 6 7.5 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 1 L6 3 L3 3 C3 3 2 3 2 4 L2 13 C2 14 3 14 3 14 L12 14 C12 14 13 14 13 13 L13 2 C13 1 12 1 12 1 C12 1 8 1 8 1 z M8.5 2 L10.5 2 C10.777 2 11 2.223 11 2.5 C11 2.777 10.777 3 10.5 3 L8.5 3 C8.223 3 8 2.777 8 2.5 C8 2.223 8.223 2 8.5 2 z M7.5 6 C9.1569 6 10.5 7.3431 10.5 9 C10.5 10.6569 9.1569 12 7.5 12 C5.8430996 12 4.5 10.6569 4.5 9 C4.5 7.3430996 5.8431 6 7.5 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLaundry15;
