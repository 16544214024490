export const geometryTypeToInt = (type: string) => {
    switch (type) {
        case "fill":
        case "fill-extrusion":
            return 0
        case "line":
            return 1
        case "circle":
            return 2
        case "symbol":
            return 3
        default:
            return 0
    }
}
