import { DependencyList, useEffect, useRef } from "react"

function useDidUpdateEffect(fn: Function, dependencies: DependencyList) {
    const didMountRef = useRef(false)

    useEffect(() => {
        if (didMountRef.current) return fn()

        didMountRef.current = true
    }, dependencies)
}

export default useDidUpdateEffect
