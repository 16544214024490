import * as React from "react";
const SvgVolcano11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "volcano-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 1 L4.5 4 L6.5 4 L8 3 L8 2 L6 3 L6 1 L5.5 1 L5 2.5 L3.5 1 L3 1 z M3.5215 5 L1.0235002 9.1602 C0.8007 9.5296 1.0679 9.9999 1.5 10 L9.5 10 C9.9321 9.9999 10.1993 9.5296 9.9766 9.1602 L7.4785 5 L7 5 L7 5.5 C7 5.777 6.777 6 6.5 6 C6.223 6 6 5.777 6 5.5 C6 5.2239 5.7761 5 5.5 5 C5.2239 5 5 5.2239 5 5.5 L5 7.5 C5 7.777 4.777 8 4.5 8 C4.223 8 4 7.777 4 7.5 L4 5 L3.5215 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3 1 L4.5 4 L6.5 4 L8 3 L8 2 L6 3 L6 1 L5.5 1 L5 2.5 L3.5 1 L3 1 z M3.5215 5 L1.0235002 9.1602 C0.8007 9.5296 1.0679 9.9999 1.5 10 L9.5 10 C9.9321 9.9999 10.1993 9.5296 9.9766 9.1602 L7.4785 5 L7 5 L7 5.5 C7 5.777 6.777 6 6.5 6 C6.223 6 6 5.777 6 5.5 C6 5.2239 5.7761 5 5.5 5 C5.2239 5 5 5.2239 5 5.5 L5 7.5 C5 7.777 4.777 8 4.5 8 C4.223 8 4 7.777 4 7.5 L4 5 L3.5215 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgVolcano11;
