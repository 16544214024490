import * as React from "react";
const SvgBank15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bank-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 3 C0.446 3 0 3.446 0 4 L0 11 C0 11.554 0.446 12 1 12 L14 12 C14.554 12 15 11.554 15 11 L15 4 C15 3.446 14.554 3 14 3 L1 3 z M1 4 L2.5 4 C2.7761 4 3 4.2239 3 4.5 C3 4.7761 2.7761 5 2.5 5 C2.2239 5 2 4.7761 2 4.5 L1.5 5 C1.7761 5 2 5.2239 2 5.5 C2 5.7761 1.7761 6 1.5 6 C1.2239 6 1 5.7761 1 5.5 L1 4 z M7.5 4 C8.8807 4 10 5.567 10 7.5 L10 7.5 C10 9.433 8.8807 11 7.5 11 C6.1193 11 5 9.433 5 7.5 C5 5.5670004 6.1193 4 7.5 4 z M12.5 4 L14 4 L14 5.5 C14 5.7761 13.7761 6 13.5 6 C13.2239 6 13 5.7761 13 5.5 C13 5.2239 13.2239 5 13.5 5 L13 4.5 C13 4.7761 12.7761 5 12.5 5 C12.2239 5 12 4.7761 12 4.5 C12 4.2239 12.2239 4 12.5 4 z M7.5 5.5 C7.177 5.5 6.9664 5.6088 6.8184 5.75 L8.1817 5.75 C8.0336 5.6088 7.823 5.5 7.5 5.5 z M6.625 6 C6.5795 6.091 6.5633 6.1711 6.5449 6.25 L8.4551 6.25 C8.4367 6.1711 8.4205 6.091 8.375 6 L6.625 6 z M6.5 6.5 L6.5 6.75 L8.5 6.75 L8.5 6.5 L6.5 6.5 z M6.5 7 L6.5 7.25 L8.5 7.25 L8.5 7 L6.5 7 z M6.5 7.5 L6.5 7.75 L8.5 7.75 L8.5 7.5 L6.5 7.5 z M6.5 8 L6.25 8.25 L8.25 8.25 L8.5 8 L6.5 8 z M6 8.5 C6 8.5 6.0353 8.6024 6.1016 8.75 L8.375 8.75 L8 8.5 L6 8.5 z M1.5 9 C1.7761 9 2 9.2239 2 9.5 C2 9.7761 1.7761 10 1.5 10 L2 10.5 C2 10.2239 2.2239 10 2.5 10 C2.7761 10 3 10.2239 3 10.5 C3 10.7761 2.7761 11 2.5 11 L1 11 L1 9.5 C1 9.2239 1.2239 9 1.5 9 z M6.2383 9 C6.2842 9.0856 6.3144 9.159 6.375 9.25 L8.6426 9.25 C8.7092 9.1121 8.75 9 8.75 9 L6.2383 9 z M13.5 9 C13.7761 9 14 9.2239 14 9.5 L14 11 L12.5 11 C12.2239 11 12 10.7761 12 10.5 C12 10.2239 12.2239 10 12.5 10 C12.7761 10 13 10.2239 13 10.5 L13.5 10 C13.2239 10 13 9.7761 13 9.5 C13 9.2239 13.2239 9 13.5 9 z M6.5664 9.5 C6.645 9.5912 6.7311 9.6763 6.8262 9.75 L8.246099 9.75 C8.3462 9.6727 8.4338 9.5883 8.5 9.5 L6.5664 9.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1 3 C0.446 3 0 3.446 0 4 L0 11 C0 11.554 0.446 12 1 12 L14 12 C14.554 12 15 11.554 15 11 L15 4 C15 3.446 14.554 3 14 3 L1 3 z M1 4 L2.5 4 C2.7761 4 3 4.2239 3 4.5 C3 4.7761 2.7761 5 2.5 5 C2.2239 5 2 4.7761 2 4.5 L1.5 5 C1.7761 5 2 5.2239 2 5.5 C2 5.7761 1.7761 6 1.5 6 C1.2239 6 1 5.7761 1 5.5 L1 4 z M7.5 4 C8.8807 4 10 5.567 10 7.5 L10 7.5 C10 9.433 8.8807 11 7.5 11 C6.1193 11 5 9.433 5 7.5 C5 5.5670004 6.1193 4 7.5 4 z M12.5 4 L14 4 L14 5.5 C14 5.7761 13.7761 6 13.5 6 C13.2239 6 13 5.7761 13 5.5 C13 5.2239 13.2239 5 13.5 5 L13 4.5 C13 4.7761 12.7761 5 12.5 5 C12.2239 5 12 4.7761 12 4.5 C12 4.2239 12.2239 4 12.5 4 z M7.5 5.5 C7.177 5.5 6.9664 5.6088 6.8184 5.75 L8.1817 5.75 C8.0336 5.6088 7.823 5.5 7.5 5.5 z M6.625 6 C6.5795 6.091 6.5633 6.1711 6.5449 6.25 L8.4551 6.25 C8.4367 6.1711 8.4205 6.091 8.375 6 L6.625 6 z M6.5 6.5 L6.5 6.75 L8.5 6.75 L8.5 6.5 L6.5 6.5 z M6.5 7 L6.5 7.25 L8.5 7.25 L8.5 7 L6.5 7 z M6.5 7.5 L6.5 7.75 L8.5 7.75 L8.5 7.5 L6.5 7.5 z M6.5 8 L6.25 8.25 L8.25 8.25 L8.5 8 L6.5 8 z M6 8.5 C6 8.5 6.0353 8.6024 6.1016 8.75 L8.375 8.75 L8 8.5 L6 8.5 z M1.5 9 C1.7761 9 2 9.2239 2 9.5 C2 9.7761 1.7761 10 1.5 10 L2 10.5 C2 10.2239 2.2239 10 2.5 10 C2.7761 10 3 10.2239 3 10.5 C3 10.7761 2.7761 11 2.5 11 L1 11 L1 9.5 C1 9.2239 1.2239 9 1.5 9 z M6.2383 9 C6.2842 9.0856 6.3144 9.159 6.375 9.25 L8.6426 9.25 C8.7092 9.1121 8.75 9 8.75 9 L6.2383 9 z M13.5 9 C13.7761 9 14 9.2239 14 9.5 L14 11 L12.5 11 C12.2239 11 12 10.7761 12 10.5 C12 10.2239 12.2239 10 12.5 10 C12.7761 10 13 10.2239 13 10.5 L13.5 10 C13.2239 10 13 9.7761 13 9.5 C13 9.2239 13.2239 9 13.5 9 z M6.5664 9.5 C6.645 9.5912 6.7311 9.6763 6.8262 9.75 L8.246099 9.75 C8.3462 9.6727 8.4338 9.5883 8.5 9.5 L6.5664 9.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBank15;
