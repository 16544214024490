import * as React from "react";
const SvgIogp1021 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "346.12, 217.1, 196.12, 196.03", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("path", { d: "M348.32 321.27 L348.32 308.96 L348.12 315.12 L348.32 321.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.32 321.27 L348.91 327.4 L348.32 308.96 L348.32 321.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.91 327.4 L348.91 302.83 L348.32 308.96 L348.91 327.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M348.91 327.4 L349.89 333.48 L348.91 302.83 L348.91 327.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.89 333.48 L349.89 296.75 L348.91 302.83 L349.89 333.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M349.89 333.48 L351.26 339.48 L349.89 296.75 L349.89 333.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M351.26 339.48 L351.26 290.75 L349.89 296.75 L351.26 339.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M351.26 339.48 L353.02 345.39 L351.26 290.75 L351.26 339.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M353.02 345.39 L353.02 284.85 L351.26 290.75 L353.02 345.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M353.02 345.39 L355.14 351.17 L353.02 284.85 L353.02 345.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.14 351.17 L355.14 279.07 L353.02 284.85 L355.14 351.17 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M355.14 351.17 L357.64 356.8 L355.14 279.07 L355.14 351.17 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.64 356.8 L357.64 273.44 L355.14 279.07 L357.64 356.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.64 356.8 L360.48 362.26 L357.64 273.44 L357.64 356.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M360.48 362.26 L360.48 267.98 L357.64 273.44 L360.48 362.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M360.48 362.26 L363.68 367.52 L360.48 267.98 L360.48 362.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M363.68 367.52 L363.68 262.71 L360.48 267.98 L363.68 367.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M363.68 367.52 L367.2 372.57 L363.68 262.71 L363.68 367.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M367.2 372.57 L367.2 257.66 L363.68 262.71 L367.2 372.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M367.2 372.57 L371.04 377.39 L367.2 257.66 L367.2 372.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M371.04 377.39 L371.04 252.85 L367.2 257.66 L371.04 377.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M371.04 377.39 L375.18 381.94 L371.04 252.85 L371.04 377.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M375.18 381.94 L375.18 248.29 L371.04 252.85 L375.18 381.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M375.18 381.94 L379.6 386.23 L375.18 248.29 L375.18 381.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.6 386.23 L379.6 244 L375.18 248.29 L379.6 386.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.6 386.23 L384.29 390.22 L379.6 244 L379.6 386.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.29 390.22 L384.29 240.01 L379.6 244 L384.29 390.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.29 390.22 L389.23 393.9 L384.29 240.01 L384.29 390.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.23 393.9 L389.23 236.33 L384.29 240.01 L389.23 393.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M389.23 393.9 L394.39 397.26 L389.23 236.33 L389.23 393.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.39 397.26 L394.39 232.97 L389.23 236.33 L394.39 397.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M394.39 397.26 L399.75 400.28 L394.39 232.97 L394.39 397.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M399.75 400.28 L399.75 229.95 L394.39 232.97 L399.75 400.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M399.75 400.28 L405.3 402.96 L399.75 229.95 L399.75 400.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M405.3 402.96 L405.3 227.28 L399.75 229.95 L405.3 402.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M405.3 402.96 L411.01 405.27 L405.3 227.28 L405.3 402.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.01 405.27 L411.01 224.97 L405.3 227.28 L411.01 405.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.01 405.27 L416.85 407.21 L411.01 224.97 L411.01 405.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M416.85 407.21 L416.85 223.03 L411.01 224.97 L416.85 407.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M416.85 407.21 L422.81 408.77 L416.85 223.03 L416.85 407.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M422.81 408.77 L422.81 221.46 L416.85 223.03 L422.81 408.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M422.81 408.77 L428.85 409.95 L422.81 221.46 L422.81 408.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M428.85 409.95 L428.85 220.29 L422.81 221.46 L428.85 409.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M428.85 409.95 L434.96 410.73 L428.85 220.29 L428.85 409.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.96 410.73 L434.96 219.5 L428.85 220.29 L434.96 410.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M434.96 410.73 L441.1 411.13 L434.96 219.5 L434.96 410.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.1 411.13 L441.1 219.1 L434.96 219.5 L441.1 411.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.1 411.13 L447.26 411.13 L441.1 219.1 L441.1 411.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.26 411.13 L447.26 219.1 L441.1 219.1 L447.26 411.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.26 411.13 L453.41 410.73 L447.26 219.1 L447.26 411.13 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.41 410.73 L453.41 219.5 L447.26 219.1 L453.41 410.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.41 410.73 L459.51 409.95 L453.41 219.5 L453.41 410.73 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M459.51 409.95 L459.51 220.29 L453.41 219.5 L459.51 409.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M459.51 409.95 L465.56 408.77 L459.51 220.29 L459.51 409.95 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.56 408.77 L465.56 221.46 L459.51 220.29 L465.56 408.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.56 408.77 L471.52 407.21 L465.56 221.46 L465.56 408.77 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M471.52 407.21 L471.52 223.03 L465.56 221.46 L471.52 407.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M471.52 407.21 L477.36 405.27 L471.52 223.03 L471.52 407.21 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M477.36 405.27 L477.36 224.97 L471.52 223.03 L477.36 405.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M477.36 405.27 L483.07 402.96 L477.36 224.97 L477.36 405.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.07 402.96 L483.07 227.28 L477.36 224.97 L483.07 402.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.07 402.96 L488.62 400.28 L483.07 227.28 L483.07 402.96 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.62 400.28 L488.62 229.95 L483.07 227.28 L488.62 400.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.62 400.28 L493.98 397.26 L488.62 229.95 L488.62 400.28 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M493.98 397.26 L493.98 232.97 L488.62 229.95 L493.98 397.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M493.98 397.26 L499.14 393.9 L493.98 232.97 L493.98 397.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M499.14 393.9 L499.14 236.33 L493.98 232.97 L499.14 393.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M499.14 393.9 L504.08 390.22 L499.14 236.33 L499.14 393.9 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.08 390.22 L504.08 240.01 L499.14 236.33 L504.08 390.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M504.08 390.22 L508.77 386.23 L504.08 240.01 L504.08 390.22 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M508.77 386.23 L508.77 244 L504.08 240.01 L508.77 386.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M508.77 386.23 L513.19 381.94 L508.77 244 L508.77 386.23 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M513.19 381.94 L513.19 248.29 L508.77 244 L513.19 381.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M513.19 381.94 L517.33 377.39 L513.19 248.29 L513.19 381.94 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.33 377.39 L517.33 252.85 L513.19 248.29 L517.33 377.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.33 377.39 L521.17 372.57 L517.33 252.85 L517.33 377.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M521.17 372.57 L521.17 257.66 L517.33 252.85 L521.17 372.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M521.17 372.57 L524.69 367.52 L521.17 257.66 L521.17 372.57 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M524.69 367.52 L524.69 262.71 L521.17 257.66 L524.69 367.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M524.69 367.52 L527.88 362.26 L524.69 262.71 L524.69 367.52 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M527.88 362.26 L527.88 267.98 L524.69 262.71 L527.88 362.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M527.88 362.26 L530.73 356.8 L527.88 267.98 L527.88 362.26 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M530.73 356.8 L530.73 273.44 L527.88 267.98 L530.73 356.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M530.73 356.8 L533.22 351.17 L530.73 273.44 L530.73 356.8 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M533.22 351.17 L533.22 279.07 L530.73 273.44 L533.22 351.17 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M533.22 351.17 L535.35 345.39 L533.22 279.07 L533.22 351.17 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M535.35 345.39 L535.35 284.85 L533.22 279.07 L535.35 345.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M535.35 345.39 L537.1 339.48 L535.35 284.85 L535.35 345.39 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M537.1 339.48 L537.1 290.75 L535.35 284.85 L537.1 339.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M537.1 339.48 L538.47 333.48 L537.1 290.75 L537.1 339.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M538.47 333.48 L538.47 296.75 L537.1 290.75 L538.47 333.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M538.47 333.48 L539.46 327.4 L538.47 296.75 L538.47 333.48 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M539.46 327.4 L539.46 302.83 L538.47 296.75 L539.46 327.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M539.46 327.4 L540.05 321.27 L539.46 302.83 L539.46 327.4 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M540.05 321.27 L540.05 308.96 L539.46 302.83 L540.05 321.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("path", { d: "M540.05 321.27 L540.24 315.12 L540.05 308.96 L540.05 321.27 z", fillRule: "evenodd", style: {
  fill: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "348.12,315.12 348.32,308.96 348.91,302.83 349.89,296.75 351.26,290.75 353.02,284.85 355.14,279.07 357.64,273.44 360.48,267.98 363.68,262.71 367.2,257.66 371.04,252.85 375.18,248.29 379.6,244 384.29,240.01 389.23,236.33 394.39,232.97 399.75,229.95 405.3,227.28 411.01,224.97 416.85,223.03 422.81,221.46 428.85,220.29 434.96,219.5 441.1,219.1 447.26,219.1 453.41,219.5 459.51,220.29 465.56,221.46 471.52,223.03 477.36,224.97 483.07,227.28 488.62,229.95 493.98,232.97 499.14,236.33 504.08,240.01 508.77,244 513.19,248.29 517.33,252.85 521.17,257.66 524.69,262.71 527.88,267.98 530.73,273.44 533.22,279.07 535.35,284.85 537.1,290.75 538.47,296.75 539.46,302.83 540.05,308.96 540.24,315.12 540.05,321.27 539.46,327.4 538.47,333.48 537.1,339.48 535.35,345.39 533.22,351.17 530.73,356.8 527.88,362.26 524.69,367.52 521.17,372.57 517.33,377.39 513.19,381.94 508.77,386.23 504.08,390.22 499.14,393.9 493.98,397.26 488.62,400.28 483.07,402.96 477.36,405.27 471.52,407.21 465.56,408.77 459.51,409.95 453.41,410.73 447.26,411.13 441.1,411.13 434.96,410.73 428.85,409.95 422.81,408.77 416.85,407.21 411.01,405.27 405.3,402.96 399.75,400.28 394.39,397.26 389.23,393.9 384.29,390.22 379.6,386.23 375.18,381.94 371.04,377.39 367.2,372.57 363.68,367.52 360.48,362.26 357.64,356.8 355.14,351.17 353.02,345.39 351.26,339.48 349.89,333.48 348.91,327.4 348.32,321.27 348.12,315.12", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1021;
