import fileFormats from "../../constants/files/fileFormats"
import { fileTypes } from "../../constants/files/fileTypes"

export const fileTypeToDownloadEnum = fileType => {
    switch (fileType) {
        case fileTypes.GEO_JSON:
            return fileFormats.GEO_JSON
        case fileTypes.GEO_PACKAGE:
            return fileFormats.GEO_PACKAGE
        case fileTypes.CSV:
            return fileFormats.CSV
        case fileTypes.SHAPEFILE:
            return fileFormats.SHAPEFILE
        default:
            return ""
    }
}
