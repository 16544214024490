import makeStyles from "@mui/styles/makeStyles"
import appStyles from "utils/styles"

export const useToolsMenuStyles = makeStyles({
    closeButton: {
        "&:hover": {
            backgroundColor: appStyles.color.TOOL_HOVER,
            borderRadius: 0,
        },
        backgroundColor: appStyles.color.CLOSE_BACKGROUND,
        borderRadius: 0,
        color: "white",
        height: 49, // not 48 because the header also has a divider of 1px
        minWidth: "auto",
        padding: 0,
        width: "100%",
    },
    closedMenu: {
        fontSize: "1.5em",
    },
    content: {
        backgroundColor: appStyles.color.PRIMARY_BG,
        height: "100%",
    },
    headerItem: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        width: "100%",
    },
    measureIcon: {
        fill: "currentColor",
        paddingLeft: 3,
    },
    root: {
        backgroundColor: "#024F79",
        flex: "1 1 100%",
    },
    toolIcon: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: 60,
    },
})
