import { RefObject, useCallback, useMemo } from "react"

type Args = { minHeight: number; ref: RefObject<HTMLElement> }

const useResizableMouseEvent = ({ minHeight, ref }: Args) => {
    const onMouseDown = useCallback(() => {
        document.addEventListener("mouseup", onMouseUp, true)
        document.addEventListener("mousemove", onMouseMove, true)
    }, [])

    const onMouseUp = useCallback(() => {
        document.removeEventListener("mouseup", onMouseUp, true)
        document.removeEventListener("mousemove", onMouseMove, true)
    }, [])

    const offsetHeight = useMemo(() => document.body.offsetHeight, [])

    const onMouseMove = useCallback((e: MouseEvent) => {
        e.stopPropagation()

        if (!!ref.current) {
            let newHeight = offsetHeight - e.clientY

            if (newHeight <= minHeight) newHeight = minHeight

            ref.current.style.height = `${newHeight}px`
        }
    }, [])

    return onMouseDown
}
export default useResizableMouseEvent
