import * as React from "react";
const SvgFireStation11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fire-station-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L3.5 4 L2 2 C1.595 2.7121 0 4.1667 0 6 C0 8.7001 2.7999 11 5.5 11 C8.2001 11 11 8.7001 11 6 C11 4.1667 9.405 2.7121 9 2 L7.5 4 L5.5 0 z M5.5 5.5 C5.5 5.5 7.5 7.085 7.5 8.5 C7.5 9.1111 6.7222 9.7778 5.5 9.7778 C4.2778 9.7778 3.5 9.111099 3.5 8.5 C3.5 7.1336 5.5 5.5 5.5 5.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L3.5 4 L2 2 C1.595 2.7121 0 4.1667 0 6 C0 8.7001 2.7999 11 5.5 11 C8.2001 11 11 8.7001 11 6 C11 4.1667 9.405 2.7121 9 2 L7.5 4 L5.5 0 z M5.5 5.5 C5.5 5.5 7.5 7.085 7.5 8.5 C7.5 9.1111 6.7222 9.7778 5.5 9.7778 C4.2778 9.7778 3.5 9.111099 3.5 8.5 C3.5 7.1336 5.5 5.5 5.5 5.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFireStation11;
