import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    errorContainer: {
        alignItems: "center",
        color: theme.customColors.errorColor,
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        justifyContent: "center",
    },
    header: {
        borderBottom: `1px solid ${theme.customColors.borderColor}`,
        display: "flex",
        flex: "0 1 auto",
    },
    root: {
        display: "flex",
        flexFlow: "column",
        height: "100%",
        width: "100%",
    },
}))
