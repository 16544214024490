import * as React from "react";
const SvgStadium11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "stadium-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0 L5 3 L5 3.0117 C2.7922 3.1089 1.0876 3.8182 1 4.6816 C1 4.6816 1 7.141 1 8.3632 C0.9995 9.2672 3.0143 10 5.5 10 C7.9856997 10 10.0005 9.2672 10 8.3633 L10 4.6816 C9.9122 3.8177 8.2093 3.1082 6 3.0117 L6 2.5723 L8.5 1.5 L5 0 z M1.8184 5.752 C2.1366 5.9302 2.5373 6.0838 3 6.2051 L3 8.664101 C2.2493 8.4283 1.8213 8.0972 1.8184 7.75 L1.8184 5.752 z M9.1816 5.7559 L9.1816 7.75 C9.1803 8.0979 8.7521 8.4298 8 8.666 L8 6.2031 C8.4614 6.0836 8.862 5.932 9.1816 5.7559 z M4 6.3984 C4.4815 6.4652 4.9888 6.4995 5.5 6.5 C6.0112 6.4995 6.5185 6.4652 7 6.3984 L7 8.8906 C6.5282 8.9624 6.0171 8.9997 5.5 9 C4.9829 8.9997 4.4718 8.9624 4 8.8906 L4 6.3984 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 0 L5 3 L5 3.0117 C2.7922 3.1089 1.0876 3.8182 1 4.6816 C1 4.6816 1 7.141 1 8.3632 C0.9995 9.2672 3.0143 10 5.5 10 C7.9856997 10 10.0005 9.2672 10 8.3633 L10 4.6816 C9.9122 3.8177 8.2093 3.1082 6 3.0117 L6 2.5723 L8.5 1.5 L5 0 z M1.8184 5.752 C2.1366 5.9302 2.5373 6.0838 3 6.2051 L3 8.664101 C2.2493 8.4283 1.8213 8.0972 1.8184 7.75 L1.8184 5.752 z M9.1816 5.7559 L9.1816 7.75 C9.1803 8.0979 8.7521 8.4298 8 8.666 L8 6.2031 C8.4614 6.0836 8.862 5.932 9.1816 5.7559 z M4 6.3984 C4.4815 6.4652 4.9888 6.4995 5.5 6.5 C6.0112 6.4995 6.5185 6.4652 7 6.3984 L7 8.8906 C6.5282 8.9624 6.0171 8.9997 5.5 9 C4.9829 8.9997 4.4718 8.9624 4 8.8906 L4 6.3984 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgStadium11;
