import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    signOut: {
        alignItems: "center",
        color: theme.customColors.primaryColor,
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    signOutText: {
        marginLeft: 8,
    },
}))
