import * as React from "react";
const SvgAlcoholShop11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "alcohol-shop-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 4 L7 6.5 C7.0018 7.1341 7.4021 7.6986 8 7.91 L8 10 L7.5 10 C7.2239 10 7 10.2239 7 10.5 C7 10.7761 7.2239 11 7.5 11 L9.5 11 C9.7761 11 10 10.7761 10 10.5 C10 10.2239 9.7761 10 9.5 10 L9 10 L9 7.91 C9.5979 7.6986 9.9982 7.1341 10 6.5 L10 4 L7 4 z M9.5 6.5 C9.5 7.0523 9.0523 7.5 8.5 7.5 C7.9476995 7.5 7.5 7.0523 7.5 6.5 L7.5 4.5 L9.5 4.5 L9.5 6.5 z M4.21 2.85 L4.21 2.5 C4.4061003 2.5 4.565 2.3411 4.565 2.145 C4.565 1.9489 4.4061 1.79 4.21 1.79 L4.21 1.44 C4.2101 1.2467 4.0534 1.0899 3.8601 1.0899 C3.8567 1.0899 3.8533 1.0899 3.8500001 1.09 L3.14 1.09 C2.9468 1.0845 2.7857 1.2366 2.7801 1.4299 C2.78 1.4332 2.78 1.4366 2.78 1.44 L2.78 1.7900001 C2.5839 1.7900001 2.425 1.9489001 2.425 2.145 C2.425 2.3410997 2.5839 2.5 2.78 2.5 L2.78 2.85 C2.79 3.87 1 5 1 6 L1 10.25 C0.9944 10.6366 1.3033 10.9544 1.6898999 10.96 C1.6966 10.9601 1.7032999 10.9601 1.7099999 10.96 L5.29 10.96 C5.6528 10.9271 5.9460998 10.6503 6 10.29 L6 6 C6 5.09 4.21 3.81 4.21 2.85 z M3.5 9 C2.6716 9 2 8.3284 2 7.5 C2 6.6716003 2.6716 6 3.5 6 C4.3283997 6 5 6.6716 5 7.5 C5 8.3284 4.3284 9 3.5 9 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 4 L7 6.5 C7.0018 7.1341 7.4021 7.6986 8 7.91 L8 10 L7.5 10 C7.2239 10 7 10.2239 7 10.5 C7 10.7761 7.2239 11 7.5 11 L9.5 11 C9.7761 11 10 10.7761 10 10.5 C10 10.2239 9.7761 10 9.5 10 L9 10 L9 7.91 C9.5979 7.6986 9.9982 7.1341 10 6.5 L10 4 L7 4 z M9.5 6.5 C9.5 7.0523 9.0523 7.5 8.5 7.5 C7.9476995 7.5 7.5 7.0523 7.5 6.5 L7.5 4.5 L9.5 4.5 L9.5 6.5 z M4.21 2.85 L4.21 2.5 C4.4061003 2.5 4.565 2.3411 4.565 2.145 C4.565 1.9489 4.4061 1.79 4.21 1.79 L4.21 1.44 C4.2101 1.2467 4.0534 1.0899 3.8601 1.0899 C3.8567 1.0899 3.8533 1.0899 3.8500001 1.09 L3.14 1.09 C2.9468 1.0845 2.7857 1.2366 2.7801 1.4299 C2.78 1.4332 2.78 1.4366 2.78 1.44 L2.78 1.7900001 C2.5839 1.7900001 2.425 1.9489001 2.425 2.145 C2.425 2.3410997 2.5839 2.5 2.78 2.5 L2.78 2.85 C2.79 3.87 1 5 1 6 L1 10.25 C0.9944 10.6366 1.3033 10.9544 1.6898999 10.96 C1.6966 10.9601 1.7032999 10.9601 1.7099999 10.96 L5.29 10.96 C5.6528 10.9271 5.9460998 10.6503 6 10.29 L6 6 C6 5.09 4.21 3.81 4.21 2.85 z M3.5 9 C2.6716 9 2 8.3284 2 7.5 C2 6.6716003 2.6716 6 3.5 6 C4.3283997 6 5 6.6716 5 7.5 C5 8.3284 4.3284 9 3.5 9 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAlcoholShop11;
