import * as React from "react";
const SvgGolf15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "golf-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.3999 1.1 C3.3999 1.2 3.3999 1.3000001 3.3999 1.3000001 C3.3999 1.7 3.6999 2 4.0999 2 C4.3999 2 4.5999 1.8 4.6998997 1.5 L4.6998997 1.5 L4.9 1 L10.5 3.3 L6.6 6 C6.2 6.3 6.2 6.7 6.3 7.1 L7.2000003 9.2 L5.9000006 13.1 C5.7 13.6 6.1 14 6.5 14 C6.8 14 7 13.9 7.1 13.5 L8.5 9.5 L8.6 9.8 L8.6 13.3 C8.6 13.3 8.6 14 9.3 14 C10 14 10 13.3 10 13.3 L10 10 C10 9.8 10 9.7 9.9 9.5 L8.5 6.1 L11.2 4.2 C11.4 3.9999998 11.599999 3.8999999 11.599999 3.6 C11.599999 3.3 11.4 3.1 11.2 3 L4 0.1 C3.9122 0.1 3.882 0.1179 3.7999 0.2 L3.3999 1.1 z M5.5 3 C4.7 3 4 3.7 4 4.5 C4 5.3 4.7 6 5.5 6 C6.3 6 7 5.3 7 4.5 C7 3.6999998 6.2999 3 5.5 3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.3999 1.1 C3.3999 1.2 3.3999 1.3000001 3.3999 1.3000001 C3.3999 1.7 3.6999 2 4.0999 2 C4.3999 2 4.5999 1.8 4.6998997 1.5 L4.6998997 1.5 L4.9 1 L10.5 3.3 L6.6 6 C6.2 6.3 6.2 6.7 6.3 7.1 L7.2000003 9.2 L5.9000006 13.1 C5.7 13.6 6.1 14 6.5 14 C6.8 14 7 13.9 7.1 13.5 L8.5 9.5 L8.6 9.8 L8.6 13.3 C8.6 13.3 8.6 14 9.3 14 C10 14 10 13.3 10 13.3 L10 10 C10 9.8 10 9.7 9.9 9.5 L8.5 6.1 L11.2 4.2 C11.4 3.9999998 11.599999 3.8999999 11.599999 3.6 C11.599999 3.3 11.4 3.1 11.2 3 L4 0.1 C3.9122 0.1 3.882 0.1179 3.7999 0.2 L3.3999 1.1 z M5.5 3 C4.7 3 4 3.7 4 4.5 C4 5.3 4.7 6 5.5 6 C6.3 6 7 5.3 7 4.5 C7 3.6999998 6.2999 3 5.5 3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGolf15;
