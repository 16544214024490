import baseAtlasApi from "../../store/baseAtlasApi"
import { DatasetProperties } from "./models/DatasetProperties"

type GetDatasetPropertiesQuery = {
    datasetId: string
    orderBy?: string
    orderByDescending?: boolean
    page: number
    rowsPerPage?: number
    searchText?: string
}

export type GetDatasetPropertiesResult = { properties?: DatasetProperties[] }

type GetDatasetRowCountQuery = {
    datasetId: string
    searchText?: string
}

const DATASET_JSON_EXPIRATION_IN_SECONDS = 10

const datasetsApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        fetchDatasetProperties: build.query<GetDatasetPropertiesResult, GetDatasetPropertiesQuery>({
            keepUnusedDataFor: DATASET_JSON_EXPIRATION_IN_SECONDS,
            query: ({ datasetId, orderBy, orderByDescending, page, rowsPerPage, searchText }) => {
                const params = {
                    ...(!!searchText && { searchText }),
                    ...(!!rowsPerPage && { rowsPerPage }),
                    ...(!!orderBy && { orderBy }),
                    ...(!!orderByDescending && { orderByDescending }),
                }

                return {
                    method: "GET",
                    params,
                    url: `dataset/${datasetId}/json/${page}`,
                }
            },
        }),
        fetchDatasetRowCount: build.query<number, GetDatasetRowCountQuery>({
            query: ({ datasetId, searchText }) => ({
                method: "GET",
                params: { ...(!!searchText && { searchText }) },
                url: `dataset/${datasetId}/json/count`,
            }),
        }),
    }),
})

export const {
    useFetchDatasetPropertiesQuery,
    useFetchDatasetRowCountQuery,
    usePrefetch: useDatasetsPrefetch,
} = datasetsApi
