export enum Operators {
    Between = "between",
    Contains = "contains",
    Equal = "==",
    GreaterThan = ">",
    GreaterThanOrEqualTo = ">=",
    LessThan = "<",
    LessThanOrEqualTo = "<=",
    NotEqual = "!=",
    Undefined = "",
}

export const operatorNameMapping: Record<Operators, string> = {
    [Operators.Between]: "Between",
    [Operators.Contains]: "Contains",
    [Operators.Equal]: "Equal",
    [Operators.GreaterThan]: "Greater than",
    [Operators.GreaterThanOrEqualTo]: "Greater than or equal to",
    [Operators.LessThan]: "Less than",
    [Operators.LessThanOrEqualTo]: "Less than or equal to",
    [Operators.NotEqual]: "Not equal",
    [Operators.Undefined]: "",
}

export type StringOperators = Operators.Equal | Operators.NotEqual | Operators.Undefined | Operators.Contains
export type NumericOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined
export type DateOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined
export type UndefinedOperators = Operators.Undefined
