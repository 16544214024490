import * as React from "react";
const SvgBicycle15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bicycle-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 2 C6.8239 1.99 6.8239 3.0096002 7.5 3 L9 3 L9 4.2656 L6.1973 6.5996003 L5.2226 4 L5.5 4 C6.1761 4.01 6.1761 2.9903998 5.5 3 L3.5 3 C2.8239 2.99 2.8239 4.0096 3.5 4 L4.1523 4 L5.043 6.375 C4.5752 6.1424 4.0559 6 3.5 6 C1.5729 6 0 7.5729 0 9.5 C0 11.4271 1.5729 13 3.5 13 C5.4271 13 7 11.4271 7 9.5 C7 8.8301 6.7997 8.2089 6.4707003 7.6758 L9.291 5.3262 L9.753901 6.4864 C8.7114 7.0937 8 8.2112 8 9.5 C8 11.4271 9.5729 13 11.5 13 C13.4271 13 15 11.4271 15 9.5 C15 7.5729 13.4271 6 11.5 6 C11.2169 6 10.9456 6.0434 10.6816 6.1074 L10 4.4023 L10 2.5 C10 2.2239 9.7761 2 9.5 2 L7.5 2 z M3.5 7 C4.0923 7 4.6275997 7.2118998 5.0547 7.5527 L3.1797 9.1152 C2.6687999 9.5425005 3.3075 10.3097 3.8202999 9.8847 L5.6953 8.3222 C5.8835 8.674 6 9.0711 6 9.5 C6 10.8866 4.8866 12 3.5 12 C2.1134 12 1 10.8866 1 9.5 C1 8.1134 2.1133 7 3.5 7 L3.5 7 z M11.5 7 C12.8866 7 14 8.1134 14 9.5 C14 10.8866 12.8866 12 11.5 12 C10.1134 12 9 10.8866 9 9.5 C9 8.623 9.4468 7.8579 10.125 7.4121 L11.0352 9.6855 C11.2812 10.3086 12.2156 9.9356 11.9649 9.3144 L11.0567 7.0449 C11.2009 7.0193 11.3481 7 11.5 7 L11.5 7 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 2 C6.8239 1.99 6.8239 3.0096002 7.5 3 L9 3 L9 4.2656 L6.1973 6.5996003 L5.2226 4 L5.5 4 C6.1761 4.01 6.1761 2.9903998 5.5 3 L3.5 3 C2.8239 2.99 2.8239 4.0096 3.5 4 L4.1523 4 L5.043 6.375 C4.5752 6.1424 4.0559 6 3.5 6 C1.5729 6 0 7.5729 0 9.5 C0 11.4271 1.5729 13 3.5 13 C5.4271 13 7 11.4271 7 9.5 C7 8.8301 6.7997 8.2089 6.4707003 7.6758 L9.291 5.3262 L9.753901 6.4864 C8.7114 7.0937 8 8.2112 8 9.5 C8 11.4271 9.5729 13 11.5 13 C13.4271 13 15 11.4271 15 9.5 C15 7.5729 13.4271 6 11.5 6 C11.2169 6 10.9456 6.0434 10.6816 6.1074 L10 4.4023 L10 2.5 C10 2.2239 9.7761 2 9.5 2 L7.5 2 z M3.5 7 C4.0923 7 4.6275997 7.2118998 5.0547 7.5527 L3.1797 9.1152 C2.6687999 9.5425005 3.3075 10.3097 3.8202999 9.8847 L5.6953 8.3222 C5.8835 8.674 6 9.0711 6 9.5 C6 10.8866 4.8866 12 3.5 12 C2.1134 12 1 10.8866 1 9.5 C1 8.1134 2.1133 7 3.5 7 L3.5 7 z M11.5 7 C12.8866 7 14 8.1134 14 9.5 C14 10.8866 12.8866 12 11.5 12 C10.1134 12 9 10.8866 9 9.5 C9 8.623 9.4468 7.8579 10.125 7.4121 L11.0352 9.6855 C11.2812 10.3086 12.2156 9.9356 11.9649 9.3144 L11.0567 7.0449 C11.2009 7.0193 11.3481 7 11.5 7 L11.5 7 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBicycle15;
