import * as React from "react";
const SvgCollege15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "college-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 L0 4.5 L2 5.4 L2 7.1000004 C1.4 7.3 1 7.9 1 8.5 C1 9.1 1.4 9.7 2 9.9 L2 10 L1.1 12.1 C0.8 13 1 14 2.5 14 C4 14 4.2 13 3.9 12.1 L3 10 C3.6 9.7 4 9.2 4 8.5 C4 7.8 3.6 7.3 3 7.1 L3 5.9 L7.5 8 L15 4.5 L7.5 1 z M11.9 7.5 L7.3999996 9.5 L5 8.4 L5 8.5 C5 9.2 4.7 9.8 4.2 10.3 L4.7999997 11.7 L4.7999997 11.8 C4.9 12.2 5 12.6 4.9 13 C5.6 13.3 6.4 13.5 7.4 13.5 C10.7 13.5 11.9 11.5 11.9 10.5 L11.9 7.5 L11.9 7.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 L0 4.5 L2 5.4 L2 7.1000004 C1.4 7.3 1 7.9 1 8.5 C1 9.1 1.4 9.7 2 9.9 L2 10 L1.1 12.1 C0.8 13 1 14 2.5 14 C4 14 4.2 13 3.9 12.1 L3 10 C3.6 9.7 4 9.2 4 8.5 C4 7.8 3.6 7.3 3 7.1 L3 5.9 L7.5 8 L15 4.5 L7.5 1 z M11.9 7.5 L7.3999996 9.5 L5 8.4 L5 8.5 C5 9.2 4.7 9.8 4.2 10.3 L4.7999997 11.7 L4.7999997 11.8 C4.9 12.2 5 12.6 4.9 13 C5.6 13.3 6.4 13.5 7.4 13.5 C10.7 13.5 11.9 11.5 11.9 10.5 L11.9 7.5 L11.9 7.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCollege15;
