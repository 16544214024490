import * as React from "react";
const SvgMuseum11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "museum-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L1 2 L1 3 L10 3 L10 2 L5.5 0 z M2 4 L2 8 L1 9 L1 10 L10 10 L10 9 L9 8 L9 4 L2 4 z M3.49 5 C3.6254 4.9992 3.7553 5.0533 3.85 5.15 L5.5 6.79 L7.15 5.15 C7.3472 4.9567003 7.6637 4.9598002 7.8571 5.157 C7.947 5.2487 7.9982 5.3715 8 5.5 L8 8.5 C8 8.7761 7.7761 9 7.5 9 C7.2239 9 7 8.7761 7 8.5 L7 8.5 L7 6.71 L5.85 7.85 C5.6555 8.0461 5.339 8.0474 5.1429 7.8529 C5.1419 7.8519 5.141 7.851 5.14 7.85 L4 6.71 L4 8.5 C4 8.7761 3.7761 9 3.5 9 C3.2239 9 3 8.7761 3 8.5 L3 8.5 L3 5.5 C2.9999 5.2277 3.2178 5.0054 3.49 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L1 2 L1 3 L10 3 L10 2 L5.5 0 z M2 4 L2 8 L1 9 L1 10 L10 10 L10 9 L9 8 L9 4 L2 4 z M3.49 5 C3.6254 4.9992 3.7553 5.0533 3.85 5.15 L5.5 6.79 L7.15 5.15 C7.3472 4.9567003 7.6637 4.9598002 7.8571 5.157 C7.947 5.2487 7.9982 5.3715 8 5.5 L8 8.5 C8 8.7761 7.7761 9 7.5 9 C7.2239 9 7 8.7761 7 8.5 L7 8.5 L7 6.71 L5.85 7.85 C5.6555 8.0461 5.339 8.0474 5.1429 7.8529 C5.1419 7.8519 5.141 7.851 5.14 7.85 L4 6.71 L4 8.5 C4 8.7761 3.7761 9 3.5 9 C3.2239 9 3 8.7761 3 8.5 L3 8.5 L3 5.5 C2.9999 5.2277 3.2178 5.0054 3.49 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMuseum11;
