import React from "react"
import { Grid, Typography, FormControl, Select, MenuItem } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import PrintFeatures from "./printFeatures"

const useStyles = makeStyles(() => ({
    advancedButton: {
        backgroundColor: "#0C567E",
        color: "#fff",
        cursor: "pointer",
        marginBottom: 8,
        marginTop: 8,
        padding: 8,
        textAlign: "center",
    },
    formatSelector: {
        padding: 8,
    },
    header: {
        borderBottom: "2px solid #f5f7f8",
        padding: 16,
    },
    root: {
        background: "#fff",
    },
    section: {
        borderBottom: "2px solid #ececec",
        padding: 13,
    },
}))

const PrintOptions = ({
    dpi,
    dpiOptions,
    height,
    orientation,
    outputSize,
    unitType,
    width,
    setDpi,
    setHeight,
    setOrientation,
    setOutputSize,
    setUnitType,
    setWidth,
}) => {
    const classes = useStyles()
    // const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

    return (
        <Grid className={classes.root} container>
            <Grid item xs={12}>
                <Grid className={classes.section} container>
                    <Grid item>
                        <FormControl className={classes.formatSelector} size="small" variant="outlined">
                            <Typography variant="body1">DPI</Typography>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        horizontal: "center",
                                        vertical: "bottom",
                                    },
                                    transformOrigin: {
                                        horizontal: "center",
                                        vertical: "top",
                                    },
                                }}
                                value={dpi}
                                onChange={e => setDpi(e.target.value)}
                            >
                                {dpiOptions.map(element => (
                                    <MenuItem key={element} value={element}>
                                        {element}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formatSelector} size="small" variant="outlined">
                            <Typography variant="body1">Size</Typography>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        horizontal: "center",
                                        vertical: "bottom",
                                    },
                                    transformOrigin: {
                                        horizontal: "center",
                                        vertical: "top",
                                    },
                                }}
                                value={outputSize}
                                onChange={e => setOutputSize(e.target.value)}
                            >
                                <MenuItem value="A3">A3</MenuItem>
                                <MenuItem value="A4">A4</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl className={classes.formatSelector} size="small" variant="outlined">
                            <Typography variant="body1">Orientation</Typography>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        horizontal: "center",
                                        vertical: "bottom",
                                    },
                                    transformOrigin: {
                                        horizontal: "center",
                                        vertical: "top",
                                    },
                                }}
                                value={orientation}
                                onChange={e => setOrientation(e.target.value)}
                            >
                                <MenuItem value="landscape">Landscape</MenuItem>
                                <MenuItem value="portrait">Portrait</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            {/* TEMPORARILY REMOVED ADVANCED OPTIONS */}
            {/* <Grid item xs={12} onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                <Grid container justify='center' className={classes.advancedButton}>
                    <Typography variant="caption">Advanced Options</Typography>
                </Grid>
            </Grid> */}
            {/* {showAdvancedOptions && <Grid item xs={12}>
                <Grid container className={classes.section}>
                    <PrintAdvancedOptions
                        dpi={dpi}
                        setDpi={setDpi}
                        width={width}
                        setWidth={setWidth}
                        height={height}
                        setHeight={setHeight}
                        unitType={unitType}
                        setUnitType={setUnitType}
                    />
                </Grid>
            </Grid>} */}
            <Grid item xs={12}>
                <PrintFeatures />
            </Grid>
        </Grid>
    )
}

export default PrintOptions
