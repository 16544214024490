import React from "react"
import { useCanvas } from "../../hooks/useCanvas"

type PieChartCanvasProps = {
    colors: Array<string>
    columns: number
    height: number
    rows: number
    strokeColor: string
    width: number
    hasStroke: boolean
}

const BoxChartCanvas = ({
    colors = [],
    columns = 3,
    height = 200,
    rows = 2,
    strokeColor = "black",
    width = 300,
    hasStroke = false,
}: PieChartCanvasProps) => {
    let rectWidth = Math.floor(width / columns)
    let rectHeight = Math.floor(height / rows)

    const border = 0.5

    const draw = (context: CanvasRenderingContext2D) => {
        if (!canvasRef?.current) {
            return
        }

        context.clearRect(0, 0, width, height)

        let currentX = 0,
            currentY = 0,
            colorIndex = 0

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < columns; col++) {
                let color = colors[colorIndex] ?? "rgba(255,255,255,0)"
                context.beginPath()
                context.rect(currentX, currentY, rectWidth, rectHeight)

                context.fillStyle = color
                context.fill()

                context.strokeStyle = strokeColor
                context.lineWidth = border

                if (hasStroke) context.stroke()

                currentX += rectWidth

                //draw from left to right, increased y when hitting width
                if (currentX + rectWidth > width) {
                    currentX = 0
                    currentY += rectHeight
                }
                colorIndex++
            }
        }
    }

    const onClick = () => {}

    const canvasRef = useCanvas(draw)

    return <canvas height={height} ref={canvasRef} width={width} onClick={onClick} />
}

export default BoxChartCanvas
