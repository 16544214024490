export enum HelperLayersIds {
    GeometricStartLayer = "geometric-section-start-layer",
    SymbolStartLayer = "symbol-section-start-layer",
}

const geometricHelperLayer = {
    id: HelperLayersIds.GeometricStartLayer,
    layout: { visibility: "none" },
    paint: {},
    type: "background",
} as const

const symbolHelperLayer = {
    id: HelperLayersIds.SymbolStartLayer,
    layout: { visibility: "none" },
    paint: {},
    type: "background",
} as const

export const HelperLayers = {
    GeometricHelperLayer: geometricHelperLayer,
    SymbolHelperLayer: symbolHelperLayer,
} as const
