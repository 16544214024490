import * as React from "react";
const SvgSuitcase11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "suitcase-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 3 L8 1.578 L7.3601 1 L3.6399 1 L3 1.7477 L3 3 L1.5 3 L1 3.5 L1 9.5 L1.5 10 L9.5 10 L10 9.5 L10 3.5 L9.5 3 L8 3 z M4 2 L7 2 L7 3 L4 3 L4 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 3 L8 1.578 L7.3601 1 L3.6399 1 L3 1.7477 L3 3 L1.5 3 L1 3.5 L1 9.5 L1.5 10 L9.5 10 L10 9.5 L10 3.5 L9.5 3 L8 3 z M4 2 L7 2 L7 3 L4 3 L4 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSuitcase11;
