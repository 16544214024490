import * as yup from "yup"
import {
    MAX_HS_M,
    MAX_TP_S,
    MAX_WINDSPEED10,
    MAX_WINDSPEED100,
    MIN_HS_M,
    MIN_TP_S,
    MIN_WINDSPEED10,
    MIN_WINDSPEED100,
} from "../constants"
import { ZanderParameterTypeEnum } from "../models/ZanderParameterTypeEnum"
import {
    duplicateLimitErrorMessage,
    integerTypeErrorMessage,
    maxErrorMessage,
    minErrorMessage,
    numberTypeErrorMessage,
} from "./common"

const limitsCommon = yup.number().typeError(numberTypeErrorMessage)

const parametersTypeValidators = {
    [ZanderParameterTypeEnum.WaveHeight]: limitsCommon
        .min(MIN_HS_M, minErrorMessage(MIN_HS_M))
        .max(MAX_HS_M, maxErrorMessage(MAX_HS_M)),
    [ZanderParameterTypeEnum.WavePeriod]: limitsCommon
        .min(MIN_TP_S, minErrorMessage(MIN_TP_S))
        .max(MAX_TP_S, maxErrorMessage(MAX_TP_S))
        .integer(integerTypeErrorMessage),
    [ZanderParameterTypeEnum.WindSpeed10]: limitsCommon
        .min(MIN_WINDSPEED10, minErrorMessage(MIN_WINDSPEED10))
        .max(MAX_WINDSPEED10, maxErrorMessage(MAX_WINDSPEED10))
        .integer(integerTypeErrorMessage),
    [ZanderParameterTypeEnum.WindSpeed100]: limitsCommon
        .min(MIN_WINDSPEED100, minErrorMessage(MIN_WINDSPEED100))
        .max(MAX_WINDSPEED100, maxErrorMessage(MAX_WINDSPEED100))
        .integer(integerTypeErrorMessage),
}

const getLimitsSchema = (parameterType: ZanderParameterTypeEnum) =>
    yup.array<yup.NumberSchema>().of(parametersTypeValidators[parameterType]).unique(duplicateLimitErrorMessage)

export default getLimitsSchema
