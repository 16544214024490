import { makeStyles } from "tss-react/mui"

export const useErrorPlaceholderStyles = makeStyles()(theme => ({
    bottomMargin: {
        marginBottom: 16,
    },
    container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "80%",
        justifyContent: "center",
        width: "100%",
    },
}))
