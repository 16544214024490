export default class PitchToggle {
    constructor({ bearing = 0, minpitchzoom = null, pitch = 70 }, setTerrainToggled) {
        this._bearing = bearing
        this._pitch = pitch
        this._minpitchzoom = minpitchzoom
        this._setTerrainToggled = setTerrainToggled
    }

    onAdd(map) {
        this._map = map
        let _this = this

        this._map.on("pitchend", e => {
            if (e.target.getPitch() !== 0) {
                _this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d"
            } else {
                _this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d"
            }
        })

        this._btn = document.createElement("button")
        this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d"
        this._btn.type = "button"
        this._btn["aria-label"] = "Toggle Pitch"
        this._btn.onclick = function () {
            if (map.getPitch() === 0) {
                let options = { bearing: _this._bearing, pitch: _this._pitch }
                if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
                    options.zoom = _this._minpitchzoom
                }
                map.easeTo(options)
                _this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d"
                _this._setTerrainToggled(true)
            } else {
                map.easeTo({ bearing: 0, pitch: 0 })
                _this._btn.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d"
                _this._setTerrainToggled(false)
            }
        }

        this._container = document.createElement("div")
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group"
        this._container.style.marginTop = "48px"
        this._container.appendChild(this._btn)

        return this._container
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
    }
}
