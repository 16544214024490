import React from "react"
import { Typography } from "@mui/material"

const CustomTypography = (props, ref) => {
    const { className, textWeight, ...otherProps } = props

    let weight
    switch (textWeight) {
        case "regular":
            weight = "400"
            break
        case "semibold":
            weight = "600"
            break
        case "bold":
            weight = "700"
            break
        default:
            weight = "inherit"
    }

    return (
        <Typography className={`${className ? className : ""}`} fontWeight={weight} ref={ref} {...otherProps}>
            {props.children}
        </Typography>
    )
}

export default React.forwardRef(CustomTypography)
