import { Grid } from "@mui/material"
import CoordinatesWidget from "features/mapTools/components/CoordinatesWidget/CoordinatesWidget"
import { useMapToolsStyles } from "./styles/mapToolsStyles"
import ZoomLevelWidget from "./ZoomLevelWidget/ZoomLevelWidget"

const MapTools = () => {
    const classes = useMapToolsStyles()
    return (
        <Grid alignItems="center" className={classes.root} container justifyContent="flex-end" spacing={1}>
            <Grid item>
                <CoordinatesWidget />
            </Grid>
            <Grid item>
                <ZoomLevelWidget />
            </Grid>
        </Grid>
    )
}

export default MapTools
