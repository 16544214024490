import React, { useEffect } from "react"
import SaveIcon from "@mui/icons-material/Save"
import { Select, FormControl, InputLabel, Dialog } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import { PropertyStyleTypes } from "../constants/propertyStyleTypes"
import { useStylerContext } from "../hooks/useStylerContext"
import * as ColorUtils from "../utils/colorUtils"
import { columnTypeIsNumber } from "../utils/helpers/columnTypeIsNumber"
import { isColorProperty } from "../utils/helpers/isColorProperty"
import { isExpressionProperty } from "../utils/helpers/isExpressionProperty"

const CategoriseModal = ({ open, property, onClose, onFinish }) => {
    const {
        columns,
        distinctColumnValues,
        initSharedExpression,
        keysArray,
        selectedDatasetColumn,
        sharedExpressionType,
        setSelectedDatasetColumn,
    } = useStylerContext()

    const sharedExpressionInitted = sharedExpressionType === PropertyStyleTypes.Categorize

    const implicitModalAction = sharedExpressionInitted && !isExpressionProperty(property)

    useEffect(() => {
        if (open && implicitModalAction) {
            onFinished()
        }
    }, [open])

    const validColumnType = {
        bigint: true,
        bigserial: true,
        "character varying": true,
        integer: true,
        serial: true,
        smallint: true,
    }

    const getDefaultValue = colorSeed => {
        switch (property.propertyType) {
            case "color":
                return colorSeed !== undefined
                    ? ColorUtils.getColor(ColorUtils.paletteIdentifier.DEFAULT, colorSeed)
                    : "#FFFFFF"
            case "numberArray":
                return ["literal", [1, 0]]
            case "number":
            case "float":
                return 1
            case "text":
                return "default"
            case "select":
                return property.options[0]
            case "boolean":
                return true
            case "icon":
                return "playground-11"
            default:
                console.error("invalid style type")
                return null
        }
    }

    const onFinished = () => {
        if (!selectedDatasetColumn) return

        const sharedExpressionInitted = sharedExpressionType === PropertyStyleTypes.Categorize

        const willFollowSharedKeys =
            sharedExpressionInitted && isColorProperty(property) && !isExpressionProperty(property)

        const matchArrayKeys = willFollowSharedKeys ? keysArray : distinctColumnValues
        const keyValuePairs = getValueRange(matchArrayKeys)

        const matchArray = ["match", ["get", selectedDatasetColumn.prettyName]]

        keyValuePairs.forEach(keyValue => {
            if (columnTypeIsNumber(selectedDatasetColumn.type.toLowerCase())) {
                matchArray.push(parseFloat(keyValue.key), keyValue.value)
            } else {
                matchArray.push(keyValue.key, keyValue.value)
            }
        })

        matchArray.push(getDefaultValue())

        // if the shared expression wasn't initialized we initialize it
        // likewise, if the current property is an expression, we let the user
        // re-initialize it with other values
        if (isColorProperty(property) && (!sharedExpressionInitted || isExpressionProperty(property))) {
            initSharedExpression(matchArrayKeys, PropertyStyleTypes.Categorize, selectedDatasetColumn.prettyName)
        }
        onClose()
        onFinish(matchArray)
    }

    const onColumnChange = column => {
        setSelectedDatasetColumn(column)
    }

    const getValueRange = keyValues => {
        return keyValues.map((key, i) => ({ key, value: getDefaultValue(i) }))
    }

    const renderColumns = () =>
        columns
            .filter(x => validColumnType[x.type])
            .map((item, index) => {
                return (
                    <MenuItem
                        key={index}
                        className="menu-item-flex"
                        value={item.prettyName}
                        onClick={() => onColumnChange(item)}
                    >
                        <div className="name">{item.prettyName}</div>
                        <span className="type">{item.type}</span>
                    </MenuItem>
                )
            })

    return (
        <Dialog
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className="atlas-dialog graduate-style-modal"
            closeAfterTransition
            open={!implicitModalAction && open}
            onClose={onClose}
        >
            <div className="dialog-header">
                <Typography className="dialog-title" variant="h6">
                    Categorise
                </Typography>

                <Button color="primary" variant="contained" onClick={onFinished}>
                    <SaveIcon style={{ marginLeft: -8, marginRight: 8 }} />
                    Update
                </Button>
            </div>
            <div className="container">
                <div className="section">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="schema-label">Column</InputLabel>
                        <Select
                            className="column-select"
                            fullWidth
                            label="Column"
                            labelId="schema-label"
                            value={selectedDatasetColumn ? selectedDatasetColumn.prettyName : ""}
                        >
                            {renderColumns()}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </Dialog>
    )
}

export default CategoriseModal
