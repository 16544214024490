import * as React from "react";
const SvgDrinkingWater11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "drinking-water-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 3 L5 3 C4.7239 3 4.5 3.2239 4.5 3.5 L4.5 5 L2.5 5 L2.5 2.51 C2.4945 1.6816 3.1616 1.0056 3.99 1 C3.9933 1 3.9967 1 4 1 L10 1 L10 3 z M4.38 8.56 L4.38 8.56 L3.49 7 L2.6 8.55 L2.6 8.55 C2.5286 8.6893 2.4909 8.8435 2.49 9 C2.4903 9.5522995 2.9382 9.9998 3.4905 9.9995 C4.0424 9.9992 4.4897 9.5519 4.49 9 C4.4875 8.8468 4.4499 8.6963 4.38 8.56 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 3 L5 3 C4.7239 3 4.5 3.2239 4.5 3.5 L4.5 5 L2.5 5 L2.5 2.51 C2.4945 1.6816 3.1616 1.0056 3.99 1 C3.9933 1 3.9967 1 4 1 L10 1 L10 3 z M4.38 8.56 L4.38 8.56 L3.49 7 L2.6 8.55 L2.6 8.55 C2.5286 8.6893 2.4909 8.8435 2.49 9 C2.4903 9.5522995 2.9382 9.9998 3.4905 9.9995 C4.0424 9.9992 4.4897 9.5519 4.49 9 C4.4875 8.8468 4.4499 8.6963 4.38 8.56 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDrinkingWater11;
