import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function filterRecurseWithReturnNoSelector<T = any>(
    layers: GenericLayer<T>[],
    func: any,
    parentGroupId?: string,
) {
    let mappedData = []
    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i]
        if (func(layer, i, parentGroupId)) {
            mappedData.push({ ...layer })
        } else {
            if (isGroup(layer)) {
                const filteredLayers: object[] = filterRecurseWithReturnNoSelector(
                    layer.layers!,
                    func,
                    layer.resourceId,
                )

                if (filteredLayers.length > 0) {
                    mappedData.push({ ...layer, layers: filteredLayers })
                }
            }
        }
    }
    return mappedData
}
