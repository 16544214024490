export const CORE_CLIENT_KEY = "LATEST_CORE_CLIENT"
export const CORE_PROJECT_KEY = "LATEST_CORE_PROJECT"

export const saveCoreIds = (clientId: string, projectId: string) => {
    try {
        localStorage.setItem(CORE_CLIENT_KEY, clientId)
        localStorage.setItem(CORE_PROJECT_KEY, projectId)
    } catch (err) {
        console.error(err)
    }
}
