import * as React from "react";
const SvgCastle11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "castle-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.67 0.81 L8.67 2.29 C8.6812 2.6709 8.3815 2.9888 8.0006 3 C8.0004 3 8.0002 3 8 3 L3.09 3 C2.7089999 3.0056 2.3955998 2.7012 2.3899999 2.3201 C2.3899 2.3134 2.3899 2.3067 2.39 2.3 L2.39 0.81 C2.39 0.6195 2.5445 0.465 2.7350001 0.465 C2.9255002 0.465 3.08 0.6195 3.08 0.81 L3.08 1.5 L4 1.5 L4 1 L4 1 C4 0.72389996 4.2239 0.5 4.5 0.5 C4.7761 0.5 5 0.7239 5 1 L5 1 L5 1.5 L6 1.5 L6 1 L6 1 C6 0.72389996 6.2239 0.5 6.5 0.5 C6.7761 0.5 7 0.7239 7 1 L7 1 L7 1.5 L8 1.5 L8 0.81 C7.9679 0.625 8.0919 0.449 8.2769 0.4169 C8.461901 0.38480002 8.6379 0.5088 8.67 0.6938 C8.6767 0.7323 8.6767 0.7716 8.67 0.81 z M10.06 9.63 C10.06 9.8233 9.9033 9.9800005 9.71 9.9800005 L9.71 9.9800005 L1.35 9.9800005 C1.1567 9.9799 1.0001 9.8231 1.0002 9.6298 C1.0004 9.4366 1.1569 9.2801 1.35 9.28 L1.7 9.28 C2.0754 9.2912 2.3888001 8.995999 2.4 8.6206 C2.4004002 8.6071 2.4004002 8.5935 2.4 8.58 C2.4 8.58 3.1000001 5.38 3.1000001 4.6899996 C3.0944 4.3145 3.3943 4.0056 3.7699 4 C3.7766 3.9999 3.7833 3.9999 3.79 4 L7.27 4 C7.6454 3.9888 7.9588 4.284 7.97 4.6594 C7.9704 4.6729 7.9704 4.6865 7.97 4.7 C7.97 5.3999996 8.67 8.59 8.67 8.59 C8.6588 8.9654 8.954 9.2788 9.3294 9.29 C9.3429 9.2904 9.3565 9.2904 9.37 9.29 L9.71 9.29 C9.9033 9.29 10.06 9.4467 10.06 9.64 L10.06 9.64 L10.06 9.63 z M6.5 7.5 C6.5 6.9477 6.0523 6.5 5.5 6.5 C4.9477 6.5 4.5 6.9477 4.5 7.5 L4.5 9.5 L6.5 9.5 L6.5 7.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.67 0.81 L8.67 2.29 C8.6812 2.6709 8.3815 2.9888 8.0006 3 C8.0004 3 8.0002 3 8 3 L3.09 3 C2.7089999 3.0056 2.3955998 2.7012 2.3899999 2.3201 C2.3899 2.3134 2.3899 2.3067 2.39 2.3 L2.39 0.81 C2.39 0.6195 2.5445 0.465 2.7350001 0.465 C2.9255002 0.465 3.08 0.6195 3.08 0.81 L3.08 1.5 L4 1.5 L4 1 L4 1 C4 0.72389996 4.2239 0.5 4.5 0.5 C4.7761 0.5 5 0.7239 5 1 L5 1 L5 1.5 L6 1.5 L6 1 L6 1 C6 0.72389996 6.2239 0.5 6.5 0.5 C6.7761 0.5 7 0.7239 7 1 L7 1 L7 1.5 L8 1.5 L8 0.81 C7.9679 0.625 8.0919 0.449 8.2769 0.4169 C8.461901 0.38480002 8.6379 0.5088 8.67 0.6938 C8.6767 0.7323 8.6767 0.7716 8.67 0.81 z M10.06 9.63 C10.06 9.8233 9.9033 9.9800005 9.71 9.9800005 L9.71 9.9800005 L1.35 9.9800005 C1.1567 9.9799 1.0001 9.8231 1.0002 9.6298 C1.0004 9.4366 1.1569 9.2801 1.35 9.28 L1.7 9.28 C2.0754 9.2912 2.3888001 8.995999 2.4 8.6206 C2.4004002 8.6071 2.4004002 8.5935 2.4 8.58 C2.4 8.58 3.1000001 5.38 3.1000001 4.6899996 C3.0944 4.3145 3.3943 4.0056 3.7699 4 C3.7766 3.9999 3.7833 3.9999 3.79 4 L7.27 4 C7.6454 3.9888 7.9588 4.284 7.97 4.6594 C7.9704 4.6729 7.9704 4.6865 7.97 4.7 C7.97 5.3999996 8.67 8.59 8.67 8.59 C8.6588 8.9654 8.954 9.2788 9.3294 9.29 C9.3429 9.2904 9.3565 9.2904 9.37 9.29 L9.71 9.29 C9.9033 9.29 10.06 9.4467 10.06 9.64 L10.06 9.64 L10.06 9.63 z M6.5 7.5 C6.5 6.9477 6.0523 6.5 5.5 6.5 C4.9477 6.5 4.5 6.9477 4.5 7.5 L4.5 9.5 L6.5 9.5 L6.5 7.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCastle11;
