import * as React from "react";
const SvgBicycle11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bicycle-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.5 1.5 C5.8239 1.49 5.8239 2.5096002 6.5 2.5 L7 2.5 L7 3.211 L4.252 4.7813 L3.7383 3.5 L4.4531 3.5 C5.1292 3.51 5.1292 2.4903998 4.4531 2.5 L2.4258 2.5 C1.7497001 2.49 1.7497001 3.5096002 2.4258 3.5 L2.6602 3.5 L3.0918002 4.5781 C2.9011 4.5311 2.7047 4.5001 2.5 4.5001 C1.1252 4.5001 0 5.6253004 0 7.0001 C0 8.3749 1.1252 9.5001 2.5 9.5001 C3.8748 9.5001 5 8.3748 5 7.0001 C5 6.5288 4.8601 6.0923 4.6309 5.7149 L7.1015997 4.3028 L7.5039 4.711 C6.6216 5.0981 6 5.9792 6 7.0001 C6 8.3749 7.1252003 9.5001 8.5 9.5001 C9.8748 9.5001 11 8.3749 11 7.0001 C11 5.7042003 9.9966 4.6426 8.7305 4.5235004 L8 3.793 L8 2 C8 1.7239 7.7761 1.5 7.5 1.5 L6.5 1.5 z M2.5 5.5 C3.3344 5.5 4 6.1657 4 7 C4 7.8343 3.3344 8.5 2.5 8.5 C1.6656001 8.5 1 7.8344 1 7 C1 6.1656 1.6656 5.5 2.5 5.5 z M8.4551 5.504 L8.4571 5.504 C8.4869995 5.507 8.5171 5.507 8.5469 5.504 C9.3587 5.5289 10 6.1818 10 7.0001 C10 7.8345003 9.3344 8.5001 8.5 8.5001 C7.6656 8.5001 7 7.8345 7 7.0001 C7 6.1811 7.6424 5.5279 8.4551 5.504 L8.4551 5.504 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.5 1.5 C5.8239 1.49 5.8239 2.5096002 6.5 2.5 L7 2.5 L7 3.211 L4.252 4.7813 L3.7383 3.5 L4.4531 3.5 C5.1292 3.51 5.1292 2.4903998 4.4531 2.5 L2.4258 2.5 C1.7497001 2.49 1.7497001 3.5096002 2.4258 3.5 L2.6602 3.5 L3.0918002 4.5781 C2.9011 4.5311 2.7047 4.5001 2.5 4.5001 C1.1252 4.5001 0 5.6253004 0 7.0001 C0 8.3749 1.1252 9.5001 2.5 9.5001 C3.8748 9.5001 5 8.3748 5 7.0001 C5 6.5288 4.8601 6.0923 4.6309 5.7149 L7.1015997 4.3028 L7.5039 4.711 C6.6216 5.0981 6 5.9792 6 7.0001 C6 8.3749 7.1252003 9.5001 8.5 9.5001 C9.8748 9.5001 11 8.3749 11 7.0001 C11 5.7042003 9.9966 4.6426 8.7305 4.5235004 L8 3.793 L8 2 C8 1.7239 7.7761 1.5 7.5 1.5 L6.5 1.5 z M2.5 5.5 C3.3344 5.5 4 6.1657 4 7 C4 7.8343 3.3344 8.5 2.5 8.5 C1.6656001 8.5 1 7.8344 1 7 C1 6.1656 1.6656 5.5 2.5 5.5 z M8.4551 5.504 L8.4571 5.504 C8.4869995 5.507 8.5171 5.507 8.5469 5.504 C9.3587 5.5289 10 6.1818 10 7.0001 C10 7.8345003 9.3344 8.5001 8.5 8.5001 C7.6656 8.5001 7 7.8345 7 7.0001 C7 6.1811 7.6424 5.5279 8.4551 5.504 L8.4551 5.504 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBicycle11;
