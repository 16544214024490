import * as React from "react";
const SvgGolf11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "golf-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.0492 0.638 C3.5323 1.1814 4.2729 1.813 4.7284 1.3132 L5.2237 0.7698 L7.5563 2.1635 L3.3063 4.5 C3.0126 4.6615 2.9648 4.9407 3.0724 5.2212 L3.961 7.5376997 L3.0254998 10.342299 C2.8974 10.7268 3.1975 10.991 3.5001 11 C3.6955 11.0058 3.892 10.907 3.9747999 10.6587 L4.8947 7.8950996 L5.7482 7.6119 L6 8.1172 L6 10.5 C6 10.5 6 11 6.5 11 C7 11 7 10.5 7 10.5 L7 8.1172 C7 8 6.9893 7.8618 6.9358 7.7548 L5.6546 4.415 L8.2074 3.0115 C8.3942 2.9313 8.4975 2.758 8.4975 2.5 C8.4975 2.2741 8.2798 2.087 8.0411005 1.9444001 L4.9047 0.0706 C4.7451997 -0.024699993 4.6298 0.027600002 4.5821 0.077700004 L4.0492 0.638 z M3 2.0002 C2.4477 2.0002 2 2.4479 2 3.0002 C2 3.5525 2.4477 4.0002003 3 4.0002003 C3.5523 4.0002003 4 3.5525002 4 3.0002003 C4 2.4479003 3.5523 2.0002 3 2.0002 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.0492 0.638 C3.5323 1.1814 4.2729 1.813 4.7284 1.3132 L5.2237 0.7698 L7.5563 2.1635 L3.3063 4.5 C3.0126 4.6615 2.9648 4.9407 3.0724 5.2212 L3.961 7.5376997 L3.0254998 10.342299 C2.8974 10.7268 3.1975 10.991 3.5001 11 C3.6955 11.0058 3.892 10.907 3.9747999 10.6587 L4.8947 7.8950996 L5.7482 7.6119 L6 8.1172 L6 10.5 C6 10.5 6 11 6.5 11 C7 11 7 10.5 7 10.5 L7 8.1172 C7 8 6.9893 7.8618 6.9358 7.7548 L5.6546 4.415 L8.2074 3.0115 C8.3942 2.9313 8.4975 2.758 8.4975 2.5 C8.4975 2.2741 8.2798 2.087 8.0411005 1.9444001 L4.9047 0.0706 C4.7451997 -0.024699993 4.6298 0.027600002 4.5821 0.077700004 L4.0492 0.638 z M3 2.0002 C2.4477 2.0002 2 2.4479 2 3.0002 C2 3.5525 2.4477 4.0002003 3 4.0002003 C3.5523 4.0002003 4 3.5525002 4 3.0002003 C4 2.4479003 3.5523 2.0002 3 2.0002 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGolf11;
