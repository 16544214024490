import React from "react"
import { IconButtonProps, SelectProps } from "@mui/material"
import TablePagination from "@mui/material/TablePagination"

type Props = {
    count?: number
    page: number
    rowsPerPage: number
    onPageChange: (page: number) => void
    onRowsPerPageChange: (rowsPerPage: number) => void
}

const AttributeTablePagination = ({ count, page, rowsPerPage, onPageChange, onRowsPerPageChange }: Props) => (
    <TablePagination
        backIconButtonProps={
            {
                "data-testid": backIconButtonTestId,
            } as DataTestIdProps
        }
        component="div"
        count={count ?? -1}
        nextIconButtonProps={
            {
                "data-testid": nextIconButtonTestId,
            } as DataTestIdProps
        }
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        SelectProps={
            {
                "data-testid": rowsPerPageSelectTestId,
            } as DataTestIdSelectProps
        }
        onPageChange={(_, page) => onPageChange(page)}
        onRowsPerPageChange={e => onRowsPerPageChange(parseInt(e.target.value))}
    />
)

export default AttributeTablePagination

const backIconButtonTestId = "qa-attribute-table-pagination-back-icon-button"
const nextIconButtonTestId = "qa-attribute-table-pagination-next-icon-button"
const rowsPerPageSelectTestId = "qa-attribute-table-pagination-rows-per-page-select"

type DataTestIdProps = {
    "data-testid"?: string
} & IconButtonProps

type DataTestIdSelectProps = {
    "data-testid"?: string
} & SelectProps<unknown>
