import { MapboxOverlay } from "@deck.gl/mapbox"
import { DeckProps } from "deck.gl"
import { useControl } from "@emblautec/react-map-gl"
import useAisArchiveLayers from "./hooks/useAisArchiveLayers"

const DeckGLOverlay = () => {
    const layers = useAisArchiveLayers()
    const props: DeckProps = { layers }
    // @ts-ignore TODO: fix this
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props))
    overlay.setProps(props)

    return null
}

export default DeckGLOverlay
