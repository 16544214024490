import * as React from "react";
const SvgPrison15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "prison-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 1 L3.5 14 L12 14 L12 1 L3.5 1 z M9.5 2 L11 2 L11 5.5 L9.5 5.5 L9.5 2 z M4.5 2.0547 L6 2.0547 L6 7 L4.5 7 L4.5 2.0547 z M7 2.0547 L8.5 2.0547 L8.5 7 L7 7 L7 2.0547 z M10.25 6.5 C10.6642 6.5 11 6.8358 11 7.25 C11 7.6642 10.6642 8 10.25 8 L10.25 8 C9.8358 8 9.5 7.6642 9.5 7.25 L9.5 7.25 C9.5 6.8358 9.8358 6.5 10.25 6.5 z M7 8 L8.4727 8 L8.5 13 L7.0273 13 L7 8 z M4.5 8.166 L6 8.166 L6 13 L4.5 13 L4.5 8.166 z M9.5 9 L11 9 L11 13 L9.5 13 L9.5 9 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 1 L3.5 14 L12 14 L12 1 L3.5 1 z M9.5 2 L11 2 L11 5.5 L9.5 5.5 L9.5 2 z M4.5 2.0547 L6 2.0547 L6 7 L4.5 7 L4.5 2.0547 z M7 2.0547 L8.5 2.0547 L8.5 7 L7 7 L7 2.0547 z M10.25 6.5 C10.6642 6.5 11 6.8358 11 7.25 C11 7.6642 10.6642 8 10.25 8 L10.25 8 C9.8358 8 9.5 7.6642 9.5 7.25 L9.5 7.25 C9.5 6.8358 9.8358 6.5 10.25 6.5 z M7 8 L8.4727 8 L8.5 13 L7.0273 13 L7 8 z M4.5 8.166 L6 8.166 L6 13 L4.5 13 L4.5 8.166 z M9.5 9 L11 9 L11 13 L9.5 13 L9.5 9 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPrison15;
