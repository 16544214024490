import * as React from "react";
const SvgDentist11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "dentist-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.48 6 C4.857 6.0823 4.3088 6.4527 4 7 C3.54 8.21 3.86 10 3.18 10 C2.5000002 10 2.7 8.49 2.5 7 C2.2864 6.3547 2.0326 5.7235 1.74 5.11 C1.53 3.7 1 1.28 2.67 1 C4.34 0.72 4.35 2.52 5.5 2.52 C6.65 2.52 6.67 0.72 8.33 1 C9.99 1.28 9.47 3.7 9.26 5.11 C8.9674 5.7235 8.7136 6.3547 8.5 7 C8.3 8.49 8.5 10 7.82 10 C7.1400003 10 7.46 8.21 7 7 C6.6912 6.4527 6.143 6.0823 5.52 6 L5.52 6 L5.48 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.48 6 C4.857 6.0823 4.3088 6.4527 4 7 C3.54 8.21 3.86 10 3.18 10 C2.5000002 10 2.7 8.49 2.5 7 C2.2864 6.3547 2.0326 5.7235 1.74 5.11 C1.53 3.7 1 1.28 2.67 1 C4.34 0.72 4.35 2.52 5.5 2.52 C6.65 2.52 6.67 0.72 8.33 1 C9.99 1.28 9.47 3.7 9.26 5.11 C8.9674 5.7235 8.7136 6.3547 8.5 7 C8.3 8.49 8.5 10 7.82 10 C7.1400003 10 7.46 8.21 7 7 C6.6912 6.4527 6.143 6.0823 5.52 6 L5.52 6 L5.48 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDentist11;
