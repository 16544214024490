import makeStyles from "@mui/styles/makeStyles"

const useLayerStyles = makeStyles(theme => ({
    contextMenuBtn: {
        backgroundColor: "#e3e4e5",
        borderRadius: 3,
        color: "black",
        height: 24,
        marginLeft: "auto",
        width: 24,
    },

    grayIcon: {
        color: "rgba(0, 0, 0, 0.54)",
        marginRight: 8,
    },

    grow: {
        flexGrow: 1,
    },
    switch: {
        marginLeft: -12,
        marginRight: 8,
    },
    cancelBtn: {
        backgroundColor: "#fce2e1",
        borderRadius: 3,
        color: theme.customColors.errorColor,
        height: 24,
        marginLeft: "auto",
        width: 24,
    },
}))

export default useLayerStyles
