import { makeStyles } from "@mui/styles"

export const useLegendEntryStyles = makeStyles(() => ({
    dividerSpacing: {
        margin: 8,
    },
    iconContainerClass: {
        marginLeft: 10,
    },
    labelsContainer: {
        overflow: "none",
        padding: 8,
    },
    labelText: {
        flexGrow: 1,
        fontSize: 10,
        maxWidth: "18vw",
        minWidth: 0,
        textTransform: "capitalize",
    },
    multiLabelEntryContainer: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        paddingLeft: 8,
    },
    multiLabelHeaderText: {
        fontSize: 10,
        marginLeft: 16,
        textTransform: "capitalize",
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 10,
        lineHeight: "15px",
        minWidth: 0,
        textTransform: "capitalize",
    },
    singleLabelEntryContainer: {
        display: "flex",
        marginLeft: 8,
    },
}))
