import { makeStyles } from "@mui/styles"

export const useLimitsCreatorStyles = makeStyles(theme => ({
    chip: {
        background: "white",
        borderColor: theme.customColors.primaryColor,
        fontSize: 12,
        fontWeight: "bold",
        height: 28,
        justifyContent: "space-between",
        marginRight: 8,
        width: 58,
    },
    chipError: {
        borderColor: theme.customColors.errorColor,
        color: theme.customColors.errorColor,
    },
    grow: {
        flexGrow: 1,
    },
    icon: {
        marginRight: 8,
    },
    inputChipTextField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: 16,
            color: theme.customColors.primaryColor,
            height: 28,
            position: "relative",
        },
        height: 28,
        marginBottom: 0 + "!Important",
        marginRight: 8,
        width: 58,
    },
    limitCreator: {
        alignItems: "center",
        display: "flex",
        minHeight: 35,
        width: "100%",
    },
    percentilesInput: {
        borderColor: theme.customColors.primaryColor,
        fontSize: 12,
        fontWeight: "bold",
    },
    percentilesTextField: {
        paddingTop: 6,
        width: 25,
    },
    rightAlign: {
        display: "flex",
    },
}))
