import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, InputAdornment, TextField } from "@mui/material"
import useSearchFieldStyles from "./styles"

type Props = {
    searchText: string
    startSearch: (searchText: string) => void
}
const SearchField: FC<Props> = ({ searchText, startSearch }) => {
    const classes = useSearchFieldStyles()

    const [text, setText] = useState(searchText)

    useEffect(() => {
        setText(searchText)
    }, [searchText])

    const onChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value)
    }

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            startSearch(text)
        }
    }

    return (
        <TextField
            className={classes.searchInput}
            fullWidth
            hiddenLabel
            inputProps={{ autoComplete: "off", "data-testid": searchfieldTestId }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton data-testid={searchButtonTestId} onClick={() => startSearch(text)}>
                            <SearchIcon className={classes.searchIcon} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            placeholder="Search..."
            value={text}
            variant="filled"
            onChange={onChangeSearchText}
            onKeyDown={onKeyDown}
        />
    )
}

export default SearchField

const searchfieldTestId = "qa-attribute-table-search-field"
const searchButtonTestId = "qa-attribute-table-search-button"
