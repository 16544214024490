export const mapboxDrawStyles = [
    //https://bl.ocks.org/dnseminara/0790e53cef9867e848e716937727ab18 <----- HERE you can find the default styles and an example
    //POINT
    {
        //If active we will display them with the "yellow" styles.
        filter: [
            "all",
            ["==", "$type", "Point"],
            ["==", "active", "false"],
            ["has", "user_customStyle"],
            ["==", "user_hidden", false],
        ],
        id: "gl-draw-points",
        paint: {
            //While normally a feature having custom style on true should have all the values for these styles,
            //it's still good to check and have default values.
            "circle-color": ["case", ["has", "user_circle-color"], ["get", "user_circle-color"], "#3bb2d0"],
            "circle-opacity": ["case", ["has", "user_circle-opacity"], ["get", "user_circle-opacity"], 1],
            "circle-radius": ["case", ["has", "user_circle-radius"], ["get", "user_circle-radius"], 1],
            "circle-stroke-color": [
                "case",
                ["has", "user_circle-stroke-color"],
                ["get", "user_circle-stroke-color"],
                "#3bb2d0",
            ],
            "circle-stroke-width": [
                "case",
                ["has", "user_circle-stroke-width"],
                ["get", "user_circle-stroke-width"],
                0,
            ],
        },
        type: "circle",
    },
    //LINE
    {
        filter: [
            "all",
            ["==", "$type", "LineString"],
            ["==", "active", "false"],
            ["has", "user_customStyle"],
            ["==", "user_hidden", false],
        ],
        id: "gl-draw-custom-line-active",
        layout: {
            "line-cap": ["case", ["has", "user_line-cap"], ["get", "user_line-cap"], "butt"],
            "line-join": ["case", ["has", "user_line-join"], ["get", "user_line-join"], "miter"],
        },
        paint: {
            "line-color": [
                "case",
                ["==", "active", "true"],
                "red",
                ["has", "user_line-color"],
                ["get", "user_line-color"],
                "#3bb2d0",
            ],
            "line-dasharray": [
                "case",
                ["has", "user_line-dasharray"],
                ["get", "user_line-dasharray"],
                ["literal", [1, 0]],
            ],
            "line-gap-width": ["case", ["has", "user_line-gap-width"], ["get", "user_line-gap-width"], 0],
            "line-offset": ["case", ["has", "user_line-offset"], ["get", "user_line-offset"], 0],
            "line-opacity": ["case", ["has", "user_line-opacity"], ["get", "user_line-opacity"], 1],
            "line-width": ["case", ["has", "user_line-width"], ["get", "user_line-width"], 1],
        },
        type: "line",
    },
    //CIRCLE/POLYGON
    {
        filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
            ["has", "user_customStyle"],
            ["==", "user_hidden", false],
        ],
        id: "gl-draw-polygon",
        paint: {
            "fill-antialias": true,
            "fill-color": ["case", ["has", "user_fill-color"], ["get", "user_fill-color"], "#3bb2d0"],
            "fill-opacity": ["case", ["has", "user_fill-opacity"], ["get", "user_fill-opacity"], 0.1],
            "fill-outline-color": [
                "case",
                ["has", "user_fill-outline-color"],
                ["get", "user_fill-outline-color"],
                "#3bb2d0",
            ],
        },
        type: "fill",
    },
    //The polygon needs some styles for the lines also
    {
        filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["==", "active", "false"],
            ["has", "user_customStyle"],
            ["==", "user_hidden", false],
        ],
        id: "gl-draw-polygon-lines",
        paint: {
            "line-color": ["case", ["has", "user_fill-outline-color"], ["get", "user_fill-outline-color"], "#3bb2d0"],
        },
        type: "line",
    },

    //These sare the styles for when an item is active(editing). They are the default styles for mapbox draw.
    {
        filter: ["all", ["==", "$type", "Point"], ["!=", "meta", "midpoint"], ["==", "active", "true"]],
        id: "gl-draw-point-active",
        paint: {
            "circle-color": "#fbb03b",
            "circle-radius": 5,
        },
        type: "circle",
    },
    {
        filter: ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
        id: "gl-draw-polygon-and-line-vertex-inactive",
        paint: {
            "circle-color": "#fbb03b",
            "circle-radius": 3,
        },
        type: "circle",
    },
    {
        filter: ["all", ["==", "$type", "LineString"], ["==", "active", "true"]],
        id: "gl-draw-line-active",
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
        type: "line",
    },
    {
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        id: "gl-draw-polygon-stroke-active",
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
        type: "line",
    },
    {
        filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
        id: "gl-draw-polygon-fill-active",
        paint: {
            "fill-color": "#fbb03b",
            "fill-opacity": 0.1,
            "fill-outline-color": "#fbb03b",
        },
        type: "fill",
    },
    {
        filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
        id: "gl-draw-polygon-midpoint",
        paint: {
            "circle-color": "#fbb03b",
            "circle-radius": 3,
        },
        type: "circle",
    },
]
