export const boundsQueryParamName = "bounds"
export const bearingQueryParamName = "bearing"
export const pitchQueryParamName = "pitch"
export const terrainExaggerationParamName = "exaggeration"
export const basemapQueryParamName = "basemap"
export const languageQueryParamName = "language"
export const visibleLayersQueryParamName = "visibleLayers"
export const latQueryParamName = "lat"
export const lngQueryParamName = "lng"

export const clientIdParam = "clientId"
export const projectIdParam = "projectId"
export const appParam = "app"

export const featureIdsParam = "featureIds"
export const includeMetadataParam = "includeMetadata"

export const sidebarStatusQueryParamName = "sidebar"

export const zoomQueryParamName = "zoom"
export const centerQueryParamName = "center"

export const hideLogoQueryParamName = "hideLogo"
