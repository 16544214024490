import makeStyles from "@mui/styles/makeStyles"

const useContextMenuStyles = makeStyles(theme => ({
    customMenuStyle: {
        "& .MuiList-root": {
            backgroundColor: "white",
            borderRadius: 4,
            boxShadow:
                "0px 3px 1px -2px rgba(0, 0, 0 , 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
            margin: 8,
        },
        "& .MuiPaper-root": {
            backgroundColor: "transparent",
            marginLeft: -8,
            marginTop: -32,
            paddingTop: 24,
        },
    },
}))

export default useContextMenuStyles
