import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles<Theme>(theme => ({
    tag: {
        alignItems: "center",
        borderRadius: "15px",
        display: "flex",
        fontSize: "13px",
        height: "30px",
        marginBottom: "4px",
        marginRight: "4px",
        padding: "0 10px",
    },
    tagList: {
        display: "flex",
        flexWrap: "wrap",
    },
}))

export default useStyles
