import * as React from "react";
const SvgIogp1101 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "246.1, 156.84, 307.80002, 307.79", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400,310.74 400,310.74", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "348.78,310.74 348.98,306.27 349.56,301.84 350.53,297.48 351.87,293.22 353.58,289.09 355.65,285.13 358.05,281.36 360.77,277.82 363.78,274.52 367.08,271.5 370.62,268.78 374.39,266.38 378.36,264.32 382.48,262.61 386.74,261.27 391.11,260.3 395.54,259.72 400,259.52 404.46,259.72 408.89,260.3 413.26,261.27 417.52,262.61 421.64,264.32 425.61,266.38 429.38,268.78 432.92,271.5 436.22,274.52 439.23,277.82 441.95,281.36 444.35,285.13 446.42,289.09 448.13,293.22 449.47,297.48 450.44,301.84 451.02,306.27 451.22,310.74 451.02,315.2 450.44,319.63 449.47,323.99 448.13,328.25 446.42,332.38 444.35,336.34 441.95,340.11 439.23,343.66 436.22,346.95 432.92,349.97 429.38,352.69 425.61,355.09 421.64,357.15 417.52,358.86 413.26,360.21 408.89,361.17 404.46,361.76 400,361.95 395.54,361.76 391.11,361.17 386.74,360.21 382.48,358.86 378.36,357.15 374.39,355.09 370.62,352.69 367.08,349.97 363.78,346.95 360.77,343.66 358.05,340.11 355.65,336.34 353.58,332.38 351.87,328.25 350.53,323.99 349.56,319.63 348.98,315.2 348.78,310.74", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.39 312.96 L387.39 308.51 L387.2 310.74 L387.39 312.96 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.39 312.96 L387.97 315.12 L387.39 308.51 L387.39 312.96 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.97 315.12 L387.97 306.36 L387.39 308.51 L387.97 315.12 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M387.97 315.12 L388.91 317.14 L387.97 306.36 L387.97 315.12 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M388.91 317.14 L388.91 304.33 L387.97 306.36 L388.91 317.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M388.91 317.14 L390.19 318.97 L388.91 304.33 L388.91 317.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M390.19 318.97 L390.19 302.51 L388.91 304.33 L390.19 318.97 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M390.19 318.97 L391.77 320.55 L390.19 302.51 L390.19 318.97 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.77 320.55 L391.77 300.93 L390.19 302.51 L391.77 320.55 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.77 320.55 L393.6 321.83 L391.77 300.93 L391.77 320.55 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M393.6 321.83 L393.6 299.65 L391.77 300.93 L393.6 321.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M393.6 321.83 L395.62 322.77 L393.6 299.65 L393.6 321.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.62 322.77 L395.62 298.71 L393.6 299.65 L395.62 322.77 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.62 322.77 L397.78 323.35 L395.62 298.71 L395.62 322.77 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.78 323.35 L397.78 298.13 L395.62 298.71 L397.78 323.35 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.78 323.35 L400 323.54 L397.78 298.13 L397.78 323.35 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M400 323.54 L400 297.93 L397.78 298.13 L400 323.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M400 323.54 L402.22 323.35 L400 297.93 L400 323.54 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.22 323.35 L402.22 298.13 L400 297.93 L402.22 323.35 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.22 323.35 L404.38 322.77 L402.22 298.13 L402.22 323.35 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.38 322.77 L404.38 298.71 L402.22 298.13 L404.38 322.77 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.38 322.77 L406.4 321.83 L404.38 298.71 L404.38 322.77 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M406.4 321.83 L406.4 299.65 L404.38 298.71 L406.4 321.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M406.4 321.83 L408.23 320.55 L406.4 299.65 L406.4 321.83 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.23 320.55 L408.23 300.93 L406.4 299.65 L408.23 320.55 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.23 320.55 L409.81 318.97 L408.23 300.93 L408.23 320.55 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.81 318.97 L409.81 302.51 L408.23 300.93 L409.81 318.97 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.81 318.97 L411.09 317.14 L409.81 302.51 L409.81 318.97 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.09 317.14 L411.09 304.33 L409.81 302.51 L411.09 317.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M411.09 317.14 L412.03 315.12 L411.09 304.33 L411.09 317.14 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.03 315.12 L412.03 306.36 L411.09 304.33 L412.03 315.12 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.03 315.12 L412.61 312.96 L412.03 306.36 L412.03 315.12 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.61 312.96 L412.61 308.51 L412.03 306.36 L412.61 312.96 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.61 312.96 L412.8 310.74 L412.61 308.51 L412.61 312.96 z", fillRule: "evenodd", style: {
  fill: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "387.2,310.74 387.39,308.51 387.97,306.36 388.91,304.33 390.19,302.51 391.77,300.93 393.6,299.65 395.62,298.71 397.78,298.13 400,297.93 402.22,298.13 404.38,298.71 406.4,299.65 408.23,300.93 409.81,302.51 411.09,304.33 412.03,306.36 412.61,308.51 412.8,310.74 412.61,312.96 412.03,315.12 411.09,317.14 409.81,318.97 408.23,320.55 406.4,321.83 404.38,322.77 402.22,323.35 400,323.54 397.78,323.35 395.62,322.77 393.6,321.83 391.77,320.55 390.19,318.97 388.91,317.14 387.97,315.12 387.39,312.96 387.2,310.74", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,464.38 400,361.95", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,259.52 400,157.09", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "246.35,310.74 348.78,310.74", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "451.22,310.74 553.65,310.74", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "fuchsia"
} }));
export default SvgIogp1101;
