import React from "react"
import { Typography, TextField, FormControl, Select, InputLabel, FilledInput, MenuItem } from "@mui/material"

function SelectProperty(props) {
    function handleChange(e) {
        props.onPropertyChanged(e.target.value)
    }

    return (
        <div className="property">
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" value={props.title} onChange={props.onTitleChanged} />
                )}
                {!props.editable && (
                    <Typography className="name" variant="body1">
                        {props.title}
                    </Typography>
                )}
                <div className="text-container">
                    <FormControl className="property-select" fullWidth variant="filled">
                        <InputLabel htmlFor="filled-age-simple">{props.title} type</InputLabel>
                        <Select
                            input={<FilledInput id="filled-age-simple" name="age" />}
                            value={props.value}
                            onChange={handleChange}
                        >
                            {props.options.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default SelectProperty
