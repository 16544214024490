import * as React from "react";
const SvgDoctor15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "doctor-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 7 C4.1193 7 3 5.8807 3 4.5 L3 4.5 L3 2.5 C3 2.2239 3.2239 2 3.5 2 L4 2 C4.2761 2 4.5 1.7761 4.5 1.5 C4.5 1.2239 4.2761 1 4 1 L3.5 1 C2.6716 1 2 1.6716 2 2.5 L2 4.5 C2.0013 5.6466 2.5658 6.7195 3.51 7.37 L3.51 7.37 C4.4131 8.1662 4.9514 9.297 5 10.5 C5 12.433 6.567 14 8.5 14 C10.433001 14 12 12.433 12 10.5 L12 9.93 C13.0695 9.6539 13.7126 8.563001 13.4365 7.4935 C13.1603 6.424 12.0695 5.7809 11 6.057 C9.9305 6.3332 9.2874 7.424 9.5635 8.4935 C9.7454 9.198 10.2955 9.7481 11 9.93 L11 10.5 C11 11.8807 9.8807 13 8.5 13 C7.1193 13 6 11.8807 6 10.5 C6.0511 9.2955 6.5932 8.1644 7.5 7.37 L7.5 7.37 C8.4404 6.7172 9.001 5.6448 9 4.5 L9 2.5 C9 1.6716 8.3284 1 7.5 1 L7 1 C6.7239 1 6.5 1.2239 6.5 1.5 C6.5 1.7761 6.7239 2 7 2 L7.5 2 C7.7761 2 8 2.2239 8 2.5 L8 4.5 L8 4.5 C8 5.8807 6.8807 7 5.5 7 M11.5 9 C10.9477005 9 10.5 8.5523 10.5 8 C10.5 7.4476995 10.9477 7 11.5 7 C12.0523 7 12.5 7.4477 12.5 8 C12.5 8.5522995 12.0523 9 11.5 9 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 7 C4.1193 7 3 5.8807 3 4.5 L3 4.5 L3 2.5 C3 2.2239 3.2239 2 3.5 2 L4 2 C4.2761 2 4.5 1.7761 4.5 1.5 C4.5 1.2239 4.2761 1 4 1 L3.5 1 C2.6716 1 2 1.6716 2 2.5 L2 4.5 C2.0013 5.6466 2.5658 6.7195 3.51 7.37 L3.51 7.37 C4.4131 8.1662 4.9514 9.297 5 10.5 C5 12.433 6.567 14 8.5 14 C10.433001 14 12 12.433 12 10.5 L12 9.93 C13.0695 9.6539 13.7126 8.563001 13.4365 7.4935 C13.1603 6.424 12.0695 5.7809 11 6.057 C9.9305 6.3332 9.2874 7.424 9.5635 8.4935 C9.7454 9.198 10.2955 9.7481 11 9.93 L11 10.5 C11 11.8807 9.8807 13 8.5 13 C7.1193 13 6 11.8807 6 10.5 C6.0511 9.2955 6.5932 8.1644 7.5 7.37 L7.5 7.37 C8.4404 6.7172 9.001 5.6448 9 4.5 L9 2.5 C9 1.6716 8.3284 1 7.5 1 L7 1 C6.7239 1 6.5 1.2239 6.5 1.5 C6.5 1.7761 6.7239 2 7 2 L7.5 2 C7.7761 2 8 2.2239 8 2.5 L8 4.5 L8 4.5 C8 5.8807 6.8807 7 5.5 7 M11.5 9 C10.9477005 9 10.5 8.5523 10.5 8 C10.5 7.4476995 10.9477 7 11.5 7 C12.0523 7 12.5 7.4477 12.5 8 C12.5 8.5522995 12.0523 9 11.5 9 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDoctor15;
