import * as React from "react";
const SvgBakery15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bakery-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.2941 4.3824 L6 9.5 C6 9.5 6 10.5 7 10.5 L8 10.5 C9 10.5 9 9.5 9 9.5 L9.7059 4.3824 C9.7059 3 7.5 3 7.5 3 C7.5 3 5.291 3 5.2941 4.3824 z M3.5 5 C2 5 2 6 2 6 L3 10 L4.5 10 C5.255 10 5.2941 9.2353 5.2941 9.2353 L4.5 5 L3.5 5 z M1.5 7.5 C1.5 7.5 0.8824 7.4706 0.44120002 7.9118 C0 8.3529 0 8.7941 0 8.7941 L0 11 L0.8824 11 C2 11 2 10 2 10 L1.5 7.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.5 5 C13 5 13 6 13 6 L12 10 L10.5 10 C9.745 10 9.7059 9.2353 9.7059 9.2353 L10.5 5 L11.5 5 z M13.5 7.5 C13.5 7.5 14.1176 7.4706 14.5588 7.9118 C15 8.3529 15 8.7941 15 8.7941 L15 11 L14.1176 11 C13 11 13 10 13 10 L13.5 7.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.2941 4.3824 L6 9.5 C6 9.5 6 10.5 7 10.5 L8 10.5 C9 10.5 9 9.5 9 9.5 L9.7059 4.3824 C9.7059 3 7.5 3 7.5 3 C7.5 3 5.291 3 5.2941 4.3824 z M3.5 5 C2 5 2 6 2 6 L3 10 L4.5 10 C5.255 10 5.2941 9.2353 5.2941 9.2353 L4.5 5 L3.5 5 z M1.5 7.5 C1.5 7.5 0.8824 7.4706 0.44120002 7.9118 C0 8.3529 0 8.7941 0 8.7941 L0 11 L0.8824 11 C2 11 2 10 2 10 L1.5 7.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.5 5 C13 5 13 6 13 6 L12 10 L10.5 10 C9.745 10 9.7059 9.2353 9.7059 9.2353 L10.5 5 L11.5 5 z M13.5 7.5 C13.5 7.5 14.1176 7.4706 14.5588 7.9118 C15 8.3529 15 8.7941 15 8.7941 L15 11 L14.1176 11 C13 11 13 10 13 10 L13.5 7.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBakery15;
