import { useEffect, useState } from "react"
import { Alert, Box, CircularProgress } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { Subscription } from "model/ais/Subscription"
import { setBoatDetails } from "reducers/ais"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import useLeftDistance from "../../hooks/useLeftDistance"
import { ArchiveMetadata } from "../../models/ArchiveMetadata"
import AisArchivePlayer from "./AisArchivePlayer"
import useArchiveAnimationsManager from "./hooks/useArchiveAnimationsManager"
import useSliderValueTransform from "./hooks/useSliderValueTransform"

type Props = {
    archiveMetadata: ArchiveMetadata
    subscription: Subscription
    onClose: () => void
}

const DATE_FORMAT = "DD MMMM YYYY HH:mm:ss [UTC]"
const LOADER_WIDTH = 40

const AisArchivePlayerContainer = ({ archiveMetadata, subscription, onClose }: Props) => {
    const leftDistance = useLeftDistance(LOADER_WIDTH)

    const initialIntervalStartDate = dayjs.utc(archiveMetadata.earliestDataTimestamp).second(0).millisecond(0)
    const initialIntervalEndDate = dayjs.utc(archiveMetadata.latestDataTimestamp).second(0).millisecond(0)
    const [filteringInterval, setFilteringInterval] = useState<[Dayjs, Dayjs]>([
        initialIntervalStartDate,
        initialIntervalEndDate,
    ])
    const [isDataOutOfRange, setIsDataOutOfRange] = useState(false)

    const {
        changePlaybackSpeed,
        currentDate,
        endDate,
        jumpToBucket,
        playbackSpeed,
        isFetching,
        isPlaying,
        shouldShowReplay,
        pausePlayback,
        startDate,
        startPlayback,
        stopPlayback,
    } = useArchiveAnimationsManager({
        archiveMetadata,
        filteringInterval,
        subscription,
    })

    const { dateToSliderValue, dateToSliderValueInitially, sliderValueToBucketIndex, sliderValueToDate, step } =
        useSliderValueTransform(startDate, endDate, initialIntervalStartDate, initialIntervalEndDate, playbackSpeed)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (initialIntervalStartDate.isAfter(currentDate) || initialIntervalEndDate.isBefore(currentDate))
            setIsDataOutOfRange(true)
        else setIsDataOutOfRange(false)
    }, [currentDate])

    useEffect(
        () => () => {
            stopPlayback()
            dispatch(setBoatDetails({}))
        },

        [stopPlayback],
    )

    return (
        <div>
            {isFetching && (
                <Box bottom="50%" left={`calc(50% + ${leftDistance}px)`} position="fixed" zIndex={2}>
                    <CircularProgress />
                </Box>
            )}
            {isDataOutOfRange && (
                <Box mb={1}>
                    <Alert severity="info">Some data is missing for this timelapse re-play</Alert>
                </Box>
            )}
            <AisArchivePlayer
                endDate={initialIntervalEndDate}
                filteringInterval={filteringInterval}
                getLabelText={value => sliderValueToDate(value).utc().format(DATE_FORMAT)}
                label={currentDate?.utc().format(DATE_FORMAT) ?? ""}
                playbackSpeed={playbackSpeed}
                sliderValue={currentDate ? dateToSliderValue(currentDate) : 0}
                step={step}
                isPlaying={isPlaying}
                shouldShowReplay={shouldShowReplay}
                startDate={initialIntervalStartDate}
                onClose={onClose}
                onFilteringIntervalChange={(newFilteringInterval: [Dayjs, Dayjs]) => {
                    setFilteringInterval(newFilteringInterval)
                }}
                onPlaybackSpeedChange={changePlaybackSpeed}
                onPlayToggle={() => (isPlaying ? pausePlayback() : startPlayback())}
                onReplay={() => {
                    jumpToBucket(0)
                    startPlayback()
                }}
                onSliderValueChange={value => jumpToBucket(sliderValueToBucketIndex(value))}
            />
        </div>
    )
}

export { AisArchivePlayerContainer }
