import * as React from "react";
const SvgPark11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "park-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 3.75 C11 2.7835 10.2165 2 9.25 2 C8.9711 2.0012 8.6966 2.0698 8.45 2.2 C8.4284 1.5308 7.8795 0.9997 7.21 1 C7.1386 1.0103 7.0683 1.027 7 1.05 C6.9091 0.4341 6.3724 -0.0168 5.75 0 C5.2633 0.0026 4.8224 0.2874 4.62 0.73 C4.4366 0.6044 4.222 0.5317 4 0.52 C3.5966 0.5282 3.223 0.7338 3 1.07 C2.8417 1.0195 2.6761 0.9959 2.51 1 C1.6761 0.9991 0.9992 1.6743 0.9983 2.5083 C0.9981 2.7043 1.036 2.8985 1.11 3.08 C0.302 3.2358 -0.2268 4.0171 -0.071 4.8251 C0.0422 5.4122 0.4954 5.8748 1.08 6 C1.3292 6.7843 2.167 7.218 2.9513001 6.9688 C3.2148 6.8851 3.4498 6.7297 3.63 6.52 L3.75 6.52 C4.0712 6.5191 4.3795 6.3936 4.61 6.17 L4.61 10 L3 11 L8 11 L6.4 10 L6.4 9 C7.0623 8.1914 7.8694 7.5132 8.780001 7 C9.039301 6.9507 9.280901 6.8333 9.4800005 6.66 L9.4800005 6.66 L9.4800005 6.66 C9.8179 6.3704 10.0086 5.9449 10 5.5 C10.0033 5.4434 10.0033 5.3866 10 5.33 C10.6103 5.0405 10.9996 4.4255 11 3.75 z M6.36 8.25 L6.36 6 L6.54 6 C6.7212 5.9985 6.9007 5.9647 7.0699997 5.9 C7.1931996 6.3546 7.5227995 6.7249002 7.9599996 6.9 C7.375 7.2849 6.8378 7.7381 6.36 8.25 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11 3.75 C11 2.7835 10.2165 2 9.25 2 C8.9711 2.0012 8.6966 2.0698 8.45 2.2 C8.4284 1.5308 7.8795 0.9997 7.21 1 C7.1386 1.0103 7.0683 1.027 7 1.05 C6.9091 0.4341 6.3724 -0.0168 5.75 0 C5.2633 0.0026 4.8224 0.2874 4.62 0.73 C4.4366 0.6044 4.222 0.5317 4 0.52 C3.5966 0.5282 3.223 0.7338 3 1.07 C2.8417 1.0195 2.6761 0.9959 2.51 1 C1.6761 0.9991 0.9992 1.6743 0.9983 2.5083 C0.9981 2.7043 1.036 2.8985 1.11 3.08 C0.302 3.2358 -0.2268 4.0171 -0.071 4.8251 C0.0422 5.4122 0.4954 5.8748 1.08 6 C1.3292 6.7843 2.167 7.218 2.9513001 6.9688 C3.2148 6.8851 3.4498 6.7297 3.63 6.52 L3.75 6.52 C4.0712 6.5191 4.3795 6.3936 4.61 6.17 L4.61 10 L3 11 L8 11 L6.4 10 L6.4 9 C7.0623 8.1914 7.8694 7.5132 8.780001 7 C9.039301 6.9507 9.280901 6.8333 9.4800005 6.66 L9.4800005 6.66 L9.4800005 6.66 C9.8179 6.3704 10.0086 5.9449 10 5.5 C10.0033 5.4434 10.0033 5.3866 10 5.33 C10.6103 5.0405 10.9996 4.4255 11 3.75 z M6.36 8.25 L6.36 6 L6.54 6 C6.7212 5.9985 6.9007 5.9647 7.0699997 5.9 C7.1931996 6.3546 7.5227995 6.7249002 7.9599996 6.9 C7.375 7.2849 6.8378 7.7381 6.36 8.25 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPark11;
