import download from "downloadjs"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

let imagePromises = []

// The resize method takes into account the dpr. In order to
// increase the clarity we need to resize the map through this.
const forceDprUpdate = printMap => {
    const container = printMap.getContainer()

    // The resize() function checks if the container changed size before running the canvas resizing code
    // We need to trigger that code by actually changing the width with a small margin
    const newWidth = "99.9%"
    container.style.width = newWidth

    printMap.resize()

    const oldWidth = "100%"
    container.style.width = oldWidth

    printMap.resize()
}

export const onPrint = (
    unitType,
    height,
    width,
    dpi,
    printMap,
    printFeatures,
    outputType,
    orientation,
    setPrinting,
) => {
    let desiredHeight = 0
    let desiredWidth = 0

    switch (unitType) {
        case "inch":
            desiredHeight = height * dpi
            desiredWidth = width * dpi
            break
        case "milimeter":
            desiredHeight = (height / 25.4) * dpi
            desiredWidth = (width / 25.4) * dpi
            break
        case "pixel":
            desiredHeight = height
            desiredWidth = width
            break
        default:
            desiredHeight = height * dpi
            desiredWidth = width * dpi
            break
    }

    let printPreviewContainer = document.getElementById("print-preview-container")

    //Define under the window object the devicePixelRatio for the map
    Object.defineProperty(window, "devicePixelRatio", {
        get: () => {
            return desiredHeight / printPreviewContainer.offsetHeight
        },
    })

    forceDprUpdate(printMap)

    setTimeout(
        () => {
            // This is done on a map rerender so that we do not have to use preserveDrawingBuffer: true which is a performance drawback
            printMap.once("render", () =>
                printFeatures.showMapLegend
                    ? createImageWithLegend(
                          desiredHeight,
                          desiredWidth,
                          printMap,
                          printFeatures,
                          outputType,
                          orientation,
                          width,
                          height,
                          setPrinting,
                      )
                    : createImage(
                          desiredHeight,
                          desiredWidth,
                          printMap,
                          printFeatures,
                          outputType,
                          orientation,
                          width,
                          height,
                          setPrinting,
                      ),
            )
            printMap.triggerRepaint() // needed to trigger a rerender
        },

        650,
    )
}

const createImage = (
    desiredHeight,
    desiredWidth,
    printMap,
    printFeatures,
    outputType,
    orientation,
    width,
    height,
    setPrinting,
) => {
    let finalImg
    let actualPixelRatio = 1

    finalImg = new Image()

    //Create final image canvas
    let finalCanvas = document.createElement("canvas")
    finalCanvas.height = desiredHeight
    finalCanvas.width = desiredWidth
    finalCanvas.style.display = "none"
    document.body.appendChild(finalCanvas)

    let canvasContext = finalCanvas.getContext("2d")

    //Get map canvas and convert it to a image
    let mapCanvas = printMap.getCanvas(document.querySelector(".mapboxgl-canvas"))

    let mapImage = new Image()
    mapImage.src = mapCanvas.toDataURL({ pixelRatio: 3 })

    //Once the map image is loaded draw it on the final canvas
    imagePromises.push(
        new Promise(resolve => {
            mapImage.onload = () => {
                let x = -mapImage.width / 2 - -desiredWidth / 2
                let y = 0

                resolve({ source: mapImage, x: x, y: y })
            }
        }),
    )

    if (printFeatures.showMapTitle && !printFeatures.showMapLegend) {
        let title = document.getElementById("map-title")
        let position = {
            x: desiredWidth / 2 - (title.offsetWidth * window.devicePixelRatio) / 2,
            y: 16,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(title, position, properties)
    }

    if (printFeatures.showMapNotes && !printFeatures.showMapLegend) {
        let notes = document.getElementById("map-notes")
        notes.style.wordWrap = "break-word"
        let position = {
            x: desiredWidth / 2 - (notes.offsetWidth * window.devicePixelRatio) / 2,
            y: desiredHeight - notes.offsetHeight * window.devicePixelRatio - 16,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(notes, position, properties)
    }

    if (printFeatures.showMapCopyright && !printFeatures.showMapLegend) {
        let copyright = document.getElementById("map-copyright")
        let position = {
            x: desiredWidth - copyright.offsetWidth * window.devicePixelRatio - 8 * window.devicePixelRatio,
            y: desiredHeight - copyright.offsetHeight * window.devicePixelRatio - 5 * window.devicePixelRatio,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(copyright, position, properties)
    }

    if (printFeatures.showMapDate && !printFeatures.showMapLegend) {
        let mapDate = document.getElementById("map-date")
        let position = {
            x: desiredWidth - mapDate.offsetWidth * window.devicePixelRatio - 8 * window.devicePixelRatio,
            y: desiredHeight - mapDate.offsetHeight * window.devicePixelRatio - 55 * window.devicePixelRatio,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(mapDate, position, properties)
    }

    if (printFeatures.showMapLogo) {
        let mapLogo = document.getElementById("map-logo")
        let position = {
            x: 16,
            y: 16,
        }
        let properties = { allowTaint: true, backgroundColor: null, useCORS: true }
        createImageFeaturePromise(mapLogo, position, properties)
    }

    if (printFeatures.showMapNorthArrow) {
        let mapNorthArrow = document.getElementById("map-north-arrow")
        let position = {
            x: desiredWidth - mapNorthArrow.offsetWidth * window.devicePixelRatio - 26,
            y: 36,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(mapNorthArrow, position, properties)
    }

    if (printFeatures.showMapScalebar) {
        let scaleControl = document.getElementsByClassName("mapboxgl-ctrl-scale")[1]
        let position = {
            x: desiredWidth - scaleControl.offsetWidth * window.devicePixelRatio - 8 * devicePixelRatio,
            y: desiredHeight - scaleControl.offsetHeight * window.devicePixelRatio - 30 * devicePixelRatio,
        }
        createImageFeaturePromise(scaleControl, position)
    }

    //Constructing the final image with all the features
    Promise.all(imagePromises).then(images => {
        for (let i = 0; i < images.length; i++) {
            let image = images[i]
            canvasContext.drawImage(image.source, image.x, image.y)
        }
        finalImg.src = finalCanvas.toDataURL()

        Object.defineProperty(window, "devicePixelRatio", {
            get: function () {
                return actualPixelRatio
            },
        })

        forceDprUpdate(printMap)

        finalCanvas.toBlob(blob => {
            onDownload(blob, outputType, orientation, width, height, setPrinting)
        })
    })
}

const createImageWithLegend = (
    desiredHeight,
    desiredWidth,
    printMap,
    printFeatures,
    outputType,
    orientation,
    width,
    height,
    setPrinting,
) => {
    let finalImg
    let actualPixelRatio = 1
    finalImg = new Image()

    //Create final image canvas
    let finalCanvas = document.createElement("canvas")
    finalCanvas.height = desiredHeight
    finalCanvas.width = desiredWidth
    finalCanvas.style.display = "none"
    document.body.appendChild(finalCanvas)
    let canvasContext = finalCanvas.getContext("2d")

    let legendInfo =
        orientation === "portrait"
            ? document.getElementById("modal-info-portrait")
            : document.getElementById("modal-info-landscape")
    let mapCanvas = printMap.getCanvas(document.getElementsByClassName("mapboxgl-canvas")[1])
    let mapImage = new Image()
    mapImage.src = mapCanvas.toDataURL()

    imagePromises.push(
        new Promise(resolve => {
            mapImage.onload = () => {
                let x = 0
                let y = 0

                resolve({ source: mapImage, x: x, y: y })
            }
        }),
    )

    if (orientation === "portrait") {
        let position = {
            x: 0,
            y: desiredHeight - legendInfo.offsetHeight * window.devicePixelRatio - 15,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(legendInfo, position, properties)
    } else {
        let position = {
            x: desiredWidth - legendInfo.offsetWidth * window.devicePixelRatio,
            y: 0,
        }
        let properties = { allowTaint: true, backgroundColor: null }
        createImageFeaturePromise(legendInfo, position, properties)
    }
    if (printFeatures.showMapNorthArrow) {
        let mapNorthArrow = document.getElementById("map-north-arrow")
        let properties = { allowTaint: true, backgroundColor: null }
        let position =
            orientation === "portrait"
                ? {
                      x: desiredWidth - mapNorthArrow.offsetWidth * window.devicePixelRatio - 26,
                      y: 36,
                  }
                : {
                      x:
                          desiredWidth -
                          mapNorthArrow.offsetWidth * window.devicePixelRatio -
                          legendInfo.offsetWidth * window.devicePixelRatio -
                          26,
                      y: 36,
                  }
        createImageFeaturePromise(mapNorthArrow, position, properties)
    }

    if (printFeatures.showMapScalebar) {
        let scaleControl = document.getElementsByClassName("mapboxgl-ctrl-scale")[1]
        let position =
            orientation === "portrait"
                ? {
                      x: desiredWidth - scaleControl.offsetWidth * window.devicePixelRatio - 37,
                      y:
                          desiredHeight -
                          scaleControl.offsetHeight * window.devicePixelRatio -
                          legendInfo.offsetHeight * window.devicePixelRatio -
                          50,
                  }
                : {
                      x:
                          desiredWidth -
                          scaleControl.offsetWidth * window.devicePixelRatio -
                          legendInfo.offsetWidth * window.devicePixelRatio -
                          37,
                      y: desiredHeight - scaleControl.offsetHeight * window.devicePixelRatio - 27,
                  }
        createImageFeaturePromise(scaleControl, position)
    }

    if (printFeatures.showMapLogo) {
        let mapLogo = document.getElementById("map-logo")
        let position = {
            x: 16,
            y: 16,
        }
        let properties = { allowTaint: true, backgroundColor: null, useCORS: true }
        createImageFeaturePromise(mapLogo, position, properties)
    }

    //Constructing the final image with all the features
    Promise.all(imagePromises).then(images => {
        for (let i = 0; i < images.length; i++) {
            let image = images[i]
            canvasContext.drawImage(image.source, image.x, image.y)
        }
        finalImg.src = finalCanvas.toDataURL()

        Object.defineProperty(window, "devicePixelRatio", {
            get: function () {
                return actualPixelRatio
            },
        })

        forceDprUpdate(printMap)

        finalCanvas.toBlob(blob => {
            onDownload(blob, outputType, orientation, width, height, setPrinting)
        })
    })

    imagePromises = []
}

const createImageFeaturePromise = (htmlElement, htmlElementPosition, htmlElementProperties = {}) => {
    imagePromises.push(
        new Promise(resolve => {
            html2canvas(htmlElement, htmlElementProperties).then(canvas => {
                let img = new Image()
                img.src = canvas.toDataURL()

                img.onload = () => {
                    let x = htmlElementPosition.x
                    let y = htmlElementPosition.y

                    resolve({ source: img, x: x, y: y })
                }
            })
        }),
    )
}

const onDownload = (blob, outputType, orientation, width, height, setPrinting) => {
    if (outputType === "image") {
        download(blob, "map.png")
    } else {
        let imageBase64
        let imageData = new FileReader()

        imageData.readAsDataURL(blob)
        imageData.onload = () => {
            imageBase64 = imageData.result
            let pdf = new jsPDF({
                compress: true,
                format: [width, height],
                orientation: orientation,
                unit: "in",
            })
            pdf.addImage(imageBase64, "PNG", 0, 0, width, height, null, "FAST")
            pdf.setProperties({
                author: "lautec",
                creator: "Print Maps",
                subject: "fisk",
                title: "GIS Map",
            })
            pdf.save("map.pdf")
        }
    }
    setPrinting(false)
}
