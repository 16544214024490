import * as React from "react";
const SvgTownHall15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "town-hall-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L1 3.4453 L1 4 L14 4 L14 3.4453 L7.5 0 z M2 5 L2 10 L1 11.5547 L1 13 L14 13 L14 11.5547 L13 10 L13 5 L2 5 z M4 6 L5 6 L5 11.5 L4 11.5 L4 6 z M7 6 L8 6 L8 11.5 L7 11.5 L7 6 z M10 6 L11 6 L11 11.5 L10 11.5 L10 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L1 3.4453 L1 4 L14 4 L14 3.4453 L7.5 0 z M2 5 L2 10 L1 11.5547 L1 13 L14 13 L14 11.5547 L13 10 L13 5 L2 5 z M4 6 L5 6 L5 11.5 L4 11.5 L4 6 z M7 6 L8 6 L8 11.5 L7 11.5 L7 6 z M10 6 L11 6 L11 11.5 L10 11.5 L10 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTownHall15;
