import * as React from "react";
const SvgBakery11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bakery-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 2 C3.5 2 3.5 3 3.5 3 L5 7.5 C5 7.5 5 8 5.5 8 C6 8 6 7.5 6 7.5 L7.5 3 C7.5 3 7.5 2 6.5 2 L4.5 2 z M9 3.5 L7 7.5 L8.5 7.5 L9.5 8.5 L10 8.5 C11 8.5 11 7.6 11 7.6 L11 6.3 L9 3.5 z M0 6.3 L0 7.5 C0 7.5 0.0296 8.5097 1 8.5 C1.9704 8.4903 1.5 8.5 1.5 8.5 L2.5 7.5 L4 7.5 L2 3.5 L0 6.3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.5 2 C3.5 2 3.5 3 3.5 3 L5 7.5 C5 7.5 5 8 5.5 8 C6 8 6 7.5 6 7.5 L7.5 3 C7.5 3 7.5 2 6.5 2 L4.5 2 z M9 3.5 L7 7.5 L8.5 7.5 L9.5 8.5 L10 8.5 C11 8.5 11 7.6 11 7.6 L11 6.3 L9 3.5 z M0 6.3 L0 7.5 C0 7.5 0.0296 8.5097 1 8.5 C1.9704 8.4903 1.5 8.5 1.5 8.5 L2.5 7.5 L4 7.5 L2 3.5 L0 6.3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBakery11;
