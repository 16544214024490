import makeStyles from "@mui/styles/makeStyles"

export const useMapPreviewStyles = makeStyles(() => ({
    loaderContainer: {
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
    modalLegendLandscape: {
        backgroundColor: "#fff",
        gridArea: "legendLandscape",
        minWidth: 400,
    },
    modalLegendPortrait: {
        backgroundColor: "#fff",
        gridArea: "legendPortrait",
    },
    modalMapContainer: {
        "& .mapboxgl-ctrl-attrib": {
            display: "none",
        },
        "& .mapboxgl-ctrl-scale": {
            fontSize: "0.5rem",
            marginBottom: 25,
            marginRight: 10,
        },
        bottom: 0,
        gridArea: "modalMap",
        height: "100%",
        left: 0,
        position: "relative",
        right: 0,
        top: 0,
    },
    root: {
        display: "grid",
        flexDirection: "row",
        gridTemplateAreas: '"modalMap legendLandscape" "legendPortrait legendLandscape"',
        gridTemplateColumns: "1fr min-content",
        gridTemplateRows: "1fr min-content",
        height: "90vh",
    },
}))
