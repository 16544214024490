import * as React from "react";
const SvgLodging11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "lodging-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1.5 2 C1.2239 2 1 2.2239 1 2.5 L1 8.5 C1 8.7761 1.2239 9 1.5 9 C1.7761 9 2 8.7761 2 8.5 L2 8 L9 8 L9 8.5 C9 8.7761 9.2239 9 9.5 9 C9.7761 9 10 8.7761 10 8.5 L10 7.5 C10 7.2239 9.7761 7 9.5 7 L2 7 L2 2.5 C2 2.2239 1.7761 2 1.5 2 z M3.5 2 C2.9477 2 2.5 2.4477 2.5 3 C2.5 3.5523 2.9477 4 3.5 4 C4.0523 4 4.5 3.5523 4.5 3 C4.5 2.4477 4.0523 2 3.5 2 z M6 3 C5.4477 3 5 3.4477 5 4 L5 5 L3 5 C2.7239 5 2.5 5.2239 2.5 5.5 C2.5 5.7761 2.7239 6 3 6 L10 6 L10 5 C10 3.8954 9.1046 3 8 3 L6 3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M1.5 2 C1.2239 2 1 2.2239 1 2.5 L1 8.5 C1 8.7761 1.2239 9 1.5 9 C1.7761 9 2 8.7761 2 8.5 L2 8 L9 8 L9 8.5 C9 8.7761 9.2239 9 9.5 9 C9.7761 9 10 8.7761 10 8.5 L10 7.5 C10 7.2239 9.7761 7 9.5 7 L2 7 L2 2.5 C2 2.2239 1.7761 2 1.5 2 z M3.5 2 C2.9477 2 2.5 2.4477 2.5 3 C2.5 3.5523 2.9477 4 3.5 4 C4.0523 4 4.5 3.5523 4.5 3 C4.5 2.4477 4.0523 2 3.5 2 z M6 3 C5.4477 3 5 3.4477 5 4 L5 5 L3 5 C2.7239 5 2.5 5.2239 2.5 5.5 C2.5 5.7761 2.7239 6 3 6 L10 6 L10 5 C10 3.8954 9.1046 3 8 3 L6 3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgLodging11;
