import * as React from "react";
const SvgPicnicSite15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "picnic-site-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 3 C3.446 3 3 3.446 3 4 C3 4.554 3.446 5 4 5 L5.2969 5 L4.6523 7 L2.5 7 C1.946 7 1.5 7.446 1.5 8 C1.5 8.554 1.946 9 2.5 9 L4.0098 9 L3.041 12.0098 C2.9125998 12.4037 3.1278 12.8271 3.5217 12.9555 C3.9155998 13.0838995 4.3389997 12.8687 4.4674 12.4748 C4.4679003 12.4735 4.4683 12.4721 4.4687 12.4708 L5.5859 9 L9.414 9 L10.531199 12.4707 C10.6585 12.865001 11.0813 13.0814 11.475499 12.954101 C11.869699 12.826801 12.086199 12.404 11.9588995 12.009801 L11.9588995 12.009801 L10.9902 9 L12.5 9 C13.054 9 13.5 8.554 13.5 8 C13.5 7.446 13.054 7 12.5 7 L10.3477 7 L9.7031 5 L11 5 C11.554 5 12 4.554 12 4 C12 3.446 11.554 3 11 3 L4 3 z M6.873 5 L8.127 5 L8.7715 7 L6.2284994 7 L6.873 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 3 C3.446 3 3 3.446 3 4 C3 4.554 3.446 5 4 5 L5.2969 5 L4.6523 7 L2.5 7 C1.946 7 1.5 7.446 1.5 8 C1.5 8.554 1.946 9 2.5 9 L4.0098 9 L3.041 12.0098 C2.9125998 12.4037 3.1278 12.8271 3.5217 12.9555 C3.9155998 13.0838995 4.3389997 12.8687 4.4674 12.4748 C4.4679003 12.4735 4.4683 12.4721 4.4687 12.4708 L5.5859 9 L9.414 9 L10.531199 12.4707 C10.6585 12.865001 11.0813 13.0814 11.475499 12.954101 C11.869699 12.826801 12.086199 12.404 11.9588995 12.009801 L11.9588995 12.009801 L10.9902 9 L12.5 9 C13.054 9 13.5 8.554 13.5 8 C13.5 7.446 13.054 7 12.5 7 L10.3477 7 L9.7031 5 L11 5 C11.554 5 12 4.554 12 4 C12 3.446 11.554 3 11 3 L4 3 z M6.873 5 L8.127 5 L8.7715 7 L6.2284994 7 L6.873 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPicnicSite15;
