import makeStyles from "@mui/styles/makeStyles"

//Fullscreen is not available on some devices (IOS mainly)
const fullScreenAvailable =
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled

export const useBasemapSelectorStyles = makeStyles(() => ({
    basemapsContainer: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        padding: 0,
    },
    basemapTypeTitle: {
        fontWeight: 600,
        textAlign: "center",
    },
    icon: {
        color: "#484848",
    },
    iconContainer: {
        "&:hover": {
            backgroundColor: "whitesmoke",
            borderRadius: 4,
        },
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 29,

        justifyContent: "center",
        width: 29,
    },
    iconRoot: {
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
        position: "absolute",
        right: 10,
        top: fullScreenAvailable ? 192 : 229, //if fullscreen button is not show, then the buttons get moved up
        zIndex: 2,
    },
    list: {
        maxHeight: 280,
        overflow: "auto",
        overflowX: "overlay",
        position: "relative",
    },
    listTitle: {
        padding: 8,
        textAlign: "center",
    },
    radioText: {
        paddingLeft: 8,
        textTransform: "capitalize",
    },
    sectionContainer: {
        backgroundColor: "#fff",
        padding: 0,
    },
}))
