import * as React from "react";
const SvgSwimming15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "swimming-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.1113 2 C9.9989 2 9.6758 2.1465 9.6758 2.1465 L6.3535 3.8262 C5.9111 4.0024 5.7358 4.7081 6.002 5.0605 L6.9727 6.4687004 L3.002 8.498 L5 9.998 L7.502 8.498 L10.002 9.998 L11.004 8.996 L8.004 4.9960003 L10.5606 3.4667003 C11.0892 3.2005002 11.004001 2.7622004 11.004001 2.4960003 C10.9999 2.2861 10.6437 2 10.1113 2 z M12.252 5 C11.2847 5 10.5 5.7827 10.5 6.75 C10.5 7.7173 11.2847 8.502 12.252 8.502 C13.219299 8.502 14.002 7.7173 14.002 6.75 C14.002 5.7827 13.2192 5 12.252 5 z M2.5 10 L0 11.5 L0 13 L2.5 11.5 L5 13 L7.502 11.5 L10.002 13 L12 11.5 L15 13 L15 11.5 L12 10 L10.002 11.5 L7.502 10 L5 11.5 L2.5 10 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.1113 2 C9.9989 2 9.6758 2.1465 9.6758 2.1465 L6.3535 3.8262 C5.9111 4.0024 5.7358 4.7081 6.002 5.0605 L6.9727 6.4687004 L3.002 8.498 L5 9.998 L7.502 8.498 L10.002 9.998 L11.004 8.996 L8.004 4.9960003 L10.5606 3.4667003 C11.0892 3.2005002 11.004001 2.7622004 11.004001 2.4960003 C10.9999 2.2861 10.6437 2 10.1113 2 z M12.252 5 C11.2847 5 10.5 5.7827 10.5 6.75 C10.5 7.7173 11.2847 8.502 12.252 8.502 C13.219299 8.502 14.002 7.7173 14.002 6.75 C14.002 5.7827 13.2192 5 12.252 5 z M2.5 10 L0 11.5 L0 13 L2.5 11.5 L5 13 L7.502 11.5 L10.002 13 L12 11.5 L15 13 L15 11.5 L12 10 L10.002 11.5 L7.502 10 L5 11.5 L2.5 10 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSwimming15;
