import { useRef, useEffect } from "react"

const useCanvas = (draw: (context: CanvasRenderingContext2D) => void, options = {}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas = canvasRef.current

        if (!canvas) {
            throw Error("canvas has not be set")
        }

        const context = canvas.getContext("2d")

        if (!context) {
            throw Error("2d context is null")
        }

        draw(context)
    }, [draw])
    return canvasRef
}

const useAnimatedCanvas = (draw: (context: CanvasRenderingContext2D, frameCount: number) => void, options = {}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        const canvas = canvasRef.current

        if (!canvas) {
            throw Error("canvas has not be set")
        }

        const context = canvas.getContext("2d")

        if (!context) {
            throw Error("2d context is null")
        }

        let frameCount = 0
        let animationFrameId = 0
        const render = () => {
            frameCount++
            draw(context, frameCount)
            animationFrameId = window.requestAnimationFrame(render)
        }
        render()
        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw])
    return canvasRef
}

export { useCanvas, useAnimatedCanvas }
