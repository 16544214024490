import { makeStyles } from "@mui/styles"
import appStyles from "utils/styles"

export const useStyles = makeStyles(theme => ({
    closeButton: {
        borderBottom: "1px solid" + theme.customColors.borderColor,
        color: theme.customColors.appTextColor,
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        padding: 8,
        paddingLeft: 16,
    },
    headerText: {
        fontWeight: "bold",
    },
    openButton: {
        backgroundColor: appStyles.color.HEADER_BACKGROUND,
        borderRadius: "50%",
        color: "white",
        cursor: "pointer",
        display: "flex",
        justifyContent: "space-between",
        padding: 8,
    },
    root: {
        backgroundColor: "#fff",
        borderRadius: 8,
        bottom: 35,
        left: 6,
        maxWidth: 385,
        position: "absolute",
        zIndex: 1,
    },
    rootClosed: {
        backgroundColor: "transparent",
        bottom: 35,
        left: 6,
        minWidth: 20,
        opacity: 0.9,
        position: "absolute",
        zIndex: 1,
    },
    rootLegend: {
        position: "relative",
        zIndex: 1,
    },
}))
