import React, { useContext, useMemo } from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import { Typography } from "@mui/material"
import { LAYER_TYPES } from "../../constants/layers/layerTypes"
import { useStylerContext } from "../../hooks/useStylerContext"
import StylesSubList from "./components/StylesSubList/StylesSubList"

const DROP_CONTEXTS = {
    normalStyles: "normalLayers",
    symbolStyles: "symbolStyles",
}

const StylesList = ({ selectedStyleId, onAddStyleToLayer, onRemoveStyleFromLayer, onSelectStyle, onStyleDrop }) => {
    const { layer } = useStylerContext()
    const styles = layer.styles
    const symbolStyles = useMemo(() => styles.filter(s => s.type === LAYER_TYPES.symbol), [styles])
    const normalStyles = useMemo(() => styles.filter(s => s.type !== LAYER_TYPES.symbol), [styles])

    const styleDeletionDisabled = styles.length === 1

    return (
        <div>
            <Typography className="layer-type-category" variant="caption">
                Symbol
            </Typography>
            <div className="add-button" onClick={() => onAddStyleToLayer(true)}>
                <AddIcon color="primary" fontSize="small" />
            </div>
            <StylesSubList
                dndContext={DROP_CONTEXTS.symbolStyles}
                selectedStyleId={selectedStyleId}
                styles={symbolStyles}
                styleDeletionDisabled={styleDeletionDisabled}
                onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                onSelectStyle={onSelectStyle}
                onStyleDrop={onStyleDrop}
            />
            <Typography className="layer-type-category" variant="caption">
                Geometry
            </Typography>
            <div className="add-button" onClick={() => onAddStyleToLayer()}>
                <AddIcon color="primary" fontSize="small" />
            </div>
            <StylesSubList
                dndContext={DROP_CONTEXTS.normalStyles}
                selectedStyleId={selectedStyleId}
                styles={normalStyles}
                styleDeletionDisabled={styleDeletionDisabled}
                onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                onSelectStyle={onSelectStyle}
                onStyleDrop={onStyleDrop}
            />
        </div>
    )
}

export default StylesList
