import type { FilterType } from "../../contexts/FilterContext"
import React from "react"
import { Divider } from "@mui/material"
import { FilterContextProvider } from "../../contexts/FilterContext"
import "../../scss/main.scss"
import FilterContainer from "./components/FilterContainer/FilterContainer"
import FiltersList from "./components/FiltersList/FiltersList"
import { Column } from "./models/Column"
import { useLayerFilterEditorStyles } from "./styles"

type LayerFilterEditorProps = {
    columns: Column[]
    initialFiltersList: FilterType[]
    onFiltersUpdate: (filters: FilterType[]) => void
}

const LayerFilterEditor = ({ columns, initialFiltersList, onFiltersUpdate }: LayerFilterEditorProps) => {
    const classes = useLayerFilterEditorStyles()

    return (
        <div className={classes.filtersList}>
            <FilterContextProvider initialFiltersList={initialFiltersList} onFiltersUpdate={onFiltersUpdate}>
                <div className={classes.filtersContainer}>
                    <div className={classes.filters}>
                        <FiltersList />
                    </div>
                    <Divider orientation="vertical" />
                </div>

                <div className={classes.filterContainer}>
                    <FilterContainer columns={columns} />
                </div>
            </FilterContextProvider>
        </div>
    )
}

export default LayerFilterEditor
