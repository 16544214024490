import * as React from "react";
const SvgCafe15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cafe-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 5 L10 5 L10 3 L2 3 L2 7 C2.0133 9.2091 3.8149 10.9890995 6.024 10.9758 C7.4345 10.9673 8.7362 10.2166 9.45 9 L12 9 C13.1046 9 14 8.1046 14 7 C14 5.8954 13.1046 5 12 5 z M12 8 L9.86 8 C9.9487 7.6739 9.9958 7.3379 10 7 L10 6 L12 6 C12.5522995 6 13 6.4477 13 7 C13 7.5523 12.5523 8 12 8 z M10 12.5 C10 12.7761 9.7761 13 9.5 13 L2.5 13 C2.2239 13 2 12.7761 2 12.5 C2 12.2239 2.2239 12 2.5 12 L9.5 12 C9.7761 12 10 12.2239 10 12.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 5 L10 5 L10 3 L2 3 L2 7 C2.0133 9.2091 3.8149 10.9890995 6.024 10.9758 C7.4345 10.9673 8.7362 10.2166 9.45 9 L12 9 C13.1046 9 14 8.1046 14 7 C14 5.8954 13.1046 5 12 5 z M12 8 L9.86 8 C9.9487 7.6739 9.9958 7.3379 10 7 L10 6 L12 6 C12.5522995 6 13 6.4477 13 7 C13 7.5523 12.5523 8 12 8 z M10 12.5 C10 12.7761 9.7761 13 9.5 13 L2.5 13 C2.2239 13 2 12.7761 2 12.5 C2 12.2239 2.2239 12 2.5 12 L9.5 12 C9.7761 12 10 12.2239 10 12.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCafe15;
