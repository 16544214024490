import React from "react"
import { Button, IconButton, Tooltip } from "@mui/material"
import clsx from "clsx"
import { useSelector } from "react-redux"
import { getIsDrawerOpen } from "selectors/sidebarAndDrawer"
import { useNavStyles } from "../useNavStyles"

const NavButton = ({ active, icon, name, onClick }) => {
    const classes = useNavStyles()
    const isDrawerOpen = useSelector(getIsDrawerOpen)

    return isDrawerOpen ? (
        <Button
            className={clsx(
                active ? classes.actionButtonActive : classes.actionButtonInactive,
                classes.actionButton,
                classes.navOpen,
            )}
            onClick={onClick}
        >
            <div className={classes.toolIcon}>{icon}</div>
            <span className={classes.toolLabel}>{name}</span>
        </Button>
    ) : (
        <Tooltip placement="left" title={name}>
            <IconButton
                className={clsx(
                    active ? classes.actionButtonActive : classes.actionButtonInactive,
                    classes.actionButton,
                )}
                size="large"
                onClick={onClick}
            >
                {icon}
            </IconButton>
        </Tooltip>
    )
}

export default NavButton
