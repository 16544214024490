import { FCWC, useRef, useState } from "react"
import clsx from "clsx"
import useDragAndDropStyles from "./styles"

type Props = {
    index: number
    type: string
    onOrderChange: (index: number, newIndex: number, moveBelow: boolean) => void
}

const DndReorderContainer: FCWC<Props> = ({ children, index, type, onOrderChange }) => {
    const classes = useDragAndDropStyles()
    const [isBeingDragged, setIsBeingDragged] = useState(false)
    const [dragOver, setDragOver] = useState(false)
    const [dragAboveMiddle, setDragAboveMiddle] = useState<boolean | null>(null)
    const [eventTarget, setEventTarget] = useState<EventTarget | null>(null)
    const dragContainerRef = useRef<HTMLDivElement | null>(null)

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation()

        const dropData = {
            index,
            type,
        }

        e.dataTransfer.setData("text/plain", JSON.stringify(dropData))
        setIsBeingDragged(true)

        return false
    }

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        setIsBeingDragged(false)
    }

    const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()

        if (isBeingDragged || !dragContainerRef.current) return

        const rect = dragContainerRef.current.getBoundingClientRect()
        setDragAboveMiddle(e.clientY < rect.y + rect.height / 2)
        setDragOver(true)
        setEventTarget(e.target)
    }

    const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        if (eventTarget === e.target) {
            setDragOver(false)
        }
    }

    const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setDragOver(false)
        let dropData

        try {
            dropData = JSON.parse(e.dataTransfer.getData("text") || "")
        } catch {
            return
        }

        if (dropData.type !== type) return
        if (dropData.index === index) return

        onOrderChange(dropData.index, index, !dragAboveMiddle)
    }

    const dragIndicatorClass = dragAboveMiddle ? classes.dragIndicatorAbove : classes.dragIndicatorBelow

    return (
        <div
            className={clsx(classes.dragCursor, dragOver && dragIndicatorClass)}
            draggable={true}
            ref={dragContainerRef}
            style={{ opacity: isBeingDragged ? 0.4 : 1 }}
            onDragEnd={onDragEnd}
            onDragEnter={onDragOver}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDragStart={onDragStart}
            onDrop={onDrop}
        >
            {children}
        </div>
    )
}

export default DndReorderContainer
