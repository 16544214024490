import { FC, Fragment } from "react"
import LaunchIcon from "@mui/icons-material/Launch"
import { Box, CircularProgress, Link, Stack, Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import useDefaultInfoboxStyles from "features/infobox/components/DefaultInfobox/styles"
import { getLayerStyles } from "selectors/layerSelector"
import { useStyles } from "./styles"
import useDprLocationHistory from "./useDprLocationHistory"

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature
}

const DprProperties: FC<Props> = ({ feature }) => {
    const classes = useStyles()
    const infoboxClasses = useDefaultInfoboxStyles()
    // @ts-ignore TODO: fix this
    const styles = useSelector(getLayerStyles(feature?.layer?.metadata?.resourceId))

    const { locationHistory, isError, isLoading, shouldDisplayInfo } = useDprLocationHistory({ feature })

    const renderRow = (key: string, value: string, isIndented = true, isKeyBold = false) => (
        <tr key={key}>
            <td className={infoboxClasses.propertyKey} style={{ paddingLeft: isIndented ? "12px" : "0" }}>
                <CustomTypography textWeight={isKeyBold ? "bold" : "regular"} variant="body2">
                    {key}
                </CustomTypography>
            </td>
            <td className={infoboxClasses.propertyValue}>
                <CustomTypography variant="body2">{value}</CustomTypography>
            </td>
        </tr>
    )

    const getDisplayName = (historyEntry: any) => {
        if ((styles[0].type as any) === "pie-chart" || (styles[0].type as any) === "box-chart") {
            return (
                <Link
                    color="primary"
                    href={historyEntry.dprLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                >
                    <Stack direction="row" justifyContent="flex-end">
                        <Tooltip title="Open DPR in new tab">
                            <Box alignItems="center" display="flex" gap={0.5}>
                                {
                                    styles[0].properties.filter(x => x.title === "labels")[0].value[
                                        historyEntry.milestoneBitPosition
                                    ]
                                }

                                <LaunchIcon color="primary" fontSize="small" />
                            </Box>
                        </Tooltip>
                    </Stack>
                </Link>
            )
        } else {
            return historyEntry.milestoneDisplayName
        }
    }

    if (!shouldDisplayInfo) return null

    if (isLoading) {
        return (
            <tr>
                <td colSpan={2}>
                    <CircularProgress className={classes.status} size={20} />
                </td>
            </tr>
        )
    }

    if (isError) {
        return (
            <tr>
                <td colSpan={2}>
                    <CustomTypography className={classes.status} color="error" variant="body2">
                        Failed to fetch the DPR milestone information
                    </CustomTypography>
                </td>
            </tr>
        )
    }

    return (
        <>
            {!!locationHistory?.length && renderRow("DPR Milestones", "", false, true)}

            {locationHistory?.map(historyEntry => (
                <Fragment key={`${historyEntry.locationName}-${historyEntry.milestoneId}-${historyEntry.dprDate}`}>
                    {renderRow("Milestone Name", getDisplayName(historyEntry), true, true)}
                    {renderRow("Completion Date", dayjs(historyEntry.dprDate).format("L"))}
                    {renderRow("Vessel", historyEntry.vessel)}
                    {renderRow("Scope", historyEntry.scope)}
                </Fragment>
            ))}
        </>
    )
}

export default DprProperties
