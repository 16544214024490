import * as React from "react";
const SvgPlaceOfWorship15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "place-of-worship-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L5.5 2 L5.5 4 L9.5 4 L9.5 2 L7.5 0 z M5.5 4.5 L4 6 L11 6 L9.5 4.5 L5.5 4.5 z M2 6.5 C1.4477 6.5 1 6.9477 1 7.5 L1 13 L3 13 L3 7.5 C3 6.9477 2.5523 6.5 2 6.5 z M4 6.5 L4 13 L11 13 L11 6.5 L4 6.5 z M13 6.5 C12.4477005 6.5 12 6.9477 12 7.5 L12 13 L14 13 L14 7.5 C14 6.9477 13.5523 6.5 13 6.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L5.5 2 L5.5 4 L9.5 4 L9.5 2 L7.5 0 z M5.5 4.5 L4 6 L11 6 L9.5 4.5 L5.5 4.5 z M2 6.5 C1.4477 6.5 1 6.9477 1 7.5 L1 13 L3 13 L3 7.5 C3 6.9477 2.5523 6.5 2 6.5 z M4 6.5 L4 13 L11 13 L11 6.5 L4 6.5 z M13 6.5 C12.4477005 6.5 12 6.9477 12 7.5 L12 13 L14 13 L14 7.5 C14 6.9477 13.5523 6.5 13 6.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPlaceOfWorship15;
