import * as React from "react";
const SvgRestaurant15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "restaurant-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 0 L2.5 5.5 C2.3536 6.305 4.2815 6.6809998 4.25 7.5 L4 14 C3.9616 14.9993 5 15 5 15 C5 15 6.0384 14.9993 6 14 L5.75 7.5 C5.7186 6.6823997 7.4834 6.3192 7.5 5.5 L6.5 0 L6 0 L6.25 4 L5.5 4.5 L5.25 0 L4.75 0 L4.5 4.5 L3.75 4 L4 0 L3.5 0 z M12 0 C11.2636 0 10.0358 0.6549 9.5449 1.6367 C9.1358 2.3731 9 4.0182 9 5 L9 7.5 C9 8.3182 10.0909 8.5 10.5 8.5 L10 14 C9.9095 14.9959 11 15 11 15 C11 15 12 15 12 14 L12 0 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.5 0 L2.5 5.5 C2.3536 6.305 4.2815 6.6809998 4.25 7.5 L4 14 C3.9616 14.9993 5 15 5 15 C5 15 6.0384 14.9993 6 14 L5.75 7.5 C5.7186 6.6823997 7.4834 6.3192 7.5 5.5 L6.5 0 L6 0 L6.25 4 L5.5 4.5 L5.25 0 L4.75 0 L4.5 4.5 L3.75 4 L4 0 L3.5 0 z M12 0 C11.2636 0 10.0358 0.6549 9.5449 1.6367 C9.1358 2.3731 9 4.0182 9 5 L9 7.5 C9 8.3182 10.0909 8.5 10.5 8.5 L10 14 C9.9095 14.9959 11 15 11 15 C11 15 12 15 12 14 L12 0 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRestaurant15;
