import { createSlice } from "@reduxjs/toolkit"
import { fetchInfoboxes } from "./actions"
import { Infobox } from "./models/Infobox"

type InfoboxState = {
    fetching: boolean
    infoboxes: Infobox[]
    loading: boolean
}

const initialState: InfoboxState = {
    fetching: false,
    infoboxes: [],
    loading: false,
}

const infoboxSlice = createSlice({
    extraReducers: builder => {
        builder
            .addCase(fetchInfoboxes.pending, state => {
                state.fetching = true
            })
            .addCase(fetchInfoboxes.rejected, state => {
                state.infoboxes = []
            })
            .addCase(fetchInfoboxes.fulfilled, (state, { payload }) => {
                state.infoboxes = payload
            })
    },
    initialState,
    name: "infobox",
    reducers: {},
})

export default infoboxSlice.reducer
