import * as React from "react";
const SvgRailMetro15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-metro-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C5.5 0 4.75 0 4.5 1 L3 6.5 L3 10 C3 11 4 11 4 11 L11 11 C11 11 12 11 12 10 L12 6.5 L10.5 1 C10.2273 0 9.5 0 9.5 0 L5.5 0 z M6.5 1.5 L8.5 1.5 C8.5 1.5 9.0357 1.5 9.25 2.5 L10 6 C10.2146 7.0017 9 7 9 7 L6 7 C6 7 4.7854 7.0017 5 6 L5.75 2.5 C5.9643 1.5 6.5 1.5 6.5 1.5 z M5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 z M6.75 8 L8.25 8 C8.3885 8 8.5 8.1115 8.5 8.25 C8.5 8.3885 8.3885 8.5 8.25 8.5 L6.75 8.5 C6.6115 8.5 6.5 8.3885 6.5 8.25 C6.5 8.1115 6.6115 8 6.75 8 z M10 8 C10.5522995 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 C9.4477 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 z M4.125 12 L3 15 L4.5 15 L4.875 14 L10.125 14 L10.5 15 L12 15 L10.875 12 L9.375 12 L9.75 13 L5.25 13 L5.625 12 L4.125 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 C5.5 0 4.75 0 4.5 1 L3 6.5 L3 10 C3 11 4 11 4 11 L11 11 C11 11 12 11 12 10 L12 6.5 L10.5 1 C10.2273 0 9.5 0 9.5 0 L5.5 0 z M6.5 1.5 L8.5 1.5 C8.5 1.5 9.0357 1.5 9.25 2.5 L10 6 C10.2146 7.0017 9 7 9 7 L6 7 C6 7 4.7854 7.0017 5 6 L5.75 2.5 C5.9643 1.5 6.5 1.5 6.5 1.5 z M5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 z M6.75 8 L8.25 8 C8.3885 8 8.5 8.1115 8.5 8.25 C8.5 8.3885 8.3885 8.5 8.25 8.5 L6.75 8.5 C6.6115 8.5 6.5 8.3885 6.5 8.25 C6.5 8.1115 6.6115 8 6.75 8 z M10 8 C10.5522995 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 C9.4477 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 z M4.125 12 L3 15 L4.5 15 L4.875 14 L10.125 14 L10.5 15 L12 15 L10.875 12 L9.375 12 L9.75 13 L5.25 13 L5.625 12 L4.125 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRailMetro15;
