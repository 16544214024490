import * as React from "react";
const SvgCircleStroked15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "circle-stroked-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C11.6422 0 15 3.3578 15 7.5 C15 11.6422 11.6422 15 7.5 15 C3.3577995 15 0 11.6422 0 7.5 C0 3.3577995 3.3578 0 7.5 0 z M7.5 1.6666 C4.2783003 1.6666 1.6666999 4.2783003 1.6666999 7.5 C1.6666999 10.7217 4.2783 13.3334 7.5 13.3334 C10.721701 13.3334 13.3333 10.7217 13.3333 7.5 C13.3333 4.2783003 10.7217 1.6666 7.5 1.6666 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 C11.6422 0 15 3.3578 15 7.5 C15 11.6422 11.6422 15 7.5 15 C3.3577995 15 0 11.6422 0 7.5 C0 3.3577995 3.3578 0 7.5 0 z M7.5 1.6666 C4.2783003 1.6666 1.6666999 4.2783003 1.6666999 7.5 C1.6666999 10.7217 4.2783 13.3334 7.5 13.3334 C10.721701 13.3334 13.3333 10.7217 13.3333 7.5 C13.3333 4.2783003 10.7217 1.6666 7.5 1.6666 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCircleStroked15;
