import { makeStyles } from "@mui/styles"

const useZanderTabsStyles = makeStyles(theme => ({
    activeTab: {
        cursor: "unset",
    },
    closeIconVisible: {
        display: "initial",
    },
    closeTabIcon: {
        "&:hover": {
            color: theme.customColors.appTextColor,
        },
        color: theme.customColors.mutedColor,
        cursor: "pointer",
        display: "none",
        marginTop: -8,
        position: "absolute",
        right: -2,
    },
    tabLabel: {
        display: "flex",
        fontSize: 600,
    },
}))

export default useZanderTabsStyles
