import makeStyles from "@mui/styles/makeStyles"

export const useAppViewStyles = makeStyles(theme => ({
    appsGrid: {
        display: "grid",
        gap: theme.spacing(3),
        gridTemplateColumns: `repeat(auto-fill, minmax(${theme.customSpacing.appCardWidth}px, 1fr))`,
        padding: theme.spacing(3, 2.5),
    },
    container: {
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        overflow: "auto",
    },
    logo: {
        height: 30,
    },
    noApps: {
        color: theme.customColors.mutedColor,
        display: "flex",
        justifyContent: "center",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
    },
    searchField: {
        maxWidth: 400,
        width: "100%",
    },
    searchFieldContainer: {
        alignItems: "center",
        backgroundColor: theme.customColors.appBgColor,
        boxSizing: "border-box",
        display: "flex",
        minHeight: 60,
        paddingInline: theme.spacing(2.5),
        width: "100%",
    },
    searchIcon: {
        color: theme.customColors.darkGray,
        width: 20,
    },
    selectAppTitle: {
        fontSize: 20,
        fontWeight: 400,
        margin: theme.spacing(1, 0, 1, 2.5),
    },
    topbar: {
        display: "flex",
    },
    topbarLeft: {
        alignItems: "center",
        display: "flex",
    },
}))
