import * as React from "react";
const SvgIogp1110 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "331.44, 260.95, 84.5, 86.25", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "372.82,304.07 372.82,304.07", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M372.82 345.2 L376.85 345 L372.82 262.95 L372.82 345.2 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.85 345 L376.85 263.15 L372.82 262.95 L376.85 345 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M376.85 345 L380.84 344.41 L376.85 263.15 L376.85 345 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.84 344.41 L380.84 263.74 L376.85 263.15 L380.84 344.41 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M380.84 344.41 L384.76 343.43 L380.84 263.74 L380.84 344.41 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.76 343.43 L384.76 264.72 L380.84 263.74 L384.76 343.43 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M384.76 343.43 L388.56 342.07 L384.76 264.72 L384.76 343.43 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M388.56 342.07 L388.56 266.08 L384.76 264.72 L388.56 342.07 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M388.56 342.07 L392.2 340.34 L388.56 266.08 L388.56 342.07 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M392.2 340.34 L392.2 267.8 L388.56 266.08 L392.2 340.34 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M392.2 340.34 L395.67 338.27 L392.2 267.8 L392.2 340.34 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.67 338.27 L395.67 269.88 L392.2 267.8 L395.67 338.27 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M395.67 338.27 L398.91 335.86 L395.67 269.88 L395.67 338.27 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.91 335.86 L398.91 272.28 L395.67 269.88 L398.91 335.86 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M398.91 335.86 L401.9 333.15 L398.91 272.28 L398.91 335.86 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.9 333.15 L401.9 274.99 L398.91 272.28 L401.9 333.15 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M401.9 333.15 L404.61 330.16 L401.9 274.99 L401.9 333.15 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.61 330.16 L404.61 277.98 L401.9 274.99 L404.61 330.16 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M404.61 330.16 L407.01 326.92 L404.61 277.98 L404.61 330.16 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M407.01 326.92 L407.01 281.22 L404.61 277.98 L407.01 326.92 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M407.01 326.92 L409.09 323.46 L407.01 281.22 L407.01 326.92 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.09 323.46 L409.09 284.69 L407.01 281.22 L409.09 323.46 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M409.09 323.46 L410.81 319.81 L409.09 284.69 L409.09 323.46 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M410.81 319.81 L410.81 288.33 L409.09 284.69 L410.81 319.81 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M410.81 319.81 L412.17 316.01 L410.81 288.33 L410.81 319.81 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.17 316.01 L412.17 292.13 L410.81 288.33 L412.17 316.01 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M412.17 316.01 L413.15 312.1 L412.17 292.13 L412.17 316.01 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.15 312.1 L413.15 296.05 L412.17 292.13 L413.15 312.1 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.15 312.1 L413.74 308.1 L413.15 296.05 L413.15 312.1 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.74 308.1 L413.74 300.04 L413.15 296.05 L413.74 308.1 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("path", { d: "M413.74 308.1 L413.94 304.07 L413.74 300.04 L413.74 308.1 z", fillRule: "evenodd", style: {
  fill: "#A87000"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "331.69,304.07 331.89,300.04 332.48,296.05 333.47,292.13 334.82,288.33 336.55,284.69 338.63,281.22 341.03,277.98 343.74,274.99 346.73,272.28 349.97,269.88 353.43,267.8 357.08,266.08 360.88,264.72 364.8,263.74 368.79,263.15 372.82,262.95 376.85,263.15 380.84,263.74 384.76,264.72 388.56,266.08 392.2,267.8 395.67,269.88 398.91,272.28 401.9,274.99 404.61,277.98 407.01,281.22 409.09,284.69 410.81,288.33 412.17,292.13 413.15,296.05 413.74,300.04 413.94,304.07 413.74,308.1 413.15,312.1 412.17,316.01 410.81,319.81 409.09,323.46 407.01,326.92 404.61,330.16 401.9,333.15 398.91,335.86 395.67,338.27 392.2,340.34 388.56,342.07 384.76,343.43 380.84,344.41 376.85,345 372.82,345.2 368.79,345 364.8,344.41 360.88,343.43 357.08,342.07 353.43,340.34 349.97,338.27 346.73,335.86 343.74,333.15 341.03,330.16 338.63,326.92 336.55,323.46 334.82,319.81 333.47,316.01 332.48,312.1 331.89,308.1 331.69,304.07", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#A87000"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "372.82,262.95 372.82,345.2", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#A87000"
} }));
export default SvgIogp1110;
