import { ComponentProps, FC, ReactNode } from "react"
import { Tooltip } from "@mui/material"
import clsx from "clsx"
import { useSelector } from "react-redux"
import ButtonLink from "components/common/ButtonLink/ButtonLink"
import { getIsDrawerOpen } from "selectors/sidebarAndDrawer"
import { useNavStyles } from "../useNavStyles"

type Props = {
    active: boolean
    icon: ReactNode
    name: string
} & ComponentProps<typeof ButtonLink>

const NavLink: FC<Props> = ({ active, icon, name, ...props }) => {
    const classes = useNavStyles()
    const isDrawerOpen = useSelector(getIsDrawerOpen)

    const renderLink = () => (
        <ButtonLink
            className={clsx(
                active ? classes.actionButtonActive : classes.actionButtonInactive,
                classes.actionButton,
                isDrawerOpen && classes.navOpen,
            )}
            {...props}
        >
            <span className={isDrawerOpen ? classes.toolIcon : classes.toolIconClosed}>{icon}</span>
            <span className={classes.toolLabel}>{isDrawerOpen && name}</span>
        </ButtonLink>
    )

    return isDrawerOpen ? (
        renderLink()
    ) : (
        <Tooltip placement="left" title={name}>
            {renderLink()}
        </Tooltip>
    )
}

export default NavLink
