import axios from "axios"
import Config from "../config"

export const axiosClient = axios.create({
    baseURL: Config.apiUrl,
    headers: {
        "Content-Type": "application/json",
    },
    timeout: 120000,
    withCredentials: true,
})

export const generateCancelToken = () => axios.CancelToken.source()

export default axiosClient
