export default [
    {
        name: "Blues",
        values: ["#f7fbff", "#deebf7", "#c6dbef", "#9ecae1", "#6baed6", "#4292c6", "#2171b5", "#08519c", "#08306b"],
    },
    {
        name: "Green",
        values: ["#f7fcf5", "#e5f5e0", "#c7e9c0", "#a1d99b", "#74c476", "#41ab5d", "#238b45", "#006d2c", "#00441b"],
    },
    {
        name: "Grays",
        values: ["#fafafa", "#050505"],
    },
    {
        name: "Magma",
        values: ["#000004", "#231151", "#5e177f", "#982c80", "#d3426e", "#f8765c", "#febb80", "#fcfdbf"],
    },
    {
        name: "RdGy",
        values: ["#ca0020", "#f4a582", "#ffffff", "#bababa", "#404040"],
    },
    {
        name: "Reds",
        values: ["#fff5f0", "#fee0d2", "#fcbba1", "#fc9272", "#fb6a4a", "#ef3b2c", "#cb181d", "#a50f15", "#67000d"],
    },
    {
        name: "Spectral",
        values: ["#d7191c", "#fdae61", "#ffffbf", "#abdda4", "#2b83ba"],
    },
    {
        name: "Viridis",
        values: ["#440154", "#462c7b", "#3a528b", "#2b728e", "#20908d", "#27ae80", "#5dc962", "#abdc32", "#fde725"],
    },
]
