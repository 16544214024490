import { useCallback, useEffect, useMemo, useState } from "react"
import { Dayjs } from "dayjs"
import { generateCancelToken } from "actions/apiClient"
import { useFetchArchiveQuery } from "features/ais//api"
import { findTargetChunkBinary, getSecondsBetweenBuckets, HIGHEST_BUCKET_SIZE } from "../utils"

type Args = {
    averageResponsesPerMinute: number
    bucketSizeInMinutes: number
    endDate: Dayjs
    filterInterval: [Dayjs, Dayjs]
    interpolationFactor: number
    lookAheadInMinutes: number
    lookBehindInMinutes: number
    // used when changing the speed
    overrideStartDate: Dayjs | null
    // used to overwrite the start date for current chunk
    subscriptionId: string
    startDate: Dayjs
}

// Used to compute the chunk size, can be subject to change
// the higher it goes, the bigger the payload for each chunk
const CHUNK_MULTIPLY_FACTOR = 500

const useAisArchiveData = ({
    averageResponsesPerMinute,
    bucketSizeInMinutes,
    endDate,
    filterInterval,
    interpolationFactor,
    lookAheadInMinutes,
    lookBehindInMinutes,
    overrideStartDate,
    subscriptionId,
    startDate,
}: Args) => {
    const chunks = useMemo<[Dayjs, Dayjs][]>(() => {
        const chunkSizeInMinutes = Math.floor(CHUNK_MULTIPLY_FACTOR / averageResponsesPerMinute) * HIGHEST_BUCKET_SIZE
        const chunks: [Dayjs, Dayjs][] = []
        let currentStart = startDate

        while (currentStart.isBefore(endDate)) {
            let currentEnd = currentStart.add(chunkSizeInMinutes, "minute")
            if (currentEnd.isAfter(endDate)) currentEnd = endDate
            chunks.push([currentStart, currentEnd])
            currentStart = currentEnd.add(bucketSizeInMinutes, "minute")
        }

        return chunks
    }, [averageResponsesPerMinute, bucketSizeInMinutes, endDate, startDate])

    const [currentChunkIndex, setCurrentChunkIndex] = useState(0)
    const currentChunk = chunks[currentChunkIndex]
    const hasNextChunk = chunks.length - 1 > currentChunkIndex
    const nextChunk = hasNextChunk ? chunks[currentChunkIndex + 1] : currentChunk

    useEffect(() => {
        setCurrentChunkIndex(0)
    }, [filterInterval])

    const cancelToken = useMemo(
        () => generateCancelToken(),

        [currentChunk],
    )

    useEffect(
        () => () => {
            cancelToken.cancel("New request has been made")
        },

        [currentChunk],
    )

    const { data: currentChunkData, isFetching: isCurrentChunkFetching } = useFetchArchiveQuery({
        bucketSizeInMinutes,
        endDate: currentChunk[1],
        interpolationFactor,
        lookAheadInMinutes,
        lookBehindInMinutes,
        subscriptionId,
        cancelToken: cancelToken.token,
        startDate: overrideStartDate ?? currentChunk[0],
    })

    const { isFetching: isNextChunkFetching } = useFetchArchiveQuery(
        {
            bucketSizeInMinutes,
            endDate: hasNextChunk ? nextChunk[1] : currentChunk[1],
            interpolationFactor,
            lookAheadInMinutes,
            lookBehindInMinutes,
            subscriptionId,
            startDate: hasNextChunk ? nextChunk[0] : currentChunk[0],
        },
        { skip: isCurrentChunkFetching || !hasNextChunk },
    )

    const goToNextChunk = useCallback(() => {
        setCurrentChunkIndex(currentChunkIndex + 1)
    }, [currentChunkIndex])

    const goToChunkHavingBucketWithIndex = useCallback(
        (bucketIndex: number) => {
            const secondsBetweenBuckets = getSecondsBetweenBuckets(bucketSizeInMinutes, interpolationFactor)
            const targetTime = startDate.add(bucketIndex * secondsBetweenBuckets, "seconds")
            const targetChunkIndex = findTargetChunkBinary(chunks, targetTime)
            if (targetChunkIndex >= 0) setCurrentChunkIndex(targetChunkIndex)
        },
        [chunks, startDate, interpolationFactor, bucketSizeInMinutes],
    )

    return {
        data: currentChunkData,
        goToChunkHavingBucketWithIndex,
        goToNextChunk,
        hasNextChunk,
        isFetching: isCurrentChunkFetching,
        isNextChunkFetching,
    }
}

export default useAisArchiveData
