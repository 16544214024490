import * as React from "react";
const SvgPark15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "park-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 5.75 C14.0113 5.0637 13.6202 4.4341 13 4.14 C12.9475 3.4906 12.4014 2.9926 11.75 3 C11.6512 3.0079 11.5538 3.0281 11.46 3.06 C11.3993 2.3999999 10.8151 1.9142 10.1552 1.9748999 C9.8965 1.9987 9.6526 2.1058 9.46 2.28 L9.46 2.28 C9.46 1.5896 8.9004 1.03 8.21 1.03 C7.5196 1.03 6.96 1.5896 6.96 2.28 C6.96 2.28 7 2.3 7 2.33 C6.4886 1.8913 5.7184 1.9503 5.2797 2.4618 C5.1316 2.6345 5.0347 2.8451 5 3.07 C4.8417 3.0195 4.6761 2.9959 4.51 3 C3.6816 2.9931 3.0044 3.659 2.9975 4.4874 C2.9958 4.6872 3.0341 4.8852 3.11 5.07 C2.3175 5.2915 1.8546 6.1136 2.0761 6.9061 C2.2163 7.4078 2.6083 7.7998 3.11 7.94 C3.3632998 8.7229 4.2033997 9.1523 4.9863 8.899 C5.5216 8.7258 5.9137 8.2659 6 7.71 C6.183 7.8691 6.4093 7.9701 6.65 8 L6.65 13 L5 14 L10 14 L8.4 13 L8.4 11 C9.1381 10.1085 10.091499 9.4201 11.17 9 C11.9712 9.1879 12.773 8.6908 12.9609 7.8897 C12.9893 7.7686 13.0025 7.6444 13 7.52 C13.0029 7.4667 13.0029 7.4133 13 7.36 C13.6202 7.0659 14.0113 6.4363 14 5.75 z M8.4 10.26 L8.4 6.82 C8.6703 7.3007 9.1785 7.5987 9.73 7.6 L10.009999 7.6 C10.0255995 8.0391 10.2342 8.448999 10.579999 8.72 C9.7643 9.094 9.0251 9.6162 8.4 10.26 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 5.75 C14.0113 5.0637 13.6202 4.4341 13 4.14 C12.9475 3.4906 12.4014 2.9926 11.75 3 C11.6512 3.0079 11.5538 3.0281 11.46 3.06 C11.3993 2.3999999 10.8151 1.9142 10.1552 1.9748999 C9.8965 1.9987 9.6526 2.1058 9.46 2.28 L9.46 2.28 C9.46 1.5896 8.9004 1.03 8.21 1.03 C7.5196 1.03 6.96 1.5896 6.96 2.28 C6.96 2.28 7 2.3 7 2.33 C6.4886 1.8913 5.7184 1.9503 5.2797 2.4618 C5.1316 2.6345 5.0347 2.8451 5 3.07 C4.8417 3.0195 4.6761 2.9959 4.51 3 C3.6816 2.9931 3.0044 3.659 2.9975 4.4874 C2.9958 4.6872 3.0341 4.8852 3.11 5.07 C2.3175 5.2915 1.8546 6.1136 2.0761 6.9061 C2.2163 7.4078 2.6083 7.7998 3.11 7.94 C3.3632998 8.7229 4.2033997 9.1523 4.9863 8.899 C5.5216 8.7258 5.9137 8.2659 6 7.71 C6.183 7.8691 6.4093 7.9701 6.65 8 L6.65 13 L5 14 L10 14 L8.4 13 L8.4 11 C9.1381 10.1085 10.091499 9.4201 11.17 9 C11.9712 9.1879 12.773 8.6908 12.9609 7.8897 C12.9893 7.7686 13.0025 7.6444 13 7.52 C13.0029 7.4667 13.0029 7.4133 13 7.36 C13.6202 7.0659 14.0113 6.4363 14 5.75 z M8.4 10.26 L8.4 6.82 C8.6703 7.3007 9.1785 7.5987 9.73 7.6 L10.009999 7.6 C10.0255995 8.0391 10.2342 8.448999 10.579999 8.72 C9.7643 9.094 9.0251 9.6162 8.4 10.26 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgPark15;
