import * as React from "react";
const SvgAirport15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "airport-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15 6.8182 L15 8.5 L8.5 7.5 L8.1818 12.272699 L11 14 L11 15 L7.5 14.3182 L4 15 L4 14 L6.8182 12.2727 L6.5 7.5 L0 8.5 L0 6.8182 L6.5 4.5 L6.5 1.5 C6.5 1.5 6.5 0 7.5 0 C8.5 0 8.5 1.5 8.5 1.5 L8.5 4.3182 L15 6.8182 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M15 6.8182 L15 8.5 L8.5 7.5 L8.1818 12.272699 L11 14 L11 15 L7.5 14.3182 L4 15 L4 14 L6.8182 12.2727 L6.5 7.5 L0 8.5 L0 6.8182 L6.5 4.5 L6.5 1.5 C6.5 1.5 6.5 0 7.5 0 C8.5 0 8.5 1.5 8.5 1.5 L8.5 4.3182 L15 6.8182 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAirport15;
