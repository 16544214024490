import * as React from "react";
const SvgSchool11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "school-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.5 9 L8.5 8 L10 8 L10 6 L7.5 6 L7.5 5 L10 5 L10 3 L8.5 3 L8.5 2 L10 2 L10 1 L6 1 L6 10 L10 10 L10 9 L8.5 9 z M4 7 L1 7 L1 1 L4 1 L4 7 z M4 8 L2.5 10 L1 8 L4 8 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.5 9 L8.5 8 L10 8 L10 6 L7.5 6 L7.5 5 L10 5 L10 3 L8.5 3 L8.5 2 L10 2 L10 1 L6 1 L6 10 L10 10 L10 9 L8.5 9 z M4 7 L1 7 L1 1 L4 1 L4 7 z M4 8 L2.5 10 L1 8 L4 8 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgSchool11;
