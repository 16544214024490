import { isGroup } from "./isGroup"
import { GenericLayer } from "../models/genericLayer.model"

export function recurseParents<T = object>(
    layers: GenericLayer<T>[],
    entityId: string,
    func: Function,
    parentGroupId?: string,
) {
    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i]

        if (layer.resourceId === entityId) {
            return true
        }

        if (isGroup(layer)) {
            const foundChild = recurseParents(layer.layers!, entityId, func, layer.resourceId)

            if (foundChild) {
                func(layer)

                return true
            }
        }
    }
    return
}
