export enum ModuleStatus {
    Active = 1,
    Inactive,
}

export type CoreModule = {
    abbreviation?: string
    description: string
    favIconUrl: string
    iconUrl?: string
    id: string
    landingPageUrl: string
    locationUrl: string
    name: string
    status: ModuleStatus
}
