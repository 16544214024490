import * as React from "react";
const SvgCar15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "car-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 7 C13.996 6.3096 13.5213 5.7111 12.85 5.55 L11.46 2.3100002 L11.46 2.3100002 L11.46 2.3100002 L11.46 2.3100002 C11.3833 2.1233 11.2019 2.001 11 2 L4 2 C3.8124 2.0034 3.6425 2.1115 3.56 2.28 L3.56 2.28 L3.56 2.28 L3.56 2.28 L2.15 5.54 C1.475 5.702 0.9994 6.3059 1 7 L1 10.5 L2 10.5 L2 11.5 C2 12.0522995 2.4477 12.5 3 12.5 C3.5523 12.5 4 12.0523 4 11.5 L4 10.5 L11 10.5 L11 11.5 C11 12.0522995 11.4477 12.5 12 12.5 C12.5523 12.5 13 12.0523 13 11.5 L13 10.5 L14 10.5 L14 7 z M4.3 3 L10.700001 3 L11.750001 5.5 L3.250001 5.5 L4.3 3 z M3 9 C2.4477 9 2 8.5523 2 8 C2 7.4476995 2.4477 7 3 7 C3.5523 7 4 7.4477 4 8 C4 8.5522995 3.5523 9 3 9 z M12 9 C11.4477005 9 11 8.5523 11 8 C11 7.4476995 11.4477 7 12 7 C12.5523 7 13 7.4477 13 8 C13 8.5522995 12.5523 9 12 9 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 7 C13.996 6.3096 13.5213 5.7111 12.85 5.55 L11.46 2.3100002 L11.46 2.3100002 L11.46 2.3100002 L11.46 2.3100002 C11.3833 2.1233 11.2019 2.001 11 2 L4 2 C3.8124 2.0034 3.6425 2.1115 3.56 2.28 L3.56 2.28 L3.56 2.28 L3.56 2.28 L2.15 5.54 C1.475 5.702 0.9994 6.3059 1 7 L1 10.5 L2 10.5 L2 11.5 C2 12.0522995 2.4477 12.5 3 12.5 C3.5523 12.5 4 12.0523 4 11.5 L4 10.5 L11 10.5 L11 11.5 C11 12.0522995 11.4477 12.5 12 12.5 C12.5523 12.5 13 12.0523 13 11.5 L13 10.5 L14 10.5 L14 7 z M4.3 3 L10.700001 3 L11.750001 5.5 L3.250001 5.5 L4.3 3 z M3 9 C2.4477 9 2 8.5523 2 8 C2 7.4476995 2.4477 7 3 7 C3.5523 7 4 7.4477 4 8 C4 8.5522995 3.5523 9 3 9 z M12 9 C11.4477005 9 11 8.5523 11 8 C11 7.4476995 11.4477 7 12 7 C12.5523 7 13 7.4477 13 8 C13 8.5522995 12.5523 9 12 9 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCar15;
