import * as React from "react";
const SvgIogp1117 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "179.02, 79.01, 441.97992, 441.98993", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400.01,228.34 400.01,228.34", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.91 234.24 L311.91 222.44 L311.71 228.34 L311.91 234.24 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M311.91 234.24 L312.5 240.11 L311.91 222.44 L311.91 234.24 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M312.5 240.11 L312.5 216.57 L311.91 222.44 L312.5 240.11 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M312.5 240.11 L313.48 245.93 L312.5 216.57 L312.5 240.11 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M313.48 245.93 L313.48 210.75 L312.5 216.57 L313.48 245.93 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M313.48 245.93 L314.85 251.67 L313.48 210.75 L313.48 245.93 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.85 251.67 L314.85 205.01 L313.48 210.75 L314.85 251.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M314.85 251.67 L316.6 257.3 L314.85 205.01 L314.85 251.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.6 257.3 L316.6 199.38 L314.85 205.01 L316.6 257.3 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M316.6 257.3 L318.72 262.81 L316.6 199.38 L316.6 257.3 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M318.72 262.81 L318.72 193.87 L316.6 199.38 L318.72 262.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M318.72 262.81 L321.2 268.16 L318.72 193.87 L318.72 262.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.2 268.16 L321.2 188.52 L318.72 193.87 L321.2 268.16 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M321.2 268.16 L324.04 273.34 L321.2 188.52 L321.2 268.16 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M324.04 273.34 L324.04 183.34 L321.2 188.52 L324.04 273.34 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M324.04 273.34 L327.21 278.31 L324.04 183.34 L324.04 273.34 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M327.21 278.31 L327.21 178.37 L324.04 183.34 L327.21 278.31 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M327.21 278.31 L330.71 283.06 L327.21 178.37 L327.21 278.31 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.71 283.06 L330.71 173.62 L327.21 178.37 L330.71 283.06 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M330.71 283.06 L334.52 287.57 L330.71 173.62 L330.71 283.06 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M334.52 287.57 L334.52 169.11 L330.71 173.62 L334.52 287.57 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M334.52 287.57 L338.62 291.81 L334.52 169.11 L334.52 287.57 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.62 291.81 L338.62 164.87 L334.52 169.11 L338.62 291.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M338.62 291.81 L343 295.77 L338.62 164.87 L338.62 291.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343 295.77 L343 160.91 L338.62 164.87 L343 295.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M343 295.77 L347.63 299.42 L343 160.91 L343 295.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M347.63 299.42 L347.63 157.25 L343 160.91 L347.63 299.42 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M347.63 299.42 L352.5 302.76 L347.63 157.25 L347.63 299.42 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.5 302.76 L352.5 153.91 L347.63 157.25 L352.5 302.76 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M352.5 302.76 L357.57 305.77 L352.5 153.91 L352.5 302.76 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.57 305.77 L357.57 150.91 L352.5 153.91 L357.57 305.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M357.57 305.77 L362.84 308.43 L357.57 150.91 L357.57 305.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M362.84 308.43 L362.84 148.25 L357.57 150.91 L362.84 308.43 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M362.84 308.43 L368.27 310.74 L362.84 148.25 L362.84 308.43 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.27 310.74 L368.27 145.94 L362.84 148.25 L368.27 310.74 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M368.27 310.74 L373.85 312.67 L368.27 145.94 L368.27 310.74 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.85 312.67 L373.85 144.01 L368.27 145.94 L373.85 312.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M373.85 312.67 L379.54 314.23 L373.85 144.01 L373.85 312.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.54 314.23 L379.54 142.45 L373.85 144.01 L379.54 314.23 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M379.54 314.23 L385.32 315.41 L379.54 142.45 L379.54 314.23 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M385.32 315.41 L385.32 141.27 L379.54 142.45 L385.32 315.41 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M385.32 315.41 L391.17 316.19 L385.32 141.27 L385.32 315.41 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.17 316.19 L391.17 140.49 L385.32 141.27 L391.17 316.19 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M391.17 316.19 L397.06 316.59 L391.17 140.49 L391.17 316.19 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.06 316.59 L397.06 140.09 L391.17 140.49 L397.06 316.59 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M397.06 316.59 L402.96 316.59 L397.06 140.09 L397.06 316.59 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.96 316.59 L402.96 140.09 L397.06 140.09 L402.96 316.59 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M402.96 316.59 L408.85 316.19 L402.96 140.09 L402.96 316.59 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.85 316.19 L408.85 140.49 L402.96 140.09 L408.85 316.19 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M408.85 316.19 L414.69 315.41 L408.85 140.49 L408.85 316.19 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M414.69 315.41 L414.69 141.27 L408.85 140.49 L414.69 315.41 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M414.69 315.41 L420.48 314.23 L414.69 141.27 L414.69 315.41 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.48 314.23 L420.48 142.45 L414.69 141.27 L420.48 314.23 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M420.48 314.23 L426.17 312.67 L420.48 142.45 L420.48 314.23 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.17 312.67 L426.17 144.01 L420.48 142.45 L426.17 312.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M426.17 312.67 L431.74 310.74 L426.17 144.01 L426.17 312.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M431.74 310.74 L431.74 145.94 L426.17 144.01 L431.74 310.74 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M431.74 310.74 L437.18 308.43 L431.74 145.94 L431.74 310.74 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.18 308.43 L437.18 148.25 L431.74 145.94 L437.18 308.43 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M437.18 308.43 L442.44 305.77 L437.18 148.25 L437.18 308.43 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M442.44 305.77 L442.44 150.91 L437.18 148.25 L442.44 305.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M442.44 305.77 L447.52 302.76 L442.44 150.91 L442.44 305.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.52 302.76 L447.52 153.91 L442.44 150.91 L447.52 302.76 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.52 302.76 L452.38 299.42 L447.52 153.91 L447.52 302.76 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M452.38 299.42 L452.38 157.25 L447.52 153.91 L452.38 299.42 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M452.38 299.42 L457.02 295.77 L452.38 157.25 L452.38 299.42 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.02 295.77 L457.02 160.91 L452.38 157.25 L457.02 295.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.02 295.77 L461.39 291.81 L457.02 160.91 L457.02 295.77 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.39 291.81 L461.39 164.87 L457.02 160.91 L461.39 291.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M461.39 291.81 L465.49 287.57 L461.39 164.87 L461.39 291.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.49 287.57 L465.49 169.11 L461.39 164.87 L465.49 287.57 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M465.49 287.57 L469.3 283.06 L465.49 169.11 L465.49 287.57 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M469.3 283.06 L469.3 173.62 L465.49 169.11 L469.3 283.06 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M469.3 283.06 L472.8 278.31 L469.3 173.62 L469.3 283.06 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M472.8 278.31 L472.8 178.37 L469.3 173.62 L472.8 278.31 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M472.8 278.31 L475.98 273.34 L472.8 178.37 L472.8 278.31 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.98 273.34 L475.98 183.34 L472.8 178.37 L475.98 273.34 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M475.98 273.34 L478.81 268.16 L475.98 183.34 L475.98 273.34 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.81 268.16 L478.81 188.52 L475.98 183.34 L478.81 268.16 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M478.81 268.16 L481.3 262.81 L478.81 188.52 L478.81 268.16 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.3 262.81 L481.3 193.87 L478.81 188.52 L481.3 262.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M481.3 262.81 L483.42 257.3 L481.3 193.87 L481.3 262.81 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.42 257.3 L483.42 199.38 L481.3 193.87 L483.42 257.3 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M483.42 257.3 L485.17 251.67 L483.42 199.38 L483.42 257.3 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.17 251.67 L485.17 205.01 L483.42 199.38 L485.17 251.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M485.17 251.67 L486.54 245.93 L485.17 205.01 L485.17 251.67 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M486.54 245.93 L486.54 210.75 L485.17 205.01 L486.54 245.93 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M486.54 245.93 L487.52 240.11 L486.54 210.75 L486.54 245.93 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.52 240.11 L487.52 216.57 L486.54 210.75 L487.52 240.11 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.52 240.11 L488.11 234.24 L487.52 216.57 L487.52 240.11 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.11 234.24 L488.11 222.44 L487.52 216.57 L488.11 234.24 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("path", { d: "M488.11 234.24 L488.31 228.34 L488.11 222.44 L488.11 234.24 z", fillRule: "evenodd", style: {
  fill: "#38A800"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "311.71,228.34 311.91,222.44 312.5,216.57 313.48,210.75 314.85,205.01 316.6,199.38 318.72,193.87 321.2,188.52 324.04,183.34 327.21,178.37 330.71,173.62 334.52,169.11 338.62,164.87 343,160.91 347.63,157.25 352.5,153.91 357.57,150.91 362.84,148.25 368.27,145.94 373.85,144.01 379.54,142.45 385.32,141.27 391.17,140.49 397.06,140.09 402.96,140.09 408.85,140.49 414.69,141.27 420.48,142.45 426.17,144.01 431.74,145.94 437.18,148.25 442.44,150.91 447.52,153.91 452.38,157.25 457.02,160.91 461.39,164.87 465.49,169.11 469.3,173.62 472.8,178.37 475.98,183.34 478.81,188.52 481.3,193.87 483.42,199.38 485.17,205.01 486.54,210.75 487.52,216.57 488.11,222.44 488.31,228.34 488.11,234.24 487.52,240.11 486.54,245.93 485.17,251.67 483.42,257.3 481.3,262.81 478.81,268.16 475.98,273.34 472.8,278.31 469.3,283.06 465.49,287.57 461.39,291.81 457.02,295.77 452.38,299.42 447.52,302.76 442.44,305.77 437.18,308.43 431.74,310.74 426.17,312.67 420.48,314.23 414.69,315.41 408.85,316.19 402.96,316.59 397.06,316.59 391.17,316.19 385.32,315.41 379.54,314.23 373.85,312.67 368.27,310.74 362.84,308.43 357.57,305.77 352.5,302.76 347.63,299.42 343,295.77 338.62,291.81 334.52,287.57 330.71,283.06 327.21,278.31 324.04,273.34 321.2,268.16 318.72,262.81 316.6,257.3 314.85,251.67 313.48,245.93 312.5,240.11 311.91,234.24 311.71,228.34", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#38A800"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "179.27,79.26 620.75,79.26 400.01,520.75 179.27,79.26", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#38A800"
} }));
export default SvgIogp1117;
