import * as React from "react";
const SvgCircle11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "circle-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 C10 7.9853 7.9853 10 5.5 10 C3.0147 10 1 7.9853 1 5.5 C1 3.0147 3.0147 1 5.5 1 C7.9853 1 10 3.0147 10 5.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 5.5 C10 7.9853 7.9853 10 5.5 10 C3.0147 10 1 7.9853 1 5.5 C1 3.0147 3.0147 1 5.5 1 C7.9853 1 10 3.0147 10 5.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCircle11;
