import { FC } from "react"
import { ExpandLess } from "@mui/icons-material"
import { Collapse, IconButton, Tooltip } from "@mui/material"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import OverflowTip from "components/common/OverflowTip/OverflowTip"
import useDefaultInfoboxStyles from "features/infobox/components/DefaultInfobox/styles"

type Props = {
    collapsed: boolean
    coordinatesPrecision?: number
    featureIndex: number
    lat: number
    long: number
    name: string
    rasterValuePrecision?: number
    value: number
    hasMultipleFeatures: boolean
    toggleFeature: (index: number) => void
}

const RasterFeature: FC<Props> = ({
    collapsed,
    coordinatesPrecision = 6,
    featureIndex,
    lat,
    long,
    name,
    rasterValuePrecision = 2,
    value,
    hasMultipleFeatures,
    toggleFeature,
}) => {
    const classes: any = useDefaultInfoboxStyles()

    const table = (
        <table className={classes.table}>
            <tbody>
                <tr className={classes.property}>
                    <td className={classes.propertyKey}>
                        <CustomTypography className={classes.overflowText} textWeight="bold" variant="body2">
                            {"Coord (lat, lng)"}
                        </CustomTypography>
                    </td>
                    <td className={classes.propertyValue}>
                        <CustomTypography className={classes.overflowText} variant="body2">
                            {lat.toFixed(coordinatesPrecision) + ", " + long.toFixed(coordinatesPrecision)}
                        </CustomTypography>
                    </td>
                </tr>
                <tr className={classes.property}>
                    <td className={classes.propertyKey}>
                        <CustomTypography className={classes.overflowText} textWeight="bold" variant="body2">
                            {"Value"}
                        </CustomTypography>
                    </td>
                    <td className={classes.propertyValue}>
                        <CustomTypography className={classes.overflowText} variant="body2">
                            {value?.toFixed(rasterValuePrecision) ?? "No data"}
                        </CustomTypography>
                    </td>
                </tr>
            </tbody>
        </table>
    )

    return (
        <div className={classes.feature}>
            <div className={classes.featureHeaderContainer}>
                <div className={classes.featureHeader}>
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <IconButton
                                className={classes.innerCollapseBtn}
                                size="small"
                                onClick={_ => toggleFeature(featureIndex)}
                            >
                                <ExpandLess className={collapsed ? classes.expandedLeft : classes.collapsed} />
                            </IconButton>
                        </Tooltip>
                    )}
                    <OverflowTip textWeight="bold" variant="subtitle1">
                        {name}
                    </OverflowTip>
                </div>
            </div>
            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    <div className={classes.borderBox}>{table}</div>
                </Collapse>
            ) : (
                table
            )}
        </div>
    )
}

export default RasterFeature
