import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    activeSession: {
        color: theme.customColors.mutedColor,
        fontSize: 12,
    },
    client: {
        display: "flex",
        justifyContent: "space-between",
    },

    clientLogo: {
        height: 25,
        marginLeft: 8,
    },
    clientName: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 200,
    },
    clients: {
        maxHeight: 290,
        overflowY: "auto",
    },
    selected: {
        backgroundColor: theme.customColors.lightGray,
        cursor: "default",
        padding: "8px 16px",
    },
}))
