import * as React from "react";
const SvgDrinkingWater15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "drinking-water-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 1 L14 1 L14 4 L6.5 4 C6.2239 4 6 4.2239 6 4.5 L6 7 L3 7 L3 3 C3 1.8954 3.8954 1 5 1 z M5.9 11.94 L5.9 11.94 L5.9 11.94 L5.9 11.94 L4.5 9 L3.1100001 11.93 C3.0535 12.1156 3.0166 12.3067 3 12.5 C2.9979 13.3284 3.6678 14.0017 4.4962 14.0038 C5.3246 14.0059 5.9979 13.3361 6 12.5076 C6.0005 12.313 5.9631 12.1203 5.89 11.94 L5.9 11.94 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5 1 L14 1 L14 4 L6.5 4 C6.2239 4 6 4.2239 6 4.5 L6 7 L3 7 L3 3 C3 1.8954 3.8954 1 5 1 z M5.9 11.94 L5.9 11.94 L5.9 11.94 L5.9 11.94 L4.5 9 L3.1100001 11.93 C3.0535 12.1156 3.0166 12.3067 3 12.5 C2.9979 13.3284 3.6678 14.0017 4.4962 14.0038 C5.3246 14.0059 5.9979 13.3361 6 12.5076 C6.0005 12.313 5.9631 12.1203 5.89 11.94 L5.9 11.94 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgDrinkingWater15;
