import { makeStyles } from "@mui/styles"

const useDragAndDropStyles = makeStyles(theme => ({
    dragCursor: {
        borderBottom: "2px solid transparent",
        borderTop: "2px solid transparent",
        cursor: "move",
    },
    dragIndicatorAbove: {
        borderTop: "2px solid " + theme.palette.primary.main + "!important",
    },
    dragIndicatorBelow: {
        borderBottom: "2px solid " + theme.palette.primary.main + "!important",
    },
}))

export default useDragAndDropStyles
