export const columnNumberTypes = {
    bigint: true,
    bigserial: true,
    decimal: true,
    "double precision": true,
    integer: true,
    numeric: true,
    real: true,
    serial: true,
    smallint: true,
}
