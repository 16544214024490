import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    image: {
        height: "5vh",
        maxHeight: 50,
    },
    root: {
        backgroundColor: "rgba(0,0,0,0)",
        left: 10,
        pointerEvents: "none",
        position: "absolute",
        top: 10,
        zIndex: 1,
    },
    rootLegend: {
        display: "flex",
        justifyContent: "center",
        position: "relative",
    },
}))

const MapLogo = ({ logoSrc, modal = false }) => {
    const classes = useStyles()
    return (
        <div className={`${modal ? classes.rootLegend : classes.root}`} id="map-logo">
            <img alt="map-logo" className={classes.image} src={logoSrc} />
        </div>
    )
}

export default MapLogo
