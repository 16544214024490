import * as React from "react";
const SvgInformation15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "information-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C6.7 1 6 1.7 6 2.5 C6 3.3 6.7 4 7.5 4 C8.3 4 9 3.3 9 2.5 C9 1.7 8.3 1 7.5 1 z M4 5 L4 6 C4 6 6 6 6 8 L6 10 C6 12 4 12 4 12 L4 13 L11 13 L11 12 C11 12 9 12 9 10 L9 6 C9 5.5 8.5 5 8 5 L4 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 1 C6.7 1 6 1.7 6 2.5 C6 3.3 6.7 4 7.5 4 C8.3 4 9 3.3 9 2.5 C9 1.7 8.3 1 7.5 1 z M4 5 L4 6 C4 6 6 6 6 8 L6 10 C6 12 4 12 4 12 L4 13 L11 13 L11 12 C11 12 9 12 9 10 L9 6 C9 5.5 8.5 5 8 5 L4 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgInformation15;
