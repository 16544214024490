import * as React from "react";
const SvgReligiousMuslim11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "religious-muslim-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.9 0 C2.2 0 0 2.2 0 4.9 C0 7.6000004 2.2 9.8 4.9 9.8 C5.8 9.8 6.6000004 9.6 7.3 9.2 L6.7 9.2 C4.2999997 9.2 2.3999996 7.2999997 2.3999996 4.8999996 C2.3999996 2.4999995 4.2999997 0.5999994 6.7 0.5999994 L7.2999997 0.5999994 C6.5 0.2 5.8 0 4.9 0 z M8.5 2 L7.6845 3.7 L6 4 L7 5.5 L6.5 7 L8.5 6 L10.5 7 L10 5.5 L11 4 L9.5 3.7 L8.5 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4.9 0 C2.2 0 0 2.2 0 4.9 C0 7.6000004 2.2 9.8 4.9 9.8 C5.8 9.8 6.6000004 9.6 7.3 9.2 L6.7 9.2 C4.2999997 9.2 2.3999996 7.2999997 2.3999996 4.8999996 C2.3999996 2.4999995 4.2999997 0.5999994 6.7 0.5999994 L7.2999997 0.5999994 C6.5 0.2 5.8 0 4.9 0 z M8.5 2 L7.6845 3.7 L6 4 L7 5.5 L6.5 7 L8.5 6 L10.5 7 L10 5.5 L11 4 L9.5 3.7 L8.5 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgReligiousMuslim11;
