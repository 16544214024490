import * as React from "react";
const SvgTownHall11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "town-hall-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L1 2 L1 3 L10 3 L10 2 L5.5 0 z M2 4 L2 8 L1 9 L1 10 L10 10 L10 9 L9 8 L9 4 L2 4 z M3 5 L4 5 L4 8 L3 8 L3 5 z M5 5 L6 5 L6 8 L5 8 L5 5 z M7 5 L8 5 L8 8 L7 8 L7 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 0 L1 2 L1 3 L10 3 L10 2 L5.5 0 z M2 4 L2 8 L1 9 L1 10 L10 10 L10 9 L9 8 L9 4 L2 4 z M3 5 L4 5 L4 8 L3 8 L3 5 z M5 5 L6 5 L6 8 L5 8 L5 5 z M7 5 L8 5 L8 8 L7 8 L7 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTownHall11;
