import makeStyles from "@mui/styles/makeStyles"

export const useZoomLevelStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "hsla(0,0%,100%,.75)",
        borderColor: "#333",
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 1px",
        color: "#333",
        fontWeight: "bold",
    },
    zoomContainer: {
        fontSize: "0.685rem",
        padding: "1px 6px 1px 6px",
    },
}))
