import * as React from "react";
const SvgEmbassy11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "embassy-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 2 C4.6014 2.0766 3.7537 2.4494 3.09 3.06 C3.0316 3.1262 2.9995 3.2117 3 3.3 L3 6.62 C2.9889 6.8074 3.1318 6.9684 3.3193 6.9796 C3.4115 6.985 3.5021 6.9527 3.57 6.89 C4.1239 6.4637 4.8011 6.2286 5.5 6.22 C6.61 6.22 6.85 7 8 7 C8.6869 6.9329 9.3313 6.6371 9.83 6.16 C9.9401 6.0917 10.0051 5.9695 10 5.84 L10 2.37 C10.0309 2.1792 9.9013 1.9994999 9.7104 1.9685999 C9.6387 1.9569 9.5652 1.9679 9.5 2 C9.0686 2.3529 8.5507 2.5842 8 2.67 C6.85 2.67 6.65 2 5.5 2 z M1.5 1.5 C2.0523 1.5 2.5 1.9477 2.5 2.5 C2.5 3.0523 2.0523 3.5 1.5 3.5 C0.9477 3.5 0.5 3.0523 0.5 2.5 C0.5 1.9477 0.9477 1.5 1.5 1.5 z M2 4.5 L2 10.5 C2 10.7761 1.7761 11 1.5 11 C1.2239 11 1 10.7761 1 10.5 L1 4.5 C1 4.2239 1.2239 4 1.5 4 C1.7761 4 2 4.2239 2 4.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 2 C4.6014 2.0766 3.7537 2.4494 3.09 3.06 C3.0316 3.1262 2.9995 3.2117 3 3.3 L3 6.62 C2.9889 6.8074 3.1318 6.9684 3.3193 6.9796 C3.4115 6.985 3.5021 6.9527 3.57 6.89 C4.1239 6.4637 4.8011 6.2286 5.5 6.22 C6.61 6.22 6.85 7 8 7 C8.6869 6.9329 9.3313 6.6371 9.83 6.16 C9.9401 6.0917 10.0051 5.9695 10 5.84 L10 2.37 C10.0309 2.1792 9.9013 1.9994999 9.7104 1.9685999 C9.6387 1.9569 9.5652 1.9679 9.5 2 C9.0686 2.3529 8.5507 2.5842 8 2.67 C6.85 2.67 6.65 2 5.5 2 z M1.5 1.5 C2.0523 1.5 2.5 1.9477 2.5 2.5 C2.5 3.0523 2.0523 3.5 1.5 3.5 C0.9477 3.5 0.5 3.0523 0.5 2.5 C0.5 1.9477 0.9477 1.5 1.5 1.5 z M2 4.5 L2 10.5 C2 10.7761 1.7761 11 1.5 11 C1.2239 11 1 10.7761 1 10.5 L1 4.5 C1 4.2239 1.2239 4 1.5 4 C1.7761 4 2 4.2239 2 4.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgEmbassy11;
