import * as React from "react";
const SvgAirfield15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "airfield-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.8182 0.6818 L4.7727 0.6818 C4.0909 0.6818 4.0909 0 4.7727 0 L10.2272 0 C10.908999 0 10.908999 0.6818 10.2272 0.6818 L8.1818 0.6818 C8.1818 0.6818 9 1.2727001 9 2.6363 L9 4 L15 4 L15 6 L9 8 L8.5 13 L11 14.3182 L11 15 L4 15 L4 14.3182 L6.5 13 L6 8 L0 6 L0 4 L6 4 L6 2.6364 C6 1.2727 6.8182 0.6818 6.8182 0.6818 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.8182 0.6818 L4.7727 0.6818 C4.0909 0.6818 4.0909 0 4.7727 0 L10.2272 0 C10.908999 0 10.908999 0.6818 10.2272 0.6818 L8.1818 0.6818 C8.1818 0.6818 9 1.2727001 9 2.6363 L9 4 L15 4 L15 6 L9 8 L8.5 13 L11 14.3182 L11 15 L4 15 L4 14.3182 L6.5 13 L6 8 L0 6 L0 4 L6 4 L6 2.6364 C6 1.2727 6.8182 0.6818 6.8182 0.6818 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgAirfield15;
