import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer"
import { DatasetProperties } from "features/datasets/models/DatasetProperties"
import AttributeRow from "../AttributeRow/AttributeRow"
import AttributeTableTableHead from "../AttributeTableTableHead/AttributeTableTableHead"
import useAttributeTableStyles from "./styles"

type Props = {
    areAllFeaturesSelected: boolean
    features: DatasetProperties[]
    orderBy: string
    orderDesc: boolean
    isAtLeastOneFeatureSelected: boolean
    isDownloadable: boolean
    isFeatureSelected: (id: number) => boolean
    onFlyToFeature: (boundingBox: string) => void
    onSortBy: (column: string) => void
    onToggleFeature: (id: number) => void
    onToggleSelectAllFeatures: () => void
}

const AttributeTable = ({
    areAllFeaturesSelected,
    features,
    orderBy,
    orderDesc,
    isAtLeastOneFeatureSelected,
    isDownloadable,
    isFeatureSelected,
    onFlyToFeature,
    onSortBy,
    onToggleFeature,
    onToggleSelectAllFeatures,
}: Props) => {
    const classes = useAttributeTableStyles()

    const featureNames = Object.keys(features[0])
        .filter(name => name !== "bounding_box")
        .map(name => ({
            name,
            isNumeric: typeof features[0][name] === "number",
        }))

    return (
        <TableContainer className={classes.tableContainer}>
            <Table
                aria-label="attributes table"
                aria-labelledby="tableTitle"
                className={classes.table}
                size="small"
                stickyHeader
            >
                <AttributeTableTableHead
                    areAllRowsSelected={areAllFeaturesSelected}
                    featureNames={featureNames}
                    orderBy={orderBy}
                    orderDesc={orderDesc}
                    isAtLeastOneRowSelected={isAtLeastOneFeatureSelected}
                    isDownloadable={isDownloadable}
                    onSortBy={onSortBy}
                    onToggleSelectAll={onToggleSelectAllFeatures}
                />
                <TableBody>
                    {features.map((feature, index) => {
                        const { bounding_box, ...rest } = feature

                        return (
                            <AttributeRow
                                key={`row-${index}`}
                                feature={rest}
                                label={`attributes-table-checkbox-${index}`}
                                isChecked={isFeatureSelected(feature.ogc_fid)}
                                isDownloadable={isDownloadable}
                                onFeatureCheckToggle={() => onToggleFeature(feature.ogc_fid)}
                                onRowClick={() => onFlyToFeature(bounding_box)}
                            />
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default AttributeTable
