import * as React from "react";
const SvgRailLight11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-light-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 0 C3.5 0 3.5 0.5 3.5 0.5 C3.5 0.5 3.5 1 4 1 L5 1 L5 1.9707 L4 2 C4 2 3 2 3 3 L3 6 C3 8 5 8 5 8 L6 8 C6 8 8 8 8 6 L8 3 C8 2 7 2 7 2 L6 2 L6 1 L7 1 C7.5 1 7.5 0.5 7.5 0.5 C7.5 0.5 7.5 0 7 0 L4 0 z M5.5 3 L7 3.5 L7 5 L5.5 5 L4 5 L4 3.5 L5.5 3 z M5.5 6 C5.777 6 6 6.223 6 6.5 C6 6.777 5.777 7 5.5 7 C5.223 7 5 6.777 5 6.5 C5 6.223 5.223 6 5.5 6 z M2.834 8.5 L2 11 L3.5 11 L3.834 10 L7.1660004 10 L7.5 11 L9 11 L8.166 8.5 L6.668 8.5 L6.834 9 L4.166 9 L4.332 8.5 L2.834 8.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 0 C3.5 0 3.5 0.5 3.5 0.5 C3.5 0.5 3.5 1 4 1 L5 1 L5 1.9707 L4 2 C4 2 3 2 3 3 L3 6 C3 8 5 8 5 8 L6 8 C6 8 8 8 8 6 L8 3 C8 2 7 2 7 2 L6 2 L6 1 L7 1 C7.5 1 7.5 0.5 7.5 0.5 C7.5 0.5 7.5 0 7 0 L4 0 z M5.5 3 L7 3.5 L7 5 L5.5 5 L4 5 L4 3.5 L5.5 3 z M5.5 6 C5.777 6 6 6.223 6 6.5 C6 6.777 5.777 7 5.5 7 C5.223 7 5 6.777 5 6.5 C5 6.223 5.223 6 5.5 6 z M2.834 8.5 L2 11 L3.5 11 L3.834 10 L7.1660004 10 L7.5 11 L9 11 L8.166 8.5 L6.668 8.5 L6.834 9 L4.166 9 L4.332 8.5 L2.834 8.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRailLight11;
