import baseAtlasApi from "../../store/baseAtlasApi"
import { DprDataset } from "./models/DprDataset"
import { DprDatasetColumns } from "./models/DprDatasetColumns"
import { DprMilestoneHistoryEntry } from "./models/DprMilestoneHistoryEntry"

export const dprIntegrationApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        fetchDprDataset: build.query<DprDataset, string>({
            query: datasetId => ({
                method: "GET",
                url: `dpr/milestones/datasets/${datasetId}`,
            }),
        }),
        fetchDprDatasetColumns: build.query<DprDatasetColumns, string>({
            query: datasetId => ({
                method: "GET",
                url: `dpr/milestones/datasets/${datasetId}/columns`,
            }),
        }),
        fetchLocationStatusHistory: build.query<
            DprMilestoneHistoryEntry[],
            { datasetId: string; locationName: string }
        >({
            query: ({ datasetId, locationName }) => ({
                method: "GET",
                url: `dpr/milestones/datasets/${datasetId}/locations/${locationName}/status/history`,
            }),
        }),
    }),
})

export const {
    useFetchDprDatasetColumnsQuery,
    useFetchDprDatasetQuery,
    useFetchLocationStatusHistoryQuery,
    usePrefetch: useDatasetsPrefetch,
} = dprIntegrationApi
