import * as React from "react";
const SvgFuel15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "fuel-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13 6 L13 6 L13 11.5 C13 11.7761 12.7761 12 12.5 12 C12.2239 12 12 11.7761 12 11.5 L12 9.5 C12 8.6716 11.3284 8 10.5 8 L9 8 L9 2 C9 1.4477 8.5523 1 8 1 L2 1 C1.4477 1 1 1.4477 1 2 L1 13 C1 13.5522995 1.4477 14 2 14 L8 14 C8.5522995 14 9 13.5523 9 13 L9 9 L10.5 9 C10.7761 9 11 9.2239 11 9.5 L11 11.5 C11 12.3284 11.6716 13 12.5 13 C13.3284 13 14 12.3284 14 11.5 L14 5 C14 4.4477 13.5523 4 13 4 L13 4 L13 2.49 C12.9946 2.2178 12.7723 1.9999 12.5 2 C12.2184 2.0047 11.9938 2.2367 11.9985 2.5184 C11.9987 2.5289 11.9992 2.5395 12 2.55 L12 5 C12 5.5523 12.4477 6 13 6 C13.5523 6 14 5.5523 14 5 C14 4.4477 13.5523 4 13 4 M8 6.5 C8 6.7761 7.7761 7 7.5 7 L2.5 7 C2.2239 7 2 6.7761 2 6.5 L2 3.5 C2 3.2239 2.2239 3 2.5 3 L7.5 3 C7.7761 3 8 3.2239 8 3.5 L8 6.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13 6 L13 6 L13 11.5 C13 11.7761 12.7761 12 12.5 12 C12.2239 12 12 11.7761 12 11.5 L12 9.5 C12 8.6716 11.3284 8 10.5 8 L9 8 L9 2 C9 1.4477 8.5523 1 8 1 L2 1 C1.4477 1 1 1.4477 1 2 L1 13 C1 13.5522995 1.4477 14 2 14 L8 14 C8.5522995 14 9 13.5523 9 13 L9 9 L10.5 9 C10.7761 9 11 9.2239 11 9.5 L11 11.5 C11 12.3284 11.6716 13 12.5 13 C13.3284 13 14 12.3284 14 11.5 L14 5 C14 4.4477 13.5523 4 13 4 L13 4 L13 2.49 C12.9946 2.2178 12.7723 1.9999 12.5 2 C12.2184 2.0047 11.9938 2.2367 11.9985 2.5184 C11.9987 2.5289 11.9992 2.5395 12 2.55 L12 5 C12 5.5523 12.4477 6 13 6 C13.5523 6 14 5.5523 14 5 C14 4.4477 13.5523 4 13 4 M8 6.5 C8 6.7761 7.7761 7 7.5 7 L2.5 7 C2.2239 7 2 6.7761 2 6.5 L2 3.5 C2 3.2239 2.2239 3 2.5 3 L7.5 3 C7.7761 3 8 3.2239 8 3.5 L8 6.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgFuel15;
