import React, { FC } from "react"
import { useVirtualizer } from "@tanstack/react-virtual"
import { StyledContainerDiv, StyledInnerContainerDiv, StyledVirtualItemsContainerDiv } from "./VirtualizedList.styles"

export type VirtualizedListProps = {
    width: number
    height: number
    enabled?: boolean
    children: Array<React.ReactNode>
}

const VirtualizedList: FC<VirtualizedListProps> = ({
    enabled = true,
    width,
    height,
    children = [],
}: VirtualizedListProps) => {
    const parentRef = React.useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: children.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 45, // this is required for the first render only
        enabled,
    })

    const items = virtualizer.getVirtualItems()

    return (
        <StyledContainerDiv width={width} height={height} ref={parentRef}>
            <StyledInnerContainerDiv height={virtualizer.getTotalSize()}>
                <StyledVirtualItemsContainerDiv startYPosition={items[0]?.start}>
                    {items.map(({ index, key }) => (
                        <div key={key} data-index={index} ref={virtualizer.measureElement}>
                            {children?.[index] || null}
                        </div>
                    ))}
                </StyledVirtualItemsContainerDiv>
            </StyledInnerContainerDiv>
        </StyledContainerDiv>
    )
}

export default VirtualizedList
