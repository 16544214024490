import React from "react"
import { Checkbox } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

function BooleanProperty(props) {
    function handleChange(e) {
        props.onPropertyChanged(e.target.checked)
    }

    return (
        <div className="property">
            <div className="property-content">
                {props.editable && (
                    <TextField className="textfield" value={props.title} onChange={props.onTitleChanged} />
                )}
                {!props.editable && (
                    <Typography className="name" variant="body1">
                        {props.title}
                    </Typography>
                )}
                <div className="checkbox-container">
                    <FormControl className="property-boolean" variant="filled">
                        <Checkbox checked={props.value} value={props.value} onChange={handleChange}></Checkbox>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export default BooleanProperty
