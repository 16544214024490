import { FC, useEffect, useMemo } from "react"
import { MetadataType } from "model/enums/MetadataType"
import { MetadataProperty as MetadataPropertyType, Tag } from "model/metadata/MetadataProperty"
import { TagValue, TagWithValue } from "model/metadata/MetadataPropertyValue"
import { useDispatch } from "react-redux"
import * as layerActions from "actions/layerSelector"
import * as metadataSchemaActions from "actions/metadataSchema"
import CustomDialog from "components/common/CustomModal/CustomModal"
import ErrorPlaceholder from "components/common/ErrorPlaceholder/ErrorPlaceholder"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import { METADATA_SCHEMA_ID } from "constants/metadata"
import { getLayerMetadata, getLayerMetadataLoading } from "selectors/layerSelector"
import { getMetadataSchema, getMetadataSchemaLoading } from "selectors/metadataSchema"
import { useAppSelector } from "store/hooks/useAppSelector"
import MetadataProperty from "../MetadataProperty/MetadataProperty"
import useStyles from "./styles"

type Props = {
    datasetId: string
    datasetName: string
    open: boolean
    onClose: () => void
}

const MetadataModal: FC<Props> = ({ datasetId, datasetName, open, onClose }) => {
    const classes = useStyles()

    const metadataSchema = useAppSelector(getMetadataSchema)
    const metadataSchemaLoading = useAppSelector(getMetadataSchemaLoading)
    const layerMetadata = useAppSelector(getLayerMetadata)
    const layerMetadataLoading = useAppSelector(getLayerMetadataLoading)

    const dispatch = useDispatch()

    useEffect(() => {
        if (open) {
            dispatch(metadataSchemaActions.getMetadataSchema(METADATA_SCHEMA_ID))
            dispatch(layerActions.getLayerMetadata(datasetId))
        }
    }, [open])

    const hasNoMetadata = metadataSchema.length === 0 || layerMetadata.length === 0
    const loading = metadataSchemaLoading || layerMetadataLoading

    const mergeTags = (propertyTags: Tag[], valueTags: TagValue[]): TagWithValue[] => {
        const tagValueDict = valueTags.reduce((acc: Record<string, boolean>, tagValue) => {
            acc[tagValue.id] = tagValue.enabled
            return acc
        }, {})

        return propertyTags.map(tag => {
            return {
                ...tag,
                enabled: tagValueDict[tag.id],
            }
        })
    }

    const mergeAndFilterMetadata = (): MetadataPropertyType[] => {
        if (hasNoMetadata || loading) return []

        const layerMetadataDict = layerMetadata.reduce((acc: Record<string, any>, property) => {
            acc[property.id] = property.value
            return acc
        }, {})

        return metadataSchema
            .filter(property => !property.hidden)
            .map(property => {
                const value = layerMetadataDict[property.id]

                return {
                    ...property,
                    value: property.type === MetadataType.TagList ? mergeTags(property.value as Tag[], value) : value,
                }
            })
            .filter(property => {
                switch (property.type) {
                    case MetadataType.TagList:
                        return (property.value as TagWithValue[]).filter(tag => tag.enabled).length !== 0
                    case MetadataType.Number:
                    case MetadataType.Date:
                    case MetadataType.Text:
                        return property.value !== "" && property.value !== null
                    default:
                        return false
                }
            })
    }

    const mergedMetadata = useMemo(() => mergeAndFilterMetadata(), [layerMetadata, metadataSchema])
    const dialogTitle = `Metadata of ${datasetName}`

    return (
        <CustomDialog dialogTitle={dialogTitle} hideActionContainer isOpen={open} handleClose={onClose}>
            <LoadingPlaceholder loading={loading} message="Loading layer metadata">
                <ErrorPlaceholder
                    error={hasNoMetadata}
                    message="No metadata available for the current layer"
                    onTryAgain={null}
                >
                    <div className={classes.metadataContainer}>
                        {mergedMetadata.map(property => (
                            <MetadataProperty key={property.id} property={property} />
                        ))}
                    </div>
                </ErrorPlaceholder>
            </LoadingPlaceholder>
        </CustomDialog>
    )
}

export default MetadataModal
