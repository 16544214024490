import React from "react"
import { Grid, Stack } from "@mui/material"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import ColorContainer from "../styleProperties/colorProperty/colorContainer"
import ColorTitle from "../styleProperties/colorProperty/colorTitle"
import { getIsLayerTypeRaster } from "@windgis/shared"

const ColorDragAndDrop = ({
    interpolate,
    layerType,
    numOfPropertiesWhenLastCase = 0,
    properties,
    property,
    propertyIndex,
    isZoomDependent,
    onExpressionPropertyChanged,
    onPropertyTitleChanged,
    onPropertyValueChanged,
    onRemoveGraduateColorProperty
}) => {
    const { max, min, name, options, propertyType, value } = property

    const dragAndDropColor = (array, fromIndex, toIndex) => {
        const newArray = [...array]

        if (fromIndex === null || toIndex === null) return newArray

        if (fromIndex > toIndex) {
            for (let i = toIndex; i <= fromIndex - 2; i += 2) {
                newArray[i + 2] = array[i]
            }
        } else {
            for (let i = toIndex; i >= fromIndex + 2; i -= 2) {
                newArray[i - 2] = array[i]
            }
        }
        newArray[toIndex] = array[fromIndex]

        return newArray
    }

    const onDragColorEndMatchExpr = result => {
        let valueArray = [...property.value]

        const to = result.destination.index * 2 + 1
        const from = Number(result.source.index) * 2 + 1

        if (to >= valueArray.length) return

        valueArray = dragAndDropColor(valueArray, from, to)

        onPropertyValueChanged(valueArray)
    }

    const onDragColorEndInterpolateExpr = result => {
        let valueArray = [...property.value]

        const to = (result.destination.index + 2) * 2
        const from = (Number(result.source.index) + 2) * 2

        if (to >= valueArray.length) return

        valueArray = dragAndDropColor(valueArray, from, to)

        onPropertyValueChanged(valueArray)
    }

    return (
        <DragDropContext onDragEnd={interpolate ? onDragColorEndInterpolateExpr : onDragColorEndMatchExpr}>
            <Grid container>
                <Grid item xs>
                    <Stack gap={1}>
                        {properties.map((prop, index) => {
                            const isDefaultValue =
                                !interpolate && !getIsLayerTypeRaster(layerType) && index === properties.length - 1

                            const { _, expressionArrayIndex, keyIndex, title, value } = prop
                            const propertyProps = {
                                key: expressionArrayIndex,
                                editable: !getIsLayerTypeRaster(layerType),
                                index: expressionArrayIndex / 2,
                                title,
                                value,
                                isDefaultValue,
                                isZoomDependent,
                                onPropertyChanged: value =>
                                    onExpressionPropertyChanged(expressionArrayIndex, type, value, isDefaultValue),
                                onTitleChanged: newTitle =>
                                    onPropertyTitleChanged(newTitle, keyIndex, expressionArrayIndex),
                            }
                            const isLastRemainingCase = interpolate
                                ? properties.length === 1
                                : !getIsLayerTypeRaster(layerType) && properties.length === numOfPropertiesWhenLastCase
                            const onlyTwoInterpolatedValuesRemaining = propertyIndex === 2
                            const notRemovable = interpolate
                                ? isLastRemainingCase || onlyTwoInterpolatedValuesRemaining
                                : isLastRemainingCase

                            return (
                                <ColorTitle
                                    onRemove={!notRemovable && (() => onRemoveGraduateColorProperty(keyIndex))}
                                    {...propertyProps}
                                />
                            )
                        })}
                    </Stack>
                </Grid>
                <Grid item xs={"auto"}>
                    <Stack>
                        <Droppable droppableId="colorPopoverDroppable" type={"colorPopoverDroppable"}>
                            {provided => (
                                <Stack gap={1} ref={provided.innerRef} {...provided.droppableProps}>
                                    {properties.map((prop, index) => {
                                        const isDefaultValue =
                                            !interpolate && !getIsLayerTypeRaster(layerType) && index === properties.length - 1
                                        const { _0, _1, expressionArrayIndex, value } = prop

                                        return (
                                            <ColorContainer
                                                key={expressionArrayIndex}
                                                index={interpolate ? index : expressionArrayIndex / 2}
                                                value={value}
                                                isDefaultValue={isDefaultValue}
                                                draggable={!getIsLayerTypeRaster(layerType)}
                                                onPropertyChanged={value =>
                                                    onExpressionPropertyChanged(
                                                        expressionArrayIndex,
                                                        propertyType,
                                                        value,
                                                        isDefaultValue,
                                                    )
                                                }
                                            />
                                        )
                                    })}
                                    {provided.placeholder}
                                </Stack>
                            )}
                        </Droppable>
                    </Stack>
                </Grid>
            </Grid>
        </DragDropContext>
    )
}

export default ColorDragAndDrop
