import * as React from "react";
const SvgIogp1030 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "290.35, 201.61, 165.38, 165.37999", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "373.04,284.3 373.04,284.3", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#828282"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "292.63,284.3 292.83,278.69 293.41,273.11 294.39,267.59 295.75,262.14 297.48,256.8 299.58,251.6 302.04,246.55 304.85,241.69 307.99,237.04 311.44,232.62 315.2,228.45 319.24,224.55 323.53,220.94 328.08,217.64 332.83,214.67 337.79,212.04 342.92,209.75 348.19,207.83 353.59,206.29 359.07,205.12 364.63,204.34 370.23,203.95 375.84,203.95 381.44,204.34 387,205.12 392.49,206.29 397.88,207.83 403.16,209.75 408.28,212.04 413.24,214.67 418,217.64 422.54,220.94 426.84,224.55 430.88,228.45 434.63,232.62 438.09,237.04 441.22,241.69 444.03,246.55 446.49,251.6 448.59,256.8 450.33,262.14 451.68,267.59 452.66,273.11 453.25,278.69 453.44,284.3 453.25,289.91 452.66,295.49 451.68,301.02 450.33,306.47 448.59,311.8 446.49,317.01 444.03,322.05 441.22,326.91 438.09,331.56 434.63,335.99 430.88,340.16 426.84,344.06 422.54,347.66 418,350.96 413.24,353.94 408.28,356.57 403.16,358.85 397.88,360.77 392.49,362.32 387,363.49 381.44,364.27 375.84,364.66 370.23,364.66 364.63,364.27 359.07,363.49 353.59,362.32 348.19,360.77 342.92,358.85 337.79,356.57 332.83,353.94 328.08,350.96 323.53,347.66 319.24,344.06 315.2,340.16 311.44,335.99 307.99,331.56 304.85,326.91 302.04,322.05 299.58,317.01 297.48,311.8 295.75,306.47 294.39,301.02 293.41,295.49 292.83,289.91 292.63,284.3", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#828282"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "290.6,366.74 455.48,201.86", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#828282"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "455.48,366.74 290.6,201.86", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#828282"
} }));
export default SvgIogp1030;
