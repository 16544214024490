import * as React from "react";
const SvgMuseum15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "museum-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L1 3.4453 L1 4 L14 4 L14 3.4453 L7.5 0 z M2 5 L2 10 L1 11.5547 L1 13 L14 13 L14 11.5547 L13 10 L13 5 L2 5 z M4.6152 6 C4.7842 5.9977 4.947 6.0639 5.0664 6.1836 L7.5 8.6172 L9.933599 6.1836 C10.1781 5.9399 10.573799 5.9404 10.817599 6.1849 C10.9341 6.3017 10.9997 6.46 11 6.625 L11 10.8672 C11.0049 11.2123995 10.729 11.4962 10.3838005 11.500999 C10.038601 11.505899 9.754801 11.23 9.75 10.8848 C9.7499 10.8789 9.7499 10.873 9.75 10.8671 L9.75 8.1328 L7.9414 9.9414 C7.6974 10.1847 7.3026 10.1847 7.0586 9.9414 L5.25 8.1328 L5.25 10.8672 C5.2549 11.2123995 4.979 11.4962 4.6338 11.500999 C4.2887 11.5059 4.0049 11.2301 4 10.8849 C3.9999 10.879 3.9999 10.8731 4 10.8672 L4 6.625 C4 6.2836 4.2739 6.0054 4.6152 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5 0 L1 3.4453 L1 4 L14 4 L14 3.4453 L7.5 0 z M2 5 L2 10 L1 11.5547 L1 13 L14 13 L14 11.5547 L13 10 L13 5 L2 5 z M4.6152 6 C4.7842 5.9977 4.947 6.0639 5.0664 6.1836 L7.5 8.6172 L9.933599 6.1836 C10.1781 5.9399 10.573799 5.9404 10.817599 6.1849 C10.9341 6.3017 10.9997 6.46 11 6.625 L11 10.8672 C11.0049 11.2123995 10.729 11.4962 10.3838005 11.500999 C10.038601 11.505899 9.754801 11.23 9.75 10.8848 C9.7499 10.8789 9.7499 10.873 9.75 10.8671 L9.75 8.1328 L7.9414 9.9414 C7.6974 10.1847 7.3026 10.1847 7.0586 9.9414 L5.25 8.1328 L5.25 10.8672 C5.2549 11.2123995 4.979 11.4962 4.6338 11.500999 C4.2887 11.5059 4.0049 11.2301 4 10.8849 C3.9999 10.879 3.9999 10.8731 4 10.8672 L4 6.625 C4 6.2836 4.2739 6.0054 4.6152 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgMuseum15;
