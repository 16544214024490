import * as React from "react";
const SvgVolcano15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "volcano-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.4844 1.0002 C8.337999 1.0052 8.2009 1.0733 8.1094 1.1877 L6.4492 3.2619 L4.8438 1.7385 C4.4079 1.3374 3.7599 1.893 4.0899 2.385 L5.7559 4.7854 C5.9472 5.061 6.3503 5.0737 6.5586 4.8108 C6.7249 4.6009 7 4.133 7.5 4.133 C8 4.133 8.2929 4.6237 8.4414 4.8107 C8.6164 5.0311 8.9387 5.0638 9.1543 4.883 L10.8223 3.4825997 C11.2631 3.1084998 10.8229 2.4090996 10.295 2.6446998 L9 3.2268 L9 1.5002 C9.0002 1.2179 8.7666 0.9915 8.4844 1.0002 L8.4844 1.0002 z M5 6.0002 L2.0762 11.924 C1.9993 12.0009 2 12.155 2 12.3088 C2 12.8473 2.3837 13.000199 2.6914 13.000199 L12.308599 13.000199 C12.693199 13.000199 12.999999 12.847199 12.999999 12.3088 C12.999999 12.155 13.000799 12.0779 12.9237995 11.924 L10 6.0002 C9.5 6.0002 9 6.5002 9 7.0002 L9 7.5002 C9 7.7771997 8.777 8.0002 8.5 8.0002 C8.223 8.0002 8 7.7772 8 7.5002 L8 7.0002 C8 6.7240996 7.7762 6.5002 7.5 6.5002 C7.2238 6.5002 7 6.7241 7 7.0002 L7 9.0002 C7 9.277201 6.777 9.5002 6.5 9.5002 C6.223 9.5002 6 9.2772 6 9.0002 L6 7.0002003 C6 6.5002 5.5 6.0002 5 6.0002 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.4844 1.0002 C8.337999 1.0052 8.2009 1.0733 8.1094 1.1877 L6.4492 3.2619 L4.8438 1.7385 C4.4079 1.3374 3.7599 1.893 4.0899 2.385 L5.7559 4.7854 C5.9472 5.061 6.3503 5.0737 6.5586 4.8108 C6.7249 4.6009 7 4.133 7.5 4.133 C8 4.133 8.2929 4.6237 8.4414 4.8107 C8.6164 5.0311 8.9387 5.0638 9.1543 4.883 L10.8223 3.4825997 C11.2631 3.1084998 10.8229 2.4090996 10.295 2.6446998 L9 3.2268 L9 1.5002 C9.0002 1.2179 8.7666 0.9915 8.4844 1.0002 L8.4844 1.0002 z M5 6.0002 L2.0762 11.924 C1.9993 12.0009 2 12.155 2 12.3088 C2 12.8473 2.3837 13.000199 2.6914 13.000199 L12.308599 13.000199 C12.693199 13.000199 12.999999 12.847199 12.999999 12.3088 C12.999999 12.155 13.000799 12.0779 12.9237995 11.924 L10 6.0002 C9.5 6.0002 9 6.5002 9 7.0002 L9 7.5002 C9 7.7771997 8.777 8.0002 8.5 8.0002 C8.223 8.0002 8 7.7772 8 7.5002 L8 7.0002 C8 6.7240996 7.7762 6.5002 7.5 6.5002 C7.2238 6.5002 7 6.7241 7 7.0002 L7 9.0002 C7 9.277201 6.777 9.5002 6.5 9.5002 C6.223 9.5002 6 9.2772 6 9.0002 L6 7.0002003 C6 6.5002 5.5 6.0002 5 6.0002 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgVolcano15;
