import * as React from "react";
const SvgBeer11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "beer-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 C3.2 1 2.5 1.6600001 2.5 1.6600001 L2.5 3.66 C2.5328 4.5797 2.7577 5.4823 3.16 6.3100004 C3.536 7.2746005 3.536 8.3454 3.16 9.31 C3.16 9.31 3.16 9.97 5.48 9.97 C7.8 9.97 7.8 9.31 7.8 9.31 C7.4240003 8.345401 7.4240003 7.2746005 7.8 6.3100004 C8.2023 5.4823003 8.4272 4.5797005 8.46 3.6600003 L8.46 1.6600003 C8.46 1.66 7.8 1 5.5 1 z M5.5 9.28 C4.9736 9.3066 4.4465 9.2458 3.94 9.1 C4.0726 8.621301 4.1399 8.126801 4.14 7.63 L6.8599997 7.63 C6.8455997 7.8498 6.8455997 8.0702 6.8599997 8.29 C6.8939 8.5635 6.9474 8.8342 7.02 9.1 C6.5269 9.2448 6.0136 9.309 5.5 9.29 L5.5 9.28 z M7.82 3.28 C6.3084 3.7224998 4.7016 3.7224998 3.19 3.28 L3.18 2 C4.6951 1.5578 6.3049 1.5578 7.8199997 2 C7.8199997 2 7.85 3.33 7.8199997 3.33 L7.82 3.28 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.5 1 C3.2 1 2.5 1.6600001 2.5 1.6600001 L2.5 3.66 C2.5328 4.5797 2.7577 5.4823 3.16 6.3100004 C3.536 7.2746005 3.536 8.3454 3.16 9.31 C3.16 9.31 3.16 9.97 5.48 9.97 C7.8 9.97 7.8 9.31 7.8 9.31 C7.4240003 8.345401 7.4240003 7.2746005 7.8 6.3100004 C8.2023 5.4823003 8.4272 4.5797005 8.46 3.6600003 L8.46 1.6600003 C8.46 1.66 7.8 1 5.5 1 z M5.5 9.28 C4.9736 9.3066 4.4465 9.2458 3.94 9.1 C4.0726 8.621301 4.1399 8.126801 4.14 7.63 L6.8599997 7.63 C6.8455997 7.8498 6.8455997 8.0702 6.8599997 8.29 C6.8939 8.5635 6.9474 8.8342 7.02 9.1 C6.5269 9.2448 6.0136 9.309 5.5 9.29 L5.5 9.28 z M7.82 3.28 C6.3084 3.7224998 4.7016 3.7224998 3.19 3.28 L3.18 2 C4.6951 1.5578 6.3049 1.5578 7.8199997 2 C7.8199997 2 7.85 3.33 7.8199997 3.33 L7.82 3.28 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBeer11;
