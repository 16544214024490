import { FC, useMemo, useState } from "react"
import PublicIcon from "@mui/icons-material/Public"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import { Divider, FormControl, MenuItem, MenuList, Select, Skeleton } from "@mui/material"
import { App } from "model/app/App"
import { useHistory, useParams } from "react-router-dom"
import ButtonLink from "components/common/ButtonLink/ButtonLink"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import OverflowTip from "components/common/OverflowTip/OverflowTip"
import CustomChip from "components/CustomChip/CustomChip"
import config from "config"
import { clientIdParam } from "constants/map/queryParams"
import { CoreClient } from "features/core/models/CoreClient"
import { getClientById } from "features/core/selectors"
import { getAppsSelector } from "selectors/appsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"

type Props = {
    appName?: string
}

type Params = {
    clientId: string
    projectId: string
}

const AppsPicker: FC<Props> = ({ appName }) => {
    const classes = useStyles()

    const [open, setOpen] = useState(false)

    const apps: App[] = useAppSelector(getAppsSelector)

    const { clientId, projectId } = useParams<Params>()
    const currentClient: CoreClient | undefined = useAppSelector(getClientById(clientId))

    const currentProject = currentClient?.projects?.find(x => x.id === projectId)

    const history = useHistory()

    const getPathname = (app: any) => {
        const publicRoute = app.public ? "public/" : ""
        const pathname = `/${clientId}/${projectId}/${publicRoute}${app.id}/map/layer-selector`
        if (history.location.pathname !== pathname) return pathname
        return "#"
    }

    const getLandingPathname = () => {
        const queryParams = new URLSearchParams()
        queryParams.append(clientIdParam, clientId)
        return `${config.landingUrl}?${queryParams.toString()}`
    }

    const renderValue = () => {
        return (
            <div className={classes.appAndProject}>
                <OverflowTip className={classes.appAndProjectNames} variant="body2">
                    {currentProject?.abbreviation ?? ""} / {appName}
                </OverflowTip>
                {!!currentProject?.logoUrl && <img alt="" height={25} src={currentProject.logoUrl} />}
            </div>
        )
    }

    const renderMenuItem = (app: App) => {
        return (
            <MenuItem key={app.id} selected={app.name === appName} value={app.name} onClick={() => setOpen(false)}>
                <ButtonLink className={classes.link} to={getPathname(app)}>
                    <div className={classes.overflowText}>
                        <OverflowTip className={classes.appName} variant="body2">
                            {app.name}
                        </OverflowTip>
                    </div>
                    {app.public && (
                        <CustomChip className={classes.blueChip} expanded={false} Icon={PublicIcon} label="Public" />
                    )}
                </ButtonLink>
            </MenuItem>
        )
    }

    const sortByModified = (app1: App, app2: App) => {
        return new Date(app2.modifiedUtc).getTime() - new Date(app1.modifiedUtc).getTime()
    }

    const filteredAndSortedApps = useMemo(() => apps.sort(sortByModified), [apps])

    return appName && !!filteredAndSortedApps.length ? (
        <FormControl className={classes.formControl}>
            <Select
                className={classes.appsMenu}
                displayEmpty
                id="apps-menu-select"
                open={open}
                value=""
                variant="standard"
                disableUnderline
                renderValue={renderValue}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <div className={classes.project}>
                    <div>
                        <OverflowTip fontSize="inherit" textWeight="bold" variant="caption">
                            {currentProject?.name}
                        </OverflowTip>
                        <CustomTypography color="muted" fontSize="inherit" variant="caption">
                            ({currentProject?.abbreviation})
                        </CustomTypography>
                    </div>
                    <ButtonLink className={classes.switchProject} to={getLandingPathname()}>
                        <SwapHorizIcon fontSize="small" />
                        <CustomTypography color="inherit" fontSize="inherit" textWeight="semibold" variant="caption">
                            Switch Project
                        </CustomTypography>
                    </ButtonLink>
                </div>
                <Divider />

                <MenuList className={classes.apps}>{filteredAndSortedApps.map(app => renderMenuItem(app))}</MenuList>

                <Divider />
            </Select>
        </FormControl>
    ) : (
        <Skeleton className={classes.formControl} height={35} variant="rectangular" />
    )
}

export default AppsPicker
