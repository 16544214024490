import * as React from "react";
const SvgCinema15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cinema-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 7.5 L14 9.5 C14 9.7761 13.7761 10 13.5 10 C13.2239 10 13 9.7761 13 9.5 C13 9.5 13.06 9 12 9 L11 9 L11 11.5 C11 11.7761 10.7761 12 10.5 12 L2.5 12 C2.2239 12 2 11.7761 2 11.5 L2 7.5 C2 7.2239 2.2239 7 2.5 7 L10.5 7 C10.7761 7 11 7.2239 11 7.5 L11 8 L12 8 C13.059999 8 13 7.5 13 7.5 C13 7.2239 13.2239 7 13.5 7 C13.7761 7 14 7.2239 14 7.5 z M4 3 C2.8954 3 2 3.8954 2 5 C2 6.1046 2.8954 7 4 7 C5.1046 7 6 6.1046 6 5 C6 3.8954 5.1046 3 4 3 z M4 6 C3.4477 6 3 5.5523 3 5 C3 4.4477 3.4477 4 4 4 C4.5523 4 5 4.4477 5 5 C5 5.5523 4.5523 6 4 6 z M8.5 2 C7.1193 2 6 3.1193 6 4.5 C6 5.8807 7.1193 7 8.5 7 C9.8807 7 11 5.8807 11 4.5 C11 3.1193 9.8807 2 8.5 2 z M8.5 6 C7.6716 6 7 5.3284 7 4.5 C7 3.6715999 7.6716 3 8.5 3 C9.3284 3 10 3.6716 10 4.5 C10 5.3283997 9.3284 6 8.5 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M14 7.5 L14 9.5 C14 9.7761 13.7761 10 13.5 10 C13.2239 10 13 9.7761 13 9.5 C13 9.5 13.06 9 12 9 L11 9 L11 11.5 C11 11.7761 10.7761 12 10.5 12 L2.5 12 C2.2239 12 2 11.7761 2 11.5 L2 7.5 C2 7.2239 2.2239 7 2.5 7 L10.5 7 C10.7761 7 11 7.2239 11 7.5 L11 8 L12 8 C13.059999 8 13 7.5 13 7.5 C13 7.2239 13.2239 7 13.5 7 C13.7761 7 14 7.2239 14 7.5 z M4 3 C2.8954 3 2 3.8954 2 5 C2 6.1046 2.8954 7 4 7 C5.1046 7 6 6.1046 6 5 C6 3.8954 5.1046 3 4 3 z M4 6 C3.4477 6 3 5.5523 3 5 C3 4.4477 3.4477 4 4 4 C4.5523 4 5 4.4477 5 5 C5 5.5523 4.5523 6 4 6 z M8.5 2 C7.1193 2 6 3.1193 6 4.5 C6 5.8807 7.1193 7 8.5 7 C9.8807 7 11 5.8807 11 4.5 C11 3.1193 9.8807 2 8.5 2 z M8.5 6 C7.6716 6 7 5.3284 7 4.5 C7 3.6715999 7.6716 3 8.5 3 C9.3284 3 10 3.6716 10 4.5 C10 5.3283997 9.3284 6 8.5 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCinema15;
