import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"

export const PortalComponent = props => {
    const el = useRef(document.createElement("div"))
    useEffect(() => {
        const portal = document.getElementById("map-table")
        portal.appendChild(el.current)
        const currEl = el.current
        return () => {
            portal.removeChild(currEl)
        }
    }, [props.children])

    return ReactDOM.createPortal(props.children, el.current)
}

export const PortalDiv = () => <div id="map-table"></div>
