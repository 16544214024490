import * as yup from "yup"
import { MIN_PERCENTILE_ELEMENTS, MIN_WEATHER_WINDOWS_ELEMENTS } from "../constants"
import { ZanderParameter } from "../models/ZanderParameter"
import { minArraySizeErrorMessage } from "./common"

const hasAtLeastOneLimit = (parameters: ZanderParameter[]) => parameters.some(p => p.maxLimits.length > 0)

const sheetsSchema = yup.array().of(
    yup.object({
        endHour: yup.number(),
        parameters: yup
            .array()
            .test("at least one criteria test", "Must provide at least one limit", hasAtLeastOneLimit),
        percentiles: yup.array().min(MIN_PERCENTILE_ELEMENTS, minArraySizeErrorMessage(MIN_PERCENTILE_ELEMENTS)),
        weatherWindowDurations: yup
            .array()
            .min(MIN_WEATHER_WINDOWS_ELEMENTS, minArraySizeErrorMessage(MIN_WEATHER_WINDOWS_ELEMENTS)),
        startHour: yup.number(),
    }),
)

export default sheetsSchema
