import { FC } from "react"
import { MapIds } from "../../model/enums/MapIds"
import { useMapHandlers } from "../customHooks/map/useMapHandlers"

type WithMapHandlersParams = {
    mapId: MapIds
}

export const withMapHandlers =
    <P extends object>(WrappedComponent: FC<P>, { mapId }: WithMapHandlersParams) =>
    (props: P) => {
        const handlers = useMapHandlers({ mapId })

        return <WrappedComponent {...handlers} {...props} />
    }
