import { memo, useMemo, useState } from "react"
import SpeedIcon from "@mui/icons-material/Speed"
import { Box, IconButton, Popover, Slider, Stack, Typography } from "@mui/material"
import { PlaybackSpeed } from "../../models/PlaybackSpeed"

type Props = {
    speed: PlaybackSpeed
    onChange: (newSpeed: PlaybackSpeed) => void
}

const PlaybackSpeedSelect = ({ speed, onChange }: Props) => {
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null)
    const [value, setValue] = useState(speed)

    const [min, max] = useMemo(() => {
        const values = Object.values(PlaybackSpeed).filter(v => typeof v === "number")

        return [values[0] as number, values[values.length - 1] as number]
    }, [])

    return (
        <>
            <IconButton color="primary" onClick={e => setAnchorElement(e.currentTarget)}>
                <SpeedIcon />
            </IconButton>
            <Popover
                anchorEl={anchorElement}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
                open={Boolean(anchorElement)}
                transformOrigin={{
                    horizontal: "center",
                    vertical: "bottom",
                }}
                onClose={() => setAnchorElement(null)}
            >
                <Box height={200} padding={1} width={50}>
                    <Stack alignItems="center" gap={1} height="100%">
                        <Typography variant="subtitle2">Faster</Typography>
                        <Slider
                            marks
                            max={max}
                            min={min}
                            orientation={"vertical"}
                            size={"medium"}
                            value={value}
                            onChange={(_, value) => setValue(value as number)}
                            onChangeCommitted={(_, value) => onChange(value as number)}
                        />
                        <Typography variant="subtitle2"> Slower </Typography>
                    </Stack>
                </Box>
            </Popover>
        </>
    )
}

export default memo(PlaybackSpeedSelect)
