import * as React from "react";
const SvgBus15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "bus-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 0 C2.6364 0 1 0.7433 1 2.7461 L1 8.1992 L1 12 C1 12 1 13 2 13 L2 14 C2 14 2 15 3 15 C4 15 4 14 4 14 L4 13 L11 13 L11 14 C11 14 11 15 12 15 C13 15 13 14 13 14 L13 13 C13 13 14 13 14 12 L14 2.7461 C14 0.7006 12.764 0 11.4004 0 L4 0 z M4.25 1.5 L10.75 1.5 C10.8885 1.5 11 1.6115 11 1.75 C11 1.8885 10.8885 2 10.75 2 L4.25 2 C4.1115 2 4 1.8885 4 1.75 C4 1.6115 4.1115 1.5 4.25 1.5 z M3 3 L12 3 C13 3 13 3.9668 13 3.9668 L13 7 C13 7 13 8 12 8 L3 8 C2 8 2 7 2 7 L2 4 C2 4 2 3 3 3 z M3 10 C3.5523 10 4 10.4477 4 11 C4 11.5523 3.5523 12 3 12 C2.4477 12 2 11.5523 2 11 C2 10.4477 2.4477 10 3 10 z M12 10 C12.5522995 10 13 10.4477 13 11 C13 11.5523 12.5523 12 12 12 C11.4477 12 11 11.5523 11 11 C11 10.4477 11.4477 10 12 10 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M4 0 C2.6364 0 1 0.7433 1 2.7461 L1 8.1992 L1 12 C1 12 1 13 2 13 L2 14 C2 14 2 15 3 15 C4 15 4 14 4 14 L4 13 L11 13 L11 14 C11 14 11 15 12 15 C13 15 13 14 13 14 L13 13 C13 13 14 13 14 12 L14 2.7461 C14 0.7006 12.764 0 11.4004 0 L4 0 z M4.25 1.5 L10.75 1.5 C10.8885 1.5 11 1.6115 11 1.75 C11 1.8885 10.8885 2 10.75 2 L4.25 2 C4.1115 2 4 1.8885 4 1.75 C4 1.6115 4.1115 1.5 4.25 1.5 z M3 3 L12 3 C13 3 13 3.9668 13 3.9668 L13 7 C13 7 13 8 12 8 L3 8 C2 8 2 7 2 7 L2 4 C2 4 2 3 3 3 z M3 10 C3.5523 10 4 10.4477 4 11 C4 11.5523 3.5523 12 3 12 C2.4477 12 2 11.5523 2 11 C2 10.4477 2.4477 10 3 10 z M12 10 C12.5522995 10 13 10.4477 13 11 C13 11.5523 12.5523 12 12 12 C11.4477 12 11 11.5523 11 11 C11 10.4477 11.4477 10 12 10 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgBus15;
