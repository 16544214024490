import { getApp } from "actions/apps"
import { resetProjectData } from "actions/globalActions"
import * as types from "../actions/actionTypes"

const appsInit = {
    apps: [],
    error: null,
    loadingApps: false,
    selectedApp: null,
}

export function appsReducer(state = appsInit, action) {
    switch (action.type) {
        case types.FETCH_APPS_START:
            return {
                ...state,
                error: null,
                loadingApps: true,
            }
        case types.FETCH_APPS_COMPLETE:
            return {
                ...state,
                apps: action.result,
                loadingApps: false,
            }
        case types.FETCH_APPS_FAILED:
            return {
                ...state,
                error: action.result,
                loadingApps: false,
            }
        case getApp.fulfilled.type:
            return {
                ...state,
                selectedApp: action.payload,
            }
        case resetProjectData.type:
            return appsInit
        default:
            return state
    }
}
