import { createAsyncThunk } from "@reduxjs/toolkit"
import { coreApiClient } from "actions/coreApiClient"
import { CoreAccountPermissions } from "./models/CoreAccountPermissions"
import { CoreClient } from "./models/CoreClient"
import { CoreModule } from "./models/CoreModule"
import { PaginatedResponse } from "./models/PaginatedResponse"

export const fetchCoreClientsWithProjects = createAsyncThunk("getCoreClientsWithProjects", (moduleId: string) => {
    return coreApiClient
        .get<PaginatedResponse<CoreClient>>(`client/listAccessible/${moduleId}`)
        .then(res => res.data.items)
})

export const fetchModules = createAsyncThunk("getModules", () => {
    return coreApiClient.get<CoreModule[]>("module/list").then(res => res.data)
})

export const fetchAccountPermissions = createAsyncThunk("fetchAccountPermissions", () => {
    return coreApiClient.get<CoreAccountPermissions>("account/myPermissions").then(res => res.data)
})
