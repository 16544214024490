import { makeStyles } from "@mui/styles"

export const useLegendEntryStyles = makeStyles(() => ({
    dividerSpacing: {
        marginBottom: 16,
        marginTop: 16,
    },
    labelsContainer: {
        overflowX: "hidden",
        overflowY: "auto",
        padding: 20,
    },
    labelText: {
        flexGrow: 1,
        textTransform: "capitalize",
    },
    multiLabelEntryContainer: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        paddingLeft: 21,
    },
    multiLabelHeaderText: {
        textTransform: "capitalize",
    },
    multiLabelText: {
        flexGrow: 1,
        fontSize: 14,
        lineHeight: "14px",
        textTransform: "capitalize",
    },
    singleLabelEntryContainer: {
        alignItems: "center",
        display: "flex",
        textTransform: "capitalize",
    },
}))
