import React, { useEffect, useState } from "react"
import RemoveIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import * as Yup from "yup"
import TitleTextField from "../common/TitleTextfield/TitleTextField"

const numberArrayValidation = Yup.array(Yup.number().typeError("Array elements need to be numbers"))
    .typeError("Invalid array of numbers")
    .required("Required")

//This isn't used anywhere and is left as reference. It can be used, but custom property components would probably fit better.
function NumberArrayProperty({
    editable,
    fixedArrayLen,
    maxArrayLen,
    minArrayLen,
    title,
    value,
    isDefaultValue,
    isLastRemainingCase,
    isZoomDependent,
    onPropertyChanged,
    onRemove,
    onTitleChanged,
}) {
    const [formValue, setFormValue] = useState(value.join(","))
    const [formError, setFormError] = useState(undefined)
    useEffect(() => {
        processArray(value.join(","))
    }, [])

    const validateArray = array => {
        return numberArrayValidation
            .when("$minArrayLen", {
                then: arr => arr.min(minArrayLen, `Array must be of at least length ${minArrayLen}`),
                is: true,
            })
            .when("$maxArrayLen", {
                then: arr => arr.max(maxArrayLen, `Array must be at most of length ${maxArrayLen}`),
                is: true,
            })
            .when("$fixedArrayLen", {
                then: arr => arr.length(fixedArrayLen, `Array must be of length ${fixedArrayLen}`),
                is: true,
            })
            .validate(array, {
                context: { fixedArrayLen: !!fixedArrayLen, maxArrayLen: !!maxArrayLen, minArrayLen: !!minArrayLen },
            })
    }

    const processArray = numberStringArray => {
        let numbersArray = numberStringArray.split(",")
        if (numbersArray.some(elem => elem === "")) {
            //This is a bit hacky but it's an easy way to trigger the invalid array validation error
            numbersArray = "INVALID_ARRAY"
        }
        //For empty string we send undefined to trigger required
        const valueToProcess = numberStringArray ? numbersArray : undefined

        setFormValue(numberStringArray)
        validateArray(valueToProcess)
            .then(() => {
                setFormError()
                onPropertyChanged(numbersArray)
            })
            .catch(err => {
                setFormError(err.errors[0])
            })
    }

    const onChange = e => {
        processArray(e.target.value)
        // const newValue =
        //     value[0] === "literal"
        //         ? [
        //               "literal",
        //               e.target.value
        //                   .split(",")
        //                   .map((stringNum) => {
        //                       const conversion = Number(stringNum);

        //                       return isNaN(conversion) || conversion.toString().length !== stringNum.length ? stringNum : conversion;
        //                   })
        //                   .filter((_, index) => index < 2)
        //           ]
        //         : e.target.value.split(",").filter((_, index) => index < 4);
    }
    const removable = onRemove && !isDefaultValue && !isLastRemainingCase

    return (
        <div className="property">
            <div className="property-content">
                {editable && (
                    <TitleTextField
                        value={title}
                        isDefaultValue={isDefaultValue}
                        isZoomDependent={isZoomDependent}
                        onTitleChanged={onTitleChanged}
                    />
                )}
                {removable && (
                    <div className="remove-button" onClick={onRemove}>
                        <RemoveIcon />
                    </div>
                )}
                <Box alignItems="center" display="flex" paddingX={1} width="100%">
                    <TextField
                        className="number"
                        error={!!formError}
                        fullWidth
                        helperText={formError}
                        label={title}
                        value={formValue}
                        onChange={onChange}
                    />
                </Box>
            </div>
        </div>
    )
}

export default NumberArrayProperty
