import * as types from "./actionTypes"

export const toggleMapTitle = () => ({ type: types.PRINT_TOGGLE_MAP_TITLE })

export const onMapTitleChange = value => ({ payload: value, type: types.PRINT_MAP_TITLE })

export const toggleMapNotes = () => ({ type: types.PRINT_TOGGLE_MAP_NOTES })

export const onMapNotesChange = value => ({ payload: value, type: types.PRINT_MAP_NOTES })

export const toggleMapLegend = () => ({ type: types.PRINT_MAP_LEGEND })

export const toggleMapScalebar = () => ({ type: types.PRINT_MAP_SCALEBAR })

export const toggleMapDate = () => ({ type: types.PRINT_TOGGLE_MAP_DATE })

export const toggleMapDateFormat = value => ({ payload: value, type: types.PRINT_TOGGLE_MAP_DATE_FORMAT })

export const toggleMapCopyright = () => ({ type: types.PRINT_TOGGLE_MAP_COPYRIGHT })

export const toggleMapNorthArrow = () => ({ type: types.PRINT_TOGGLE_MAP_NORTH_ARROW })

export const toggleMapLogo = () => ({ type: types.PRINT_TOGGLE_MAP_LOGO })

export const resetMapFeatures = () => ({ type: types.RESET_PRINT_FEATURES })
