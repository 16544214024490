export const nonInterpolableProperties = {
    "fill-antialias": true,
    "icon-allow-overlap": true,
    "icon-image": true,
    "line-cap": true,
    "line-dasharray": true,
    "line-join": true,
    "symbol-placement": true,
    "text-allow-overlap": true,
    "text-anchor": true,
    "text-field": true,
    "text-justify": true,
    "text-rotation-alignment": true,
}
