import { FC } from "react"
import { MenuItem } from "@mui/material"
import clsx from "clsx"
import ButtonLink from "components/common/ButtonLink/ButtonLink"
import CustomTypography from "components/common/CustomTypography/CustomTypography"
import OverflowTip from "components/common/OverflowTip/OverflowTip"
import { MENU_ITEM_HEIGHT } from "components/common/UserMenu/UserMenu"
import config from "config"
import { clientIdParam } from "constants/map/queryParams"
import { CoreClient } from "features/core/models/CoreClient"
import { useStyles } from "./style"

type Props = {
    activeCoreClients: CoreClient[]
    clientId: string
}

const ClientsPicker: FC<Props> = ({ activeCoreClients, clientId }) => {
    const classes = useStyles()

    const getPathname = (client: CoreClient) => {
        const queryParams = new URLSearchParams()
        queryParams.append(clientIdParam, client.id)

        return `${config.landingUrl}?${queryParams.toString()}`
    }

    const renderCoreClients = () => {
        return activeCoreClients.map(client => (
            <MenuItem
                key={client.id}
                className={clsx(classes.client, client.id === clientId && classes.selected)}
                component={ButtonLink}
                divider
                sx={{ height: MENU_ITEM_HEIGHT }}
                to={getPathname(client)}
            >
                <div className={classes.clientName}>
                    <OverflowTip variant="body2">{client.name}</OverflowTip>
                    {client.id === clientId && (
                        <CustomTypography className={classes.activeSession} variant="caption">
                            Active session
                        </CustomTypography>
                    )}
                </div>
                {!!client.logoUrl && <img alt="Client logo" className={classes.clientLogo} src={client.logoUrl} />}
            </MenuItem>
        ))
    }

    return <div className={classes.clients}>{renderCoreClients()}</div>
}

export default ClientsPicker
