import React from "react"
import { Icons } from "@emblautec/layer-styler-component"
import { useStyles } from "./style"

type Props = {
    symbolColor: string
    symbolIconColor: string
    symbolImage: string
}

const SymbolIcon = ({ symbolColor, symbolIconColor, symbolImage }: Props) => {
    const { legend, symbol } = useStyles({ symbolColor, symbolIconColor })
    return (
        <div className={legend}>
            <div className={symbol}>{symbolImage !== "" ? Icons[symbolImage] : "A"}</div>
        </div>
    )
}

export default SymbolIcon
