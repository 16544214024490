import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosClient from "./apiClient"

export const getApp = createAsyncThunk<any, { appId: string; isPublic: boolean }>(
    "getApp",
    async ({ appId, isPublic }) => {
        const route: string = isPublic ? `application/public/${appId}` : `application/${appId}/published`
        return axiosClient.get(route).then(res => res.data)
    },
)
