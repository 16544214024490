import * as React from "react";
const SvgIogp1047 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "250.42, 176.44, 105.93999, 218.91998", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "273.26,364.98 280.79,357.45 273.26,349.92 265.73,357.45 265.73,364.98 273.26,380.05 280.79,387.58 295.86,395.11 310.92,395.11 325.98,387.58 333.52,380.05 341.05,364.98 341.05,357.45 333.52,342.39 325.98,334.86 265.73,304.73 258.2,297.2 250.67,282.14 250.67,274.61 258.2,259.54 265.73,252.01 280.79,244.48 288.33,244.48 348.58,274.61", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "333.52,206.82 325.98,214.35 333.52,221.88 341.05,214.35 341.05,206.82 333.52,191.76 325.98,184.23 310.92,176.69 295.86,176.69 280.79,184.23 273.26,191.76 265.73,206.82 265.73,214.35 273.26,229.42 280.79,236.95 341.05,267.07 348.58,274.61 356.11,289.67 356.11,297.2 348.58,312.26 341.05,319.79 325.98,327.33 318.45,327.33 258.2,297.2", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "333.84,255.58 330.87,261.38 328.03,266.59 325.88,271.06 324.93,274.53 324.48,279.65 325.47,283.69", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIogp1047;
