import { useMemo } from "react"
import { MapLayer } from "model/map/MapLayer"
import { STYLE_TYPES } from "constants/layers/styleTypes"

type Params = {
    layers: MapLayer[]
}

// This will return us a map with the first polygon style for each source. This will be
// used to decide if to use a generated polygon label or a normal label for the layer
export const useSourceFirstPolygonLayerIdDict = ({ layers }: Params) => {
    return useMemo(() => {
        return layers.reduce((acc: Record<string, string>, layer) => {
            if (layer.type === STYLE_TYPES.fill) {
                acc[layer.resourceId] = layer.layerId
            }
            return acc
        }, {})
    }, [layers])
}
