import * as React from "react";
const SvgGrocery15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "grocery-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.2001 14 C3.2001 14.6 3.6001 15 4.2001 15 C4.8001 15 5.2001 14.6 5.2001 14 C5.2001 13.4 4.8001 13 4.2001 13 C3.6001 13 3.2001 13.4 3.2001 14 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.2001 14 C8.2001 14.6 8.6001 15 9.2001 15 C9.8001 15 10.2001 14.6 10.2001 14 C10.2001 13.4 9.8001 13 9.2001 13 C8.6001 13 8.2001 13.4 8.2001 14 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.25 1.5 C13.2322 1.5 13.2 1.5 13.2 1.5 C13.2 1.5 11.8079 1.4588 11.253 2 C10.7195 2.5202 10.5 2.9177 10.5 4 L1.2001 4 L2.5935998 8.814 C2.5971 8.8213 2.604 8.8279 2.6078 8.8351 C2.8422 9.4924 3.4536 9.9659 4.1846 9.993401 C4.1876 9.9947 4.2001 10 4.2001 10 L10.7001 10 L10.7001 10.2 C10.7001 10.2 10.7001 10.5 10.5001 10.9 C10.3001 11.299999 10.2001 11.5 9.4001 11.5 L2.9 11.5 C1.9000001 11.5 1.9000001 13 2.9 13 L4.1000004 13 L4.2000003 13 L9.1 13 L9.200001 13 L9.300001 13 C10.500001 13 11.400002 12.3 11.700001 11.6 C12.000001 10.900001 12.000001 10.3 12.000001 10.3 L12.000001 10 L12.000001 4 C12.000001 3.4764 12.229401 3 12.700001 3 C12.700001 3 13.232301 3 13.250001 3 C13.6642 3 14 2.6642 14 2.25 C14 1.8357999 13.6642 1.5 13.25 1.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M3.2001 14 C3.2001 14.6 3.6001 15 4.2001 15 C4.8001 15 5.2001 14.6 5.2001 14 C5.2001 13.4 4.8001 13 4.2001 13 C3.6001 13 3.2001 13.4 3.2001 14 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.2001 14 C8.2001 14.6 8.6001 15 9.2001 15 C9.8001 15 10.2001 14.6 10.2001 14 C10.2001 13.4 9.8001 13 9.2001 13 C8.6001 13 8.2001 13.4 8.2001 14 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.25 1.5 C13.2322 1.5 13.2 1.5 13.2 1.5 C13.2 1.5 11.8079 1.4588 11.253 2 C10.7195 2.5202 10.5 2.9177 10.5 4 L1.2001 4 L2.5935998 8.814 C2.5971 8.8213 2.604 8.8279 2.6078 8.8351 C2.8422 9.4924 3.4536 9.9659 4.1846 9.993401 C4.1876 9.9947 4.2001 10 4.2001 10 L10.7001 10 L10.7001 10.2 C10.7001 10.2 10.7001 10.5 10.5001 10.9 C10.3001 11.299999 10.2001 11.5 9.4001 11.5 L2.9 11.5 C1.9000001 11.5 1.9000001 13 2.9 13 L4.1000004 13 L4.2000003 13 L9.1 13 L9.200001 13 L9.300001 13 C10.500001 13 11.400002 12.3 11.700001 11.6 C12.000001 10.900001 12.000001 10.3 12.000001 10.3 L12.000001 10 L12.000001 4 C12.000001 3.4764 12.229401 3 12.700001 3 C12.700001 3 13.232301 3 13.250001 3 C13.6642 3 14 2.6642 14 2.25 C14 1.8357999 13.6642 1.5 13.25 1.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgGrocery15;
