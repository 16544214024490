import InfoIcon from "@mui/icons-material/Info"
import { Box } from "@mui/material"
import { useTheme } from "@mui/styles"
import clsx from "clsx"
import CustomTypography from "../CustomTypography/CustomTypography"
import { useStyles } from "./styles.js"

const InfoTextSection = ({ iconSize, textColor = undefined, textVariant, textWeight, ...props }) => {
    const classes = useStyles()
    const theme = useTheme()

    const extraSmallIcon = "extra-small"

    return (
        <Box className={classes.infoContainer} {...props}>
            <InfoIcon
                className={clsx(classes.infoIcon, { [classes.extraSmall]: iconSize == extraSmallIcon })}
                color="secondary"
                fontSize={iconSize}
            />
            <CustomTypography
                color={textColor || theme.customColors.appTextColorLight}
                textWeight={textWeight}
                variant={textVariant}
            >
                {props.children}
            </CustomTypography>
        </Box>
    )
}

export default InfoTextSection
