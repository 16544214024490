import * as React from "react";
const SvgTheatre11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "theatre-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0.6055 0.6992 C0.3027 0.6992 0 1.0019 0 1.3047 L0 5.0391 C0 6.4519 0.578 8 3 8 L3.5 8 L3.5 6.1836 L1.2109 6.1758 C1.2109 6.1758 1.5135 5.0390997 3.0273 5.0390997 C3.3300998 5.0390997 3.4308999 5.0396996 3.6327999 5.1407 L3.6327999 3.7266 C3.6327999 3.1211 3.8346 2.7188 4.2383 2.4161 C4.541 2.1133 4.789 2 5.3945 2 L6 2 L6 1.3945 C6 1.0917001 5.8028 0.7363 5.5 0.7363 C4.8945 0.7363 4.3393 1.3047 3.0273 1.3047 C2.0182 1.3047 1.211 0.6992 0.6055 0.6992 z M1.75 2.5 C2.1642 2.5 2.5 2.8358 2.5 3.25 C2.5 3.6642 2.1642 4 1.75 4 C1.3357999 4 1 3.6642 1 3.25 C1 2.8358 1.3358 2.5 1.75 2.5 z M5.5 3 C5.1972 3 4.9453 3.4238 4.9453 3.7266 L4.9453 7.461 C4.9453 8.8738 5.5506 10.4883 7.9726 10.4883 C10.3946 10.4883 11 8.7729 11 7.4609 L11 3.7266 C11 3.4238 10.8028 3 10.5 3 C9.8945 3 8.9083 3.5 8 3.5 C7.0916996 3.5 6.1055 3 5.5 3 z M6.75 5 C7.1642 5 7.5 5.3358 7.5 5.75 C7.5 6.1642 7.1642 6.5 6.75 6.5 C6.3358 6.5 6 6.1642 6 5.75 C6 5.3358 6.3358 5 6.75 5 z M9.25 5 C9.6642 5 10 5.3358 10 5.75 C10 6.1642 9.6642 6.5 9.25 6.5 C8.8358 6.5 8.5 6.1642 8.5 5.75 C8.5 5.3358 8.8358 5 9.25 5 z M6.1562 8.0664 L9.8906 8.0664 C9.8906 8.0664 9.588 9.2773 8.0742 9.2773 C6.560399 9.2773 6.1562 8.0664 6.1562 8.0664 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0.6055 0.6992 C0.3027 0.6992 0 1.0019 0 1.3047 L0 5.0391 C0 6.4519 0.578 8 3 8 L3.5 8 L3.5 6.1836 L1.2109 6.1758 C1.2109 6.1758 1.5135 5.0390997 3.0273 5.0390997 C3.3300998 5.0390997 3.4308999 5.0396996 3.6327999 5.1407 L3.6327999 3.7266 C3.6327999 3.1211 3.8346 2.7188 4.2383 2.4161 C4.541 2.1133 4.789 2 5.3945 2 L6 2 L6 1.3945 C6 1.0917001 5.8028 0.7363 5.5 0.7363 C4.8945 0.7363 4.3393 1.3047 3.0273 1.3047 C2.0182 1.3047 1.211 0.6992 0.6055 0.6992 z M1.75 2.5 C2.1642 2.5 2.5 2.8358 2.5 3.25 C2.5 3.6642 2.1642 4 1.75 4 C1.3357999 4 1 3.6642 1 3.25 C1 2.8358 1.3358 2.5 1.75 2.5 z M5.5 3 C5.1972 3 4.9453 3.4238 4.9453 3.7266 L4.9453 7.461 C4.9453 8.8738 5.5506 10.4883 7.9726 10.4883 C10.3946 10.4883 11 8.7729 11 7.4609 L11 3.7266 C11 3.4238 10.8028 3 10.5 3 C9.8945 3 8.9083 3.5 8 3.5 C7.0916996 3.5 6.1055 3 5.5 3 z M6.75 5 C7.1642 5 7.5 5.3358 7.5 5.75 C7.5 6.1642 7.1642 6.5 6.75 6.5 C6.3358 6.5 6 6.1642 6 5.75 C6 5.3358 6.3358 5 6.75 5 z M9.25 5 C9.6642 5 10 5.3358 10 5.75 C10 6.1642 9.6642 6.5 9.25 6.5 C8.8358 6.5 8.5 6.1642 8.5 5.75 C8.5 5.3358 8.8358 5 9.25 5 z M6.1562 8.0664 L9.8906 8.0664 C9.8906 8.0664 9.588 9.2773 8.0742 9.2773 C6.560399 9.2773 6.1562 8.0664 6.1562 8.0664 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTheatre11;
