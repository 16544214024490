import { Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import LegendEntries from "components/legend/LegendEntries/LegendEntries"
import MapCopyright from "components/map/printMapExtraInfo/mapCopyright"
import MapDate from "components/map/printMapExtraInfo/mapDate"
import MapNotes from "components/map/printMapExtraInfo/mapNotes"
import MapTitle from "components/map/printMapExtraInfo/mapTitle"
import { getToggledWidgetsSet } from "features/mapTools/selectors"
import { getPrintFeatures } from "selectors/printSelectors"
import widgetTypes from "utils/tools/widgets"
import { useLegendEntryStyles } from "./legendEntryStyles"
import { useStyles } from "./styles"

const MapLegendPortrait = () => {
    const classes = useStyles()

    const legendEntryClasses = useLegendEntryStyles()

    const printFeatures = useSelector(getPrintFeatures)
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT)

    const isRightPanelNecessary =
        printFeatures.showMapTitle ||
        printFeatures.showMapCopyright ||
        printFeatures.showMapDate ||
        printFeatures.showMapNotes

    const spacingOptions = {
        multiLabelEntryBreakpointSpacing: 12,
        multiLabelItemBreakpointSpacing: 3,
        multiLabelItemVerticalBreakpointSpacing: 12,
        singleEntryBreakpointSpacing: 6,
    }

    return (
        <Grid className={classes.root} container id="modal-info-portrait">
            <Grid className={classes.innerRoot} item xs={isRightPanelNecessary ? 10 : 12}>
                <Grid container>
                    <Grid className={classes.titlecontainer} item xs={12}>
                        <Typography className={classes.legendTitle}>Legend</Typography>
                    </Grid>
                    <Grid className={classes.labelsContainer} item xs={12}>
                        <LegendEntries classes={legendEntryClasses} spacingOptions={spacingOptions} />
                    </Grid>
                </Grid>
            </Grid>

            {isRightPanelNecessary && (
                <Grid className={classes.rightSidePanel} item xs={2}>
                    <Grid container>
                        {/* TEMPORARILY DISABLED */}
                        {/* <Grid item xs={12}>
                            {printFeatures.showMapLogo && <MapLogo modal={modalOpen} />}
                        </Grid> */}
                        <Grid className={classes.sectionStyle} item xs={12}>
                            {printFeatures.showMapTitle && (
                                <MapTitle mapTitle={printFeatures.mapTitle} modal={modalOpen} />
                            )}
                        </Grid>
                    </Grid>
                    {printFeatures.showMapScalebar && <div id="map-scalebar"></div>}
                    {printFeatures.showMapCopyright && (
                        <MapCopyright
                            mapCopyright={printFeatures.mapCopyright}
                            modal={modalOpen}
                            showMapLegend={printFeatures.showMapLegend}
                        />
                    )}
                    {printFeatures.showMapDate && (
                        <MapDate
                            mapDateFormat={printFeatures.mapDateFormat}
                            modal={modalOpen}
                            showMapLegend={printFeatures.showMapLegend}
                        />
                    )}
                    <div className={classes.notesSectionStyle}>
                        {printFeatures.showMapNotes && <MapNotes mapNotes={printFeatures.mapNotes} modal={modalOpen} />}
                    </div>
                </Grid>
            )}
        </Grid>
    )
}

export default MapLegendPortrait
