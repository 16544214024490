import { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { Tab, Typography } from "@mui/material"
import { StyledTabs } from "components/common/customTabs/customTabs"
import { ZanderSheet } from "features/zander/models/ZanderSheet"
import useZanderTabsStyles from "./styles"

type Props = {
    activeSheet: number
    sheets: ZanderSheet[]
    onChange: (index: number) => void
    onClose: (index: number) => void
}

const ZanderTabs = ({ activeSheet, sheets, onChange, onClose }: Props) => {
    const classes = useZanderTabsStyles()
    const [closeIconVisibility, setCloseIconVisibility] = useState(false)

    const onMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        if (activeSheet === index) {
            setCloseIconVisibility(true)
        }
    }

    const onMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        if (activeSheet === index) {
            setCloseIconVisibility(false)
        }
    }

    return (
        <StyledTabs
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={activeSheet}
            variant="scrollable"
            onChange={(_: React.SyntheticEvent, index: number) => onChange(index)}
        >
            {sheets.map((_, index) => (
                <Tab
                    key={index}
                    className={activeSheet === index ? classes.activeTab : ""}
                    label={
                        <div className={classes.tabLabel}>
                            <Typography variant="body1">Table {index + 1}</Typography>
                            {activeSheet === index && sheets.length > 1 && (
                                <CloseIcon
                                    className={`${classes.closeTabIcon} ${
                                        closeIconVisibility ? classes.closeIconVisible : ""
                                    }`}
                                    fontSize="small"
                                    onClick={e => {
                                        e.preventDefault()
                                        onClose(index)
                                    }}
                                />
                            )}
                        </div>
                    }
                    value={index}
                    onMouseEnter={e => onMouseEnter(e, index)}
                    onMouseLeave={e => onMouseLeave(e, index)}
                />
            ))}
        </StyledTabs>
    )
}

export default ZanderTabs
