import { linearProgressClasses } from "@mui/material/LinearProgress"
import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    percentage: {
        marginBottom: 8,
        marginTop: 16,
    },
    progressBar: {
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
        },
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.customColors.borderColor,
        },
        borderRadius: 5,
        height: 10,
        width: 350,
    },
    downloadContainer: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    },
}))
