import * as React from "react";
const SvgCemetery15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cemetery-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.46 12 L10.78 12 L12 3.55 C12.0175 3.2633 11.7992 3.0168 11.5126 2.9993 C11.4884 2.9979 11.4641 2.9981 11.44 3 L10.259999 3 C10.259999 2.08 9.029999 1 7.5099993 1 C5.99 1 4.77 2.08 4.77 3 L3.54 3 C3.253 2.9885 3.0111 3.2117 2.9995 3.4987 C2.9988 3.5158 2.999 3.5329 3 3.55 L4.2 12 L3.55 12 C3.2609 11.9886 3.0162 12.2112 3 12.5 L3 14 L12 14 L12 12.49 C11.9839 12.2067 11.7435 11.9886 11.46 12 z M4.5 5 L10.5 5 L10.5 6 L4.5 6 L4.5 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.46 12 L10.78 12 L12 3.55 C12.0175 3.2633 11.7992 3.0168 11.5126 2.9993 C11.4884 2.9979 11.4641 2.9981 11.44 3 L10.259999 3 C10.259999 2.08 9.029999 1 7.5099993 1 C5.99 1 4.77 2.08 4.77 3 L3.54 3 C3.253 2.9885 3.0111 3.2117 2.9995 3.4987 C2.9988 3.5158 2.999 3.5329 3 3.55 L4.2 12 L3.55 12 C3.2609 11.9886 3.0162 12.2112 3 12.5 L3 14 L12 14 L12 12.49 C11.9839 12.2067 11.7435 11.9886 11.46 12 z M4.5 5 L10.5 5 L10.5 6 L4.5 6 L4.5 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCemetery15;
