export enum StyleType {
    Background = "background",
    Circle = "circle",
    Fill = "fill",
    FillExtrusion = "fill-extrusion",
    Heatmap = "heatmap",
    HillShade = "hillshade",
    Line = "line",
    Raster = "raster",
    Sky = "sky",
    Symbol = "symbol",
}
