import dayjs, { Dayjs } from "dayjs"
import { ArchiveBucket } from "features/ais/models/ArchiveBucket"
import { ArchiveData } from "../../models/ArchiveData"
import { BoatPosition } from "../../models/BoatPosition"
import { BoatPositionWithStartTime } from "../../models/BoatPositionWithStartTime"
import { PlaybackSpeed } from "../../models/PlaybackSpeed"

type PlaybackSpeedSetting = {
    bucketSizeInMinutes: number
    interpolationFactor: number
    intervalBetweenLayersInMs: number
    lookAheadInMinutes: number
}

// it is important to keep track of this, so we create our chunks
// in a way that is divisible by all possible bucket sizes
export const HIGHEST_BUCKET_SIZE = 2
export const SLIDER_RANGE_LENGTH = 100

export const PLAYBACK_SPEED_SETTINGS_DICTIONARY: Record<PlaybackSpeed, PlaybackSpeedSetting> = {
    [PlaybackSpeed["0.5x"]]: {
        bucketSizeInMinutes: 1,
        interpolationFactor: 3,
        intervalBetweenLayersInMs: 500,
        lookAheadInMinutes: 10,
    },
    [PlaybackSpeed["16x"]]: {
        bucketSizeInMinutes: HIGHEST_BUCKET_SIZE,
        interpolationFactor: 0,
        intervalBetweenLayersInMs: 125,
        lookAheadInMinutes: 32,
    },
    [PlaybackSpeed["1x"]]: {
        bucketSizeInMinutes: 1,
        interpolationFactor: 1,
        intervalBetweenLayersInMs: 500,
        lookAheadInMinutes: 10,
    },
    [PlaybackSpeed["2x"]]: {
        bucketSizeInMinutes: 1,
        interpolationFactor: 0,
        intervalBetweenLayersInMs: 500,
        lookAheadInMinutes: 10,
    },
    [PlaybackSpeed["32x"]]: {
        bucketSizeInMinutes: HIGHEST_BUCKET_SIZE,
        interpolationFactor: 0,
        intervalBetweenLayersInMs: 62,
        lookAheadInMinutes: 32,
    },
    [PlaybackSpeed["4x"]]: {
        bucketSizeInMinutes: 1,
        interpolationFactor: 0,
        intervalBetweenLayersInMs: 250,
        lookAheadInMinutes: 16,
    },
    [PlaybackSpeed["8x"]]: {
        bucketSizeInMinutes: 1,
        interpolationFactor: 0,
        intervalBetweenLayersInMs: 125,
        lookAheadInMinutes: 24,
    },
}

export const getBoatIdentifier = (boat: BoatPosition) => `${boat.mmsi}-${boat.imo}`

export const getSecondsBetweenBuckets = (bucketSizeInMinutes: number, interpolationFactor: number) =>
    (60 * bucketSizeInMinutes) / (1 + interpolationFactor)

export const buildBoatsDictionary = (data: ArchiveBucket[]) => {
    const boatsDictionary: Record<string, BoatPositionWithStartTime> = {}

    for (const archiveBucket of data) {
        for (const boatPosition of archiveBucket.boatPositions) {
            if (!boatsDictionary[getBoatIdentifier(boatPosition)]) {
                boatsDictionary[getBoatIdentifier(boatPosition)] = {
                    ...boatPosition,
                    startTime: archiveBucket.startDate,
                }
            }
        }
    }

    return boatsDictionary
}

export const interpolateBuckets = (archiveData: ArchiveData, noOfBucketsToAdd: number) => {
    const buckets = archiveData.buckets
    const newBuckets: ArchiveBucket[] = []
    const interval = 60 / (noOfBucketsToAdd + 1) // number of seconds between new buckets

    for (let i = 0; i < buckets.length - 1; i++) {
        const startBucket = buckets[i]
        const endBucket = buckets[i + 1]
        newBuckets.push({ ...startBucket, startDate: dayjs(startBucket.startDate) })

        const boatsInNextBucketDictionary = endBucket.boatPositions.reduce(
            (acc, current) => {
                acc[getBoatIdentifier(current)] = current
                return acc
            },
            {} as Record<string, BoatPosition>,
        )

        const boatsToInterpolate = startBucket.boatPositions.filter(bp =>
            Boolean(boatsInNextBucketDictionary[getBoatIdentifier(bp)]),
        )

        for (let j = 1; j <= noOfBucketsToAdd; j++) {
            const factor = j / (noOfBucketsToAdd + 1)
            const startDate = dayjs(startBucket.startDate).add(j * interval, "seconds")

            const interpolatedBoatPositions = boatsToInterpolate.map(bp =>
                interpolateBoatPositions(bp, boatsInNextBucketDictionary[getBoatIdentifier(bp)], factor),
            )

            newBuckets.push({ boatPositions: interpolatedBoatPositions, startDate })
        }
    }

    const lastBucket = buckets[buckets.length - 1]
    newBuckets.push({ ...lastBucket, startDate: dayjs(lastBucket.startDate) })

    return { boatDetails: archiveData.boatDetails, buckets: newBuckets }
}

const interpolateBoatPositions = (start: BoatPosition, end: BoatPosition, factor: number): BoatPosition => ({
    ...start,
    lat: interpolate(start.lat, end.lat, factor),
    lon: interpolate(start.lon, end.lon, factor),
    time: dayjs(start.time).add(factor * dayjs(end.time).diff(start.time), "millisecond"),
})

const interpolate = (startValue: number, endValue: number, factor: number): number =>
    startValue + factor * (endValue - startValue)

export const findTargetChunkBinary = (chunks: [Dayjs, Dayjs][], target: Dayjs) => {
    let left = 0
    let right = chunks.length - 1

    while (left <= right) {
        const mid = Math.floor((left + right) / 2)
        const [start, end] = chunks[mid]

        if (
            (target.isAfter(start, "seconds") || target.isSame(start, "seconds")) &&
            (target.isBefore(end, "seconds") || target.isSame(end, "seconds"))
        )
            return mid
        else if (!target.isBefore(start, "seconds")) left = mid + 1
        else right = mid - 1
    }

    return -1
}
