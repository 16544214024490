import makeStyles from "@mui/styles/makeStyles"

export const useCoordinatesWidgetStyles = makeStyles(theme => ({
    coordinateContainer: {
        padding: "1px 6px 1px 6px",
    },
    coordinatesText: {
        fontSize: "0.685rem",
        minWidth: 90,
        padding: "0px 8px",
    },
    copyButton: {
        cursor: "pointer",
    },
    crsBox: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        marginRight: 8,
        padding: 8,
    },
    crsName: {
        textTransform: "uppercase",
    },
    iconStyle: {
        color: theme.customColors.black,
        height: 15,
    },
    root: {
        alignItems: "center",
        backgroundColor: theme.customColors.appBgColorLight,
        borderColor: theme.customColors.black,
        borderStyle: "solid",
        borderWidth: "1px 1px 1px 1px",
        color: theme.customColors.black,
        display: "flex",
        fontWeight: "bold",
    },
    selected: {
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    iconStyleDisabled: {
        color: "grey",
        height: 15,
    },
}))
