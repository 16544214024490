import * as React from "react";
const SvgArtGallery15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "art-gallery-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.71 4 L7.85 1.15 C7.6555 0.9539 7.339 0.9526 7.1429 1.1471 C7.1419 1.1481 7.141 1.149 7.14 1.15 L4.29 4 L1.5 4 C1.2239 4 1 4.2239 1 4.5 L1 13.5 C1 13.7761 1.2239 14 1.5 14 L13.5 14 C13.7761 14 14 13.7761 14 13.5 L14 4.5 C14 4.2239 13.7761 4 13.5 4 L10.71 4 z M7.5 2.21 L9.29 4 L5.71 4 L7.5 2.21 z M13 13 L2 13 L2 5 L13 5 L13 13 z M5 8 C4.4477 8 4 7.5523 4 7 C4 6.4477 4.4477 6 5 6 C5.5523 6 6 6.4477 6 7 C6 7.5523 5.5523 8 5 8 z M12 12 L4.5 12 L6 9 L7.25 11.5 L9.5 7 L12 12 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.71 4 L7.85 1.15 C7.6555 0.9539 7.339 0.9526 7.1429 1.1471 C7.1419 1.1481 7.141 1.149 7.14 1.15 L4.29 4 L1.5 4 C1.2239 4 1 4.2239 1 4.5 L1 13.5 C1 13.7761 1.2239 14 1.5 14 L13.5 14 C13.7761 14 14 13.7761 14 13.5 L14 4.5 C14 4.2239 13.7761 4 13.5 4 L10.71 4 z M7.5 2.21 L9.29 4 L5.71 4 L7.5 2.21 z M13 13 L2 13 L2 5 L13 5 L13 13 z M5 8 C4.4477 8 4 7.5523 4 7 C4 6.4477 4.4477 6 5 6 C5.5523 6 6 6.4477 6 7 C6 7.5523 5.5523 8 5 8 z M12 12 L4.5 12 L6 9 L7.25 11.5 L9.5 7 L12 12 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgArtGallery15;
