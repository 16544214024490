import * as React from "react";
const SvgTriangleStroked15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "triangle-stroked-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5243 1.5004 C7.2429 1.4913 6.9787 1.6423 6.8336 1.8952 L1.3336 11.7644 C1.0218 12.3078 1.395 12.9999 2 13 L13 13 C13.605 12.9999 13.9782 12.3078 13.6664 11.7645 L8.1664 1.8952999 C8.0302 1.6579 7.7884 1.5092 7.5243 1.5004 z M7.5 3.8993 L11.626699 11.3696995 L3.3733 11.3696995 L7.5 3.8993 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.5243 1.5004 C7.2429 1.4913 6.9787 1.6423 6.8336 1.8952 L1.3336 11.7644 C1.0218 12.3078 1.395 12.9999 2 13 L13 13 C13.605 12.9999 13.9782 12.3078 13.6664 11.7645 L8.1664 1.8952999 C8.0302 1.6579 7.7884 1.5092 7.5243 1.5004 z M7.5 3.8993 L11.626699 11.3696995 L3.3733 11.3696995 L7.5 3.8993 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgTriangleStroked15;
