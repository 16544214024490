import { FC } from "react"
import { Stack, TextField, TextFieldProps } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import dayjs, { Dayjs } from "dayjs"
import { display } from "html2canvas/dist/types/css/property-descriptors/display"
import { StyledTextField } from "./CustomDatePicker.styles"
import { DatePickerView } from "./types/DatePickerView"

type Props = {
    maxDate?: Dayjs
    minDate?: Dayjs
    name?: string
    value: Dayjs
    views?: DatePickerView[]
    onChange: (value: Dayjs) => void
}

const CustomDateTimePicker: FC<Props> = ({
    maxDate,
    minDate,
    name = "",
    value,
    views = ["year", "month", "day"],
    onChange,
}) => {
    return (
        <Stack alignItems="center" justifyContent="center">
            {/* @ts-ignore TODO: fix this */}
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                maxDate={maxDate}
                minDate={minDate}
                // @ts-ignore TODO: fix this
                slotProps={{
                    nextIconButton: {
                        sx: { "data-testid": `qa-${name}-date-picker-left-arrow` },
                    },
                    previousIconButton: {
                        sx: { "data-testid": `qa-${name}-date-picker-right-arrow` },
                    },
                }}
                value={value}
                views={views}
                onChange={(val, _) => onChange(val as Dayjs)}
            />
            <Stack alignItems="center" direction="row" paddingBottom="15px">
                {/* @ts-ignore TODO: fix this */}
                <TimePicker
                    ampm={false}
                    minutesStep={1}
                    // @ts-ignore TODO: fix this
                    slotProps={{
                        actionBar: {
                            actions: ["accept"],
                            sx: {
                                display: "flex",
                                justifyContent: "center",
                                paddingBlock: 0,
                            },
                        },
                    }}
                    sx={{ maxWidth: "105px" }}
                    timeSteps={{ minutes: 1 }}
                    timezone="UTC"
                    value={value}
                    views={["hours", "minutes"]}
                    onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                            onChange(value.set("hour", newValue.hour()).set("minute", newValue.minute()))
                        }
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CustomDateTimePicker
