import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    headerIconButton: {
        borderRadius: 4,
        height: 30,
        width: 30,
    },
    icon: {
        color: theme.customColors.appTextColor,
    },
}))
