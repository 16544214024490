import * as React from "react";
const SvgCafe11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cafe-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 9.5 C7 9.7761 6.7761 10 6.5 10 L2.5 10 C2.2239 10 2 9.7761 2 9.5 C2 9.2239 2.2239 9 2.5 9 L6.5 9 C6.7761 9 7 9.2239 7 9.5 z M8 3 L7 3 L7 2 L2 2 L2 6 C2.0016 7.3807 3.1222 8.4987 4.5029 8.4971 C5.4948 8.4959 6.3921 7.9085 6.79 7 L8 7 C9.1046 7 10 6.1046 10 5 C10 3.8954 9.1046 3 8 3 z M8 6 L7 6 L7 4 L8 4 C8.5522995 4 9 4.4477 9 5 C9 5.5523 8.5523 6 8 6 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M7 9.5 C7 9.7761 6.7761 10 6.5 10 L2.5 10 C2.2239 10 2 9.7761 2 9.5 C2 9.2239 2.2239 9 2.5 9 L6.5 9 C6.7761 9 7 9.2239 7 9.5 z M8 3 L7 3 L7 2 L2 2 L2 6 C2.0016 7.3807 3.1222 8.4987 4.5029 8.4971 C5.4948 8.4959 6.3921 7.9085 6.79 7 L8 7 C9.1046 7 10 6.1046 10 5 C10 3.8954 9.1046 3 8 3 z M8 6 L7 6 L7 4 L8 4 C8.5522995 4 9 4.4477 9 5 C9 5.5523 8.5523 6 8 6 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCafe11;
