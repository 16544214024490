import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles(theme => ({
    accountButton: {
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.07)",
        },
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: "100%",
        justifyContent: "center",

        paddingInline: theme.spacing(1.5),
    },
    accountButtonCollapsed: {
        cursor: "pointer",
        marginRight: theme.customSpacing.generalSidePadding,
    },
    largeAvatar: {
        backgroundColor: theme.customColors.appBgColorDark,
        fontSize: 16,
        fontWeight: "bold",
        height: 50,
        marginBottom: 8,
        width: 50,
    },
    largeProfileInfo: {
        alignItems: "center",
        borderBottom: `1px solid ${theme.customColors.lightGray}`,
        display: "flex",
        flexDirection: "column",
        padding: 16,
        textAlign: "center",
    },
    myAccount: {
        color: theme.customColors.primary3,
        fontSize: 14,
        textDecoration: "none",
    },
    overflowHidden: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    smallAvatar: {
        backgroundColor: theme.customColors.appBgColorDark,
        fontSize: 12,
        height: 30,
        width: 30,
    },

    smallProfileInfo: {
        display: "flex",
        flexDirection: "column",
        height: 30,
        justifyContent: "center",
        marginLeft: 8,
        marginRight: 4,
        maxWidth: 100,
    },
    username: {
        alignItems: "center",
        display: "flex",
        lineHeight: 1,
        marginLeft: 8,
        textTransform: "capitalize",
    },
}))
