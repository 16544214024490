import * as React from "react";
const SvgIogp1022 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "189.64, 146.1, 420.7199, 309.55", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "583.03,453.65 218.96,308.21", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "218.96,308.21 249.54,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "249.54,453.65 583.03,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "507.64,146.35 402.35,381.47", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400.49,344.21 400.49,344.21", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }), /* @__PURE__ */ React.createElement("path", { d: "M249.54 453.65 L249.54 320.43 L218.96 308.21 L249.54 453.65 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("path", { d: "M249.54 453.65 L583.03 453.65 L249.54 320.43 L249.54 453.65 z", fillRule: "evenodd", style: {
  fill: "red"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "189.89,453.65 610.11,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "red"
} }));
export default SvgIogp1022;
