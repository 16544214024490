import * as React from "react";
const SvgRail15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rail-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.93 14.26 L11.93 14.26 L10.93 12.26 L10.93 12.26 C10.8013 12.0157 10.4989 11.922 10.254601 12.0508 C10.0313 12.1684 9.9307 12.434 10.02 12.67 L10.02 12.67 L10.02 12.67 L10.19 13 L4.81 13 L4.94 12.73 L4.94 12.73 L4.94 12.73 C5.0377 12.4717 4.9075003 12.1831 4.6492 12.0854 C4.4132 11.9962 4.1476 12.0968 4.03 12.32 L4.03 12.32 L3.0300002 14.32 L3.0300002 14.32 C3.0088 14.3776 2.9986 14.4387 3 14.5 C3 14.7761 3.2239 15 3.5 15 C3.6802 14.999 3.8454 14.8992 3.93 14.74 L3.93 14.74 L3.93 14.69 L4 14.69 L4.31 14 L10.690001 14 L11 14.69 L11 14.69 L11 14.74 L11 14.74 C11.0846 14.8991995 11.2498 14.999 11.43 15 C11.7061 15 11.93 14.7761 11.93 14.5 C11.9499 14.4212 11.9499 14.3388 11.93 14.26 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 1 L3 1 C2.4477 1 2 1.4477 2 2 L2 10 C2 10.5522995 2.4477 11 3 11 L12 11 C12.5522995 11 13 10.5523 13 10 L13 2 C13 1.4477 12.5523 1 12 1 z M5.76 1.5 L9.26 1.5 C9.3981 1.5 9.51 1.6119 9.51 1.75 C9.51 1.8881 9.3981 2 9.26 2 L5.75 2 C5.6119 2 5.5 1.8881 5.5 1.75 C5.5 1.6119 5.6119 1.5 5.75 1.5 L5.76 1.5 z M5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 z M7 7 L3.5 7 C3.2239 7 3 6.7761 3 6.5 L3 3.5 C3 3.2239 3.2239 3 3.5 3 L7 3 L7 7 z M10 10 C9.4477005 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 C10.5523 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 z M12 6.5 C12 6.7761 11.7761 7 11.5 7 L8 7 L8 3 L11.5 3 C11.7761 3 12 3.2239 12 3.5 L12 6.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 1 L3 1 C2.4477 1 2 1.4477 2 2 L2 10 C2 10.5522995 2.4477 11 3 11 L12 11 C12.5522995 11 13 10.5523 13 10 L13 2 C13 1.4477 12.5523 1 12 1 z M5.76 1.5 L9.26 1.5 C9.3981 1.5 9.51 1.6119 9.51 1.75 C9.51 1.8881 9.3981 2 9.26 2 L5.75 2 C5.6119 2 5.5 1.8881 5.5 1.75 C5.5 1.6119 5.6119 1.5 5.75 1.5 L5.76 1.5 z M5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 z M7 7 L3.5 7 C3.2239 7 3 6.7761 3 6.5 L3 3.5 C3 3.2239 3.2239 3 3.5 3 L7 3 L7 7 z M10 10 C9.4477005 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 C10.5523 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 z M12 6.5 C12 6.7761 11.7761 7 11.5 7 L8 7 L8 3 L11.5 3 C11.7761 3 12 3.2239 12 3.5 L12 6.5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M11.93 14.26 L11.93 14.26 L10.93 12.26 L10.93 12.26 C10.8013 12.0157 10.4989 11.922 10.254601 12.0508 C10.0313 12.1684 9.9307 12.434 10.02 12.67 L10.02 12.67 L10.02 12.67 L10.19 13 L4.81 13 L4.94 12.73 L4.94 12.73 L4.94 12.73 C5.0377 12.4717 4.9075003 12.1831 4.6492 12.0854 C4.4132 11.9962 4.1476 12.0968 4.03 12.32 L4.03 12.32 L3.0300002 14.32 L3.0300002 14.32 C3.0088 14.3776 2.9986 14.4387 3 14.5 C3 14.7761 3.2239 15 3.5 15 C3.6802 14.999 3.8454 14.8992 3.93 14.74 L3.93 14.74 L3.93 14.69 L4 14.69 L4.31 14 L10.690001 14 L11 14.69 L11 14.69 L11 14.74 L11 14.74 C11.0846 14.8991995 11.2498 14.999 11.43 15 C11.7061 15 11.93 14.7761 11.93 14.5 C11.9499 14.4212 11.9499 14.3388 11.93 14.26 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 1 L3 1 C2.4477 1 2 1.4477 2 2 L2 10 C2 10.5522995 2.4477 11 3 11 L12 11 C12.5522995 11 13 10.5523 13 10 L13 2 C13 1.4477 12.5523 1 12 1 z M5.76 1.5 L9.26 1.5 C9.3981 1.5 9.51 1.6119 9.51 1.75 C9.51 1.8881 9.3981 2 9.26 2 L5.75 2 C5.6119 2 5.5 1.8881 5.5 1.75 C5.5 1.6119 5.6119 1.5 5.75 1.5 L5.76 1.5 z M5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 z M7 7 L3.5 7 C3.2239 7 3 6.7761 3 6.5 L3 3.5 C3 3.2239 3.2239 3 3.5 3 L7 3 L7 7 z M10 10 C9.4477005 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 C10.5523 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 z M12 6.5 C12 6.7761 11.7761 7 11.5 7 L8 7 L8 3 L11.5 3 C11.7761 3 12 3.2239 12 3.5 L12 6.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12 1 L3 1 C2.4477 1 2 1.4477 2 2 L2 10 C2 10.5522995 2.4477 11 3 11 L12 11 C12.5522995 11 13 10.5523 13 10 L13 2 C13 1.4477 12.5523 1 12 1 z M5.76 1.5 L9.26 1.5 C9.3981 1.5 9.51 1.6119 9.51 1.75 C9.51 1.8881 9.3981 2 9.26 2 L5.75 2 C5.6119 2 5.5 1.8881 5.5 1.75 C5.5 1.6119 5.6119 1.5 5.75 1.5 L5.76 1.5 z M5 10 C4.4477 10 4 9.5523 4 9 C4 8.4477 4.4477 8 5 8 C5.5523 8 6 8.4477 6 9 C6 9.5523 5.5523 10 5 10 z M7 7 L3.5 7 C3.2239 7 3 6.7761 3 6.5 L3 3.5 C3 3.2239 3.2239 3 3.5 3 L7 3 L7 7 z M10 10 C9.4477005 10 9 9.5523 9 9 C9 8.4477 9.4477 8 10 8 C10.5523 8 11 8.4477 11 9 C11 9.5523 10.5523 10 10 10 z M12 6.5 C12 6.7761 11.7761 7 11.5 7 L8 7 L8 3 L11.5 3 C11.7761 3 12 3.2239 12 3.5 L12 6.5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRail15;
