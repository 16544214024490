import * as React from "react";
const SvgRocket11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "rocket-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 1 C7.488 1 5.4077 2.1459 4.0488 4 L3 4 C2.1988 4 1.8162 4.3675 1.5 5 L1 6 L2 6 L3 6 L4 7 L5 8 L5 9 L5 10 L6 9.5 C6.6325 9.1838 7 8.8012 7 8 L7 6.9512 C8.8541 5.5923 10 3.512 10 2 L10 1 L9 1 z M7.5 3 C7.7761 3 8 3.2239 8 3.5 C8 3.7761 7.7761 4 7.5 4 C7.2239 4 7 3.7761 7 3.5 C7 3.2239 7.2239 3 7.5 3 z M2.75 7.25 L2.5 7.5 C2 8 2 9 2 9 C2 9 2.9448 9.0552 3.5 8.5 L3.75 8.25 L2.75 7.25 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M9 1 C7.488 1 5.4077 2.1459 4.0488 4 L3 4 C2.1988 4 1.8162 4.3675 1.5 5 L1 6 L2 6 L3 6 L4 7 L5 8 L5 9 L5 10 L6 9.5 C6.6325 9.1838 7 8.8012 7 8 L7 6.9512 C8.8541 5.5923 10 3.512 10 2 L10 1 L9 1 z M7.5 3 C7.7761 3 8 3.2239 8 3.5 C8 3.7761 7.7761 4 7.5 4 C7.2239 4 7 3.7761 7 3.5 C7 3.2239 7.2239 3 7.5 3 z M2.75 7.25 L2.5 7.5 C2 8 2 9 2 9 C2 9 2.9448 9.0552 3.5 8.5 L3.75 8.25 L2.75 7.25 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgRocket11;
