import * as React from "react";
const SvgReligiousMuslim15 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 21, height: 21, viewBox: "0, 0, 21, 21", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "religious-muslim-15.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 21, height: 21, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.7941 0 C3 0 0 3 0 6.7941 C0 10.5882 3 13.5882 6.7941 13.5882 C8.911699 13.5882 11.2059 12.882299 12.4412 11.2941 C11.6471 11.8235 10.1471 12.4412 9 12.4412 C6.0882 12.4412 3.8824 9.5294 3.8824 6.6177 C3.8824 3.7060003 6.0882 1.1471 9 1.1471 C10.0588 1.1471 11.5588 1.7646999 12.4412 2.2942 C11.2059 0.7059 8.9118 0 6.7941 0 z M11 3 L10 5.5 L7 5.5 L9.5 7 L8.5 10 L11 8.5 L13.5 10 L12.5 7 L15 5.5 L12 5.5 L11 3 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.7941 0 C3 0 0 3 0 6.7941 C0 10.5882 3 13.5882 6.7941 13.5882 C8.911699 13.5882 11.2059 12.882299 12.4412 11.2941 C11.6471 11.8235 10.1471 12.4412 9 12.4412 C6.0882 12.4412 3.8824 9.5294 3.8824 6.6177 C3.8824 3.7060003 6.0882 1.1471 9 1.1471 C10.0588 1.1471 11.5588 1.7646999 12.4412 2.2942 C11.2059 0.7059 8.9118 0 6.7941 0 z M11 3 L10 5.5 L7 5.5 L9.5 7 L8.5 10 L11 8.5 L13.5 10 L12.5 7 L15 5.5 L12 5.5 L11 3 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgReligiousMuslim15;
