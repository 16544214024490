import * as React from "react";
const SvgIsolatedDepression = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "246.1, 146.1, 307.80002, 307.8", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "400,300 400,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "246.35,300 246.55,292.22 247.14,284.46 248.12,276.73 249.5,269.07 251.26,261.49 253.4,254 255.92,246.64 258.8,239.41 262.05,232.33 265.66,225.43 269.61,218.73 273.89,212.23 278.5,205.95 283.42,199.92 288.64,194.14 294.14,188.64 299.92,183.42 305.95,178.5 312.23,173.89 318.73,169.61 325.43,165.66 332.33,162.05 339.41,158.8 346.64,155.92 354,153.4 361.49,151.26 369.07,149.5 376.73,148.12 384.46,147.14 392.22,146.55 400,146.35 407.78,146.55 415.54,147.14 423.27,148.12 430.93,149.5 438.51,151.26 446,153.4 453.36,155.92 460.59,158.8 467.67,162.05 474.57,165.66 481.27,169.61 487.77,173.89 494.05,178.5 500.08,183.42 505.86,188.64 511.36,194.14 516.58,199.92 521.5,205.95 526.11,212.23 530.39,218.73 534.34,225.43 537.95,232.33 541.2,239.41 544.08,246.64 546.6,254 548.74,261.49 550.5,269.07 551.88,276.73 552.86,284.46 553.45,292.22 553.65,300 553.45,307.78 552.86,315.54 551.88,323.27 550.5,330.93 548.74,338.51 546.6,346 544.08,353.36 541.2,360.59 537.95,367.67 534.34,374.57 530.39,381.27 526.11,387.77 521.5,394.05 516.58,400.08 511.36,405.86 505.86,411.36 500.08,416.58 494.05,421.5 487.77,426.11 481.27,430.39 474.57,434.34 467.67,437.95 460.59,441.2 453.36,444.08 446,446.6 438.51,448.74 430.93,450.5 423.27,451.88 415.54,452.86 407.78,453.45 400,453.65 392.22,453.45 384.46,452.86 376.73,451.88 369.07,450.5 361.49,448.74 354,446.6 346.64,444.08 339.41,441.2 332.33,437.95 325.43,434.34 318.73,430.39 312.23,426.11 305.95,421.5 299.92,416.58 294.14,411.36 288.64,405.86 283.42,400.08 278.5,394.05 273.89,387.77 269.61,381.27 265.66,374.57 262.05,367.67 258.8,360.59 255.92,353.36 253.4,346 251.26,338.51 249.5,330.93 248.12,323.27 247.14,315.54 246.55,307.78 246.35,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "291.35,191.35 334.81,234.81", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "465.19,365.19 508.65,408.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "291.35,408.65 334.81,365.19", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "465.19,234.81 508.65,191.35", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,146.35 400,207.81", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "400,392.19 400,453.65", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "246.35,300 307.81,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "492.19,300 553.65,300", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "black"
} }));
export default SvgIsolatedDepression;
