import makeStyles from "@mui/styles/makeStyles"

export const useLayerSelectorStylerStyle = makeStyles(theme => ({
    backIcon: {
        color: theme.customColors.black,
    },
    header: {
        backgroundColor: theme.customColors.white,
        borderTop: "1px solid " + theme.customColors.borderColor,
        width: "100%",
    },
    stylerDivWrapper: { display: "flex", flexGrow: 1, overflow: "auto" },
    stylerTabs: {
        borderTop: "1px solid " + theme.customColors.borderColor,
    },
}))
