import * as React from "react";
const SvgZoo11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "zoo-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 2 C7.1 2 6.5 2.5 6.2 3.1 C6.2 3.1 5.5 4 5 4 L3 4 C2.5 4 2 4.5 2 4.5 L0 6.5 L0 7 L0.5 7 L1.7 5.8 L1.9 6 C1.9 6 1 7.7 1 9 C1 9.5 1.5 9.5 1.5 9.5 L2 9.5 C2 9.5 2.2 9.5 2 9.3 L1.8 9.1 C1.8 8.6 2.6 7.7000003 3 7.2000003 C3 7.2000003 3 8 3 8.6 C3 8.900001 3.1111 9.5 3.4111 9.5 L3.9111 9.5 C3.9111 9.5 4.1110997 9.5 3.9111 9.3 L3.7110999 9.1 C3.5111 8.7 4.3 7.5 4.3 7.5 L6.1000004 7.5 L6.6 9 C6.7999997 9.5 7.1 9.5 7.1 9.5 L8.1 9.5 C8.3 9.5 8.8 9.3 8.3 9 L8.1 8.8 L8.1 7 L8.1 7 C9.1 7 9.200001 5.7 9.6 5.3 L10.3 5.2000003 C10.5 5.2000003 11.1 5.0000005 11.1 4.4 L11.1 4 L9.9 3 L9.9 3 C9.4 2.4 8.7 2 8 2 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8 2 C7.1 2 6.5 2.5 6.2 3.1 C6.2 3.1 5.5 4 5 4 L3 4 C2.5 4 2 4.5 2 4.5 L0 6.5 L0 7 L0.5 7 L1.7 5.8 L1.9 6 C1.9 6 1 7.7 1 9 C1 9.5 1.5 9.5 1.5 9.5 L2 9.5 C2 9.5 2.2 9.5 2 9.3 L1.8 9.1 C1.8 8.6 2.6 7.7000003 3 7.2000003 C3 7.2000003 3 8 3 8.6 C3 8.900001 3.1111 9.5 3.4111 9.5 L3.9111 9.5 C3.9111 9.5 4.1110997 9.5 3.9111 9.3 L3.7110999 9.1 C3.5111 8.7 4.3 7.5 4.3 7.5 L6.1000004 7.5 L6.6 9 C6.7999997 9.5 7.1 9.5 7.1 9.5 L8.1 9.5 C8.3 9.5 8.8 9.3 8.3 9 L8.1 8.8 L8.1 7 L8.1 7 C9.1 7 9.200001 5.7 9.6 5.3 L10.3 5.2000003 C10.5 5.2000003 11.1 5.0000005 11.1 4.4 L11.1 4 L9.9 3 L9.9 3 C9.4 2.4 8.7 2 8 2 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgZoo11;
