import * as React from "react";
const SvgIogp1027 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 800, height: 600, viewBox: "436.21, 199.8, 132.54001, 132.54", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("desc", null, "Produce by Acme CAD Converter"), /* @__PURE__ */ React.createElement("polyline", { points: "502.48,266.07 502.48,266.07", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M438.41 271.11 L438.41 261.03 L438.21 266.07 L438.41 271.11 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M438.41 271.11 L439.01 276.13 L438.41 261.03 L438.41 271.11 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M439.01 276.13 L439.01 256.02 L438.41 261.03 L439.01 276.13 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M439.01 276.13 L439.99 281.08 L439.01 256.02 L439.01 276.13 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M439.99 281.08 L439.99 251.07 L439.01 256.02 L439.99 281.08 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M439.99 281.08 L441.36 285.93 L439.99 251.07 L439.99 281.08 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.36 285.93 L441.36 246.21 L439.99 251.07 L441.36 285.93 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M441.36 285.93 L443.11 290.67 L441.36 246.21 L441.36 285.93 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.11 290.67 L443.11 241.48 L441.36 246.21 L443.11 290.67 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M443.11 290.67 L445.22 295.25 L443.11 241.48 L443.11 290.67 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M445.22 295.25 L445.22 236.89 L443.11 241.48 L445.22 295.25 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M445.22 295.25 L447.69 299.65 L445.22 236.89 L445.22 295.25 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.69 299.65 L447.69 232.49 L445.22 236.89 L447.69 299.65 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M447.69 299.65 L450.49 303.85 L447.69 232.49 L447.69 299.65 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M450.49 303.85 L450.49 228.29 L447.69 232.49 L450.49 303.85 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M450.49 303.85 L453.61 307.81 L450.49 228.29 L450.49 303.85 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.61 307.81 L453.61 224.33 L450.49 228.29 L453.61 307.81 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M453.61 307.81 L457.04 311.52 L453.61 224.33 L453.61 307.81 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.04 311.52 L457.04 220.63 L453.61 224.33 L457.04 311.52 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M457.04 311.52 L460.74 314.94 L457.04 220.63 L457.04 311.52 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M460.74 314.94 L460.74 217.2 L457.04 220.63 L460.74 314.94 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M460.74 314.94 L464.71 318.07 L460.74 217.2 L460.74 314.94 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M464.71 318.07 L464.71 214.08 L460.74 217.2 L464.71 318.07 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M464.71 318.07 L468.9 320.87 L464.71 214.08 L464.71 318.07 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.9 320.87 L468.9 211.27 L464.71 214.08 L468.9 320.87 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M468.9 320.87 L473.31 323.34 L468.9 211.27 L468.9 320.87 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M473.31 323.34 L473.31 208.81 L468.9 211.27 L473.31 323.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M473.31 323.34 L477.89 325.45 L473.31 208.81 L473.31 323.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M477.89 325.45 L477.89 206.69 L473.31 208.81 L477.89 325.45 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M477.89 325.45 L482.62 327.2 L477.89 206.69 L477.89 325.45 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M482.62 327.2 L482.62 204.95 L477.89 206.69 L482.62 327.2 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M482.62 327.2 L487.48 328.57 L482.62 204.95 L482.62 327.2 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.48 328.57 L487.48 203.58 L482.62 204.95 L487.48 328.57 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M487.48 328.57 L492.43 329.55 L487.48 203.58 L487.48 328.57 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M492.43 329.55 L492.43 202.59 L487.48 203.58 L492.43 329.55 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M492.43 329.55 L497.44 330.14 L492.43 202.59 L492.43 329.55 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.44 330.14 L497.44 202 L492.43 202.59 L497.44 330.14 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M497.44 330.14 L502.48 330.34 L497.44 202 L497.44 330.14 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M502.48 330.34 L502.48 201.8 L497.44 202 L502.48 330.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M502.48 330.34 L507.53 330.14 L502.48 201.8 L502.48 330.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M507.53 330.14 L507.53 202 L502.48 201.8 L507.53 330.14 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M507.53 330.14 L512.54 329.55 L507.53 202 L507.53 330.14 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M512.54 329.55 L512.54 202.59 L507.53 202 L512.54 329.55 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M512.54 329.55 L517.49 328.57 L512.54 202.59 L512.54 329.55 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.49 328.57 L517.49 203.58 L512.54 202.59 L517.49 328.57 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M517.49 328.57 L522.35 327.2 L517.49 203.58 L517.49 328.57 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M522.35 327.2 L522.35 204.95 L517.49 203.58 L522.35 327.2 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M522.35 327.2 L527.08 325.45 L522.35 204.95 L522.35 327.2 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M527.08 325.45 L527.08 206.69 L522.35 204.95 L527.08 325.45 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M527.08 325.45 L531.66 323.34 L527.08 206.69 L527.08 325.45 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M531.66 323.34 L531.66 208.81 L527.08 206.69 L531.66 323.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M531.66 323.34 L536.07 320.87 L531.66 208.81 L531.66 323.34 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M536.07 320.87 L536.07 211.27 L531.66 208.81 L536.07 320.87 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M536.07 320.87 L540.26 318.07 L536.07 211.27 L536.07 320.87 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M540.26 318.07 L540.26 214.08 L536.07 211.27 L540.26 318.07 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M540.26 318.07 L544.22 314.94 L540.26 214.08 L540.26 318.07 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M544.22 314.94 L544.22 217.2 L540.26 214.08 L544.22 314.94 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M544.22 314.94 L547.93 311.52 L544.22 217.2 L544.22 314.94 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M547.93 311.52 L547.93 220.63 L544.22 217.2 L547.93 311.52 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M547.93 311.52 L551.36 307.81 L547.93 220.63 L547.93 311.52 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M551.36 307.81 L551.36 224.33 L547.93 220.63 L551.36 307.81 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M551.36 307.81 L554.48 303.85 L551.36 224.33 L551.36 307.81 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M554.48 303.85 L554.48 228.29 L551.36 224.33 L554.48 303.85 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M554.48 303.85 L557.28 299.65 L554.48 228.29 L554.48 303.85 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M557.28 299.65 L557.28 232.49 L554.48 228.29 L557.28 299.65 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M557.28 299.65 L559.75 295.25 L557.28 232.49 L557.28 299.65 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M559.75 295.25 L559.75 236.89 L557.28 232.49 L559.75 295.25 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M559.75 295.25 L561.86 290.67 L559.75 236.89 L559.75 295.25 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M561.86 290.67 L561.86 241.48 L559.75 236.89 L561.86 290.67 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M561.86 290.67 L563.61 285.93 L561.86 241.48 L561.86 290.67 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M563.61 285.93 L563.61 246.21 L561.86 241.48 L563.61 285.93 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M563.61 285.93 L564.98 281.08 L563.61 246.21 L563.61 285.93 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M564.98 281.08 L564.98 251.07 L563.61 246.21 L564.98 281.08 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M564.98 281.08 L565.96 276.13 L564.98 251.07 L564.98 281.08 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M565.96 276.13 L565.96 256.02 L564.98 251.07 L565.96 276.13 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M565.96 276.13 L566.56 271.11 L565.96 256.02 L565.96 276.13 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M566.56 271.11 L566.56 261.03 L565.96 256.02 L566.56 271.11 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("path", { d: "M566.56 271.11 L566.75 266.07 L566.56 261.03 L566.56 271.11 z", fillRule: "evenodd", style: {
  fill: "#CCCCCC"
} }), /* @__PURE__ */ React.createElement("polyline", { points: "438.21,266.07 438.41,261.03 439.01,256.02 439.99,251.07 441.36,246.21 443.11,241.48 445.22,236.89 447.69,232.49 450.49,228.29 453.61,224.33 457.04,220.63 460.74,217.2 464.71,214.08 468.9,211.27 473.31,208.81 477.89,206.69 482.62,204.95 487.48,203.58 492.43,202.59 497.44,202 502.48,201.8 507.53,202 512.54,202.59 517.49,203.58 522.35,204.95 527.08,206.69 531.66,208.81 536.07,211.27 540.26,214.08 544.22,217.2 547.93,220.63 551.36,224.33 554.48,228.29 557.28,232.49 559.75,236.89 561.86,241.48 563.61,246.21 564.98,251.07 565.96,256.02 566.56,261.03 566.75,266.07 566.56,271.11 565.96,276.13 564.98,281.08 563.61,285.93 561.86,290.67 559.75,295.25 557.28,299.65 554.48,303.85 551.36,307.81 547.93,311.52 544.22,314.94 540.26,318.07 536.07,320.87 531.66,323.34 527.08,325.45 522.35,327.2 517.49,328.57 512.54,329.55 507.53,330.14 502.48,330.34 497.44,330.14 492.43,329.55 487.48,328.57 482.62,327.2 477.89,325.45 473.31,323.34 468.9,320.87 464.71,318.07 460.74,314.94 457.04,311.52 453.61,307.81 450.49,303.85 447.69,299.65 445.22,295.25 443.11,290.67 441.36,285.93 439.99,281.08 439.01,276.13 438.41,271.11 438.21,266.07", strokeWidth: 0.5, style: {
  fill: "none",
  stroke: "#CCCCCC"
} }));
export default SvgIogp1027;
