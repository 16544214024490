import * as React from "react";
const SvgCemetery11 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 17, height: 17, viewBox: "0, 0, 17, 17", xml: "http://www.w3.org/XML/1998/namespace", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlnsXml: "http://www.w3.org/XML/1998/namespace", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("title", null, "cemetery-11.svg"), /* @__PURE__ */ React.createElement("rect", { x: 0, y: 0, width: 17, height: 17, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.65 8 L8 8 L8 8 L9 2.3899999 C9.0167 2.1918998 8.8696 2.0177999 8.6714 2.0010998 C8.6577 1.9999 8.6438 1.9996 8.63 2 L7.16 2 C7.16 1.35 6.46 1 5.49 1 C4.5199995 1 3.66 1.35 3.66 2 L2.35 2 C2.1512 2.0048 1.994 2.1699 1.9988 2.3686 C1.999 2.3758 1.9994 2.3829 2 2.39 L3 8 L3 8 L2.35 8 C2.1567 7.9999 1.9999 8.1566 1.9999 8.3499 C1.9999 8.3533 1.9999 8.3567 2 8.360001 L2 10 L9 10 L9 8.36 C9.0055 8.1668 8.8534 8.0057 8.6601 8.000099 C8.6568 8 8.6534 8 8.65 8 z M7 5 L4 5 L4 4 L7 4 L7 5 z", transform: "translate(3, 3)", strokeWidth: 2, strokeLinejoin: "round", fillOpacity: 0.25, strokeOpacity: 0.25, style: {
  fill: "white",
  stroke: "white"
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.65 8 L8 8 L8 8 L9 2.3899999 C9.0167 2.1918998 8.8696 2.0177999 8.6714 2.0010998 C8.6577 1.9999 8.6438 1.9996 8.63 2 L7.16 2 C7.16 1.35 6.46 1 5.49 1 C4.5199995 1 3.66 1.35 3.66 2 L2.35 2 C2.1512 2.0048 1.994 2.1699 1.9988 2.3686 C1.999 2.3758 1.9994 2.3829 2 2.39 L3 8 L3 8 L2.35 8 C2.1567 7.9999 1.9999 8.1566 1.9999 8.3499 C1.9999 8.3533 1.9999 8.3567 2 8.360001 L2 10 L9 10 L9 8.36 C9.0055 8.1668 8.8534 8.0057 8.6601 8.000099 C8.6568 8 8.6534 8 8.65 8 z M7 5 L4 5 L4 4 L7 4 L7 5 z", transform: "translate(3, 3)", style: {
  fill: "#444444"
} }));
export default SvgCemetery11;
